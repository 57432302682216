import { useCallback, useContext } from "react"
import { IPoint, IPointEmpty } from "../../../../../../types";
import { MetaSourceTrunkTexture, MetaWAMTexture, MetaTAPTrunkTexture, MetaTAPVerticalTrunkTexture, getConnectorTexture, getConnectorTypeString, isMMC } from "../../../../../../factories/Texture";
import { CONNECTOR_STEP, GROUP_STEP } from "../../types";
import { TrunkContext } from "../../../../../../../workspace/redux/build/types";
import { DROP_MAX_CONNECTORS_PREEXPAND } from "../../../drop-base/types";
import { useSelector } from "react-redux";
import { BORDER_COLOR, CABLE_COLOR, COLLAPSED_LAST_GROUPINDEX, ConnectorIndexLabel, getConnectorCableDrawWidth, IConnectorLegProps, IFurcationProps, STAGGER_OFFSET_Y } from "./types";
import { Application, Graphics, Sprite, Text, TextStyle } from "pixi.js";
import { IUnitOfMeasure } from "../../../../../../../workspace/components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure";
import { useApp } from "@inlet/react-pixi";
import { texturesLoadedSelector } from "../../../../../../../workspace/components/overlay/components/header/components/status/redux/selectors";
import { MarkerStyleContext } from "../../../../../decorators/markers/types";
import { fiberTypeTrunkColorSelector, sscBootColorSelector, sscDefaultTriggerColorSelector } from "../../../../../../../workspace/redux/ssc/selectors";
import { IConnectorHighlightProps } from "../highlight/types";
import { getBounds } from "../../../../../../../workspace/selectors/build.selectors";
import { Rectangle } from "../../../../../decorators/interaction/types";
import { Feeder } from "../../../../types";

export const useConnectorFurcation = ({ legProps }: IFurcationProps) => {
    const { color: markerColor } = useContext(MarkerStyleContext);
    const { section, position, isCollapsed, connectorType, stagger, nbGroups, nbConnectors, connectorAnchorPoint, furcationPoint } = useContext(TrunkContext);
    const defaultConnectorColor = useSelector(sscDefaultTriggerColorSelector(connectorType));
    const connectorBootColor = useSelector(sscBootColorSelector(connectorType));
    const display = useSelector(texturesLoadedSelector);
    const { colorHex } = useSelector(fiberTypeTrunkColorSelector);
    const app = useApp();

    const drawFurcation = useCallback((grfx: Graphics) => {
        grfx.clear();
        grfx.removeChildren();
        for (const leg of legProps) {
            const staggerOffsetY = leg.staggerPosition * STAGGER_OFFSET_Y;
            const cableColor = colorHex ?? CABLE_COLOR.toString();
            const cableWidth = getConnectorCableDrawWidth(connectorType);
            const legPoint: IPoint = furcationPoint ?? IPointEmpty;
            drawLeg(grfx, cableColor, cableWidth, legPoint, leg, staggerOffsetY);

            const { width, height } = getConnectorTexture(connectorType);
            const connectorColor = leg.connectorColor ?? defaultConnectorColor;
            const type = getConnectorTypeString(connectorType);
            drawConnector(grfx, app, type, connectorColor, connectorBootColor, leg, staggerOffsetY, width, height);
    
            drawLabel(grfx, markerColor, leg, staggerOffsetY, width, height);

            const markersPoint: IPoint = connectorAnchorPoint ?? IPointEmpty;
            drawConnectorMarkers(grfx, isCollapsed, markersPoint, markerColor, section, nbGroups, nbConnectors, stagger);
        }
    }, [app, connectorAnchorPoint, furcationPoint, connectorBootColor, defaultConnectorColor, isCollapsed, markerColor, nbConnectors, nbGroups, section, stagger, colorHex, connectorType, legProps]);

    return { 
        display,
        position,
        highlights: getConnectorHighlights(position, connectorType, legProps), 
        drawFurcation 
    }
}

export const drawLeg = (grfx: Graphics, cableColor: string, cableWidth: number, point: IPoint, leg: IConnectorLegProps, staggerOffsetY: number) => {
    const { legEndpoint } = leg;
    const borderWidth = cableWidth + 2;
    const borderColor = BORDER_COLOR;

    grfx.lineStyle(borderWidth, borderColor);
    grfx.moveTo(legEndpoint.x, legEndpoint.y + 200);
    grfx.bezierCurveTo(legEndpoint.x, legEndpoint.y + 200, legEndpoint.x, legEndpoint.y + 150, point.x, point.y);

    grfx.moveTo(legEndpoint.x, legEndpoint.y + 199);
    grfx.lineTo(legEndpoint.x, legEndpoint.y + 315 + staggerOffsetY);

    grfx.lineStyle(cableWidth, Number(cableColor));

    grfx.moveTo(legEndpoint.x, legEndpoint.y + 200);
    grfx.bezierCurveTo(legEndpoint.x, legEndpoint.y + 200, legEndpoint.x, legEndpoint.y + 150, point.x, point.y);

    grfx.moveTo(legEndpoint.x, legEndpoint.y + 199);
    grfx.lineTo(legEndpoint.x, legEndpoint.y + 315 + staggerOffsetY);
};

export const drawConnector = (grfx: Graphics, app: Application, type: string, connectorColor: string, connectorBootColor: string, leg: IConnectorLegProps, staggerOffsetY: number, width: number, height: number) => {
    const { location } = leg;
    let key = `${type}_${connectorColor}_${connectorBootColor}`;
    let resource = app.loader.resources[key];
    if (resource === undefined) {
        key = `${type}_${connectorColor}`;
        resource = app.loader.resources[key];
    }

    if (resource && resource.texture) { 
        const connector = new Sprite(resource.texture);
        let offetX = isMMC(type) ? -1.5 : 0;
        connector.x = location.x + offetX;
        connector.y = location.y + staggerOffsetY;
        connector.width = width;
        connector.height = height;
        grfx.addChild(connector);
    }
};

export const drawLabel = (grfx: Graphics, markerColor: number, leg: IConnectorLegProps, staggerOffsetY: number, width: number, height: number) => {
    const { location, text: connectorText } = leg;
    const style = new TextStyle({ align: 'center', fontFamily: 'TheSansC4s', fill: [markerColor], fontSize: 24 });
    const text = new Text(connectorText, style);

    text.name = ConnectorIndexLabel;
    text.x = location.x + width * 0.5;
    text.y = location.y + staggerOffsetY + height + 12;
    text.anchor.x = 0.5;
    text.scale.x = text.scale.x * 0.5;
    text.scale.y = text.scale.y * 0.5;

    grfx.addChild(text)
};

export const drawConnectorMarkers = (grfx: Graphics, isCollapsed: boolean, point: IPoint, markerColor: number, section: string, nbGroups: number, nbConnectors: number, stagger: IUnitOfMeasure) => {
    if (isCollapsed) {
        grfx.lineStyle(1, markerColor);
    
        const dotOffset = 5;
        const offset = section === Feeder ? { x: 60, y: 70 } : { x: 60, y: 48 };
        const dotRadius = 0.7
        const connectorPerGroup = nbConnectors / nbGroups
        const isStaggered = stagger.value > 0 && nbGroups > 1;

        const showMiddle = nbGroups >= 2 || nbGroups === 1
        drawMiddle(grfx, showMiddle, point, offset, markerColor, dotOffset, dotRadius, isStaggered);
    
        const showSides = nbGroups > 1 && connectorPerGroup > 2
        drawSides(grfx, showSides, point, offset, markerColor, dotOffset, dotRadius, isStaggered);
    }
};

export const drawMiddle = (grfx: Graphics, showMiddle: boolean, point: IPoint, offset: IPoint, markerColor: number, dotOffset: number, dotRadius: number, isStaggered: boolean) => {
    if (showMiddle) {
        let x = point.x
        let y = isStaggered ? point.y + offset.y + GROUP_STEP : point.y + offset.y;
        grfx.drawCircle(x, y, 14);
        grfx.beginFill(markerColor);
        grfx.drawCircle(x - dotOffset, y + dotOffset, dotRadius);
        grfx.drawCircle(x, y + dotOffset, dotRadius);
        grfx.drawCircle(x + dotOffset, y + dotOffset, dotRadius);
        grfx.endFill();
    }
};

export const drawSides = (grfx: Graphics, showSides: boolean, point: IPoint, offset: IPoint, markerColor: number, dotOffset: number, dotRadius: number, isStaggered: boolean) => {
    if (showSides) {
        let x = point.x - offset.x
        let y = point.y + offset.y;
        grfx.drawCircle(x, y, 14);
        grfx.beginFill(markerColor);
        grfx.drawCircle(x - dotOffset, y + dotOffset, dotRadius);
        grfx.drawCircle(x, y + dotOffset, dotRadius);
        grfx.drawCircle(x + dotOffset, y + dotOffset, dotRadius);
        grfx.endFill();

        x = point.x + offset.x;
        y = isStaggered ? point.y + offset.y + (COLLAPSED_LAST_GROUPINDEX - 1) * GROUP_STEP : point.y + offset.y;
        grfx.drawCircle(x, y, 14);
        grfx.beginFill(markerColor);
        grfx.drawCircle(x - dotOffset, y + dotOffset, dotRadius);
        grfx.drawCircle(x, y + dotOffset, dotRadius);
        grfx.drawCircle(x + dotOffset, y + dotOffset, dotRadius);
        grfx.endFill();
    }
};

export const getConnectorHighlights = (position: number, connectorType: string, legProps: IConnectorLegProps[]) : IConnectorHighlightProps[] => {
    const { width, height } = getConnectorTexture(connectorType);
    return legProps.map((g, i) => {
        const staggerOffsetY = g.staggerPosition * STAGGER_OFFSET_Y;
        const bounds: Rectangle = getBounds(g.location.x - 2, g.location.y + staggerOffsetY - 2, width + 4, height + 4);
        return { key: `highlight-${i}`, position, index: g.connectorPosition, bounds };
    });
}

export function getLabelText(enabled: boolean, count: number, type: string) {
    return enabled ? count.toString() + ' X ' + type + ' connector(s)' : 'Source connector(s)';
}

export function getWAMLocation(nbConnectors: number, isCollapsed: boolean) {
    let width = getTAPWidth(nbConnectors, isCollapsed);
    return { x: (width * 0.5), y: 6.5 };
}

export function getVerticalTapLocation(wamLocation: IPoint) {
    return { x: wamLocation.x + 30.5, y: 35 }
}

export function getTAPWidth(nbConnectors: number, isCollapsed: boolean) {
    return MetaTAPTrunkTexture.width + getHorizontalTrunkWidth(nbConnectors, isCollapsed);
}

export function getHorizontalTrunkWidth(nbConnectors: number, isCollapsed: boolean) {
    let nbExtraConnectors = nbConnectors - DROP_MAX_CONNECTORS_PREEXPAND;
    let width = nbExtraConnectors * CONNECTOR_STEP > 0 ? nbExtraConnectors * CONNECTOR_STEP : 0;
    return isCollapsed ? 0 : width;
}

export const getFurcationPoint = (sectionContext: string, wamLocation?: IPoint) => {
    if (sectionContext === Feeder) {
        const { height } = MetaSourceTrunkTexture;
        return { x: 20, y: height - 14 };
    } else {
        const { width, height: wamHeight } = MetaWAMTexture;
        const { height: trunkHeight } = MetaTAPVerticalTrunkTexture;
        return { x: wamLocation!.x + width - 7.5, y: wamLocation!.y + wamHeight + trunkHeight - 25 };
    }
}

export const getConnectorAnchorPoint = (sectionContext: string, furcationPoint: IPoint) => {
    if (sectionContext === Feeder) {
        return { x: furcationPoint.x, y: furcationPoint.y + 252.8 };
    } else {
        return { x: furcationPoint.x, y: furcationPoint.y + 297 };
    }
}

export const getAnchorHeight = (sectionContext: string) => {
    if (sectionContext === Feeder) {
        const { height } = MetaSourceTrunkTexture;
        return height + 343.5;
    } else {
        const { height: wamHeight } = MetaWAMTexture;
        const { height: verticalHeight } = MetaTAPVerticalTrunkTexture
        return wamHeight + verticalHeight + 359
    }
}

export const getStaggerPosition = (stagger: IUnitOfMeasure | undefined, index: number, customBLength: boolean = false) => {
    return (stagger && stagger.value > 0) || customBLength ? index : 0;
}