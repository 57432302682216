import React from 'react';
import { useCustomBLength } from './hooks';
import { BLengthInput } from '../blength-input/BLengthInput';

import "./CustomBLength.scss";

export const CustomBLength = (props: { disabled: boolean }) => {
    const { bLengths, display, lengthBString } = useCustomBLength();
    return (
        <div className="blength-custom-section" hidden={!display}>
            <label className="blength-custom-title" hidden={!display}>{lengthBString}</label>
            {bLengths.map(b => <BLengthInput {...b} key={`${b.position}`} id={`g${b.position}`} disabled={props.disabled} />)}
        </div>
    )
}