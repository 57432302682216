import { Dialog } from "@orbit/dialog"
import { DialogHeader } from "../../../../../ui/dialog/header/DialogHeader"
import { useAboutThisApplication } from "./hooks"
import { ThirdPartyCopyrightDialog } from "./third-party-copyright-dialog/ThirdPartyCopyrightDialog"
import { Divider } from "@mui/material"
import { TextButton } from '@orbit/button'
import { Typography } from "@orbit/theme-provider"
import "./AboutThisApplication.scss"
import CorningLogo from './resources/images/Corning_301Blue_BoxLockup_WhiteLogo_Solid_1in.png';

export const AboutThisApplication = () => {
    const { showAboutApplication, dialogHeaderProps, dialogBodyProps, closeDialog } = useAboutThisApplication();

    return (
        <div id="about-this-application-container">
            {showAboutApplication ?
                <Dialog
                    open={showAboutApplication}
                    onClose={closeDialog} 
                    className="about-this-application"
                    preventOutsideDismiss={true} >
                    <DialogHeader {...dialogHeaderProps} />
                        <div className="content-container" >
                            <div className="left-container">
                                <img width="100px" alt="Corning Logo" height="100px" src={CorningLogo}/>
                            </div>
                            <div className="right-container">
                                <Typography label={dialogBodyProps.title ?? ""} variant="h6"/>
                                <Typography label={dialogBodyProps.lastBuild ?? ""} variant="caption"/>
                                <TextButton className="right-container-button " onClick={dialogBodyProps.onThirdPartyButtonClick}>{dialogBodyProps.thirdPartyButtonTitle}</TextButton>
                            </div>
                        </div>
                        <Divider />
                        <div className="corning-copyright-container"> 
                            <span className="copyright-font">{dialogBodyProps.corningCopyright}</span>
                        </div>
                </Dialog> : null }
                <ThirdPartyCopyrightDialog />
            </div>
    )
}