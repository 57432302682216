import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToolItem } from '../../../../ToolItem';
import { zoomIn, zoomOut } from '../../../../../../../pixi/components/viewport/redux/reducers';
import { viewportSelector } from '../../../../../../../pixi/components/viewport/redux/selectors';
import icon_plus  from './resources/icons/icon_plus.svg'
import icon_minus  from './resources/icons/icon_minus.svg'

export const ZoomTool = () => {
    const { zoomText, onZoomIn, onZoomOut } = useZoomText();
    return (
        <div className="controls-container zoom-container background-blur toggle-pointer-events">
            <ToolItem source={icon_plus} alt="Zoom in" tooltip="Zoom in" labelTop={true} onClick={onZoomIn}/>
            <ToolItem source={icon_minus} alt="Zoom out" tooltip="Zoom out" labelTop={true} onClick={onZoomOut}/>
            <div className="tool-vertical-divider"/>
            <label className="zoom-label">{zoomText}%</label>
        </div>
    );
}

export default ZoomTool;


function displayZoom(zoom: number) {
    const zoomPercentage = zoom * 100;
    return zoomPercentage.toFixed().toString();
}

function useZoomText() {
    const { scale } = useSelector(viewportSelector);
    const dispatch = useDispatch();
    
    const [zoomValue, setZoomValue] = useState(1);
    const [zoomText, setZoomText] = useState('100');
    
    
    const onZoomIn = () => dispatch(zoomIn())
    const onZoomOut = () => dispatch(zoomOut())


    useEffect(() => {
        setZoomText(displayZoom(zoomValue));
    }, [zoomValue])

    useEffect(() => {
        if (scale.x) {
            setZoomValue(scale.x)
        }
    }, [scale.x])


    return { zoomText, onZoomIn, onZoomOut };
}