import { Container } from '@inlet/react-pixi';
import { DocumentDropContainer } from '../types';
import { useDropGraphic } from './hooks'
import { PreviousDrop } from './components/PreviousDrop'
import { CurrentDrop } from './components/CurrentDrop'
import { BuildContext, TrunkContext} from '../../../../workspace/redux/build/types';
import { IPoint } from '../../../types';


export const DropGraphic = (props: IPoint) => {
    const dropGraphic = useDropGraphic()
    return (
        <Container name={DocumentDropContainer} {...props}>
            { dropGraphic.map(graphic => {
                const { dropContext, prevTrunkContext, ...data } = graphic;
                const { drop, build } = data;
                return (
                <Container key={graphic.key} name={`drop${drop.position}`}>
                    <BuildContext.Provider value={{ ...build }}>
                        <TrunkContext.Provider value={prevTrunkContext}>
                            <PreviousDrop {...data}/>
                        </TrunkContext.Provider>
                        <TrunkContext.Provider value={dropContext}>
                            <CurrentDrop {...data} />
                        </TrunkContext.Provider>
                    </BuildContext.Provider>
                </Container>
                )
            })}
        </Container>
    );

}