export interface IPoint {
    x: number,
    y: number
}

export const pointEqual = (p1: IPoint | undefined, p2: IPoint | undefined) => {
    return p1 && p2 
        ? p1.x === p2.x && p1.y === p2.y
        : p1 === undefined && p2 === undefined
}

export interface ISize {
    width: number;
    height: number;
}

export const IPointEmpty: Readonly<IPoint> = {
    x: 0,
    y: 0
}

export const cablePosition: Readonly<IPoint> = {
    x: 210,
    y: 200,
};

export const ISizeEmpty: Readonly<ISize> = {
    width: 0,
    height: 0
}

export const StageContainer = "stage-container";