import React from "react"
import { usePolarity } from "./hooks"
import { PolarityContext } from "./redux/types"
import { PolarityToolbar } from "./toolbar/PolarityToolbar"
import { ConnectorAssignmentDialog } from "./connector-assignment/ConnectorAssignmentDialog"
import { FiberMappingDialog } from "./fiber-mapping/FiberMappingDialog"
import { PolarityDialog } from "./PolarityDialog"

export const Polarity: React.FC = () => {
    const { polarityContext } = usePolarity();
    return (
        <PolarityContext.Provider value={polarityContext}>
            <PolarityToolbar />
            <ConnectorAssignmentDialog />
            <FiberMappingDialog />
            <PolarityDialog />
        </PolarityContext.Provider>
    )
}