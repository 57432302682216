import { useRef, useState, useEffect } from 'react';
import * as Pixi from 'pixi.js';
import { Rectangle } from "../interaction/types";

export const useBoundingBox = (bounds: Rectangle) => {
    const containerRef = useRef<Pixi.Container>(null);
    const { isMouseOver } = useMouseOver(containerRef);

    return { bounds, containerRef, isMouseOver }
}

export const useMouseOver = (ref: React.RefObject<Pixi.Container>, triggerMouseOver?: (e?: Pixi.InteractionEvent) => void, triggerMouseOut?: (e?: Pixi.InteractionEvent) => void) => {
    const [isMouseOver, setIsMouseOver] = useState(false);

    useEffect(() => {
        const setMouseTrue = (e: Pixi.InteractionEvent) => {
            setIsMouseOver(true);
            if (triggerMouseOver) {
                triggerMouseOver(e);
            }
        }
    
        const setMouseFalse = (e: Pixi.InteractionEvent) => {
            setIsMouseOver(false);
            if (triggerMouseOut) {
                triggerMouseOut(e);
            }
        }

        let container = ref.current! as unknown as Pixi.Container
        if (container) {
            container.addListener('mouseover', setMouseTrue);
            container.addListener('mouseout', setMouseFalse);
        }

        return () => {
            if (container) {
                container.removeListener('mouseover', setMouseTrue);
                container.removeListener('mouseout', setMouseFalse);
            }   
        }
    }, [ref, triggerMouseOver, triggerMouseOut])

    return { isMouseOver };
}