import Overlay from './components/overlay/Overlay';
import PixiComponent from '../pixi/PixiComponent';
import { OffScreen } from './components/off-screen/OffScreen';
import { useOktaSession } from './okta/hooks';
import { CTCMDNAThemeProvider } from '@orbit/theme-provider';

import "@orbit/text-field/build/index.css"
import "@orbit/select/build/index.css"
import './Workspace.scss'

export const Workspace = () => {
    const { oktaLoaded } = useOktaSession();
    return !oktaLoaded ? null : ( 
        <div className="workspace">
            <CTCMDNAThemeProvider>
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons"rel="stylesheet" />
                <PixiComponent />
                <Overlay />
                <OffScreen/>
            </CTCMDNAThemeProvider>
        </div> 
    );
}

export default Workspace;