import { createSelector } from "reselect";
import { viewportContextSelector } from "../../../../../../pixi/components/viewport/redux/selectors";
import { currentBuildSelector, workspaceSelector } from "../../../../../selectors/root.selectors";
import { getConnectorType } from "./types";
import { ViewportStatus } from "../../../../../../pixi/components/viewport/redux/types";

export const wizardSelector = createSelector(
    workspaceSelector,
    workspace => workspace.wizard
);

export const wizardSavedBuildDataSelector = createSelector(
    wizardSelector,
    wizard => wizard.savedBuildData
);

export const wizardDisplaySelector = createSelector(
    wizardSelector,
    wizard => wizard.display
);

export const wizardApplyEditChangesSelector = createSelector(
    wizardSelector,
    wizard => wizard.applyEditChanges
);

export const connectorAssignmentResetRequiredSelector = createSelector(
    wizardSavedBuildDataSelector,
    currentBuildSelector,
    (savedBuildData, currentBuild): boolean => {
        if (savedBuildData && currentBuild) {
            const savedSourceGroups = savedBuildData.source.groups;
            const currentSourceGroups = currentBuild.source.groups;
            const savedDestinations = savedBuildData.destinations;
            const currentDestinations = currentBuild.destinations;

            if (savedDestinations.length !== currentDestinations.length) {
                return true;
            }
            const savedConnectorType = savedSourceGroups[0].connectors[0].type!;
            const currentConnectorType = currentSourceGroups[0].connectors[0].type!;
            const sourceGroupChanged = savedSourceGroups.length !== currentSourceGroups.length
            const sourceFiberDensityChanged = getConnectorType(savedConnectorType).fiberCount !== getConnectorType(currentConnectorType).fiberCount
            const apCountChanged = savedDestinations.length !== currentDestinations.length;
            const dstGroupChanged = savedDestinations.some((d, i) => d.groups.length !== currentDestinations[i].groups.length)
            const dstFiberDensityChanged = savedDestinations.some((d, i) => d.groups.length && d.groups[0].connectors.length && getConnectorType(d.groups[0].connectors[0].type!).fiberCount !== getConnectorType(currentDestinations[i].groups[0].type!).fiberCount)
            return [
                sourceGroupChanged,
                sourceFiberDensityChanged,
                apCountChanged,
                dstGroupChanged,
                dstFiberDensityChanged,
            ].some((b) => b);
        }
        return false;
    }
);

export const wizardActiveSelector = createSelector(
    wizardDisplaySelector,
    viewportContextSelector,
    (wizardOpen, { context: viewportContext }) => wizardOpen && viewportContext === ViewportStatus.Inactive
);
