import React from 'react'
import { useSelector } from 'react-redux';
import { getUnitsName } from '../../../../../header/components/units-of-measure-container/UnitsOfMeasure';
import { unitsOfMeasureContainerUnitSelector } from '../../../../../header/components/units-of-measure-container/redux/selectors';

import '../../../../../footer/Footer.scss';

export default function CurrentUnitsTool() {
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);

    return (
        <div className="controls-container current-units-container toggle-pointer-events">
            <p className="unit-label">
                Unit: {getUnitsName(unit, true)}
            </p>
        </div>
    );
}