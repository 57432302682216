export interface IWarningsState {
    displayCard: boolean;
    acknowledgedWarnings: IWarning[];
    nextWarningGroupId: number;
    unacknowledgedWarningGroups: IWarningGroup[];
};

export const initialState: IWarningsState = {
    displayCard: false,
    acknowledgedWarnings: [],
    nextWarningGroupId: 1,
    unacknowledgedWarningGroups: [],
};

export interface IWarning {
    id: string;
    severity: WarningSeverity;
    componentName: string;
    problemText: string;
    solutionText?: string;
};

export enum WarningSeverity {
    Warning = 0,
    Error = 1,
};

export const initialWarning: IWarning = {
    id: '',
    componentName: '',
    problemText: '',
    severity: WarningSeverity.Warning,
}

export interface IWarningGroup {
    id: number;
    warnings: IWarning[];
};
