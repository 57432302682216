import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IConnectorData, IConnectorGroupData } from "../../connector/types";
import { IDestinationData } from "../../destination/types";
import { ISourceData } from "../../source/types";
import { initialState, IBuildData, initialBuildData } from '../../types'
import { UserWizardPresetDTO, ToUserWizardPreset, ToWizardPreset } from "../../../../services/wizardpreset/types";
import { WizardPresetService } from "../../../../services/wizardpreset/wizardpreset-service";
import { IUnitOfMeasure } from "../../../../components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure";
import { addPreset, setUserWizardPreset } from "./redux/reducers";
import { initialUserWizardPreset, UserWizardPreset, WizardPreset } from "./redux/types";
import { ConfigurationType } from "../../../../components/overlay/components/wizard/redux/types";
import { currentBuildConfigurationTypeSelector } from "../../../../selectors/build.selectors";
import { workspaceSelector } from "../../../../selectors/root.selectors";
import { selectedPresetSelector } from "./selector";

export const useWizardPreset = () => {
    const { builds } = useSelector(workspaceSelector);
    const { currentBuild } = builds;
    const configType = useSelector(currentBuildConfigurationTypeSelector)
    const selectedPreset = useSelector(selectedPresetSelector);
    const { addWizardPreset: addWizardPresetApi } = useWizardPresetApi();

    const selectedPresetBuild = useMemo(() => {
        if (selectedPreset && selectedPreset.id) {
            return createBuildUsingWizardPreset(selectedPreset);

        }
        return { ...initialBuildData, configurationType: configType }
    }, [selectedPreset, configType])

    const addWizardPreset = useCallback(async () => {
        if (currentBuild) {
            await addWizardPresetApi(BuildToWizardPreset(currentBuild));
        }
    }, [currentBuild, addWizardPresetApi]);

    return { addWizardPreset, selectedPresetBuild, selectedPreset };
}

export const useWizardPresetLoad = () => {
    const { setUserWizardPresetFromDTO, addUserWizardPreset } = useWizardPresetApi();

    const loadWizardPresets = useCallback(async () => {
        const res = await new WizardPresetService().getUserWizardPreset();
        if (res.data) {
            setUserWizardPresetFromDTO(res.data);
        } else {
            // user does not have a userwizard preset so let's add one
            await addUserWizardPreset(initialUserWizardPreset);
        }
    }, [addUserWizardPreset, setUserWizardPresetFromDTO]);

    return { loadWizardPresets }
}

export const useWizardPresetApi = () => {
    const dispatch = useDispatch();
    const configurationType = useSelector(currentBuildConfigurationTypeSelector)

    const setUserWizardPresetFromDTO = useCallback((userWizardPresetDTO: UserWizardPresetDTO) => {
        const wizardPreset = ToUserWizardPreset(userWizardPresetDTO, configurationType)
        dispatch(setUserWizardPreset(wizardPreset));
    }, [dispatch, configurationType]);

    const addUserWizardPreset = useCallback(async (userWizardPreset: UserWizardPreset) => {
        const res = await new WizardPresetService().addUserWizardPreset({ ...userWizardPreset, id: undefined });
        if (res.data) {
            setUserWizardPresetFromDTO(res.data);
        }
    }, [setUserWizardPresetFromDTO]);

    const addWizardPreset = useCallback(async (wizardPreset: WizardPreset) => {
        const res = await new WizardPresetService().addWizardPreset(wizardPreset);
        if (res.data) {
            const preset = ToWizardPreset(res.data);
            dispatch(addPreset(preset));
        }
    }, [dispatch]);

    return { setUserWizardPresetFromDTO, addUserWizardPreset, addWizardPreset };
}

function createConnectorData(tapPosition: number, groupPosition: number, connectorType: string, staggerLength: IUnitOfMeasure, connectorsPerGroup: number): IConnectorData[] {
    let connectorData: IConnectorData[] = [];
    for (let i = 0; i < connectorsPerGroup; i++) {
        connectorData.push({
            position: i,
            stagger: { unit: staggerLength.unit, value: staggerLength.value },
            type: connectorType,
            tapPosition,
            groupPosition
        });
    }
    return connectorData;
}

function createConnectorGroupData(tapPosition: number, connectorType: string, staggerLength: IUnitOfMeasure, groupCount: number, connectorsPerGroup: number): IConnectorGroupData[] {
    let connectorGroupData: IConnectorGroupData[] = [];
    for (let i = 0; i < groupCount; i++) {
        connectorGroupData.push({
            position: i,
            type: connectorType,
            stagger: { unit: staggerLength.unit, value: staggerLength.value },
            connectors: createConnectorData(tapPosition, i, connectorType, staggerLength, connectorsPerGroup),
            tapPosition
        });
    }
    return connectorGroupData;
}

function createBuildUsingWizardPreset(wizardPreset?: WizardPreset): IBuildData {
    if (!wizardPreset) return initialState.currentBuild!;

    let newBuild: IBuildData = { ...initialState.currentBuild!, configurationType: wizardPreset.configurationType };
    let calculatedSource: ISourceData | undefined;
    let calculatedDestinations: IDestinationData[] | undefined;

    if (wizardPreset.feederPreset) {
        newBuild.flameRating = wizardPreset.feederPreset.flameRating
        const { connectorType, connectorsPerGroup, fiberCount, groups, staggerLength, aLength, bLength, gripOnEnd } = wizardPreset.feederPreset;
        let source: ISourceData = {
            isCollapsed: false,
            fiberType: initialState.currentBuild!.source.fiberType,
            fiberCount: fiberCount,
            lengthA: { unit: aLength!.unit, value: aLength!.value },
            lengthB: { unit: bLength!.unit, value: bLength!.value },
            gripOnEnd: gripOnEnd,
            groups: createConnectorGroupData(0, connectorType!, staggerLength!, groups!, connectorsPerGroup!)
        }
        calculatedSource = source;
    }

    if (wizardPreset.dropPreset) {
        const { connectorType, accessPointCount, connectorsPerGroup, groups, staggerLength, aLength, bLength } = wizardPreset.dropPreset;
        let destinationGroupData: IDestinationData[] = [];
        let initialDestination: IDestinationData = initialState.currentBuild!.destinations[0];

        for (let i = 0; i < accessPointCount!; i++) {
            const tapPosition = i + 1;
            var destinationData: IDestinationData = {
                position: tapPosition,
                groups: createConnectorGroupData(tapPosition, connectorType!, staggerLength!, groups!, connectorsPerGroup!),
                isCollapsed: initialDestination.isCollapsed,
                lengthA: { unit: aLength!.unit, value: aLength!.value },
                lengthB: { unit: bLength!.unit, value: bLength!.value },
            }
            destinationGroupData.push(destinationData);
        }
        calculatedDestinations = destinationGroupData;
    }

    if (calculatedSource && calculatedDestinations && wizardPreset.feederPreset) {
        newBuild = {
            ...initialState.currentBuild!,
            configurationType: wizardPreset.configurationType ?? ConfigurationType.Patching,
            isAsymmetric: wizardPreset.dropPreset?.calculateAccessPoints ?? true,
            source: calculatedSource,
            destinations: calculatedDestinations,
        }
    }
    return newBuild;
};

export function BuildToWizardPreset(build: IBuildData): WizardPreset {
    const source = build.source!;
    const sourceGroup = source.groups[0]!;
    const destination = build.destinations[0]!;
    const destinationGroup = destination.groups[0];

    const newPreset: WizardPreset = {
        configurationType: build.configurationType ?? ConfigurationType.Patching,
        feederPreset: {
            aLength: { value: source.lengthA!.value, unit: source.lengthA!.unit },
            bLength: { value: source.lengthB!.value, unit: source.lengthB!.unit },
            staggerLength: { value: sourceGroup.stagger!.value, unit: sourceGroup.stagger!.unit },
            connectorType: sourceGroup.type,
            connectorsPerGroup: sourceGroup.connectors.length,
            fiberCount: source.fiberCount,
            groups: source.groups.length,
            gripOnEnd: source.gripOnEnd,
            flameRating: build.flameRating,
            customTag: "_ _ _ _ _" // default-custom-tag, translation.json, ln 72
        },
        dropPreset: {
            aLength: { value: destination.lengthA!.value, unit: destination.lengthA!.unit },
            bLength: { value: destination.lengthB!.value, unit: destination.lengthB!.unit },
            staggerLength: { value: destinationGroup.stagger!.value, unit: destinationGroup.stagger!.unit },
            calculateAccessPoints: build.isAsymmetric,
            accessPointCount: build.destinations.length,
            connectorType: destinationGroup.type,
            connectorsPerGroup: destinationGroup.connectors.length,
            groups: destination.groups.length,
        }
    }
    return newPreset;
}