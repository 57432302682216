import { useCallback, useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../../../locales/types";
import { ICollapsibleDialogProps } from "../../../../../../../ui/dialog/collapsible/types";
import { Blue, IColor, IColorDialogProps } from "../../../../../../../ui/dialog/color/types";
import { IConnectorData } from "../../../../../../redux/build/connector/types";
import { updatePositionedConnectors } from "../../../../../../redux/build/reducers";
import { DialogReducer, showDialog } from "../../../../../../redux/dialog/reducer";
import { IDialogContext, initialDialogState } from "../../../../../../redux/dialog/types";
import { sscDefaultTriggerColorSelector } from "../../../../../../redux/ssc/selectors";
import { AppDispatch } from "../../../../../../redux/reducers";
import { buildIdSelector, colorSelector, selectedTrunkSelector, triggerColorsInfoSelector } from "../../../../../../selectors/build.selectors";
import { setShowConnectorReport, showTriggerManagement } from "../../../../redux/reducers";
import { showTriggerManagementSelector } from "../../../../redux/selectors";
import { currentStatusSelector } from "../../../header/components/status/redux/selectors";
import { WorkspaceStatus } from "../../../header/components/status/redux/types";
import { ConnectorReportContext, setTriggerColorsChanged } from "../../redux/reducers";
import { setSelectedTrigger, setTriggers, TriggerManagementReducer, updateTrigger } from "./reducer/reducers";
import { initialTriggerManagementState, ITriggerInfo } from "./reducer/types";
import { propagationOptionsSelector } from "../../../polarity/propagation/redux/selectors";

export const useTriggerColorManagement = () => {
    const display = useSelector(showTriggerManagementSelector);
    const [state, dispatch] = useReducer(TriggerManagementReducer, initialTriggerManagementState);
    const { triggers, selectedTrigger } = state;
    const [colorDialogState, colorDialogDispatch] = useReducer(DialogReducer, initialDialogState);
    const colorDialogContext: IDialogContext = { state: colorDialogState, dispatch: colorDialogDispatch };
    const { dispatch: connectorReportDispatch } = useContext(ConnectorReportContext);
    const currentStatus = useSelector(currentStatusSelector);
    const disabled = currentStatus === WorkspaceStatus.Locked || currentStatus === WorkspaceStatus.Saving || currentStatus === WorkspaceStatus.Busy;
    const buildId = useSelector(buildIdSelector);
    const triggerInfos = useSelector(triggerColorsInfoSelector);
    const { groups } = useSelector(selectedTrunkSelector);
    const defaultColor = useSelector(sscDefaultTriggerColorSelector(selectedTrigger?.connectorType))
    const colors = useSelector(colorSelector);
    const propagationOptions = useSelector(propagationOptionsSelector);
    const { t } = useTranslation();
    const storeDispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (display) {
            dispatch(setTriggers(triggerInfos));
        }
    }, [display, triggerInfos]);

    useEffect(() => {
        if (!colorDialogState.props.open) {
            dispatch(setSelectedTrigger());
        }
    }, [colorDialogState.props.open]);

    const onClose = useCallback(() => {
        dispatch(setTriggers([]));
        storeDispatch(showTriggerManagement(false));
        storeDispatch(setShowConnectorReport(true));
        colorDialogDispatch(showDialog(false));
    }, [storeDispatch, colorDialogDispatch]);

    const dialogProps: ICollapsibleDialogProps = {
        className: "trigger-management-dialog",
        display,
        headerProps: {
            title: t(LocalizationKeys.ManageConnectorColor),
            closable: true,
            onClose
        }
    };

    const onTriggerClick = useCallback((trigger: ITriggerInfo) => {
        dispatch(setSelectedTrigger(trigger));
        colorDialogDispatch(showDialog(true));
    }, [dispatch, colorDialogDispatch]);

    const onColorButtonClick = useCallback((color: IColor) => {
        if (selectedTrigger) {
            const trigger = { ...selectedTrigger, color };
            dispatch(updateTrigger(trigger));
        }
        colorDialogDispatch(showDialog(false));
    }, [selectedTrigger, dispatch]);

    const onResetButtonClick = useCallback(() => {
        if (selectedTrigger) {
            const trigger: ITriggerInfo = { ...selectedTrigger, color: colors.find(c => c.name === defaultColor) ?? Blue }
            dispatch(updateTrigger(trigger));
        }
        dispatch(showDialog(false));
    }, [dispatch, selectedTrigger, colors, defaultColor])

    const colorDialogProps: IColorDialogProps = {
        context: colorDialogContext,
        preventOutsideDismiss: true,
        currentColor: selectedTrigger?.color,
        className: "trigger-management",
        displayFooter: true,
        colors: selectedTrigger ? colors : [],
        onColorButtonClick,
        onResetButtonClick,
    }

    const groupColumn = {
        className: "group-column",
        label: t(LocalizationKeys.Group)
    };
    const connectorColumn = {
        className: "connector-column",
        label: t(LocalizationKeys.Connector)
    };
    const triggerColumn = {
        className: "trigger-column",
        label: t(LocalizationKeys.TriggerHousing)
    }
    const tableProps = {
        header: [groupColumn, connectorColumn, triggerColumn],
        body: triggers
    }

    const onCancel = useCallback(() => {
        onClose();
    }, [onClose]);

    const cancelButtonProps = {
        disabled,
        onClick: onCancel
    };

    const applyColor = useCallback(async () => {
        if (buildId !== undefined && groups) {
            const connectors: IConnectorData[] = [];
            const feederConnectors: IConnectorData[] = [];
            for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
                const group = groups[groupIndex];
                const trigger = triggers[groupIndex];
                const feederGroupConnectors: IConnectorData[] = group.connectors.map(c => ({ ...c, color: trigger.color.name }));
                feederConnectors.push(...feederGroupConnectors);
            }
            connectors.push(...feederConnectors);
            await storeDispatch(updatePositionedConnectors(connectors, { buildId, options: propagationOptions }));
            connectorReportDispatch(setTriggerColorsChanged(true));
        }
        onClose();
    }, [groups, triggers, onClose, storeDispatch, buildId, propagationOptions, connectorReportDispatch]);

    const onApplyScheme = useCallback(() => {
        applyColor();
    }, [applyColor]);

    const applyButtonProps = {
        disabled,
        onClick: onApplyScheme
    };

    return {
        dialogProps,
        colorDialogProps,
        tableProps,
        selectedTrigger,
        onTriggerClick,
        cancelProps: {
            buttonProps: cancelButtonProps,
            label: t(LocalizationKeys.Cancel)
        },
        applyProps: {
            buttonProps: applyButtonProps,
            label: t(LocalizationKeys.ApplyScheme)
        }
    };
}