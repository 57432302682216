import { forwardRef } from "react";
import { withMemoization } from "../../../../../../../../../pixi/memoization/Memoization";
import { useCanvas } from "./hooks";
import { ICanvasHandle, ICanvasProps } from "./types";

export const Canvas = withMemoization(forwardRef<ICanvasHandle, ICanvasProps>((props, ref) => {
    const { canvasRef, width, height, containerRef, onWheel, onMouseMove, onMouseDown, onMouseUp } = useCanvas(props, ref);
    return (
        <div className="canvas-container" ref={containerRef}>
            <canvas
                ref={canvasRef}
                width={width}
                height={height}
                onWheel={onWheel}
                onMouseMove={onMouseMove}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
            />
        </div>
    )
}));