import { IEditToolbarState } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";

export const setToolbarDisplayReducer = (state: IEditToolbarState, action: PayloadAction<boolean>) => {
    state.display = action.payload;
}

export const setHoveredPositionReducer = (state: IEditToolbarState, action: PayloadAction<number>) => {
    state.selection.hovered = action.payload;
}

export const setSelectedPositionReducer = (state: IEditToolbarState, action: PayloadAction<number>) => {
    state.selection.selected = action.payload;
}

export const clearSelectionReducer = (state: IEditToolbarState) => {
    state.selection.hovered = -1;
    state.selection.selected = -1;
    state.display = false;
}