import { useTranslation } from "react-i18next";
import { LocalizationKeys } from '../../../../../../../../../locales/types';
import { useSelector, useDispatch } from "react-redux"
import { useCallback } from "react";
import { setFlameRating } from "../../../../../../../../redux/build/reducers";
import { sscFlameRatingSelector } from "../../../../../../../../redux/ssc/selectors";
import { currentBuildSelector } from "../../../../../../../../selectors/root.selectors";
import { FlameRatings } from "./types";

export const useFlameRating = () => {
    const { t } = useTranslation();
    const build = useSelector(currentBuildSelector);
    const { flameRatingOptions } = useSelector(sscFlameRatingSelector)
    const label = t(LocalizationKeys.FlameRating);
    const disabled = !!build?.catalogCode;
    const dispatch = useDispatch();

    const onFlameRatingChange = useCallback((e: React.ChangeEvent<any>) => {
        const value = e.target.value;
        dispatch(setFlameRating(value));
    }, [dispatch]);

    return { label, onFlameRatingChange, flameRatingOptions, disabled, flameRatingValue: build?.flameRating ?? FlameRatings.Riser };
}