import React from 'react';
import { IColorDialogProps } from './types';
import { useColorDialog } from './hooks';
import { DialogHeader } from '../header/DialogHeader';
import { ColorButton } from './ColorButton';
import { DialogContext } from '../../../workspace/redux/dialog/reducer';
import { DialogFooter } from '../footer/DialogFooter';

import './ColorDialog.scss';

export const ColorDialog: React.FC<IColorDialogProps> = (props) => {
    const { context, open, headerProps, footerProps, colors, currentColor, onColorButtonClick, displayFooter, className } = useColorDialog(props);
    if (!open) {
        return null;
    }
    return (
        <DialogContext.Provider value={context}>
            <div className={`color-dialog ${className}`}>
                <DialogHeader {...headerProps} />
                <div className="color-palette">
                    { colors.map(c => <ColorButton key={`color-${c.name}`} color={c} selected={currentColor !== undefined && c.name === currentColor.name} onClick={onColorButtonClick} /> ) }
                </div>
                {displayFooter ? <DialogFooter {...footerProps} /> : null}
            </div>
        </DialogContext.Provider>
    )
}