import { IColor } from '../../../ui/dialog/color/types';
import { IWarning } from '../../components/overlay/components/header/components/warnings/redux/types';
import { IFiberMapData } from '../build/connector/polarity/fiber-map/types';
import { IConfigurationType } from '../../components/overlay/components/wizard/components/setup/components/configuration-type/types';
// temp values before change to drop
export const NoneGrip = "0";
export const FeederGrip = "1";
export const sscUpdatedEventName = 'ssc-updated'

export interface IRangeIndex {
    value: number,
    isFromEnd: boolean
}

export interface IRange {
    min: number,
    max: number
}

export interface IConfigSession {
    sessionId: string;
    configurationType: string;
    configurationTypes: IConfigurationType[];
    flameRatings: { [value: string]: SessionCharacteristic<string> },
    fiberTypes: SessionCharacteristic<string>[],
    grips: { [value: string]: SessionCharacteristic<string> },
    defaultConnectorColors: Record<string, Record<string, IColor>>;
    connectorColors: Record<string, Record<string, IColor[]>>;
    defaultTrunkColors: Record<string, string>;
    defaultBootColors: Record<string, Record<string, string>>;
    feederEndConnectorColors: IColor[];
    feederEndLegRange: IRange;
    feederEndStaggerRange: IRange;
    feederEndConnectorTypes: IUIConnectorType[];
    feederEndGroupCountRange: IRange;
    feederEndALengthRange: IRange;
    allAccessPointsColors: IColor[];
    accessPointColors: { [key: string]: IColor[] };
    distributionLegRange: IRange;
    distributionStaggerRange: IRange;
    distributionConnectorTypes: IUIConnectorType[];
    distributionGroupCountRange: IRange;
    accessPointRange: IRange;
    distributionConnectorCountPerGroupRange: IRange;
    distributionDropFiberCountRange: IRange;
    distributionALengthRange: IRange;
    fiberCounts: SessionCharacteristic<IRange>[],
    polarityMaps: IPolaritySessionMap[]
}

export interface IBuildSession {
    sessionId: string,
    id: string,
    instanceId: string,
    isPremium: boolean,
    fiberType: string,
    configStatus: string,
    configurationType: string,
    flameRating: string,
    grip: string,
    description: string,
    fiberCount: number,
    feederEnd: IFeederEnd,
    distribution: IDistribution,
    warnings: IWarning[]
}

export interface IUpdateSessionResponse {
    session: IBuildSession,
    connectorColors: Record<string, Record<string, IColor[]>>;
    defaultConnectorColors: Record<string, Record<string, IColor>>;
}

export interface IColorResponse {
    id: string,
    displayValue: string
}

export interface IDistributionColorResponse {
    item1: IColorResponse[],
    item2: string
}

export interface IFeederEnd {
    id: string,
    instanceId: string,
    parentInstanceId: string,
    description: string,
    connectorColors: IColor[],
    drop: IDrop
}

export interface IDistribution {
    id: string,
    instanceId: string,
    parentInstanceId: string,
    description: string,

    color: string,
    fiberCountLocal?: number,
    uiConnectorType: string,
    furcationGroupCount: number,
    furcationGroupConnectorCount: number,
    furcationLegLength: number,
    furcationStaggerLength: number,
    accessPointCount: number,
    accessPoints: IAccessPoint[],

    uiConnectorTypes: { [id: string]: IUIConnectorType },
    furcationGroupCounts: SessionCharacteristic<number>[],
    furcationGroupConnectorCounts: SessionCharacteristic<number>[],
    furcationLegLengthRange: SessionCharacteristic<IRange>[],
    furcationStaggerLengthRange: SessionCharacteristic<IRange>[],
    accessPointCounts: SessionCharacteristic<IRange>[],
    dropFiberCountRange: IRange,
    aLengthRange: IRange
}

export interface IAccessPoint {
    id: string,
    instanceId: string,
    parentInstanceId: string,
    description: string,
    color: string,
    fiberCountLocal?: number,
    distanceFromPrior: number,
    distanceFromFeeder: number,
    sequenceNumber: number,
    connectorColors: IColor[],
    drop: IDrop
}

export interface IDrop {
    id: string,
    instanceId: string,
    parentInstanceId: string,
    description: string,
    color: string,
    fiberCountLocal?: number,
    uiConnectorType: string,
    furcationGroupCount: number,
    furcationGroupConnectorCount: number,
    furcationLegLength: number,
    furcationStaggerLength: number,
    uiConnectorTypes: { [id: string]: IUIConnectorType },
    furcationGroupCounts: SessionCharacteristic<number>[],
    furcationLegLengthRange: SessionCharacteristic<IRange>[],
    furcationStaggerLengthRange: SessionCharacteristic<IRange>[],
    sequenceNumber?: number,
    furcationGroups: IFurcationGroup[],
    aLengthRange: IRange
}

export interface IPolaritySessionMap {
    polarityId: string,
    polarityDescription: string,
    feederConnectorCount: number,
    feederTerminationCount: number,
    distributionConnectorCount: number,
    distributionTerminationCount: number,
}

export interface IFurcationGroup {
    id: string,
    instanceId: string,
    parentInstanceId: string,
    description: string,
    connectorCount: number,
    sequenceNumber?: number,
    furcationGroupLegs?: IFurcationGroupLeg[]
}

export interface IFurcationGroupLeg {
    id: string,
    instanceId: string,
    parentInstanceId: string,
    description: string,
    colour: string,
    furcationLegLength: number,
    sequenceNumber?: number,
    connectors: (IFeederConnector | IDistributionConnector)[],
}

export interface IFeederConnector {
    id: string,
    instanceId: string,
    parentInstanceId: string,
    description: string,
    labelData: string,
    color: IColor,
    defaultColor: IColor,
}

export interface IDistributionConnector {
    id: string,
    instanceId: string,
    parentInstanceId: string,
    description: string,
    labelData: string,
    color: IColor,
    defaultColor: IColor,
}

export interface IUIConnectorType {
    id: string,
    fiberCount: number,
    pinned?: boolean,
    description: string,
}

interface ISessionId {
    buildId: number,
    sessionId: string,
    configStatus: string,
    warnings: IWarning[]
}

export interface IEDSSessionState {
    configSession: IConfigSession,
    configSessionBusy: boolean,
    configSessions: { [configType: string]: IConfigSession },
    sessionIds: { [buildId: number]: ISessionId },
    sessionBusy: boolean,
    storedBuildId: ISessionId
}

export const initialSessionIds: { [buildId: number]: ISessionId } = {
    0: {
        buildId: 0,
        sessionId: "",
        configStatus: "N",
        warnings: []
    }
}

export const initialDrop: IDrop = {
    color: "",
    description: "",
    furcationGroupConnectorCount: 0,
    furcationGroupCount: 0,
    furcationGroupCounts: [],
    furcationLegLength: 0,
    furcationLegLengthRange: [],
    furcationStaggerLength: 0,
    furcationStaggerLengthRange: [],
    id: "",
    instanceId: "",
    parentInstanceId: "",
    uiConnectorType: "",
    uiConnectorTypes: {},
    fiberCountLocal: 0,
    furcationGroups: [],
    aLengthRange: { min: 0, max: 0 }
}

export const initialFeederEnd: IFeederEnd = {
    description: "",
    id: "",
    instanceId: "",
    parentInstanceId: "",
    connectorColors: [],
    drop: initialDrop
}

const initialDistribution: IDistribution = {
    ...initialDrop,
    accessPointCount: 0,
    accessPointCounts: [],
    accessPoints: [],
    furcationGroupConnectorCounts: [],
    dropFiberCountRange: { min: 0, max: 0 }
}

export const initialEDSSession: IBuildSession = {
    sessionId: "",
    id: "",
    instanceId: "",
    fiberType: "",
    isPremium: false,
    configStatus: "N",
    configurationType: "",
    flameRating: "",
    grip: "",
    description: "",
    fiberCount: 0,
    feederEnd: initialFeederEnd,
    distribution: initialDistribution,
    warnings: []
}

export const initialConfigSession: IConfigSession = {
    sessionId: "",
    configurationType: "",
    configurationTypes: [],
    fiberTypes: [],
    defaultConnectorColors: {},
    defaultTrunkColors: {},
    defaultBootColors: {},
    connectorColors: {},
    feederEndConnectorColors: [],
    feederEndLegRange: { min: 0, max: 0 },
    feederEndStaggerRange: { min: 0, max: 0 },
    feederEndConnectorTypes: [],
    feederEndGroupCountRange: { min: 0, max: 0 },
    feederEndALengthRange: { min: 0, max: 0 },
    allAccessPointsColors: [],
    accessPointColors: {},
    distributionLegRange: { min: 0, max: 0 },
    distributionStaggerRange: { min: 0, max: 0 },
    distributionConnectorTypes: [],
    distributionGroupCountRange: { min: 0, max: 0 },
    accessPointRange: { min: 0, max: 0 },
    distributionConnectorCountPerGroupRange: { min: 0, max: 0 },
    distributionDropFiberCountRange: { min: 0, max: 0 },
    distributionALengthRange: { min: 0, max: 0 },
    fiberCounts: [],
    flameRatings: {},
    grips: {},
    polarityMaps: []
}

export const initialEDSSessionState: IEDSSessionState = {
    configSession: initialConfigSession,
    sessionIds: initialSessionIds,
    sessionBusy: false,
    configSessionBusy: false,
    configSessions: {},
    storedBuildId: initialSessionIds[0]
}

export type SessionCharacteristic<T> = {
    id: T,
    description: string,
}

export interface IGenerateCatalogCodeResponse {
    sessionId: string;
    catalogCode?: string;
}

export interface IApplyPolarityResponse {
    fiberMaps: IFiberMapData[];
    warnings: IWarning[];
    configStatus: string,
}

export interface IDeletePolarityMapResponse {
    configStatus: string;
    warnings: IWarning[];
}