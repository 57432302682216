import useResizeObserver from "@react-hook/resize-observer";
import { useCallback, useEffect, useState } from "react";
import { IPanAndZoomToolbarProps } from "./toolbar/types";
import { useSelector, useDispatch } from "react-redux";
import { setAppliedPolarity, setBuildPolarity } from "../../../../../../../redux/build/connector/polarity/reducer";
import { PolarityMap, PolarityConfig, CUSTOM_MAP_KEY } from "../../../../../../../redux/build/connector/polarity/types";
import { sscDefaultBuildPolaritiesSelector } from "../../../../../../../redux/ssc/selectors";
import { currentBuildSelector } from "../../../../../../../selectors/root.selectors";
import { BuildService } from "../../../../../../../services/build-service";
import { ICanvasHandle, ICanvasProps } from "./canvas/types";
import { IPolarityDiagramProps } from "./types";

export const usePolarityDiagram = ({ polarityMap, polarityConfig }: IPolarityDiagramProps) => {
    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const containerRef = useCallback((container: HTMLDivElement | null) => setContainer(container), []);
    const applyButton = useApplyButton(polarityMap, polarityConfig);

    const title = polarityMap.description ?? "";
    const src = polarityMap.imageUri ?? "";

    const { toolbarProps, canvasProps, canvasRef} =  usePanAndZoom(src, container);

    return { toolbarProps, canvasProps, title, applyButton, canvasRef, containerRef }
}

const usePanAndZoom = (src: string, container: HTMLDivElement | null) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [canvas, setCanvas] = useState<ICanvasHandle | null>(null);

    const canvasRef = useCallback((canvas: ICanvasHandle | null) => {
        setCanvas(canvas);
    }, []);

    useResizeObserver(container!, (entry) => {
        const width = entry.contentRect.width;
        const height = entry.contentRect.height;
        setWidth(width);
        setHeight(height);
    });

    const onZoom = useCallback((zoom: number) => setZoom(zoom), []);

    const currentZoom = (zoom * 100).toFixed() + "%";

    const canvasProps: ICanvasProps = { 
        src, 
        width, 
        height, 
        onZoom 
    };

    const toolbarProps: IPanAndZoomToolbarProps = {
        currentZoom,
        container,
        canvas,
    };

    return { canvasProps, toolbarProps, canvas, currentZoom, canvasRef };
}

const useApplyButton = (selectedMap: PolarityMap, selectedConfig: PolarityConfig) => {
    const configs = useSelector(sscDefaultBuildPolaritiesSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const [configDispatched, setConfigDispatched] = useState(false);
    const [applyDisabled, setApplyDisabled] = useState(true);
    const dispatch = useDispatch();

    const onApplyClick = useCallback(async () => {
        if (selectedMap.key !== -1 && selectedConfig && !configDispatched) {
            const appliedPolarity = {
                ...selectedConfig,
                polarityMap: { ...selectedMap, id: 0 },
            };
            dispatch(setAppliedPolarity(appliedPolarity))

            const existingConfigIndex = configs?.findIndex(config => config.from?.fiberCount === appliedPolarity.from?.fiberCount && config.to?.fiberCount === appliedPolarity.to?.fiberCount)
            let newConfigs = configs ? [...configs] : []

            if (existingConfigIndex !== undefined && existingConfigIndex > -1) {
                newConfigs[existingConfigIndex] = { ...appliedPolarity }
            } else {
                newConfigs.push({ ...appliedPolarity })
            }

            const buildService = new BuildService();
            const res = await buildService.updateBuildPolarityDefinitions(currentBuild?.id ?? 0, newConfigs)
            if (res.data && res.succesful) {
                dispatch(setBuildPolarity(res.data ?? []));
            }

            setConfigDispatched(true);
        }
    }, [selectedMap, selectedConfig, configDispatched, dispatch, configs, currentBuild]);

    useEffect(() => {
        if (configDispatched) {
            setConfigDispatched(false);
        }
    }, [configDispatched]);

    useEffect(() => {
        setApplyDisabled(selectedMap === undefined || selectedMap.key === CUSTOM_MAP_KEY || !!currentBuild?.catalogCode);
    }, [selectedMap, currentBuild]);

    const applyButton = {
        onClick: onApplyClick,
        disabled: applyDisabled
    }

    return applyButton;
}