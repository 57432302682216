import { createSlice } from "@reduxjs/toolkit";
import { initialPropagationState } from "./types";
import {
    showPropagationDialogAction,
    setPropagationStateAction,
    setColorsPropagationStateAction,
    setLabelsPropagationStateAction,
    resetPropagationAction
} from './actions';

export const propagationSlice = createSlice({
    name: "propagation",
    initialState: initialPropagationState,
    reducers: {
        showPropagationDialog: showPropagationDialogAction,
        setPropagationState: setPropagationStateAction,
        setColorsPropagationState: setColorsPropagationStateAction,
        setLabelsPropagationState: setLabelsPropagationStateAction,
        resetPropagation: resetPropagationAction
    }
});

export const PropagationReducer = propagationSlice.reducer;
export const {
    showPropagationDialog,
    setPropagationState,
    setColorsPropagationState,
    setLabelsPropagationState,
    resetPropagation
} = propagationSlice.actions;