import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Okta } from "../../../../okta/okta";
import { AuthenticationService } from "../../../../services/authentication-service";
import { loginFailed, loginSuccessful } from "../../redux/reducers";
import { RedirectResult } from './types';

const decodeHash = (): Array<{ key: string; value: string }> => {
    return window.location.hash
        .slice(1)
        .split('&')
        .map((s) => { const p = s.split('='); return { key: p[0], value: decodeURIComponent(p[1]).replace(/\+/g, ' ') }; });
};

export const useOpenIdCallback = () => {
    const [result, setResult] = useState<RedirectResult | undefined>();
    const [isLogout] = useState<boolean>(window.location.href.includes('/oid_logout'));

    const dispatch = useDispatch();

    const login = useCallback(async (): Promise<RedirectResult> => {
        if (!window.location.hash) {
            console.debug('Navigated to openid callback without any hash');
            return { successful: true };
        }
        const idToken = await Okta.getAndSaveTokenFromUrl();
        if (!idToken) {
            const parts = decodeHash();
            const error = parts.find((p) => p.key === 'error');
            const reason = parts.find((p) => p.key === 'error_description');
            console.warn('Callback expects a JWT token in the id_token hash of the url, but got: ', parts);
            return {
                successful: false,
                error: error && error.value,
                reason: reason && reason.value,
            };
        }

        const res = await new AuthenticationService().oidLogin(idToken);

        if (res.succesful && res.data) {
            dispatch(loginSuccessful(res.data));
            return { successful: true };
        }
        else {
            dispatch(loginFailed(undefined));
            return { successful: false, error: '', reason: res.reason };
        }
    }, [dispatch]);

    useEffect(() => {
        login().then((res) => {
            setResult(res);
        });
    }, [login]);

    return { successful: result?.successful, reason: result?.reason, isLogout };
};

export const useLogout = () => {
    const logout = useCallback(async () => {
        const service = new AuthenticationService();
        service.logout().then(() => {
            AuthenticationService.saveToken(null);
        });
        await Okta.logoutWithRedirect();
    }, []);

    return { logout };
};

export const logoutSessionAsync = async () => {
    const service = new AuthenticationService();
    service.logout().then(() => {
        AuthenticationService.saveToken(null);
    });
    await Okta.logoutWithRedirect();
}