import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { hybrisCustomerSelector } from "../../../../../../authentication/redux/selectors";
import { IPolarityAssignmentRowProps } from "./types";

export const usePolarityAssignmentRow = (props: IPolarityAssignmentRowProps) => {
    const { data, onRowClick, onEditPolarity, onDeletePolarity } = props;
    const hybrisCustomer = useSelector(hybrisCustomerSelector);

    const tableClassName = useMemo(() => {
        if (data) {
            if (data.selected && data.editable) {
                return "selected editable";
            } else if (data.selected && !data.editable) {
                return "selected";
            } else if (!data.selected && data.editable) {
                return "editable";
            } else {
                return "";
            }
        }

    }, [data])

    const editButtonClassName = classNames("edit-button", { "hybris-button": !!hybrisCustomer });

    const deleteButtonClassName = useMemo(() => {
        if (data) {
            if (data.canDelete) {
                return classNames("delete-button", { "hybris-button": !!hybrisCustomer });
            } else {
                return classNames("delete-button", "disabled", { "hybris-button": !!hybrisCustomer });
            }
        }
    }, [data, hybrisCustomer])

    const onEdit = useCallback(() => {
        if (data) {
            onEditPolarity(data.id!)
        }
    }, [data, onEditPolarity])

    const onDelete = useCallback(() => {
        if (data) {
            onDeletePolarity(data.id!);
        }
    }, [data, onDeletePolarity])

    const onButtonCellClick = useCallback((e: any) => {
        e.stopPropagation();
    }, []);

    return { data, tableClassName, editButtonClassName, deleteButtonClassName, onRowClick, onEdit, onDelete, onButtonCellClick } 
}