import React from "react";
import { renderToString } from "react-dom/server";

export const LCUnibootSVG = (hex: string, scale?: number, bootColor?: string) => {
    let width = 140;
    let height = 700;
    if (scale) {
        width *= scale;
        height *= scale;
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 70">
            <g fill={bootColor} transform="translate(-1195.526 -1576)">
                <path stroke="none" d="M 1207.026489257812 1619.5 L 1198.026489257812 1619.5 L 1198.026489257812 1602.419067382812 L 1199.989990234375 1576.5 L 1205.06298828125 1576.5 L 1207.026489257812 1602.419067382812 L 1207.026489257812 1619.5 Z"/>
                <path stroke="none" fill="#484646" d="M 1200.45361328125 1577 L 1198.526489257812 1602.43798828125 L 1198.526489257812 1619 L 1206.526489257812 1619 L 1206.526489257812 1602.43798828125 L 1204.599365234375 1577 L 1200.45361328125 1577 M 1199.526489257812 1576 L 1205.526489257812 1576 L 1207.526489257812 1602.400146484375 L 1207.526489257812 1620 L 1197.526489257812 1620 L 1197.526489257812 1602.400146484375 L 1199.526489257812 1576 Z"/>
            </g>
            <g fill={hex} transform="translate(-1195.526 -1576)">
                <path stroke="none" d="M 1209.026489257812 1628.5 L 1196.026489257812 1628.5 L 1196.026489257812 1618.0615234375 L 1197.916870117188 1610.5 L 1200.026489257812 1610.5 L 1200.026489257812 1614 L 1200.026489257812 1614.081176757812 L 1200.052124023438 1614.158081054688 L 1201.03125 1617.095458984375 C 1201.080810546875 1617.679443359375 1201.500366210938 1618.482299804688 1202.517822265625 1618.499877929688 C 1202.52880859375 1618.500122070312 1202.53955078125 1618.500244140625 1202.55029296875 1618.500244140625 C 1203.558349609375 1618.500244140625 1203.973510742188 1617.688720703125 1204.021850585938 1617.094848632812 L 1205.000854492188 1614.158081054688 L 1205.027587890625 1614.077758789062 L 1205.026489257812 1613.992919921875 L 1204.977172851562 1610.5 L 1207.136108398438 1610.5 L 1209.026489257812 1618.0615234375 L 1209.026489257812 1628.5 Z"/>
                <path stroke="none" fill="#484646" d="M 1198.307250976562 1611 L 1196.526489257812 1618.123046875 L 1196.526489257812 1628 L 1208.526489257812 1628 L 1208.526489257812 1618.123046875 L 1206.745727539062 1611 L 1205.484252929688 1611 L 1205.5263671875 1613.98583984375 L 1205.52880859375 1614.155395507812 L 1205.475219726562 1614.316284179688 L 1204.51171875 1617.206420898438 C 1204.489135742188 1617.387084960938 1204.432250976562 1617.662109375 1204.289306640625 1617.94775390625 C 1203.954467773438 1618.616577148438 1203.320678710938 1619.000244140625 1202.55029296875 1619.000244140625 C 1202.53662109375 1619.000244140625 1202.52294921875 1619.000122070312 1202.509155273438 1618.999877929688 C 1201.194946289062 1618.977172851562 1200.631103515625 1617.97021484375 1200.539794921875 1617.202026367188 L 1199.577758789062 1614.316284179688 L 1199.526489257812 1614.162231445312 L 1199.526489257812 1614 L 1199.526489257812 1611 L 1198.307250976562 1611 M 1197.526489257812 1610 L 1200.526489257812 1610 L 1200.526489257812 1614 L 1201.526489257812 1617 C 1201.526489257812 1617 1201.552001953125 1617.983154296875 1202.526489257812 1618 C 1202.534545898438 1618.000122070312 1202.542358398438 1618.000244140625 1202.55029296875 1618.000244140625 C 1203.501342773438 1618.000244140625 1203.526489257812 1617 1203.526489257812 1617 L 1204.526489257812 1614 L 1204.470092773438 1610 L 1207.526489257812 1610 L 1209.526489257812 1618 L 1209.526489257812 1629 L 1195.526489257812 1629 L 1195.526489257812 1618 L 1197.526489257812 1610 Z"/>
            </g>
            <rect fill="#c4c4c4" width="2" height="4" transform="translate(3 66)"/>
            <g fill="#005090" stroke="#484646" transform="translate(2 55)">
                <rect stroke="none" width="4" height="11"/>
                <rect fill="none" x="0.5" y="0.5" width="3" height="10"/>
            </g>
            <g fill="#005090" stroke="#484646" transform="translate(8 55)">
                <rect stroke="none" width="4" height="11"/>
                <rect fill="none" x="0.5" y="0.5" width="3" height="10"/>
            </g>
            <g fill="#484646" stroke="#484646" transform="translate(8 53)">
                <rect stroke="none" width="4" height="3"/>
                <rect fill="none" x="0.5" y="0.5" width="3" height="2"/>
            </g>
            <g fill="#484646" stroke="#484646" transform="translate(2 53)">
                <rect stroke="none" width="4" height="3"/>
                <rect fill="none" x="0.5" y="0.5" width="3" height="2"/>
            </g>
            <rect fill="#c4c4c4" width="2" height="4" transform="translate(9 66)"/>
            <g fill="none" stroke="#484646" transform="translate(2 46)">
                <rect stroke="none" width="10" height="7"/>
                <rect fill="none" x="0.5" y="0.5" width="9" height="6"/>
            </g>
            <rect fill="#484646" width="6" height="1" transform="translate(4 6)"/>
            <rect fill="#484646" width="6" height="1" transform="translate(4 11)"/>
            <rect fill="#484646" width="2" height="2" transform="translate(3 13)"/>
            <rect fill="#484646" width="2" height="2" transform="translate(3 18)"/>
            <rect fill="#484646" width="1" height="2" transform="translate(4 8)"/>
            <rect fill="#484646" width="2" height="2" transform="translate(9 13)"/>
            <rect fill="#484646" width="2" height="2" transform="translate(9 18)"/>
            <rect fill="#484646" width="1" height="2" transform="translate(9 8)"/>
            <rect fill="#484646" width="8" height="1" transform="translate(3 16)"/>
            <rect fill="#484646" width="8" height="1" transform="translate(3 21)"/>
            <rect fill="#484646" width="8" height="1" transform="translate(3 48)"/>
            <rect fill="#484646" width="8" height="1" transform="translate(3 50)"/>
            <text fill="#484646" fontSize="3px" fontFamily="TheSansC4s, ArialMT, Arial" transform="translate(4 40) rotate(180)">
                <tspan x="0" y="0">A</tspan>
            </text>
            <text fill="#484646" fontSize="3px" fontFamily="TheSansC4s, ArialMT, Arial" transform="translate(12 40) rotate(180)">
                <tspan x="0" y="0">B</tspan>
            </text>
        </svg>
    )
}

export const getLCUnibootSVGString = (triggerColorHex: string, bootColor: string): string => {
    const lcUnibootSvg = LCUnibootSVG(triggerColorHex, undefined, bootColor);
    return renderToString(lcUnibootSvg);
};
