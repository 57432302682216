import { createSlice } from "@reduxjs/toolkit";
import { initialNotificationManagerState } from "./types";
import { setNotificationAction, setPropagationNotificationAction, showNotificationAction } from "./actions";

const notificationManagerSlice = createSlice({
    initialState: initialNotificationManagerState,
    name: "notificationManager",
    reducers: {
        showNotification: showNotificationAction,
        setNotification: setNotificationAction,
        setPropagationNotification: setPropagationNotificationAction
    }
});

export const NotificationManagerReducer = notificationManagerSlice.reducer;
export const { 
    showNotification,
    setNotification,
    setPropagationNotification
} = notificationManagerSlice.actions;