import { IOverlayState } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { IConnectorAssignmentMap } from "../components/polarity/connector-assignment/redux/types";
import { AssignedIndexCodes } from "../../../redux/build/connector/polarity/fiber-map/types";

export const setDisplayPrintAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.printSettings.showPrintDialog = action.payload;
}

export const setEnableFiberMapsOptionAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.printSettings.enableFiberMapsOption = action.payload;
}

export const setEnablePolarityDiagramOptionAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.printSettings.enablePolarityDiagramOption = action.payload;
}

export const setShowThirdPartyCopyrightAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showThirdPartyCopyright = action.payload;
}

export const setShowAboutApplicationAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showAboutApplication = action.payload;
}

export const setShowConnectorReportAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showConnectorReport = action.payload;
}

export const setShowLabelSchemeAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showLabelScheme = action.payload;
}

export const setShowTriggerManagementAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showTriggerManagement = action.payload;
}

export const setShowPolarityAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showPolarity = action.payload;
}

export const setDisablePolarityAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.disablePolarity = action.payload;
}

export const setShowCablePresetSnackbarAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showCablePresetSnackbar = action.payload;
}

export const setShowConnectorAssignmentAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showConnectorAssignment = action.payload;
}

export const setShowFiberMappingAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showFiberMapping = action.payload;
}

export const setShowViewProductAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showViewProduct = action.payload;
}

export const setShowMoreMenuAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showMoreMenu = action.payload;
}

export const setShowIdleAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showIdle = action.payload;
}

export const setShowTimeoutAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showTimeout = action.payload;
}

export const setShowAnonymousUserWarningAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showAnonymousUserWarning = action.payload;
}

export function checkForAssignedFibers(assignmentMapping: IConnectorAssignmentMap[]) {
    const fiberMaps = assignmentMapping.flatMap(c => c.fiberMap!);
    const sourceIndices = fiberMaps.flatMap(s => s.sourceIndices);
    const destinationIndices = fiberMaps.flatMap(d => d.destinationIndices);

    const sourceAssigned = !sourceIndices.every(c => c.assignedIndex === AssignedIndexCodes.Unassigned);
    const sourceBlocked = sourceIndices.every(c => c.assignedIndex === AssignedIndexCodes.Blocked);
    const sourceMapped = sourceAssigned && !sourceBlocked;

    const destinationAssigned = !destinationIndices.every(c => c.assignedIndex === AssignedIndexCodes.Unassigned);
    const destinationBlocked = destinationIndices.every(c => c.assignedIndex === AssignedIndexCodes.Blocked);
    const destinationMapped = destinationAssigned && !destinationBlocked;

    return sourceMapped && destinationMapped;
}
