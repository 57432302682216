import React from 'react';

import './Label.scss';

export const Label = (props: { label: string }) => {
    return (
        <div className="label-container">
            <div className="label">
                {props.label}
            </div>
        </div>
    )
}