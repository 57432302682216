import React from 'react';
import { useCreationInfo } from './hooks';

import './CreationInfo.scss';

export const CreationInfo = (props: { projectId: number }) => {
    const { projectId } = props;
    const { creationInfoString } = useCreationInfo(projectId);

    return (
        <div className="creation-info">
            {creationInfoString}
        </div>
    );
}