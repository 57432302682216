import { IConnectorCasingProps } from './types';

export const ConnectorCasing = ({ width, height, fill, filter, opacity }: IConnectorCasingProps) => {
    return (
        <g transform="matrix(1, 0, 0, 1, -9, -1)" filter={filter}>
            <g transform="translate(9 25)">
                <rect width={width} height={height} rx="16" fill={fill} opacity={opacity} stroke="none" />
                <rect x="2" y="2" width={width - 4} height={height - 4} rx="14" fill="none" stroke="#000010" strokeWidth="4px" />
            </g>
        </g>
    );
};