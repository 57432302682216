export const PolarityMTP: React.FC<{ isMTP8: boolean }> = ({ isMTP8 }) => {
    return (
        <svg id={isMTP8 ? "MTP8" : "MTP12"} width={1820} height={1940} fill="none" viewBox="0 0 182 194" xmlns="http://www.w3.org/2000/svg">
            <rect x="56.1824" y="1.00012" width="31.8343" height="192" fill="white" stroke="black" strokeWidth="2"/>
            <rect x="1" y="-1" width="6" height="162" transform="matrix(-1 -2.42845e-10 2.41503e-10 1 95 17.0001)" fill="white" stroke="black" strokeWidth="2"/>
            <rect x="1" y="-1" width="31.8343" height="142" transform="matrix(-1 -2.42845e-10 2.41503e-10 1 57.1934 27.0001)" fill="white" stroke="black" strokeWidth="2"/>
            <rect x="1" y="-1" width="31.8343" height="42" transform="matrix(-1 -2.42845e-10 2.41503e-10 1 57.1934 77.0001)" fill="#BDBDBD" stroke="black" strokeWidth="2"/>
            <rect x="93.9724" y="1.00012" width="82" height="192" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_1" x="171.055" y="11.0001" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_2" x="171.055" y="26.0001" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_3" x="171.055" y="41.0001" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_4" x="171.055" y="56.0001" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_5" x="171.055" y="71.0001" width="7.94475" height="8" fill={isMTP8 ? "#696969" : "#FFFFFF"} stroke="black" strokeWidth="2"/>
            <rect id="pin_6" x="171.055" y="86.0001" width="7.94475" height="8" fill={isMTP8 ? "#696969" : "#FFFFFF"} stroke="black" strokeWidth="2"/>
            <rect id="pin_7" x="171.055" y="101" width="7.94475" height="8" fill={isMTP8 ? "#696969" : "#FFFFFF"} stroke="black" strokeWidth="2"/>
            <rect id="pin_8" x="171.055" y="116" width="7.94475" height="8" fill={isMTP8 ? "#696969" : "#FFFFFF"} stroke="black" strokeWidth="2"/>
            <rect id="pin_9" x="171.055" y="131" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_10" x="171.055" y="146" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_11" x="171.055" y="161" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_12" x="171.055" y="176" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <path d="M167.138 13.8971L159.026 13.8971L159.026 14.7851L159.854 16.9691L160.802 16.7891L160.13 15.0131L167.138 15.0131L167.138 13.8971Z" fill="black"/>
            <path d="M167.138 181.909L159.026 181.909L159.026 182.797L159.854 184.981L160.802 184.801L160.13 183.025L167.138 183.025L167.138 181.909ZM167.138 175.366L166.106 175.366C166.106 176.734 166.118 178.042 166.154 178.774L166.142 178.774L164.87 177.658C163.238 176.218 162.11 175.678 161.09 175.678C159.854 175.678 158.93 176.494 158.93 177.994C158.93 178.798 159.122 179.446 159.398 179.998L160.358 179.83C160.094 179.386 159.89 178.786 159.89 178.258C159.89 177.322 160.478 176.854 161.246 176.854C162.062 176.854 162.95 177.298 164.45 178.606L166.214 180.118L167.138 180.118L167.138 175.366Z" fill="black"/>
        </svg>
        
    )
};

