import { IBuildData } from "../../../../../redux/build/types";
import { IProjectManagerData } from "../../../../../redux/project-manager/types";
import { BuildInfoDTO } from "../../../../../services/types";

export interface IProjectManagerState {
    display: boolean,
    data: IProjectManagerData,
    showDetails: boolean,
    showInfo: boolean,
    recentBuild?: IBuildData,
    selectedBuildId: number,
    selectedBuild?: IBuildData,
}

export interface ISortData {
    type: Sort,
    order: boolean
}

export const Sorts = {
    Name: "name",
    Description: "description",
    DateModified: "date modified",
    Manual: "manual"
} as const;

export type Sort = typeof Sorts[keyof typeof Sorts]

export const initialState: IProjectManagerState = {
    display: false,
    data: {
        sortType: Sorts.DateModified,
        isSortAscending: false,
        projectIds: [],
        builds: []
    },
    showDetails: false,
    showInfo: false,
    selectedBuildId: -1,
}

export const sortProject = <T>(sortType: string, first: T, second: T, selector: (arg: T) => string | undefined, ascending?: boolean) => {
    const a = selector(first);
    const b = selector(second);
    const flip = !ascending ? -1 : 1;
    switch (sortType) {
        case Sorts.DateModified:
            return a?.length && b?.length ? flip * (new Date(b).getTime() - new Date(a).getTime()) : 0
        case Sorts.Description:
        case Sorts.Name:
            return a?.length && b?.length ? flip * (b.toLowerCase().localeCompare(a.toLowerCase())) : 0
        default:
            return 0;
    }
}

export const sortPredicates: { [sort: string]: (first: BuildInfoDTO, second: BuildInfoDTO, ascending: boolean) => number } = {
    [Sorts.DateModified]: (first, second, ascending) => sortProject(Sorts.DateModified, first, second, b => b.lastModified, ascending),
    [Sorts.Name]: (first, second, ascending) => sortProject(Sorts.Name, first, second, b => b.name, ascending),
    [Sorts.Description]: (first, second, ascending) => sortProject(Sorts.Description, first, second, b => b.description, ascending)
}