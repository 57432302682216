import React from 'react';
import { useTriggerColorManagement } from './hooks';
import { CollapsibleDialog } from '../../../../../../../ui/dialog/collapsible/CollapsibleDialog';
import ReactDOM from 'react-dom';
import { ColorDialog } from '../../../../../../../ui/dialog/color/ColorDialog';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { getConnnectorSVG } from '../../../../../../../pixi/factories/Texture';
import { ColorButton } from '../../../../../../../ui/dialog/color/ColorButton';

import './TriggerColorManagement.scss';

export const TriggerColorManagement: React.FC = () => {
    const { dialogProps, colorDialogProps, tableProps, selectedTrigger, onTriggerClick, cancelProps, applyProps } = useTriggerColorManagement();
    if (!dialogProps.display) {
        return null;
    }
    return (
        <CollapsibleDialog {...dialogProps}>
            { ReactDOM.createPortal(<ColorDialog {...colorDialogProps} />, document.body) } 
            <div className="table-container">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            { tableProps.header.map((c, i) => <TableCell key={`column-${i}`} className={c.className} align="right">{c.label}</TableCell>) }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            tableProps.body.map((r, i) =>
                                <TableRow key={`row-${i}`}>
                                    <TableCell align="right">{r.groupPosition}</TableCell>
                                    <TableCell className="connector-cell" align="right">{getConnnectorSVG(r.connectorType, r.color.hex, 0.1)}</TableCell>
                                    <TableCell align="right">
                                        <ColorButton color={r.color} selected={selectedTrigger?.groupPosition === r.groupPosition} onClick={() => onTriggerClick(r)} />
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </div>
            <div className="trigger-footer">
                <Button variant='text' {...cancelProps.buttonProps}>
                    {cancelProps.label}
                </Button>
                <Button variant="contained" {...applyProps.buttonProps}>
                    {applyProps.label}
                </Button>
            </div>
        </CollapsibleDialog>
    )
}