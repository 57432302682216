import React from 'react';
import { DialogContext } from '../../../../../../redux/dialog/reducer';
import { RestrictedInputDialog } from '../../../projects/components/drawer/components/row/generic-components/RestrictedInputDialog';
import { useFiberMappingSaveDialog } from './hooks';
import { FiberMappingSaveDialogProps } from './types';

export const FiberMappingSaveDialog: React.FC<FiberMappingSaveDialogProps> = (props) => {
    const { context } = useFiberMappingSaveDialog(props)
    return (
        <DialogContext.Provider value={context}>
            <RestrictedInputDialog {...props} />
        </DialogContext.Provider>
    )
}