import { Sprite, useApp } from '@inlet/react-pixi';
import {
    MetaSprite,
    MetaSourceTrunkTexture,
    MetaSourceTrunkExternalTexture,
    MetaTAPTrunkTexture,
    MetaWAMTexture,
    MetaTAPVerticalTrunkTexture,
} from '../../factories/Texture';
import { useSelector } from 'react-redux';
import { texturesLoadedSelector } from '../../../workspace/components/overlay/components/header/components/status/redux/selectors';
import { ConnectorSpriteProps } from './components/connectors/types';
import { SpriteProps } from './types';
import { IPointEmpty } from '../../types';

const EDSSprite = (props: { meta: MetaSprite, x?: number, y?: number }) => {
    const { meta, x, y } = props;
    const pos = { x: x ? x : 0, y: y ? y : 0 }
    const app = useApp();
    const { resources } = app.loader;
    const resource = resources[meta.name];
    const textureLoaded = useSelector(texturesLoadedSelector)
    if (!(textureLoaded && resource && resource.texture)) {
        return null;
    }

    return <Sprite name={meta.name} texture={resource.texture} width={meta.width} height={meta.height} {...pos} />;
}

export const FeederSprite = (props: SpriteProps = IPointEmpty) => <EDSSprite meta={{ ...MetaSourceTrunkTexture, name: !props.color ? MetaSourceTrunkTexture.name : `${MetaSourceTrunkTexture.name}_${props.color}` }} {...props} />;
export const FeederExternalFurcationSprite = (props: SpriteProps = IPointEmpty) => <EDSSprite meta={{ ...MetaSourceTrunkExternalTexture, name: !props.color ? MetaSourceTrunkExternalTexture.name : `${MetaSourceTrunkExternalTexture.name}_${props.color}` }} {...props} />;
export const TAPTrunkSprite = (props: SpriteProps = IPointEmpty) => <EDSSprite meta={{ ...MetaTAPTrunkTexture, name: !props.color ? MetaTAPTrunkTexture.name : `${MetaTAPTrunkTexture.name}_${props.color}` }}{...props} />;
export const TAPVerticalTrunkSprite = (props: SpriteProps = IPointEmpty) => <EDSSprite meta={{ ...MetaTAPVerticalTrunkTexture, name: !props.color ? MetaTAPVerticalTrunkTexture.name : `${MetaTAPVerticalTrunkTexture.name}_${props.color}` }} {...props} />;
export const WAMSprite = (props: SpriteProps = IPointEmpty) => <EDSSprite meta={{ ...MetaWAMTexture, name: !props.color ? MetaWAMTexture.name : `${MetaWAMTexture.name}_${props.color}` }} {...props} />;

export const ConnectorSprite = ({ type, width, bootColor, height, color, x, y }: ConnectorSpriteProps) => {
    const app = useApp();
    const { resources } = app.loader;
    const resource = resources[`${type}_${color}_${bootColor}`] === undefined ?  resources[`${type}_${color}`] : resources[`${type}_${color}_${bootColor}`]
    const textureLoaded = useSelector(texturesLoadedSelector)

    if (!(textureLoaded && resource && resource.texture)) {
        return null;
    }

    return <Sprite texture={resource.texture} x={x} y={y} width={width} height={height} />
}
