import { Container } from '@inlet/react-pixi';
import { DocumentDrop } from '../../../../../workspace/redux/document/types'
import { FeederBase } from '../../../build/components/feeder-base/FeederBase';
import { DropBase } from '../../../build/components/drop-base/DropBase';
import { usePreviousDrop } from './hooks';
import { ALength } from '../../../decorators/markers/components/length/a/ALength';

export const PreviousDrop = (props: DocumentDrop) => {
    const { display, aProps, prevTrunk, baseProps } = usePreviousDrop(props);
    if (!display) {
        return null;
    }

    return (
        <Container>
            <ALength {...aProps} />
            { prevTrunk.position === 0 ? <FeederBase {...baseProps} /> : <DropBase {...baseProps} /> }
        </Container>
    )
};