import { IColor } from "../../../../../../../../ui/dialog/color/types";

export interface ITriggerInfo {
    groupPosition: number;
    connectorType: string;
    color: IColor;
}

export interface ITriggerManagementState {
    triggers: ITriggerInfo[];
    selectedTrigger?: ITriggerInfo;
}

export const initialTriggerManagementState: ITriggerManagementState = {
    triggers: []
}