import { createSlice } from "@reduxjs/toolkit";
import { initialStatusState } from "./types";
import { setStatusStateAction, setTextureLoadedAction } from './actions';


const StatusSlice = createSlice({
    name: 'status',
    initialState: initialStatusState,
    reducers: {
        setStatusState: setStatusStateAction,
        setTextureLoaded: setTextureLoadedAction
    }
});

export const StatusReducer = StatusSlice.reducer;
export const { setStatusState, setTextureLoaded } = StatusSlice.actions;

