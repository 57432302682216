import { ProjectManagerService } from "../../services/projectmanager-service";
import { useSelector, useDispatch, batch } from "react-redux";
import { useCallback } from "react";
import { setProjectManager, setRecentProject, setRecentProjectId } from "../../components/overlay/components/projects/redux/reducers";
import { IProjectManagerData } from "./types";
import { useWebLoad } from "../../components/overlay/components/header/components/status/hooks";
import { BuildService } from "../../services/build-service";
import { setUserPolarities } from "../build/connector/polarity/reducer";
import { projectManagerSelector } from "./selectors";
import { setBuilds } from "../build/reducers";
import { updateBuildListEntry } from "../build/reducers";
import { Unit, Units } from "../../components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure";
import { setUnit } from "../../components/overlay/components/header/components/units-of-measure-container/redux/reducers";
import { IBuildData } from "../build/types";

export const useProjectManager = () => {
    const { data: pmData, selectedBuild } = useSelector(projectManagerSelector);
    const dispatch = useDispatch();

    const { dispatchBuild } = useWebLoad();

    // using this callback for initial polarity load
    const getProjectManager = useCallback(async () => {
        new BuildService().getUserBuildPolarityDefinitionsByGroupId().then((polarities) => {
            if (polarities.succesful && polarities.data) {
                dispatch(setUserPolarities(polarities.data));
            }
        });
        
        const { succesful, data } = await new ProjectManagerService().getProjectManager();
        if (succesful && data) {
            batch(() => {
                dispatch(setProjectManager(data));
            })
            return data;
        }
    }, [dispatch]);

    const getProject = useCallback(async (projectId?: number) => {
        let project: IBuildData | undefined;
        if (selectedBuild && selectedBuild.id === projectId) {
            project = selectedBuild;
        } else {
            const { succesful, data } = await new ProjectManagerService().getProject(projectId ?? 0);
            if (succesful && data) {
                dispatch(setRecentProject(data));
                project = data;
            }
        }

        return project;
    }, [selectedBuild, dispatch]);

    const updateRecentProject = useCallback(async (projectId: number) => {
        if (pmData) {
            const { succesful, data } = await new ProjectManagerService().updateRecentProject(projectId);
            if (succesful && data) {
                dispatch(setRecentProjectId(projectId));
                const { build: previousBuild } = data;
                if (previousBuild) {
                    dispatch(updateBuildListEntry(previousBuild));
                }
            }
        }
    }, [pmData, dispatch]);

    const loadProjectManager = useCallback(async () => {
        const projectManagerData: IProjectManagerData | undefined = await getProjectManager();
        if (projectManagerData) {
            const { builds } = projectManagerData;
            if (builds) {
                dispatch(setBuilds(builds));
            }
        }
    }, [dispatch, getProjectManager]);

    const loadRecentProject = useCallback(async (projectId?: number) => {
        const recentBuild: IBuildData | undefined = await getProject(projectId);
        if (recentBuild) {
            dispatch(setUnit(recentBuild.unit as Unit || Units.UoMInches));
            dispatch(updateBuildListEntry(recentBuild));
        }

        await dispatchBuild(recentBuild);
    }, [dispatchBuild, getProject, dispatch]);

    return { updateRecentProject, loadProjectManager, loadRecentProject };
}

