import { createSelector } from "reselect";
import { isPolarityModeSelector } from "../../../../../redux/selectors";
import { disabledUISelector } from "../../../../header/components/status/redux/selectors";
import { workspaceSelector } from "../../../../../../../selectors/root.selectors";

export const toolbarSelector = createSelector(
    workspaceSelector,
    workspace => workspace.toolbar
);

export const editToolbarDisplaySelector = createSelector(
    toolbarSelector,
    toolbar => toolbar.display
);

export const toolbarSelectionSelector = createSelector(
    toolbarSelector,
    toolbar => toolbar.selection
);

export const selectedPositionSelector = createSelector(
    toolbarSelectionSelector,
    selection => selection.selected
);

export const showActionToolbarSelector = createSelector(
    editToolbarDisplaySelector,
    disabledUISelector,
    isPolarityModeSelector,
    (editToolbarDisplay, disabledUI, isPolarityMode) => !editToolbarDisplay && !disabledUI && !isPolarityMode
);