import * as Pixi from 'pixi.js';
import { ComponentName } from '../build/types';

class _CanvasExtractor {
    public static _canvasExtractor: _CanvasExtractor | null = null;

    private app: Pixi.Application;
    private extract: Pixi.Extract;
    private canvasContainer: Pixi.Container | null = null;

    private constructor(app: Pixi.Application) {
        this.app = app;
        this.extract = app.renderer.plugins.extract;
    }

    public getImageElements = (...componentNames: ComponentName[]) => {
        this.setCanvasContainer();

        const imageElements = Object.fromEntries(
            componentNames.map(c => [c, new Array<HTMLCanvasElement>()])
        )

        const displayObjects = this.getDisplayObjects(this.canvasContainer!, 1, ...componentNames)

        componentNames.forEach(componentName => {
            const components = displayObjects[componentName];

            components.map(c => c as Pixi.Container).forEach(component => {
                const imageElement = this.extract.canvas(component);
                imageElement.className = component.name;
                const context = imageElement.getContext('2d');
                if (context) {
                    context.globalCompositeOperation = 'destination-over'
                    context.fillStyle = "white";
                    context.fillRect(0, 0, imageElement.width, imageElement.height);
                }

                imageElements[componentName].push(imageElement)
            })
        });

        return imageElements;
    }

    public extractImages = (displayObjects : Pixi.DisplayObject[]) => {
        const imageElements: HTMLCanvasElement[]= [];
        displayObjects.map(c => c as Pixi.Container).forEach(component => {
            const imageElement = this.extract.canvas(component);
            imageElement.className = component.name;
            const context = imageElement.getContext('2d');
            if (context) {
                context.globalCompositeOperation = 'destination-over'
                context.fillStyle = "white";
                context.fillRect(0, 0, imageElement.width, imageElement.height);
            }

            imageElements.push(imageElement)
        })

        return imageElements;
    }

    private getDisplayObjects = (container: Pixi.Container, levels: number, ...componentNames: ComponentName[]) => {

        const displayList = Object.fromEntries(
            componentNames.map(c => [c, new Array<Pixi.DisplayObject>()])
        )

        let componentName = componentNames.find(c => c === container.name)
        if (componentName) {
            displayList[componentName].push(container);
        }
        if (levels === 0) {
            return displayList;
        }

        for (let i = 0; i < container.children.length; i++) {
            let child = container.children[i];
            let componentName = componentNames.find(c => c === child.name)
            if (componentName) {
                displayList[componentName] = [...displayList[componentName], child];
            }
        }

        if (levels > 1) {
            for (let i = 0; i < container.children.length; i++) {
                let child = container.children[i];
                let childContainer = child as Pixi.Container;
                if (childContainer && childContainer.children.length > 0 && child.name) {
                    let childDisplayList = this.getDisplayObjects(childContainer, levels - 1, ...componentNames);
                    let keys = Object.keys(childDisplayList);
                    keys.forEach(k => {
                        displayList[k] = [...displayList[k], ...childDisplayList[k]]
                    })
                }
            }
        }

        return displayList;
    }

    public getDocumentDisplayGraphics = (...componentNames: ComponentName[]) => {
        this.setCanvasContainer();
        const documentGraphics = this.getDisplayObjects(this.canvasContainer!, 1, ...componentNames)

        return documentGraphics;
    }

    private setCanvasContainer = () => {
        if (!this.canvasContainer) {
            const documentGraphics = this.app.stage.children[1] as Pixi.Container;
            this.canvasContainer = documentGraphics;
        }
    }

    public static Initialize(app: Pixi.Application) {
        if (this._canvasExtractor === null) {
            this._canvasExtractor = new _CanvasExtractor(app);
        }

        return this._canvasExtractor;
    }

    public static GetInstance() {
        return this._canvasExtractor;
    }
}
export const initializeCanvasExtractor = (app: Pixi.Application) => _CanvasExtractor.Initialize(app);

export const CanvasExtractor = _CanvasExtractor.GetInstance()!;
export const pixi2Image = (...componentNames: ComponentName[]) => _CanvasExtractor.GetInstance()!.getImageElements(...componentNames);
export const getPixiDisplayObjects = (...componentNames: ComponentName[]) => _CanvasExtractor.GetInstance()!.getDocumentDisplayGraphics(...componentNames)
export const extractImages = (displayObjects : Pixi.DisplayObject[]) => _CanvasExtractor.GetInstance()!.extractImages(displayObjects)