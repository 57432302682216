import React from 'react';
import { useLabelScheme } from './hooks';
import { CollapsibleDialog } from '../../../../../../../ui/dialog/collapsible/CollapsibleDialog';
import { Template } from './components/template/Template';
import { Button, Checkbox, Divider, FormControlLabel, Tab, Tabs } from '@mui/material';
import { Label } from './components/label/Label';
import { Customization } from './components/customization/Customization';
import { LabelCustomizationContext } from './components/customization/reducers/types';

import './LabelScheme.scss';

export const LabelScheme: React.FC = () => {
    const { dialogProps, schemeProps, exampleProps, customizationContext, applyAllProps, cancelProps, applyProps } = useLabelScheme();
    return (
        <CollapsibleDialog {...dialogProps}>
            <div className="scheme-content">
                <div className="templates-container">
                    <div className='templates-title'>
                        {schemeProps.title}
                    </div>
                    <div className="templates">
                        { 
                            schemeProps.templates.map((t, i) => {
                                return (
                                    <div key={`template-${i}`}>
                                        <Template {...t} />
                                        <Divider />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="examples-container">
                    <Tabs {...exampleProps.tabNavigation}>
                        { exampleProps.tabs.map((t, i) => <Tab key={`example-tab-${i}`} {...t} value={t.value}/>)}
                    </Tabs>
                    <div className="examples-panel">
                        {
                            exampleProps.showLabels ?
                            <div className='labels'>
                                { exampleProps.labels.map((l, i) => <Label key={`example-label-${i}`} label={l} />)}
                            </div> :
                            <LabelCustomizationContext.Provider value={customizationContext}>
                                <Customization />
                            </LabelCustomizationContext.Provider>
                            
                        }
                    </div>
                </div>
            </div>
            <div className="scheme-footer">
                { applyAllProps.display && <FormControlLabel label={applyAllProps.label} control={<Checkbox {...applyAllProps.checkboxProps} />} /> }
                <div className="button-container">
                    <Button variant='text' {...cancelProps.buttonProps}>
                        {cancelProps.label}
                    </Button>
                    <Button variant="contained" {...applyProps.buttonProps}>
                        {applyProps.label}
                    </Button>
                </div>
            </div>
        </CollapsibleDialog>
    )
}