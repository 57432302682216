import CorningLogo from "../overlay/components/header/resources/Corning_Logo_301Blue.svg";
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../locales/types';
import { useReportTables } from './hooks';
import { IsReportReady } from '../overlay/components/header/components/tools/components/export/hooks';
import { BuildPlanTable } from './components/BuildPlanTable';
import { FiberMapTable } from './components/fiber-map/FiberMapTable';
import { ColorChip } from '../../../ui/dialog/color/chip/ColorChip';
import { PolarityDiagrams } from './components/polarity-diagrams/PolarityDiagrams';
import { PolarityConnectors } from "./components/polarity-connectors/PolarityConnectors";

import './OffScreen.scss'

export const OffScreen = () => {
    const { t } = useTranslation();
    const { currentStatus, aLengthTableValues, unitName, feederBLengthTable, dropBLengthTable, dropTable, feederTable, buildPlanTables, colors, fiberColors, diagrams } = useReportTables();
    return (
        <div id="offscreen-container">
            {IsReportReady(currentStatus) &&
                <div>
                    <div id="report-table-container">
                        <table id="cable-trunk-length-table">
                            <thead>
                                <tr>
                                    <th className={"drop"}>Drop</th>
                                    <th className={"section"}>Section</th>
                                    <th className={"ainc"}>A length - incremental ({unitName})</th>
                                    <th className={"acul"}>A length - cumulative ({unitName})</th>
                                    <th className={"comment"}>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {aLengthTableValues.map(a => {
                                    return (<tr key={a.id}>
                                        <td>{a.position}</td>
                                        <td>{a.section}</td>
                                        <td>{a.aLength}</td>
                                        <td>{a.aCumulative}</td>
                                        <td>{"                                                  "}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                        <table id="feeder-connector-table">
                            <thead>
                                <tr>
                                    <th>Connector</th>
                                    <th>Group</th>
                                    <th>Label</th>
                                    <th>Connector Color</th>
                                    <th>B0 length ({unitName})</th>
                                    <th>Connector Type</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {feederBLengthTable.map(f => <tr key={f.connectorId}>
                                    <td>{f.connectorId}</td>
                                    <td>{f.groupNumber}</td>
                                    <td>{f.label}</td>
                                    <td>{f.colorIndex}</td>
                                    <td>{f.b}</td>
                                    <td>{f.connectorType.description}</td>
                                    <td>{f.comment}</td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                        <div id="drop-table-container">
                            {dropBLengthTable.map(d => {
                                const { data } = d;
                                return (
                                    <table key={d.id} id={d.id}>
                                        <thead>
                                            <tr>
                                                <th>Connector</th>
                                                <th>Group</th>
                                                <th>Label</th>
                                                <th>Connector Color</th>
                                                <th>B{d.position} length ({unitName})</th>
                                                <th>Connector Type</th>
                                                <th>Comment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((f, i) => <tr key={`${f.connectorId}${i}`}>
                                                <td>{f.connectorId}</td>
                                                <td>{f.groupNumber}</td>
                                                <td>{f.label}</td>
                                                <td>{f.colorIndex}</td>
                                                <td>{f.b}</td>
                                                <td>{f.connectorType.description}</td>
                                                <td>{f.comment}</td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>)
                            })}
                        </div>
                    </div>
                    <div id="label-tables">
                        <table id="drop-label-table">
                            <thead>
                                <tr>
                                    <th colSpan={6}>{t(LocalizationKeys.AccessPoint)}</th>
                                </tr>
                                <tr>
                                    <th>{t(LocalizationKeys.ManufTapNumber)}</th>
                                    <th>{t(LocalizationKeys.ManufConnNumber)}</th>
                                    <th>{t(LocalizationKeys.Label)}</th>
                                    <th>{t(LocalizationKeys.ReportLenghtBx, { units: unitName })}</th>
                                    <th>{t(LocalizationKeys.ConnType)}</th>
                                    <th>{t(LocalizationKeys.Comment)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dropTable.map((d, i) => {
                                    return <tr id={`${i}`} key={`d${i}`}>
                                        <td>{d.tapPosition}</td>
                                        <td>{d.connectorId}</td>
                                        <td>{d.label}</td>
                                        <td>{d.b}</td>
                                        <td>{d.connectorType.description}</td>
                                        <td>{d.comment}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <table id="feeder-label-table">
                            <thead>
                                <tr>
                                    <th colSpan={6}>{t(LocalizationKeys.Feeder)}</th>
                                </tr>
                                <tr>
                                    <th>{t(LocalizationKeys.ConfigConnNumber)}</th>
                                    <th>{t(LocalizationKeys.ManufConnNumber)}</th>
                                    <th>{t(LocalizationKeys.Label)}</th>
                                    <th>{t(LocalizationKeys.ReportLenghtB0, { units: unitName })}</th>
                                    <th>{t(LocalizationKeys.ConnType)}</th>
                                    <th>{t(LocalizationKeys.Comment)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {feederTable.map((f, i) => {
                                    return <tr id={`${i}`} key={`f${i}`}>
                                        <td>{f.connectorId}</td>
                                        <td>{i + 1}</td>
                                        <td>{f.label}</td>
                                        <td>{f.b}</td>
                                        <td>{f.connectorType.description}</td>
                                        <td>{f.comment}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <BuildPlanTable {...{ buildPlanTables }} />
                    <FiberMapTable />
                </div>
            })
            <img id="corning-secondary-logo" src={CorningLogo} alt={"a corning logo"} width={200}></img>
            <div id="eds-report-images" />
            <PolarityDiagrams {...diagrams} />
            <PolarityConnectors />
            <div id="fiber-position-chip-container">
                { fiberColors.map((c, i) => <ColorChip key={`${i}`} color={c} scale={1} />) }
            </div>
            <div id="connector-chip-container">
                { colors.map((c, i) => <ColorChip key={`${i}`} color={c} scale={1} />) }
            </div>
        </div>)
}