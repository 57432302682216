import { createSelector } from "reselect";
import { FlameRating, IFlameRating } from "../../components/overlay/components/wizard/components/setup/components/flame-rating/types";
import { ConfigurationType, ConnectorTypes, FiberCounts, IConnectorType, IFiberCount, IFiberCountRange } from "../../components/overlay/components/wizard/redux/types";
import { initialEDSSession, IPolaritySessionMap, IRange, IUIConnectorType, SessionCharacteristic, initialConfigSession, initialSessionIds, IConfigSession } from './types';
import { convertTo, Unit, Units } from "../../components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure";
import { BuildPolarity, PolarityConfig, generateConnectorType, GetPolarityMapSessionImgUrl, PolarityMap, SESSION_MAP_KEY, GetPolaritySessionId } from "../build/connector/polarity/types";
import { currentBuildSelector, workspaceSelector } from "../../selectors/root.selectors";
import { getBuildPolarityConfig, polarityMapsToConfig } from "../build/connector/polarity/hooks";
import { unitsOfMeasureContainerUnitSelector } from '../../components/overlay/components/header/components/units-of-measure-container/redux/selectors';
import { polaritySelector, userPolarityMaps } from '../build/connector/polarity/selectors';
import { selectedPresetConfigurationTypeSelector } from "../build/wizard/wizard-preset/selector";
import { toolbarSelectionSelector } from "../../components/overlay/components/footer/components/toolbar/redux/selectors";
import { viewportContextSelector } from "../../../pixi/components/viewport/redux/selectors";
import { ViewportStatus } from "../../../pixi/components/viewport/redux/types";
import { WarningSeverity } from "../../components/overlay/components/header/components/warnings/redux/types";
import { AllColors } from "../../components/overlay/components/polarity/fiber-mapping/connector/templates/types";
import { isMMC, isMTP } from "../../../pixi/factories/Texture";

export const sscSelector = createSelector(
    workspaceSelector,
    workspace => workspace.ssc
)

const sscSessionSelector = createSelector(
    currentBuildSelector,
    selectedPresetConfigurationTypeSelector,
    sscSelector,
    (currentBuild, preset, ssc) => {
        const configType = currentBuild?.configurationType ?? preset
        const defaultConfigSession = Object.values(ssc.configSessions).find(s => s !== undefined)
        const session = ssc.configSessions[configType] || defaultConfigSession
        return session ?? initialConfigSession
    }
)

export const configSessionSelector = createSelector(
    currentBuildSelector,
    selectedPresetConfigurationTypeSelector,
    sscSelector,
    (currentBuild, preset, ssc) => {
        const configType = currentBuild?.configurationType ?? preset;
        const defaultConfigSession = Object.values(ssc.configSessions).find(s => s !== undefined);
        const session = ssc.configSessions[configType] ?? defaultConfigSession;
        return session ?? initialConfigSession;
    }
)

const sscFeederConnectorTypesSelector = createSelector(
    configSessionSelector,
    config => {
        let connectors = config.feederEndConnectorTypes.map(t => uiConnectorToConnectorType(t));
        return config.configurationType === ConfigurationType.Patching ? connectors.filter(c => c.type && !isMMC(c.type)) : connectors;
    }
)

const sscFiberTypeOptionsSelector = createSelector(
    configSessionSelector,
    config => config.fiberTypes
)

export const currentBuildFiberTypeSelector = createSelector(
    currentBuildSelector,
    sscFiberTypeOptionsSelector,
    (currentBuild, fiberTypes) => currentBuild?.fiberType?.length ? currentBuild?.fiberType : fiberTypes[0]?.id
)

export const fiberTypeTrunkColorSelector = createSelector(
    currentBuildFiberTypeSelector,
    (fiberType) => {
        const color = fiberType === "ULTRA" ? "YL" : "AQ";
        const colorhex: string | undefined = AllColors.find(c => c.id === color)?.hex;
        return { id: color, colorHex: colorhex ? colorhex.replace("#", "0x") : colorhex }
    }
)

const sscDistributionConnectorTypesSelector = createSelector(
    configSessionSelector,
    config => {
        let connectors = config.distributionConnectorTypes.map(t => uiConnectorToConnectorType(t));
        return config.configurationType === ConfigurationType.Patching ? connectors.filter(c => c.type && !isMMC(c.type)) : connectors;
    }
)

export const sscConnectorTypeRecordSelector = createSelector(
    sscFeederConnectorTypesSelector,
    sscDistributionConnectorTypesSelector,
    (feeder, distribution) => {
        const connectorTypes = new Array<IConnectorType>()
            .concat(feeder).concat(distribution)
            .reduce((acc, cur) => { acc[cur.type ?? ""] = cur; return acc }, {} as Record<string, IConnectorType>)
        return connectorTypes;
    }
)

export const sscBootColorSelector = (connectorType: string) => createSelector(
    configSessionSelector,
    currentBuildFiberTypeSelector,
    sscConnectorTypeRecordSelector,
    (config, fiberType, connectors) => getSscBootColor(connectorType, config, fiberType, connectors)
)

export const getSscBootColor = (connectorType: string, config: IConfigSession, fiberType: string, connectors: Record<string, IConnectorType>) => {
    const connector = connectors[connectorType]
    const isLC = !isMTP(connectorType)
    const defaultColor = isLC ? "BL" : "BK"
    const color = connector ? config.defaultBootColors[fiberType][connector.key] : defaultColor
    return color;
}

export const sscDefaultTriggerColorSelector = (connectorType?: string) => createSelector(
    configSessionSelector,
    currentBuildFiberTypeSelector,
    sscConnectorTypeRecordSelector,
    (config, fiberType, connectors) => getSscDefaultTriggerColor(connectorType ?? "", config, fiberType, connectors)
)

export const getSscDefaultTriggerColor = (connectorType: string, config: IConfigSession, fiberType: string, connectors: Record<string, IConnectorType>) => {
    const connector = connectors[connectorType ?? ""]
    const color = connector ? config.defaultConnectorColors[fiberType][connector.key].name : "Blue"
    return color;
}

export const sscDefaultTriggersColorSelector = createSelector(
    configSessionSelector,
    currentBuildFiberTypeSelector,
    (config, fiberType) => {
        const colors = config.defaultConnectorColors[fiberType]
        return colors;
    }
)

export const sscConfigSessionSelector = (configType = ConfigurationType.Patching) => {
    return createSelector(
        sscSelector,
        (ssc) => ssc.configSessions[configType] ?? initialEDSSession
    )
}

export const sscAllConfigSessionSelector = createSelector(
    sscSelector,
    (ssc) => ssc.configSessions
)

export const sscCurrentSessionSelector = createSelector(
    currentBuildSelector,
    sscSelector,
    (build, ssc) => ssc.sessionIds[build?.id ?? 0] ?? initialSessionIds[0]
)

export const sscConfigSessionIdSelector = createSelector(
    sscSelector,
    ssc => ssc.configSession.sessionId ?? ''
)

export const sscSessionConfigStatusSelector = createSelector(
    currentBuildSelector,
    sscSelector,
    (build, ssc) => (ssc.sessionIds[build?.id ?? 0]?.configStatus ?? "N") === "Y"
);

export const sscAllWarningsSelector = createSelector(
    sscCurrentSessionSelector,
    (session) => session.warnings
)

export const sscNotificationsSelector = createSelector(
    sscAllWarningsSelector,
    (sscAllWarnings) => {
        return {
            warnings: sscAllWarnings.filter((w) => w.severity === WarningSeverity.Warning),
            errors: sscAllWarnings.filter((w) => w.severity === WarningSeverity.Error)
        }
    }
)

export const sscSessionIdsSelector = createSelector(
    sscSelector,
    (ssc) => ssc.sessionIds,
);

export const sscSessionBusySelector = createSelector(
    sscSelector,
    ssc => ssc.sessionBusy
);

export const sscConfigSessionBusySelector = createSelector(
    sscSelector,
    ssc => ssc.configSessionBusy
);

export const sscConnectorColorsSelector = createSelector(
    configSessionSelector,
    currentBuildFiberTypeSelector,
    (config, fiberType) => config.connectorColors[fiberType] ?? {}
)

export const sscConnectorTypeSelector = (connectorType: string) => {
    return createSelector(
        sscConnectorTypeRecordSelector,
        (records) => records[connectorType]
    )
}

export const sscConfigurationTypeSelector = createSelector(
    configSessionSelector,
    (session) => {
        const {
            configurationType: configurationTypeCode,
            configurationTypes: configurationTypeOptions
        } = session;
        const configurationType = configurationTypeOptions.find((o) => o.key === configurationTypeCode) ?? {
            code: "",
            key: "",
            value: "",
            description: "",
        };
        return { configurationType, configurationTypeOptions };
    }
)

export const sscFiberCountSelector = createSelector(
    sscSessionSelector,
    (session) => {
        let fiberCountOptions: IFiberCount[] = session.fiberCounts.filter(f => f.id.min === f.id.max).map(f => {
            return {
                key: f.id.min.toString(),
                description: f.description,
                count: f.id.min,
            };
        });

        var uniqueFiberCount: { [fiberCount: number]: IFiberCount } = {}
        fiberCountOptions.forEach(fiberCountOption => {
            if (!uniqueFiberCount[fiberCountOption.count]) {
                uniqueFiberCount[fiberCountOption.count] = fiberCountOption;
            }
        });

        fiberCountOptions = Object.values(uniqueFiberCount)


        const fiberCountRanges: IFiberCountRange[] = session.fiberCounts.filter(f => f.id.min < f.id.max).map(f => {
            return {
                min: f.id.min,
                max: f.id.max,
                description: f.description
            };
        });

        let minFiberCount = 0;
        let maxFiberCount = 0;
        if (fiberCountRanges.length) {
            const rangeOptions = FiberCounts.filter(f => fiberCountRanges.some(r => f.count >= r.min && f.count <= r.max) && !fiberCountOptions.some(sscFiberCount => sscFiberCount.count === f.count))
            fiberCountOptions.push(...rangeOptions)
            minFiberCount = fiberCountRanges.length ? fiberCountRanges.map(f => f.min).reduce((a, b) => Math.min(a, b)) : 0
            maxFiberCount = fiberCountRanges.length ? fiberCountRanges.map(f => f.max).reduce((a, b) => Math.max(a, b)) : 0
        }

        fiberCountOptions = fiberCountOptions.sort((a, b) => a.count > b.count ? 1 : -1)
        return { fiberCountOptions, fiberCountRanges, minFiberCount, maxFiberCount }
    }
)

export const sscGripSelector = createSelector(
    sscSessionSelector,
    (session) => {
        const gripOptions = session.grips;
        return { gripOptions }
    }
)

export const sscFlameRatingSelector = createSelector(
    sscSessionSelector,
    (session) => {
        const sessionFlameRatingOptions = session.flameRatings ?? {}
        const objectValues = Object.values(sessionFlameRatingOptions)
        const flameRatingOptions: IFlameRating[] = objectValues.length ? Object.values(sessionFlameRatingOptions).map(c => csticToFlameRating(c)) : []
        return { flameRatingOptions }
    }
)

export const sscSessionPolarityMapsSelector = createSelector(
    sscSessionSelector,
    ssc => {
        const uniqueMaps: { [key: string]: PolarityMap } = {}
        for (let i = 0; i < ssc.polarityMaps.length; i++) {
            const polarityMap = ssc.polarityMaps[i];
            if (!uniqueMaps[polarityMap.polarityId]) uniqueMaps[polarityMap.polarityId] = sessionToPolarityMap(polarityMap)

        }
        return Object.values(uniqueMaps)
    }
)

export const sscSessionPolarityDiagramsSelector = createSelector(
    sscSessionPolarityMapsSelector,
    (sessionMaps) => { 
        return sessionMaps.filter(p => p.imageUri !== undefined).map(p => {
            return { sessionId: GetPolaritySessionId(p.customKey ?? ""), url: p.imageUri }
        })
    }
)

export const sscFeederOptionsSelector = createSelector(
    configSessionSelector,
    sscFeederConnectorTypesSelector,
    sscFiberTypeOptionsSelector,
    unitsOfMeasureContainerUnitSelector,
    (config, connectorTypes, fiberTypes, unit) => {
        const {
            feederEndLegRange,
            feederEndStaggerRange,
            feederEndGroupCountRange,
            feederEndALengthRange,
        } = config;

        return {
            fiberTypes,
            legRange: convertRangeUnit(feederEndLegRange, unit),
            staggerRange: convertRangeUnit(feederEndStaggerRange, unit),
            connectorTypes: connectorTypes,
            groupCounts: feederEndGroupCountRange,
            aLengthRange: convertRangeUnit(feederEndALengthRange, unit)
        };
    }
)

export const sscDistributionOptionsSelector = createSelector(
    configSessionSelector,
    sscDistributionConnectorTypesSelector,
    unitsOfMeasureContainerUnitSelector,
    (config, connectorTypes, unit) => {
        const {
            distributionLegRange,
            distributionStaggerRange,
            distributionGroupCountRange,
            accessPointRange,
            distributionConnectorCountPerGroupRange,
            distributionDropFiberCountRange,
            distributionALengthRange,
        } = config;

        return {
            legRange: convertRangeUnit(distributionLegRange, unit),
            staggerRange: convertRangeUnit(distributionStaggerRange, unit),
            connectorTypes: connectorTypes,
            accessPointRange,
            groupCounts: distributionGroupCountRange,
            connectorsPerGroupCounts: distributionConnectorCountPerGroupRange,
            dropFiberCountRange: distributionDropFiberCountRange,
            aLengthRange: convertRangeUnit(distributionALengthRange, unit),
        };
    }
)

export const sscDistributionOptionsLegRangeSelector = createSelector(
    sscFeederOptionsSelector,
    sscDistributionOptionsSelector,
    toolbarSelectionSelector,
    viewportContextSelector,
    (feeder, distribution, selection, {context}) => {
        return {
            ...distribution,
            aLengthRange: selection.selected <= 1 && context === ViewportStatus.Editing ? feeder.aLengthRange : distribution.aLengthRange
        }
    }
)

export const sscDropOptionsSelector = (position: number) => {
    return createSelector(
        sscFeederOptionsSelector,
        sscDistributionOptionsSelector,
        (feeder, distribution) => position >= 1 ? distribution : feeder
    )
}

export const sscBuildPolaritySelector = createSelector(
    currentBuildSelector,
    userPolarityMaps,
    sscSessionPolarityMapsSelector,
    (currentBuild, userPolarityMaps, sscMaps) => {
        const sortedSscMaps = [...sscMaps].sort((a, b) => a.sourceConnectors.length > b.sourceConnectors.length ? 1 : -1);
        const sortedUserMaps = [...userPolarityMaps].sort((a, b) => a.sourceConnectors.length > b.sourceConnectors.length ? 1 : -1);
        const uniqueSortedMaps = [...sortedSscMaps, ...sortedUserMaps];
        return currentBuild ? getBuildPolarityConfig(currentBuild, uniqueSortedMaps) : []
    }
)

export const sscPolarityConfigSelector = createSelector(
    sscSessionPolarityMapsSelector,
    (polarityList) => polarityMapsToConfig(polarityList)
)

export const buildDefaultPolaritySelector = createSelector(
    polaritySelector,
    (polarity) => polarity.configs ?? []
)

export const sscDefaultBuildPolaritiesSelector = createSelector(
    sscPolarityConfigSelector,
    buildDefaultPolaritySelector,
    (configs, buildConfigs) => {
        const defaultConfigs = configs.filter(config => config.polarityMaps).map(config => {
            const defaultConfig: PolarityConfig = { ...config, polarityMaps: [] }
            if (config.polarityMaps) {
                const defaultPolarityMap = config.polarityMaps.find(m => m.customKey && m.customKey.endsWith("_B") && m.sourceConnectors?.length === 1 && m.destinationConnectors?.length === 1)
                if (defaultPolarityMap) {
                    defaultConfig.polarityMaps = [defaultPolarityMap]
                }
            }

            return defaultConfig
        })

        const defaultBuildPolarities = defaultConfigs.filter(c => c.polarityMaps && c.polarityMaps.length).map(c => {
            const buildPolarity: BuildPolarity = {
                ...c,
                id: 0,
                polarityMap: c.polarityMaps![0]
            }
            const buildDefault = buildConfigs.find(b => b.from?.fiberCount === buildPolarity.from?.fiberCount && b.to?.fiberCount === buildPolarity.to?.fiberCount)

            return buildDefault ?? buildPolarity;
        })

        return defaultBuildPolarities
    }
)

function generatePolarityConnectors(connectorCount: number, terminationCount: number): IConnectorType[] {
    const connectorType = generateConnectorType(terminationCount)
    return new Array(connectorCount).fill({ ...connectorType })
}

function sessionToPolarityMap(sessionMap: IPolaritySessionMap): PolarityMap {
    return {
        customKey: sessionMap.polarityId,
        description: sessionMap.polarityDescription,
        destinationConnectors: generatePolarityConnectors(sessionMap.distributionConnectorCount, sessionMap.distributionTerminationCount),
        sourceConnectors: generatePolarityConnectors(sessionMap.feederConnectorCount, sessionMap.feederTerminationCount),
        key: SESSION_MAP_KEY,
        imageUri: GetPolarityMapSessionImgUrl(sessionMap.polarityId)
    }
}

function convertRangeUnit(range: IRange | undefined, convertUnit: Unit, sscUnit?: Unit) {
    let unit = sscUnit ?? Units.UoMInches;

    const convertedRange: IRange = { min: convertTo({ value: range?.min ?? 0, unit }, convertUnit).value, max: convertTo({ value: range?.max ?? 0, unit }, convertUnit).value }
    return convertedRange;
}

function uiConnectorToConnectorType(ui: IUIConnectorType): IConnectorType {
    const connector = ConnectorTypes.find(c => c.id === ui.id && c.fiberCount === ui.fiberCount);
    return connector ?
        { ...connector, description: ui.description, key: ui.id } : { description: ui.description, fiberCount: ui.fiberCount, pinned: ui.pinned, key: ui.id, type: ui.id }
}

function csticToFlameRating(cstic: SessionCharacteristic<string>): IFlameRating {
    const flameRating: IFlameRating = {
        code: cstic.id,
        key: cstic.id,
        value: cstic.id as FlameRating,
        description: cstic.description,
    }

    return flameRating;
}

