import { IApiResult } from "../services/api-result";
import { apiError } from "./error/reducers";

type ActionType<T> = (dispatch: any) => Promise<T>;
type DispatchType<T> = (action: T) => void;

export const createAsyncAction = <T>(action: ActionType<T>) => (async (dispatch: DispatchType<T>) => action(dispatch));

export const checkResult = (res: IApiResult<any>, dispatch: any) => {
    if (!res.succesful && res.reason !== "canceled") {
        dispatch(apiError(res));
        return false;
    }
    return true;
};

export const extractData = <T>(res: IApiResult<T>, dispatch: any) => {
    if (checkResult(res, dispatch)) {
        return res.data;
    }
    return undefined;
};