import React from "react";
import { IStaggerAutoCompleteProps } from "./types";
import { GenericAutocomplete } from "../../../../../../../../../ui/dropdown/autocomplete/GenericAutocomplete";
import { useStaggerAutocomplete } from "./hooks";

export const StaggerAutocomplete = (props: IStaggerAutoCompleteProps) => {
    const { open, disabled, options, staggerValue, onOpen, onClose, onValueChanged, onInputChanged, label, isInputValid, inputErrorText, onInputKeyDown } = useStaggerAutocomplete(props);
    return (
        <GenericAutocomplete
            open={open}
            disabled={disabled}
            options={options}
            value={staggerValue}
            onOpen={onOpen}
            onClose={onClose}
            onValueChanged={onValueChanged}
            onInputChanged={onInputChanged}
            handleOptionSelected={() => true} // We are handling our own validation through input changed
            label={label}
            isInputValid={isInputValid}
            inputErrorText={inputErrorText}
            onInputKeyDown={onInputKeyDown}
        />
    );
}