import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../../../locales/types';
import { useConnectorAssignmentDialog } from "./hooks"
import { CollapsibleDialog } from "../../../../../../ui/dialog/collapsible/CollapsibleDialog"
import { ConnectorAssignmentContext } from './redux/types';
import { DataTable, DataTableBody, DataTableContent } from '@rmwc/data-table'
import { ConnectorAssignmentRow } from './row/ConnectorAssignmentRow';
import { ToolItem } from '../../../ToolItem';
import { NavigationBar } from '../../../../../../ui/navigation/NavigationBar';
import { GenericDialog } from '../../../../../../ui/dialog/generic-dialog/GenericDialog';
import { CUSTOM_MAP_KEY, getPolarityMapDescription, getPolarityMapPolarityType } from '../../../../../redux/build/connector/polarity/types';
import { Checkbox } from '@orbit/checkbox';
import { Select, MenuItem } from "@mui/material";
import { ContainedButton, TextButton } from '@orbit/button';
import { Propagation } from '../propagation/Propagation';

import '@rmwc/data-table/styles';
import './ConnectorAssignmentDialog.scss';

export const ConnectorAssignmentDialog: React.FC = () => {
    const { t } = useTranslation();
    const { dialogProps, assignmentContext, sourceProps, leftTAPProps, rightTAPProps, resetAllProps, polarityProps, assignmentProps, applyProps, navigationBarProps, selectConnectorsCheckbox, propagate } = useConnectorAssignmentDialog();
    if (!dialogProps.display) {
        return null;
    }
    
    return (
        <CollapsibleDialog {...dialogProps}>
            <div className="horizontal-divider" />
            <ConnectorAssignmentContext.Provider value={assignmentContext}>
                <div className="polarity-container ">
                    <div className={'checkbox-container ' + selectConnectorsCheckbox.className}>
                        <Checkbox
                            palette='primary'
                            onChange={selectConnectorsCheckbox.onCheckBoxChange}
                            checked={selectConnectorsCheckbox.checked}
                            disabled={selectConnectorsCheckbox.disabled}
                            label={selectConnectorsCheckbox.label}/>
                    </div>
                    <div className="actions-container">
                        <Select className={assignmentProps.className} value={assignmentProps.assignment} onChange={assignmentProps.onChange} disabled={assignmentProps.disabled}>
                            {assignmentProps.assignments.map((m, i) => <MenuItem key={i} value={m}>{m}</MenuItem>)}
                        </Select>
                        <Select className={polarityProps.className} value={polarityProps.type ?? CUSTOM_MAP_KEY.toString()} disabled={polarityProps.disabled} onChange={polarityProps.onChange}>
                            {polarityProps.maps.map((m, i) => <MenuItem key={i} value={`${getPolarityMapPolarityType(m)}`}>{getPolarityMapDescription(m, t)}</MenuItem>)}
                        </Select>
                        <ToolItem icon="open_in_new" tooltip={t(LocalizationKeys.PolarityDiagram)} labelBottom={true} onClick={polarityProps.onShowPolarity} />
                        <ContainedButton className="apply-button" disabled={applyProps.disabled} onClick={applyProps.onClick}>
                            {t(LocalizationKeys.Apply)}
                        </ContainedButton>
                    </div>
                </div>
                <div className="assignment-container">
                    <div className="local-container source">
                        <div className="container-header">
                            <div className="container-title">
                                {t(LocalizationKeys.Feeder)}
                            </div>
                        </div>
                        <DataTable className="assignment-table source">
                            <DataTableContent>
                                <DataTableBody>
                                    { sourceProps.rows.map((r) => <ConnectorAssignmentRow key={r.id} {...r} editable={true} />) }
                                </DataTableBody>
                            </DataTableContent>
                        </DataTable>
                    </div>
                    <div className="vertical-divider" />
                    <div className="local-container destination">
                        <div className={`container-header left-destination ${!rightTAPProps.display && "single-tap"}`}>
                            <ToolItem icon="chevron_left" disabled={leftTAPProps.previousDisabled} tooltip={t(LocalizationKeys.Previous)} labelBottom={true} onClick={leftTAPProps.onPreviousClick} />
                            <div className="container-title">
                                {leftTAPProps.title}
                            </div>
                        </div>
                        <DataTable className="assignment-table left-destination">
                            <DataTableContent>
                                <DataTableBody>
                                    { leftTAPProps.rows.map(r => <ConnectorAssignmentRow key={r.id} {...r} editable={false} />) }
                                </DataTableBody>
                            </DataTableContent>
                        </DataTable>
                    </div>
                    <div className="local-container destination">
                        { rightTAPProps.display && 
                            <>
                                <div className="container-header right-destination">
                                    <div className="container-title">
                                        {rightTAPProps.title}
                                    </div>
                                    <ToolItem icon="chevron_right" disabled={rightTAPProps.nextDisabled} tooltip={t(LocalizationKeys.Next)} labelBottom={true} onClick={rightTAPProps.onNextClick} />
                                </div>
                                <DataTable className="assignment-table right-destination">
                                    <DataTableContent>
                                        <DataTableBody>
                                            { rightTAPProps.rows.map(r => <ConnectorAssignmentRow key={r.id} {...r} editable={false} />) }
                                        </DataTableBody>
                                    </DataTableContent>
                                </DataTable>
                            </>
                        }
                    </div>
                </div>
                <div className="button-container">
                    <TextButton palette='error' disabled={resetAllProps.disabled} onClick={resetAllProps.onClick}>
                        {t(LocalizationKeys.ResetAll)}
                    </TextButton>
                    {
                        propagate.display &&
                        <TextButton {...propagate.button}>
                            {propagate.label}
                        </TextButton>
                    }
                </div>
            </ConnectorAssignmentContext.Provider>
            <div className="horizontal-divider" />
            <NavigationBar {...navigationBarProps} />
            <GenericDialog {...resetAllProps.dialogProps} />
            <Propagation />
        </CollapsibleDialog>
    )
}
