import React from 'react';
import { ToolItem } from "../../../../../../../ToolItem";
import { useExpandButton } from "./hooks";
import icon_expand_disabled from '../resources/icons/icon_expand_disabled.svg'
import icon_expand from '../resources/icons/icon_expand.svg'

export const ExpandButton = (props: { tooltip?: string, disabled?: boolean, onClick?: () => void }) => {
    const { disabled, tooltip, onClick } = props;
    const { triggerExpand } = useExpandButton();
    return (
        <ToolItem source={getExpandIcon(disabled)} alt="Expand" tooltip={tooltip ? tooltip : "Expand"} labelTop={true} disabled={disabled} onClick={onClick ? onClick : triggerExpand} />    
    );
}

function getExpandIcon(disabled?: boolean) : string {
    return disabled ? icon_expand_disabled : icon_expand;
}