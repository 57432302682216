import { PayloadAction } from "@reduxjs/toolkit";
import { getPropagationNotification, PropagationNotificationPayload } from "../../polarity/propagation/redux/types";
import { INotificationMessage, INotificationManagerState } from "./types";

export const showNotificationAction = (state: INotificationManagerState, action: PayloadAction<boolean>) => {
    state.display = action.payload;
}

export const setNotificationAction = (state: INotificationManagerState, action: PayloadAction<INotificationMessage>) => {
    state.notification = action.payload;
    state.display = true;
};

export const setPropagationNotificationAction = (state: INotificationManagerState, action: PayloadAction<PropagationNotificationPayload>) => {
    const { status, propagateColors, propagateLabels } = action.payload;
    if (propagateColors || propagateLabels) {
        state.notification = getPropagationNotification(status);
        state.display = true;
    }
}