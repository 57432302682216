import React from 'react';
import { areFurcationEqual, FurcationContainer, IFurcationProps } from './types';
import { Container, Graphics } from "@inlet/react-pixi";
import { useConnectorFurcation } from './hooks';
import { ConnectorHighlight } from '../highlight/ConnectorHighlight';
import { withMemoization } from '../../../../../../memoization/Memoization';

export const ConnectorFurcationGraphics: React.FC<IFurcationProps> = (props) => {
    const { display, highlights, drawFurcation } = useConnectorFurcation(props);
    if (!display) {
        return null;
    }

    return (
        <Container name={FurcationContainer} >
            <Graphics draw={drawFurcation} />
            { highlights.length > 0 ? highlights.map(g=> <ConnectorHighlight key={g.key} {...g} />) : null }
        </Container>
    );
}

export const ConnectorFurcation = withMemoization(ConnectorFurcationGraphics, areFurcationEqual);