import { CSSProperties, useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "../../../../../../../pixi/hooks";
import { extractOwnerFromEmail } from "../../../../../../redux/build/types";
import { projectManagerSelector } from "../../../../../../redux/project-manager/selectors";
import { PAGE_SIZE } from "../../../../../../redux/project-manager/types";
import { AppDispatch } from "../../../../../../redux/reducers";
import { buildCountSelector, buildInfoListSelectorFactory, buildsPageIndexSelector } from "../../../../../../selectors/build.selectors";
import { currentBuildSelector } from "../../../../../../selectors/root.selectors";
import { userIdSelector } from "../../../../../authentication/redux/selectors";
import { closeAll, getProjectsPage } from "../../redux/reducers";
import { ProjectDrawerReducer, setLoadingMore, setReachedLastPage } from "./redux/reducers";
import { initialProjectDrawerState, IProjectDrawerContext } from "./redux/types";

const SCROLL_BUFFER = 50;
const DESIGN_BUFFER = 30;

export const useProjectDrawer = () => {
    const userId = useSelector(userIdSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const pageIndex = useSelector(buildsPageIndexSelector);
    const buildCount = useSelector(buildCountSelector);
    const { display: open } = useSelector(projectManagerSelector);
    const [state, dispatch] = useReducer(ProjectDrawerReducer, initialProjectDrawerState);
    const { search, reachedLastPage, loadingMore, reloading } = state;
    const context: IProjectDrawerContext = { state, dispatch };
    const storeDispatch = useDispatch<AppDispatch>();
    const containerRef = useRef<HTMLDivElement>(null);
    const buildInfoList = useSelector(buildInfoListSelectorFactory(extractOwnerFromEmail(currentBuild?.ownerEmail ?? "").includes(search)));

    useEffect(() => {
        if (open) {
            dispatch(setReachedLastPage(false));
        }
    }, [open]);

    const projects = useMemo(() => {
        return buildInfoList.map(build => {
            const buildUserId = build ? build.userId : -1;
            const buildLockedById = build && build.lockedById ? build.lockedById : -1;
            const editDisabled = buildLockedById !== -1 ? buildLockedById !== userId || !!build.catalogCode : !!build.catalogCode;
            const deleteDisabled = (userId !== -1 ? userId !== buildUserId : true) || editDisabled;
            const rowClassName = currentBuild?.id === build.buildId ? "row selected" : "row";
            return { projectId: build.buildId ?? 0, selected: currentBuild?.id === build.buildId, deleteDisabled, editDisabled, rowClassName };
        })
    }, [buildInfoList, userId, currentBuild]);

    const loading = loadingMore || reloading;

    const getNextPage = useCallback(async () => {
        if (loading) return;
        dispatch(setLoadingMore(true));
        // If last page is a full page, fetch the next page, otherwise fetch the current page
        const pageToFetch = buildCount % PAGE_SIZE === 0 ? pageIndex + 1 : pageIndex;
        const isLastPage = await storeDispatch(getProjectsPage(pageToFetch, search));
        batch(() => {
            dispatch(setReachedLastPage(isLastPage));
            dispatch(setLoadingMore(false));
        });
    }, [loading, buildCount, pageIndex, storeDispatch, search]);

    const onScroll = useCallback(async () => {
        const divElement = containerRef.current;
        if (divElement) {
            const fixedHeight = (divElement.scrollHeight - divElement.scrollTop).toFixed(0);
            const fixedBottom = (divElement.clientHeight + SCROLL_BUFFER).toFixed(0);
            const isBottom = Number(fixedHeight) <= Number(fixedBottom);
            if (isBottom && !reachedLastPage) {
                await getNextPage();
            }
        }
    }, [reachedLastPage, getNextPage]);

    const onDrawerClose = useCallback(() => {
        if (open) {
            storeDispatch(closeAll());
        }
    }, [open, storeDispatch]);

    const { height } = useWindowSize();
    const drawerHeight = height + DESIGN_BUFFER;
    const drawerStyle: CSSProperties = { height: `${drawerHeight}px` };

    return { open, projects, loading, reachedLastPage, reloading, drawerStyle, context, containerRef, onDrawerClose, onScroll };
}