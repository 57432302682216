import React from 'react';
import { Header } from './components/header/Header';
import { UnitsOfMeasureContainer } from './components/header/components/units-of-measure-container/UnitsOfMeasureContainer';
import { Polarity } from './components/polarity/Polarity';
import { Wizard } from './components/wizard/Wizard';
import { ProjectDrawer } from './components/projects/components/drawer/ProjectDrawer';
import { CableDetails } from './components/projects/components/drawer/components/cable-details/CableDetails';
import { CableInfo } from './components/projects/components/drawer/components/cable-info/CableInfo';
import { CablePresetSnackbar } from './components/cable-detail-presets/snackbar/CablePresetSnackbar';
import { Footer } from './components/footer/Footer';
import { Reports } from './components/reports/Reports';
import { AboutThisApplication } from './components/about-application/AboutThisApplication';
import { Warnings } from './components/header/components/warnings/Warnings';
import { ViewProductDialog } from './components/header/components/view-product/ViewProductDialog';
import { MoreMenuContainer } from './components/header/components/more-menu-container/MoreMenuContainer';
import { TimeoutDialog } from './components/time-out/TimeoutDialog';
import { AnonymousUserWarningDialog } from './components/header/components/view-product/anonymous-user-warning-dialog/AnonymousUserWarningDialog';
import { ActivityTracker } from '../../tracker/ActivityTracker';
import { IdleDialog } from './components/idle/IdleDialog';
import { useWebWorkspace } from './hooks';
import { NotificationSnackbar } from './components/notification/snackbar/notification/NotificationSnackbar';

const Overlay: React.FC = () => {
    useWebWorkspace();
    return (
        <div className='overlay'>
            <ActivityTracker />
            <Header />
            <UnitsOfMeasureContainer />
            <Warnings />
            <Reports />
            <AboutThisApplication />
            <AnonymousUserWarningDialog />
            <ViewProductDialog />
            <MoreMenuContainer />
            <IdleDialog />
            <TimeoutDialog />
            <Polarity />
            <Wizard />
            <ProjectDrawer />
            <CableDetails />
            <CableInfo />
            <CablePresetSnackbar />
            <NotificationSnackbar />
            <Footer />
        </div>
    );
}

export default Overlay;
