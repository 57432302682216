import { createContext, Dispatch } from 'react';
import { AnyAction } from 'redux';

export interface ILabelCustomizationState {
    rackNumber: number;
    reverseOrder: boolean;
};

export const initialLabelCustomizationState: ILabelCustomizationState = {
    rackNumber: 1,
    reverseOrder: false
};

export interface ILabelCustomizationContext {
    state: ILabelCustomizationState;
    dispatch: Dispatch<AnyAction>;
};

export const initialLabelCustomizationContext: ILabelCustomizationContext = {
    state: initialLabelCustomizationState,
    dispatch: () => {}
};

export const LabelCustomizationContext = createContext(initialLabelCustomizationContext);