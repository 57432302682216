import { AlertPalettes } from "@orbit/snackbar";
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../../../locales/types";
import { setResize, setViewportContext } from "../../../../../../../pixi/components/viewport/redux/reducers";
import { setBuildLoaded, setCurrentBuild, updateBuild, updateBuildInfo } from "../../../../../../redux/build/reducers";
import { IBuildData, IEnableData } from "../../../../../../redux/build/types";
import { AppDispatch } from "../../../../../../redux/reducers";
import { useSscBuildSession } from "../../../../../../redux/ssc/hooks";
import { buildStateSelector } from "../../../../../../selectors/root.selectors";
import { UserService } from '../../../../../../services/user-service';
import { setUserId } from "../../../../../authentication/redux/reducers";
import { authenticationSelector } from '../../../../../authentication/redux/selectors';
import { PageResizeEnum } from "../../../footer/components/resize/types";
import { setNotification } from "../../../notification/store/reducer";
import { getDefaultBuildDescription } from "../../../projects/components/drawer/components/row/build-info/hooks";
import { restartWizard, setWizardDisplay } from "../../../wizard/redux/reducers";
import { unitsOfMeasureContainerUnitSelector } from '../units-of-measure-container/redux/selectors';
import { setStatusState } from "./redux/reducer";
import { currentStatusSelector } from "./redux/selectors";
import { WorkspaceStatus } from "./redux/types";
import { selectedPositionSelector } from "../../../footer/components/toolbar/redux/selectors";

export const createdLoadedBuild = (build: IBuildData) => {
    const availability: IEnableData = {
        sourceEnabled: true,
        enabledDestinations: build.destinations.map(d => d.position)
    }
    const loadedBuild: IBuildData = {
        ...build,
        availability,
    }

    return loadedBuild;
}

export const useWorkspace = () => {
    const dispatch = useDispatch();
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);

    const setWorkspace = useCallback((build?: IBuildData) => {
        if (build) {
            const loadedBuild = { ...createdLoadedBuild(build), unit };
            batch(() => {
                dispatch(setCurrentBuild(loadedBuild));
                dispatch(setWizardDisplay(false));
                dispatch(setViewportContext("active"))
            });
        }
        else {
            batch(() => {
                dispatch(restartWizard());
                dispatch(setWizardDisplay(true));
                dispatch(setViewportContext("inactive"));
                dispatch(setResize({ update: true, value: PageResizeEnum.FitToSource }));
            });
        }
    }, [dispatch, unit]);

    return { setWorkspace };
}

export const useWebLoad = () => {
    const { userId, customer } = useSelector(authenticationSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { setWorkspace } = useWorkspace();
    const { updateBuildSession } = useSscBuildSession();

    const dispatchBuild = useCallback(async (build: IBuildData | undefined, skipSscUpdate = false) => {
        dispatch(setStatusState(WorkspaceStatus.Ready));
        setWorkspace(build);
        dispatch(setBuildLoaded(true));
        if (build) {
            let currentUserId = userId;
            if (customer && !userId) {
                const res = await new UserService().getUserId();
                if (res.succesful && res.data) {
                    currentUserId = res.data;
                    dispatch(setUserId(currentUserId));
                }
            }
            if (build.lockedById !== -1 && build.lockedById !== currentUserId) {
                dispatch(setStatusState(WorkspaceStatus.Locked));
                const message = build.lockedByFullName ? t(LocalizationKeys.CableLockedWarning, { fullName: build.lockedByFullName }) : t(LocalizationKeys.Unknown);
                dispatch(setNotification({ message, palette: AlertPalettes.info }))
            }

            dispatch(setResize({ update: true, value: PageResizeEnum.FitToSource }));
            if (!skipSscUpdate) {
                updateBuildSession(build, false, true, false);
            }
        }
    }, [userId, customer, t, dispatch, updateBuildSession, setWorkspace]);

    return { setWorkspace, dispatchBuild }
}

export const useWebSave = () => {
    const { currentBuild, builds } = useSelector(buildStateSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const selection = useSelector(selectedPositionSelector)
    const { updateBuildSession, updateDropMaterials } = useSscBuildSession();
    const dispatch = useDispatch<AppDispatch>();

    const saveBuild = useCallback(async (projectId?: number, skipSscUpdate: boolean = false) => {
        if (projectId) { // We are trying to save a specific build
            let build = builds.find(x => x.buildId === projectId);
            if (build) {
                dispatch(updateBuildInfo(build));
            }
        } 
        if (!currentBuild || skipSscUpdate) return;
        if (currentBuild.sessionId && selection > -1){
            dispatch(updateBuild(currentBuild));
            await updateDropMaterials(currentBuild, selection)
        }
        else if (currentBuild.sessionId) {
            dispatch(updateBuild(currentBuild));
            await updateBuildSession(currentBuild);
        }
        else { // We will make sure to push the build to the database
            const build = { ...currentBuild, unit, description: getDefaultBuildDescription(currentBuild) };
            await updateBuildSession(build, true, true);
        }

    }, [currentBuild, unit, dispatch, builds, updateBuildSession, selection, updateDropMaterials]);

    return { saveBuild, currentBuild };
}

export const useStatusIcon = () => {
    const currentStatus = useSelector(currentStatusSelector);
    const [className, setClassName] = useState("");

    useEffect(() => {
        if (currentStatus === WorkspaceStatus.Locked) {
            setClassName("status locked");
        }

        if (currentStatus === WorkspaceStatus.Loading || currentStatus === WorkspaceStatus.Saving || currentStatus === WorkspaceStatus.Busy || currentStatus === WorkspaceStatus.InitialLoad) {
            setClassName('status fadeloop');
        }

        if (currentStatus === WorkspaceStatus.Ready || currentStatus === WorkspaceStatus.Saved) {
            setClassName('status fadeout');
        }
    }, [currentStatus]);

    return { currentStatus, className }
}

