import { IPointEmpty } from "../../../../../types";
import { defaultStyle, IPath } from "../../types";

export interface ILineMarkerProps {
    key?: string;
    orientation?: Orientation,
    direction?: Direction,
    path: IPath;
    textGap?: number,
    thickness: number,
    color: number,
}

export const Directions = {
    Start: "start",
    End: "end",
    Bidirectional: "bidirectional"
} as const;

export type Direction = typeof Directions[keyof typeof Directions];

export const Orientations = {
    Horizontal: 'horizontal',
    Vertical: 'vertical'
} as const;

export type Orientation = typeof Orientations[keyof typeof Orientations]

export const Sides = {
    Left: 'left',
    Top: 'top',
    Right: 'right',
    Bottom: 'bottom'
} as const;

export type Side = typeof Sides[keyof typeof Sides]

export const initialState: ILineMarkerProps = {
    path: { start: IPointEmpty, end: IPointEmpty },
    orientation: Orientations.Vertical,
    ...defaultStyle
}