import { OktaAuth } from '@okta/okta-auth-js';
import { OktaConfig } from './okta.config';

export class Okta {

    private static async createOktaAuth(): Promise<any> {
        const config = await OktaConfig.getConfig();
        return new OktaAuth({
            issuer: config.issuer,
            clientId: config.clientId,
            redirectUri: config.loginRedirect,
            pkce: false,
        });
    }
    public static async loginWithRedirect(): Promise<void> {
        const config = await OktaConfig.getConfig();
        if(!config.enabled) {
            return;
        }
        const auth = await this.createOktaAuth();
        await auth.token.getWithRedirect({ responseType: [ 'id_token' ], scopes: config.scopes });
    }

    public static async logoutWithRedirect(): Promise<void> {
        const config = await OktaConfig.getConfig();
        if(!config.enabled) {
            return;
        }
        const token = localStorage.getItem('okta-token');
        localStorage.removeItem('okta-token');
        if (!token) {
            console.error('Unable to logout from Okta without Okta token');
            alert('Unable to logout from Okta without Okta token');
        }
        else {
            window.location.href = `${config.issuer}/oauth2/v1/logout?id_token_hint=${token}&post_logout_redirect_uri=${config.logoutRedirect}`;
        }
    }

    public static async getAndSaveTokenFromUrl(): Promise<string | null> {
        const auth = await this.createOktaAuth();
        try {
            const token = await auth.token.parseFromUrl();
            auth.tokenManager.setTokens(token.tokens);
            const idToken = token.tokens.idToken.idToken;
            localStorage.setItem('okta-token', idToken);
            return idToken;
        }
        catch (e) {
            return null;
        }
    }

    public static async renewToken(): Promise<string | null> {
        const auth = await this.createOktaAuth();
        try {
            const renewedToken = await auth.tokenManager.renew('idToken');
            localStorage.setItem('okta-token', renewedToken.idToken);
            return renewedToken.idToken;
        }
        catch (e) {
            return null;
        }
    }
}
