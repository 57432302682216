import { Dialog } from "@orbit/dialog";
import { useThirdPartyCopyrightDialog } from "./hooks"
import { DialogHeader } from "../../../../../../ui/dialog/header/DialogHeader";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../locales/types";

import "./ThirdPartyCopyrightDialog.scss"

export const ThirdPartyCopyrightDialog = () => {
    const { t } = useTranslation();
    const { showThirdPartyCopyright, licenses, dialogHeaderProps, closeDialog } = useThirdPartyCopyrightDialog();

    return (
        showThirdPartyCopyright ?
            <div id="third-party-copyright-container">
                <Dialog
                    open={showThirdPartyCopyright}
                    onClose={closeDialog}
                    preventOutsideDismiss={true}
                    className="third-party-copyright">
                    <DialogHeader {...dialogHeaderProps} />
                    <div className="third-party-copyright-content">
                        <Table>
                            <TableHead>
                                <TableRow className="table-header-font">
                                    <TableCell align="left">{t(LocalizationKeys.Components)}</TableCell>
                                    <TableCell align="left">{t(LocalizationKeys.Version)}</TableCell>
                                    <TableCell align="left">{t(LocalizationKeys.Disclaimer)}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {licenses.map(x => {
                                    return (
                                        <TableRow key={x.key}>
                                            <TableCell className="component-name-font" align="left">
                                                <a href={x.link}>{x.components}</a>
                                            </TableCell>
                                            <TableCell align="left">{x.version}</TableCell>
                                            <TableCell align="left">{x.disclaimer}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </Dialog>
            </div> : null
    )
}