import React from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../locales/types";
import { useFiberMapTable } from "./hooks";
import { FiberMapRow } from "./FiberMapRow";

export const FiberMapTable: React.FC = () => {
    const { t } = useTranslation();
    const { rows } = useFiberMapTable();
    return (
        <div id="fiber-map-table-container">
            <table id="fiber-map-table">
                <thead>
                    <tr>
                        <th className="source-location">{t(LocalizationKeys.Location)}</th>
                        <th className="source-connector-index">Conn. #</th>
                        <th className="source-connector-type">{t(LocalizationKeys.Type)}</th>
                        <th className="source-row">{t(LocalizationKeys.Row)}</th>
                        <th className="source-position">{t(LocalizationKeys.Position)}</th>
                        <th className="fiber-index">{t(LocalizationKeys.FiberNumber, { fiberIndex: "#" })}</th>
                        <th className="drop-location">{t(LocalizationKeys.Location)}</th>
                        <th className="drop-connector-index">Conn. #</th>
                        <th className="drop-connector-type">{t(LocalizationKeys.Type)}</th>
                        <th className="drop-row">{t(LocalizationKeys.Row)}</th>
                        <th className="drop-position">{t(LocalizationKeys.Position)}</th>
                    </tr>
                </thead>
                <tbody>
                    { rows.map((row, i) => <FiberMapRow key={`row${i}`} sourceFiber={row.sourceFiber} destinationFiber={row.destinationFiber} fiberIndex={i + 1} />) }
                </tbody>
            </table>
        </div>
    )
}