import React from 'react';
import { IGenericDialogProps } from './types';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../locales/types';
import { Dialog } from '@orbit/dialog';
import { ToolItem } from '../../../workspace/components/overlay/ToolItem';
import { ContainedButton, TextButton } from '@orbit/button';

import './GenericDialog.scss';

export const GenericDialog : React.FC<IGenericDialogProps> = ({ id, display, title, message, onClose, confirmText, cancelText, confirmDisabled, onConfirm, onCancel, critical, closable }) => {
    const { t } = useTranslation();
    const titleClassName = critical ? "generic-dialog-title critical" : "generic-dialog-title";
    const cancelClassName = critical ? "generic-dialog-cancel-button critical" : "generic-dialog-cancel-button";
    const confirmClassName = "generic-dialog-confirm-button";
    const confirmPalette = critical ? "error" : "primary"

    return display ?
            <Dialog id={id} className="generic-dialog-container" open={display} onClose={onClose} preventOutsideDismiss={true}>
                { 
                    title ?
                    <div className="generic-dialog-header">
                        <div className={titleClassName} >{title}</div>
                        {closable ? <ToolItem icon={"close"} tooltip={t(LocalizationKeys.Close)} labelBottom={true} onClick={onClose} /> : null}
                    </div> : null
                }
                <div className="generic-dialog-body">
                    <div className="generic-dialog-message">{message}</div>
                </div>
                <div className="generic-dialog-footer">
                    <div className="generic-dialog-button-container">
                        { closable && <TextButton className={cancelClassName} onClick={onCancel ?? onClose}>{cancelText ?? t(LocalizationKeys.Cancel)}</TextButton> }
                        <ContainedButton disabled={confirmDisabled} palette={confirmPalette} className={confirmClassName} onClick={!confirmDisabled ? onConfirm : undefined}>{confirmText ?? t(LocalizationKeys.Confirm)}</ContainedButton>
                    </div>
                </div>
            </Dialog> : 
            null;
}