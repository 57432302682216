import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setShowAnonymousUserWarning, setShowPrintDialog, setShowViewProduct } from '../../../../../redux/reducers';
import { showAnonymousUserWarningSelector } from '../../../../../redux/selectors';
import { LocalizationKeys } from '../../../../../../../../locales/types';
import { AppDispatch } from '../../../../../../../redux/reducers';

export const useAnonymousUserWarningDialog = () => {
    const showAnonymousUserWarning = useSelector(showAnonymousUserWarningSelector);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const closeDialog = useCallback((): void => {
        dispatch(setShowAnonymousUserWarning(false));
    }, [dispatch]);

    const showPrintDialog = useCallback((): void => {
        closeDialog();
        dispatch(setShowPrintDialog(true));
    }, [closeDialog, dispatch]);

    const showViewProductDialog = useCallback((): void => {
        closeDialog();
        dispatch(setShowViewProduct(true));
    }, [closeDialog, dispatch]);

    return {
        display: showAnonymousUserWarning,
        title: t(LocalizationKeys.ViewProduct),
        message: t(LocalizationKeys.AnonymousUserWarning),
        alternateText: t(LocalizationKeys.Print),
        confirmText: t(LocalizationKeys.Continue),
        closeDialog,
        showPrintDialog,
        showViewProductDialog,
    }
};
