import { UserWizardPreset, WizardPreset } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";

export const setUserWizardPresetAction = (state: UserWizardPreset, action: PayloadAction<UserWizardPreset>) => {
    const { id, selectedPreset, wizardPresets } = action.payload;
    state.id = id
    state.selectedPreset = selectedPreset;
    state.wizardPresets = wizardPresets;
}

export const setSelectedWizardPresetAction = (state: UserWizardPreset, action: PayloadAction<WizardPreset>) => {
    state.selectedPreset = action.payload;
}

export const removeWizardPresetAction = (state: UserWizardPreset, action: PayloadAction<WizardPreset>) => {
    const { id } = action.payload;
    if (state.selectedPreset! === id) {
        state.selectedPreset = undefined;
    }
    state.wizardPresets = state.wizardPresets!.filter(w => w.id !== id);;
}

export const addWizardPresetAction = (state: UserWizardPreset, action: PayloadAction<WizardPreset>) => {
    if (state.wizardPresets) {
        state.wizardPresets = state.wizardPresets.filter(p => p.configurationType !== action.payload.configurationType)
        state.wizardPresets.push(action.payload);
    }
}