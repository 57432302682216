import { PropsWithChildren } from "react";
import { getConnectorTypeString, isLCUniboot, isMTP, isMMC, isMMC16 } from "../../../../../pixi/factories/Texture";
import { CustomFiberMapData, IFiberMapIndex } from "../../../../redux/build/connector/polarity/fiber-map/types";
import { getFiberMapDataConnectorTypes } from "../../../../redux/build/connector/polarity/selectors";
import { MMCFiberColors, FiberColors } from "../../../overlay/components/polarity/fiber-mapping/connector/templates/types";
import { getConnectorType, getConnectorFamilyString } from "../../../overlay/components/wizard/redux/types";
import { IPoint } from "../../../../../pixi/types";

export const LINE_WIDTH = 3;
export const MARGIN = 100;

export interface IPolarityDiagramsProps {
    diagrams: IPolarityDiagram[];
};

export interface IPolarityDiagram {
    sessionId: string;
    url?: string;
};

export interface IPolaritySVGData {
    type: string;
    fiberCount: number;
    svg: SVGElement;
};

export const arePolarityDiagramsEqual = (prev: PropsWithChildren<IPolarityDiagramsProps>, next: PropsWithChildren<IPolarityDiagramsProps>) => {
    return prev.diagrams.length === next.diagrams.length;
};

export const generate = async (fiberMaps: CustomFiberMapData[]) => {
    const diagrams: IPolarityDiagram[] = [];
    for (const fM of fiberMaps) {
        const url = await getFiberMapImageUrl(fM);
        if (url) {
            diagrams.push({ sessionId: `${fM.id}`, url })
        }
    }

    return diagrams;
};

export const getFiberMapImageUrl = async (fiberMap: CustomFiberMapData) => {
    const sources =  getFiberMapDataConnectorTypes(fiberMap.sourceIndices);
    const destinations = getFiberMapDataConnectorTypes(fiberMap.destinationIndices);

    const has24FSources = sources.some(d => d.fiberCount === 24);
    const has24FDestinations = destinations.some(d => d.fiberCount === 24);

    if (has24FSources || has24FDestinations) return undefined;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high";

        // Fetch source connectors svg
        const sourceImages: IPolaritySVGData[] =[];
        for (const { type: connectorType, fiberCount } of sources) {
            const type = getConnectorTypeString(connectorType ?? "");
            const svg = getPolarityConnectorSVG(type, fiberCount);
            if (svg) {
                sourceImages.push({ type, svg, fiberCount });
            }
        };

        // Fetch destination connectors svg
        const destinationImages: IPolaritySVGData[] = [];
        for (const { type: connectorType, fiberCount } of destinations) {
            const type = getConnectorTypeString(connectorType ?? "");
            const svg = getPolarityConnectorSVG(type, fiberCount);
            if (svg) {
                destinationImages.push({ type, fiberCount, svg });
            }
        };

        const sourceHeight = sourceImages.map(s => s.svg.clientHeight).reduce((a, b) => (a + b) + MARGIN);
        const destinationHeight = destinationImages.map(d => d.svg.clientHeight).reduce((a, b) => (a + b) + MARGIN);

        const sourceWidth = Math.max(...sourceImages.map(s => s.svg.clientWidth));
        const destinationWidth = Math.max(...destinationImages.map(d => d.svg.clientWidth));

        canvas.height = Math.max(sourceHeight, destinationHeight);
        canvas.width = sourceWidth * 2 + destinationWidth;

        // draw source images
        const sourcePins: IPoint[] = [];
        for (let i = 0; i < sourceImages.length; i++) {        
            const { type, svg, fiberCount } = sourceImages[i];

            const image = await getImage(svg.outerHTML);
            if (image) {
                const y = (canvas.height - sourceHeight) * 0.5 + (MARGIN + image.height) * i;
                const rotate = isLCUniboot(type);
                const position: IPoint = rotate ? { x: image.width, y: y + image.height } : { x: 0, y };

                const start: IPoint = { x: image.width, y };
                const pins: IPoint[] = getPinPositions(svg, fiberCount, start);
                sourcePins.push(...pins);
                drawImage(ctx, position, image, rotate);
            }
        }

        // draw destination images
        const destinationPins: IPoint[] = [];
        for (let i = 0; i < destinationImages.length; i++) {
            const { type, svg, fiberCount } = destinationImages[i];

            const image = await getImage(svg.outerHTML);
            if (image) {
                const y = (canvas.height - destinationHeight) * 0.5 + (MARGIN + image.height) * i;
                const rotate = isMTP(type) || isMMC(type);
                const position: IPoint = rotate ? { x: canvas.width, y: y + image.height } : { x: canvas.width - image.width, y };

                const start: IPoint = { x: canvas.width - image.width, y };
                const pins: IPoint[] = getPinPositions(svg, fiberCount, start, true);
                destinationPins.push(...pins);
                drawImage(ctx, position, image, rotate);
            }
        }

        // draw pin connections
        for (const { index, assignedIndex } of fiberMap.destinationIndices) {
            if (assignedIndex >= 0) {
                const sourceType = fiberMap.sourceIndices[assignedIndex].connectorType ?? ""
                const sourceFibercount = getConnectorType(sourceType).fiberCount;
                const sourceTypeString = getConnectorTypeString(sourceType);
                const sourceIndex = isLCUniboot(sourceTypeString) ? sourcePins.length - 1 - assignedIndex : assignedIndex;
                const source = sourcePins[sourceIndex];

                const destination = destinationPins[index];
                const fibercount = sourceFibercount === 8 ? 12 : sourceFibercount;
                const colorIndex = assignedIndex % fibercount;
                const color = isMMC16(sourceType) ? MMCFiberColors[colorIndex].hex : FiberColors[colorIndex].hex;
                drawConnection(ctx, color, source, destination);
            }
        }

        drawBlockedPins(ctx, sourcePins, fiberMap.sourceIndices);
        drawBlockedPins(ctx, destinationPins, fiberMap.destinationIndices);

        return canvas.toDataURL();
    }
};

const getPolarityConnectorSVG = (type: string, fibercount: number) : SVGElement | null => {
    const offscreen = document.querySelector("#offscreen-container") as HTMLDivElement;
    const polarity = offscreen.querySelector("#polarity-connectors-container") as HTMLDivElement;
    return polarity.querySelector<SVGElement>(`#${getConnectorFamilyString(type, fibercount)}`);
};

const getPinPositions = (svg: SVGElement, fibercount: number, start: IPoint, isDestination: boolean = false) => {
    const pins: IPoint[] = [];
    const count = fibercount === 8 ? 12 : fibercount;
    for (let i = 0; i < count; i++) {
        const pin = svg.querySelector(`#pin_${i + 1}`);
        if (pin) {
            const svgRect = svg.getBoundingClientRect();
            const pinRect = pin.getBoundingClientRect();
            const delta = pinRect.y - svgRect.y + pinRect.height * 0.5;
            const offsetX = isDestination ? pinRect.height * 0.5 : -pinRect.height * 0.5;
            pins.push({ x: start.x + offsetX, y: start.y + delta });
        }
    }

    return pins;
};

const getImage = async (SVGString: string) : Promise<HTMLImageElement | undefined> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        const encodedSVG = encodeURIComponent(SVGString);
        image.src = `data:image/svg+xml;utf8,${encodedSVG}`;
        image.onload = () => resolve(image);
        image.onerror = () => reject(undefined);
    });
};

const drawImage = (ctx: CanvasRenderingContext2D, position: IPoint, image: HTMLImageElement, rotate: boolean) => {
    const { x, y } = position;
    const { width, height } = image;
    if (rotate) {
        ctx.save();
        ctx.rotate(Math.PI);
        ctx.drawImage(image, -x, -y, width, height);
        ctx.restore();
    } else {
        ctx.drawImage(image, x, y, width, height);
    }
};

const drawConnection = (ctx: CanvasRenderingContext2D, color: string, source: IPoint, destination: IPoint) => {
    ctx.beginPath();
    ctx.moveTo(source.x, source.y);
    ctx.lineTo(destination.x, destination.y);

    ctx.lineWidth = LINE_WIDTH * 10;
    ctx.strokeStyle = color;
    ctx.stroke();
};

const drawBlockedPins = (ctx: CanvasRenderingContext2D, points: IPoint[], pins: IFiberMapIndex[]) => {
    const filteredPins = pins.filter(d => d.assignedIndex === -2);
    if (filteredPins.length > 0) {
        for (const { index } of filteredPins) {
            const point = points[index];

            ctx.beginPath();
            ctx.moveTo(point.x - 2.5, point.y - 2.5);
            ctx.lineTo(point.x + 2.5, point.y + 2.5);
        
            ctx.moveTo(point.x - 2.5, point.y + 2.5);
            ctx.lineTo(point.x + 2.5, point.y - 2.5);
        
            ctx.lineWidth = LINE_WIDTH * 20;
            ctx.strokeStyle = "red";
            ctx.stroke();
        }
    }
};
