import { createSlice } from "@reduxjs/toolkit";
import { addUserFiberMapsAction, removeUserFiberMapAction, setAppliedPolarityAction, setBuildPolarityAction, setFiberMapsAction, setFilteredPolarityListAction, setUserFiberMapsAction, setUserPolaritiesAction, updateUserFiberMapsAction, setDocumentFiberMapsAction, forceReloadConnectorMapsAction, loadConnectorMapAction } from "./actions";
import { createAsyncAction, extractData } from "../../../actions";
import { BuildService } from "../../../../services/build-service";
import { initialPolarityState, PolarityConfig } from "./types";
import { setDisablePolarity } from "../../../../components/overlay/redux/reducers";

const polarityConfigurationSlice = createSlice({
    name: 'polarityConfiguration',
    initialState: initialPolarityState,
    reducers: {
        setAppliedPolarity: setAppliedPolarityAction,
        setBuildPolarity: setBuildPolarityAction,
        setUserPolarities: setUserPolaritiesAction,
        setFilteredPolarityList: setFilteredPolarityListAction,
        setFiberMaps: setFiberMapsAction,
        setUserFiberMaps: setUserFiberMapsAction,
        addUserFiberMaps: addUserFiberMapsAction,
        removeUserFiberMap: removeUserFiberMapAction,
        updateUserFiberMaps: updateUserFiberMapsAction,
        setDocumentFiberMaps: setDocumentFiberMapsAction,
        forceReloadConnectorMaps: forceReloadConnectorMapsAction,
        loadConnectorMap: loadConnectorMapAction
    }
});

export const { 
    updateUserFiberMaps, 
    setAppliedPolarity, 
    setBuildPolarity, 
    setUserPolarities, 
    setFilteredPolarityList, 
    setFiberMaps, 
    setUserFiberMaps, 
    addUserFiberMaps, 
    removeUserFiberMap,
    setDocumentFiberMaps,
    forceReloadConnectorMaps,
    loadConnectorMap
} = polarityConfigurationSlice.actions;

export const PolarityConfigurationReducer = polarityConfigurationSlice.reducer;

export const getPolarityFiberMaps = () => {
    return createAsyncAction(async (dispatch) => {
        const service = new BuildService();
        const res = await service.getPolarityFiberMaps();
        const fiberMaps = extractData(res, dispatch);
        if (fiberMaps) {
            dispatch(setFiberMaps(fiberMaps));
        }
        const userRes = await service.getUserPolarityFiberMapsByGroupId();
        const userFiberMaps = extractData(userRes, dispatch)
        if (userFiberMaps) {
            dispatch(setUserFiberMaps(userFiberMaps))
        }
    });
}

export const getBuildPolarityDefinitions = (buildId: number) => {
    return createAsyncAction(async (dispatch) => {
        const buildService = new BuildService();
        let buildPolarity: PolarityConfig[] = []
        dispatch(setDisablePolarity(false))
        
        const res = await buildService.getPolarityConfigsByBuildId(buildId);
        if (res.succesful && res.data) {
            buildPolarity = res.data || [];
            dispatch(setBuildPolarity(buildPolarity))
        }
        else {
            dispatch(setBuildPolarity(buildPolarity))
        }

        dispatch(setDisablePolarity(false))
    });
};