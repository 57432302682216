import React, { Component } from 'react';
import { WorkspaceState, connect } from '../../../../../../redux/reducers';
import { setShowConnectorReport } from '../../../../redux/reducers';
import { moveDestinationLeft, moveDestinationRight, setIsCollapsed } from '../../../../../../redux/build/reducers';
import { setToolbarDisplay, setSelectedPosition, clearSelection } from './redux/reducers';
import { setViewportContext } from '../../../../../../../pixi/components/viewport/redux/reducers';
import { setWizardDisplay, enableNextStep, setApplyEditChanges, setCurrentStep, saveBuildData } from '../../../wizard/redux/reducers';
import { ViewportStatus } from '../../../../../../../pixi/components/viewport/redux/types';
import { ToolItem } from '../../../../ToolItem';
import { setStatusState } from '../../../header/components/status/redux/reducer'
import { CollapseButton } from './components/buttons/collapse/CollapseButton';
import { ExpandButton } from './components/buttons/expand/ExpandButton';
import { getNbConnectors } from '../../../../../../redux/build/connector/types';
import { TrunkData } from '../../../../../../redux/build/types';
import { setConfigurationSession, setConfigSessionBusy } from '../../../../../../redux/ssc/reducer';
import { sscAllConfigSessionSelector, sscConfigSessionBusySelector, sscConfigSessionIdSelector } from '../../../../../../redux/ssc/selectors';
import { EDSSessionService } from '../../../../../../services/eds-session/eds-session-service';



import icon_move_left_disabled from './components/buttons/resources/icons/icon_move_left_disabled.svg'
import icon_move_left from './components/buttons/resources/icons/icon_move_left.svg'
import icon_move_right_disabled from './components/buttons/resources/icons/icon_move_right_disabled.svg'
import icon_move_right from './components/buttons/resources/icons/icon_move_right.svg'
import icon_delete_disabled from './components/buttons/resources/icons/icon_delete_disabled.svg'
import icon_delete from './components/buttons/resources/icons/icon_delete.svg'
import icon_edit_disabled from './components/buttons/resources/icons/icon_edit_disabled.svg'
import icon_edit from './components/buttons/resources/icons/icon_edit.svg'
import icon_details_disabled from './components/buttons/resources/icons/icon_details_disabled.svg'
import icon_details from './components/buttons/resources/icons/icon_details.svg'
import icon_clone_left_disabled from './components/buttons/resources/icons/icon_clone_left_disabled.svg'
import icon_clone_left from './components/buttons/resources/icons/icon_clone_left.svg'
import icon_clone_right_disabled from './components/buttons/resources/icons/icon_clone_right_disabled.svg'
import icon_clone_right from './components/buttons/resources/icons/icon_clone_right.svg'

import "../../Footer.scss";

interface IEditToolbarLocalState {
    moveLeftDisabled?: boolean;
    moveRightDisabled?: boolean;
    deleteDisabled?: boolean;
    editDisabled?: boolean;
    detailsDisabled?: boolean;
    cloneLeftDisabled?: boolean;
    cloneRightDisabled?: boolean;
    showCollapse?: boolean;
    expandCollapseDisabled?: boolean;
}

const mapStateToProps = (state: WorkspaceState) => {
    const { display, selection } = state.toolbar;
    const { showConnectorReport } = state.overlay;
    const { currentBuild } = state.builds;
    const { context } = state.viewport;
    const { stepCount } = state.wizard;
    const sessionBusy = sscConfigSessionBusySelector(state);
    const sessionId = sscConfigSessionIdSelector(state);
    const configSessions = sscAllConfigSessionSelector(state);
    return { display, selection, showConnectorReport, currentBuild, context, stepCount, sessionBusy, sessionId, configSessions };
}

const mapDispatchToProps = {
    setShowConnectorReport,
    moveDestinationLeft,
    moveDestinationRight,
    setIsCollapsed,
    setToolbarDisplay,
    setSelectedPosition,
    clearSelection,
    setViewportContext,
    setWizardDisplay,
    enableNextStep,
    setApplyEditChanges,
    setCurrentStep,
    saveBuildData,
    setStatusState,
    setConfigurationSession,
    setConfigSessionBusy
};

type props = Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;

class EditToolbarComponent extends Component<props, IEditToolbarLocalState> {
    public state: IEditToolbarLocalState = {
        moveLeftDisabled: false,
        moveRightDisabled: false,
        deleteDisabled: true,
        editDisabled: false,
        detailsDisabled: false,
        cloneLeftDisabled: true,
        cloneRightDisabled: true,
        showCollapse: true,
        expandCollapseDisabled: false
    };

    private moveSaveTimeOut?: NodeJS.Timeout;

    public componentDidUpdate(prevProps: props) {
        let selectedIndex = this.props.selection!.selected;
        if (selectedIndex !== -1) {
            const selectionChanged = prevProps.selection!.selected !== this.props.selection!.selected;
            const buildCatalogCodeChanged = prevProps.currentBuild?.catalogCode !== this.props.currentBuild?.catalogCode;
        
            let referenceChanged = false;
            if (selectedIndex === 0) {
                referenceChanged = prevProps.currentBuild!.source !== this.props.currentBuild!.source;
            }
            else {
                let prevSelectedDestination = prevProps.currentBuild!.destinations.find(x => x.position === selectedIndex);
                let selectedDestination = this.props.currentBuild!.destinations.find(x => x.position === selectedIndex);
                referenceChanged = prevSelectedDestination! !== selectedDestination!;
            }
    
            if (selectionChanged || referenceChanged || buildCatalogCodeChanged) {
                this.resetToolbarAvailabilities();
                this.setToolbarAvailabilities();
            }

            if (prevProps.sessionBusy !== this.props.sessionBusy) {
                this.setState({
                    editDisabled: this.props.sessionBusy
                })
            }
        }
    }

    private isExpandCollapseDisabled = (trunk: TrunkData) => {
        const customBLength = trunk.customBLength || false
        return getNbConnectors(trunk.groups!) <= 2 || customBLength
    }

    private setToolbarAvailabilities = () => {
        const selectedIndex = this.props.selection!.selected;
        const currentBuild = this.props.currentBuild!;

        let isCollapsed: boolean;
        let expandCollapseDisabled: boolean;
        if (selectedIndex === 0) {
            isCollapsed = currentBuild.source.isCollapsed!;
            expandCollapseDisabled = this.isExpandCollapseDisabled(currentBuild.source);
        } else {
            let dst = currentBuild.destinations.find(x => x.position === selectedIndex);
            isCollapsed = dst!.isCollapsed!;
            expandCollapseDisabled = this.isExpandCollapseDisabled(dst!);

            if (selectedIndex === 1) {
                this.setState({ moveLeftDisabled: true, cloneLeftDisabled: true });
            }

            if (selectedIndex === currentBuild.destinations.length - 1 && currentBuild.isAsymmetric) {
                this.setState({ moveRightDisabled: true, cloneRightDisabled: true });
            }

            if (selectedIndex === currentBuild.destinations.length) {
                if (currentBuild.isAsymmetric) {
                    this.setState({
                        moveLeftDisabled: true, cloneLeftDisabled: true,
                        moveRightDisabled: true, cloneRightDisabled: true
                    });
                } else {
                    this.setState({ moveRightDisabled: true, cloneRightDisabled: true });
                }
            }
        }
        if (!!currentBuild.catalogCode) {
            this.setState({
                moveLeftDisabled: true, cloneLeftDisabled: true,
                moveRightDisabled: true, cloneRightDisabled: true
            });
        }
        this.setState({ showCollapse: !isCollapsed, expandCollapseDisabled: expandCollapseDisabled });

        // Leaving it commented when we want to re-enable deleting
        // if (currentBuild.destinations.length <= 2 || this.props.selection!.selected === 1) {
        //     this.setState({ deleteDisabled: true });
        // }
    }

    private resetToolbarAvailabilities = () => {
        this.setState({
            moveLeftDisabled: false,
            moveRightDisabled: false,
            deleteDisabled: true,
            editDisabled: false,
            detailsDisabled: false,
            cloneLeftDisabled: true,
            cloneRightDisabled: true,
            showCollapse: true,
            expandCollapseDisabled: false,
        });
    }

    private getMoveLeftIcon = () : string => {
        return this.state.moveLeftDisabled ? icon_move_left_disabled : icon_move_left;
    }

    private getMoveRightIcon = () : string => {
        return this.state.moveRightDisabled ? icon_move_right_disabled : icon_move_right;
    }

    private getDeleteIcon = () : string => {
        return this.state.deleteDisabled ? icon_delete_disabled : icon_delete;
    }

    private getEditIcon = () : string => {
        return this.state.editDisabled ? icon_edit_disabled : icon_edit;
    }

    private getDetailsIcon = () : string => {
        return this.state.detailsDisabled ? icon_details_disabled : icon_details;
    }

    private getCloneLeftIcon = () : string => {
        return this.state.cloneLeftDisabled ? icon_clone_left_disabled : icon_clone_left;
    }

    private getCloneRightIcon = () : string => {
        return this.state.cloneRightDisabled ? icon_clone_right_disabled : icon_clone_right;
    }

    // duplicate handles for left and right to prevent creating anonymous click handle functions
    private handleMoveLeft = () => {
        this.triggerMoveLeft();
        if (this.moveSaveTimeOut) {
            clearTimeout(this.moveSaveTimeOut);
        }
        this.moveSaveTimeOut =  setTimeout(this.save, 500)
    }

    private handleMoveRight = () => {
        this.triggerMoveRight();
        if (this.moveSaveTimeOut) {
            clearTimeout(this.moveSaveTimeOut);
        }
        this.moveSaveTimeOut =  setTimeout(this.save, 500)
    }

    private save = () => {
        this.props.setStatusState!("saving");
    }

    private triggerMoveLeft = () => {
        const selectedIndex = this.props.selection!.selected;
        if (selectedIndex > 1) {
            this.props.moveDestinationLeft!(selectedIndex);
            this.props.setSelectedPosition!(selectedIndex - 1);
        }
    }

    private triggerMoveRight = () => {
        const selectedIndex = this.props.selection!.selected;
        if (selectedIndex < this.props.currentBuild!.destinations.length) {
            this.props.moveDestinationRight!(selectedIndex);
            this.props.setSelectedPosition!(selectedIndex + 1);
        }
    }

    private triggerDelete = () => {
        const selectedIndex = this.props.selection!.selected;
        if (selectedIndex > 0) {
            this.props.clearSelection!();
            this.props.setStatusState!("saving");
        }
    }

    private triggerEdit = async () => {
        if (this.props.context === ViewportStatus.Editing) {
            this.setIsEditing(false);
        } else {
            const selectedIndex = this.props.selection!.selected;
            if (selectedIndex === 0) {
                this.props.setCurrentStep!(1);
            } else {
                this.props.setCurrentStep!(2);
            }
            
            const configSessions = this.props.configSessions!;
            const sessionId = this.props.sessionId ?? ''
            const configurationType = this.props.currentBuild!.configurationType!
            const session = configSessions[this.props.currentBuild!.configurationType!]
            if (!session && sessionId.length) {
                const service = new EDSSessionService();
                this.props!.setConfigSessionBusy!(true)
                
                await service.updateConfigurationType({ sessionId, configurationType }).then(res => {
                    if (res.succesful && res.data) {
                        this.props.setConfigurationSession!(res.data)
                        
                    }
                    this.props.setConfigSessionBusy!(false)
                }).catch(err => {
                    this.props.setConfigSessionBusy!(false)
                });
                
            }
            
            this.setIsEditing(true);
            this.props.saveBuildData!(this.props.currentBuild!);
            this.props.setToolbarDisplay!(false);
        }
    }

    private setIsEditing = (isEditing: boolean) => {
        if (isEditing) {
            this.props.setViewportContext!(ViewportStatus.Editing);
            this.props.setWizardDisplay!(true);
            this.props.enableNextStep!(false);
        } else {
            this.props.setViewportContext!(ViewportStatus.Active);
            this.props.setApplyEditChanges!(false);
            this.props.setCurrentStep!(this.props.stepCount! + 1);
            this.props.setWizardDisplay!(false);
            this.props.saveBuildData!(undefined);
        }
    }

    private triggerDetails = () => {
        const { showConnectorReport } = this.props;
        this.props.setShowConnectorReport!(!showConnectorReport);
        this.props.setToolbarDisplay!(false);
    }

    public render() {
        const selectedIndex = this.props.selection!.selected;
        if (!this.props.display || selectedIndex === -1) {
            return null;
        }

        if (selectedIndex === 0) {
            return(
                <div className="controls-container toolbar-container background-blur toggle-pointer-events">
                    <ToolItem source={this.getEditIcon()} alt="Edit" tooltip="Edit" labelTop={true} disabled={this.state.editDisabled} onClick={this.triggerEdit} />
                    <ToolItem source={this.getDetailsIcon()} alt="Show details" tooltip="Show details" labelTop={true} disabled={this.state.detailsDisabled} onClick={this.triggerDetails} />
                    <div className="tool-vertical-divider"/>
                    {this.state.showCollapse ? <CollapseButton disabled={this.state.expandCollapseDisabled!} /> : <ExpandButton disabled={this.state.expandCollapseDisabled!} />}
                </div>
            );
        } else {
            return(
                <div className="controls-container toolbar-container background-blur toggle-pointer-events">
                    {/* <ToolItem source={this.getMoveLeftIcon()} alt="Move left" tooltip="Move left" labelTop={true}  disabled={this.state.moveLeftDisabled} onClick={this.handleMoveLeft} />
                    <ToolItem source={this.getMoveRightIcon()} alt="Move right" tooltip="Move right" labelTop={true} disabled={this.state.moveRightDisabled} onClick={this.handleMoveRight} />
                    <div className="tool-vertical-divider"/> */}
                    {/* <ToolItem source={this.getDeleteIcon()} alt="Delete" tooltip="Delete" labelTop={true} disabled={this.state.deleteDisabled} onClick={this.triggerDelete} /> */}
                    <ToolItem source={this.getEditIcon()} alt="Edit" tooltip="Edit" labelTop={true} disabled={this.state.editDisabled} onClick={this.triggerEdit} />
                    <ToolItem source={this.getDetailsIcon()} alt="Show details" tooltip="Show details" labelTop={true} disabled={this.state.detailsDisabled} onClick={this.triggerDetails} />
                    {/* <div className="tool-vertical-divider"/>
                    <ToolItem source={this.getCloneLeftIcon()} alt="Clone left" tooltip="Clone left" labelTop={true} disabled={this.state.cloneLeftDisabled} />
                    <ToolItem source={this.getCloneRightIcon()} alt="Clone right" tooltip="Clone right" labelTop={true} disabled={this.state.cloneRightDisabled} /> */}
                    <div className="tool-vertical-divider"/>
                    {this.state.showCollapse ? <CollapseButton disabled={this.state.expandCollapseDisabled!} /> : <ExpandButton disabled={this.state.expandCollapseDisabled!} />}
                </div>
            );
        }
    }
}

export const EditToolbar = connect(mapStateToProps, mapDispatchToProps)(EditToolbarComponent)
