import { useApp } from "@inlet/react-pixi";
import { Viewport as PixiViewport } from "pixi-viewport";
import * as Pixi from 'pixi.js';
import { Dispatch, MutableRefObject, useCallback, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { PageResizeArgs, PageResizeEnum } from '../../../workspace/components/overlay/components/footer/components/resize/types';
import { clearSelection } from '../../../workspace/components/overlay/components/footer/components/toolbar/redux/reducers';
import { getNbConnectors } from '../../../workspace/redux/build/connector/types';
import { currentBuildSelector, workspaceSelector } from '../../../workspace/selectors/root.selectors';
import { useWindowSize } from '../../hooks';
import { IPoint } from '../../types';
import { setIsDragging, setPosition, setResize, setScale, setViewportContext, ViewportReducer } from './redux/reducers';
import { viewportIncrementSelector, viewportSelector } from './redux/selectors';
import { initialState, IViewportState, ViewportStatus } from './redux/types';

export const useViewport = () => {
    const storeState = useSelector(viewportSelector);
    const app = useApplication();
    const viewportRef = useRef<PixiViewport>(initializeViewport(app));
    const [state, dispatch] = useReducer(ViewportReducer, initialState)
    const storeDispatch = useDispatch()
    const { resize, minScale } = state;

    useWindowResize(app, viewportRef.current);
    useWindowKeyDown();

    useViewportZoom(viewportRef, storeDispatch, app);
    useViewportClick(viewportRef.current, app);
    usePageResize(resize, minScale, viewportRef.current);
    useDrag(viewportRef, dispatch);

    useLocalViewportUpdate(storeState, dispatch);
    return { viewportRef , state };
}

const useApplication = () => {
    const application = useApp();
    const { subscribeEvents, unsubscribeEvents } = useAppEvents(application);

    useEffect(() => {
        subscribeEvents();
        return () => unsubscribeEvents()
    }, [subscribeEvents, unsubscribeEvents]);

    return application;
}

const useAppEvents = (application: Pixi.Application) => {
    const emergencyClear = useEmergencyClear();

    const subscribeEvents = useCallback(() => {
        application.view.addEventListener("dblclick", emergencyClear);
    }, [application, emergencyClear]);

    const unsubscribeEvents = useCallback(() => {
        application.view.removeEventListener('dblclick', emergencyClear);
    }, [application, emergencyClear]);

    return { subscribeEvents, unsubscribeEvents }
}

const useViewportClick = (viewport: PixiViewport, app: Pixi.Application) => {
    const { viewport: { isDragging, context } } = useSelector(workspaceSelector);
    const storeDispatch = useDispatch();
    const onViewportClick = useCallback(({ world }: { screen: Pixi.Point, world: Pixi.Point, viewport: PixiViewport }) => {
        const clickedOutside = world.y > app.screen.height || world.y < 0 || world.x > app.screen.width || world.x < 0;
        if (clickedOutside && !isDragging && context !== ViewportStatus.Editing) {
            storeDispatch(clearSelection());
        }
    }, [app.screen.height, app.screen.width, context, isDragging, storeDispatch]);

    useEffect(() => {
        viewport.on("clicked", onViewportClick);
        return () => {
            viewport.removeListener("clicked", onViewportClick);
        }
    }, [onViewportClick, viewport]);
}

const useEmergencyClear = () => {
    const dispatch = useDispatch();
    const { toolbar, viewport } = useSelector(workspaceSelector);
    const { selected } = toolbar.selection;
    const { context } = viewport;

    const emergencyClear = useCallback(() => {
        if (selected !== -1 && context !== ViewportStatus.Editing) {
            dispatch(clearSelection());
        }
    }, [selected, context, dispatch]);

    return emergencyClear;
}

const initializeViewport = (app: Pixi.Application) => {
    const viewport = new PixiViewport({
        interaction: app.renderer.plugins.interaction
    });
    
    viewport
        .drag({ 
            wheel: false,
        })
        .pinch()

    return viewport;
}

const toggleOverlayPointerEvents = (enabled: boolean) => {
    const overlayComponents = document.getElementsByClassName("toggle-pointer-events") as HTMLCollectionOf<HTMLElement>;
    const pointerEventsValue = enabled ? "auto" : "none";
    for (let i = 0; i < overlayComponents.length; i++) {
        overlayComponents[i].style.pointerEvents = pointerEventsValue;
    }
}

const useWindowResize = (application: Pixi.Application, viewport: PixiViewport) => {
    const windowSize = useWindowSize();
    useEffect(() => {
        application.renderer.resize(windowSize.width, windowSize.height);
        viewport.resize(windowSize.width, windowSize.height);
    }, [windowSize, application, viewport]) 
}

const useWindowKeyDown = () => {
    const emergencyClear = useEmergencyClear();

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === "27") {
                emergencyClear();
            }
        };
    
        window.addEventListener('keydown', onKeyDown);
        return () => window.removeEventListener('keydown', onKeyDown);
    }, [emergencyClear]);
}


const useScaleAfterResize = (args: PageResizeArgs, minZoom: number, viewport: PixiViewport, nbConnectors: number) => {
    const getScaleAfterResize = useCallback(() => {
        let fitWidth = false;
        let padding = 0;
        switch (args.value) {
            case PageResizeEnum.FitToPage:
                fitWidth = viewport.width / viewport.height > window.innerWidth / window.innerHeight;
                break;
            case PageResizeEnum.FitToHeight:
                padding = 150;
                break;
            case PageResizeEnum.FitToSource:
                const sourceWidth = (nbConnectors * 40) * viewport.scale.x;
                fitWidth = sourceWidth / viewport.height > window.innerWidth / window.innerHeight;
                padding = 150;
                break;
            default: // do nothing
                break;
        }
    
        const newScale: IPoint = { x: 1, y: 1 };
        if (fitWidth) {
            newScale.x = (window.innerWidth - padding) / viewport.width * viewport.scale.x;
            newScale.y = newScale.x;
        } else {
            newScale.y = (window.innerHeight - padding) / viewport.height * viewport.scale.y;
            newScale.x = newScale.y;
        }

        if (newScale.x < minZoom) {
            newScale.x = minZoom;
            newScale.y = minZoom;
        }
        
        return newScale;
    }, [args, minZoom, viewport, nbConnectors])

    return { getScaleAfterResize }
}

const usePositionAfterResize = (args: PageResizeArgs, minZoom: number, viewport: PixiViewport, nbConnectors: number) => {
    const getPositionAfterResize = useCallback(() => {
        const bounds = viewport.getBounds();
        const offset = {
            x: bounds.x - viewport.x,
            y: bounds.y - viewport.y
        }

        const newPosition: IPoint = { x: 1, y: 1 };
        switch(args.value) {
            case PageResizeEnum.FitToPage:
                offset.x -= 10;
                newPosition.x = (window.innerWidth - bounds.width) * 0.5 - offset.x;
                newPosition.y = (window.innerHeight - bounds.height) * 0.5 - offset.y;
                break;
            case PageResizeEnum.FitToHeight:
                offset.x -= 10;
                if (bounds.width > window.innerWidth) {
                    newPosition.x = -offset.x;
                } else {
                    newPosition.x = (window.innerWidth - bounds.width) * 0.5 - offset.x;
                }
                newPosition.y = (window.innerHeight - bounds.height) * 0.5 - offset.y;
                break;
            case PageResizeEnum.FitToSource:
                const sourceWidth = (nbConnectors * 40) * viewport.scale.x;
                newPosition.x = (window.innerWidth - sourceWidth) * 0.5 - offset.x;
                newPosition.y = (window.innerHeight - bounds.height) * 0.5 - offset.y;
                break;
            default: // do nothing
                break;
        }
        return newPosition;
    }, [args, viewport, nbConnectors])

    return { getPositionAfterResize }
}

const usePageResize = (args: PageResizeArgs, minZoom: number, viewport: PixiViewport) => {
    const { source } = useSelector(currentBuildSelector)!;
    let nbConnectors = getNbConnectors(source.groups);
    
    const { getScaleAfterResize } = useScaleAfterResize(args, minZoom, viewport, nbConnectors);
    const { getPositionAfterResize } = usePositionAfterResize(args, minZoom, viewport, nbConnectors);

    useEffect(() => {
        const newScale = getScaleAfterResize()
        viewport.scale.set(newScale.x, newScale.y)
        const newPosition = getPositionAfterResize();
        viewport.position.set(newPosition.x, newPosition.y);
    }, [getScaleAfterResize, getPositionAfterResize, viewport]); 
}

function useViewportZoom(viewportRef: MutableRefObject<PixiViewport>, storeDispatch: Dispatch<AnyAction>, app: Pixi.Application) {
    const scaleIncrement = useSelector(viewportIncrementSelector)

    useEffect(() => {
        const viewport = viewportRef.current;
        const onZoomEnd = () => {
            storeDispatch(setScale({ x: viewport.scale.x, y: viewport.scale.y }));
        };
        const onWheel = ({ deltaY }: WheelEvent) => {
            if (deltaY) {
                viewport.wheel({
                    percent: deltaY > 0 ? -0.1 : 0.1,
                    smooth: 3
                });
            }
        };
        viewport.addListener('zoomed-end', onZoomEnd);
        app.view.addEventListener('wheel', onWheel);

        return () => {
            viewport.removeListener('zoomed-end', onZoomEnd);
            app.view.removeEventListener('wheel', onWheel);
        };
    }, [app, storeDispatch, viewportRef]);

    useEffect(() => {
        const viewport = viewportRef.current;
        if (scaleIncrement) {
            viewport.zoomPercent(scaleIncrement, true)
        }
    }, [scaleIncrement, viewportRef])
}

function useDrag(viewportRef: React.RefObject<PixiViewport>, dispatch: Dispatch<any>) {
    const storeDispatch = useDispatch();

    const dragStart = useCallback(() => {
        dispatch(setIsDragging(true));
        toggleOverlayPointerEvents(false);
    }, [dispatch]);

    const dragEnd = useCallback((e: any) => {
        toggleOverlayPointerEvents(true);
        dispatch(setIsDragging(false));
        storeDispatch(setPosition({ x: e.viewport.x, y: e.viewport.y })); // We need to update the viewport position in the state, otherwise interactions that rely only on the state's values (wheelZoom, zoomTool, pageResize) won't have the updated viewport position
    }, [dispatch, storeDispatch]);

    useEffect(() => {
        const viewport = viewportRef.current;
        if (viewport) {
            viewport.on('drag-start', dragStart);
            viewport.on('drag-end', dragEnd);

            return () => {
                viewport.removeListener('drag-start', dragStart);
                viewport.removeListener('drag-end', dragEnd);
            }
        }

    }, [viewportRef, dragStart, dragEnd]);
}

const useLocalViewportUpdate = (storeState: IViewportState, dispatch: Dispatch<AnyAction>) => {
    // update viewport from store

    useEffect(() => {
        dispatch(setPosition(storeState.position))
    }, [storeState.position, dispatch])

    useEffect(() => {
        dispatch(setResize(storeState.resize))
    }, [storeState.resize, dispatch])

    useEffect(() => {
        dispatch(setViewportContext(storeState.context))
        dispatch(setIsDragging(storeState.isDragging))
    }, [storeState.context, storeState.isDragging, dispatch])
}
