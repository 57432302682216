import { createSlice } from "@reduxjs/toolkit";
import { initialState } from './types';
import { setToolbarDisplayReducer, setSelectedPositionReducer, setHoveredPositionReducer, clearSelectionReducer } from "./actions";

const editToolbarSlice = createSlice({
    name: "editToolbar",
    initialState,
    reducers: {
        setToolbarDisplay: setToolbarDisplayReducer,
        setHoveredPosition: setHoveredPositionReducer,
        setSelectedPosition: setSelectedPositionReducer,
        clearSelection: clearSelectionReducer
    }
});

export const EditToolbarReducer = editToolbarSlice.reducer;
export const { setToolbarDisplay, setHoveredPosition, setSelectedPosition, clearSelection } = editToolbarSlice.actions;