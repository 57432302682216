import { PayloadAction } from "@reduxjs/toolkit";
import { Feeder } from "../../../../../../../pixi/components/build/types";
import { IFiberMappingState, IFiberMap, ISourcePinMap, IDestinationPinMap, initialNavigationMap, IFiberMappingData } from "./types";

export const setSourceNavigationAction = (state: IFiberMappingState, action: PayloadAction<number>) => {
    state.navigation.source.nbIndex = action.payload;
}

export const setSourceIndexAction = (state:IFiberMappingState, action: PayloadAction<number>) => {
    state.navigation.source.currentIndex = action.payload;
    state.navigation.selectedPinIndex = -1;
}

export const setTAPNavigationAction = (state: IFiberMappingState, action: PayloadAction<number>) => {
    state.navigation.destination.taps.nbIndex = action.payload;
    if (state.navigation.destination.taps.currentIndex === -1) {
        state.navigation.destination.taps.currentIndex = 0;
    }
}

export const setTAPIndexAction = (state: IFiberMappingState, action: PayloadAction<number>) => {
    state.navigation.destination.taps.currentIndex = action.payload;
}

export const setTAPConnectorsNavigationAction = (state: IFiberMappingState, action: PayloadAction<number>) => {
    state.navigation.destination.connectors.nbIndex = action.payload;
    if (state.navigation.destination.connectors.currentIndex === -1) {
        state.navigation.destination.connectors.currentIndex = 0;
    }
}

export const setTAPConnectorsIndexAction = (state: IFiberMappingState, action: PayloadAction<number>) => {
    state.navigation.destination.connectors.currentIndex = action.payload;
}

export const setSelectedPinIndexAction = (state: IFiberMappingState, action: PayloadAction<number>) => {
    state.navigation.selectedPinIndex = action.payload;
}

export const setFiberMappingAction = (state: IFiberMappingState, action: PayloadAction<IFiberMappingData>) => {
    state.mapping = action.payload.mapping;
    state.unused = action.payload.unused;
}

export const handleFiberMapAction = (state: IFiberMappingState, action: PayloadAction<number>) => {
    const unusedSourcePin = state.unused.sourcePins.find(p => p.index === state.navigation.selectedPinIndex && p.connectorIndex === state.navigation.source.currentIndex)
    if (!unusedSourcePin) {
        const destinationPinIndex = action.payload;
        const fiberMap: IFiberMap = {
            sourcePin: {
                index: state.navigation.selectedPinIndex,
                connectorIndex: state.navigation.source.currentIndex
            },
            destinationPin: {
                index: destinationPinIndex,
                tapIndex: state.navigation.destination.taps.currentIndex,
                connectorIndex: state.navigation.destination.connectors.currentIndex
            }
        };
        const existingFiberMap = state.mapping.find(m => m.sourcePin.index === fiberMap.sourcePin.index && m.sourcePin.connectorIndex === fiberMap.sourcePin.connectorIndex)
        state.isModified = true;
        if (existingFiberMap) {
            existingFiberMap.destinationPin.index = destinationPinIndex;
        } else {
            state.mapping.push(fiberMap);
        }
        state.navigation.selectedPinIndex = -1;
    }
}

export const breakFiberMapAction = (state: IFiberMappingState) => {
    const sourceConnectorIndex = state.navigation.source.currentIndex;
    const tapIndex = state.navigation.destination.taps.currentIndex;
    const destinationConnectorIndex = state.navigation.destination.connectors.currentIndex;
    const sourcePinIndex = state.navigation.selectedPinIndex;
    const existingFiberMap = state.mapping.find(m => m.sourcePin.index === sourcePinIndex && m.sourcePin.connectorIndex === sourceConnectorIndex && m.destinationPin.tapIndex === tapIndex && m.destinationPin.connectorIndex === destinationConnectorIndex)
    if (existingFiberMap) {
        state.mapping = state.mapping.filter(m => m !== existingFiberMap);
        state.navigation.selectedPinIndex = -1;
        state.isModified = true;
    }
}

export const handleUnusedPinAction = (state: IFiberMappingState, action: PayloadAction<{ section: string, pinIndex: number }>) => {
    const { section, pinIndex } = action.payload;
    if (section === Feeder) {
        const pin: ISourcePinMap = {
            index: pinIndex,
            connectorIndex: state.navigation.source.currentIndex
        }
        const existingUnusedPin = state.unused.sourcePins.find(p => p.index === pin.index && p.connectorIndex === pin.connectorIndex);
        if (existingUnusedPin) {
            const filteredPins = state.unused.sourcePins.filter(p => p !== existingUnusedPin);
            state.isModified = filteredPins.length !== state.unused.sourcePins.length;
            state.unused.sourcePins = filteredPins.filter(p => p !== existingUnusedPin);
        } else {
            state.unused.sourcePins.push(pin);
            state.isModified = true;
        }
    } else {
        const pin: IDestinationPinMap = {
            index: pinIndex,
            tapIndex: state.navigation.destination.taps.currentIndex,
            connectorIndex: state.navigation.destination.connectors.currentIndex
        }
        const existingUnusedPin = state.unused.destinationPins.find(p => p.index === pin.index && p.tapIndex === pin.tapIndex && p.connectorIndex === pin.connectorIndex);
        if (existingUnusedPin) {
            const filteredPins = state.unused.destinationPins.filter(p => p !== existingUnusedPin);
            state.isModified = filteredPins.length !== state.unused.destinationPins.length;
            state.unused.destinationPins = filteredPins;
        } else {
            state.unused.destinationPins.push(pin);
            state.isModified = true;
        }
    }

    
}

export const clearAllAction = (state: IFiberMappingState, action: PayloadAction<boolean>) => {
    state.navigation.selectedPinIndex = -1;
    state.mapping = [];
    state.unused = {
        sourcePins: [],
        destinationPins: []
    }

    state.isModified = action.payload;
}

export const resetNavigationAction = (state: IFiberMappingState) => {
    state.navigation.source = initialNavigationMap;
    state.navigation.destination.taps = initialNavigationMap;
    state.navigation.destination.connectors = initialNavigationMap;
}