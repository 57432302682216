import { batch, useDispatch, useSelector } from "react-redux"
import { useCallback, useState, useEffect } from "react"
import { setShowConnectorAssignment } from "../../../../../../redux/reducers"
import { useTranslation } from "react-i18next"
import { LocalizationKeys } from "../../../../../../../../../locales/types"
import { showConnectorAssignmentSelector, showConnectorReportSelector } from '../../../../../../redux/selectors';
import { disabledUISelector } from "../../../status/redux/selectors"
import { expandAll } from "../../../../../../../../redux/build/reducers"
import { IToolItemProps } from '../../../../../../ToolItem';

export const usePolarityButton = () => {
    const { t } = useTranslation();
    const [state, setState] = useState<IToolItemProps>({});
    const disableUI = useSelector(disabledUISelector);
    const showConnectorReport = useSelector(showConnectorReportSelector);
    const showConnectorAssignment = useSelector(showConnectorAssignmentSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        setState({
            text: t(LocalizationKeys.Polarity).toLocaleUpperCase(),
            alt: t(LocalizationKeys.SetPolarity),
            tooltip: t(LocalizationKeys.SetPolarity),
            disabled: disableUI || showConnectorReport
        });
    }, [t, disableUI, showConnectorReport])

    const onClick = useCallback(() => {
        batch(() => {
            dispatch(setShowConnectorAssignment(!showConnectorAssignment));
            dispatch(expandAll());
        })
    }, [dispatch, showConnectorAssignment])

    return { ...state, onClick }
}