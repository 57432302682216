import { createSelector } from "reselect";
import { workspaceSelector } from "../../../selectors/root.selectors";

export const overlaySelector = createSelector(
    workspaceSelector,
    workspace => workspace.overlay  
);

export const showConnectorAssignmentSelector = createSelector(
    overlaySelector,
    overlay => overlay.showConnectorAssignment
);

export const showFiberMappingSelector = createSelector(
    overlaySelector,
    overlay => overlay.showFiberMapping
);

export const showPolarityConfigSelector = createSelector(
    overlaySelector,
    overlay => overlay.showPolarity
)

export const printSettingsSelector = createSelector(
    overlaySelector, 
    overlay => overlay.printSettings
)

export const showThirdPartyCopyrightSelector = createSelector(
    overlaySelector,
    overlay => overlay.showThirdPartyCopyright
)

export const showAboutApplicationSelector = createSelector(
    overlaySelector, 
    overlay => overlay.showAboutApplication
)

export const showCablePresetSnackbarSelector = createSelector(
    overlaySelector,
    overlay => overlay.showCablePresetSnackbar
)

export const showPrintDialogSelector = createSelector(
    overlaySelector,
    overlay => overlay.printSettings.showPrintDialog
)

export const enableFiberMapsOptionSelector = createSelector(
    overlaySelector, 
    overlay => overlay.printSettings.enableFiberMapsOption
)

export const enablePolarityDiagramOptionSelector = createSelector(
    overlaySelector,
    overlay => overlay.printSettings.enablePolarityDiagramOption
)

export const isPolarityModeSelector = createSelector(
    overlaySelector,
    overlay => overlay.showConnectorAssignment || overlay.showFiberMapping || overlay.showPolarity
);

export const showConnectorReportSelector = createSelector(
    overlaySelector,
    overlay => overlay.showConnectorReport
);

export const showMoreMenuSelector = createSelector(
    overlaySelector,
    overlay => overlay.showMoreMenu
);

export const showIdleSelector = createSelector(
    overlaySelector,
    overlay => overlay.showIdle
);

export const showTimeoutSelector = createSelector(
    overlaySelector,
    overlay => overlay.showTimeout
);

export const isOverlayShownSelector = createSelector(
    showPrintDialogSelector,
    showConnectorReportSelector,
    isPolarityModeSelector,
    (showPrint, showConnector, isPolarityMode) => showPrint || showConnector || isPolarityMode
);

export const showViewProductSelector = createSelector(
    overlaySelector,
    overlay => overlay.showViewProduct
);

export const showAnonymousUserWarningSelector = createSelector(
    overlaySelector,
    overlay => overlay.showAnonymousUserWarning
);

export const showLabelSchemeSelector = createSelector(
    overlaySelector,
    overlay => overlay.showLabelScheme
);

export const showTriggerManagementSelector = createSelector(
    overlaySelector,
    overlay => overlay.showTriggerManagement
);