import React from "react";
import { useRestrictedInputDialog } from "./hooks";
import { IRestrictedInputDialogProps } from "./types";
import { DialogHeader } from '../../../../../../../../../../ui/dialog/header/DialogHeader';
import { ContainedButton, TextButton } from '@orbit/button'
import { FilledTextField } from '@orbit/text-field'
import { Dialog } from "@orbit/dialog";

import "./InputDialog.scss";

export const RestrictedInputDialog: React.FC<IRestrictedInputDialogProps> = (props) => {
    const { open, inputDisabled, maxCharacterCount, inputValue, onInputChanged, cancelProps, onCancelClick, saveProps, onAcceptClick, headerProps, helperText, inputFieldLabel } = useRestrictedInputDialog(props);

    if (!open) {
        return null;
    }
    return open ? (
            <Dialog className="input-dialog toggle-pointer-events" open={open} preventOutsideDismiss={true} onClose={onCancelClick} >
                <DialogHeader {...headerProps} />
                <div className="dialog-container">
                    <FilledTextField palette="primary"
                        label={inputFieldLabel}
                        maxLength={maxCharacterCount}
                        value={inputValue}
                        helperText={inputDisabled ? helperText : ""}
                        error={inputDisabled}
                        onChange={e => onInputChanged(e)} />
                </div>
                <div className="dialog-button-container">
                    <TextButton className="cancel-button" onClick={onCancelClick}>{cancelProps.label}</TextButton>
                    <ContainedButton className="save-button" disabled={inputDisabled} onClick={onAcceptClick} >{saveProps.label}</ContainedButton>
                </div>
            </Dialog>
    ) : null
}