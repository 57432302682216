import { TooltipPlacement } from "@orbit/icon-button"
import { AlertProps, AlertVariant } from "@orbit/snackbar"
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FIVE_SEC_MS } from "../../../../../../tracker/timer/types"
import { showNotification } from "../../store/reducer"
import { notificationManagerSelector } from "../../store/selectors"

export const useNotificationSnackbar = () => {
    const { display, notification: { message, palette } } = useSelector(notificationManagerSelector)
    const storeDispatch = useDispatch();

    const onClose = useCallback(() => {
        storeDispatch(showNotification(false));
    }, [storeDispatch]);

    const notificationProps: AlertProps = {
        palette,
        variant: "filled" as AlertVariant,
        snackbarProps: {
            open: display,
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            message,
            onClose,
            autoHideDuration: FIVE_SEC_MS
        },
    };

    const closeIconProps = {
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "top" as TooltipPlacement,
        onClick: onClose 
    }

    return { notificationProps, closeIconProps };
}