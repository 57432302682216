import { PayloadAction } from "@reduxjs/toolkit";
import { IConnectorAssignmentPosition, IConnectorPosition, IConnectorHighlightsState, initialConnectorPosition, IHighlight } from "./types";

export const setLastClickedConnectorAction = (state: IConnectorHighlightsState, action: PayloadAction<IConnectorPosition>) => {
    state.lastClickedConnector = action.payload;
};

export const resetHoveredConnectorAction = (state: IConnectorHighlightsState) => {
    state.hoveredConnector = initialConnectorPosition;
    state.highlights = state.highlights.map(h => ({ ...h, status: h.status === "Hovered" ? "Assigned" : h.status }) );
};

export const setHoveredConnectorAction = (state: IConnectorHighlightsState, action: PayloadAction<IConnectorPosition>) => {
    const hoveredConnector = action.payload;
    state.hoveredConnector = hoveredConnector;
};

export const setHighlightsAction = (state: IConnectorHighlightsState, action: PayloadAction<IHighlight[]>) => {
    state.highlights = action.payload;
};

export const addOrUpdateHighlightsAction = (state: IConnectorHighlightsState, action: PayloadAction<IHighlight[]>) => {
    const highlights = action.payload;
    for (const highlight of highlights) {
        const index = state.highlights.findIndex(h => h.position === highlight.position && h.index === highlight.index);
        if (index === -1) {
            state.highlights.push(highlight);
        } else {
            state.highlights[index].status = highlight.status;
        }
    }
}

export function findSameSetConnectors(connectors: IConnectorAssignmentPosition[], position: number, index: number): IConnectorPosition[] {
    let assignedConnectors: IConnectorPosition[] = [];
    // m = 0 (feeder), m = 1 (taps)
    const m = position === 0 ? 0 : 1;
    connectors.forEach((assignment) => {
        const connectors = m ? assignment.destinationConnectors : assignment.sourceConnectors;
        if (connectors) {
            const connector = connectors.find(c => c.position === position && c.index === index);
            if (connector) {
                assignedConnectors = assignment.sourceConnectors.concat(assignment.destinationConnectors);
            }
        }
    });
    return assignedConnectors;
}


