import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../../../locales/types";
import { IGenericDialogProps } from "../../../../../../../ui/dialog/generic-dialog/types";
import { CustomFiberMapData } from "../../../../../../redux/build/connector/polarity/fiber-map/types";
import { getPolarityConfigString } from "../../../../../../redux/build/connector/polarity/hooks";
import { removeUserFiberMap, updateUserFiberMaps } from "../../../../../../redux/build/connector/polarity/reducer";
import { userFiberMapsSelector } from "../../../../../../redux/build/connector/polarity/selectors";
import { BuildPolarity, CUSTOM_MAP_KEY, getPolarityMapPolarityType, initialPolarityMap, PolarityConfig, PolarityMap, SESSION_MAP_KEY } from "../../../../../../redux/build/connector/polarity/types";
import { getBuildOwnerByUserId } from "../../../../../../redux/build/types";
import { DialogTypes } from "../../../../../../redux/dialog/types";
import { sscBuildPolaritySelector, sscDefaultBuildPolaritiesSelector } from "../../../../../../redux/ssc/selectors";
import { buildsSelector, currentBuildSelector } from "../../../../../../selectors/root.selectors";
import { setNotification } from "../../../notification/store/reducer";
import { AlertPalettes } from "@orbit/snackbar";
import { BuildService, getCurrentISODate } from "../../../../../../services/build-service";
import { userIdSelector } from "../../../../../authentication/redux/selectors";
import { POLARITY_MAX_CHARACTER } from "../../fiber-mapping/save-dialog/types";
import { setContextAssignedMapping } from "../../redux/reducer";
import { IPolarityContext, PolarityContext } from "../../redux/types";
import { IPolarityButton } from "../types";
import { IEditPolarityDialogProps } from "./edit-polarity/types";
import { IPolarityAssignmentRowProps } from "./row/types";

export const useAssignPolarity = () => {
    const configs = useSelector(sscDefaultBuildPolaritiesSelector);

    const { polarityConfigs, selectedConfig, polarityButtons, appliedMap } = usePolarityConfigButtons(configs || []);
    const { polarityAssignmentRowProps, editPolarityDialogProps, deletePolarityDialogProps, selectedMap } = usePolarityMapList(selectedConfig, appliedMap);

    return { polarityAssignmentRowProps, editPolarityDialogProps, deletePolarityDialogProps, polarityConfigs, selectedMap, selectedConfig, polarityButtons }
}

export const usePolarityConfigButtons = (appliedConfigs: BuildPolarity[]) => {
    const polarityConfigs = useSelector(sscBuildPolaritySelector)
    const [selectedConfig, setSelectedConfig] = useState(polarityConfigs[0]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [appliedMap, setAppliedMap] = useState<PolarityMap>();
    const { t } = useTranslation();

    useEffect(() => {
        if (selectedConfig !== polarityConfigs[currentIndex]) {
            setSelectedConfig(polarityConfigs[currentIndex]);
        }
    }, [currentIndex, selectedConfig, polarityConfigs])

    const onButtonClick = useCallback((pc: PolarityConfig, index: number) => {
        setSelectedConfig(pc);
        setCurrentIndex(index);
    }, [])

    const polarityButtons: IPolarityButton[] = useMemo(() => {
        return polarityConfigs.map((pc, index) => {
            const applied = appliedConfigs.find(a => pc.from && pc.to && a.from!.fiberCount === pc.from!.fiberCount && a.to!.fiberCount === pc.to!.fiberCount);
            const onClick = () => { onButtonClick(pc, index) };
            let className = "polarity-button";
            let selected = false;
            if (applied) {
                className += '-applied';
            }
            else if (selectedConfig && selectedConfig.from && pc.from && selectedConfig.from!.fiberCount === pc.from!.fiberCount && selectedConfig.to!.fiberCount === pc.to!.fiberCount) {
                className += '-selected';
                selected = true;
            }

            return { ...pc, onClick, applied, className, description: getPolarityConfigString(pc.from!, pc.to!, t), selected }
        })

    }, [polarityConfigs, appliedConfigs, selectedConfig, onButtonClick, t])

    useEffect(() => {
        const appliedConfig = appliedConfigs.find(a =>
            a.from && a.to &&
            selectedConfig.from && selectedConfig.to &&
            a.from.fiberCount === selectedConfig.from.fiberCount &&
            a.to.fiberCount === selectedConfig.to.fiberCount);
        if (appliedConfig) {
            setAppliedMap(appliedConfig.polarityMap)
        }
        else {
            setAppliedMap(undefined)
        }
    }, [selectedConfig, appliedConfigs])

    return { polarityButtons, selectedConfig, polarityConfigs, appliedMap }
}

export const usePolarityMapList = (selectedConfig: PolarityConfig, appliedMap?: PolarityMap) => {
    const userId = useSelector(userIdSelector);
    const initialPolarityMaps = (selectedConfig && selectedConfig.polarityMaps) ? selectedConfig.polarityMaps : [];
    const [polarityMaps, setPolarityMaps] = useState(initialPolarityMaps);
    const [selectedMap, setSelectedMap] = useState(initialPolarityMap);

    const { editPolarityDialogProps, onClick: onEditPolarity } = useEditPolarity(polarityMaps);
    const { deletePolarityDialogProps, onClick: onDeletePolarity } = useDeletePolarity(polarityMaps);

    useEffect(() => {
        if (selectedConfig && selectedConfig.polarityMaps) {
            const index = selectedConfig.polarityMaps.findIndex(c => c.customKey === selectedMap.customKey);
            if (index === -1) {
                setSelectedMap(initialPolarityMap);
            }
            setPolarityMaps(selectedConfig.polarityMaps || [])
        } else {
            setSelectedMap(initialPolarityMap);
            setPolarityMaps([]);
        }
    }, [selectedConfig, selectedMap])

    const onRowClick = useCallback(async (polarityMap: PolarityMap) => {
        if (polarityMap.customKey === selectedMap.customKey) {
            setSelectedMap(initialPolarityMap);
        } else {
            setSelectedMap(polarityMap);
        }
    }, [selectedMap]);

    const builds = useSelector(buildsSelector);
    const polarityAssignmentRowProps: IPolarityAssignmentRowProps[] = useMemo(() => {
        return polarityMaps.map(pm => {
            const owner = pm.userId ? getBuildOwnerByUserId(pm.userId, builds!) : "";
            const canDelete = pm.userId && userId ? pm.userId === userId : false;
            const editable = pm.key ? pm.key.toString() === CUSTOM_MAP_KEY.toString() : false;
            const applied = appliedMap && getPolarityMapPolarityType(appliedMap) === getPolarityMapPolarityType(pm);
            const imageUri = getPolarityImageUrl(pm);

            const selected = (editable && selectedMap.customKey === pm.customKey) ||
                (selectedMap && selectedMap.key === SESSION_MAP_KEY && selectedMap.customKey === pm.customKey)
            const formatedDate = pm.versionDate && pm.versionDate.length ? formatDate(pm.versionDate) : "Standard";
            const creationInfo = owner.length > 0 ? `${owner}, ${formatedDate}` : formatedDate;

            return {
                data: { ...pm, owner, applied, selected, editable, canDelete, creationInfo, imageUri },
                onRowClick: () => onRowClick({ ...pm, imageUri }),
                onEditPolarity,
                onDeletePolarity
            }
        })
    }, [userId, builds, polarityMaps, selectedMap, appliedMap, onRowClick, onEditPolarity, onDeletePolarity])

    return { polarityAssignmentRowProps, editPolarityDialogProps, deletePolarityDialogProps, selectedMap }
}

export const usePolarityMap = (configMapping: PolarityMap[], selectedMapKey?: number) => {
    const [selectedMap, setSelectedMap] = useState(configMapping.find(c => c.key === selectedMapKey));

    useEffect(() => {
        setSelectedMap(configMapping.find(c => c.key === selectedMapKey))
    }, [selectedMapKey, configMapping])

    return { selectedMap }
}

export const useEditPolarity = (configMapping: PolarityMap[]) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [polarityName, setPolarityName] = useState("");
    const [mapId, setMapId] = useState(0);
    const storeDispatch = useDispatch();

    const userFiberMaps = useSelector(userFiberMapsSelector);
    const polarityContext = useContext(PolarityContext)

    const onClick = useCallback((id: number) => {
        const index = configMapping.findIndex(c => c.id === id);
        if (index !== -1) {
            setPolarityName(configMapping[index].description!);
            setMapId(id);
        }
        setOpen(true);
    }, [configMapping])


    const OnRenameSave = useCallback(async (name: string) => {
        const oldFiberMap = userFiberMaps.find(c => c.id === mapId);
        if (oldFiberMap) {
            const updatedFiberMap: CustomFiberMapData = {
                ...oldFiberMap,
                key: `${CUSTOM_MAP_KEY}`,
                name,
                versionDate: getCurrentISODate(),
            }
            const res = await new BuildService().updateUserPolarityFiberMapName(mapId, name);
            if (res.succesful && res.data) {
                updatePolarityAssignments(oldFiberMap, updatedFiberMap, polarityContext);
                storeDispatch(updateUserFiberMaps(updatedFiberMap));
                setOpen(false);
            }
        }

    }, [mapId, userFiberMaps, polarityContext, storeDispatch])

    const onClose = useCallback(() => {
        setOpen(false);
    }, [])

    const editPolarityDialogProps: IEditPolarityDialogProps = useMemo(() => {
        return {
            saveProps: {
                label: t(LocalizationKeys.Save),
                onClick: OnRenameSave
            },
            cancelProps: {
                label: t(LocalizationKeys.Cancel),
                onClick: onClose
            },
            title: t(LocalizationKeys.EditPolarityName),
            inputFieldLabel: t(LocalizationKeys.PolarityNewName),
            maxCharacterCount: POLARITY_MAX_CHARACTER,
            value: polarityName,
            type: DialogTypes.Confirm,
            props: {
                open,
                onClose,
            }
        }
    }, [polarityName, open, onClose, OnRenameSave, t])

    return { editPolarityDialogProps, onClick };
}

export const useDeletePolarity = (configMapping: PolarityMap[]) => {
    const { t } = useTranslation(); 
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [display, setDisplay] = useState(false);
    const [mapId, setMapId] = useState(0);
    const buildId = useSelector(currentBuildSelector)!.id ?? 0;
    const { dispatch } = useContext(PolarityContext);

    const storeDispatch = useDispatch();

    const onClick = useCallback(async (id: number) => {
        let message = t(LocalizationKeys.DeleteCustomPolarityMessage);
        const res = await new BuildService().getBuildsByPolarity(id);
        if (res.succesful && res.data) {
            const { builds, finalizedBuilds, totalConnectorMaps } = res.data; 
            if (builds && finalizedBuilds) {
                if (builds.length > 0 || builds.length > 0) {
                    const error = finalizedBuilds.length > 0;
                    const totalBuilds = builds.length;
                    message = error ? 
                                t(LocalizationKeys.DeleteCustomPolarityError) : 
                                t(LocalizationKeys.DeleteCustomPolarityWarning, { totalConnectorMaps, totalBuilds });
                    setError(error);
                }
            }
        }

        setMapId(id);
        setDisplay(true);
        setMessage(message);
    }, [t])

    const onClose = useCallback(() => {
        setDisplay(false);
        setError(false);
    }, [])

    const onDeleteConfirm = useCallback(async () => {
        const index = configMapping.findIndex(c => c.id === mapId);
        if (index !== -1) {
            const res = await new BuildService().deleteUserPolarityFiberMap(buildId, mapId);
            if (res.succesful && res.data) {
                const { connectorAssignments: polarityConnectorMaps } = res.data;
                batch(() => {
                    if (polarityConnectorMaps) {
                        dispatch(setContextAssignedMapping(polarityConnectorMaps));
                    }
                    storeDispatch(setNotification({ message: t(LocalizationKeys.DeleteCustomPolaritySnackBarMessage), palette: AlertPalettes.info }));
                    storeDispatch(removeUserFiberMap(mapId));
                })
                setDisplay(false);
            }
        }
    }, [buildId, mapId, configMapping, t, dispatch, storeDispatch])

    const deletePolarityDialogProps: IGenericDialogProps = useMemo(() => {
        return {
            display,
            title: t(LocalizationKeys.DeleteCustomPolarity),
            message,
            onClose: onClose,
            onConfirm: onDeleteConfirm,
            confirmDisabled: error,
            confirmText: t(LocalizationKeys.Delete),
            closable: true,
            critical: true,
        }
    }, [display, error, message, onDeleteConfirm, onClose, t]);

    return { deletePolarityDialogProps, onClick };
}

export const formatDate = (strDate: string) => {
    const date = new Date(strDate);
    const formatedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    const formatedDate = date.toLocaleDateString();

    return `${formatedDate} ${formatedTime}`;
}

export const updatePolarityAssignments = (oldData: CustomFiberMapData, newData: CustomFiberMapData, polarityContext: IPolarityContext) => {
    const { state, dispatch } = polarityContext;

    const connectorAssignments = state.assignmentMapping;
    const updatedAssignments = connectorAssignments.map(c => {
        let polarityType = c.polarityType ?? "";
        let name = c.fiberMap!.name ?? "";

        if ((polarityType === oldData.name || polarityType.length === 0) && (name === oldData.name || name.length === 0)) {
            polarityType = newData.name;
            name = newData.name;
        }

        const fiberMap: CustomFiberMapData = { ...c.fiberMap!, name };
        return { ...c, polarityType, fiberMap };
    });

    if (updatedAssignments.length !== 0) {
        dispatch(setContextAssignedMapping(updatedAssignments));
    }

}

export const getPolarityImageUrl = (polarityMap: PolarityMap) : string | undefined => {
    if (polarityMap.imageUri) return polarityMap.imageUri;

    const offscreen = document.querySelector("#offscreen-container") as HTMLDivElement;
    const polarity = offscreen.querySelector("#polarity-container") as HTMLDivElement;
    const image = polarity.querySelector<HTMLImageElement>(`#polarity-image-${polarityMap.id}`);

    return image ? image.src : undefined;
}