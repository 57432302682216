import { createSlice } from "@reduxjs/toolkit";
import { initialConnectorReportContext, initialState } from './types';
import { setSelectedRowsAction, toggleRowSelectionAction, clearSelectionAction, setLabelChangedAction, setTriggerColorsChangedAction, setPropagationMappingAction } from "./actions";
import { createContext } from 'react';

const connectorReportSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {
        setSelectedRows: setSelectedRowsAction,
        toggleRowSelection: toggleRowSelectionAction,
        clearSelection: clearSelectionAction,
        setLabelChanged: setLabelChangedAction,
        setTriggerColorsChanged: setTriggerColorsChangedAction,
        setPropagationMapping: setPropagationMappingAction
    },
});

export const ConnectorReportReducer = connectorReportSlice.reducer;
export const {
    setSelectedRows,
    toggleRowSelection,
    clearSelection,
    setLabelChanged,
    setTriggerColorsChanged,
    setPropagationMapping
} = connectorReportSlice.actions;

export const ConnectorReportContext = createContext(initialConnectorReportContext);
