import { createContext, Dispatch } from "react"
import { AnyAction } from "redux"
import { IConnectorPosition } from "../../../../../../../pixi/components/decorators/bounds/components/selection/redux/types";
import { CustomFiberMapData } from "../../../../../../redux/build/connector/polarity/fiber-map/types";
import { IDestinationData } from "../../../../../../redux/build/destination/types";
import { ISourceData } from "../../../../../../redux/build/source/types";
import { IAssignmentRowData } from "../types";

export interface IConnectorMap {
    id?: number;
    position: number;
    index: number;
    fiberCount: number;
    unassignedFibers: number;
    blockedFiberCount?: number;
    connectorType: string;
    orderIndex: number;
}

export interface IWorkSpaceConnector {
    position: number;
    index: number;
}

export type ConnectorBuildMatrix<T> = T[][]; // [tapIndex][connectorIndex]

export interface IConnectorAssignmentMap {
    id?: number;
    polarityType: string;
    sourceMapping: IConnectorMap[];
    destinationMapping: IConnectorMap[];
    fiberMap?: CustomFiberMapData;
}

export interface IConnectorAssignmentState {
    sourceSelection: IConnectorMap[];
    destinationSelection: IConnectorMap[];
    assignedSelection: IConnectorMap[];
    assignmentMapping: IConnectorAssignmentMap[];
    rows: IAssignmentRowData[][];
    polarityType?: string;
    polarityAssignment?: string;
    filteredPolarityAssignments: string[],
    selectAllSelection: boolean;
    selectAllDisabled: boolean;
    hoveredConnectorMap: IConnectorPosition[];
    assignmentIds: Record<number, number>
}

export const initialConnectorAssignmentState: IConnectorAssignmentState = {
    sourceSelection: [],
    destinationSelection: [],
    assignedSelection: [],
    assignmentMapping: [],
    selectAllSelection: false,
    selectAllDisabled: true,
    filteredPolarityAssignments: [],
    rows: [],
    hoveredConnectorMap: [],
    assignmentIds: { }
}

export interface IConnectorAssignmentContext {
    state: IConnectorAssignmentState;
    dispatch: Dispatch<AnyAction>;
}

export const initialConnectorAssignmentContext: IConnectorAssignmentContext = {
    state: initialConnectorAssignmentState,
    dispatch: () => { }
}

export interface IConnectorAssignmentRowsArgs {
    source: ISourceData,
    taps: IDestinationData[],
    disabled: boolean
}

export const ConnectorAssignmentContext = createContext(initialConnectorAssignmentContext);