export const LocalizationKeys = {
    AboutThisApplication: "about-this-application",
    AccessPoint: "access-point",
    AccessPointConnectorTypes: "access-point-connector-types",
    AccessPointNumber: "access-point-number",
    AccessPoints: "access-points",
    AccessPointsConnectorError: "access-points-connector-count-error",
    AllConnectors: "all-connectors",
    AnonymousUserWarning: "anonymous-user-warning",
    Apply: "apply",
    ApplyColor: "apply-color",
    ApplyChanges: 'apply-changes',
    ApplyPolarity: "apply-polarity",
    ApplyScheme: "apply-scheme",
    ApplyToAllAPs: "apply-to-all-aps",
    ApplyTriggerColors: "apply-trigger-colors",
    APPosition: "ap-position",
    ApprovedBy: "approved-by",
    AssemblyNewName: "assembly-new-name",
    AssignedFibers: "assigned-fibers",
    AssignPolarity: "assign-polarity",
    Attributes: "attributes",
    Back: 'back',
    BackToWork: "back-to-work",
    BuildPlan: "build-plan",
    BuildPlanFurcationSpillout: "buildplan-furcation-spillout",
    Cables: "cables",
    CableDetailsLengthA0: "cable-details-lenght-a0",
    CableDetailsLengthAX: "cable-details-lenght-ax",
    CableDetailsLengthB0: "cable-details-lenght-b0",
    CableDetailsLengthBX: "cable-details-lenght-bx",
    CableDetailsStaggerS0: "cable-details-stagger-s0",
    CableDetailsStaggerSX: "cable-details-stagger-sx",
    CableDrawing: "cable-drawing",
    CableLockedWarning: "cable-locked-warning",
    CableName: "cable-name",
    CableNewName: "cable-new-name",
    CableNumber: "cable-number",
    CableOverview: "cable-overview",
    CablePresetConfirmation: "cable-preset-confirmation",
    CableSetup: "cable-setup",
    CableTrunkLengths: "cable-trunk-lengths",
    Cancel: 'cancel',
    CatalogCode: 'catalog-code',
    CheckedBy: "checked-by",
    Clear: "clear",
    ClearAll: "clear-all",
    ClearFiberMapping: "clear-fiber-mapping",
    ClearFiberMappingMessage: "clear-fiber-mapping-message",
    ClientSignature: "client-signature",
    Close: "close",
    Collapse: "collapse",
    CollapsedTether: "collapsed-tether",
    Colon: "colon",
    Color: "color",
    ColorSelection: "color-selection",
    Comment: "comment",
    Components: "components",
    ConfigConnNumber: "config-conn-number",
    ConfigurationType: "configuration-type",
    Confirm: 'confirm',
    ConnColor: 'conn-color',
    Connector: "connector",
    ConnectorAndLabelSummary: "connector-label-summary",
    ConnectorAssignment: "connector-assignment",
    ConnectorNumber: "connector-number",
    Connectors: "connectors",
    ConnectorsPerGroup: "connectors-per-group",
    ConnectorSelection: "connector-selection",
    ConnectorType: "connector-type",
    ConnType: "conn-type",
    Continue: 'continue',
    CopyNumber: "copy-number",
    CorningCopyright: "corning-copyright",
    Custom: "custom",
    CustomFiberCount: "custom-fiber-count",
    CustomFiberCountValue: "custom-fiber-count-value",
    Customization: "customization",
    CustomizeLabelScheme: "customize-label-scheme",
    CustomLength: "custom-length",
    CustomPolarityUniqueName: "custom-polarity-unique-name",
    CustomTag: "custom-tag",
    Date: "date",
    DateModified: "date-modified",
    DecimalsProhibited: "decimals-prohibited",
    Decrements: "decrements",
    DefaultCustomTag: "default-custom-tag",
    DefaultDate: "default-date",
    DefaultDescription: "default-description",
    DefaultPartNumber: "default-part-number",
    Delete: "delete",
    DeleteCable: "delete-cable",
    DeleteCustomPolarity: "delete-custom-polarity",
    DeleteCustomPolarityMessage: "delete-custom-polarity-message",
    DeleteCustomPolarityWarning: "delete-custom-polarity-warning",
    DeleteCustomPolarityError: "delete-custom-polarity-error",
    DeleteCustomPolaritySnackBarMessage: "delete-custom-polarity-snackbar-message",
    DeleteMessage: "delete-message",
    Description: "description",
    Destination: "destination",
    Disclaimer: "disclaimer",
    DrawingBy: "drawn-by",
    DrawingFeatures: "drawing-features",
    DropPosition: "drop-position",
    DuplicateCable: "duplicate-cable",
    EditPolarityName: "edit-polarity-name",
    EditProperties: "edit-properties",
    EDSTitle: "eds-title",
    Ellipsis: "ellipsis",
    EmptyInputField: "empty-input-field",
    EnableCustomAccesPoints: 'enable-custom-access-points',
    EnterFullscreen: "enter-fullscreen",
    ErrorRetrievingCatalogCode: "error-retrieving-catalog-code",
    Errors: "errors",
    ErrorsAndWarnings: "errors-and-warnings",
    ErrorUpdatingBuildInfo: "error-updating-build-info",
    Examples: "examples",
    ExcessiveDecimalPlaces: "excessive-decimal-places",
    ExitFullscreen: "exit-fullscreen",
    ExitToProductPage: "exit-to-product-page",
    Expand: "expand",
    ExpandedTether: "expanded-tether",
    Feeder: "feeder",
    FeederConnector: "feeder-connector",
    FeederConnectorType: "feeder-connector-type",
    FiberCount: "fiber-count",
    FiberMap: "fiber-map",
    FiberPosition: "fiber-position",
    FiberNumber: "fiber-number",
    FiberType: "fiber-type",
    FieldLengthValidation: "field-length-validation",
    First: "first",
    FitToHeight: "fit-to-height",
    FitToPage: "fit-to-page",
    FitToSource: "fit-to-source",
    FitToWidth: "fit-to-width",
    FlameRating: "flame-rating",
    Footer: "footer",
    GetCatalogCode: "get-catalog-code",
    GripOnEnd: "grip-on-end",
    GroupPosition: "group-position",
    Group: "group",
    Groups: "groups",
    IdleSessionTitle: "idle-session-timeout",
    IdleSessionMessage: "idled-session-message",
    Imperial: "imperial",
    Important: "important",
    Increments: "increments",
    InvalidAPs: "invalid-aps",
    InvalidEntry: "invalid-entry",
    InvalidFiberDistribution: "invalid-fiber-distribution",
    InvalidFiberEntry: "invalid-fiber-entry",
    InvalidWord: "invalid-word",
    Label: "label",
    LabelScheme: "label-scheme",
    LabelSchemeApplyAllWarningMessage: "label-scheme-apply-all-warning-message",
    LabelSchemeEditLabel: "label-scheme-edit-label",
    LabelSchemeWarningMessage: "label-scheme-warning-message",
    LabelSequenceDescription: "label-sequence-description",
    Last: "last",
    LCUniboot: "lc-uniboot",
    LCUnibootViewOnly: "lc-uniboot-view-only",
    LengthAWithUnit: "lengthA-with-unit",
    LengthBWithUnit: "lengthB-with-unit",
    LimitExceeded: "limit-exceeded",
    LoadCable: "load-cable",
    Loading: "loading",
    Location: "location",
    Locked: "locked",
    LoginAgain: "login-again",
    LogOut: "log-out",
    LoadMoreCables: "load-more-cables",
    ManageConnectorColor: "manage-connector-color",
    ManageLabelScheme: "manage-label-scheme",
    Manual: "manual",
    ManufConnNumber: "manuf-conn-number",
    ManufTapNumber: "manuf-tap-number",
    MatchAssignmentColorsWarning: "match-assignment-colors-warning",
    Metric: "metric",
    MissingFibers: "missing-fibers",
    MissingValue: "missing-value",
    MmcFiberCount: "mmc-fiber-count",
    MmcFiberCountViewOnly: "mmc-fiber-count-view-only",
    MtpFiberCount: "mtp-fiber-count",
    MtpFiberCountViewOnly: "mtp-fiber-count-view-only",
    Multiple: "multiple",
    NA: "n-a",
    Name: "name",
    NbAccessPoints: "nb-access-points",
    NbAPs: "nb-aps",
    NbCPG: "nb-cpg",
    NbDstConnectors: "nb-dst-connectors",
    NbGroups: "nb-groups",
    NbSrcConnectors: "nb-src-connectors",
    NewCable: "new-cable",
    Next: "next",
    No: "no",
    NoConnectorsAvailable: "no-connectors-available",
    NoImageAvailable: "no-image-available",
    Notes: "notes",
    Off: "off",
    OK: "ok",
    On: "on",
    OpenIdFailed: "open-id-failed",
    PartNumber: "part-number",
    PartNumberAbr: "part-number-abr",
    PartNumberOverride: "part-number-override",
    PCE: "pce",
    Polarity: "polarity",
    PolarityConfig: "polarity-config",
    PolarityDiagram: "polarity-diagram",
    PolarityImportantDescription: "polarity-important-description",
    PolarityInstructionsDescription: "polarity-instructions-description",
    PolarityInstructionsTitle: "polarity-instructions-title",
    PolarityNameExists: "polarity-name-exists",
    PolarityNewName: "polarity-new-name",
    PolarityType: "polarity-type",
    PolarityUnavailable: "polarity-unavailable",
    Position: "position",
    Previous: "previous",
    Print: "print",
    PrintAllAP: "print-all-ap",
    PrintAPHint: "print-ap-hint",
    PrintReport: "print-report",
    Propagate: "propagate",
    PropagationColorError: "propagation-color-error",
    PropagationColorRestrictionError: "propagation-color-restriction-error",
    PropagationErrorMessage: "propagation-error-message",
    PropagationIdenticalMessage: "propagation-identical-message",
    PropagationLabelError: "propagation-label-error",
    PropagationManyToOneColorError: "propagation-many-to-one-color-error",
    PropagationManyToOneLabelError: "propagation-many-to-one-label-error",
    PropagationMessage: "propagation-message",
    PropagationOneToManyLabelError: "propagation-one-to-many-label-error",
    PropagationPartialSuccessMessage: "propagation-partial-success-message",
    PropagationResultPending: "propagation-result-pending",
    PropagationSuccessful: "propagation-successful",
    PropagationSuccessfulMessage: "propagation-successful-message",
    Processing: "processing",
    PullingGrip: "pulling-grip",
    Quantity: "quantity",
    QuestionMark: "question-mark",
    Racks: "racks",
    RackStartNumber: "rack-start-number",
    ReferenceFootNote: "reference-footnote",
    ReportDraft: "report-draft",
    ReportLenghtB0: "report-lenght-b0",
    ReportLenghtBx: "report-lenght-bx",
    ReportPageTitle: "report-page-title",
    ReportRepresentativeExample: "report-representative-example",
    RepresentativeExample: "representative-example",
    Reset: "reset",
    ResetAll: "reset-all",
    ResetConnectorAssignment: "reset-connector-assignment",
    ResetConnectorAssignmentMessage: "reset-connector-assignment-message",
    ResetConnectorAssignmentOnEdit: "reset-connector-assignment-on-edit",
    ResetConnectorColors: "reset-connector-colors",
    RestrictedFiberCount: "restricted-fiber-count",
    RestrictedFiberCountOptions: "restricted-fiber-count-options",
    ReverseRackOrder: "reverse-rack-order",
    Revision: "revision",
    RevisionNumber: "revision-number",
    RoundBracket: "RoundBracket",
    Row: "row",
    Save: 'save',
    SaveAs: 'save-as',
    Saved: "saved",
    Saving: "saving",
    SaveNewVersion: 'save-new-version',
    SaveNewVersionMessage: 'save-new-version-message',
    SchemeTemplates: "scheme-templates",
    SearchByNameOfDesigner: "search-by-name-of-designer",
    SelectAllConnectors: "select-all-connectors",
    SelectPolarity: "select-polarity",
    SetAsDefault: "set-as-default",
    SetPolarity: "set-polarity",
    ShowConnectorNumber: "show-connector-number",
    SingleModeDrops: "single-mode-drops",
    SortAscending: "sort-ascending",
    SortBy: "sort-by",
    SortDescending: "sort-descending",
    Source: "source",
    SpecificAP: "specific-aps",
    SscModel: "ssc-model",
    SscModelError: "ssc-model-error",
    SScModelErrorSolution: "ssc-model-error-solution",
    StaggeringFootnote: "staggering-footnote",
    StaggerWithUnit: "stagger-with-unit",
    Summary: "summary",
    Table: "table",
    TapNumber: "tap-number",
    Taps: "taps",
    TapsNumber: "taps-number",
    TetherAPSide: "tether-ap-side",
    TextfieldTemplate: "textfield-template",
    ThirdPartyOSSCopyright: "third-party-oss-copyright",
    TimeoutTitle: "timeout-title",
    TimeoutMessage: "timeout-message",
    TotalAssemblyLength: "total-assembly-length",
    TotalFiberCount: "total-fiber-count",
    TriggerHousing: "trigger-housing",
    TrunkConnectorTable: "trunk-connectors-table",
    TrunkDrawing: "trunk-drawing",
    TrunkSide: "trunk-side",
    Type: "type",
    UniformLength: "uniform-length",
    Units: "units",
    Unknown: "unknown",
    UnknownCode: "unknown-code",
    UnknownTAPPosition: "unknown-tap-position",
    UOM: "uom",
    UTC: "utc",
    ValueGreaterThan: "value-greater-than",
    ValueLesserThan: "value-lesser-than",
    ViewProduct: "view-product",
    ViewProductDialogMessage: "view-product-dialog-message",
    ViewProductRedirectMessage: "view-product-redirect-message",
    Version: "version",
    Warnings: "warnings",
    WarningSnackbarMessageErrorsAndWarnings: "warning-snackbar-message-errors-and-warnings",
    WarningSnackbarMessageMultipleErrors: "warning-snackbar-message-multiple-errors",
    WarningSnackbarMessageMultipleWarnings: "warning-snackbar-message-multiple-warnings",
    WarningTitle: "warning-title",
    WizardSubtitle: "wizard-subtitle",
    Yes: "yes",
    Zero: "zero",
    Zulu: "zulu"
} as const;

export type LocalizationKey = typeof LocalizationKeys[keyof typeof LocalizationKeys]
