import { createSlice } from "@reduxjs/toolkit";
import { initialPolarityState } from "./types";
import { 
    setPolarityAction,
    setConnectorAssignmentAction,
    setFiberMappingAction,
    prepareFiberMappingAction,
    generateFiberMappingAction,
    resetPolarityAction,
    addAssignedMapAction,
    deleteAssignedMapAction,
    resetAssignmentMapAction,
    setAssignedMapingAction,
    registerMapAction,
    setEditConnectorAssignmentMapAction,
    setEditConnectorAssignmentFiberMapAction,
    addAssignedMapsAction,
    registerMapsAction,
} from "./actions";

const polaritySlice = createSlice({
    initialState: initialPolarityState,
    name: "polarity",
    reducers: {
        setPolarity: setPolarityAction,
        setConnectorAssignment: setConnectorAssignmentAction,
        setFiberMapping: setFiberMappingAction,
        prepareFiberMapping: prepareFiberMappingAction,
        generateFiberMapping: generateFiberMappingAction,
        resetPolarity: resetPolarityAction,
        addAssignedMap: addAssignedMapAction,
        addAssignedMaps: addAssignedMapsAction,
        resetAssignmentMap: resetAssignmentMapAction,
        deleteAssignedMap: deleteAssignedMapAction,
        setContextAssignedMapping: setAssignedMapingAction,
        registerMap: registerMapAction,
        registerMaps: registerMapsAction,
        setEditConnectorAssignmentMap: setEditConnectorAssignmentMapAction,
        setEditConnectorAssignmentFiberMap: setEditConnectorAssignmentFiberMapAction
    }
});

export const PolarityReducer = polaritySlice.reducer;
export const { 
    setPolarity,
    setFiberMapping,
    prepareFiberMapping,
    generateFiberMapping,
    resetPolarity,
    setEditConnectorAssignmentMap,
    setEditConnectorAssignmentFiberMap,
    setConnectorAssignment,
    addAssignedMap,
    addAssignedMaps,
    resetAssignmentMap,
    deleteAssignedMap,
    setContextAssignedMapping,
    registerMap,
    registerMaps,
} = polaritySlice.actions;
