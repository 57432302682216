import React from "react";
import { IDuplicateDialogProps } from "./types";
import { useDuplicateDialog } from "./hooks";
import { InputDialog } from "../generic-components/InputDialog";
import { DialogContext } from "../../../../../../../../../redux/dialog/reducer";

import "./DuplicateDialog.scss";

export const DuplicateDialog: React.FC<IDuplicateDialogProps> = (props) => {
    const { open, dialogContext, inputDialogProps } = useDuplicateDialog(props);

    if(!open) {
        return null;
    }
    return (
        <DialogContext.Provider value={dialogContext}>
            <InputDialog {...inputDialogProps} />
        </DialogContext.Provider>
    )
}