import i18n from 'i18next'
import { LocalizationKeys } from "../../../../../../../../../../locales/types";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { IBuildData, IBuildInfo } from "../../../../../../../../../redux/build/types"
import { useBuildPolarityDescription } from '../../../../../../../../../redux/build/connector/polarity/hooks';
import { useTranslation } from 'react-i18next';
import { extractBuildInfo } from '../../../../../../../../../redux/build/actions';
import { buildStateSelector } from '../../../../../../../../../selectors/root.selectors';

export const useBuildInfo = (projectId: number) => {
    const { currentBuild, builds } = useSelector(buildStateSelector);
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [polarity, setPolarityDescription] = useState("");
    const [catalogCode, setCatalogCode] = useState("");

    const build = builds.find(b => b.buildId === projectId) ? builds.find(b => b.buildId === projectId)! : extractBuildInfo(currentBuild!);
    const { polarityDescription } = useBuildPolarityDescription(build);

    useEffect(() => {
        if (polarityDescription && polarityDescription.length > 0) {
            setPolarityDescription(`${t(LocalizationKeys.Polarity)}${t(LocalizationKeys.Colon)}${polarityDescription}`)
        }
        else {
            setPolarityDescription("");
        }
    }, [polarityDescription, t])

    useEffect(() => {
        setName(getName(build));
        setCatalogCode(build.catalogCode ?? "");
        setDescription(getDescription(build));
    }, [build, t]);

    return { name, catalogCode, description, polarity };
}

export function getName(build: IBuildInfo) {
    return build.name ? build.name : getDefaultName(build.buildId);
}

export function getDefaultName(buildId?: number) {
    return buildId ? i18n.t(LocalizationKeys.CableNumber) + buildId : i18n.t(LocalizationKeys.CableName);
}

export function generateCopyNumber(str: string, list: string[]) {
    var filteredList = list.filter(x => x.length === str.length);

    for (let i = 0; i < filteredList.length + 1; i++) {
        let copyNumber = i === 0 ? "" : i18n.t(LocalizationKeys.CopyNumber, { value: i });
        let copyStr = str + copyNumber;

        if (!list.find(x => x === copyStr)) {
            return copyStr;
        }
    }

    return str;
}

export function getDescription(buildInfo: IBuildInfo) { 
    return buildInfo.description && buildInfo.description.length > 0 ? buildInfo.description : getDefaultDescription(buildInfo);
}

export function getDefaultDescription(buildInfo: IBuildInfo) {
    const fiberCount = buildInfo.fiberCount!;
    const nbConnectorsSrc = buildInfo.nbConnectorsSrc!;
    const connectorTypeSrc = buildInfo.connectorTypeSrc!;
    const nbTAPs = buildInfo.nbTAPs!;
    const nbConnectorsDst = buildInfo.nbConnectorsDst!;
    const connectorTypeDst = buildInfo.connectorTypeDst!;

    let description = i18n.t(LocalizationKeys.DefaultDescription, { fiberCount: fiberCount, nbConnectorsSrc: nbConnectorsSrc, connectorTypeSrc: connectorTypeSrc, nbTAPs: nbTAPs, nbConnectorsDst: nbConnectorsDst, connectorTypeDst: connectorTypeDst});
    if (description.length > 85) {
        description = description.slice(0, 85) + i18n.t(LocalizationKeys.Ellipsis);
    }

    return description;
}

export function getDefaultBuildDescription(build: IBuildData) {
    const fiberCount = build.source.fiberCount!;
    const nbConnectorsSrc = build.source.groups.flatMap(g => g.connectors).length;
    const connectorTypeSrc = build.source.groups[0].connectors[0].type ?? ""
    const nbTAPs = build.destinations.length;
    const nbConnectorsDst = build.destinations.flatMap(d => d.groups).flatMap(g => g.connectors).length;
    const connectorTypeDst = build.destinations[0].groups[0].connectors[0].type ?? "";

    let description = i18n.t(LocalizationKeys.DefaultDescription, { fiberCount: fiberCount, nbConnectorsSrc: nbConnectorsSrc, connectorTypeSrc: connectorTypeSrc, nbTAPs: nbTAPs, nbConnectorsDst: nbConnectorsDst, connectorTypeDst: connectorTypeDst});
    if (description.length > 85) {
        description = description.slice(0, 85) + i18n.t(LocalizationKeys.Ellipsis);
    }

    return description;
}