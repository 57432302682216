import React from 'react';
import { useInitializer } from './hooks';

export const Background: React.FC = () => {
    useInitializer();

    return (
        <>
        </>
    );
}