import { useEffect, useReducer } from "react";
import { DialogReducer, setDialogState } from "../../../../../../redux/dialog/reducer";
import { initialDialogState } from "../../../../../../redux/dialog/types";
import { FiberMappingSaveDialogProps } from "./types";

export const useFiberMappingSaveDialog = ({ props: dialogProps, type }: FiberMappingSaveDialogProps) => {
    const [state, dispatch] = useReducer(DialogReducer, initialDialogState);

    useEffect(() => {
        dispatch(setDialogState({ props: dialogProps, type} ))
    }, [dialogProps, type, dispatch])

    return {
        context: { state, dispatch }
    }
}