import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PolarityConfig, PolarityMap, LCToLCMapList, BuildPolarity, TypeBMTP8Map, TypeAMTP8Map, TypeBMTP12Map, TypeAMTP12Map, TypeUMTP12Map, TypeUMTP8Map, TypeAMTP24Map, Mesh4x4Map } from "./types";
import { IBuildData, IBuildInfo } from "../../types";
import { getConnectorType, ConnLC, IConnectorType, getConnectorFamilyString } from "../../../../components/overlay/components/wizard/redux/types";
import { TFunction } from "i18next";
import { LocalizationKeys } from "../../../../../locales/types";
import { buildInfoListSelectorFactory } from "../../../../selectors/build.selectors";
import { currentBuildSelector } from "../../../../selectors/root.selectors";

export const useBuildPolarityDescription = (build?: IBuildInfo) => {
    const currentBuild = useSelector(currentBuildSelector);
    const buildInfoList = useSelector(buildInfoListSelectorFactory());
    const [polarityDescription, setPolarityDescription] = useState("");

    useEffect(() => {
        const buildId = !build ? currentBuild!.id : build.buildId;
        const polarityDescription = buildInfoList.find(b => b.buildId === buildId)?.polarityDescription ?? "";
        setPolarityDescription(polarityDescription)
    }, [build, currentBuild, buildInfoList])

    return { polarityDescription };
}

export function getPolarityDescription(appliedConfig: BuildPolarity[], t: TFunction) {
    if (appliedConfig.length > 0) {
        if (appliedConfig.length > 1) {
            return t(LocalizationKeys.Multiple);
        }
        else if (appliedConfig.length === 1 && appliedConfig[0].polarityMap?.description) {
            return appliedConfig[0].polarityMap.description;
        }
    }
    return "";
}

export function matchPolarityConfig(source: PolarityConfig[], target: PolarityConfig[]): PolarityConfig[] {
    const match: PolarityConfig[] = [];
    for (let i = 0; i < source.length; i++) {
        const s = source[i];
        if (s.from && s.to && target.find(t => t.from && t.to && t.from!.fiberCount === s.from!.fiberCount && t.to!.fiberCount === s.to!.fiberCount)) {
            match.push(s);
        }
    }

    return match;
}


export function getBuildPolarityConfig(build: IBuildData, polarityMapList: PolarityMap[]) {
    const buildSrcConnectors = build.source
        .groups.map(g => g.connectors).flat()
        .map(c => c.type).filter((value, i, self) => self.indexOf(value) === i)
        .map(c => getConnectorType(c || ""));

    const buildDstConnectors = build.destinations
        .map(d => d.groups).flat()
        .map(g => g.connectors).flat()
        .map(c => c.type).filter((value, i, self) => self.indexOf(value) === i && value)
        .map(c => getConnectorType(c || ""));

    const polarityConfigsList: PolarityConfig[] = polarityMapsToConfig(polarityMapList);
    const combinations = buildSrcConnectors.flatMap(s => buildDstConnectors.map(d => ({ source: s, destination: d })));
    const polarityConfigs = combinations.flatMap((p) => {
        const configs = polarityConfigsList.filter((c) => (c.from && c.to) && c.from.fiberCount === p.source.fiberCount && c.to.fiberCount === p.destination.fiberCount);
        return configs.length ? configs : { polarityMaps: [], from: p.source, to: p.destination }
    })

    return polarityConfigs.sort((a, b) => {
        const lengthA = a.polarityMaps ? a.polarityMaps.length : 0;
        const lengthB = b.polarityMaps ? b.polarityMaps.length : 0;
        return lengthB - lengthA;
    });
}

export function polarityMapsToConfig(polarityMaps: PolarityMap[]) {
    const polarityConfigs: PolarityConfig[] = []
    for (let i = 0; i < polarityMaps.length; i++) {
        const polarityMap = polarityMaps[i]
        const destinationConnectors = polarityMap.destinationConnectors ?? [];
        const sourceConnectors = polarityMap.sourceConnectors ?? [];
        const uniformMap = isUniformPolarityMap(sourceConnectors, destinationConnectors);

        const config = polarityConfigs.find(c => {
            const config = uniformMap && c.from && c.to &&
                c.from.fiberCount === sourceConnectors[0].fiberCount &&
                c.to.fiberCount === destinationConnectors[0].fiberCount
            const isCustom = !uniformMap && !c.from && !c.to
            return config || isCustom
        })

        if (config) {
            config.polarityMaps = config.polarityMaps ? [...config.polarityMaps, polarityMap] : [polarityMap]
        }
        else if (uniformMap) {
            const newConfig: PolarityConfig = {
                polarityMaps: [polarityMap],
                from: sourceConnectors[0],
                to: destinationConnectors[0]
            }

            polarityConfigs.push(newConfig)
        }

    }

    return polarityConfigs;
}

/**
 * checks if source and destination connectors have a uniform fiber count distribution on their respective sides
 */
function isUniformPolarityMap(sourceConnectors: IConnectorType[], destinationConnectors: IConnectorType[]) {
    const uniformSource = sourceConnectors.length && sourceConnectors.every((c) => c.fiberCount === sourceConnectors[0].fiberCount);
    const uniformDestination = destinationConnectors.length && destinationConnectors.every((c) => c.fiberCount === destinationConnectors[0].fiberCount);
    return uniformSource && uniformDestination
}

export function getPolarityConfigString(sourceType: IConnectorType, destinationType: IConnectorType, t?: TFunction) {
    const source = getConnectorFamilyString(sourceType.type ?? "", sourceType.fiberCount);
    const destination = getConnectorFamilyString(destinationType.type ?? "", destinationType.fiberCount)

    return t ? t(LocalizationKeys.PolarityConfig, { source, destination }) : `${source} to ${destination}`
}


export function getConfigMapping(polarityConfig?: PolarityConfig): PolarityMap[] {
    let polarityMaps: PolarityMap[] = [];
    if (polarityConfig && polarityConfig.from && polarityConfig.to) {
        const isFromLC = polarityConfig.from.key === ConnLC.key;
        const isToLC = polarityConfig.to.key === ConnLC.key;
        const mtpFiberCount = isFromLC ? polarityConfig.to.fiberCount : polarityConfig.from.fiberCount;
        if (isFromLC && isToLC) {
            polarityMaps = LCToLCMapList;
        }
        else if (isToLC) {
            polarityMaps = getMTPToLCMaps(mtpFiberCount);
        }
        else if (polarityConfig.from.fiberCount === polarityConfig.to.fiberCount) {
            polarityMaps = getMTPtoMTPMaps(mtpFiberCount);
        }
        else {
            polarityMaps = [];
        }
    }
    else {
        polarityMaps = [];
    }

    return polarityMaps;
}

function getMTPToLCMaps(fiberCount: number) {
    let polarityMaps: PolarityMap[] = [];
    switch (fiberCount) {
        case 12:
            polarityMaps = [TypeUMTP12Map];
            break;
        case 24:
            polarityMaps = [TypeAMTP24Map];
            break;
        case 8:
            polarityMaps = [TypeUMTP8Map];
            break;
        default:
            break;
    }
    return polarityMaps;
}

function getMTPtoMTPMaps(fiberCount: number) {
    let polarityMaps: PolarityMap[] = [];
    switch (fiberCount) {
        case 12:
            polarityMaps = [TypeBMTP12Map, TypeAMTP12Map];
            break;
        case 8:
            polarityMaps = [TypeBMTP8Map, TypeAMTP8Map, Mesh4x4Map];
            break;
        default:
            break;
    }

    return polarityMaps;
}