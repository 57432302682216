import { WebService } from './web-service';
import config from '../../config.json'

export class OktaService extends WebService {
    constructor() {
        super();
        this.isUnstableApi = false;
    }

    public async getOktaSession(): Promise<OktaSession> {
        const res = await this.client.get<OktaSession>(config.oktaApiHost + '/api/v1/sessions/me', { withCredentials: true });
        return res.data;
    }

    private static oktaSessionId: string | undefined;
    private static LS_OKTA_SESSIONID_KEY = 'okta-session-id';

    public static saveOktaSessionId(sessionId: string): void {
        window.localStorage.setItem(OktaService.LS_OKTA_SESSIONID_KEY, sessionId);
    }

    public static getOktaSessionId(): string | undefined {
        if (!OktaService.oktaSessionId) {
            OktaService.oktaSessionId = window.localStorage.getItem(OktaService.LS_OKTA_SESSIONID_KEY) || undefined;
            return OktaService.oktaSessionId;
        }
        return OktaService.oktaSessionId;
    }
}

/**  This is only the partial object, see doc for full Session object https://developer.okta.com/docs/reference/api/sessions/#session-object */
export interface OktaSession {
    id: string; // sessionId
    userId: string;
    login: string;
}