export interface IConnectorHighlightsState {
    hoveredConnector: IConnectorPosition;
    lastClickedConnector: IConnectorPosition;
    highlights: IHighlight[];
}

export interface IConnectorPosition {
    position: number,
    index: number,
    color?: IHighlightColor;
}

export interface ISelectedConnectors {
    assignedConnectors: IConnectorPosition[];
    unassignedConnectors: IConnectorPosition[];
}

export interface IConnectorAssignmentPosition {
    sourceConnectors: IConnectorPosition[];
    destinationConnectors: IConnectorPosition[];
}

export const initialConnectorPosition: IConnectorPosition = {
    position: -1,
    index: -1,
}

export const initialSelectedConnectors: ISelectedConnectors = {
    assignedConnectors: [],
    unassignedConnectors: [],
}

export const initialConnectorsState: IConnectorHighlightsState = {
    hoveredConnector: initialConnectorPosition,
    lastClickedConnector: initialConnectorPosition,
    highlights: []
}

export interface IHighlightColor {
    fill: string;
    stroke?: string;
}

export const HighlighStatuses = {
    Assigned: "Assigned",
    AssignedSelected: "AssignedSelected",
    Selected: "Selected",
    Hovered: "Hovered"
} as const;

export type HighlighStatus = typeof HighlighStatuses[keyof typeof HighlighStatuses];

export interface IHighlightState {
    assigned: boolean;
    selected: boolean;
    hovered: boolean;
    color?: IHighlightColor;
}

export interface IHighlight {
    position: number;
    index: number;
    status: HighlighStatus;
    color?: IHighlightColor;
}

export const DEFAULT_HIGHLIGHT_STATE: IHighlightState = { assigned: false, selected: false, hovered: false };

export const DEFAULT_BLUE: IHighlightColor = { fill: "#0098DB" };
export const DEFAULT_GREEN: IHighlightColor = { fill: "#4D8809" };

export const WHITE: IHighlightColor = { fill: "#FFFFFF" };
export const GREY: IHighlightColor = { fill: "#9E9E9E" };
export const BLUE: IHighlightColor = { fill: "#3867C1", stroke: "#2545B2" };
export const GREEN: IHighlightColor = { fill: "#59C795", stroke: "#3DB486" };
export const ORANGE: IHighlightColor = { fill: "#F89B1D", stroke: "#F89B1D" }; 
export const RED: IHighlightColor = { fill: "#BC3131", stroke: "#953333" };

export const DistributedColors: IHighlightColor[] = [BLUE, GREEN, ORANGE, RED];

export const toDecimalHex = (color: string | undefined) => {
    return color ? parseInt(color.replace(/^#/, ""), 16) : 0xFFFFFF;
}


