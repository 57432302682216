import { IBuildSession, IGenerateCatalogCodeResponse, IApplyPolarityResponse, IDeletePolarityMapResponse, IConfigSession, IUpdateSessionResponse } from '../../redux/ssc/types';import { IApiResult } from "../api-result";
import { SecuredService } from "../secured-service";
import { IUpdateEDSRequest, IUpdateConfigurationRequest, IApplyPolarityRequest, IApplyConnectorLabelSchemesRequest, IApplyConnectorLabelSchemesResponse, IUpdateBuildInfoRequest } from './types';

export const defaultLabelScheme = "DEFAULT";

export class EDSSessionService extends SecuredService {
    private readonly baseUrl = '/api/ssc/session'

    public async createConfigSession(): Promise<IApiResult<IConfigSession>> {
        return await this.get(`${this.baseUrl}/configuration/options`);
    }

    public async updateConfigurationType(request: IUpdateConfigurationRequest): Promise<IApiResult<IConfigSession>> {
        return await this.put(`${this.baseUrl}/configuration/eds`, request);
    }

    public async updateBuildInfo(request: IUpdateBuildInfoRequest): Promise<IApiResult<IBuildSession>> {
        return await this.put(`${this.baseUrl}/info/eds`, request);
    }

    public async updateEDSCharacteristic(request: IUpdateEDSRequest): Promise<IApiResult<IUpdateSessionResponse>> {
        return await this.put(`${this.baseUrl}/material/eds`, request);
    }

    public async updateConnectorsCharacteristic(request: IUpdateEDSRequest): Promise<IApiResult<IUpdateSessionResponse>> {
        return await this.put(`${this.baseUrl}/material/connectors`, request);
    }

    public async updateDropCharacteristic(request: IUpdateEDSRequest, dropPosition: number): Promise<IApiResult<IUpdateSessionResponse>> {
        return await this.put(`${this.baseUrl}/material/drop/${dropPosition}`, request);
    }

    public async finalizeBuild(sessionId: string): Promise<IApiResult<IGenerateCatalogCodeResponse>> {
        return await this.put(`${this.baseUrl}/${sessionId}/finalize`, null);
    }

    public async applyPolarity(request: IApplyPolarityRequest): Promise<IApiResult<IApplyPolarityResponse>> {
        return await this.put(`${this.baseUrl}/polarity/sets`, request);
    }

    public async deletePolarityMap(sessionId: string, connectorIndex: number): Promise<IApiResult<IDeletePolarityMapResponse>> {
        return await this.delete(`${this.baseUrl}/${sessionId}/polarity/set/${connectorIndex}`);
    }
    public async deletePolarityAllMap(sessionId: string): Promise<IApiResult<IDeletePolarityMapResponse>> {
        return await this.delete(`${this.baseUrl}/${sessionId}/polarity/set/all`);
    }

    public async applyConnectorLabelSchemes(request: IApplyConnectorLabelSchemesRequest): Promise<IApiResult<IApplyConnectorLabelSchemesResponse>> {
        return await this.post(`${this.baseUrl}/label/label-schemes`, request);
    }

    public async endSession(sessionId: string): Promise<IApiResult<void>> {
        return await this.post(`${this.baseUrl}/${sessionId}/destroy`);
    }
}

export const edsSessionService = new EDSSessionService();
