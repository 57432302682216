import { IAuthenticationState, initialToken, initialState } from "./types";
import { AuthenticationService, Token } from "../../../services/authentication-service";
import { Units } from "../../overlay/components/header/components/units-of-measure-container/UnitsOfMeasure";
import { OktaService } from '../../../services/okta-service';
import { PayloadAction } from "@reduxjs/toolkit";

const loadToken = (token: Token | null, state: IAuthenticationState) => {
    state.token = token ? token.raw : undefined;
    state.loggedIn = token ? !token.isExpired() && !!token.email : false;
    state.userId = token ? Number(token.sub) : undefined;
    state.firstName = token ? token.given_name : undefined;
    state.given_name = token ? token.given_name : undefined;
    state.lastName = token ? token.family_name : undefined;
    state.groupId = token ? Number(token.eds_gid) : undefined;
    state.email = token ? token.email : undefined;
    state.expiresAt = token ? token.exp * 1000 : undefined;
    state.issuedAt = token ? token.iat * 1000 : undefined;
};

const loadHybrisCustomerInfo = (state: IAuthenticationState): void => {
    const hybrisCustomer = (window as any).hybriscustomer;
    if (hybrisCustomer) {
        const { customerUid, customerName, customerEmail, accountUid, accountName, soldTo, unitOfMeasure, oktaSessionId } = hybrisCustomer;
        const account = { uid: accountUid, name: accountName, soldTo, unitOfMeasure: unitOfMeasure === "FOT" ? Units.UoMFeet : Units.UoMMeters };
        const customer = { uid: customerUid, name: customerName, email: customerEmail, account, isAnonymous: !!oktaSessionId };
        console.info('Got customer information');
        state.customer = customer;

        if (oktaSessionId) {
            OktaService.saveOktaSessionId(oktaSessionId);
        }

        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        state.expiresAt = date.getTime();
        state.loggedIn = true;
    }
    else {
        console.warn('Unable to get customer information');
    }
}

loadToken(initialToken, initialState);
loadHybrisCustomerInfo(initialState);

export const logoutReducer = (state: IAuthenticationState, action: any) => {
    loadToken(null, state);
}

export const loginSuccessfulReducer = (state: IAuthenticationState, action: any) => {
    state.failed = false;
    const token = Token.fromString(action.payload as string);
    AuthenticationService.saveToken(token?.raw ?? null);
    loadToken(token, state);
}

export const loginFailedReducer = (state: IAuthenticationState, action: any) => {
    loadToken(null, state);
    state.failed = true;
}

export const setUserIdReducer = (state: IAuthenticationState, action: PayloadAction<number>) => {
    state.userId = action.payload;
}