import { IRadioGroup, IRadioLabel } from "./types";
import { InputFieldValue } from "../input/types";
import { useState, useCallback, useEffect } from "react";

export const useRadioGroup = (section: string, defaultValue: string, disabled?: boolean): IRadioGroup => {
    const [value, setValue] = useState(defaultValue);

    const onRadioGroupChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        if (!disabled) {
            setValue(e.currentTarget.value)
        }
    }, [disabled]);

    const onLabelMouseDown = useCallback((e: React.MouseEvent<HTMLLabelElement>) => {
        if (!disabled) {
            setValue(e.currentTarget.innerText)
        }
    }, [disabled])

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue])

    return { value, section, onRadioGroupChange, onLabelMouseDown }
}

export const useRadioInput = (sectionName: string, label: string, value: InputFieldValue, disabled: boolean): IRadioLabel => {
    const [className, setClassName] = useState("");

    useEffect(() => {
        if (disabled) {
            setClassName("label-disabled");
        }
        else {
            setClassName(value === label ? "label-selected" : "")
        }
    }, [value, label, disabled])

    return { label, className, sectionName }
}