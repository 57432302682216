import { Graphics, Container } from "@inlet/react-pixi";
import { useHorizontalTrunk } from "./hooks";
import { DropHorizontalTrunk } from "../../drop-base/types";
import { FeederHorizontalTrunk } from "../../feeder-base/types";
import { Feeder } from "../../../types";

export const HorizontalTrunk: React.FC = () => {
    const { display, section, drawHorizontalTrunk } = useHorizontalTrunk()
    if (!display) {
        return null;
    }

    return (
        <Container>
            <Graphics name={section === Feeder ? FeederHorizontalTrunk : DropHorizontalTrunk} draw={drawHorizontalTrunk}/>
        </Container>
    )
}