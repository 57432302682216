import { Checkbox } from '@orbit/checkbox'
import { Radio, RadioGroup } from '@orbit/radio'
import { ICheckboxInput } from '../../../../../../../../../ui/checkbox/types';
import { IRadioLabel, IRadioGroup } from '../../../../../../../../../ui/radio/types';

export const DrawingSection = (props: { checkBox: ICheckboxInput, collapsedRadio: IRadioLabel, expandedRadio: IRadioLabel, radioGroup: IRadioGroup, disabled?: boolean} ) => {
    const { checkBox, collapsedRadio, expandedRadio, radioGroup, disabled} = props;
    return <div className={"report-section"}>
    <div className={checkBox.className}>
        <Checkbox
            palette='primary'
            label={checkBox.label}
            onChange={checkBox.onCheckBoxChange}
            checked={checkBox.checked}
            disabled={disabled}
            />
    </div>
    <div className="horizontal-field-container">
        <div className="radio-group">
            <RadioGroup value={radioGroup.value} onChange={radioGroup.onRadioGroupChange}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <Radio label={collapsedRadio.label} value={collapsedRadio.label} checked={collapsedRadio.label === radioGroup.value} disabled={checkBox.disabledChildren}/>
                    <Radio label={expandedRadio.label} value={expandedRadio.label} checked={expandedRadio.label === radioGroup.value} disabled={checkBox.disabledChildren}/>
                </div>
            </RadioGroup>
        </div>
    </div>
    <div className="section-divider"/>
</div>
}