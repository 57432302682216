
import { IGenericSnackbarProps } from './redux/types';
import { useGenericSnackbar } from './hooks';
import { ContainedButton } from "@orbit/button"
import { Alert, AlertPalette } from "@orbit/snackbar"

import './GenericSnackbar.scss'

export const GenericSnackbar = (props: IGenericSnackbarProps) => {
    const { type, snackBarButtonProps, snackbarProps } = useGenericSnackbar(props);
    return (
        <div className="snackbar-container">
            <Alert palette={type as AlertPalette}
                variant='filled'
                snackbarProps={snackbarProps}
                action={
                    <div className='snackbar-actions'>
                        {snackBarButtonProps.map((b, i) => <ContainedButton key={i} palette={type} {...b}/>)}
                    </div>
                }
            />
        </div>
    )
}