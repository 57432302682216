import React from 'react';
import { IconButton } from '@mui/material';
import { useDragHandle } from './hooks';
import { IDragHandleProps } from '../types';

import './DragHandle.scss';

export const DragHandle = (props: IDragHandleProps) => {
    const { className, onLoad } = useDragHandle(props);
    return (
        <IconButton className="drag-button" disableRipple={true} onClick={onLoad}>
            <img className={className} alt="Drag" />
        </IconButton>
    );
}