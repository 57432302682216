import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../../locales/types";
import { useSelector, useDispatch } from "react-redux";
import { useEditCallback } from "../wizard-footer/hooks";
import { useState, useEffect, useCallback } from "react";
import { ViewportStatus } from "../../../../../../../pixi/components/viewport/redux/types";
import { setCurrentBuild } from "../../../../../../redux/build/reducers";
import { setToolbarDisplay } from "../../../footer/components/toolbar/redux/reducers";
import { setWizardDisplay } from "../../redux/reducers";
import { setViewportContext } from "../../../../../../../pixi/components/viewport/redux/reducers";
import { authenticationSelector } from "../../../../../authentication/redux/selectors";
import { setStatusState } from "../../../header/components/status/redux/reducer";
import { WorkspaceStatus } from "../../../header/components/status/redux/types";
import { workspaceSelector } from "../../../../../../selectors/root.selectors";
import { restoreBuildSession } from "../../../../../../redux/ssc/reducer";

export const useWizardHeader = () => {
    const { t } = useTranslation();
    const { userId } = useSelector(authenticationSelector);
    const { viewport, toolbar, wizard, builds } = useSelector(workspaceSelector);
    const { context } = viewport;
    const { selected } = toolbar.selection;
    const { currentStep, stepCount, savedBuildData } = wizard;
    const nbBuilds = builds.builds.length;
    const { setIsEditing } = useEditCallback();
    const dispatch = useDispatch();

    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [isClosable, setIsClosable] = useState(true);

    useEffect(() => {
        if (context === ViewportStatus.Editing) {
            if (selected === 0) {
                setTitle(t(LocalizationKeys.Source));
            } else {
                setTitle(t(LocalizationKeys.Destination));
            }
        } else {
            setTitle(t(LocalizationKeys.CableSetup));
        }
    }, [context, t, selected]);

    useEffect(() => {
        if (context === ViewportStatus.Editing) {
            setSubtitle(t(LocalizationKeys.EditProperties));
        } else {
            setSubtitle(t(LocalizationKeys.WizardSubtitle, { currentStep: currentStep, stepCount: stepCount }));
        }
    }, [context, t, currentStep, stepCount]);

    useEffect(() => {
        if (nbBuilds > 0) {
            setIsClosable(true);
        } else {
            setIsClosable(false);
        }
    }, [nbBuilds]);

    const onClose = useCallback(async () => {
        if (savedBuildData) {
            if (context === ViewportStatus.Editing) {
                setIsEditing(false);
                dispatch(setToolbarDisplay(true));
            }
            dispatch(restoreBuildSession())
            dispatch(setCurrentBuild(savedBuildData));
            dispatch(setWizardDisplay(false));
            dispatch(setViewportContext(ViewportStatus.Active));
            
            if (savedBuildData.lockedById !== -1 && savedBuildData.lockedById !== userId) {
                dispatch(setStatusState(WorkspaceStatus.Locked));
            }
        }        
    }, [context, dispatch, setIsEditing, savedBuildData, userId]);

    return { title, subtitle, isClosable, onClose };
}