import { createSlice } from "@reduxjs/toolkit";
import { MutableRefObject } from "react";
import { batch } from "react-redux";
import { checkResult, createAsyncAction, extractData } from "../../../../../redux/actions";
import { addBuilds, setBuilds, setCurrentBuild, updateSpecificConnectors } from "../../../../../redux/build/reducers";
import { IBuildData } from "../../../../../redux/build/types";
import { addProjectAction, removeProjectAction, setProjectManagerAction, setProjectsAction, setRecentProjectAction, setRecentProjectIdAction } from "../../../../../redux/project-manager/actions";
import { PAGE_SIZE } from "../../../../../redux/project-manager/types";
import { ProjectManagerService } from "../../../../../services/projectmanager-service";
import { createdLoadedBuild } from "../../header/components/status/hooks";
import { LabelCustomizationOptions } from "../../reports/components/label/types";
import { closeAllAction, setDrawerDisplayAction, setSelectedBuildAction, setSelectedBuildIdAction, setSortOrderAction, setSortTypeAction, setTotalBuildCountAction, showCableDetailsAction, showCableInfoAction } from './actions';
import { initialState } from './types';
import { IPropagationOptions } from "../../polarity/propagation/redux/types";
import { setPropagationNotification } from "../../notification/store/reducer";

const projectManagerSlice = createSlice({
    name: "projectManager",
    initialState,
    reducers: {
        setDrawerDisplay: setDrawerDisplayAction,
        setProjects: setProjectsAction,
        setTotalBuildCount: setTotalBuildCountAction,
        setRecentProject: setRecentProjectAction,
        setRecentProjectId: setRecentProjectIdAction,
        setProjectManager: setProjectManagerAction,
        removeProject: removeProjectAction,
        addProject: addProjectAction,
        showCableDetails: showCableDetailsAction,
        showCableInfo: showCableInfoAction,
        closeAll: closeAllAction,
        setSelectedBuild: setSelectedBuildAction,
        setSelectedBuildId: setSelectedBuildIdAction,
        setSortType: setSortTypeAction,
        setSortOrder: setSortOrderAction,
    }
});

export const ProjectManagerReducer = projectManagerSlice.reducer;
export const { setDrawerDisplay, setProjects, setRecentProject,
    setRecentProjectId, setProjectManager, removeProject, showCableDetails,
    showCableInfo, closeAll, setSelectedBuildId, setSelectedBuild,
    setSortType, setSortOrder, addProject, setTotalBuildCount } = projectManagerSlice.actions;

export const duplicateProject = (buildId: number, duplicateName: string, sscCallback: (build: IBuildData, applyDefaultLabels?: boolean, saveDefaultColors?: boolean) => Promise<void>, pageIndex: number, search?: string) => {
    return createAsyncAction(async (dispatch) => {
        const service = new ProjectManagerService();
        const { succesful, data } = await service.duplicateProject(buildId, duplicateName, pageIndex, search);
        if (!(succesful && data)) return;
        const { build: duplicatedBuild, builds, totalBuildCount } = data;
        if (duplicatedBuild) {
            const loadedBuild = createdLoadedBuild(duplicatedBuild);
            batch(() => {
                dispatch(setCurrentBuild(loadedBuild));
                dispatch(setBuilds(builds ?? []));
                dispatch(setTotalBuildCount(totalBuildCount ?? 0));
            });
            sscCallback(loadedBuild)
        }
    });
}

export const deleteProject = (buildId: number, pageIndex: number, search?: string) => {
    return createAsyncAction(async (dispatch) => {
        const res = await new ProjectManagerService().deleteBuild(buildId, pageIndex, search);
        if (res.succesful && res.data) {
            const { build, builds, totalBuildCount } = res.data;
            batch(() => {
                dispatch(setTotalBuildCount(totalBuildCount ?? 0));
                dispatch(setBuilds(builds ?? []));
            })
            return build;
        }

        checkResult(res, dispatch);

        return undefined;
    });
}

export const updateSearch = (search: string, currentActiveRequest: MutableRefObject<ProjectManagerService | undefined>) => {
    return createAsyncAction(async (dispatch) => {
        currentActiveRequest.current?.cancel(); // Cancel currently pending search request
        currentActiveRequest.current = new ProjectManagerService();

        const service = currentActiveRequest.current;

        const res = await service.getBuildsForGroupByPage(0, search);
        if (res.succesful && res.data) {
            dispatch(setBuilds(res.data));
        }

        // Check if it is still the current pending request (i.e. didn't get cancelled)
        if (currentActiveRequest.current === service) {
            currentActiveRequest.current = undefined;
        }

        checkResult(res, dispatch);
    });
}

export const updateSortType = (sortType: string, pageIndex: number, search?: string) => {
    return createAsyncAction(async (dispatch) => {
        dispatch(setSortType(sortType));
        const service = new ProjectManagerService();
        const res = await service.updateSortType(sortType, pageIndex, search);
        if (res.succesful && res.data) {
            dispatch(setBuilds(res.data));
        }

        checkResult(res, dispatch);
    });
}

export const updateSortOrder = (isSortAscending: boolean, pageIndex: number, search?: string) => {
    return createAsyncAction(async (dispatch) => {
        dispatch(setSortOrder(isSortAscending));
        const service = new ProjectManagerService();
        const res = await service.updateSortOrder(isSortAscending, pageIndex, search);
        if (res.succesful && res.data) {
            dispatch(setBuilds(res.data));
        }

        checkResult(res, dispatch);
    });
}

export const applyLabelScheme = (build: IBuildData, schemeId: number, trunkPosition: number, options: LabelCustomizationOptions, propagationOptions: IPropagationOptions) => {
    return createAsyncAction(async (dispatch) => {
        const service = new ProjectManagerService();
        const res = await service.applyLabelScheme(build, schemeId, trunkPosition, options, propagationOptions);
        const data = extractData(res, dispatch);
        if (data) {
            const { connectors, propagationResult } = data;
            const connectorsToUpdate = connectors;
            if (propagationResult) {
                const { status, connectors } = propagationResult;
                connectorsToUpdate.push(...connectors);
                dispatch(setPropagationNotification({ status, ...propagationOptions }));
            }
            dispatch(updateSpecificConnectors(connectorsToUpdate));
        }
    })
}

export const getProjectsPage = (page: number, search?: string) => {
    return createAsyncAction(async (dispatch) => {
        const service = new ProjectManagerService();
        const res = await service.getBuildsForGroupByPage(page, search);
        let isLastPage = false;
        if (res.succesful && res.data) {
            const buildsList = res.data;
            if (buildsList.length > 0) {
                dispatch(addBuilds(buildsList));
            }
            // A page that is not full indicates that it is the last page
            isLastPage = buildsList.length < PAGE_SIZE;
        }
        checkResult(res, dispatch);
        return isLastPage;
    })
}