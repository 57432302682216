import { useTranslation } from "react-i18next"
import { LocalizationKeys } from "../../../../../../locales/types";
import { useDispatch, useSelector } from "react-redux";
import { isPolarityModeSelector, showConnectorAssignmentSelector, showFiberMappingSelector } from "../../../redux/selectors";
import { useCallback, useContext, useEffect, useState } from "react";
import { setShowConnectorAssignment, setShowFiberMapping } from "../../../redux/reducers";
import { PolarityContext } from "../redux/types";
import { currentTAPsFibersSelector } from "../../../../../selectors/build.selectors";
import { prepareFiberMapping } from "../redux/reducer";

export const usePolarityToolbar = () => {
    const { t } = useTranslation();
    const display = useSelector(isPolarityModeSelector);
    const totalFibers = useSelector(currentTAPsFibersSelector);
    const isConnectorAssignment = useSelector(showConnectorAssignmentSelector);
    const isFiberMapping = useSelector(showFiberMappingSelector);
    const [buttonGroupValue, setButtonGroupValue] = useState(t(LocalizationKeys.ConnectorAssignment))
    const { state: polarityState, dispatch: polarityDispatch } = useContext(PolarityContext);
    const storeDispatch = useDispatch();

    useEffect(() => {
        isFiberMapping ? setButtonGroupValue(t(LocalizationKeys.FiberPosition)) : setButtonGroupValue(t(LocalizationKeys.ConnectorAssignment))
    }, [isFiberMapping, t])

    const destinationMapping = polarityState.assignmentMapping.flatMap(m => m.destinationMapping)
    const sourceMapping = polarityState.assignmentMapping.flatMap(m => m.sourceMapping)
    const assignedFibers = destinationMapping.length ? 
        destinationMapping.map(dm => dm.fiberCount - dm.unassignedFibers)
        .reduce((a, b) => a + b, 0) : 0;
    
    const destinationBlockedFibers = destinationMapping.length ? 
        destinationMapping.map(sm => sm.blockedFiberCount || 0)
        .reduce((a,b) => a + b, 0) : 0;

    const sourceBlockedFibers = sourceMapping.length ? 
        sourceMapping.map(sm => sm.blockedFiberCount || 0)
        .reduce((a,b) => a + b, 0) : 0;

    const blockedFibers = Math.max(sourceBlockedFibers, destinationBlockedFibers)
    const fiberCount  = totalFibers - blockedFibers
    const percentage = (assignedFibers / fiberCount) * 100;

    const progressProps = {
        title: t(LocalizationKeys.AssignedFibers),
        percentage,
        percentageText: `${assignedFibers}/${fiberCount}`
    }

    const onConnectorAssignmentClick = useCallback(() => {
        storeDispatch(setShowFiberMapping(false));
        storeDispatch(setShowConnectorAssignment(true));
    }, [storeDispatch]);

    const connectorAssignmentProps = {
        className: isConnectorAssignment ? "connector-assignment-button polarity-toolbar-button active" : "connector-assignment-button polarity-toolbar-button",
        text: t(LocalizationKeys.ConnectorAssignment),
        onClick: onConnectorAssignmentClick
    }

    const onFiberMappingClick = useCallback(() => {
        const sourceIndex = Math.min(...polarityState.assignmentMapping.flatMap(m => m.sourceMapping.map(sm => sm.index)));
        polarityDispatch(prepareFiberMapping({ connectorIndex: sourceIndex, assignmentMap: polarityState.assignmentMapping.find(m => m.sourceMapping.some(sm => sm.index === sourceIndex))!}));
        storeDispatch(setShowFiberMapping(true));
        storeDispatch(setShowConnectorAssignment(false));
    }, [polarityState.assignmentMapping, polarityDispatch, storeDispatch]);

    const fiberMappingProps = {
        className: isFiberMapping ? "fiber-mapping-button polarity-toolbar-button active" : "fiber-mapping-button polarity-toolbar-button",
        disabled: polarityState.assignmentMapping.length === 0,
        text: t(LocalizationKeys.FiberPosition),
        onClick: onFiberMappingClick,
    }

    const onClose = useCallback(() => {
        if (isConnectorAssignment) {
            storeDispatch(setShowConnectorAssignment(false));
        }
        if (isFiberMapping) {
            storeDispatch(setShowFiberMapping(false));
        }

        setButtonGroupValue(t(LocalizationKeys.ConnectorAssignment))
    }, [isConnectorAssignment, isFiberMapping, storeDispatch, t])

    const closeProps = {
        icon: "close",
        tooltip: t(LocalizationKeys.Close),
        labelBottom: true,
        onClick: onClose
    }

    return { display, progressProps, connectorAssignmentProps, fiberMappingProps, closeProps, buttonGroupValue }
}