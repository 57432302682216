import i18n from 'i18next'
import { LocalizationKeys } from "../../../../../../locales/types";
import { LC, MTP8F_NP, MTP12F_NP, MTP24F_NP, MTP8F_P, MTP12F_P, MTP12F_FP_NP, MTP12F_FP_P, MTP24F_P, MTP24F_FP_NP, MTP24F_FP_P, MMC16F_NP, MMC16F_P, MMC24F_NP, MMC24F_P, isMTP, isMMC, LCPC, MMC8F_NP, MMC8F_P } from "../../../../../../pixi/factories/Texture";
import { IBuildData } from "../../../../../redux/build/types";
import { IRange } from "../../../../../redux/ssc/types";
import { IUnitOfMeasure, roundToDecimalBasedOnUnit, roundToDecimalPoint, Unit, UoMInches } from "../../header/components/units-of-measure-container/UnitsOfMeasure";

export interface IWizardState {
    display: boolean,
    stepCount: number,
    currentStep: number,
    isNextEnabled: boolean,
    applyEditChanges: boolean,
    dropCheckboxDisabled: boolean,
    savedBuildData?: IBuildData
}

export const initialState: IWizardState = {
    display: false,
    stepCount: 2,
    currentStep: 1,
    isNextEnabled: false,
    applyEditChanges: false,
    dropCheckboxDisabled: false
}

export interface IFiberCount {
    key: string,
    description: string;
    count: number,
    code?: string,
}

export interface IFiberCountRange {
    min: number,
    max: number,
    description: string,
}

export interface IStaggerLength {
    key: string,
    value: IUnitOfMeasure;
}

export const Stagger0: IStaggerLength = { key: '0', value: { unit: UoMInches, value: 0 } };
export const Stagger6: IStaggerLength = { key: '6', value: { unit: UoMInches, value: 6 } };
export const Stagger12: IStaggerLength = { key: '12', value: { unit: UoMInches, value: 12 } };
export const Stagger18: IStaggerLength = { key: '18', value: { unit: UoMInches, value: 18 } };
export const Stagger24: IStaggerLength = { key: '24', value: { unit: UoMInches, value: 24 } };
export const Stagger36: IStaggerLength = { key: '36', value: { unit: UoMInches, value: 36 } };

export const StaggerLengthTypes = [
    Stagger0,
    Stagger6,
    Stagger12,
    Stagger18,
    Stagger24,
    Stagger36
];

export const StaggerLength = {
    Stagger0,
    Stagger6,
    Stagger12,
    Stagger18,
    Stagger24,
    Stagger36
}

export type StaggerLengths = typeof StaggerLength[keyof typeof StaggerLength]

export interface IConnectorType {
    id?: string,
    key: string,
    description: string | null,
    type?: string,
    fiberCount: number,
    pinned?: boolean
}

// Originally we were going to use the part # as the key but some connectors do not have a part #, assigning incrementing key values
export const ConnLC: IConnectorType = { key: '1', description: LC, type: LC, fiberCount: 2, id: "LCUDUP-UNI" };
export const ConnLCPC: IConnectorType = { key: '2', description: LCPC, type: LCPC, fiberCount: 2 , id: "LCDUP-UNI" };
export const ConnMMC8_NP: IConnectorType = { key: '3', description: MMC8F_NP, type: MMC8F_NP, fiberCount: 8, pinned: false, id: "MMC-8" };
export const ConnMMC8_P: IConnectorType = { key: '4', description: MMC8F_P, type: MMC8F_P, fiberCount: 8, pinned: true, id: "MMC-8-PIN" };
export const ConnMTP8_NP: IConnectorType = { key: '5', description: MTP8F_NP, type: MTP8F_NP, fiberCount: 8, pinned: false, id: "MTP-8-SL" };
export const ConnMTP8_P: IConnectorType = { key: '6', description: MTP8F_P, type: MTP8F_P, fiberCount: 8, pinned: true, id: "MTP-8-PIN-SL" };
export const ConnMTP12_NP: IConnectorType = { key: '7', description: MTP12F_NP, type: MTP12F_NP, fiberCount: 12, pinned: false, id: "MTP-12-SL" };
export const ConnMTP12_P: IConnectorType = { key: '8', description: MTP12F_P, type: MTP12F_P, fiberCount: 12, pinned: true, id: "MTP-12-PIN-SL" };
export const ConnMTP12_FP_NP: IConnectorType = { key: '9', description: MTP12F_FP_NP, type: MTP12F_FP_NP, fiberCount: 12, pinned: false, id: "MTP-12-S" };
export const ConnMTP12_FP_P: IConnectorType = { key: '10', description: MTP12F_FP_P, type: MTP12F_FP_P, fiberCount: 12, pinned: true, id: "MTP-12-PIN-SL" };
export const ConnMMC16_NP: IConnectorType = { key: '11', description: MMC16F_NP, type: MMC16F_NP, fiberCount: 16, pinned: false, id: "MMC-16" };
export const ConnMMC16_P: IConnectorType = { key: '12', description: MMC16F_P, type: MMC16F_P, fiberCount: 16, pinned: true, id: "MMC-16-PIN" };
export const ConnMTP24_P: IConnectorType = { key: '13', description: MTP24F_P, type: MTP24F_P, fiberCount: 24, pinned: true, id: "MTP-24-PIN-SL" };
export const ConnMTP24_NP: IConnectorType = { key: '14', description: MTP24F_NP, type: MTP24F_NP, fiberCount: 24, pinned: false, id: "MTP-24-SL" };
export const ConnMTP24_FP_NP: IConnectorType = { key: '15', description: MTP24F_FP_NP, type: MTP24F_FP_NP, fiberCount: 24, pinned: false, id: "MTP-24-SL" };
export const ConnMTP24_FP_P: IConnectorType = { key: '16', description: MTP24F_FP_P, type: MTP24F_FP_P, fiberCount: 24, pinned: true, id: "MTP-24-PIN-SL" };
export const ConnMMC24_NP: IConnectorType = { key: '17', description: MMC24F_NP, type: MMC24F_NP, fiberCount: 24, pinned: false, id: "MMC-24" };
export const ConnMMC24_P: IConnectorType = { key: '18', description: MMC24F_P, type: MMC24F_P, fiberCount: 24, pinned: true, id: "MMC-24-PIN" };

export const ConnectorTypes = [
    ConnLC,
    ConnLCPC,
    ConnMMC8_NP,
    ConnMMC8_P,
    ConnMTP8_NP,
    ConnMTP8_P,
    ConnMTP12_NP,
    ConnMTP12_P,
    ConnMTP12_FP_NP,
    ConnMTP12_FP_P,
    ConnMMC16_NP,
    ConnMMC16_P,
    ConnMTP24_NP,
    ConnMTP24_P,
    ConnMTP24_FP_NP,
    ConnMTP24_FP_P,
    ConnMMC24_NP,
    ConnMMC24_P
];

export const getConnectorType = (type: string): IConnectorType => {
    switch (type) {
        case ConnLC.type:
            return ConnLC;
        case ConnLCPC.type:
            return ConnLCPC;
        case ConnMMC8_NP.type:
            return ConnMMC8_NP;
        case ConnMMC8_P.type:
            return ConnMMC8_P;
        case ConnMTP8_NP.type:
            return ConnMTP8_NP;
        case ConnMTP8_P.type:
            return ConnMTP8_P;
        case ConnMTP12_NP.type:
            return ConnMTP12_NP;
        case ConnMTP12_P.type:
            return ConnMTP12_P;
        case ConnMTP12_FP_NP.type:
            return ConnMTP12_FP_NP;
        case ConnMTP12_FP_P.type:
            return ConnMTP12_FP_P;
        case ConnMMC16_NP.type:
            return ConnMMC16_NP;
        case ConnMMC16_P.type:
            return ConnMMC16_P;
        case ConnMTP24_NP.type:
            return ConnMTP24_NP;
        case ConnMTP24_P.type:
            return ConnMTP24_P;
        case ConnMTP24_FP_NP.type:
            return ConnMTP24_FP_NP;
        case ConnMTP24_FP_P.type:
            return ConnMTP24_FP_P;
        case ConnMMC24_NP.type:
            return ConnMMC24_NP;
        case ConnMMC24_P.type:
            return ConnMMC24_P;
        default:
            return { key: "-1", description: "ConnectorNotFound", fiberCount: -1 };
    }
}

export const getConnectorFamilyString = (type: string, fiberCount: number) => {
    if (type) {
        if (isMTP(type)) {
            return `MTP${fiberCount}`;
        } else if (isMMC(type)) {
            return `MMC${fiberCount}`
        } else {
            return "LC"
        }
    }
    return "Unknown";
}

export interface ICableType {
    key: string,
    description: string | null;
}

export const ConfigurationType = {
    Patching: "E",
    Structured: "S"
}

export const Fiber48 = { key: '48', description: '48 F', count: 48, code: '48' };
export const Fiber60 = { key: '60', description: '60 F', count: 60 };
export const Fiber72 = { key: '72', description: '72 F', count: 72 };
export const Fiber84 = { key: '84', description: '84 F', count: 84 };
export const Fiber96 = { key: '96', description: '96 F', count: 96 };
export const Fiber144 = { key: '144', description: '144 F', count: 144, code: 'E4' };
export const Fiber216 = { key: '216', description: '216 F', count: 216 };
export const Fiber288 = { key: '288', description: '288 F', count: 288, code: 'U8' };
export const Fiber576 = { key: '576', description: '576 F', count: 576, code: 'AZ' };
export const Fiber864 = { key: '864', description: '864 F', count: 864, code: 'CE' };
export const Fiber1728 = { key: '1728', description: '1728 F', count: 1728, code: 'H2' };
export const Fiber3456 = { key: '3456', description: '3456 F', count: 3456 };

export const FiberCounts: IFiberCount[] = [
    Fiber48,
    Fiber60,
    Fiber72,
    Fiber84,
    Fiber96,
    Fiber144,
    Fiber288,
    Fiber576,
    Fiber864,
    Fiber1728
];

export interface IStaggerValidationArgs {
    sLengthValue: number,
    groupCount: number,
    bLengthValue: number,
    bLengthRange: IRange,
    sLengthRange: IRange,
    unit: Unit
}

export function validateStaggerValue(staggerArgs: IStaggerValidationArgs) {
    const { sLengthValue, groupCount, bLengthValue, bLengthRange, sLengthRange, unit } = staggerArgs;
    const staggerBounds = getStaggerRange(sLengthValue, groupCount, bLengthValue, bLengthRange, sLengthRange)
    let valid = true;
    let sLengthErrorText = ''
    if (Number.isNaN(sLengthValue)) {
        valid = false;
        sLengthErrorText = i18n.t(LocalizationKeys.MissingValue);
    } 
    else if (staggerBounds.minSLength > staggerBounds.maxSLength) { // max is based on group count and b length
        valid = false;
        sLengthErrorText = i18n.t(LocalizationKeys.InvalidEntry);
    }
    else if (staggerBounds.totalLegLengthValue > staggerBounds.maxLegLength) {
        sLengthErrorText = i18n.t(LocalizationKeys.ValueGreaterThan, { value: roundToDecimalBasedOnUnit(staggerBounds.maxSLength, unit) }) + unit;
        valid = false;
    }
    else if (sLengthValue < staggerBounds.minSLength && sLengthValue !== 0) { // non required field
        sLengthErrorText = i18n.t(LocalizationKeys.ValueLesserThan, { value: roundToDecimalBasedOnUnit(staggerBounds.minSLength, unit) }) + unit;
        valid = false;
    }

    return { valid, sLengthErrorText, ...staggerBounds }
}

export function getStaggerRange(value: number, groupCount: number, bLengthValue: number, bLengthRange: IRange, sLengthRange: IRange) {
    const totalLegLengthValue = !Number.isNaN(value) ? (value * (groupCount - 1)) + bLengthValue : bLengthValue;
    const maxLegLength = bLengthRange.max;
    const maxSLength = groupCount > 1 ? (maxLegLength - bLengthValue) / (groupCount - 1) : 0;
    const minSLength = groupCount > 1 ? sLengthRange.min : 0;
    const options = getStaggerOptions(minSLength, maxSLength);
    return { totalLegLengthValue, maxLegLength, maxSLength, minSLength, options };
}
export function getStaggerOptions(minSLength: number, maxSLength: number) {
    return StaggerLengthTypes.filter(s => (s.value.value >= minSLength && s.value.value <= maxSLength) || !s.value.value).map(s => roundToDecimalPoint(s.value.value, 4, false));
}

function regexCompare(rawString: string, regex: string) : boolean{
    return rawString.length === 0 || rawString.match(regex) ? true : false;
} 

export function validateWholeNumber(rawString: string) : boolean{
    return regexCompare(rawString, '^[0-9]{0,4}$');
}

export function validateUnSignedDecimal(rawString: string) : boolean{
    return regexCompare(rawString, '^[0-9]{0,4}$|(^[0-9]{0,4}[.]{1}[0-9]{0,4})$');
}