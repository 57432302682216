import { PolarityState, BuildPolarity, BuildPolarityPair, PolarityConfig, getPolarityMap } from "./types"
import { PayloadAction } from "@reduxjs/toolkit"
import { CustomFiberMapData, IFiberMapData } from "./fiber-map/types";
import { IConnectorType } from "../../../../components/overlay/components/wizard/redux/types";
import { IConnectorAssignmentMap } from "../../../../components/overlay/components/polarity/connector-assignment/redux/types";

export const setAppliedPolarityAction = (state: PolarityState, action: PayloadAction<BuildPolarity>) => {
    const { payload } = action;
    const { id, description, from, to, polarityMap } = payload;
    const { configs } = state;
    if (configs) {
        const buildConfig = configs.find(c => c.from!.key === from!.key && c.to!.key === to!.key);
        if (buildConfig) {
            buildConfig.description = description;
            buildConfig.id = id;
            buildConfig.from = from;
            buildConfig.to = to;
            buildConfig.polarityMap = polarityMap;
        }
        else {
            configs.push(payload);
        }
    }
    else {
        state.configs = [payload]
    }
}

export const setBuildPolarityAction = (state: PolarityState, action: PayloadAction<BuildPolarity[]>) => {
    state.configs = action.payload;
}

export const setFilteredPolarityListAction = (state: PolarityState, action: PayloadAction<{ sourceConnectors: IConnectorType[], destinationConnectors: IConnectorType[], polarityConfigs: PolarityConfig[] }>) => {
    const { sourceConnectors, destinationConnectors, polarityConfigs } = action.payload;
    const buildMaps = polarityConfigs.flatMap(c => c.polarityMaps!).filter(m => m)
    const polarityMaps = getPolarityMap(sourceConnectors, destinationConnectors, buildMaps)
    if (polarityMaps.length) {
        const config: PolarityConfig = {
            from: polarityMaps.length ? polarityMaps[0].sourceConnectors![0] : undefined,
            to: polarityMaps.length ? polarityMaps[0].destinationConnectors![0] : undefined,
            polarityMaps: polarityMaps,
        }

        state.selectedConfig = config;
    }
    else {
        state.selectedConfig = undefined;
    }
}

export const setUserPolaritiesAction = (state: PolarityState, action: PayloadAction<BuildPolarityPair[]>) => {
    state.buildConfigList = action.payload;
}


export const setFiberMapsAction = (state: PolarityState, action: PayloadAction<IFiberMapData[] | undefined>) => {
    state.fiberMaps = action.payload;
}

export const setUserFiberMapsAction = (state: PolarityState, action: PayloadAction<CustomFiberMapData[]>) => {
    state.userFiberMaps = action.payload;
}

export const addUserFiberMapsAction = (state: PolarityState, action: PayloadAction<CustomFiberMapData>) => {
    const newFiberMap = action.payload;
    if (state.userFiberMaps) {
        const oldFiberMapIndex = state.userFiberMaps.findIndex(c => c.id === newFiberMap.id);
        if (oldFiberMapIndex !== -1) {
            state.userFiberMaps[oldFiberMapIndex] = newFiberMap;
        }
        else {
            state.userFiberMaps.push(newFiberMap)
        }
    }
    else {
        state.userFiberMaps = [newFiberMap]
    }
}

export const removeUserFiberMapAction = (state: PolarityState, action: PayloadAction<number>) => {
    const id = action.payload;
    if (state.userFiberMaps) {
        state.userFiberMaps = state.userFiberMaps.filter(c => c.id !== id);
    }
}

export const updateUserFiberMapsAction = (state: PolarityState, action: PayloadAction<CustomFiberMapData>) => {
    const newFiberMap = action.payload;
    if (state.userFiberMaps) {
        const oldFiberMapIndex = state.userFiberMaps.findIndex(c => c.id === newFiberMap.id);
        if (oldFiberMapIndex !== -1) {
            state.userFiberMaps[oldFiberMapIndex] = newFiberMap;
        }
    }
}

export const setDocumentFiberMapsAction = (state: PolarityState, action: PayloadAction<IConnectorAssignmentMap[]>) => {
    const connectorAssignmentMaps = action.payload;
    state.connectorAssignments = connectorAssignmentMaps;
}

export const forceReloadConnectorMapsAction = (state: PolarityState) => {
    state.forceConnectorMapReload = { force: !state.forceConnectorMapReload?.force }
}

export const loadConnectorMapAction = (state: PolarityState, action: PayloadAction<number>) => {
    if (state.forceConnectorMapReload) state.forceConnectorMapReload.buildId = action.payload;
}