import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux"
import { LocalizationKeys } from '../../../../../../../../../../locales/types';
import { extractOwnerFromEmail } from '../../../../../../../../../redux/build/types';
import { buildSelectorFactory } from '../../../../../../../../../selectors/build.selectors';

export const useCreationInfo = (projectId: number) => {
    const { t } = useTranslation();
    const build = useSelector(buildSelectorFactory(projectId));
    const date = build && build.lastModified ? new Date(build.lastModified).toLocaleDateString() : t(LocalizationKeys.Unknown);
    const owner = build && build.ownerEmail ? extractOwnerFromEmail(build.ownerEmail): t(LocalizationKeys.Unknown);
    const creationInfoString = `${owner}, ${date}`;
    return { creationInfoString }
}