import { ContainedButton } from "@orbit/button";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../../../locales/types";
import { usePolarityDiagram } from "./hooks";
import { PanAndZoomToolbar } from "./toolbar/PanAndZoomToolbar";
import { IPolarityDiagramProps } from "./types";
import { Canvas } from "./canvas/Canvas";

import "./PolarityDiagram.scss";

export const PolarityDiagram: React.FC<IPolarityDiagramProps> = (props) => {
    const { canvasProps, toolbarProps, title, applyButton, containerRef, canvasRef } = usePolarityDiagram(props);
    const { t } = useTranslation();

    return (
        <div className="polarity-graphic" ref={containerRef}>
            <div className="assign-polarity-graphic-item assign-polarity-apply-container">
                <div className="assign-polarity-title">{title}</div>
                <ContainedButton {...applyButton}>{t(LocalizationKeys.SetAsDefault)}</ContainedButton>
            </div>
            <Canvas ref={canvasRef} {...canvasProps} />
            <PanAndZoomToolbar {...toolbarProps} />
        </div>
    )
}