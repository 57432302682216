import { IHighlightColor } from "../../../../../../pixi/components/decorators/bounds/components/selection/redux/types";
import { INavigationBarProps } from "../../../../../../ui/navigation/types";
import { IDestinationData } from "../../../../../redux/build/destination/types";

export interface IAssignmentRowData {
    id: number;
    mapId?: number;
    position: number;
    groupPosition: number;
    connectorPosition: number;
    fiberCount: number;
    unassignedFibers: number;
    blockedFiberCount: number;
    assigned?: boolean;
    selected?: boolean;
    disabled?: boolean;
    highlighted?: boolean;
    color?: IHighlightColor;
    selectionIndex: number;
    connectorType: string;
}

export interface ITAPNavigationMap {
    leftTAP?: IDestinationData;
    rightTAP?: IDestinationData;
    label: string;
}

export interface ITAPNavigationBarProps {
    navigationBarProps: INavigationBarProps;
    leftTAP?: IDestinationData;
    rightTAP?: IDestinationData;
    previousDisabled: boolean;
    nextDisabled: boolean;
}

export const PolarityAssignments = {
    Standard: "Standard (L to R)",
    Distributed: "Distributed"
}
export type PolarityAssignment = typeof PolarityAssignments [keyof typeof PolarityAssignments];