import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OktaConfig } from '../../../../okta/okta.config';
import { AppDispatch } from "../../../../redux/reducers";
import { login } from "../../redux/reducers";
import { hybrisCustomerSelector, loggedInSelector } from '../../redux/selectors';

export const useLogin = () => {
    const loggedIn = useSelector(loggedInSelector);
    const customer = useSelector(hybrisCustomerSelector);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        OktaConfig.getConfig()
            .then((config) => {
                if (!customer && !config.enabled) {
                    console.error('Okta is disabled, the application may not function correctly');
                }
            });
        dispatch(login());
    }, [dispatch, customer]);

    return { loggedIn };
}