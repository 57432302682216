import { MetaSourceTrunkTexture } from "../../../../factories/Texture";
import { CONNECTOR_STEP } from "../connectors/types";

export const FEEDER_MAX_CONNECTORS_PREEXPAND = 9;

export const TRUNK_BORDER_WIDTH = 20.25;
export const TRUNK_CABLE_WIDTH = 18.25;
export const TRUNK_SHADOW_WIDTH = 5;
export const TRUNK_SHADOW_ALPHA = 0.18;

export const FeederContainer = "feeder-container";
export const FeederBaseContainer = "feeder-base-container";
export const FeederHorizontalTrunk = "feeder-horizontal-trunk";
export const SourceContainer = "source-container";

export function getFeederWidth(nbConnectors: number, isCollapsed: boolean) {
    return MetaSourceTrunkTexture.width - 2 + getHorizontalTrunkWidth(nbConnectors, isCollapsed);
}

function getHorizontalTrunkWidth(nbConnectors: number, isCollapsed: boolean) {
    let nbExtraConnectors = Math.floor(nbConnectors * 0.5) - FEEDER_MAX_CONNECTORS_PREEXPAND;
    let width = nbExtraConnectors * CONNECTOR_STEP > 0 ? nbExtraConnectors * CONNECTOR_STEP : 0;
    return isCollapsed ? 0 : width;
}