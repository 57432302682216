import { useSelector } from "react-redux"
import { getUnitsName, convertTo, Unit, roundToDecimalBasedOnUnit } from "../overlay/components/header/components/units-of-measure-container/UnitsOfMeasure";
import { getConnectorType } from "../overlay/components/wizard/redux/types";
import { TrunkData } from "../../redux/build/types";
import { useEffect, useMemo, useState } from "react";
import { useBuildPlanTable } from "./components/hooks";
import { unitsOfMeasureContainerUnitSelector } from "../overlay/components/header/components/units-of-measure-container/redux/selectors";
import { currentBuildSelector } from '../../selectors/root.selectors';
import { IColor } from "../../../ui/dialog/color/types";
import { AllColors, FiberColors } from "../overlay/components/polarity/fiber-mapping/connector/templates/types";
import { currentStatusSelector } from "../overlay/components/header/components/status/redux/selectors";
import { sscConnectorTypeRecordSelector, sscDefaultTriggersColorSelector, sscSessionPolarityDiagramsSelector } from "../../redux/ssc/selectors";
import { fiberMapsSelector } from "../../redux/build/connector/polarity/selectors";
import { IPolarityDiagram, generate, IPolarityDiagramsProps } from "./components/polarity-diagrams/types";

export const useReportTables = () => {
    const currentStatus = useSelector(currentStatusSelector)
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const unitName = getUnitsName(unit, false, true);
    const aLengthTableValues = useALengthTables();
    const feederBLengthTable = useFeederConnectorTable();
    const dropBLengthTable = useDropTables();
    const { dropTable, feederTable } = useLabelTable();
    const { buildPlanTables } = useBuildPlanTable();
    const { diagrams } = usePolarityDiagrams();

    return { 
        aLengthTableValues, unitName, currentStatus, 
        feederBLengthTable, dropBLengthTable,
        dropTable, feederTable, buildPlanTables, 
        colors: AllColors, fiberColors: FiberColors,
        diagrams
    };
}

const useALengthTables = () => {
    const currentBuild = useSelector(currentBuildSelector)
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const { source, destinations } = currentBuild!;

    const feederA = source.lengthA!;
    const dropA = destinations.map(d => d.lengthA!);
    dropA.pop();
    const alengths = [feederA, ...dropA];

    const aCumulative = [...alengths.map(a => a.value)];

    for (let i = 1; i < aCumulative.length; i++) {
        aCumulative[i] = aCumulative[i] + aCumulative[i - 1]
    }

    const aLengthTableValues = alengths.map((a, i) => {
        let position = i + 1;
        let section = 'A' + i.toString();
        let convertedAlength = roundToDecimalBasedOnUnit(convertTo(a, unit).value, unit);
        let convertedACumulative = roundToDecimalBasedOnUnit(convertTo({ unit: a.unit, value: aCumulative[i] }, unit).value, unit);
        let id = section;
        return {
            id,
            position,
            section,
            aLength: convertedAlength,
            aCumulative: convertedACumulative
        }
    })

    return aLengthTableValues;
}

const useFeederConnectorTable = () => { // This can benefit from the hooks on the connector report
    const { source } = useSelector(currentBuildSelector)!;
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const connectorRecord = useSelector(sscConnectorTypeRecordSelector)
    const defaultTriggerColors = useSelector(sscDefaultTriggersColorSelector)
    
    const { data } = useMemo(() => {
        const connector = connectorRecord[source.groups[0].type ?? ""];
        const defaultColor = connector ? defaultTriggerColors[connector.key].name : ""
        return createTrunkTables(source, unit, AllColors, defaultColor)
    }, [source, unit, connectorRecord, defaultTriggerColors])

    return data;
}

const createTrunkTables = (trunk: TrunkData, unit: Unit, colors: IColor[], defaultColor: string) => {
    const { customBLength } = trunk;
    const connectorGroups = trunk.groups!;
    const data = [];
    const id = trunk.position ? `drop-${trunk.position}-table` : "source-table"
    const stagger = trunk.groups![0].stagger;
    const staggerValue = convertTo(stagger!, unit!).value;
    let groupStagger = 0;
    let connectorId: number = 1;

    for (let i = 0; i < connectorGroups.length; i++) {
        const connectorGroup = connectorGroups[i];
        const lengthB = customBLength && connectorGroup.lengthB ? convertTo(connectorGroup.lengthB, unit) : convertTo(trunk.lengthB!, unit);

        const connectorType = getConnectorType(connectorGroup.type!);
        for (let j = 0; j < connectorGroup.connectors.length; j++) {
            const b = roundToDecimalBasedOnUnit(lengthB.value + groupStagger, unit);
            const { label, color } = connectorGroup.connectors[j];
            const colorIndex = color
                ? colors.findIndex((c) => c.name === color)
                : colors.findIndex((c) => c.name === defaultColor);

            data.push({
                connectorId,
                groupNumber: connectorGroup.position! + 1,
                b,
                label,
                colorIndex,
                connectorType,
                comment: ""
            })

            connectorId++;
        }
        if (!customBLength) {
            groupStagger += staggerValue;
        }
    }

    return { data, id, position: trunk.position! };
}

const useDropTables = () => {
    const { destinations } = useSelector(currentBuildSelector)!;
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const connectorRecord = useSelector(sscConnectorTypeRecordSelector)
    const defaultTriggerColors = useSelector(sscDefaultTriggersColorSelector)

    const dropTables = useMemo(() => {
        return destinations.map(d => {
            const connector = connectorRecord[d.groups[0].type ?? ""];
            const defaultColor = connector ? defaultTriggerColors[connector.key].name : ""
            return createTrunkTables(d, unit, AllColors, defaultColor)
        })
    }, [destinations, unit, connectorRecord, defaultTriggerColors])

    return dropTables
}

const useLabelTable = () => {
    const currentBuild = useSelector(currentBuildSelector)
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const connectorRecord = useSelector(sscConnectorTypeRecordSelector)
    const defaultTriggerColors = useSelector(sscDefaultTriggersColorSelector)

    const dropTable = useMemo(() => {
        if (currentBuild) {
            const destinations = currentBuild.destinations;
            const connectorTables = destinations.map(d => { 
                const connector = connectorRecord[d.groups[0].type ?? ""];
                const defaultColor = connector ? defaultTriggerColors[connector.key].name : ""
                return createTrunkTables(d, unit, AllColors, defaultColor) 
            });
            let labelTable = [];

            for (let i = 0; i < connectorTables.length; i++) {
                const connectorTable = connectorTables[i];

                for (let j = 0; j < connectorTable.data.length; j++) {
                    labelTable.push({ ...connectorTable.data[j], tapPosition: connectorTable.position })
                }
            }
            return labelTable;
        }
        else {
            return [];
        }
    }, [currentBuild, unit, connectorRecord, defaultTriggerColors])

    const feederTable = useMemo(() => {
        const source = currentBuild!.source;
        const connector = connectorRecord[source.groups[0].type ?? ""];
        const defaultColor = connector ? defaultTriggerColors[connector.key].name : ""
        const table = createTrunkTables({ ...source }, unit, AllColors, defaultColor).data;
        let sortedTable = [];
        const groups = source.groups;
        const groupConnectorCount = groups[0].connectors.length;

        for (let i = 0; i < groupConnectorCount; i++) {
            const connectorIndices = groups.map(g => { return i + (g.position!) * groupConnectorCount })
            for (let j = 0; j < connectorIndices.length; j++) {
                const index = connectorIndices[j]
                sortedTable.push(table[index])
            }
        }

        return sortedTable;
    }, [currentBuild, unit, connectorRecord, defaultTriggerColors])

    return { dropTable, feederTable }
}


const usePolarityDiagrams = () => {
    const fiberMaps = useSelector(fiberMapsSelector);
    const sessionDiagrams: IPolarityDiagram[] = useSelector(sscSessionPolarityDiagramsSelector);
    const [customDiagrams, setCustomDiagrams] = useState<IPolarityDiagram[]>([]);
    
    useEffect(() => {
        const initialize = async () => {
            const diagrams = await generate(fiberMaps);
            setCustomDiagrams(diagrams);
        };

        initialize();
    }, [fiberMaps]);

    const diagrams: IPolarityDiagramsProps = { diagrams: [...sessionDiagrams, ...customDiagrams] };

    return { diagrams };
}