import { IUnitOfMeasure, duplicateLength } from "../../../components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure";
import { ConnectorTypes, StaggerLength } from "../../../components/overlay/components/wizard/redux/types";

export interface IConnectorGroupData {
    id?: number,
    position?: number,
    type?: string,
    stagger?: IUnitOfMeasure,
    lengthB?: IUnitOfMeasure,
    connectors: IConnectorData[],
    tapPosition?: number;
}

export interface IConnectorGroupDataWithIndex {
    id?: number,
    position?: number,
    type?: string,
    stagger?: IUnitOfMeasure,
    lengthB?: IUnitOfMeasure,
    connectors: IConnectorDataWithGroupIndex[]
}

export interface IConnectorData {
    id?: number,
    position?: number,
    type?: string,
    stagger?: IUnitOfMeasure,
    label?: string,
    color?: string,
    defaultColor?: string,
    tapPosition?: number,
    groupPosition?: number,
}

export interface IConnectorDataWithGroupIndex extends IConnectorData {
    groupIndex: number;
}

export function getNbConnectors(groups: IConnectorGroupData[]) {
    return groups.length > 0 ? groups.map(g => g.connectors.length!).reduce(function(prev, cur) { return prev + cur; }, 0) : 0;
}

export function duplicateGroup(group: IConnectorGroupData): IConnectorGroupData {
    const { id, ...groupData } = group;
    return {
        ...groupData,
        stagger: duplicateLength(groupData.stagger!),
        connectors: groupData.connectors.map(c => duplicateConnector(c))
    }
}

export function duplicateConnector(connector: IConnectorData): IConnectorData {
    const { id, ...connectorData } = connector;
    return {
        ...connectorData,
        stagger: duplicateLength(connectorData.stagger!),
    }
}

export const initialSourceConnectors = (groupPosition: number): IConnectorData[] => [
    { position: 0, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 1, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 2, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 3, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 4, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 5, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 6, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 7, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 8, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 9, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 10, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 11, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 12, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 13, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 14, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 15, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 16, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 17, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 18, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 19, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
    { position: 20, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, tapPosition: 0, groupPosition },
]

export const initialDestinationConnectors = (tapPosition: number, groupPosition: number) : IConnectorData[] => [
    { position: 0, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger24.value, tapPosition, groupPosition },
    { position: 1, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger24.value, tapPosition, groupPosition },
]

export const initialSourceGroups : IConnectorGroupData[] = [
    { position: 0, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, connectors: initialSourceConnectors(0), tapPosition: 0 },
    { position: 1, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger0.value, connectors: initialSourceConnectors(1), tapPosition: 0 }
]

export const initialDestinationGroups = (tapPosition: number): IConnectorGroupData[] => [ 
    { position: 0, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger24.value, connectors: initialDestinationConnectors(tapPosition, 0) },
    { position: 1, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger24.value, connectors: initialDestinationConnectors(tapPosition, 1) },
    { position: 2, type: ConnectorTypes[0].type, stagger: StaggerLength.Stagger24.value, connectors: initialDestinationConnectors(tapPosition, 2) }
]