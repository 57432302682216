import React from "react";
import { useReports } from "./hooks";
import { ConnectorReportContext } from "./redux/reducers";
import { ConnectorReport } from "./components/connector/ConnectorReport";
import { LabelScheme } from "./components/label/LabelScheme";
import { TriggerColorManagement } from "./components/trigger/TriggerColorManagement";
import { ReportSettings } from "./components/settings/ReportSettings";

export const Reports: React.FC = () => {
    const { context } = useReports();
    return (
        <>
            <ConnectorReportContext.Provider value={context}>
                <ConnectorReport />
                <LabelScheme />
                <TriggerColorManagement />
            </ConnectorReportContext.Provider>
            <ReportSettings />
        </>
    );
};
