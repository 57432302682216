import useResizeObserver from "@react-hook/resize-observer";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { texturesLoadedSelector } from "../workspace/components/overlay/components/header/components/status/redux/selectors";
import { unitsOfMeasureContainerUnitSelector } from "../workspace/components/overlay/components/header/components/units-of-measure-container/redux/selectors";
import { IBuildData } from "../workspace/redux/build/types";
import { trunksSelectorFactory } from "../workspace/selectors/build.selectors";
import { buildStateSelector } from "../workspace/selectors/root.selectors";
import { CableProps } from "./components/build/components/cable/types";
import { cablePosition } from "./types";

export const usePixiComponent = () => {
    const { currentBuild } = useSelector(buildStateSelector);
    const loaded = useSelector(texturesLoadedSelector)
    const unit = useSelector(unitsOfMeasureContainerUnitSelector)
    const windowSize = useWindowSize();
    const store = useStore();

    const trunks = useSelector(trunksSelectorFactory(cablePosition));

    const build = useRef<IBuildData>();
    useEffect(() => {
        if (currentBuild) {
            build.current = { ...currentBuild, unit };
        }
    }, [currentBuild, unit]);

    const cableProps: CableProps = {
        display: loaded && build.current !== undefined,
        trunks,
    };

    return { loaded, cableProps, build, unit, windowSize, store };
};

export const useWindowSize = () => {
    const [size, setSize] = useState({ width: 0, height: 0 });
    const [workspace, setWorkspace] = useState<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        const workspaces = document.getElementsByClassName("workspace");
        if (workspaces.length) {
            setWorkspace(workspaces[0] as HTMLDivElement);
            setSize(workspaces[0].getBoundingClientRect());
        }
    }, []);

    useResizeObserver(workspace, (entry) => {
        setSize(entry.contentRect);
    });

    return size;
}