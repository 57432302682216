import { useCallback, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { batch, useDispatch, useSelector } from "react-redux"
import { LocalizationKeys } from "../../../../../../../../../locales/types"
import { SectionContext, Drop } from "../../../../../../../../../pixi/components/build/types"
import { useRadioGroup, useRadioInput } from "../../../../../../../../../ui/radio/hooks"
import { setCustomBLength, setGroupBLength } from "../../../../../../../../redux/build/reducers"
import { TrunkData } from "../../../../../../../../redux/build/types"
import { Units } from "../../../../../header/components/units-of-measure-container/UnitsOfMeasure"
import { wizardActiveSelector } from "../../../../redux/selectors"

const lengthBOptions = [LocalizationKeys.UniformLength, LocalizationKeys.CustomLength]
export const useBLengthSection = (trunk: TrunkData, disabled: boolean) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isCustom, setIsCustom] = useState(false);
    const [customRadioOption] = useState<string>(trunk && trunk.customBLength ? t(lengthBOptions[1]) : t(lengthBOptions[0]));
    const section = useContext(SectionContext);
    const wizardActive = useSelector(wizardActiveSelector);
    const applyToAllDst = section === Drop && wizardActive;

    const { onRadioGroupChange: onCustomBLengthRadioChange, ...bLengthRadioGroup } = useRadioGroup("bLength", customRadioOption, disabled);
    const uniformRadio = useRadioInput("bLength", t(LocalizationKeys.UniformLength), bLengthRadioGroup.value, disabled);
    const customRadio = useRadioInput("bLength", t(LocalizationKeys.CustomLength), bLengthRadioGroup.value, disabled);
    const onRadioGroupChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        onCustomBLengthRadioChange(e)
        if (e.currentTarget.value) {
            const groups = trunk?.groups ?? [];
            const trunkPosition = trunk?.position ?? 0;
            const trunkLengthB = trunk?.lengthB ?? { unit: Units.UoMInches, value: 0 };
            batch(() => {
                const stagger = groups[0]?.stagger ?? { unit: Units.UoMInches, value: 0 };
                groups.forEach(g => {
                    const groupLengthB = g.lengthB ?? { unit: Units.UoMInches, value: -1 };
                    const groupPosition = g.position ?? 0;
                    if (groupLengthB.value < 0) {
                        const defaultLengthValue = { value: trunkLengthB.value + (groupPosition * stagger.value), unit: trunkLengthB.unit };
                        dispatch(setGroupBLength({ trunkPosition, groupPosition, length: defaultLengthValue, applyToAllDst }));
                    }
                });
            })
        }
    }, [onCustomBLengthRadioChange, dispatch, trunk, applyToAllDst]);

    useEffect(() => {
        setIsCustom(bLengthRadioGroup.value === t(LocalizationKeys.CustomLength));
    }, [bLengthRadioGroup.value, t]);

    useEffect(() => {
        dispatch(setCustomBLength({ trunkPosition: trunk.position!, customBLength: isCustom, applyToAllDst }));
    }, [wizardActive, trunk.position, dispatch, isCustom, applyToAllDst]);

    return { bLengthRadioGroup: { ...bLengthRadioGroup, onRadioGroupChange }, radioOptions: [uniformRadio, customRadio], isCustom }
}