import { createSelector } from "reselect";
import { selectedPositionSelector } from "../../../../components/overlay/components/footer/components/toolbar/redux/selectors";
import { workspaceSelector } from "../../../../selectors/root.selectors";

export const labelSchemeSelector = createSelector(
    workspaceSelector,
    workspace => workspace.labelScheme
);

export const orgBundlesSelector = createSelector(
    labelSchemeSelector,
    labelScheme => labelScheme.orgBundles
);

export const appBundleSelector = createSelector(
    labelSchemeSelector,
    labelScheme => labelScheme.appBundle
);

export const userBundleSelector = createSelector(
    labelSchemeSelector,
    labelScheme => labelScheme.userBundle
);

export const orgSchemesSelector = createSelector(
    orgBundlesSelector,
    orgBundles => orgBundles?.length ? orgBundles.flatMap(o => o.schemeBundle).flatMap(b => b.labelSchemes) : []    
);

export const appSchemesSelector = createSelector(
    appBundleSelector,
    appBundle => appBundle.labelSchemes
);

export const userSchemesSelector = createSelector(
    userBundleSelector,
    userBundle => userBundle.labelSchemes
);

export const defaultSchemesSelector = createSelector(
    orgSchemesSelector,
    appSchemesSelector,
    userSchemesSelector,
    (orgSchemes, appSchemes, userSchemes) => {
        return [...orgSchemes, ...appSchemes, ...userSchemes]
    } 
);

export const schemesSelector = createSelector(
    selectedPositionSelector,
    defaultSchemesSelector,
    (position, defaultSchemes) => {
        return position === 0 ? defaultSchemes.filter(s => s.side === "feeder") : defaultSchemes.filter(s => s.side === "drop")
    }
);