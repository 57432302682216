import { TFunction } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../../../../../locales/types";
import { useCheckBoxInput } from "../../../../../../../../../ui/checkbox/hooks";
import { printSettingsSelector } from "../../../../../../redux/selectors";
import { IDiagramSection, initialReportSettings } from "../../types";
import { IDiagramSectionField } from "./types";

function useDiagramSectionField(name: string, diagram: IDiagramSection, translate: TFunction): IDiagramSectionField {
    const { include, disabled } = diagram;
    const checkbox = useCheckBoxInput(`${name}-checkbox`, translate(name), include, disabled);
    return {
        checkbox: checkbox
    };
}

export const useDiagramSectionFields = () => {
    const printSettings = useSelector(printSettingsSelector);
    const { showPrintDialog, enableFiberMapsOption, enablePolarityDiagramOption } = printSettings;

    const { t } = useTranslation();

    const [buildPlan, setBuildPlan] = useState(initialReportSettings.buildPlan!);
    const [connectorLabel, setConnectorLabel] = useState(initialReportSettings.connectorLabel!);
    const [polarityDiagram, setPolarityDiagram] = useState(initialReportSettings.polarity!);
    const [fiberMap, setFiberMap] = useState(initialReportSettings.fiberMap!);
    const [validDiagramsSection, setValidDiagramsSection] = useState(true);

    const connectorLabelField = useDiagramSectionField(LocalizationKeys.ConnectorAndLabelSummary, connectorLabel!, t);
    const polarityDiagramField = useDiagramSectionField(LocalizationKeys.PolarityDiagram, polarityDiagram!, t);
    const fiberMapField = useDiagramSectionField(LocalizationKeys.FiberMap, fiberMap!, t);
    const buildPlanField = useDiagramSectionField(LocalizationKeys.BuildPlan, buildPlan!, t);

    useEffect(() => {
        const checked = connectorLabelField.checkbox.checked || polarityDiagramField.checkbox.checked || buildPlanField.checkbox.checked || fiberMapField.checkbox.checked;
        if (validDiagramsSection !== checked) {
            setValidDiagramsSection(checked);
        }
    }, [connectorLabelField.checkbox.checked, polarityDiagramField.checkbox.checked, fiberMapField.checkbox.checked, buildPlanField.checkbox.checked, validDiagramsSection])

    useEffect(() => {
        if (showPrintDialog) {
            const disabled = !enablePolarityDiagramOption;
            const include = enablePolarityDiagramOption ? polarityDiagram.include : false;
            if (include !== polarityDiagram.include || disabled !== polarityDiagram.disabled) {
                setPolarityDiagram({ include, disabled });
            }
        }
    }, [showPrintDialog, enablePolarityDiagramOption, polarityDiagram]);

    useEffect(() => {
        if (showPrintDialog) {
            let include = fiberMap.include;
            let disabled = fiberMap.disabled;
            if (enableFiberMapsOption) {
                disabled = false;
            } else {
                disabled = true; 
                include = false;
            }

            if (include !== fiberMap.include || disabled !== fiberMap.disabled) {
                setFiberMap({
                    include, 
                    disabled
                });
            }
        }
    }, [showPrintDialog, enableFiberMapsOption, fiberMap])

    useEffect(() => {
        if (showPrintDialog && !polarityDiagram.disabled && polarityDiagram.include !== polarityDiagramField.checkbox.checked) {
            setPolarityDiagram({
                include: polarityDiagramField.checkbox.checked,
                disabled: polarityDiagram.disabled
            })
        }
    }, [polarityDiagram, polarityDiagramField.checkbox.checked, showPrintDialog]);

    useEffect(() => { 
        if (showPrintDialog && !fiberMap.disabled && fiberMap.include !== fiberMapField.checkbox.checked) {
            setFiberMap({
                include: fiberMapField.checkbox.checked,
                disabled: fiberMap.disabled
            })
        }
    }, [fiberMap, fiberMapField.checkbox.checked, showPrintDialog])

    useEffect(() => {
        if (showPrintDialog) {
            setConnectorLabel({
                include: connectorLabelField.checkbox.checked,
                disabled: false
            })
        }
    }, [showPrintDialog, connectorLabelField.checkbox.checked])
    
    useEffect(() => {
        if (showPrintDialog) {
            if (connectorLabel.include && connectorLabel.disabled) {
                setConnectorLabel({
                    include: false,
                    disabled: true
                })
            }
        }
    }, [showPrintDialog, connectorLabel])

    useEffect(() => {
        if (showPrintDialog) {
            setBuildPlan({
                include: buildPlanField.checkbox.checked,
                disabled: false
            })
        }
    }, [buildPlanField.checkbox.checked, showPrintDialog])

    return {
        validDiagramsSection,
        diagramSectionFields: [connectorLabelField, polarityDiagramField, fiberMapField, buildPlanField],
        fieldValues: { buildPlan, connectorLabel, polarityDiagram, fiberMap }
    }
}