export const WorkspaceStatus = {
    Inactive: "inactive",
    Locked: 'locked',
    Loading: 'loading',
    Ready: 'ready',
    Saving: 'saving',
    Saved: 'saved',
    Reporting: 'reporting',
    Busy: 'busy',
    InitialLoad: 'initial load',
} as const;

export type WorkspaceStatusTypes = typeof WorkspaceStatus [keyof typeof WorkspaceStatus]

export interface StatusState {
    currentStatus: WorkspaceStatusTypes,
    textureStatus: WorkspaceStatusTypes
}

export const initialStatusState: StatusState = {
    currentStatus: WorkspaceStatus.Ready,
    textureStatus: WorkspaceStatus.InitialLoad
}
