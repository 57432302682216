import { useSelector, useDispatch } from "react-redux";
import { useCallback, useState, useEffect } from "react";
import { showCableDetails, closeAll } from "../../../../redux/reducers";
import { ISourceData } from "../../../../../../../../redux/build/source/types";
import { IDestinationData } from "../../../../../../../../redux/build/destination/types";
import { convertTo, Unit, convertToDisplay, getUnitsName } from "../../../../../header/components/units-of-measure-container/UnitsOfMeasure";
import { useLoadProject } from "../row/drag/hooks";
import { getNbConnectors } from "../../../../../../../../redux/build/connector/types";
import { getName } from "../row/build-info/hooks";
import { getLegacyFlameRating } from "../../../../../wizard/components/setup/components/flame-rating/types";
import { sscFlameRatingSelector } from "../../../../../../../../redux/ssc/selectors";
import { projectManagerSelector } from "../../../../../../../../redux/project-manager/selectors";
import { currentBuildSelector } from "../../../../../../../../selectors/root.selectors";
import { unitsOfMeasureContainerUnitSelector } from "../../../../../header/components/units-of-measure-container/redux/selectors";

export const useCableDetails = () => {
    const { showDetails: open, selectedBuild } = useSelector(projectManagerSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const { flameRatingOptions } = useSelector(sscFlameRatingSelector)
    const build = selectedBuild ? selectedBuild : currentBuild
    const catalogCode = build?.catalogCode ?? "";
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const flameRating = getLegacyFlameRating(build!.flameRating!, flameRatingOptions);
    const feederParams = getFeederParameters(build!.source, unit, flameRating!.description);
    const dropParams = getDropParameters(build!.destinations, unit);
    const units = getUnitsName(unit);
    const { onLoad: triggerLoad } = useLoadProject(build!.id!);

    const [pageTitle, setPageTitle] = useState("Cable attributes");
    const [loadDisabled, setLoadDisabled] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (build) {
            setPageTitle(getName(build) + " attributes");
        }
    }, [build])

    const onReturn = useCallback(() => {
        dispatch(showCableDetails(false));
    }, [dispatch]);

    const onClose = useCallback(() => {
        if (open) {
            dispatch(closeAll());
        }
    }, [open, dispatch]);

    useEffect(() => {
        if (selectedBuild && currentBuild) {
            setLoadDisabled(selectedBuild.id === currentBuild.id);
        }
    }, [currentBuild, selectedBuild]);

    const onLoad = useCallback(async () => {
        triggerLoad();
        onClose();
    }, [triggerLoad, onClose]);

    return { pageTitle, catalogCode, open, onReturn, onClose, loadDisabled, onLoad, feederParams, dropParams, units };
}

function getFeederParameters(source: ISourceData, unit: Unit, flameRating: string) {
    const nbConnectors = getNbConnectors(source.groups);
    const nbGroups = source.groups.length;
    const connectorsPerGroups = nbConnectors / nbGroups;
    const lengthA = convertTo(source.lengthA!, unit);
    const lengthB = convertTo(source.lengthB!, unit);
    const stagger = convertTo(source.groups[0].stagger!, unit);
    return {
        fiberCount: source.fiberCount!,
        flameRating: flameRating,
        connectorType: source.groups[0].type!,
        bundles: nbGroups,
        connectorsPerBundle: connectorsPerGroups,
        nbConnectors: nbConnectors,
        lengthA: convertToDisplay(lengthA, unit),
        lengthB: convertToDisplay(lengthB, unit),
        stagger: convertToDisplay(stagger, unit),
        gripOnEnd: source.gripOnEnd!
    }
}

function getDropParameters(destinations: IDestinationData[], unit: Unit) {
    const destination = destinations[0];
    const nbConnectors = getNbConnectors(destination.groups);
    const nbGroups = destination.groups.length;
    const connectorsPerGroups = nbConnectors / nbGroups;
    const lengthA = convertTo(destination.lengthA!, unit);
    const lengthB = convertTo(destination.lengthB!, unit);
    const stagger = convertTo(destination.groups[0].stagger!, unit);
    return {
        nbAccessPoints: destinations.length,
        connectorType: destination.groups[0].type!,
        bundles: nbGroups,
        connectorsPerBundle: connectorsPerGroups,
        nbConnectors: nbConnectors,
        lengthA: convertToDisplay(lengthA, unit),
        lengthB: convertToDisplay(lengthB, unit),
        stagger: convertToDisplay(stagger, unit),
    }
}
