export const PolarityLC: React.FC = () => {
    return (
        <svg id="LC" width={820} height={540} viewBox="0 0 82 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-outside-1_145_578" maskUnits="userSpaceOnUse" x="42.9021" y="25" width="31" height="29" fill="black">
                <rect fill="white" x="42.9021" y="25" width="31" height="29"/>
                <path d="M66.4016 52L45.7748 52L45.7748 27.6945L66.4016 27.6945L71.9021 32.382L71.9021 46.7917L66.4016 52Z"/>
            </mask>

            <path d="M66.4016 52L45.7748 52L45.7748 27.6945L66.4016 27.6945L71.9021 32.382L71.9021 46.7917L66.4016 52Z" fill="#BDBDBD"/>
            <path d="M66.4016 52L66.4016 54L67.1983 54L67.7767 53.4523L66.4016 52ZM45.7748 52L43.7748 52L43.7748 54L45.7748 54L45.7748 52ZM45.7748 27.6945L45.7748 25.6945L43.7748 25.6945L43.7748 27.6945L45.7748 27.6945ZM66.4016 27.6945L67.6989 26.1722L67.1382 25.6945L66.4016 25.6945L66.4016 27.6945ZM71.9021 46.7917L73.2772 48.2439L73.9021 47.6522L73.9021 46.7917L71.9021 46.7917ZM71.9021 32.382L73.9021 32.382L73.9021 31.4586L73.1993 30.8597L71.9021 32.382ZM66.4016 50L45.7748 50L45.7748 54L66.4016 54L66.4016 50ZM47.7748 52L47.7748 27.6945L43.7748 27.6945L43.7748 52L47.7748 52ZM45.7748 29.6945L66.4016 29.6945L66.4016 25.6945L45.7748 25.6945L45.7748 29.6945ZM70.527 45.3394L65.0265 50.5477L67.7767 53.4523L73.2772 48.2439L70.527 45.3394ZM65.1044 29.2167L70.6049 33.9042L73.1993 30.8597L67.6989 26.1722L65.1044 29.2167ZM69.9021 32.382L69.9021 46.7917L73.9021 46.7917L73.9021 32.382L69.9021 32.382Z" fill="black" mask="url(#path-1-outside-1_145_578)"/>
            <rect x="72.2776" y="31.5555" width="3.37512" height="15.8889" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_1" x="81" y="37.8055" width="3.38889" height="4.75024" transform="rotate(90 81 37.8055)" fill="white" stroke="black" strokeWidth="2"/>
            <rect x="61.9639" y="41.2778" width="13.1263" height="2.8611" transform="rotate(-180 61.9639 41.2778)" fill="#E1E1E1" stroke="black" strokeWidth="2"/>
            
            <mask id="path-6-outside-2_145_578" maskUnits="userSpaceOnUse" x="42.9021" y="-0.694461" width="31" height="29" fill="black">
                <rect fill="white" x="42.9021" y="-0.694461" width="31" height="29"/>
                <path d="M66.4016 26.3055L45.7748 26.3055L45.7748 2.00001L66.4016 2.00002L71.9021 6.68751L71.9021 21.0972L66.4016 26.3055Z"/>
            </mask>

            <path d="M66.4016 26.3055L45.7748 26.3055L45.7748 2.00001L66.4016 2.00002L71.9021 6.68751L71.9021 21.0972L66.4016 26.3055Z" fill="white"/>
            <path  d="M66.4016 26.3055L66.4016 28.3055L67.1983 28.3055L67.7767 27.7578L66.4016 26.3055ZM45.7748 26.3055L43.7748 26.3055L43.7748 28.3055L45.7748 28.3055L45.7748 26.3055ZM45.7748 2.00001L45.7748 1.42631e-05L43.7748 1.40865e-05L43.7748 2.00001L45.7748 2.00001ZM66.4016 2.00002L67.6989 0.47779L67.1382 1.61495e-05L66.4016 1.60844e-05L66.4016 2.00002ZM71.9021 21.0972L73.2772 22.5495L73.9021 21.9578L73.9021 21.0972L71.9021 21.0972ZM71.9021 6.68751L73.9021 6.68751L73.9021 5.76418L73.1993 5.16528L71.9021 6.68751ZM66.4016 24.3055L45.7748 24.3055L45.7748 28.3055L66.4016 28.3055L66.4016 24.3055ZM47.7748 26.3055L47.7748 2.00001L43.7748 2.00001L43.7748 26.3055L47.7748 26.3055ZM45.7748 4.00001L66.4016 4.00002L66.4016 1.60844e-05L45.7748 1.42631e-05L45.7748 4.00001ZM70.527 19.645L65.0265 24.8533L67.7767 27.7578L73.2772 22.5495L70.527 19.645ZM65.1044 3.52224L70.6049 8.20974L73.1993 5.16528L67.6989 0.47779L65.1044 3.52224ZM69.9021 6.68751L69.9021 21.0972L73.9021 21.0972L73.9021 6.68751L69.9021 6.68751Z" fill="black" mask="url(#path-6-outside-2_145_578)"/>
            <rect x="72.2776" y="5.86108" width="3.37512" height="15.8889" fill="white" stroke="black" strokeWidth="2"/>
            <rect x="81" y="12.1111" width="3.38889" height="4.75024" transform="rotate(90 81 12.1111)" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_2" x="61.9639" y="15.5834" width="13.1263" height="2.8611" transform="rotate(-180 61.9639 15.5834)" fill="#E1E1E1" stroke="black" strokeWidth="2"/>
            <rect x="26.7485" y="1" width="18.0265" height="52" fill="#E9EEF6" stroke="black" strokeWidth="2"/>

            <path d="M1 16.2745V10.7255L26.1788 1.43489V25.5651L1 16.2745Z" fill="white" stroke="black" strokeWidth="2"/>
            <path d="M1 43.2745V37.7255L26.1788 28.4349V52.5651L1 43.2745Z" fill="white" stroke="black" strokeWidth="2"/>
            <path d="M30.4065 11.4089C30.4065 12.7689 30.3745 13.8569 30.3745 14.5929C30.3745 17.6969 32.0865 18.9769 33.7185 18.9769C34.9505 18.9769 35.8465 18.2249 36.1505 17.0409L36.1665 17.0409C36.5665 18.0489 37.4945 18.6089 38.5185 18.6089C40.2945 18.6089 41.2705 17.3129 41.2705 14.7209C41.2705 13.1049 41.1905 11.4089 41.1905 11.4089L30.4065 11.4089ZM39.5905 13.4889C39.6065 13.8249 39.6225 14.2089 39.6225 14.5769C39.6225 15.9369 39.0305 16.4169 38.2145 16.4169C37.2865 16.4169 36.7105 15.7769 36.7105 14.4169C36.7105 14.0169 36.7105 13.7449 36.7265 13.4889L39.5905 13.4889ZM35.1425 13.4889C35.1585 13.7129 35.1585 14.2729 35.1585 14.4649C35.1585 16.0809 34.5825 16.7369 33.6225 16.7369C32.6945 16.7369 32.0385 16.0809 32.0385 14.6569C32.0385 14.2889 32.0545 13.7449 32.0865 13.4889L35.1425 13.4889Z" fill="black"/>
            <path d="M30.4065 44.782L41.2065 41.15L41.2065 38.446L30.4065 34.83L30.4065 36.926L32.7105 37.678L32.7105 41.742L30.4065 42.478L30.4065 44.782ZM34.3585 41.278L34.3585 38.158L37.7505 39.278C38.2785 39.438 38.8385 39.582 39.3505 39.694L39.3505 39.726C38.8385 39.838 38.2945 39.982 37.7345 40.158L34.3585 41.278Z" fill="black"/>
        </svg>
    )
};