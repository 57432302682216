import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types";
import { setWarningsCardDisplayAction, acknowledgeWarningGroupAction } from './actions';

const warningsSlice = createSlice({
    name: "warnings",
    initialState,
    reducers: {
        setWarningsCardDisplay: setWarningsCardDisplayAction,
        acknowledgeWarningGroup: acknowledgeWarningGroupAction,
    },
});

export const WarningsReducer = warningsSlice.reducer;
export const { 
    setWarningsCardDisplay,
    acknowledgeWarningGroup
} = warningsSlice.actions;
