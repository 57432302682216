import { createSelector } from "reselect";
import { getConnectorType, IConnectorType } from "../../../../components/overlay/components/wizard/redux/types";
import { workspaceSelector } from "../../../../selectors/root.selectors";
import { connectorModelToConnectorType, CustomFiberMapData, IDocumentFiberMap, IFiberMapIndex } from "./fiber-map/types";
import { CUSTOM_MAP_KEY, GetPolaritySessionId, PolarityMap } from "./types";

export const polaritySelector = createSelector(
    workspaceSelector,
    workspace => workspace.polarity
)

export const polarityConfigsSelector = createSelector(
    polaritySelector,
    polarity => polarity.configs ?? []
)

export const connectorAssignmentSelector = createSelector(
    polaritySelector,
    (polarity) => polarity.connectorAssignments ?? [],
);

export const userFiberMapsSelector = createSelector(
    polaritySelector,
    polarity => polarity.userFiberMaps ?? []
)

export const userFiberMapsNameSelector = createSelector(
    userFiberMapsSelector,
    userFiberMaps => Object.fromEntries([...userFiberMaps].map(u => [u.name, u]))
)

export const userPolarityMaps = createSelector(
    userFiberMapsSelector,
    userMaps => generatePolarityMapList(userMaps)
)

export const fiberMapsSelector = createSelector(
    polaritySelector,
    userFiberMapsSelector,
    (polarity, userMaps) => {
        const userFiberMapData: CustomFiberMapData[] = userMaps.filter(c => c.id !== undefined).map(f => {
            return { ...f, key: `${CUSTOM_MAP_KEY}` }
        });

        const fiberMaps: CustomFiberMapData[] = polarity.fiberMaps ? [...polarity.fiberMaps, ...userFiberMapData] : userFiberMapData
        return fiberMaps
    }
)

export const filteredPolarityMapsSelector = createSelector(
    polaritySelector,
    (polarity) => {
        return polarity.selectedConfig && polarity.selectedConfig.polarityMaps ? [...polarity.selectedConfig.polarityMaps] : []
    }
)

export const fiberMapSelectorFactory = (key: number) => {
    return createSelector(
        fiberMapsSelector,
        fiberMaps => fiberMaps[key]
    )
}

export const documentFiberMapsSelector = createSelector(
    connectorAssignmentSelector,
    (connectorAssignments) => {
        const documentFiberMaps: IDocumentFiberMap[] = connectorAssignments
            .map((am) => ({ ...am.fiberMap, key: am.polarityType }))
            .filter((fm): fm is CustomFiberMapData => fm !== undefined)
            .filter(({ key, name }, i, arr) => arr.findIndex((fm) => fm.key === key && fm.name === name) === i)
            .map(({ key, name, id }) => ({
                id,
                sessionId: GetPolaritySessionId(key),
                name,
                accessPointIndices: connectorAssignments
                    .filter((am) => am.fiberMap && am.fiberMap.name === name)
                    .flatMap((am) => am.destinationMapping)
                    .map((dm) => dm.position)
                    .filter((p, i, arr) => arr.indexOf(p) === i)
            }));

        return documentFiberMaps;
    },
);

export const forceReloadSelector = createSelector(
    polaritySelector,
    polarity => polarity.forceConnectorMapReload
)

export function generatePolarityMapList(userMaps: CustomFiberMapData[]) {
    const userPolarity: PolarityMap[] = userMaps.map(u => {
        const polarityMap: PolarityMap = fiberMapDataToPolarityMap(u);
        return polarityMap;
    });

    return [...userPolarity];
}

export function fiberMapDataToPolarityMap(fiberMapData: CustomFiberMapData) {
    const sourceConnectors = getFiberMapDataConnectorTypes(fiberMapData.sourceIndices);
    const destinationConnectors = getFiberMapDataConnectorTypes(fiberMapData.destinationIndices);
    const polarityMap: PolarityMap = {
        userId: fiberMapData.userId,
        description: fiberMapData.name,
        id: fiberMapData.id,
        key: Number.parseInt(fiberMapData.key) ?? CUSTOM_MAP_KEY,
        sessionId: fiberMapData.key,
        customKey: fiberMapData.name,
        imageUri: undefined,
        sourceConnectors,
        destinationConnectors,
        versionDate: fiberMapData.versionDate
    };

    return polarityMap;
}

export function getFiberMapDataConnectorTypes(fiberMapIndices: IFiberMapIndex[]) {
    const uniqueConnectorTypes: { [connectorType: string]: IConnectorType[]; } = {};
    let i = 0
    while (i < fiberMapIndices.length) {
        const index = fiberMapIndices[i];
        const connectorType = index.connectorType && index.connectorType.length > 0 ? getConnectorType(index.connectorType) : connectorModelToConnectorType(index.connector)
        if (connectorType && connectorType.type) {
            const fiberCount = connectorType.fiberCount === 8 ? 12 : connectorType.fiberCount
            if (uniqueConnectorTypes[connectorType.type]) {
                uniqueConnectorTypes[connectorType.type].push({ ...connectorType })
            }
            else if (!uniqueConnectorTypes[connectorType.type]) {
                uniqueConnectorTypes[connectorType.type] = [{ ...connectorType }];
            }

            i += fiberCount
        }
        else {
            i++;
        }
    }

    const connectorTypes = Object.values(uniqueConnectorTypes).flat();
    return connectorTypes;
}

