import { IConnectorType } from "../../../../redux/types";
import { IUnitOfMeasure } from "../../../../../header/components/units-of-measure-container/UnitsOfMeasure";

export interface IDestinationSetupProps {
    disabled?: boolean;
    applyChanges?: (destinationSetup: IDestinationSetupState) => void
}

export interface IDestinationSetupState {
    fiberCount: number,
    position?: number,
    connectorType: IConnectorType,

    accessPoints?: number,
    accessPointsFieldValue?: string,
    isAccessPointsValid?: boolean,
    accessPointsErrorText?: string,

    groupCount?: number,
    groupCountFieldValue?: string,
    isGroupCountValid?: boolean,
    groupCountErrorText?: string,

    nbConnectorsPerGroup?: number
    nbConnectorsPerGroupFieldFieldValue?: string,
    isNbConnectorsPerGroupValid?: boolean,
    nbConnectorsPerGroupErrorText?: string,

    alength?: IUnitOfMeasure,
    aLengthField?: string,
    isALengthValid?: boolean,
    aLengthErrorText?: string,

    blength?: IUnitOfMeasure,
    bLengthField?: string,
    isBLengthValid?: boolean,
    bLengthErrorText?: string,

    slength: IUnitOfMeasure,
    sLengthField?: string,
    isSLengthValid?: boolean,
    sLengthErrorText?: string,
}

export function getAsymmetricAPCount(groupCount: number, connectorsPerGroup: number, connectorType: IConnectorType, fiberCount: number, minValidAPCount: number, maxValidAPCount: number, maxDropFiber: number) {
    const maxAPCount = getMaxAsymmetricAPCount(groupCount, connectorsPerGroup, connectorType, fiberCount, maxDropFiber);
    const accessPoints = adjustValue(maxAPCount, minValidAPCount, maxValidAPCount, Math.max(minValidAPCount, maxAPCount));
    return accessPoints;
}

function adjustValue(value: number, minValidValue: number, maxValidValue: number, maxValue: number) {
    const maxPossibleValue = Math.min(maxValue, maxValidValue)
    const adjustedValue = Math.min(Math.max(value, minValidValue), maxPossibleValue);
    return adjustedValue;
}

export function getMaxAsymmetricAPCount(groupCount: number, connectorsPerGroup: number, connectorType: IConnectorType, fiberCount: number, maxDropFiber: number) {
    const dropFiber = groupCount * connectorsPerGroup * connectorType.fiberCount
    const validDropFiber = Math.min(maxDropFiber, dropFiber)
    const apCount = Math.ceil(fiberCount / validDropFiber)
    return apCount;
}

export function getMaxGroupCount(accessPoints: number, connectorType: IConnectorType, fiberCount: number) {
    if (!accessPoints || !fiberCount) return 1;
    const maxGroupCount = Math.floor(fiberCount / (connectorType.fiberCount * accessPoints))

    return maxGroupCount
}

export function getConnectorsPerGroupCount(accessPoints: number, groupCount: number, connectorType: IConnectorType, fiberCount: number) {
    const maxValidConnectorsPerGroup = Math.floor(fiberCount / (accessPoints * groupCount * connectorType.fiberCount))
    return maxValidConnectorsPerGroup
}

export function getMaxValidConnectorsPerGroupCount(groupCount: number, connectorType: IConnectorType, fiberCount: number) {
    return Math.floor(fiberCount / (groupCount * connectorType.fiberCount))
}