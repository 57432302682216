import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../../../../locales/types";
import { LabelCustomizationContext } from "./reducers/types";
import { setRackNumber, setReverseOrder } from './reducers/reducer';

export const useCustomization = () => {
    const { state: { rackNumber, reverseOrder }, dispatch } = useContext(LabelCustomizationContext);
    const { t } = useTranslation();

    const [rackNumberInput, setRackNumberInput] = useState(rackNumber.toString());

    const onReverseOrderChange = useCallback((e, checked) => {
        dispatch(setReverseOrder(checked));
    }, [dispatch]);

    const onRackNumberChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const rackNumberInput = e.currentTarget.value;
        const rackNumberValue = Number(rackNumberInput);
        if (rackNumberInput.length === 0 || rackNumberValue > 0) {
            setRackNumberInput(rackNumberInput);
            if (rackNumberValue > 0) {
                dispatch(setRackNumber(rackNumberValue))
            }
        }
    }, [dispatch]);

    const onReset = useCallback(() => {
        dispatch(setReverseOrder(false));
        dispatch(setRackNumber(1));
        setRackNumberInput("1");
    }, [dispatch]);

    const resetButtonProps = {
        disabled: rackNumberInput.length === 0,
        onClick: onReset
    };

    return {
        title: t(LocalizationKeys.CustomizeLabelScheme),
        reverseProps: {
            label: t(LocalizationKeys.ReverseRackOrder),
            checkboxProps: {
                value: reverseOrder,
                checked: reverseOrder,
                onChange: onReverseOrderChange
            }
        },
        rackNumberProps: {
            fieldProps: {
                label: t(LocalizationKeys.RackStartNumber)
            },
            inputProps: {
                id: "rack-number-input",
                value: rackNumberInput,
                onChange: onRackNumberChange,
                autoComplete: "off",
                spellCheck: false
            }
        },
        resetProps: {
            buttonProps: resetButtonProps,
            label: t(LocalizationKeys.Reset)
        }
    }
}