import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { GoogleTagManager } from "../../tag-manager";
import { isAnonymousUserSelector } from "../components/authentication/redux/selectors";
import { OktaService } from "../services/okta-service";

export const useOktaSession = () => {
    const [oktaLoaded, setOktaLoaded] = useState(false);
    const isAnonymous = useSelector(isAnonymousUserSelector);
    const navigate = useNavigate()

    useEffect(() => {
        GoogleTagManager.initialize();
    }, []);

    useEffect(() => {
        new OktaService().getOktaSession()
            .then((oktaSession) => {
                OktaService.saveOktaSessionId(oktaSession.id);
                setOktaLoaded(true);
            })
            .catch(() => {
                navigate("/login")
            });
    }, [isAnonymous, navigate]);

    return {
        oktaLoaded: oktaLoaded
    };
};