import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isPolarityModeSelector, showFiberMappingSelector } from "../../../../../../../workspace/components/overlay/redux/selectors";
import { DEFAULT_RECTANGLE_LAYOUT, IRectangleDrawingProps, IRectangleLayout } from "../../../../../decorators/bounds/components/rectangle/types";
import { resetHoveredConnector, setHoveredConnector, setLastClickedConnector } from "../../../../../decorators/bounds/components/selection/redux/reducer";
import { getConnectorHighlightSelectorFactory } from "../../../../../decorators/bounds/components/selection/redux/selectors";
import { DEFAULT_BLUE, DEFAULT_GREEN, HighlighStatuses, IHighlight, ORANGE, toDecimalHex } from "../../../../../decorators/bounds/components/selection/redux/types";
import { viewportContextSelector } from "../../../../../viewport/redux/selectors";
import { ViewportStatus } from "../../../../../viewport/redux/types";
import { IConnectorHighlightProps } from "./types";

export const useConnectorHighlight = ({ position, index, bounds }: IConnectorHighlightProps) => {
    const { context } = useSelector(viewportContextSelector);
    const isPolarity = useSelector(isPolarityModeSelector);
    const isFiberMappingUI = useSelector(showFiberMappingSelector);
    const highlight = useSelector(getConnectorHighlightSelectorFactory(position, index));
    const storeDispatch = useDispatch();

    const onMouseClick = useCallback(() => {
        storeDispatch(setLastClickedConnector({ position, index }));
    }, [position, index, storeDispatch]);

    const selected = highlight ? (highlight.status === HighlighStatuses.Selected || highlight.status === HighlighStatuses.AssignedSelected) : false;
    const onMouseOver = useCallback(() => {
        if (!selected) {
            storeDispatch(setHoveredConnector({ position, index }));
        }
    }, [selected, position, index, storeDispatch]);

    const onMouseOut = useCallback(() => {
        if (!selected) {
            storeDispatch(resetHoveredConnector());
        }
    }, [selected, storeDispatch]);

    const isHighlighted = !!highlight;
    const display = context !== ViewportStatus.Inactive && isHighlighted && isPolarity;
    const interactive = display && !isFiberMappingUI && highlight.status !== HighlighStatuses.Selected;
    const layout = getConnectorLayout(highlight);
    const rectangleProps: IRectangleDrawingProps = { interactive, layout, bounds, onMouseClick, onMouseOver, onMouseOut };

    return { display, rectangleProps };
};

export function getConnectorLayout(highlight: IHighlight | undefined): IRectangleLayout {
    if (!highlight) return DEFAULT_RECTANGLE_LAYOUT;

    const { status, color: highlightColor } = highlight;

    if (highlightColor) {
        const borderColor = toDecimalHex(highlightColor.stroke ?? highlightColor.fill);
        let color: number | undefined = undefined;
        switch(status) {
            case HighlighStatuses.AssignedSelected:
            case HighlighStatuses.Selected:
            case HighlighStatuses.Hovered:
                color = toDecimalHex(highlightColor.fill);
                break;
        }

        return { borderColor, color, alpha: 1 };
    }

    return getDefaultConnectorLayout(highlight);
}

export function getDefaultConnectorLayout(highlight: IHighlight): IRectangleLayout {
    const { status } = highlight;
    const green = toDecimalHex(DEFAULT_GREEN.fill);
    const blue = toDecimalHex(DEFAULT_BLUE.fill);
    const orange = toDecimalHex(ORANGE.fill);

    let borderColor: number = green;
    let color: number | undefined = undefined;

    switch(status) {
        case HighlighStatuses.AssignedSelected:
            borderColor = blue;
            color = blue;
            break;
        case HighlighStatuses.Selected: 
            borderColor = orange;
            color = orange;
            break;
        case HighlighStatuses.Hovered:
            borderColor = green;
            color = green;
            break;
    }

    return { borderColor, color, alpha: 1 };
}