import { Container } from "@inlet/react-pixi";
import { ALine } from "./line/ALine";
import { LineMarker } from "../../line/LineMarker";
import { ILengthProps } from "../types";

export const ALength: React.FC<ILengthProps> = (props: ILengthProps) => {
    const { position, start, line, end } = props;
    const lineProps = line ? { position, line } : undefined;

    return (
        <Container>
            { start ? <LineMarker {...start} /> : null }
            { lineProps ? <ALine {...lineProps} /> : null }
            { end ? <LineMarker {...end} /> : null }
        </Container>
    )
};