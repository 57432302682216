import { setDisplayAction } from "./actions";
import { DrawerActions, DrawerActionTypes, DrawerContextProps } from "./types";

export function DrawerReducer(state: DrawerContextProps, action: DrawerActions): DrawerContextProps {
    switch (action.type) {
        case DrawerActionTypes.SetDisplay:
            return setDisplayAction(state, action.payload);
        default:
            return { ...state }
    }
}