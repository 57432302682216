import { IPolarityState, IConnectorAssignment, initialConnectorAssignment, IConnectorAssignmentIndex } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { PolarityMap, CUSTOM_MAP_KEY } from "../../../../../redux/build/connector/polarity/types";
import { IDestinationPinMap, IFiberMap, ISourcePinMap, IFiberMappingData } from "../fiber-mapping/redux/types";
import { AssignedIndexCodes, CustomFiberMapData, IFiberMapData, IFiberMapIndex } from "../../../../../redux/build/connector/polarity/fiber-map/types";
import { ConnectorBuildMatrix, IConnectorAssignmentMap, IConnectorMap } from "../connector-assignment/redux/types";
import { fiberMapDataToPolarityMap, getFiberMapDataConnectorTypes } from "../../../../../redux/build/connector/polarity/selectors";
import { generateEmptyCustomMap } from "../connector-assignment/hooks";
import { IFiberMapInfo, IFiberMapRowProps } from "../../../../off-screen/components/fiber-map/types";
import i18n from 'i18next'
import { LocalizationKeys } from "../../../../../../locales/types";

export const setPolarityAction = (state: IPolarityState, action: PayloadAction<PolarityMap | undefined>) => {
    state.polarity = action.payload;
}

export const setConnectorAssignmentAction = (state: IPolarityState, action: PayloadAction<IConnectorAssignment>) => {
    state.connectorAssignment = action.payload;
}

export const setFiberMappingAction = (state: IPolarityState, action: PayloadAction<IFiberMap[]>) => {
    state.fiberMapping = action.payload;
}

export const prepareFiberMappingAction = (state: IPolarityState, action: PayloadAction<{ connectorIndex: number | undefined, assignmentMap: IConnectorAssignmentMap }>) => {
    const { connectorIndex, assignmentMap } = action.payload;
    let sourceConnectors: IConnectorAssignmentIndex[] = [];
    let destinations: { tapIndex: number, connectors: IConnectorAssignmentIndex[] }[] = [];
    const assignmentWithSource = state.assignmentMapping.find(m => m.sourceMapping.find(m => m.index === connectorIndex));
    if (assignmentWithSource) {
        for (let sourceMap of assignmentWithSource.sourceMapping) {
            const sourceIndex = sourceMap.index - 1
            if (!sourceConnectors.find(s => s.index === sourceIndex)) {
                sourceConnectors.push({ index: sourceIndex, fiberCount: sourceMap.fiberCount, connectorType: sourceMap.connectorType });
            }
        }

        const destinationMapping = [...assignmentWithSource.destinationMapping].sort((a, b) => a.index > b.index ? 1 : -1)
        for (let i = 0; i < destinationMapping.length; i++) {
            const destinationMap = destinationMapping[i]
            const destinationIndex = destinationMap.index - 1
            const existingDestination = destinations.find(d => d.tapIndex === destinationMap.position);
            if (existingDestination) {
                existingDestination.connectors.push({ index: destinationIndex, fiberCount: destinationMap.fiberCount, connectorType: destinationMap.connectorType });
            } else {
                destinations.push({ tapIndex: destinationMap.position, connectors: [{ index: destinationIndex, fiberCount: destinationMap.fiberCount, connectorType: destinationMap.connectorType }] });
            }
        }
    }
    else {
        sourceConnectors = assignmentMap.sourceMapping.map(m => {
            return { index: m.index - 1, fiberCount: m.fiberCount, connectorType: m.connectorType}
        })
        const destinationMatrix = buildConnectorMapMatrix(assignmentMap.destinationMapping)
        for (let i = 0; i < destinationMatrix.length; i++) {
            const destinationMaps = destinationMatrix[i];
            for (let j = 0; j < destinationMaps.length; j++) {
                const connectorMap = destinationMaps[j];
                const existingDestination = destinations.find(d => d.tapIndex === connectorMap.position)
                if (existingDestination) {
                    existingDestination.connectors.push({ connectorType: connectorMap.connectorType, fiberCount: connectorMap.fiberCount, index: connectorMap.index - 1})
                }
                else {
                    destinations.push({ tapIndex: connectorMap.position, connectors: [{ connectorType: connectorMap.connectorType, fiberCount: connectorMap.fiberCount, index: connectorMap.index - 1}]})
                }
                
            }
            
        }
    }

    // Sorting the indexes in ascending order
    sourceConnectors = sourceConnectors.sort((a, b) => a.index > b.index ? 1 : -1);
    destinations = destinations.sort((a, b) => a.tapIndex > b.tapIndex ? 1 : -1);
    for (let destination of destinations) {
        destination.connectors = destination.connectors.sort((a, b) => a.index > b.index ? 1 : -1);
    }

    const assignment: IConnectorAssignment = {
        source: {
            currentIndex: connectorIndex ? connectorIndex - 1 : 0,
            connectors: sourceConnectors
        },
        destinations
    };

    state.connectorAssignment = assignment;
    state.editAssignmentMap = assignmentMap
    if (assignmentMap.fiberMap) {
        state.polarity = fiberMapDataToPolarityMap(assignmentMap.fiberMap)
    }
}

export const setEditConnectorAssignmentMapAction = (state: IPolarityState, action: PayloadAction<IConnectorAssignmentMap>) => {
    const editAssignmentMap = action.payload;
    state.editAssignmentMap = editAssignmentMap;
    if (editAssignmentMap.fiberMap) {
        state.polarity = fiberMapDataToPolarityMap(editAssignmentMap.fiberMap)
    }
}

export const setEditConnectorAssignmentFiberMapAction = (state: IPolarityState, action: PayloadAction<IFiberMappingData>) => {
    const editMap = state.editAssignmentMap
    if (editMap) {
        const fiberMappingData = action.payload;
        const assignmentMap = getAssignmentMap(state, editMap);
        if (assignmentMap) {
            updateConnectorAssignmentMap(assignmentMap, fiberMappingData);
            if (assignmentMap.fiberMap) state.polarity = fiberMapDataToPolarityMap(assignmentMap.fiberMap)
        }
    }
}

export const generateFiberMappingAction = (state: IPolarityState, action: PayloadAction<IFiberMapData>) => {
    const fiberMap = action.payload;
    if (state.editAssignmentMap) {
        const { fiberMapping } = generateFiberMappingData(state.editAssignmentMap, fiberMap);
        state.fiberMapping = fiberMapping;
    }
}

export const resetPolarityAction = (state: IPolarityState) => {
    state.polarity = undefined;
    state.connectorAssignment = initialConnectorAssignment;
    state.fiberMapping = [];
}

export const setAssignedMapingAction = (state: IPolarityState, action: PayloadAction<IConnectorAssignmentMap[]>) => {
    state.assignmentMapping = action.payload;
}

export const addAssignedMapAction = (state: IPolarityState, action: PayloadAction<IConnectorAssignmentMap>) => {
    const assignmentMap = action.payload;
    if (assignmentMap.sourceMapping.length && assignmentMap.destinationMapping.length) {
        state.assignmentMapping.push(action.payload);
    }
}

export const addAssignedMapsAction = (state: IPolarityState, action: PayloadAction<IConnectorAssignmentMap[]>) => {
    const assignmentMaps = action.payload;
    if (assignmentMaps.every(a => a.sourceMapping.length && a.destinationMapping.length)) {
        state.assignmentMapping.push(...assignmentMaps);
    }
}

export const registerMapAction = (state: IPolarityState, action: PayloadAction<IConnectorAssignmentMap>) => {
    const assignmentMap = action.payload;
    const map = getAssignmentMap(state, assignmentMap)

    if (map) {
        map.destinationMapping = assignmentMap.destinationMapping;
        map.sourceMapping = assignmentMap.sourceMapping;
        map.fiberMap = assignmentMap.fiberMap;
        map.id = assignmentMap.id;
        map.polarityType = assignmentMap.polarityType;
    } else if (assignmentMap.sourceMapping.length && assignmentMap.destinationMapping.length) {
        state.assignmentMapping.push(assignmentMap);
    }
}

export const registerMapsAction = (state: IPolarityState,  action: PayloadAction<IConnectorAssignmentMap[]>) => {
    const assignmentMaps = action.payload;
    assignmentMaps.forEach((m) => {
        const map = getAssignmentMap(state, m)

        if (map) {
            map.sourceMapping = m.sourceMapping;
            map.destinationMapping = m.destinationMapping;
            map.fiberMap = m.fiberMap;
            map.id = m.id;
            map.polarityType = m.polarityType;
        } else if (m.sourceMapping.length && m.destinationMapping.length) {
            state.assignmentMapping.push(m);
        }
    });
} 

export const deleteAssignedMapAction = (state: IPolarityState, action: PayloadAction<number>) => {
    const index = action.payload;
    const assignmentIndex = state.assignmentMapping.findIndex(am => am.sourceMapping.find(m => m.index === index));
    if (assignmentIndex > -1) {
        state.assignmentMapping = state.assignmentMapping.filter((m, i) => i !== assignmentIndex)
    }
}

export const resetAssignmentMapAction = (state: IPolarityState) => {
    state.assignmentMapping = [];
}

function convertToCustomFiberMapData(connectorMap: IConnectorAssignmentMap, fiberMappingData: IFiberMappingData): CustomFiberMapData {
    const { mapping, unused, name, key } = fiberMappingData

    const sourceConnectorMaps = [...connectorMap.sourceMapping].sort((a, b) => a.orderIndex > b.orderIndex ? 1 : -1)
    const destinationConnectorMaps = [...connectorMap.destinationMapping].sort((a, b) => a.orderIndex > b.orderIndex ? 1 : -1)

    const sourceMapMatrix = buildConnectorMapMatrix(connectorMap.sourceMapping)
    const destinationMapMatrix = buildConnectorMapMatrix(connectorMap.destinationMapping)
    const fiberMap = generateEmptyCustomMap(connectorMap, name)
    fiberMap.key = key ?? `${CUSTOM_MAP_KEY}`;
    for (let i = 0; i < mapping.length; i++) {
        const { sourcePin, destinationPin } = mapping[i];
        const destinationConnector = destinationMapMatrix[destinationPin.tapIndex][destinationPin.connectorIndex]
        const sourceConnector = sourceMapMatrix[0][sourcePin.connectorIndex]

        if (sourceConnector && destinationConnector) {
            const sourcePinIndex = getFiberMapIndex(sourceMapMatrix, sourcePin, sourceConnectorMaps, 0)
            const destinationPinIndex = getFiberMapIndex(destinationMapMatrix, destinationPin, destinationConnectorMaps, destinationPin.tapIndex)

            fiberMap.sourceIndices[sourcePinIndex] = {
                ...fiberMap.sourceIndices[sourcePinIndex],
                assignedIndex: sourcePinIndex,
            }
            fiberMap.destinationIndices[destinationPinIndex] = {
                ...fiberMap.destinationIndices[destinationPinIndex],
                assignedIndex: sourcePinIndex,
            }
        }
    }

    const unusedSourcePins = unused.sourcePins
    const unusedDestinationPins = unused.destinationPins

    unusedSourcePins.forEach(p => {
        const sourcePinIndex = getFiberMapIndex(sourceMapMatrix, p, sourceConnectorMaps, 0);
        fiberMap.sourceIndices[sourcePinIndex].assignedIndex = AssignedIndexCodes.Blocked
    })

    unusedDestinationPins.forEach(p => {
        const destinationPinIndex = getFiberMapIndex(destinationMapMatrix, p, destinationConnectorMaps, p.tapIndex);
        fiberMap.destinationIndices[destinationPinIndex].assignedIndex = AssignedIndexCodes.Blocked
    })

    return fiberMap
}

export function updateConnectorAssignmentMap(connectorMap: IConnectorAssignmentMap, fiberMapping: IFiberMappingData): IConnectorAssignmentMap {
    if (connectorMap) {
        const fiberMap = convertToCustomFiberMapData(connectorMap, fiberMapping)
        connectorMap.polarityType = fiberMap.name
        const sourceMapping: IConnectorMap[] = []
        const destinationMapping: IConnectorMap[] = []

        connectorMap.fiberMap = fiberMap;

        const sortedSourceMaps = [...connectorMap.sourceMapping].sort((a, b) => a.orderIndex > b.orderIndex ? 1 : -1)
        let startIndex = 0;
        for (let i = 0; i < sortedSourceMaps.length; i++) {
            const sourceMap = sortedSourceMaps[i];
            const { fiberCount, unassignedFibers, blockedFiberCount } = mapFiberMapToConnectorMap(sourceMap, fiberMap.sourceIndices, startIndex);
            sourceMapping.push({ ...sourceMap, unassignedFibers, blockedFiberCount })
            startIndex += fiberCount
        }

        startIndex = 0;
        for (let i = 0; i < connectorMap.destinationMapping.length; i++) {
            const destinationMap = connectorMap.destinationMapping[i];
            const { indices, fiberCount, unassignedFibers, blockedFiberCount } = mapFiberMapToConnectorMap(destinationMap, fiberMap.destinationIndices, startIndex);
            destinationMapping.push({ ...destinationMap, unassignedFibers, blockedFiberCount })
                const assignedIndices = [...indices].filter(d => d.assignedIndex > AssignedIndexCodes.Unassigned)
                for (let j = 0; j < assignedIndices.length; j++) {
                    const assignedDestinationIndex = assignedIndices[j];
                    const assignedSourceIndex = fiberMap.sourceIndices.findIndex(s => s.assignedIndex === assignedDestinationIndex.assignedIndex)
                    if (assignedSourceIndex > -1) {
                        assignedDestinationIndex.assignedIndex = assignedSourceIndex
                    }

                }
                startIndex += fiberCount
        }

        fiberMap.sourceIndices.forEach((s, i) => {
            s.index = i;
            s.assignedIndex = s.assignedIndex > AssignedIndexCodes.Unassigned ? i : s.assignedIndex
        })

        fiberMap.destinationIndices.forEach((d, i) => d.index = i)

        connectorMap.sourceMapping = sourceMapping.map((s, i) => { return { ...s, orderIndex: i } });
        connectorMap.destinationMapping = destinationMapping.map((d, i) => { return { ...d, orderIndex: i } });

        if (!fiberMap.versionDate) {
            fiberMap.versionDate = fiberMapping.versionDate;
        }
    }

    return connectorMap;
}

export function mapFiberMapToConnectorMap(connectorMap: IConnectorMap, fiberMapIndices: IFiberMapIndex[], startIndex: number) {
    const fiberCount = getAdjustedFiberCount(connectorMap.fiberCount);
    const indices = fiberMapIndices.slice(startIndex, startIndex + fiberCount);
    const blockedFiberCount = indices.filter(p => p.assignedIndex === AssignedIndexCodes.Blocked).length;
    const mtp8FiberCorrection = connectorMap.fiberCount === 8 ? -4 : 0;
    const unassignedFibers = indices.filter(p => p.assignedIndex === AssignedIndexCodes.Unassigned).length + mtp8FiberCorrection + blockedFiberCount;
    return { indices, fiberCount, unassignedFibers, blockedFiberCount };
}

export function generateFiberMappingData(connectorAssignment: IConnectorAssignmentMap, fiberMap: IFiberMapData) {
    const fiberMapping: IFiberMap[] = [];
    const unused: { sourcePins: ISourcePinMap[], destinationPins: IDestinationPinMap[] } = {
        sourcePins: [],
        destinationPins: [],
    }

    const sourceMapMatrix = buildConnectorMapMatrix(connectorAssignment.sourceMapping)
    const destinationMapMatrix = buildConnectorMapMatrix(connectorAssignment.destinationMapping)
    const sourceFiberMatrix = fiberMapIndicesToMatrix(fiberMap.sourceIndices)
    const destinationFiberMatrix = fiberMapIndicesToMatrix(fiberMap.destinationIndices)

    const sourceMaps = sourceMapMatrix[0]
    for (let tapIndex = 0; tapIndex < destinationMapMatrix.length; tapIndex++) {
        const destinationMaps = destinationMapMatrix[tapIndex];

        for (let sourceConnectorIndex = 0; sourceConnectorIndex < sourceMaps.length; sourceConnectorIndex++) {
            for (let destinationConnectorIndex = 0; destinationConnectorIndex < destinationMaps.length; destinationConnectorIndex++) {
                const sourceMap = sourceMaps[sourceConnectorIndex];
                const sourceIndices = sourceFiberMatrix[sourceMap.orderIndex]
                const destinationMap = destinationMaps[destinationConnectorIndex];
                const destinationIndices = destinationFiberMatrix[destinationMap.orderIndex]
                if (sourceIndices && destinationIndices) {
                    for (let sourcePinIndex = 0; sourcePinIndex < sourceIndices.length; sourcePinIndex++) {
                        const sourceFiberIndex = sourceIndices[sourcePinIndex];

                        const sourcePin: ISourcePinMap = {
                            connectorIndex: sourceConnectorIndex,
                            index: sourcePinIndex + 1
                        }

                        // match destination pin with source pin from fibermap
                        const destinationPinIndex = destinationIndices.findIndex(d => d.assignedIndex === sourceFiberIndex.assignedIndex)
                        if (sourceFiberIndex.assignedIndex > AssignedIndexCodes.Unassigned && destinationPinIndex > -1) {

                            const destinationPin: IDestinationPinMap = {
                                connectorIndex: destinationConnectorIndex,
                                index: destinationPinIndex + 1,
                                tapIndex
                            }

                            fiberMapping.push({
                                sourcePin,
                                destinationPin
                            })
                        }
                    }
                }

                if (sourceIndices) {
                    for (let sourcePinIndex = 0; sourcePinIndex < sourceIndices.length; sourcePinIndex++) {
                        const sourceFiberIndex = sourceIndices[sourcePinIndex];
                        if (sourceFiberIndex.assignedIndex === AssignedIndexCodes.Blocked) {
                            const sourcePin: ISourcePinMap = {
                                connectorIndex: sourceConnectorIndex,
                                index: sourcePinIndex + 1
                            }
                            unused.sourcePins.push(sourcePin)
                        }
                    }
                }

                if (destinationIndices) {
                    const unusedDestinationPins = destinationIndices.filter(d => d.assignedIndex === AssignedIndexCodes.Blocked)
                    
                    for (let i = 0; i < unusedDestinationPins.length; i++) {
                        const destinationFiberIndex = destinationIndices.findIndex(d => d.index === unusedDestinationPins[i].index);
                        if (destinationFiberIndex > -1) {
                            const destinationPin: IDestinationPinMap = {
                                connectorIndex: destinationConnectorIndex,
                                index: destinationFiberIndex + 1,
                                tapIndex
                            }

                            unused.destinationPins.push(destinationPin)
                        }
                    }
                }
            }
        }
    }

    return { fiberMapping, unused, name: fiberMap.name };
}

export function generateFiberMapRows(assignment: IConnectorAssignmentMap, fiberMap: IFiberMapData): IFiberMapRowProps[] {
    const sourceMapMatrix = buildConnectorMapMatrix(assignment.sourceMapping)
    const destinationMapMatrix = buildConnectorMapMatrix(assignment.destinationMapping)
    const sourceFiberMatrix = fiberMapIndicesToMatrix(fiberMap.sourceIndices)
    const destinationFiberMatrix = fiberMapIndicesToMatrix(fiberMap.destinationIndices)

    const rows: IFiberMapRowProps[] = [];
    const sourceMaps = sourceMapMatrix[0]
    for (let tapIndex = 0; tapIndex < destinationMapMatrix.length; tapIndex++) {
        const destinationMaps = destinationMapMatrix[tapIndex];
        for (let srcIndex = 0; srcIndex < sourceMaps.length; srcIndex++) {
            for (let dstIndex = 0; dstIndex < destinationMaps.length; dstIndex++) {
                const sourceMap = sourceMaps[srcIndex];
                const sourceIndices = sourceFiberMatrix[sourceMap.orderIndex]
                const destinationMap = destinationMaps[dstIndex];
                const destinationIndices = destinationFiberMatrix[destinationMap.orderIndex]
                if (sourceIndices && destinationIndices) {
                    for (let sourcePinIndex = 0; sourcePinIndex < sourceIndices.length; sourcePinIndex++) {
                        const sourceFiberIndex = sourceIndices[sourcePinIndex];
                        const destinationFiberIndex = destinationIndices.find(d => d.assignedIndex === sourceFiberIndex.assignedIndex)
                        if (sourceFiberIndex.assignedIndex > AssignedIndexCodes.Unassigned && destinationFiberIndex) {
                            const sourceFiberCount = getAdjustedFiberCount(sourceMap.fiberCount);
                            const sourcePinIndex = (sourceFiberIndex.index % sourceFiberCount) + 1;
                            const sourceFiber: IFiberMapInfo = {
                                location: i18n.t(LocalizationKeys.Feeder),
                                connectorIndex: sourceMap.index,
                                connectorType: sourceMap.connectorType ?? "",
                                row: getPinRowLocation(sourceFiberCount, sourcePinIndex),
                                pinIndex: sourcePinIndex
                            };
                            const destinationFiberCount = getAdjustedFiberCount(destinationMap.fiberCount);
                            const destinationPinIndex = (destinationFiberIndex.index % destinationFiberCount) + 1;
                            const destinationFiber: IFiberMapInfo = {
                                location: i18n.t(LocalizationKeys.TapNumber, { tapNumber: destinationMap.position }),
                                connectorIndex: destinationMap.index,
                                connectorType: destinationMap.connectorType ?? "",
                                row: getPinRowLocation(destinationFiberCount, destinationPinIndex),
                                pinIndex: destinationPinIndex
                            };
                            rows.push({
                                sourceFiber,
                                destinationFiber
                            });
                        }
                    }
                }
            }
        }
    }

    return rows;
}

function getAssignmentMap(state: IPolarityState, assignmentMap: IConnectorAssignmentMap) {
    return state.assignmentMapping.find(a => {
        const source = a.sourceMapping.some((s, i) => {
            if (i >= assignmentMap.sourceMapping.length) {
                return false;
            }
            return s.connectorType === assignmentMap.sourceMapping[i].connectorType && s.index === assignmentMap.sourceMapping[i].index && s.position === assignmentMap.sourceMapping[i].position;
        });

        return source;
    });
}

function getAdjustedFiberCount(fiberCount: number) {
    if (fiberCount === 8) return 12;
    return fiberCount
}

function getPinRowLocation(fiberCount: number, pinIndex: number) {
    if (fiberCount === 24) {
        return pinIndex > 12 ? "BOTTOM" : "TOP"
    } else {
        return "N/A"
    }
}

/**
 * generates build-sorted connector map matrix
 * @param connectorMaps Assignment Ordered connector map
 * @returns Build ordered connector map [tapIndex][connectorIndex]
 */
function buildConnectorMapMatrix(connectorMaps: IConnectorMap[]): ConnectorBuildMatrix<IConnectorMap> {
    const positionSortedMap = [...connectorMaps].sort((a, b) => a.position > b.position ? 1 : -1)
    const tapConnectors: { [tapIndex: number]: IConnectorMap[] } = {}
    for (let i = 0; i < positionSortedMap.length; i++) {
        const connectorMap = positionSortedMap[i];

        if (tapConnectors[connectorMap.position]) {
            tapConnectors[connectorMap.position] = [...tapConnectors[connectorMap.position], { ...connectorMap }].sort((a, b) => a.index > b.index ? 1 : -1)
        }
        else {
            tapConnectors[connectorMap.position] = [{ ...connectorMap }]
        }
    }

    const tapConnectorMatrix: ConnectorBuildMatrix<IConnectorMap> = []

    // keys sorted from positionSortedMap sorting
    const tapPositions = Object.keys(tapConnectors).map(t => Number.parseInt(t))

    for (let i = 0; i < tapPositions.length; i++) {
        const tapPosition = tapPositions[i];
        tapConnectorMatrix[i] = tapConnectors[tapPosition]

    }

    return tapConnectorMatrix
}

/**
 *  transforms flat fiber map to a matrix [connectorIndex][fiberIndex]
 * @param fiberMapIndices 
 * @returns matrix [connectorIndex][fiberIndex]
 */
function fiberMapIndicesToMatrix(fiberMapIndices: IFiberMapIndex[]): IFiberMapIndex[][] {
    let matrix: IFiberMapIndex[][] = []
    const connectorTypes = getFiberMapDataConnectorTypes(fiberMapIndices)
    let startIndex = 0;
    for (let i = 0; i < connectorTypes.length; i++) {
        const connectorType = connectorTypes[i];
        const fiberCount = getAdjustedFiberCount(connectorType.fiberCount)
        const indices = [...fiberMapIndices].slice(startIndex, startIndex + fiberCount)
        matrix.push(indices)
        startIndex += fiberCount
    }
    return matrix
}

function getFiberMapIndex(buildConnectorMapMatrix: ConnectorBuildMatrix<IConnectorMap>, pin: ISourcePinMap, connectorMaps: IConnectorMap[], tapIndex: number) {
    const connectorMap = buildConnectorMapMatrix[tapIndex][pin.connectorIndex];
    const startIndex = getFiberStartIndex(connectorMaps, connectorMap);
    const fiberMapIndex = startIndex + pin.index - 1;
    return fiberMapIndex;
}

function getFiberStartIndex(connectorMaps: IConnectorMap[], connectorMap: IConnectorMap) {
    const prevConnectors = connectorMaps.filter(m => m.orderIndex < connectorMap.orderIndex);
    const startIndex = prevConnectors.length ? prevConnectors
        .map(m => getAdjustedFiberCount(m.fiberCount)).reduce((a, b) => a + b, 0) : 0;
    return startIndex;
}