import React, { Fragment } from 'react';
import { withDrawerRow } from '../../../../../../../../../ctcm-components/drawer/components/drawer-row/DrawerRow';
import { BuildInfo } from './build-info/BuildInfo';
import { CreationInfo } from './creation-info/CreationInfo';
import { DragHandle } from './drag/DragHandle';
import { BuildToolbar } from './toolbar/BuildToolbar';
import { ProjectManagerRowProps } from './types';

export const ProjectManagerRow = withDrawerRow((props: ProjectManagerRowProps) => {
    const { projectId, deleteDisabled, editDisabled } = props;
    return (
        <Fragment key={`row-${projectId}`}>
            <div className="row">
                <div className="row-start">
                    <DragHandle projectId={projectId} editDisabled={editDisabled} />
                </div>
                <div className="row-middle">
                    <BuildInfo projectId={projectId} />
                </div>
                <div className="row-end">
                    <BuildToolbar projectId={projectId} deleteDisabled={deleteDisabled} editDisabled={editDisabled} />
                    <CreationInfo projectId={projectId} />
                </div>
            </div>
        </Fragment>
    );
})