import { IUnitsOfMeasureContainerState } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { Unit } from '../UnitsOfMeasure';

export const setUnitsOfMeasureDisplayReducer = (state: IUnitsOfMeasureContainerState, action: PayloadAction<boolean>) => {
    state.display = action.payload;
}

export const setUnitReducer = (state: IUnitsOfMeasureContainerState, action: PayloadAction<Unit>) => {
    state.unit = action.payload;
}