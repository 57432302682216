export enum PageResizeEnum {
    FitToPage,
    FitToHeight,
    FitToSource
}

export interface PageResizeArgs {
    update?: boolean,
    value: PageResizeEnum
}

export interface IPageResizeState {
    resize: PageResizeArgs
}

export const initialState: IPageResizeState = {
    resize: {
        update: false,
        value: PageResizeEnum.FitToSource
    },
}