import { IBuildsState, IBuildData, ICableInfoUpdateState, TrunkData, IBuildInfo } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { ISourceSetupArgs } from "../../components/overlay/components/wizard/components/setup/source/redux/types";
import { ISourceData } from "./source/types";
import { IDestinationSetupState } from "../../components/overlay/components/wizard/components/setup/destination/redux/types";
import { IDestinationData } from "./destination/types";
import { StaggerLength, getConnectorType, IConnectorType } from "../../components/overlay/components/wizard/redux/types";
import { IUnitOfMeasure } from "../../components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure";
import { IConnectorData, IConnectorGroupData, getNbConnectors } from "./connector/types";
import { FlameRating } from "../../components/overlay/components/wizard/components/setup/components/flame-rating/types";

export const getBuildReducer = (state: IBuildsState, action: PayloadAction<IBuildData>) => {
    state.currentBuild = action.payload;
    const buildInfo = extractBuildInfo(action.payload);
    state.builds.push(buildInfo);
}

export const addBuildReducer = (state: IBuildsState, action: PayloadAction<IBuildData>) => {
    const build = action.payload;
    const buildInfo = extractBuildInfo(build);
    state.builds.push(buildInfo);
}

export const addBuildsReducer = (state: IBuildsState, action: PayloadAction<IBuildInfo[]>) => {
    const newBuilds = [...action.payload].filter(n => !state.builds.some(b => b.buildId === n.buildId));

    state.builds = [...state.builds, ...newBuilds];
}

export const setBuildsReducer = (state: IBuildsState, action: PayloadAction<IBuildInfo[]>) => {
    state.builds = action.payload;
}

export const updatedBuildInfoReducer = (state: IBuildsState, action: PayloadAction<IBuildInfo>) => {
    const buildInfo = action.payload;
    updateBuildInfo(state, buildInfo);
}

export const updateBuildReducer = (state: IBuildsState, action: PayloadAction<IBuildData>) => {
    const build = action.payload;
    updateBuild(state, build);
}

export const updateBuildListEntryReducer = (state: IBuildsState, action: PayloadAction<IBuildData>) => {
    const { payload: build } = action;
    if (build.id) {
        const updatedBuild = { ...build, lastModified: new Date().toLocaleDateString() };
        const updatedBuildInfo = extractBuildInfo(updatedBuild);

        const index = state.builds.findIndex((b) => b.buildId === updatedBuildInfo.buildId);
        if (index < 0) {
            state.builds.push(updatedBuildInfo);
        } else {
            state.builds[index] = updatedBuildInfo;
        }
    }
}

export function updateBuildInfo(state: IBuildsState, buildInfo: IBuildInfo) {
    if (buildInfo.buildId) {
        const index = state.builds.findIndex(b => b.buildId === buildInfo.buildId);
        if (index < 0) {
            state.builds.push(buildInfo);
        } else {
            const updatedBuildInfo: IBuildInfo = { ...state.builds[index], description: buildInfo.description, name: buildInfo.name, partNumber: buildInfo.partNumber, lastModified: new Date().toLocaleDateString() };
            state.builds[index] = updatedBuildInfo;

            if (state.currentBuild!.id === buildInfo.buildId) {
                const updatedBuild: IBuildData = {
                    ...state.currentBuild!,
                    description: updatedBuildInfo.description,
                    name: updatedBuildInfo.name,
                    lastModified: updatedBuildInfo.lastModified
                }

                state.currentBuild = updatedBuild;
            }

        }
    }
}

export function updateBuild(state: IBuildsState, build: IBuildData) {
    if (build.id) {
        const updatedBuild = { ...build, lastModified: new Date().toLocaleDateString() };
        const updatedBuildInfo = extractBuildInfo(build);

        const index = state.builds.findIndex((b) => b.buildId === updatedBuild.id);
        if (index < 0) {
            state.builds.push(updatedBuildInfo);
        } else {
            state.builds[index] = updatedBuildInfo;

            if (state.currentBuild!.id === build.id) {
                let availabilities = state.currentBuild!.availability!;
                state.currentBuild = { ...updatedBuild, availability: availabilities };
            }
        }
    }
}

export const deleteBuildReducer = (state: IBuildsState, action: PayloadAction<number>) => {
    state.builds = state.builds.filter(x => x.buildId !== action.payload);
}

export const setCurrentBuildReducer = (state: IBuildsState, action: PayloadAction<IBuildData>) => {
    const currentBuild = action.payload;
    state.currentBuild = currentBuild;

    const builds = [...state.builds];
    for (let i = 0; i < builds.length; i++) {
        const build = builds[i];
        if (build.buildId === currentBuild.id) {
            builds[i] = currentBuild;
        }
    }
}

export const setPolarityDescriptionReducer = (state: IBuildsState, action: PayloadAction<string>) => {
    state.currentBuild!.polarityDescription = action.payload;
}

export const resetPolarityDescriptionReducer = (state: IBuildsState) => {
    state.currentBuild!.polarityDescription = "";
}

export const setPolarityAssignmentReducer = (state: IBuildsState, action: PayloadAction<string>) => {
    state.currentBuild!.polarityAssignment = action.payload;
}

export const resetPolarityAssignmentReducer = (state: IBuildsState) => {
    state.currentBuild!.polarityAssignment = "";
}

export const setSourceEnabledReducer = (state: IBuildsState, action: PayloadAction<boolean>) => {
    state.currentBuild!.availability!.sourceEnabled = action.payload;
}

export const setDestinationsEnabledReducer = (state: IBuildsState, action: PayloadAction<boolean>) => {
    const enable = action.payload;
    if (enable) {
        state.currentBuild!.availability!.enabledDestinations = state.currentBuild!.destinations.map(d => d.position);
    }
    else {
        state.currentBuild!.availability!.enabledDestinations = [];
    }
}

export const addEnabledDestinationReducer = (state: IBuildsState, action: PayloadAction<number>) => {
    state.currentBuild!.availability!.enabledDestinations.push(action.payload);
}

export const removeEnabledDestinationReducer = (state: IBuildsState, action: PayloadAction<number>) => {
    state.currentBuild!.availability!.enabledDestinations = state.currentBuild!.availability!.enabledDestinations.filter(x => x !== action.payload);
}

export const updateSourceReducer = (state: IBuildsState, action: PayloadAction<ISourceSetupArgs>) => {
    let { sourceSetup: setup } = action.payload;

    const currentBuild = state.currentBuild!;
    const fiberType = setup.fiberType;
    const fiberCount = setup.fiberCount.count!;
    const axLength = setup.aLength!;
    const bxLength = setup.bLength!;
    const sxLength = setup.sLength === undefined ? StaggerLength.Stagger0.value : setup.sLength;
    const connectorType = setup.connectorType!;
    const groupCount = setup.groupCount!;
    const connectorsPerGroup = setup.connectorCount!;
    const gripOnEnd = setup.gripOnEnd;

    let sourceGroups = currentBuild.source.groups;
    let lengthA: IUnitOfMeasure = { id: currentBuild.source.lengthA!.id, value: axLength.value, unit: axLength.unit };
    let lengthB: IUnitOfMeasure = { id: currentBuild.source.lengthB!.id, value: bxLength.value, unit: bxLength.unit };
    const tapFiberCount = groupCount * connectorsPerGroup * connectorType.fiberCount
    let groups = updateGroups(0, sourceGroups, groupCount, connectorsPerGroup, connectorType, sxLength, tapFiberCount);
    let source: ISourceData = {
        ...currentBuild.source,
        fiberType: fiberType,
        fiberCount: fiberCount,
        lengthA: lengthA,
        lengthB: lengthB,
        groups: groups,
        gripOnEnd: gripOnEnd
    }

    let availabilities = state.currentBuild!.availability!;
    availabilities.sourceEnabled = true;
    currentBuild.source = source;
    currentBuild.fiberType = fiberType;

    updateBuild(state, currentBuild);
}

export const updateDestinationReducer = (state: IBuildsState, action: PayloadAction<IDestinationSetupState>) => {
    let setup = action.payload;
    const position = setup.position!;
    const groupCount = setup.groupCount!;
    const connectorsPerGroup = setup.nbConnectorsPerGroup!;
    const connectorCount = setup.groupCount! * connectorsPerGroup;
    const axLength = setup.alength!;
    const bxLength = setup.blength!;
    const sxLength = setup.slength!;
    const connectorType = setup.connectorType!;
    const fiberCount = setup.fiberCount;

    const destinations = state.currentBuild!.destinations;
    const source = state.currentBuild!.source;

    let modifiedDestinationTap = destinations.find(x => x.position === position);
    modifiedDestinationTap!.lengthB = { id: modifiedDestinationTap!.lengthB!.id, value: bxLength.value, unit: bxLength.unit };
    modifiedDestinationTap!.groups = updateGroups(position, modifiedDestinationTap!.groups, groupCount, connectorsPerGroup, connectorType, sxLength, fiberCount);
    if (connectorCount <= 2) {
        modifiedDestinationTap!.isCollapsed = false;
    }
    if (position > 1) {
        let modifiedDestinationTrunk = destinations.find(x => x.position === position - 1)
        if (modifiedDestinationTrunk && modifiedDestinationTrunk.lengthA) {
            modifiedDestinationTrunk.lengthA = { ...modifiedDestinationTrunk.lengthA, value: axLength.value, unit: axLength.unit }
        }
    }
    else {
        source.lengthA = { ...source.lengthA, value: axLength.value, unit: axLength.unit }
    }

    state.currentBuild!.destinations = destinations;
    if (state.currentBuild) {
        updateBuild(state, state.currentBuild);
    }
}

export const updateGroups = (tapPosition: number, previousGroups: IConnectorGroupData[], groupCount: number, connectorsPerGroup: number, connectorType: IConnectorType, stagger: IUnitOfMeasure, tapFiberCount: number): IConnectorGroupData[] => {
    let groups: IConnectorGroupData[] = [];
    let remainingFiberCount = tapFiberCount;
    for (let i = 0; i < groupCount; i++) {
        const previousGroup = previousGroups[i];
        let groupId = i < previousGroups.length ? previousGroup.id : -1;
        let connectors: IConnectorData[] = [];
        for (let j = 0; j < connectorsPerGroup && remainingFiberCount >= connectorType.fiberCount; j++) {
            let connectorId = groupId !== -1 && j < previousGroup.connectors.length ? previousGroup.connectors[j].id : -1;
            let connector: IConnectorData = {
                position: j,
                type: connectorType.type,
                stagger: { value: stagger.value, unit: stagger.unit },
                tapPosition,
                groupPosition: i
            };

            if (connectorId !== -1) {
                let previousConnector = previousGroup.connectors[j];
                connector.id = connectorId;
                connector.position = previousConnector.position;
                connector.stagger = { id: previousConnector.stagger!.id, value: stagger.value, unit: stagger.unit };
                connector.label = previousConnector.label;
                connector.color = previousConnector.color;
                connector.tapPosition = tapPosition;
                connector.groupPosition = i;
            }

            connectors.push(connector);
            remainingFiberCount -= connectorType.fiberCount
        }

        if (connectors.length) {
            let group: IConnectorGroupData = {
                position: i,
                type: connectorType.type,
                stagger: { value: stagger.value, unit: stagger.unit },
                connectors: connectors,
                tapPosition
            }

            if (groupId !== -1) {
                group.id = groupId;
                group.position = previousGroup.position;
                group.stagger = { id: previousGroup.stagger!.id, value: stagger.value, unit: stagger.unit };
                group.lengthB = previousGroup.lengthB;
                group.tapPosition = tapPosition;
            }

            groups.push(group);
        }
    }

    return groups;
}

export const updateAllDestinationsReducer = (state: IBuildsState, action: PayloadAction<IDestinationSetupState>) => {
    const setup = action.payload;
    const accessPoints = setup.accessPoints!;
    let groupCount = setup.groupCount!;
    let connectorsPerGroup = setup.nbConnectorsPerGroup!;
    let axLength = setup.alength!;
    const bxLength = setup.blength!;
    const sxLength = setup.slength!;
    const connectorType = setup.connectorType!;
    const currentDestinations = state.currentBuild!.destinations

    let destinations: IDestinationData[] = [];
    const fiberCount = state.currentBuild!.source.fiberCount!;
    let remainingFiberCount = fiberCount
    const maxTapFiberCount = groupCount * connectorsPerGroup * connectorType.fiberCount
    for (let i = 0; i < accessPoints; i++) {
        let tapFiberCount = Math.min(maxTapFiberCount, remainingFiberCount)
        if (i === accessPoints - 1) {
            axLength = currentDestinations[currentDestinations.length - 1].lengthA!;
        }

        if (tapFiberCount < connectorType.fiberCount) {
            break;
        }
        destinations.push({
            position: i + 1,
            lengthA: { value: axLength.value, unit: axLength.unit },
            lengthB: { value: bxLength.value, unit: bxLength.unit },
            groups: updateGroups(i + 1, [], groupCount, connectorsPerGroup, connectorType, sxLength, tapFiberCount),
            isCollapsed: false
        });
        remainingFiberCount -= tapFiberCount;
    }

    const availabilities = state.currentBuild!.availability!;
    availabilities.enabledDestinations = destinations.map(x => x.position);
    state.currentBuild!.destinations = destinations;

    updateBuild(state, state.currentBuild!);
}

export const moveDestinationLeftReducer = (state: IBuildsState, action: PayloadAction<number>) => {
    let destinations = [...state.currentBuild!.destinations];
    let rightPosition = action.payload;
    let rightDestination = destinations.find(x => x.position === rightPosition);
    let rightIndex = destinations.indexOf(rightDestination!);

    let leftPosition = action.payload - 1;
    let leftDestination = destinations.find(x => x.position === leftPosition);
    let leftIndex = destinations.indexOf(leftDestination!);
    let leftALength = { ...leftDestination!.lengthA! };

    let newLeftDestination = { ...rightDestination! }
    let newRightDestination = { ...leftDestination! }

    if (leftPosition === 1) {
        newLeftDestination.lengthA = { ...newLeftDestination.lengthA!, value: leftDestination!.lengthA!.value };
        newRightDestination.lengthA = { ...newRightDestination.lengthA!, value: rightDestination!.lengthA!.value };
    }
    else if (leftPosition > 1 && rightPosition - 2 > 0) { // not swapping with first drop
        const previousLeftPosition = action.payload - 2;
        const previousLeftDestination = destinations.find(x => x.position === previousLeftPosition);
        const previousLeftALength = { ...previousLeftDestination!.lengthA! };

        newRightDestination!.lengthA = { ...newRightDestination!.lengthA!, value: newLeftDestination!.lengthA!.value };
        previousLeftDestination!.lengthA = { ...previousLeftDestination!.lengthA!, value: leftALength.value }
        newLeftDestination!.lengthA = { ...newLeftDestination!.lengthA!, value: previousLeftALength.value }
    }


    newLeftDestination!.position--
    newRightDestination!.position++

    destinations[leftIndex] = newLeftDestination!;
    destinations[rightIndex] = newRightDestination!;


    state.currentBuild!.destinations = destinations;
}

export const moveDestinationRightReducer = (state: IBuildsState, action: PayloadAction<number>) => {
    let destinations = [...state.currentBuild!.destinations];
    let leftPosition = action.payload;
    let leftDestination = destinations.find(x => x.position === leftPosition);
    let leftIndex = destinations.indexOf(leftDestination!);
    let leftALength = { ...leftDestination!.lengthA! };

    let rightPosition = action.payload + 1;
    let rightDestination = destinations.find(x => x.position === rightPosition);
    let rightIndex = destinations.indexOf(rightDestination!);

    let newLeftDestination = { ...rightDestination! }
    let newRightDestination = { ...leftDestination! }

    if (leftPosition === 1) { // tap 1 case , won't modify a Lengths
        newLeftDestination.lengthA = { ...newLeftDestination.lengthA!, value: leftDestination!.lengthA!.value };
        newRightDestination.lengthA = { ...newRightDestination.lengthA!, value: rightDestination!.lengthA!.value };
    }
    else {
        const previousLeftPosition = leftPosition - 1;
        const previousLeftDestination = destinations.find(x => x.position === previousLeftPosition);
        const previousLeftALength = { ...previousLeftDestination!.lengthA! };

        newRightDestination!.lengthA = { ...newRightDestination!.lengthA!, value: newLeftDestination!.lengthA!.value };
        previousLeftDestination!.lengthA = { ...previousLeftDestination!.lengthA!, value: leftALength.value }
        newLeftDestination!.lengthA = { ...newLeftDestination!.lengthA!, value: previousLeftALength.value }

    }

    newRightDestination!.position++;
    newLeftDestination!.position--;

    destinations[rightIndex] = newRightDestination!;
    destinations[leftIndex] = newLeftDestination!;

    state.currentBuild!.destinations = destinations;
}

export const removeDestinationByPositionReducer = (state: IBuildsState, action: PayloadAction<number>) => {
    let deletedPosition = action.payload;
    let deletedDestination = state.currentBuild!.destinations.find(x => x.position === deletedPosition)!;
    let beforeDestination = state.currentBuild!.destinations.find(x => x.position === deletedPosition - 1);
    let filteredDestinations = [...state.currentBuild!.destinations.filter(x => x.position !== deletedPosition)];

    if (deletedPosition > 1) {
        filteredDestinations = shiftALengths(state.currentBuild!.destinations, deletedPosition)
        if (beforeDestination) {
            beforeDestination.lengthA = { ...beforeDestination.lengthA!, value: deletedDestination.lengthA!.value };
        }

        state.currentBuild!.destinations = filteredDestinations;
    }
    else {
        let aLengths = state.currentBuild!.destinations.map(d => d.lengthA!);
        const [, ...destinations] = state.currentBuild!.destinations;
        destinations.forEach((d, i) => d.lengthA = { ...d.lengthA!, value: aLengths[i].value })
        filteredDestinations = destinations
    }

    state.currentBuild!.destinations = filteredDestinations;

    state.currentBuild!.availability!.enabledDestinations.pop();
}

const shiftALengths = (destinations: IDestinationData[], deletedPosition: number) => {
    const filteredDestinations = [...destinations.filter(x => x.position !== deletedPosition)];
    filteredDestinations.forEach(x => {
        if (x.position > 0) {
            const destination = destinations.find(x => x.position === x.position - 1);
            if (destination) {
                x.lengthA = { ...x.lengthA!, value: destination.lengthA!.value };
            }
        }
    });

    return filteredDestinations;
}

export const setBuildLoadedReducer = (state: IBuildsState, action: PayloadAction<boolean>) => {
    state.loaded = action.payload;
}

export const setIsCollapsedReducer = (state: IBuildsState, action: PayloadAction<number>) => {
    let position = action.payload;
    let isCollapsed: boolean;
    if (position === 0) {
        isCollapsed = setTrunkCollapse(state.currentBuild!.source, state.currentBuild!.source.isCollapsed!);
        state.currentBuild!.source.isCollapsed = !isCollapsed;
    } else {
        let destination = state.currentBuild!.destinations.find(x => x.position === position);
        isCollapsed = setTrunkCollapse(destination!, destination!.isCollapsed!);
        destination!.isCollapsed = !isCollapsed;
    }
}

function setTrunkCollapse(trunk: TrunkData, isCollapsed: boolean) {
    return trunk.customBLength ? false : isCollapsed
}

export const expandAllReducer = (state: IBuildsState) => {
    state.currentBuild!.source.isCollapsed = false;
    state.currentBuild!.destinations.forEach(d => d.isCollapsed = false);
}

export const collapseAllReducer = (state: IBuildsState) => {
    let source = state.currentBuild!.source;
    let nbConnectorsSrc = getNbConnectors(source.groups);
    if (nbConnectorsSrc > 2) {
        source.isCollapsed = setTrunkCollapse(source, true);
    }

    state.currentBuild!.destinations.forEach(d => {
        let nbConnectorsDst = getNbConnectors(d.groups);
        if (nbConnectorsDst > 2) {
            d.isCollapsed = setTrunkCollapse(d, true);
        }
    });
}

export const setFlameRatingAction = (state: IBuildsState, action: PayloadAction<FlameRating>) => {
    state.currentBuild!.flameRating = action.payload;
    updateBuild(state, state.currentBuild!);
}

export const setConfigurationTypeAction = (state: IBuildsState, action: PayloadAction<{ configurationType: string, fiberCount?: number }>) => {
    const { configurationType, fiberCount } = action.payload;
    if (!state.currentBuild) return;
    state.currentBuild.configurationType = configurationType;
    if (fiberCount) state.currentBuild.source.fiberCount = fiberCount
    updateBuild(state, state.currentBuild);
}

export const setBuildInfoAction = (state: IBuildsState, action: PayloadAction<ICableInfoUpdateState>) => {
    const { buildId, name, desc } = action.payload;
    const buildInfo = state.builds.find(x => x.buildId === buildId) ? state.builds.find(x => x.buildId === buildId)! : extractBuildInfo(state.currentBuild!);

    buildInfo.name = name;
    buildInfo.description = desc;
    updateBuildInfo(state, buildInfo);
}

export const setConnectorAction = (state: IBuildsState, action: PayloadAction<{ position: number, groupPosition: number, connectorPosition: number, connector: IConnectorData }>) => {
    const { currentBuild } = state;
    if (!currentBuild) {
        return;
    }

    const { position, groupPosition, connectorPosition, connector } = action.payload;
    const group = position === 0
        ? currentBuild.source.groups.find(g => g.position === groupPosition)
        : currentBuild.destinations.find(d => d.position === position)?.groups.find(g => g.position === groupPosition);
    if (!group) {
        return;
    }

    if (connectorPosition in group.connectors) {
        group.connectors[connectorPosition] = connector;
    }

    const buildInfo = extractBuildInfo(currentBuild);
    updateBuildInfo(state, buildInfo);
}

export const updateAllConnectorsAction = (state: IBuildsState, action: PayloadAction<IConnectorData[]>) => {
    const updatedConnectors = action.payload;
    const { currentBuild } = state;
    let index = 0;
    if (currentBuild) {
        currentBuild.source.groups = currentBuild.source.groups.map(group => {
            return {
                ...group,
                stagger: group.stagger,
                lengthB: group.lengthB,
                connectors: group.connectors.map(connector => {
                    const updatedConnector = updatedConnectors[index++]
                    return updateConnector(connector, updatedConnector);
                })
            };
        });

        currentBuild.destinations = currentBuild.destinations.map((destination) => {
            return {
                ...destination, groups: destination.groups.map(group => {
                    return {
                        ...group,
                        stagger: group.stagger,
                        lengthB: group.stagger,
                        connectors: group.connectors.map(connector => {
                            const updatedConnector = updatedConnectors[index++]
                            return updateConnector(connector, updatedConnector);
                        })
                    }
                })
            };
        });

        const buildInfo = extractBuildInfo(currentBuild);
        updateBuildInfo(state, buildInfo);
    }
}

export const setCurrentBuildSessionIdAction = (state: IBuildsState, action: PayloadAction<string>) => {
    if (state.currentBuild) {
        state.currentBuild.sessionId = action.payload;
    }
}

export const updatePositionedConnectorsAction = (state: IBuildsState, action: PayloadAction<IConnectorData[]>) => {
    const updatedConnectors = action.payload;
    for (const { tapPosition, groupPosition, position, color, label } of updatedConnectors) {
        if (tapPosition !== undefined && groupPosition !== undefined && position !== undefined) {
            const drop = tapPosition ? state.currentBuild!.destinations[tapPosition - 1] : state.currentBuild!.source
            const connector = drop.groups[groupPosition].connectors[position]
            connector.color = color
            connector.label = label
        }
    }
}

const updateConnector = (connector: IConnectorData, updatedConnector?: IConnectorData): IConnectorData => {
    if (!updatedConnector) return connector;

    return {
        id: updatedConnector.id ?? connector.id,
        position: updatedConnector.position ?? connector.position,
        type: updatedConnector.type ?? connector.type,
        stagger: updatedConnector.stagger ?? connector.stagger,
        label: updatedConnector.label ?? connector.label,
        color: updatedConnector.color,
        defaultColor: updatedConnector.defaultColor ?? connector.defaultColor,
        tapPosition: updatedConnector.tapPosition ?? connector.tapPosition,
        groupPosition: updatedConnector.groupPosition ?? connector.groupPosition
    }
}

export const updateConnectorGroupsAction = (state: IBuildsState, action: PayloadAction<Partial<IBuildData>>) => {
    const { source, destinations } = action.payload;
    const currentBuild = state.currentBuild;
    if (currentBuild) {
        if (source) {
            currentBuild.source = source;
        }
        if (destinations) {
            currentBuild.destinations = destinations;
        }
    }
}

export const setCurrentBuildAsymmetricAction = (state: IBuildsState, action: PayloadAction<boolean>) => {
    const { currentBuild } = state;
    currentBuild!.isAsymmetric = action.payload;
}

export const setGroupBLengthAction = (state: IBuildsState, action: PayloadAction<{ trunkPosition: number, groupPosition: number, length?: Omit<IUnitOfMeasure, "id">, applyToAllDst?: boolean }>) => {
    const { trunkPosition, groupPosition, length, applyToAllDst } = action.payload;
    const { currentBuild } = state;
    if (currentBuild) {
        const source = currentBuild.source;
        let destinations: IDestinationData[] = [...currentBuild.destinations]
        if (!trunkPosition && groupPosition < source.groups.length) {
            source.groups[groupPosition].lengthB = length ? { ...source.groups[groupPosition].lengthB, ...length } : undefined
        } else if (trunkPosition && groupPosition < destinations[trunkPosition - 1].groups.length) {
            if (applyToAllDst) {
                destinations = destinations.map(d => {
                    d.groups[groupPosition].lengthB = length ? { ...destinations[trunkPosition - 1].groups[groupPosition].lengthB, ...length } : undefined;
                    return d;
                });
            } else {
                destinations[trunkPosition - 1].groups[groupPosition].lengthB = length ? { ...destinations[trunkPosition - 1].groups[groupPosition].lengthB, ...length } : undefined;
            }
            currentBuild.destinations = destinations;
        }
    }
}

export const setCustomBLengthAction = (state: IBuildsState, action: PayloadAction<{ trunkPosition: number, customBLength: boolean, applyToAllDst?: boolean }>) => {
    const { currentBuild } = state;
    if (currentBuild) {
        const { trunkPosition, customBLength, applyToAllDst } = action.payload;
        const { source, destinations } = currentBuild!;
        if (trunkPosition > 0) {
            if (applyToAllDst) {
                currentBuild.destinations = destinations.map(d => ({ ...d, customBLength: customBLength }));
            } else {
                const destination = destinations.find(d => d.position === trunkPosition)
                if (destination) {
                    destination.customBLength = customBLength;
                }
            }
        }
        else if (!trunkPosition) {
            source.customBLength = customBLength;
        }
    }
}

export const setCatalogCodeAction = (state: IBuildsState, action: PayloadAction<string | undefined>) => {
    if (state.currentBuild) {
        state.currentBuild.catalogCode = action.payload;
        updateBuild(state, state.currentBuild);
    }
}

export const setFiberTypeAction = (state: IBuildsState, action: PayloadAction<string>) => {
    if (state.currentBuild) state.currentBuild.fiberType = action.payload;
}

export const extractBuildInfo = (build: IBuildData) => {
    const buildInfo: IBuildInfo = {
        buildId: build.id!,
        userId: build.userId!,
        groupId: build.groupId!,
        description: build.description!,
        polarityDescription: build.polarityDescription!,
        name: build.name!,
        ownerEmail: build.ownerEmail,
        lockedById: build.lockedById,
        catalogCode: build.catalogCode,
        lastModified: build.lastModified,
        fiberCount: build.source.fiberCount!,
        fiberType: build.source.fiberType!,
        gripOnEnd: build.source.gripOnEnd!,
        nbConnectorsSrc: getNbConnectors(build.source.groups!),
        connectorTypeSrc: getConnectorType(build.source.groups[0].type!).type,
        nbConnectorsDst: getNbConnectors(build.destinations[0].groups),
        connectorTypeDst: getConnectorType(build.destinations[0].groups[0].type!).type,
        nbTAPs: build.destinations.length,
    }
    return buildInfo;
}

