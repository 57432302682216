import { Container } from '@inlet/react-pixi';
import { DropGraphic } from "./drop-graphic/DropGraphic";
import { MarkerStyleContext, feederReportStyle, overviewReportStyle, dropReportStyle } from '../decorators/markers/types';
import { DocumentGraphicsContainer } from './types';
import { useDocumentGraphics } from './hooks';
import { BuildContext } from '../../../workspace/redux/build/types';
import { Trunk } from '../build/components/trunk/Trunk';
import { Cable } from '../build/components/cable/Cable';

export const DocumentGraphics = () => {
    const { unit, windowSize, build, overviewGraphics, feederGraphics } = useDocumentGraphics();
    return <Container name={DocumentGraphicsContainer}>
        <MarkerStyleContext.Provider value={overviewReportStyle}>
            <BuildContext.Provider value={{...build, unit}}>
               <Cable {...overviewGraphics} />
            </BuildContext.Provider>
        </MarkerStyleContext.Provider>
        <MarkerStyleContext.Provider value={feederReportStyle}>
            { feederGraphics.display &&
                <BuildContext.Provider value={{...build, unit, source: feederGraphics.source }}>
                    <Trunk {...feederGraphics.trunkProps} />
                </BuildContext.Provider> }
        </MarkerStyleContext.Provider>
        <MarkerStyleContext.Provider value={dropReportStyle}>
            <BuildContext.Provider value={{ ...build, unit }}>
                <DropGraphic x={2 * windowSize.width} y={windowSize.height}/>
            </BuildContext.Provider>
        </MarkerStyleContext.Provider>
    </Container>
}