import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../../../../../locales/types';

export const Loading = () => {
    const { t } = useTranslation();

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="48" viewBox="0 0 150 48">
            <rect fill="#a2a2a2" opacity="0.8" width="120" height="30" rx="15" transform="translate(16 9)"/>
            <rect fill="none" width="150" height="48"/>
            <text fill="#fdfdfd" fontSize="14" fontFamily="TheSansC4s" fontWeight="300" transform="translate(60 29)">
                <tspan x="0" y="0">{t(LocalizationKeys.Loading)}</tspan>
            </text>
            <g transform="translate(28 12)">
                <path fill="none" d="M0,0H24V24H0Z"/>
                <path fill="#fafafa" d="M19.35,10.04a7.492,7.492,0,0,0-14-2A6,6,0,0,0,6,20H19a4.986,4.986,0,0,0,.35-9.96ZM14,13v4H10V13H7l5-5,5,5Z"/>
            </g>
        </svg>
    );
};
