import { Dialog } from '@orbit/dialog';
import { usePolarityDialog } from './hooks';
import { ToolItem } from '../../ToolItem';
import { AssignPolarity } from './components/assign-polarity/AssignPolarity';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../../locales/types';

import "./PolarityDialog.scss";

export const PolarityDialog: React.FC = () => {
    const { t } = useTranslation();
    const { showPolarity, closeDialog } = usePolarityDialog();
    return (
        showPolarity ? (
            <Dialog
                className="toggle-pointer-events polarity-card"
                open={showPolarity}
                onClose={closeDialog}>
                    <div className="polarity-dialog-title-container">
                        <div className="polarity-dialog-title">{t(LocalizationKeys.Polarity)}</div>
                        <ToolItem icon={"close"} onClick={closeDialog} alt={"Close"} />
                    </div>
                    <AssignPolarity />
            </Dialog>) : null
    )
}