import React from 'react';
import { useDropCheckbox } from './hooks';
import { Checkbox } from '@orbit/checkbox'
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../../../../../../locales/types';

export const DropCheckbox = () => {
    const { isVisible, isDisabled, isChecked, onCheckboxChange, labelClassName, onLabelClick } = useDropCheckbox();
    const { t } = useTranslation();

    if (isVisible) {
        return (
            <div className={"ap-checkbox-container"}>
                <Checkbox palette="primary" className={"ap-checkbox"} disabled={isDisabled} checked={isChecked} onChange={onCheckboxChange} />
                <div className={labelClassName} onClick={onLabelClick}>{t(LocalizationKeys.EnableCustomAccesPoints)}</div>
            </div>
        );
    } else {
        return null;
    }
}