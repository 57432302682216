import { useDispatch, useSelector } from "react-redux";
import { disabledUISelector } from "../status/redux/selectors";
import { useCallback, useEffect, useState } from "react";
import { useCreateButton } from "../../../projects/components/drawer/components/sub-header/hooks";
import { setUnitsOfMeasureContainerDisplay } from "../units-of-measure-container/redux/reducers";
import { isImperial, Unit } from "../units-of-measure-container/UnitsOfMeasure";
import i18next from 'i18next'
import { LocalizationKeys } from "../../../../../../../locales/types";
import { showConnectorReportSelector, showMoreMenuSelector } from '../../../../redux/selectors';
import { warningsCardDisplaySelector } from "../warnings/redux/selectors";
import { WarningSeverity } from "../warnings/redux/types";
import { setWarningsCardDisplay } from "../warnings/redux/reducer";
import { sscConfigSessionBusySelector, sscAllWarningsSelector } from "../../../../../../redux/ssc/selectors";
import { unitsOfMeasureContainerUnitSelector } from "../units-of-measure-container/redux/selectors";
import { hybrisCustomerSelector } from '../../../../../authentication/redux/selectors';
import { setShowMoreMenu } from "../../../../redux/reducers";
import { workspaceSelector } from "../../../../../../selectors/root.selectors";

import icon_imperial from '../../resources/icons/icon_imperial.svg'
import icon_metric from '../../resources/icons/icon_metric.svg'

export const useToolsContainer = () => {
    const { unitsOfMeasureContainer } = useSelector(workspaceSelector);
    const displayWarningsCard = useSelector(warningsCardDisplaySelector);
    const { display: unitsDisplay } = unitsOfMeasureContainer;
    const currentUnits = useSelector(unitsOfMeasureContainerUnitSelector);
    const disableUI = useSelector(disabledUISelector);
    const connectorReportDisplay = useSelector(showConnectorReportSelector);
    const warnings = useSelector(sscAllWarningsSelector);
    const displayMoreMenu = useSelector(showMoreMenuSelector);
    const sessionBusy = useSelector(sscConfigSessionBusySelector);
    const isCustomer = !!useSelector(hybrisCustomerSelector);
    const dispatch = useDispatch();

    const [unitsOfMeasureIcon, setUnitsofMeasureIcon] = useState(getMeasurementInfo(currentUnits));
    const [measurementIcon, measurementLabel] = unitsOfMeasureIcon;

    const warningCount = warnings.length;
    const badgeClassName = warnings.some((w) => w.severity === WarningSeverity.Error)
        ? "error" 
        : "warning";

    useEffect(() => {
        setUnitsofMeasureIcon(getMeasurementInfo(currentUnits));
    }, [currentUnits]);

    const { triggerCreate } = useCreateButton()

    const unitsOfMeasureDisplay = useCallback(() => {
        dispatch(setUnitsOfMeasureContainerDisplay(!unitsDisplay));
    }, [dispatch, unitsDisplay]);

    const warningsListDisplay = useCallback(() => {
        dispatch(setWarningsCardDisplay(!displayWarningsCard));
    }, [dispatch, displayWarningsCard]);

    const moreMenuDisplay = useCallback(() => {
        dispatch(setShowMoreMenu(!displayMoreMenu));
    }, [dispatch, displayMoreMenu]);    

    return {
        disableNewCable: disableUI || connectorReportDisplay || sessionBusy, 
        disableAll: connectorReportDisplay, 
        measurementIcon, 
        measurementLabel, 
        warningCount,
        badgeClassName,
        unitsOfMeasureDisplay, 
        triggerCreate,
        warningsListDisplay,
        isCustomer,
        moreMenuDisplay
    };
}

const getMeasurementInfo = (currentUnits: Unit): [string, string] => {
    let icon = null;
    let label = "";
    let uom = currentUnits;
    if (isImperial(uom)) {
        label = i18next.t(LocalizationKeys.Imperial);
        icon = icon_imperial;
    } else {
        label = i18next.t(LocalizationKeys.Metric);
        icon = icon_metric;
    }
    return [icon, label];
}