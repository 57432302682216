import { useDispatch, useSelector, batch } from "react-redux";
import { useCallback } from "react";
import { useProjectManager } from "../../../../../../../../../redux/project-manager/hooks";
import { useProjectDrawer } from "../../../hooks";
import { setBuildLoaded } from "../../../../../../../../../redux/build/reducers";
import { setStatusState } from "../../../../../../header/components/status/redux/reducer";
import { WorkspaceStatus } from "../../../../../../header/components/status/redux/types";
import { IDragHandleProps } from '../types';
import { currentBuildSelector } from "../../../../../../../../../selectors/root.selectors";
import { loadConnectorMap } from "../../../../../../../../../redux/build/connector/polarity/reducer";
import { setHighlights } from "../../../../../../../../../../pixi/components/decorators/bounds/components/selection/redux/reducer";
import { setPropagationState } from "../../../../../../polarity/propagation/redux/reducer";

export const useDragHandle = ({ projectId, editDisabled }: IDragHandleProps) => {
    const { className, onLoad } = useLoadProject(projectId, editDisabled);
    return { className, onLoad };
}

export const useLoadProject = (projectId: number, editDisabled = false) => {
    const dispatch = useDispatch();
    const { onDrawerClose } = useProjectDrawer();
    const { updateRecentProject, loadRecentProject } = useProjectManager();
    const currentBuild = useSelector(currentBuildSelector);
    const className = editDisabled ? "lock-icon" : "eds-icon";

    const onLoad = useCallback(async () => {
        if (currentBuild && projectId !== currentBuild.id) {
            batch(() => {
                dispatch(setStatusState(WorkspaceStatus.Busy));
                dispatch(setBuildLoaded(false));
                dispatch(setHighlights([]));
                dispatch(loadConnectorMap(projectId));
                dispatch(setPropagationState({ propagateColors: false, propagateLabels: false }));
            });

            updateRecentProject(projectId);
            loadRecentProject(projectId);
        }

        onDrawerClose();
    }, [onDrawerClose, updateRecentProject, loadRecentProject, dispatch, currentBuild, projectId]);

    return { className, onLoad };
}