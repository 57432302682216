import { useApp } from "@inlet/react-pixi";
import * as Pixi from "pixi.js";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSelection } from "../../../../workspace/components/overlay/components/footer/components/toolbar/redux/reducers";
import { workspaceSelector } from "../../../../workspace/selectors/root.selectors";
import { ViewportStatus } from "../redux/types";

export const useViewportBackground = () => {
    const app = useApp();
    const { viewport: { isDragging, context } } = useSelector(workspaceSelector);
    const storeDispatch = useDispatch();
    const onPointerTap = useCallback(() => {
        if (!isDragging && context !== ViewportStatus.Editing) {
            storeDispatch(clearSelection());
        }
    }, [context, isDragging, storeDispatch]);

    const backgroundProps = {
        texture: Pixi.Texture.WHITE,
        width: app.view.width,
        height: app.view.height,
        interactive: true,
        pointertap: onPointerTap
    }

    return backgroundProps;
}