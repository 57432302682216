import { useSelector, useDispatch } from "react-redux";
import { useBuildInfo } from "../row/build-info/hooks";
import { useState, useCallback, useEffect, useMemo } from "react";
import { showCableInfo, closeAll } from "../../../../redux/reducers";
import { setBuildInfo } from "../../../../../../../../redux/build/reducers";
import { useWebSave } from "../../../../../header/components/status/hooks";
import { useBuildPolarityDescription } from "../../../../../../../../redux/build/connector/polarity/hooks";
import { useInputField } from "../../../../../../../../../ui/input/hooks";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../../../../locales/types";
import { IBuildInfo } from "../../../../../../../../redux/build/types";
import { extractBuildInfo } from "../../../../../../../../redux/build/actions";
import { ICableInfoProps } from "./types";
import { InputField } from "../../../../../../../../../ui/input/types";
import { useSscBuildSession } from '../../../../../../../../redux/ssc/hooks';
import { projectManagerSelector } from "../../../../../../../../redux/project-manager/selectors";
import { buildStateSelector } from "../../../../../../../../selectors/root.selectors";

export const useCableInfo = () => {
    const { t } = useTranslation();
    const { showInfo: open, selectedBuildId } = useSelector(projectManagerSelector);
    const { currentBuild, builds } = useSelector(buildStateSelector);
    const selectedBuildInfo = builds.find(b => b.buildId === selectedBuildId );
    const build =  selectedBuildInfo ? selectedBuildInfo : extractBuildInfo(currentBuild!);
    const buildInfo = useBuildInfo(build.buildId!);
    let { saveBuild } = useWebSave();
    const { updateSscBuildInfo } = useSscBuildSession();

    const polarityInput = usePolarityDescription(build);

    const [buildName, setBuildName] = useState(buildInfo.name);
    const [nameChanged, setNameChanged] = useState(false);
    const [buildDescription, setBuildDescription] = useState(buildInfo.description);
    const [descriptionChanged, setDescriptionChanged] = useState(false);
    const [executeSave, setExecuteSave] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (executeSave) {
            saveBuild(selectedBuildId, true);
            setExecuteSave(false);

            if (currentBuild && currentBuild?.id === selectedBuildId) {
                updateSscBuildInfo(currentBuild);
            }
        }
    }, [executeSave, saveBuild, selectedBuildId, currentBuild, updateSscBuildInfo]);

    const onReturn = useCallback(() => {
        dispatch(showCableInfo(false));
        setBuildName(buildInfo.name);
        setBuildDescription(buildInfo.description);
        setNameChanged(false);
        setDescriptionChanged(false);
    }, [buildInfo, dispatch]);

    const onClose = useCallback(() => {
        if (open) {
            dispatch(closeAll());
            setBuildName(buildInfo.name);
            setBuildDescription(buildInfo.description);
            setNameChanged(false);
            setDescriptionChanged(false);
        }
    }, [open, buildInfo, dispatch]);

    const onNameChanged = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setBuildName(entry);
        setNameChanged(entry.length === buildInfo.name.length ? entry !== buildInfo.name : true);
    }, [buildInfo.name]);

    const onDescriptionChanged = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        const entry = e.currentTarget.value;
        setBuildDescription(entry);
        setDescriptionChanged(entry.length === buildInfo.description.length ? entry !== buildInfo.description : true);
    }, [buildInfo.description]);

    const onSave = useCallback(async () => {
        const name = buildName.length > 0 ? buildName : buildInfo.name;
        const desc = buildDescription.length > 0 ? buildDescription : buildInfo.description;

        if (name !== buildInfo.name || desc !== buildInfo.description) {
            dispatch(setBuildInfo({ buildId: selectedBuildId, name, desc }));
            setExecuteSave(true);
        }

        setBuildName(name);
        setNameChanged(false);
        setBuildDescription(desc);
        setDescriptionChanged(false);
    }, [dispatch, selectedBuildId, buildName, buildDescription, buildInfo]);

    const onCancel = useCallback(() => {
        setBuildName(buildInfo.name);
        setBuildDescription(buildInfo.description);
        setNameChanged(false);
        setDescriptionChanged(false);
    }, [buildInfo]);

    const cableInfoProps: ICableInfoProps = useMemo(() => {
        const catalogCode = buildInfo.catalogCode;
        const nameIsValid = nameChanged ? buildName.length > 0 : true;
        const descriptionIsValid = descriptionChanged ? buildDescription.length > 0 : true;
        const buildInfoChanged = nameChanged || descriptionChanged; 
        const buildInfoValid = nameIsValid && descriptionIsValid

        const nameInput: InputField = {
            label: t(LocalizationKeys.CableName),
            value: nameChanged ? buildName : buildInfo.name,
            onChange: onNameChanged,
            maxLength: 20,
            isValid: nameIsValid
        }

        const descriptionInput: InputField = {
            label: t(LocalizationKeys.Description),
            value: descriptionChanged ? buildDescription : buildInfo.description,
            onChange: onDescriptionChanged,
            maxLength: 80,
            isValid: descriptionIsValid
        }

        return {
            open,
            nameInput,
            descriptionInput,
            catalogCode,
            polarityInput,
            saveDisabled: !buildInfoChanged || !buildInfoValid,
            cancelDisabled: !buildInfoChanged,
            onReturn,
            onClose,
            onSave,
            onCancel
        }

    }, [buildInfo, buildName, nameChanged, descriptionChanged, buildDescription, open, polarityInput, t, onReturn, onClose, onDescriptionChanged, onNameChanged, onSave, onCancel])

    return cableInfoProps;
}

export const usePolarityDescription = (build?: IBuildInfo) => {
    const { polarityDescription } = useBuildPolarityDescription(build);
    const { t } = useTranslation();
    const polarityInput = useInputField(t(LocalizationKeys.Polarity), polarityDescription ?? "", 30)

    return polarityInput;
}