import { useApp } from '@inlet/react-pixi';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Black, Green, IColor } from '../../ui/dialog/color/types';
import { setTextureLoaded } from '../../workspace/components/overlay/components/header/components/status/redux/reducer';
import { currentStatusSelector } from '../../workspace/components/overlay/components/header/components/status/redux/selectors';
import { WorkspaceStatus } from '../../workspace/components/overlay/components/header/components/status/redux/types';
import { FiberColors, LCBootColors, MTPBootColors, MTPColors, TrunkColors } from '../../workspace/components/overlay/components/polarity/fiber-mapping/connector/templates/types';
import { getMTPProSVGString } from '../factories/MTPPro';
import { getTrunkSprite } from '../factories/Trunks';
import { ConnectorTypes } from '../../workspace/components/overlay/components/wizard/redux/types';
import { getLCUnibootSVGString } from '../factories/LcUniboot';
import { getMTPSVGString } from '../factories/MTP24';
import { getConnectorTypeString, getMTPBootColor, isMMC, isMTP, isMTP24, isPinned, MetaSpriteList } from '../factories/Texture';
import { getMMCSVGString } from '../factories/MMC';

export const useResourceManager = () => {
    const app = useApp();
    const status = useSelector(currentStatusSelector);
    const dispatch = useDispatch();

    const dispatchTextureLoaded = useCallback(() => {
        dispatch(setTextureLoaded())
    }, [dispatch])

    useEffect(() => {
        if (status === WorkspaceStatus.InitialLoad) {
            app.loader.reset();

            for (const metaSprite of MetaSpriteList) {
                app.loader.add(metaSprite.name, metaSprite.img)
                for (let trunkColor of TrunkColors) {
                    const trunkSprite = getTrunkSprite(metaSprite.name, trunkColor)
                    if (trunkSprite) {
                        const encodedSVG = encodeURIComponent(trunkSprite);
                        const texturePath = `data:image/svg+xml;utf8,${encodedSVG}`;
                        const textureName = `${metaSprite.name}_${trunkColor.id}`;
                        app.loader.add(textureName, texturePath)
                    }

                }
            }

            const connectorTypeStrings = ConnectorTypes.map((c) => getConnectorTypeString(c.type ?? ""))
                .filter((c, i, self) => self.indexOf(c) === i)
                .filter(c => !isMTP(c) && !isMMC(c));

            const mtpConnectorTypeStrings = ConnectorTypes.map((c) => getConnectorTypeString(c.type ?? ""))
                .filter((c, i, self) => self.indexOf(c) === i)
                .filter(c => isMTP(c));

            const mmcConnectorTypeString = ConnectorTypes.map((c) => getConnectorTypeString(c.type ?? ""))
                .filter((c, i, self) => self.indexOf(c) === i)
                .filter(c => isMMC(c));

            for (let fiberColor of FiberColors) {
                connectorTypeStrings.forEach((connectorType) => {
                    for (let bootColor of LCBootColors) {
                        const { textureName, texturePath } = generateConnectorTexture(connectorType, fiberColor, bootColor);
                        app.loader.add(textureName, texturePath);
                    }
                });
            }

            for (let mtpFiberColor of MTPColors) {
                mtpConnectorTypeStrings.forEach((connectorType) => {
                    for (let bootColor of MTPBootColors) {
                        const { textureName, texturePath } = generateConnectorTexture(connectorType, mtpFiberColor, bootColor);
                        app.loader.add(textureName, texturePath);
                    }
                });
            }

            mmcConnectorTypeString.forEach((connectorType) => {
                const { textureName, texturePath } = generateConnectorTexture(connectorType, Green, Black);
                app.loader.add(textureName, texturePath);
            });

            app.loader.onComplete.add(dispatchTextureLoaded)
            app.loader.load();
        }
    }, [status, app, dispatchTextureLoaded]);
}

function generateConnectorTexture(connectorType: string, { hex: color, name }: IColor, { id: bootId, hex: bootColor }: IColor) {
    let svgString = "";
    if (isMTP(connectorType)) {
        const pinned = isPinned(connectorType);
        svgString = isMTP24(connectorType) ? getMTPSVGString(color, getMTPBootColor(connectorType), pinned) : getMTPProSVGString(color, pinned);
    } else if (isMMC(connectorType)) {
        svgString = getMMCSVGString(color, bootColor, isPinned(connectorType));
    } else {
        svgString = getLCUnibootSVGString(color, bootColor);
    }
    const encodedSVG = encodeURIComponent(svgString);
    const texturePath = `data:image/svg+xml;utf8,${encodedSVG}`;

    const textureName = `${connectorType}_${name}_${bootId}`;
    return { textureName, texturePath };
}