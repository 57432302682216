import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../../../../../../locales/types';
import { useCableInfo } from './hooks';
import { DrawerPageTitle } from '../page-title/DrawerPageTitle';
import { FilledTextField } from '@orbit/text-field'
import { OutlinedButton, ContainedButton, TextButton } from '@orbit/button';
import { Drawer } from '../../../../../../../../../ctcm-components/drawer/Drawer';
import CorningLogo from '../../resources/images/image_corning_drawer_logo.png';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

import './CableInfo.scss'

export const CableInfo = () => {
    const { t } = useTranslation();
    const { open, nameInput, descriptionInput, catalogCode, onClose, onReturn, cancelDisabled, saveDisabled, onSave, onCancel, polarityInput } = useCableInfo();
    return (
        <Drawer
            className="cable-info"
            open={open}
            onClose={onClose}
            logo={CorningLogo}
        >
            <DrawerPageTitle title="Cable Information" catalogCode={catalogCode} />
            <div className="cable-name">
                <FilledTextField
                    label={nameInput.label}
                    palette='primary'
                    value={nameInput.value}
                    onChange={e => nameInput.onChange && nameInput.onChange(e)}
                    maxLength={nameInput.maxLength}
                    error={!nameInput.isValid}
                    disabled={nameInput.disabled}/>
            </div>
            <div className="cable-desc">
                <FilledTextField
                    label={descriptionInput.label}
                    palette='primary'
                    value={descriptionInput.value}
                    onChange={e => descriptionInput.onChange && descriptionInput.onChange(e)}
                    maxLength={descriptionInput.maxLength}
                    error={!descriptionInput.isValid}
                    disabled={descriptionInput.disabled}/>
            </div>
            <div className="cable-polarity">
                <FilledTextField
                    label={polarityInput.label}
                    palette='primary'
                    value={polarityInput.value}
                    onChange={e => polarityInput.onChange && polarityInput.onChange(e)}
                    maxLength={polarityInput.maxLength}
                    error={!polarityInput.isValid}
                    disabled={polarityInput.disabled}/>
            </div>
            <div className="info-buttons">
                <OutlinedButton className="back-button" onClick={onReturn} startIcon={<ChevronLeft/>}>
                    {t(LocalizationKeys.Back)}
                </OutlinedButton>
                <div className="right-info-buttons">
                    <TextButton className="cancel-button" disabled={cancelDisabled} onClick={onCancel}>
                        {t(LocalizationKeys.Cancel)}
                    </TextButton>
                    <ContainedButton className="save-button" disabled={saveDisabled} onClick={onSave}>
                        {t(LocalizationKeys.Save)}
                    </ContainedButton>
                </div>
            </div>
        </Drawer>
    );
}