import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next"
import { LocalizationKeys } from "../../../../../../../locales/types";
import { IFiberMappingConnectorProps } from "./types";
import { FiberMappingContext } from "../redux/types";
import { PolarityContext } from "../../redux/types";
import { setSourceIndex, setTAPConnectorsIndex, setTAPConnectorsNavigation, setTAPIndex } from "../redux/reducer";
import { SectionContext, Feeder } from "../../../../../../../pixi/components/build/types";

export const useFiberMappingConnector = (props: IFiberMappingConnectorProps) => {
    const { type, disabled } = props;
    const section = useContext(SectionContext);
    const { state, dispatch } = useContext(FiberMappingContext);
    const { state: polarityState } = useContext(PolarityContext);
    const { t } = useTranslation();

    const previousDisabled = section === Feeder ? state.navigation.source.currentIndex === 0 : state.navigation.destination.taps.currentIndex === 0 && state.navigation.destination.connectors.currentIndex === 0;
    const onPreviousClick = useCallback(() => {
        if (section === Feeder) {
            const previousIndex = state.navigation.source.currentIndex ? state.navigation.source.currentIndex - 1 : 0;
            dispatch(setSourceIndex(previousIndex));
        } else {
            let previousIndex = state.navigation.destination.connectors.currentIndex - 1;
            if (previousIndex < 0) {
                let previousTAPIndex = state.navigation.destination.taps.currentIndex - 1;
                if (previousTAPIndex >= 0) {
                    const connectorAssignment = polarityState.connectorAssignment.destinations[previousTAPIndex];
                    dispatch(setTAPIndex(previousTAPIndex));
                    dispatch(setTAPConnectorsNavigation(connectorAssignment.connectors.length));
                    dispatch(setTAPConnectorsIndex(connectorAssignment.connectors.length - 1));
                }
            } else {
                dispatch(setTAPConnectorsIndex(previousIndex));
            }
        }
    }, [section, state.navigation, dispatch, polarityState.connectorAssignment]);

    const nextDisabled = section === Feeder ? state.navigation.source.currentIndex === state.navigation.source.nbIndex - 1 : state.navigation.destination.taps.currentIndex === state.navigation.destination.taps.nbIndex - 1 && state.navigation.destination.connectors.currentIndex === state.navigation.destination.connectors.nbIndex - 1;
    const onNextClick = useCallback(() => {
        if (section === Feeder) {
            const nextIndex = (state.navigation.source.currentIndex + 1) % state.navigation.source.nbIndex;
            dispatch(setSourceIndex(nextIndex));
        } else {
            let nextIndex = state.navigation.destination.connectors.currentIndex + 1;
            if (nextIndex >= state.navigation.destination.connectors.nbIndex) {
                const nextTAPIndex = (state.navigation.destination.taps.currentIndex + 1) % state.navigation.destination.taps.nbIndex;
                const connectorAssignment = polarityState.connectorAssignment.destinations[nextTAPIndex];
                dispatch(setTAPIndex(nextTAPIndex));
                dispatch(setTAPConnectorsNavigation(connectorAssignment.connectors.length));
                dispatch(setTAPConnectorsIndex(0));
            } else {
                dispatch(setTAPConnectorsIndex(nextIndex));
            }
        }
    }, [section, state.navigation, dispatch, polarityState.connectorAssignment]);

    return {
        type,
        disabled,
        previousProps: {
            disabled: previousDisabled,
            tooltip: t(LocalizationKeys.Previous),
            onClick: onPreviousClick
        },
        nextProps: {
            disabled: nextDisabled,
            tooltip: t(LocalizationKeys.Next),
            onClick: onNextClick
        }
    }
}