import { ConnectorAssignmentRowProps } from "./types";
import { useCallback, useContext, useMemo } from "react";
import { deleteAssignment, handleSelection } from "../redux/reducer";
import { ConnectorAssignmentContext, IConnectorMap } from "../redux/types";
import { PolarityContext } from "../../redux/types";
import { batch, useDispatch, useSelector } from "react-redux";
import { deleteAssignedMap, prepareFiberMapping } from "../../redux/reducer";
import { BuildService } from "../../../../../../services/build-service";
import { setShowConnectorAssignment, setShowFiberMapping } from "../../../../redux/reducers";
import { sscSessionBusySelector } from "../../../../../../redux/ssc/selectors";
import { EDSSessionService } from "../../../../../../services/eds-session/eds-session-service";
import { setConfigStatus, setSessionBusy, setSessionWarnings } from "../../../../../../redux/ssc/reducer";
import { hybrisCustomerSelector } from "../../../../../authentication/redux/selectors";
import { setStatusState } from "../../../header/components/status/redux/reducer";
import { WorkspaceStatus } from "../../../header/components/status/redux/types";
import { setPolarityDescription } from "../../../../../../redux/build/reducers";
import { buildIdSelector, buildSessionIdSelector } from "../../../../../../selectors/build.selectors";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../../locales/types";
import { TooltipPlacement } from "@orbit/icon-button";
import { isPropagationActiveSelector } from "../../propagation/redux/selectors";
import { usePropagationResult } from "../../propagation/hooks";
import { currentStatusSelector } from "../../../header/components/status/redux/selectors";

export const useConnectorAssignmentRow = (props: ConnectorAssignmentRowProps) => {
    const { editable, ...data } = props;
    const { state: { assignmentMapping }, dispatch: assignmentDispatch } = useContext(ConnectorAssignmentContext);
    const { dispatch: polarityDispatch } = useContext(PolarityContext);
    const isPropagationActive = useSelector(isPropagationActiveSelector);
    const storeDispatch = useDispatch();
    const currentBuildId = useSelector(buildIdSelector)
    const sessionId = useSelector(buildSessionIdSelector);
    const hybrisCustomer = useSelector(hybrisCustomerSelector);
    const currentStatus = useSelector(currentStatusSelector);
    const sscSessionBusy = useSelector(sscSessionBusySelector);
    const locked = currentStatus === WorkspaceStatus.Locked || currentStatus === WorkspaceStatus.Saving || currentStatus === WorkspaceStatus.Loading || currentStatus === WorkspaceStatus.InitialLoad;
    const { getPropagationStatus } = usePropagationResult();
    const { t } = useTranslation();

    const { assigned, position, id, mapId, connectorType, selected, highlighted, fiberCount, unassignedFibers, selectionIndex, blockedFiberCount, disabled: dataDisabled } = data
    const disabled = dataDisabled && sscSessionBusy;

    const onRowClick = useCallback(() => {
        const connectorMap: IConnectorMap = {
            position: position,
            index: id,
            fiberCount: fiberCount,
            connectorType: connectorType,
            unassignedFibers: unassignedFibers,
            orderIndex: selectionIndex,
        };
        assignmentDispatch(handleSelection({
            connectorMap
        }));
    }, [assignmentDispatch, position, id, fiberCount, connectorType, unassignedFibers, selectionIndex]);

    const onButtonCellClick = useCallback((e: any) => {
        e.stopPropagation();
    }, []);

    const onDeleteAssignment = useCallback(async () => {
        if (sessionId) {
            assignmentDispatch(deleteAssignment(id));
            polarityDispatch(deleteAssignedMap(id))
            storeDispatch(setSessionBusy(true))
            storeDispatch(setStatusState(WorkspaceStatus.Saving))
            try {
                const buildRes = await new BuildService().deletePolarityConnectorMap(mapId ?? 0)
                const sscRes = await new EDSSessionService().deletePolarityMap(sessionId, id);
                if (buildRes.data && buildRes.data.polarityDescription) {
                    storeDispatch(setPolarityDescription(buildRes.data.polarityDescription));
                }
                batch(() => {
                    if (sscRes.succesful && sscRes.data) {
                        storeDispatch(setSessionWarnings({ buildId: currentBuildId, warnings: sscRes.data.warnings }));
                        storeDispatch(setConfigStatus({ buildId: currentBuildId, configStatus: sscRes.data.configStatus }));
                    }
                })
            } finally {
                storeDispatch(setStatusState(WorkspaceStatus.Saved))
                storeDispatch(setSessionBusy(false))
            }
        }
    }, [assignmentDispatch, id, polarityDispatch, mapId, sessionId, storeDispatch, currentBuildId]);

    const onEditFiberMap = useCallback(() => {
        const assignmentWithSource = assignmentMapping.find(m => m.sourceMapping.find(m => m.index === id));
        if (assignmentWithSource) {
            polarityDispatch(prepareFiberMapping({ connectorIndex: id, assignmentMap: assignmentWithSource }));
            storeDispatch(setShowFiberMapping(true));
            storeDispatch(setShowConnectorAssignment(false));
        }
    }, [polarityDispatch, id, storeDispatch, assignmentMapping]);

    const assignedIconProps = {
        className: !disabled ? "material-icons" : "material-icons disabled",
        icon: "check_circle",
    };

    let className: string = "";
    if (assigned) {
        className = selected || highlighted ? "assigned selected" : "assigned";
    } else {
        className = selected ? "selected" : "";
    }
    const fiberAssignmentLabel = fiberCount - unassignedFibers + "/" + (fiberCount - blockedFiberCount);
    const selectionLabel = (selectionIndex + 1).toString();

    const deleteIconProps = {
        className: "delete-icon",
        palette: MainPalettes.error,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement,
        disabled: data.disabled,
        onClick: onDeleteAssignment
    };

    const editIconProps = {
        className: "edit-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement,
        onClick: onEditFiberMap
    }

    const propagationSuccessful = t(LocalizationKeys.PropagationSuccessful)
    const propagationStatus = useMemo(() => {
        if (locked || disabled) {
            return t(LocalizationKeys.PropagationResultPending);
        }
        let status = "";
        if (assigned && isPropagationActive && mapId) {
            const map = assignmentMapping.find(m => m.id === mapId);
            if (map) {
                status = getPropagationStatus(map);
            }
        }
        return status.length > 0 ? status : propagationSuccessful;
    }, [locked, disabled, t, assigned, isPropagationActive, mapId, assignmentMapping, getPropagationStatus, propagationSuccessful]);
    const isPropagationSuccesful = propagationStatus === propagationSuccessful;

    const propagationIconProps = {
        className: "propagation-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: propagationStatus,
        placement: "bottom" as TooltipPlacement
    };

    const propagation = {
        active: isPropagationActive,
        successful: isPropagationSuccesful,
        iconProps: propagationIconProps
    };

    return { 
        editable,
        data: { 
            ...data,
            className,
            fiberAssignmentLabel,
            selectionLabel 
        },
        onRowClick,
        onButtonCellClick,
        assignedIconProps,
        hybrisCustomer,
        disabled,
        deleteIconProps,
        editIconProps,
        propagation
    };
}
