import React from 'react';
import { useBuildToolbar } from './hooks';
import { GenericDialog } from '../../../../../../../../../../ui/dialog/generic-dialog/GenericDialog';
import { ToolItem } from '../../../../../../../ToolItem';
import { DuplicateDialog } from '../duplicate-dialog/DuplicateDialog';
import DisabledDeleteIcon from '../../../resources/icons/icon_delete_disabled.svg';
import DeleteIcon from '../../../resources/icons/icon_delete.svg';
import DisabledEditIcon from '../../../resources/icons/icon_edit_disabled.svg';
import EditIcon from '../../../resources/icons/icon_edit.svg';
import icon_details_disabled from '../../../resources/icons/icon_details_disabled.svg';
import icon_details from '../../../resources/icons/icon_details.svg';
import icon_duplicate from "../../../resources/icons/icon_duplicate.svg"

export const BuildToolbar = (props: { projectId: number, deleteDisabled: boolean, editDisabled: boolean }) => {
    const { projectId, deleteDisabled, editDisabled } = props;
    const { dialogContext, triggerDuplicate, triggerDetails, triggerInfo, triggerDelete, displayDialog, dialogMessage, dialogTitle, onDialogOpen, onDialogClose } = useBuildToolbar(projectId);
    return (
        <div className="build-toolbar">
            <ToolItem source={icon_duplicate} alt="Duplicate" tooltip="Duplicate" labelTop={true} onClick={() => triggerDuplicate()} />
            <ToolItem source={getDetailsIcon()} alt="Cable attributes" tooltip="Cable attributes" labelTop={true} onClick={() => triggerDetails(projectId)} />
            <ToolItem source={getEditIcon(editDisabled)} alt="Edit" disabled={editDisabled} tooltip="Edit" labelTop={true} onClick={() => triggerInfo(projectId)} />
            <ToolItem source={getDeleteIcon(deleteDisabled)} disabled={deleteDisabled} alt="Delete" tooltip="Delete" labelTop={true} onClick={onDialogOpen} />
            <GenericDialog title={dialogTitle} message={dialogMessage} display={displayDialog} closable onClose={onDialogClose} onConfirm={() => { onDialogClose(); triggerDelete(projectId); }} />
            <DuplicateDialog context={dialogContext} projectId={projectId} />
        </div>
    );
}

function getDeleteIcon(disabled?: boolean): string {
    return disabled ? DisabledDeleteIcon : DeleteIcon;
}

function getEditIcon(disabled?: boolean): string {
    return disabled ? DisabledEditIcon : EditIcon;
}

function getDetailsIcon(disabled?: boolean): string {
    return disabled ? icon_details_disabled : icon_details;
}