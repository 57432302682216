import React from 'react';
import { useActionToolbar } from "./hooks";
import { CollapseButton } from '../buttons/collapse/CollapseButton';
import { ExpandButton } from '../buttons/expand/ExpandButton';
import { ToolItem } from '../../../../../../ToolItem';
import { ColorDialog } from '../../../../../../../../../ui/dialog/color/ColorDialog';
import { GenericDialog } from '../../../../../../../../../ui/dialog/generic-dialog/GenericDialog';

import "../../../../Footer.scss";
import "./ActionToolbar.scss"

export const ActionToolbar = () => {
    const { actionToolbarProps, colorDialogProps, confirmResetDialogProps } = useActionToolbar();
    const { showToolbar, showCollapse, disableColorPalette, triggerCollapseAll, triggerExpandAll, onColorPaletteClick } = actionToolbarProps;

    return (
        <>
            { showToolbar &&
            <div className="controls-container toolbar-container background-blur toggle-pointer-events">
                <ToolItem icon="palette" disabled={disableColorPalette} onClick={onColorPaletteClick} />
                {showCollapse ? 
                <CollapseButton tooltip={"Collapse all"} disabled={false} onClick={triggerCollapseAll} /> 
                : 
                <ExpandButton tooltip={"Expand all"} disabled={false} onClick={triggerExpandAll} />}
                <ColorDialog {...colorDialogProps} />
                <GenericDialog {...confirmResetDialogProps} />
            </div>
            }
        </>
    );
}