import { ICollapsibleDialogProps } from "./types";
import { useReducer, useCallback, useEffect } from "react";
import { DialogReducer, showDialog } from "../../../workspace/redux/dialog/reducer";
import { initialDialogState, IDialogContext } from "../../../workspace/redux/dialog/types";

export const useCollapsibleDialog = (props: ICollapsibleDialogProps) => {
    const { display, className, headerProps, children } = props;
    const { onClose: dialogHeaderOnClose } = headerProps;
    const [state, dispatch] = useReducer(DialogReducer, initialDialogState);
    const context: IDialogContext = { state, dispatch };
    const { open, collapsed } = state.props;
    const dialogClassName = collapsed ? className + "-collapsed" : className;

    const onClose = useCallback(() => {
        dispatch(showDialog(false));
        if (dialogHeaderOnClose) {
            dialogHeaderOnClose();
        }
    }, [dispatch, dialogHeaderOnClose]);

    useEffect(() => {
        if (display) {
            dispatch(showDialog(true));
        } else {
            if (open) {
                onClose();
            }
        }
    }, [display, dispatch, open, onClose]);

    return { context, dialogClassName, open, onClose, headerProps, collapsed, children };
}