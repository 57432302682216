import { createSelector } from "reselect";
import { workspaceSelector } from "../../../../../../../selectors/root.selectors";

export const warningsSelector = createSelector(
    workspaceSelector,
    workspace => workspace.warnings
);

export const warningsCardDisplaySelector = createSelector(
    warningsSelector,
    (warnings) => warnings.displayCard,
);

export const acknowledgedWarningsSelector = createSelector(
    warningsSelector,
    (warnings) => warnings.acknowledgedWarnings,
);

export const unacknowledgedWarningGroupsSelector = createSelector(
    warningsSelector,
    (warnings) => warnings.unacknowledgedWarningGroups,
);

export const allWarningsSelector = createSelector(
    warningsSelector,
    (warnings) => [
        ...warnings.acknowledgedWarnings,
        ...warnings.unacknowledgedWarningGroups.flatMap((g) => g.warnings),
    ],
);
