import { Filters } from "../../../../factories/Filters";
import { Drop, DropContainer, IBaseProps, LabelProps, PositionLabel, SourceContainer, TrunkProps } from "../../types";
import { IFurcationProps, STAGGER_OFFSET_Y } from "../connectors/components/furcation/types";
import { Rectangle } from "../../../decorators/interaction/types";
import { getConnectorTexture } from "../../../../factories/Texture";
import { IPoint, IPointEmpty } from "../../../../types";

const WHITE = 0xFFFFFF;

export const useTrunk = ({ x, y, trunkContext, legProps }: TrunkProps) => {
    const { section, position, connectorType, isCollapsed, nbConnectors, nbGroups, wamLocation, furcationPoint, verticalTrunkSpriteProps, enabled } = trunkContext;
    
    const greyScaleFilter = Filters.getInstance().greyscaleMatrix;
    greyScaleFilter.enabled = !enabled;

    const furcation: IPoint = furcationPoint ?? IPointEmpty;
    const texture = getConnectorTexture(connectorType);

    const xs = legProps.map(m => m.location.x);
    const ys = legProps.map(m => m.location.y);
    
    const multiplier = isCollapsed && nbGroups <= 2 ? 1 : 0.5;
    const furcationOffsetY = (nbGroups + 1) * STAGGER_OFFSET_Y + texture.height * multiplier;

    const start: IPoint = { x: Math.min(...xs), y: Math.min(...ys) };
    const end: IPoint = { x: Math.max(...xs), y: Math.max(...ys) };
    const furcationEndY = end.y + furcationOffsetY

    const width = (end.x - start.x) + texture.width;
    const height = furcationEndY - furcation.y
    const buffer: Rectangle = { x: start.x, y: furcation.y, width, height };
    const furcationProps: IFurcationProps = { legProps };

    const positionLabelProps: LabelProps | undefined = section === Drop ? {
        x: 58,
        y: 25,
        name: PositionLabel,
        text: position.toString(),
        color: WHITE
    } : undefined;
    

    const furcationLabelProps: LabelProps = {
        x: furcation.x,
        y: furcationEndY,
        name: "furcation-label",
        text: `${nbConnectors} x ${connectorType} connector(s)`,
        color: 0x000000
    }

    const baseProps: IBaseProps = { 
        connectorType,
        wamLocation: section === Drop ? wamLocation : undefined
    }

    const ContainerName = section === Drop ? DropContainer : SourceContainer;

    return { 
        x, y, section, ContainerName,
        trunkContext, greyScaleFilter,
        buffer, furcationProps, verticalTrunkSpriteProps, 
        positionLabelProps, furcationLabelProps, baseProps 
    };

};