import { createSlice } from "@reduxjs/toolkit";
import { setRackNumberAction, setReverseOrderAction } from "./actions";
import { initialLabelCustomizationState } from "./types";

const labelCustomizationSlice = createSlice({
    initialState: initialLabelCustomizationState,
    name: "labelCustomization",
    reducers: {
        setRackNumber: setRackNumberAction,
        setReverseOrder: setReverseOrderAction
    }
});

export const LabelCustomizationReducer = labelCustomizationSlice.reducer;
export const { setRackNumber, setReverseOrder } = labelCustomizationSlice.actions;