import { StatusState, WorkspaceStatus, WorkspaceStatusTypes } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";


export const setStatusStateAction = (state: StatusState, action: PayloadAction<WorkspaceStatusTypes>) => {
    state.currentStatus = action.payload;
}

export const setTextureLoadedAction = (state: StatusState) => {
    state.textureStatus = WorkspaceStatus.Ready
}