import { Utils } from "../../utils";
import { AuthenticationService } from "../services/authentication-service";

export interface OktaServerConfig {
    useOkta: boolean;
    oktaDomain: string;
    oktaClientId: string;
    oktaScopes: string[];
}

export interface OktaConfig {
    enabled: boolean;
    issuer: string;
    clientId: string;
    scopes: string[];
    loginRedirect: string;
    logoutRedirect: string;
}

export class OktaConfig {
    private static config: OktaConfig;

    public static async getConfig(): Promise<OktaConfig> {
        if (!this.config) {
            const res = await new AuthenticationService().oidConfig();
            const serverConfig = res.data;
            if (!serverConfig) {
                throw new Error('Unable to retrieve the authentication configuration');
            }
            const isHybris = Utils.isHybrisEnvironment();
            if (serverConfig.useOkta && isHybris) {
                console.warn('Authentication configuration set to use Okta but a hybris customer was detected: Okta SSO was disabled');
            }
            this.config = {
                enabled: serverConfig.useOkta && !isHybris,
                issuer: serverConfig.oktaDomain,
                clientId: serverConfig.oktaClientId,
                scopes: serverConfig.oktaScopes,
                loginRedirect: window.location.origin + '/oid_login',
                logoutRedirect: window.location.origin + '/oid_logout',
            };
        }
        return this.config;
    }
}
