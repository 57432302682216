import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../../../../locales/types';
import { ToolItem } from '../../../../ToolItem';
import { AboutThisApplicationButton } from './components/about-application/AboutApplicationButton';
import { ExportButton } from './components/export/ExportButton';
import { FullscreenButton } from './components/fullscreen/FullscreenButton';
import { useToolsContainer } from './hooks';
import { PolarityButton } from '../polarity/components/button/PolarityButton';
import { ViewProduct } from '../view-product/ViewProduct';
import WarningIcon from '../../resources/icons/icon_warnings.svg'

export const ToolsContainer = () => {
    const { t } = useTranslation();
    const { disableAll, disableNewCable, measurementIcon, measurementLabel, warningCount, badgeClassName, isCustomer, unitsOfMeasureDisplay, triggerCreate, warningsListDisplay, moreMenuDisplay } = useToolsContainer();
    return (
        <div className="button-container background-blur tools-container toggle-pointer-events">
            <ViewProduct />
            <PolarityButton />
            <div className="vertical-divider" />
            <ToolItem 
                source={WarningIcon} 
                alt={t(LocalizationKeys.Warnings)} 
                disabled={disableAll} 
                tooltip={t(LocalizationKeys.Warnings)} 
                labelBottom={true} 
                onClick={warningsListDisplay}
                badge={warningCount}
                badgeClassName={badgeClassName}
            />
            <ExportButton />
            <ToolItem source={measurementIcon} alt={measurementLabel} disabled={disableAll} tooltip={measurementLabel} labelBottom={true} onClick={unitsOfMeasureDisplay} />
            <FullscreenButton />
            <AboutThisApplicationButton />
            <div className="vertical-divider" />
            <ToolItem icon="add_box" alt={t(LocalizationKeys.NewCable)} disabled={disableNewCable} tooltip={t(LocalizationKeys.NewCable)} labelBottom={true} onClick={triggerCreate} />
            {isCustomer ? null :
                <>
                    <div className="vertical-divider" />
                    <ToolItem icon="more_vert" onClick={moreMenuDisplay} />
                </>
            }
        </div>
    );
}
