import React from 'react';
import { IFiberMapRowProps } from "./types";

export const FiberMapRow: React.FC<IFiberMapRowProps> = ({ sourceFiber, fiberIndex, destinationFiber }) => {
    return (
        <tr> 
            <td>
                {sourceFiber.location}
            </td>
            <td>
                {sourceFiber.connectorIndex}
            </td>
            <td>
                {sourceFiber.connectorType}
            </td>
            <td>
                {sourceFiber.row}
            </td>
            <td>
                {sourceFiber.pinIndex}
            </td>
            <td>
                {fiberIndex}
            </td>
            <td>
                {destinationFiber.location}
            </td>
            <td>
                {destinationFiber.connectorIndex}
            </td>
            <td>
                {destinationFiber.connectorType}
            </td>
            <td>
                {destinationFiber.row}
            </td>
            <td>
                {destinationFiber.pinIndex}
            </td>
        </tr>
    )
}