import { useState, useEffect } from "react";
import { initialReportSettings } from "../../types";
import { useInputField } from "../../../../../../../../../ui/input/hooks";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../../../../locales/types";

export const useFooterFields = () => {
    const { t } = useTranslation();

    const [siteLocation, setSiteLocation] = useState(initialReportSettings.siteLocation);
    const [drawn, setDrawn] = useState(initialReportSettings.drawn);
    const [notes, setNotes] = useState(initialReportSettings.notes);
    const [date, setDate] = useState(getDateNowString());
    const [revision, setRevision] = useState(initialReportSettings.revision);

    const siteLocationField = useInputField(t(LocalizationKeys.Location), siteLocation!, 18);
    const drawnField = useInputField(t(LocalizationKeys.DrawingBy), drawn!, 15);
    const notesField = useInputField(t(LocalizationKeys.Notes), notes!, 60);
    const dateField = useInputField(t(LocalizationKeys.Date), date);
    const revisionField = useInputField(t(LocalizationKeys.RevisionNumber), revision!, 10);

    // update fields
    useEffect(() => {
        setSiteLocation(siteLocationField.value as string)
    }, [siteLocationField.value])

    useEffect(() => {
        setDrawn(drawnField.value as string)
    }, [drawnField.value])

    useEffect(() => {
        setNotes(notesField.value as string)
    }, [notesField.value])

    useEffect(() => {
        setDate(dateField.value as string)
    }, [dateField.value])

    useEffect(() => {
        setRevision(revisionField.value as string)
    }, [revisionField.value])

    return {
        footerFields: [dateField, revisionField, siteLocationField, drawnField, notesField],
        fieldValues: { siteLocation, drawn, notes, date, revision }
    }
}


function getDateNowString() {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0');;
    const day = String(date.getDate()).padStart(2, '0')
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}