import * as Pixi from 'pixi.js';
import { CONNECTOR_STEP } from "../../connectors/types";
import { useContext, useState, useEffect, useCallback } from "react";
import { FEEDER_MAX_CONNECTORS_PREEXPAND, TRUNK_BORDER_WIDTH, TRUNK_CABLE_WIDTH, TRUNK_SHADOW_WIDTH, TRUNK_SHADOW_ALPHA } from "../../feeder-base/types";
import { DROP_MAX_CONNECTORS_PREEXPAND } from "../../drop-base/types";
import { MetaSourceTrunkTexture, MetaTAPTrunkTexture } from '../../../../../factories/Texture';
import { useSelector } from 'react-redux';
import { fiberTypeTrunkColorSelector } from '../../../../../../workspace/redux/ssc/selectors';
import { TrunkContext } from '../../../../../../workspace/redux/build/types';
import { BORDER_COLOR, CABLE_COLOR } from '../../connectors/components/furcation/types';
import { Feeder } from '../../../types';

export const useHorizontalTrunk = () => {
    const { section, isCollapsed, nbConnectors } = useContext(TrunkContext);
    const [maxConnectorsPreExpand, setMaxConnectorsPreExpand] = useState(getMaxConnectorsPreExpand(section));
    const [display, setDisplay] = useState(nbConnectors > maxConnectorsPreExpand);
    const { colorHex } = useSelector(fiberTypeTrunkColorSelector)

    const drawHorizontalTrunk = useCallback((grfx: Pixi.Graphics) => {
        grfx.clear();

        const lineX = section === Feeder ? MetaSourceTrunkTexture.width - 12 : MetaTAPTrunkTexture.width - 15;
        let lineY = section === Feeder ? 16.2 : 32.6;
        const htWidth = getHorizontalTrunkWidth(nbConnectors, false, maxConnectorsPreExpand);
        const endX = section === Feeder ? lineX + htWidth * 0.5 : lineX + htWidth;
    
        // Border
        grfx.moveTo(lineX, lineY);
        grfx.lineStyle(TRUNK_BORDER_WIDTH, BORDER_COLOR);
        grfx.lineTo(endX, lineY);
    
        // Cable
        grfx.moveTo(lineX, lineY);
        grfx.lineStyle(TRUNK_CABLE_WIDTH, colorHex ? Number(colorHex) : CABLE_COLOR);
        grfx.lineTo(endX, lineY);
    
        // Shadow
        lineY = section === Feeder ? 23 : 39.1;
        grfx.moveTo(lineX, lineY);
        grfx.lineStyle(TRUNK_SHADOW_WIDTH, BORDER_COLOR, TRUNK_SHADOW_ALPHA);
        grfx.lineTo(endX, lineY);
    }, [nbConnectors, section, maxConnectorsPreExpand, colorHex]);

    useEffect(() => {
        setMaxConnectorsPreExpand(getMaxConnectorsPreExpand(section))
    }, [section]);

    useEffect(() => {
        setDisplay(nbConnectors > maxConnectorsPreExpand && !isCollapsed)
    }, [nbConnectors, maxConnectorsPreExpand, isCollapsed]);

    return { display, section, drawHorizontalTrunk };
}

function getMaxConnectorsPreExpand(sectionContext: string): number {
    return sectionContext === Feeder ? FEEDER_MAX_CONNECTORS_PREEXPAND * 2 : DROP_MAX_CONNECTORS_PREEXPAND;
}

export function getHorizontalTrunkWidth(expandedConnectors: number, isCollapsed: boolean, maxConnectorsPreExpand: number) {
    const extraConnectors = expandedConnectors - maxConnectorsPreExpand;
    let width = extraConnectors * CONNECTOR_STEP > 0 ? extraConnectors * CONNECTOR_STEP : 0;
    return isCollapsed ? 0 : width;
}


