import { useSelector, useDispatch, batch } from "react-redux";
import { useBuildInfo } from "../../../projects/components/drawer/components/row/build-info/hooks";
import { useCallback } from "react";
import { setDrawerDisplay } from "../../../projects/redux/reducers";
import { currentBuildSelector } from "../../../../../../selectors/root.selectors";
import { disabledUISelector } from "../status/redux/selectors";
import { showConnectorAssignmentSelector, showFiberMappingSelector } from "../../../../redux/selectors";
import { setShowConnectorAssignment, setShowFiberMapping } from "../../../../redux/reducers";
import { warningsCardDisplaySelector } from "../warnings/redux/selectors";
import { setWarningsCardDisplay } from "../warnings/redux/reducer";
import { unitsOfMeasureContainerDisplaySelector } from "../units-of-measure-container/redux/selectors";
import { setUnitsOfMeasureContainerDisplay } from "../units-of-measure-container/redux/reducers";
import { isAnonymousUserSelector } from '../../../../../authentication/redux/selectors';

export const useProjectButton = () => {
    const connectorAssignmentDisplayed = useSelector(showConnectorAssignmentSelector);
    const fiberMappingDisplayed = useSelector(showFiberMappingSelector);
    const warningsDisplayed = useSelector(warningsCardDisplaySelector);
    const unitsOfMeasureContainerDisplayed = useSelector(unitsOfMeasureContainerDisplaySelector);
    const currentBuild = useSelector(currentBuildSelector);
    const disabledUI = useSelector(disabledUISelector);
    const isAnonymous = useSelector(isAnonymousUserSelector);
    const disabled = disabledUI || isAnonymous;
    const { name } = useBuildInfo(currentBuild!.id!);
    const catalogCode = currentBuild?.catalogCode ?? "";
    const dispatch = useDispatch();
    
    const closeOtherDialogs = useCallback(() => {
        if (connectorAssignmentDisplayed) {
            dispatch(setShowConnectorAssignment(false));
        }

        if (fiberMappingDisplayed) {
            dispatch(setShowFiberMapping(false));
        }

        if (warningsDisplayed) {
            dispatch(setWarningsCardDisplay(false));
        }

        if (unitsOfMeasureContainerDisplayed) {
            dispatch(setUnitsOfMeasureContainerDisplay(false));
        }

    }, [connectorAssignmentDisplayed, fiberMappingDisplayed, warningsDisplayed, unitsOfMeasureContainerDisplayed, dispatch]);

    const openDrawer = useCallback(async () => {
        batch(() => {
            dispatch(setDrawerDisplay!(true));
            closeOtherDialogs();


        })
    }, [closeOtherDialogs, dispatch]);

    return { name, catalogCode, disabled, isAnonymous, openDrawer };
}