import { SecuredService } from "../secured-service";
import { IApiResult } from "../api-result";
import { UserWizardPreset, WizardPreset } from "../../redux/build/wizard/wizard-preset/redux/types";
import { UserWizardPresetDTO, ToUserWizardPresetDTO, WizardPresetDTO, ToWizardPresetDTO } from "./types";

export class WizardPresetService extends SecuredService {
    private readonly baseUrl = '/api/wizardpreset'

    public async getUserWizardPreset(): Promise<IApiResult<UserWizardPresetDTO>> {
        const res = await this.get(`${this.baseUrl}`);
        if (res.data) {
            const dto = res.data as UserWizardPresetDTO;
            res.data = dto;
        }
        return res;
    }

    public async addUserWizardPreset(userWizardPreset: UserWizardPreset): Promise<IApiResult<UserWizardPresetDTO>> {
        const uwpDTO = ToUserWizardPresetDTO(userWizardPreset);
        const res = await this.post(`${this.baseUrl}/user`, uwpDTO);
        if (res.data) {
            const dto = res.data as UserWizardPresetDTO;
            res.data = dto;
        }
        return res;
    }

    public async addWizardPreset(wizardPreset: WizardPreset): Promise<IApiResult<WizardPresetDTO>> {
        const wpDTO = ToWizardPresetDTO(wizardPreset);
        const res = await this.post(`${this.baseUrl}`, wpDTO);
        if (res.data) {
            const dto = res.data as WizardPresetDTO;
            res.data = dto;
        }
        return res;
    }

    public async updateUserWizardpreset(userWizardPreset: UserWizardPreset): Promise<IApiResult<UserWizardPresetDTO>> {
        const dto = ToUserWizardPresetDTO(userWizardPreset);
        const res = await this.put(this.baseUrl, dto);

        if (res.data) {
            const dto = res.data as UserWizardPresetDTO;
            res.data = dto;
        }
        return res;
    }

    public async updateSelectedWizardPreset(selectedWizardPreset: WizardPreset): Promise<IApiResult<UserWizardPresetDTO>> {
        const userWizardPreset: UserWizardPresetDTO = {
            selectedPresetId: selectedWizardPreset.id,
            wizardPresets: []
        };
        const res = await this.put(`${this.baseUrl}/selectedpreset`, userWizardPreset);
        if (res.data) {
            const dto = res.data as UserWizardPresetDTO;
            res.data = dto;
        }
        return res;
    }

    public async deleteWizardPreset(wizardPresetId: number): Promise<IApiResult<void>> {
        return await this.delete<void>(`${this.baseUrl}/delete/${wizardPresetId}`);
    }
}