import { IToolItemProps } from "../../../../../../ToolItem";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../../../redux/reducers";
import { useState, useEffect, useCallback } from "react";
import { setShowPrintDialog } from '../../../../../../redux/reducers';
import { WorkspaceStatus, WorkspaceStatusTypes } from "../../../status/redux/types";
import { showConnectorReportSelector } from '../../../../../../redux/selectors';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../../../../../../locales/types';
import { currentStatusSelector } from "../../../status/redux/selectors";
import { wizardSelector } from "../../../../../wizard/redux/selectors";
import { viewportSelector } from "../../../../../../../../../pixi/components/viewport/redux/selectors";

export const useExportButton = () : IToolItemProps => {
    const { t } = useTranslation();
    const currentStatus = useSelector(currentStatusSelector);
    const { display: wizardDisplay } = useSelector(wizardSelector)
    const connectorReportDisplay = useSelector(showConnectorReportSelector);
    const { context } = useSelector(viewportSelector)
    const [ state, setState ] = useState<IToolItemProps>({})
    const dispatch = useDispatch<AppDispatch>();

    const onClick = useCallback(() => {
        dispatch(setShowPrintDialog(true));
    }, [dispatch])

    useEffect(() => {
        const disabled = !(IsReportReady(currentStatus)) || wizardDisplay || connectorReportDisplay;
        setState({
            disabled,
            alt: t(LocalizationKeys.PrintReport),
            tooltip: t(LocalizationKeys.PrintReport),
            labelBottom: true
        });
    }, [t, connectorReportDisplay, context, currentStatus, wizardDisplay])

    return { ...state, onClick, icon: "print" };
}

export function IsReportReady(status: WorkspaceStatusTypes) {
    return status === WorkspaceStatus.Ready || status === WorkspaceStatus.Saved || status === WorkspaceStatus.Saving || status === WorkspaceStatus.Reporting || status === WorkspaceStatus.Locked;
}
