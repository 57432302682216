import React from "react";
import { DialogContext } from "../../../../../../../redux/dialog/reducer";
import { InputDialog } from "../../../../projects/components/drawer/components/row/generic-components/InputDialog";
import { useEditPolarityNameDialog } from "./hooks";
import { IEditPolarityDialogProps } from "./types";

export const EditPolarityNameDialog: React.FC<IEditPolarityDialogProps> = (props) => {
    const { context } = useEditPolarityNameDialog(props);
    const { open } = context.state.props;

    if (!open) return null;
    
    return (
        <DialogContext.Provider value={context}>
            <InputDialog {...props} />
        </DialogContext.Provider>
    )
}