import React from 'react'
import { usePolarityButton } from './hooks';
import { ToolItem } from '../../../../../../ToolItem';

import "../../../../Header.scss";

export const PolarityButton = () => {
    const state = usePolarityButton();
    return <ToolItem {...state} />;
}

export default PolarityButton;