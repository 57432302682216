import { createSlice } from "@reduxjs/toolkit";
import { apiErrorReducer } from "./actions";
import { initialState } from './types'
import { IApiResult } from "../../services/api-result";

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        apiError: apiErrorReducer
    }
})

export const apiError = (result: IApiResult<any>) => {
    return async (dispatch: (action: any) => void) => {
        console.error(`API Error ${result.code}: ${result.reason}`);
    };
};

export const ErrorReducer = errorSlice.reducer;

