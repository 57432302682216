import { Add, Fullscreen, FullscreenExit, Remove, UnfoldMore, ZoomIn } from "@mui/icons-material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { usePanAndZoomToolbar } from "./hooks";
import { IPanAndZoomToolbarProps } from "./types";
import { LocalizationKeys } from "../../../../../../../../../locales/types";

export const PanAndZoomToolbar: React.FC<IPanAndZoomToolbarProps> = (props) => {
    const {
        currentZoom, onZoomIn, onZoomOut, fitOption, onResizeClick, fullscreen, container, onToggleFullscreen, isMenuOpen, menuButtonRef, onMenuOpen, onMenuClose, onMenuClick, buttonLabel
    } = usePanAndZoomToolbar(props);

    const { t } = useTranslation();

    return (
        <div className="pan-and-zoom-toolbar">
            <div className="toolbar-group">
                <div className="toolbar-item zoom-control">
                    <IconButton onClick={onZoomIn}>{<Add />}</IconButton>
                    <IconButton onClick={onZoomOut}>{<Remove />}</IconButton>
                    <hr className="vertical-divider" />
                    <span className="current-zoom">{currentZoom}</span>
                </div>
                <div className="toolbar-item resize-control">
                    <IconButton onClick={onResizeClick} className="resize-button"><ZoomIn /></IconButton>
                    <Button className="menu-button" onClick={onMenuOpen} endIcon={<UnfoldMore />} ref={menuButtonRef}>{buttonLabel}</Button>
                    <Menu open={isMenuOpen} onClose={onMenuClose} anchorEl={menuButtonRef.current} container={fullscreen ? container : undefined} keepMounted>
                        <MenuItem selected={fitOption === "page"} onClick={() => onMenuClick("page")}>{t(LocalizationKeys.FitToPage)}</MenuItem>
                        <MenuItem selected={fitOption === "width"} onClick={() => onMenuClick("width")}>{t(LocalizationKeys.FitToWidth)}</MenuItem>
                        <MenuItem selected={fitOption === "height"} onClick={() => onMenuClick("height")}>{t(LocalizationKeys.FitToHeight)}</MenuItem>
                    </Menu>
                </div>
                <div className="toolbar-item reference-note">{t(LocalizationKeys.ReferenceFootNote)}</div>
            </div>
            <IconButton className="fullscreen-button" onClick={onToggleFullscreen}>{fullscreen ? <FullscreenExit /> : <Fullscreen />}</IconButton>
        </div>
    )
}