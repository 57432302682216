
import { useCallback, useEffect, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { renewToken } from "../components/authentication/redux/reducers";
import { setStatusState } from "../components/overlay/components/header/components/status/redux/reducer";
import { currentStatusSelector } from "../components/overlay/components/header/components/status/redux/selectors";
import { WorkspaceStatus } from "../components/overlay/components/header/components/status/redux/types";
import { setShowIdle } from "../components/overlay/redux/reducers";
import { AppDispatch } from "../redux/reducers";
import { workspaceSelector } from "../selectors/root.selectors";
import { AuthenticationService } from "../services/authentication-service";
import { FIVE_MINS, FOUR_HOURS, MS_MULTIPLIER } from "./timer/types";

export const useActivityTracker = () => {
    const status = useSelector(currentStatusSelector);
    const token = AuthenticationService.getToken();
    const expiration = (token ? (token.exp - token.iat) : FOUR_HOURS) * MS_MULTIPLIER;

    // Listen for changes on the workspace
    const workspace = useSelector(workspaceSelector);

    const changeRef = useRef(false);
    const clickRef = useRef(false);

    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
    const storeDispatch = useDispatch<AppDispatch>();

    const onTimeout = useCallback(async () => {
        if (changeRef.current && clickRef.current) {
            changeRef.current = false;
            storeDispatch(renewToken());
        } else {
            batch(() => {
                storeDispatch(setShowIdle(true));
                storeDispatch(setStatusState(WorkspaceStatus.Inactive));
            });
        }
        clickRef.current = false;
        setTimeoutId(undefined);
    }, [storeDispatch]);

    // Detect user activity
    useEffect(() => {
        window.addEventListener("click", () => { clickRef.current = true; });
        if (status !== WorkspaceStatus.Inactive) {
            changeRef.current = true;
        }

        return () => { window.removeEventListener("click", () => clickRef.current = true); };
    }, [status, workspace]);

    // Handle idling
    useEffect(() => {
        if (status !== WorkspaceStatus.Inactive && !timeoutId) {
            changeRef.current = false;

            const id = setTimeout(onTimeout, expiration - (FIVE_MINS * MS_MULTIPLIER));
            setTimeoutId(id);
        }
    }, [status, expiration, timeoutId, onTimeout]);

};