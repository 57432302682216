import { Dispatch } from 'react';
import { createSlice } from "@reduxjs/toolkit";
import { setDisplayPrintAction, 
         setShowConnectorReportAction, 
         setShowLabelSchemeAction,
         setShowTriggerManagementAction,
         setShowPolarityAction, 
         setDisablePolarityAction, 
         setShowCablePresetSnackbarAction, 
         setShowConnectorAssignmentAction, 
         setShowFiberMappingAction,
         setEnableFiberMapsOptionAction, 
         setEnablePolarityDiagramOptionAction, 
         setShowAboutApplicationAction, 
         setShowThirdPartyCopyrightAction,
         setShowViewProductAction, 
         setShowMoreMenuAction,
         setShowTimeoutAction, 
         setShowAnonymousUserWarningAction,
         setShowIdleAction } from "./actions";
import { initialOverlayState } from "./types";
import { setStatusState } from '../components/header/components/status/redux/reducer';
import { WorkspaceStatus } from '../components/header/components/status/redux/types';
import { setViewportContext } from '../../../../pixi/components/viewport/redux/reducers';
import { ViewportStatus } from '../../../../pixi/components/viewport/redux/types';

const overlaySlice = createSlice({
    name: 'overlay',
    initialState: initialOverlayState,
    reducers: {
        setPrintSettingsDisplay: setDisplayPrintAction,
        setEnablePolarityDiagramOption: setEnablePolarityDiagramOptionAction,
        setEnableFiberMapsOptions: setEnableFiberMapsOptionAction,
        setShowThirdPartyCopyright: setShowThirdPartyCopyrightAction,
        setShowAboutApplication: setShowAboutApplicationAction,
        setShowConnectorReport: setShowConnectorReportAction,
        setShowLabelScheme: setShowLabelSchemeAction,
        showTriggerManagement: setShowTriggerManagementAction,
        setShowPolarity: setShowPolarityAction,
        setDisablePolarity: setDisablePolarityAction,
        setShowCablePresetSnackbar: setShowCablePresetSnackbarAction,
        setShowConnectorAssignment: setShowConnectorAssignmentAction,
        setShowFiberMapping: setShowFiberMappingAction,
        setShowViewProduct: setShowViewProductAction,
        setShowMoreMenu: setShowMoreMenuAction,
        setShowIdle: setShowIdleAction,
        setShowTimeout: setShowTimeoutAction,
        setShowAnonymousUserWarning: setShowAnonymousUserWarningAction
    }
});

export const { 
    setPrintSettingsDisplay,
    setEnablePolarityDiagramOption,
    setEnableFiberMapsOptions,
    setShowThirdPartyCopyright,
    setShowAboutApplication,
    setShowConnectorReport,
    setShowLabelScheme,
    showTriggerManagement,
    setShowPolarity,
    setDisablePolarity,
    setShowCablePresetSnackbar,
    setShowConnectorAssignment,
    setShowFiberMapping,
    setShowViewProduct,
    setShowAnonymousUserWarning,
    setShowMoreMenu,
    setShowIdle,
    setShowTimeout
} = overlaySlice.actions;

export const OverlayReducer = overlaySlice.reducer;

export const setShowPrintDialog = (show: boolean) => {
    return (dispatch: Dispatch<any>): void => {
        dispatch(setStatusState(show ? WorkspaceStatus.Reporting : WorkspaceStatus.Ready));
        dispatch(setViewportContext(show ? ViewportStatus.Inactive : ViewportStatus.Active));
        dispatch(setPrintSettingsDisplay(show));
    };
};
