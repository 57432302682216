import { DialogProps, IDialogActions } from "@orbit/dialog";
import { IConnectorAssignmentMap } from "../connector-assignment/redux/types";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../locales/types";
import { setPropagationState, showPropagationDialog } from "./redux/reducer";
import { CheckboxProps } from "@orbit/checkbox";
import { MainPalettes } from "@orbit/theme-provider";
import { batch, useDispatch, useSelector } from "react-redux";
import { buildConnectorsSelector, buildIdSelector } from "../../../../../selectors/build.selectors";
import { IConnectorData } from "../../../../../redux/build/connector/types";
import { applyPropagation } from "../../../../../redux/build/reducers";
import { AppDispatch } from "../../../../../redux/reducers";
import { propagationSelector } from "./redux/selectors";
import { getConnectorType } from "../../wizard/redux/types";
import { Mustard } from "../../../../../../ui/dialog/color/types";
import { ConnectorAssignmentRelationship } from "../redux/types";
import { IPropagationOptions } from "./redux/types";

export const usePropagation = () => {
    const buildId = useSelector(buildIdSelector);
    const { showDialog, propagateColors, propagateLabels } = useSelector(propagationSelector);
    const { t } = useTranslation();
    const storeDispatch = useDispatch<AppDispatch>();

    const [isColorChecked, setIsColorChecked] = useState(propagateColors);
    const [isLabelChecked, setIsLabelChecked] = useState(propagateLabels);

    useEffect(() => {
        if (showDialog) {
            setIsColorChecked(propagateColors);
            setIsLabelChecked(propagateLabels);
        }
    }, [showDialog, propagateColors, propagateLabels]);
    
    const onClose = useCallback(() => {
        storeDispatch(showPropagationDialog(false));
    }, [storeDispatch]);

    const onConfirm = useCallback(() => {
        batch(() => {
            if (buildId !== undefined) {
                const isPropagationActive = isColorChecked || isLabelChecked;
                const options: IPropagationOptions = { propagateColors: isColorChecked, propagateLabels: isLabelChecked };
                if (isPropagationActive) {
                    storeDispatch(applyPropagation({ buildId, options }));
                }
                storeDispatch(setPropagationState(options));
            }
            onClose();
        });
    }, [buildId, isColorChecked, isLabelChecked, storeDispatch, onClose]);

    const actions: IDialogActions = {
        cancelText: t(LocalizationKeys.Cancel),
        onCancelClick: onClose,
        confirmText: t(LocalizationKeys.Save), 
        onConfirmClick: onConfirm,
        actionGuidance: {
            button: 'confirm',
            severity: 'standard'
        }
    };

    const dialogProps: DialogProps = {
        open: showDialog,
        className: "propagation-dialog",
        title: `${t(LocalizationKeys.Propagate)} ${t(LocalizationKeys.Connector)} ${t(LocalizationKeys.Attributes)}`,
        message: t(LocalizationKeys.PropagationMessage),
        modal: false,
        actions,
        onClose
    };

    const onTriggerCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setIsColorChecked(e.currentTarget.checked);
    }, []);

    const triggerCheckbox: CheckboxProps = {
        palette: MainPalettes.primary,
        placement: "end",
        label: `${t(LocalizationKeys.TriggerHousing)} ${t(LocalizationKeys.Color)}`,
        checked: isColorChecked,
        onChange: onTriggerCheckboxChange
    };

    const onLabelCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setIsLabelChecked(e.currentTarget.checked);
    }, []);

    const labelCheckbox: CheckboxProps = {
        palette: MainPalettes.primary,
        placement: "end",
        label: t(LocalizationKeys.LabelScheme),
        checked: isLabelChecked,
        onChange: onLabelCheckboxChange
    };

    return {
        dialogProps,
        checkboxes: {
            trigger: triggerCheckbox,
            label: labelCheckbox
        }
    }
}

// This hook can be used to track propagation on the front-end if needed
export const usePropagationResult = () => {
    const { propagateColors, propagateLabels } = useSelector(propagationSelector);
    const { srcConnectors, tapConnectors } = useSelector(buildConnectorsSelector);
    const { t } = useTranslation();

    const validateColorPropagation = useCallback((feederConnector: IConnectorData, tapConnector: IConnectorData): string => {
        if (feederConnector.type && tapConnector.type) {
            const feederFiberCount = getConnectorType(feederConnector.type).fiberCount;
            const tapFiberCount = getConnectorType(tapConnector.type).fiberCount;
            if (feederFiberCount !== tapFiberCount && feederFiberCount === 24 && feederConnector.color === Mustard.name) {
                return t(LocalizationKeys.PropagationColorRestrictionError);
            }
        }
        if (feederConnector.color !== tapConnector.color) {
            return t(LocalizationKeys.PropagationColorError);
        }
        return "";
    }, [t]);

    const validateLabelPropagation = useCallback((feederConnector: IConnectorData, tapConnector: IConnectorData, relationship: ConnectorAssignmentRelationship) => {
        if (relationship === "OneToMany") {
            return t(LocalizationKeys.PropagationOneToManyLabelError);
        }
        if (relationship === "ManyToOne") {
            return t(LocalizationKeys.PropagationManyToOneLabelError);
        }
        if (feederConnector.label !== tapConnector.label) {
            return t(LocalizationKeys.PropagationLabelError);
        }
        return "";
    }, [t]);

    const validatePropagationStatus = useCallback((feederConnector: IConnectorData, tapConnector: IConnectorData, relationship: ConnectorAssignmentRelationship, canPropagateColor: boolean = true, canPropagateLabel: boolean = true) => {
        let colorStatus = "";
        if (canPropagateColor) {
            colorStatus = validateColorPropagation(feederConnector, tapConnector);
        }
        let labelStatus = "";
        if (canPropagateLabel) {
            labelStatus = validateLabelPropagation(feederConnector, tapConnector, relationship);
        }
        return [colorStatus, labelStatus].join(" ").trim();
    }, [validateColorPropagation, validateLabelPropagation])

    const getPropagationStatus = useCallback(({ sourceMapping, destinationMapping }: IConnectorAssignmentMap) => {
        let status = "";
        if (sourceMapping.length === 1) { // one-to-one or one-to-many
            const srcConnectorIndex = sourceMapping[0].index - 1;
            const feederConnector = srcConnectors[srcConnectorIndex];
            const relationship = destinationMapping.length === 1 ? "OneToOne" : "OneToMany";
            for (const destination of destinationMapping) {
                const tapConnector = tapConnectors.filter(c => c.tapPosition === destination.position)[destination.index - 1];
                status = validatePropagationStatus(feederConnector, tapConnector, relationship, propagateColors, propagateLabels);
                if (status.length > 0) {
                    return status;
                }
            }
        } else { // many-to-one or many-to-many
            if (destinationMapping.length === 1) { // many-to-one
                const srcMapIndexes = sourceMapping.map(m => m.index);
                const srcMapConnectors = srcConnectors.filter((c, conIndex) => srcMapIndexes.includes(conIndex + 1));
                if (srcMapConnectors.length > 0) {
                    const feederConnector = srcMapConnectors[0];
                    const color = feederConnector.color;
                    if (propagateColors && !srcMapConnectors.every(c => c.color === color)) {
                        return t(LocalizationKeys.PropagationManyToOneColorError);
                    }
                    const destination = destinationMapping[0];
                    const tapConnector = tapConnectors.filter(c => c.tapPosition === destination.position)[destination.index - 1];
                    status = validatePropagationStatus(feederConnector, tapConnector, "ManyToOne", propagateColors, propagateLabels);
                    if (status.length > 0) {
                        return status;
                    }
                }
            } else { // many-to-many
                for (let srcMapIndex = 0; srcMapIndex < sourceMapping.length; srcMapIndex++) {
                    const feeder = sourceMapping[srcMapIndex];
                    const feederConnector = srcConnectors[feeder.index - 1];
                    const destination = destinationMapping[srcMapIndex];
                    const tapConnector = tapConnectors.filter(c => c.tapPosition === destination.position)[destination.index - 1];
                    status = validatePropagationStatus(feederConnector, tapConnector, "ManyToMany", propagateColors, propagateLabels);
                    if (status.length > 0) {
                        return status;
                    }
                }
            }
        }
        return status;
    }, [srcConnectors, tapConnectors, validatePropagationStatus, propagateColors, propagateLabels, t]);
    
    return { getPropagationStatus };
}