import React from 'react';
import { IMenuItemProps } from '../../types';

export const MenuItem = ({ label, icon, onClick }: IMenuItemProps) => {
    return (
        <li id={label} className="menu-item" onClick={onClick}>
            <div className="menu-item-icon material-icons">{icon}</div><span>{label}</span>
        </li>
    );
};
