import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../locales/types";
import { useSelector, useDispatch } from "react-redux";
import { showFiberMappingSelector } from "../../../redux/selectors";
import { currentSourceSelector, currentTAPsSelector, getGroupsConnectors, currentBuildCatalogCodeSelector, buildSessionIdSelector } from '../../../../../selectors/build.selectors';
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { setShowConnectorAssignment, setShowFiberMapping } from "../../../redux/reducers";
import { IDialogHeaderProps } from "../../../../../../ui/dialog/header/types";
import { ICollapsibleDialogProps } from "../../../../../../ui/dialog/collapsible/types";
import { IGenericDialogProps } from "../../../../../../ui/dialog/generic-dialog/types";
import { LC } from "../../../../../../pixi/factories/Texture";
import { FiberMappingReducer, setSourceIndex, setSourceNavigation, setTAPConnectorsIndex, setTAPConnectorsNavigation, setTAPIndex, setTAPNavigation, clearAll, setSelectedPinIndex, setFiberMapping, resetNavigation } from "./redux/reducer";
import { IFiberMappingContext, IFiberMappingState, initialFiberMappingState, IFiberMappingData } from "./redux/types";
import { IPolarityState, PolarityContext } from "../redux/types";
import { IConnectorData } from "../../../../../redux/build/connector/types";
import { registerMap, resetPolarity, setEditConnectorAssignmentMap, setPolarity } from "../redux/reducer";
import { CustomMap, CUSTOM_MAP_KEY } from "../../../../../redux/build/connector/polarity/types";
import { setHighlights } from "../../../../../../pixi/components/decorators/bounds/components/selection/redux/reducer";
import { IHighlight } from "../../../../../../pixi/components/decorators/bounds/components/selection/redux/types";
import { TFunction } from "i18next";
import { CUSTOM_POLARITY_RESERVED_WORDS, FiberMappingSaveDialogProps, POLARITY_MAX_CHARACTER } from "./save-dialog/types";
import { DialogTypes } from "../../../../../redux/dialog/types";
import { userFiberMapsNameSelector, userFiberMapsSelector } from "../../../../../redux/build/connector/polarity/selectors";
import { generateFiberMappingData, updateConnectorAssignmentMap } from "../redux/actions";
import { BuildService, getCurrentISODate } from "../../../../../services/build-service";
import { groupIdSelector, isAnonymousUserSelector } from "../../../../authentication/redux/selectors";
import { addUserFiberMaps, updateUserFiberMaps } from "../../../../../redux/build/connector/polarity/reducer";
import { CustomFiberMapData } from "../../../../../redux/build/connector/polarity/fiber-map/types";
import { currentStatusSelector } from "../../header/components/status/redux/selectors";
import { WorkspaceStatus } from "../../header/components/status/redux/types";
import { sscSessionBusySelector } from "../../../../../redux/ssc/selectors";
import { EDSSessionService } from "../../../../../services/eds-session/eds-session-service";
import { currentBuildSelector } from "../../../../../selectors/root.selectors";
import { setConfigStatus, setSessionBusy, setSessionWarnings } from "../../../../../redux/ssc/reducer";
import { setStatusState } from "../../header/components/status/redux/reducer";
import { setPolarityDescription, updateSpecificConnectors } from "../../../../../redux/build/reducers";
import { toApplyPolarityRequest } from "../../../../../services/eds-session/types";
import { SelectChangeEvent } from "@mui/material";
import { AppDispatch } from "../../../../../redux/reducers";
import { isPropagationActiveSelector, propagationOptionsSelector } from "../propagation/redux/selectors";
import { IPropagationOptions } from "../propagation/redux/types";
import { setPropagationNotification } from "../../notification/store/reducer";

export const useFiberMappingDialog = () => {
    const { t } = useTranslation();
    const display = useSelector(showFiberMappingSelector);
    const { state: polarityState, dispatch: polarityDispatch } = useContext(PolarityContext);
    const { connectorAssignment } = polarityState;
    const source = useSelector(currentSourceSelector);
    const destinations = useSelector(currentTAPsSelector);
    const [state, dispatch] = useReducer(FiberMappingReducer, initialFiberMappingState);
    const fiberMappingContext: IFiberMappingContext = { state, dispatch };
    const storeDispatch = useDispatch();
    const catalogCode = useSelector(currentBuildCatalogCodeSelector);
    const currentStatus = useSelector(currentStatusSelector);
    const sscSessionBusy = useSelector(sscSessionBusySelector);
    const locked = currentStatus === WorkspaceStatus.Locked;
    const hasCatalogCode = catalogCode.length > 0;
    const currentBuildEditable = !hasCatalogCode && !locked && !sscSessionBusy;
    const [editDisabled, setEditDisabled] = useState(false);

    const onClose = useCallback(() => {
        storeDispatch(setShowFiberMapping(false));
    }, [storeDispatch]);

    useEffect(() => {
        if (!display) {
            setEditDisabled(currentStatus === WorkspaceStatus.Locked);

            dispatch(clearAll(false));
            dispatch(resetNavigation());
            polarityDispatch(resetPolarity());
        }
    }, [currentStatus, display, dispatch, storeDispatch, polarityDispatch])

    const headerProps: IDialogHeaderProps = {
        title: t(LocalizationKeys.FiberPosition),
        collapsible: true
    };

    const dialogProps: ICollapsibleDialogProps = {
        display: display,
        className: "fiber-mapping-dialog",
        headerProps
    };

    const polarityName = getPolarityTypeName(polarityState, state, t);
    const polarityType = getPolarityType(polarityState, polarityName, t);

    const convertToCustomMap = useCallback(() => {
        if (polarityState.polarity && polarityState.polarity.key !== CUSTOM_MAP_KEY) {
            polarityDispatch(setPolarity(CustomMap));
        }
    }, [polarityState.polarity, polarityDispatch]);

    const clearAllDisabled = (state.mapping.length === 0 && state.unused.sourcePins.length === 0 && state.unused.destinationPins.length === 0) || !currentBuildEditable;
    const [showClearDialog, setShowClearDialog] = useState(false);

    const onClearAll = useCallback(() => {
        setShowClearDialog(true);
    }, []);

    const onClearClose = useCallback(() => {
        setShowClearDialog(false);
    }, [])

    const onClearConfirm = useCallback(() => {
        dispatch(clearAll(true));
        convertToCustomMap();
        onClearClose();
    }, [onClearClose, convertToCustomMap]);

    const clearAllDialogProps: IGenericDialogProps = {
        title: t(LocalizationKeys.ClearFiberMapping),
        display: showClearDialog,
        message: t(LocalizationKeys.ClearFiberMappingMessage),
        onClose: onClearClose,
        confirmText: t(LocalizationKeys.Clear),
        onConfirm: onClearConfirm,
        closable: true,
        critical: true
    };

    const data = useMemo(() => {
        let srcConnectors: { index: number, connector: IConnectorData }[] = [];
        let srcConnectorType = LC;
        if (source) {
            srcConnectors = getGroupsConnectors(source.groups).map((c, i) => { return { index: i, connector: c } });
            if (connectorAssignment.source.connectors.length > 0) {
                const srcConnector = srcConnectors[connectorAssignment.source.currentIndex];
                srcConnectors = srcConnectors.filter(c => connectorAssignment.source.connectors.find(x => x.index === c.index));
                const sourceIndex = srcConnectors.indexOf(srcConnector);
                if (sourceIndex !== -1 && state.navigation.source.currentIndex === -1 && state.navigation.source.currentIndex !== sourceIndex) {
                    dispatch(setSourceIndex(sourceIndex));
                }
            }
            if (srcConnectors.length > 0) {
                srcConnectorType = srcConnectors[0].connector.type ?? LC;
            }
        }

        dispatch(setSourceNavigation(srcConnectors.length));

        let taps = destinations;
        let tap = taps[0];
        let tapConnectors = getGroupsConnectors(tap.groups).map((c, i) => { return { index: i, connector: c } });;
        let tapConnectorType = LC;
        if (connectorAssignment.destinations.length > 0) {
            const tapsPosition = connectorAssignment.destinations.map(d => d.tapIndex);
            taps = destinations.filter(d => tapsPosition.includes(d.position));
            tap = taps[state.navigation.destination.taps.currentIndex];
            const connectors = getGroupsConnectors(tap.groups).map((c, i) => { return { index: i, connector: c } });
            tapConnectors = connectors.filter(c => {
                const existingAssignment = connectorAssignment.destinations.find(d => d.tapIndex === tap.position);
                return existingAssignment && existingAssignment.connectors.find(x => x.index === c.index);
            });
            tapConnectorType = tapConnectors[0].connector.type ?? LC;
        }

        dispatch(setTAPNavigation(taps.length));
        dispatch(setTAPConnectorsNavigation(tapConnectors.length));

        return {
            source: {
                connectorType: srcConnectorType,
                connectors: srcConnectors
            },
            destination: {
                taps,
                tap,
                connectorType: tapConnectorType,
                connectors: tapConnectors
            }
        }
    }, [source, connectorAssignment.source, state.navigation.source.currentIndex, destinations, state.navigation.destination, connectorAssignment.destinations]);

    const onSourceConnectorChange = useCallback((e: SelectChangeEvent<string>) => {
        const connectorIndex = Number(e.target.value);
        dispatch(setSourceIndex(connectorIndex));
    }, []);

    const onTAPChange = useCallback((e: SelectChangeEvent<string>) => {
        const tapIndex = Number(e.target.value);
        dispatch(setTAPIndex(tapIndex));
        dispatch(setTAPConnectorsIndex(0));
    }, []);

    const onTAPConnectorsChange = useCallback((e: SelectChangeEvent<string>) => {
        const connectorIndex = Number(e.target.value);
        dispatch(setTAPConnectorsIndex(connectorIndex));
    }, []);

    const onCancel = useCallback(() => {
        onClose();
        storeDispatch(setShowConnectorAssignment(true));
    }, [onClose, storeDispatch]);

    const { saveDisabled,
        saveAsDisabled,
        onSave, 
        fiberMappingSaveDialogProps, 
        updateFiberMapDialogProps: saveNewDialogProps, 
        onSaveAsClick } = useFiberMappingSave(state, polarityName, currentBuildEditable, onClose);

    const onKeyPress = useCallback((e: any) => {
        if (e.keyCode === 27) {
            if (state.navigation.selectedPinIndex !== -1) {
                dispatch(setSelectedPinIndex(-1));
            }
        }
    }, [state.navigation.selectedPinIndex, dispatch]);

    useEffect(() => {
        if (display) {
            document.addEventListener("keydown", onKeyPress, false);
        }
        return () => {
            document.removeEventListener("keydown", onKeyPress, false);
        };
    }, [display, onKeyPress]);

    useEffect(() => {
        if (display) {
            const sourceIndex = state.navigation.source.currentIndex;
            const source: IHighlight = { position: 0, index: connectorAssignment.source.connectors[sourceIndex].index, status: "AssignedSelected" };

            const destinationPostion = state.navigation.destination.taps.currentIndex;
            const destinationIndex = state.navigation.destination.connectors.currentIndex;
            const destination: IHighlight = {     
                position: connectorAssignment.destinations[destinationPostion].tapIndex,
                index: connectorAssignment.destinations[destinationPostion].connectors[destinationIndex].index,
                status: "AssignedSelected"
            };

            storeDispatch(setHighlights([source, destination]));
        }
    }, [display, state.navigation.source, state.navigation.destination, connectorAssignment.source, connectorAssignment.destinations, storeDispatch]);

    const dropdownProps = useMemo(() => {
        return {
            source: {
                value: state.navigation.source.currentIndex.toString(),
                onChange: onSourceConnectorChange
            },
            destination: {
                taps: {
                    value: state.navigation.destination.taps.currentIndex.toString(),
                    onChange: onTAPChange
                },
                connectors: {
                    value: state.navigation.destination.connectors.currentIndex.toString(),
                    onChange: onTAPConnectorsChange
                }
            }
        }
    }, [state.navigation, onSourceConnectorChange, onTAPChange, onTAPConnectorsChange]);

    useEffect(() => {
        if (polarityState.editAssignmentMap && polarityState.editAssignmentMap.fiberMap && display) {
            const { fiberMapping: fiberMappingData, unused } = generateFiberMappingData(polarityState.editAssignmentMap, polarityState.editAssignmentMap.fiberMap)
            const fiberMapping = {
                name: "",
                mapping: fiberMappingData,
                unused
            }
            dispatch(setFiberMapping(fiberMapping));
        }
        else if (polarityState.fiberMapping.length > 0) {
            const defaultFiberMap = {
                name: "",
                mapping: polarityState.fiberMapping,
                unused: {
                    sourcePins: [],
                    destinationPins: [],
                }
            }
            dispatch(setFiberMapping(defaultFiberMap));
        }
    }, [polarityState.fiberMapping, dispatch, polarityState.editAssignmentMap, display]);

    useEffect(() => {
        if ((state.mapping.length > 0 && polarityState.fiberMapping.length !== state.mapping.length) || state.unused.sourcePins.length > 0 || state.unused.destinationPins.length > 0) {
            convertToCustomMap();
        }
    }, [state.mapping, polarityState.fiberMapping, state.unused, convertToCustomMap]);

    return {
        dialogProps,
        polarityType,
        editable: currentBuildEditable,
        clearAllProps: {
            disabled: clearAllDisabled || editDisabled,
            onClick: onClearAll,
            dialogProps: clearAllDialogProps
        },
        data,
        dropdownProps,
        cancelProps: {
            disabled: !currentBuildEditable,
            text: t(LocalizationKeys.Cancel),
            onClick: onCancel
        },
        saveProps: {
            disabled: saveDisabled,
            text: t(LocalizationKeys.Save),
            onClick: onSave
        },
        saveAsProps: {
            disabled: saveAsDisabled,
            text: t(LocalizationKeys.SaveAs),
            onClick: onSaveAsClick
        },
        fiberMappingContext,
        fiberMappingSaveDialogProps,
        saveNewDialogProps
    };
}

const useFiberMappingSave = (state: IFiberMappingState, polarityType: string, editable: boolean, onFiberDialogClose: () => void) => {
    const { t } = useTranslation();
    const storeDispatch = useDispatch<AppDispatch>();
    const groupId = useSelector(groupIdSelector);
    const userFiberMaps = useSelector(userFiberMapsSelector);
    const { state: polarityState, dispatch: polarityDispatch } = useContext(PolarityContext);
    const isPropagationActive = useSelector(isPropagationActiveSelector);
    const propagationOptions = useSelector(propagationOptionsSelector);
    const [saveDialogOpen, setSaveDialogOpen] = useState(false);
    const [saveNewDialogOpen, setSaveNewDialogOpen] = useState(false);
    const [fiberMapName, setFiberMapName] = useState(polarityType);
    const currentBuild = useSelector(currentBuildSelector)
    const userFiberMapNames = useSelector(userFiberMapsNameSelector);
    const sessionBusy = useSelector(sscSessionBusySelector)
    const isAnonymous = useSelector(isAnonymousUserSelector);
    const saveDisabled = !state.isModified || !editable || sessionBusy;
    const saveAsDisabled = saveDisabled || isAnonymous;
    const sessionId = useSelector(buildSessionIdSelector);

    useEffect(() => {
        setFiberMapName(polarityType === "Custom" ? "" : polarityType);
    }, [polarityType])

    const onSaveAsClick = () => setSaveDialogOpen(true)
    const onDialogClose = useCallback(() => {
        setSaveDialogOpen(false);
        onFiberDialogClose();
    }, [onFiberDialogClose])

    const saveFiberMap = useCallback(async (value?: string, versionDate?: string) => {
        const fiberMappingData: IFiberMappingData = {
            groupId: groupId ?? -1,
            name: value || polarityType,
            mapping: state.mapping,
            unused: state.unused,
            versionDate
        };

        if (polarityState.editAssignmentMap) {
            const updatedMap = updateConnectorAssignmentMap({ ...polarityState.editAssignmentMap }, fiberMappingData);

            if (currentBuild && currentBuild.id !== undefined && sessionId) {
                storeDispatch(setStatusState(WorkspaceStatus.Saving))
                storeDispatch(setSessionBusy(true))
                
                const forcePropagation = updatedMap.id === undefined && updatedMap.polarityType === "Custom" && isPropagationActive;
                const options: IPropagationOptions = forcePropagation ? propagationOptions : { propagateColors: false, propagateLabels: false }; // No need to propagate on every fibermap updates
                await new BuildService().addOrUpdatePolarityConnectorMap(currentBuild.id, updatedMap, options).then((res) => {
                    if (res.succesful && res.data) {
                        const { connectorAssignments: polarityConnectorMaps, polarityDescription, userFiberMaps, propagationResult } = res.data;
                        polarityDispatch(registerMap(polarityConnectorMaps![0]))
                        polarityDispatch(setEditConnectorAssignmentMap(polarityConnectorMaps![0]));
                        storeDispatch(setPolarityDescription(polarityDescription!));
                        if (userFiberMaps && userFiberMaps.length) storeDispatch(addUserFiberMaps(userFiberMaps[0]));
                        if (propagationResult) {
                            const { status, connectors } = propagationResult;
                            storeDispatch(updateSpecificConnectors(connectors));
                            storeDispatch(setPropagationNotification({ status, ...options }));
                        }
                        const sscService = new EDSSessionService();
                        const source = currentBuild.source
                        const destinations = currentBuild.destinations;
                        const updatedMaps = [updatedMap];
                        const applyPolarityRequest = toApplyPolarityRequest(updatedMaps, destinations, source, { sourceConnectors: [], destinationConnectors: [], description: updatedMap.polarityType }, sessionId)
                        
                        sscService.applyPolarity(applyPolarityRequest).then(sscRes => {
                            storeDispatch(setSessionBusy(false))
                            if (sscRes.data) {
                                storeDispatch(setSessionWarnings({ buildId: currentBuild.id, warnings: sscRes.data.warnings }));
                                storeDispatch(setConfigStatus({ buildId: currentBuild.id, configStatus: sscRes.data.configStatus }))
                            }
                            storeDispatch(setStatusState(WorkspaceStatus.Saved))
                        }).catch((r) => {
                            storeDispatch(setSessionBusy(false))
                            storeDispatch(setStatusState(WorkspaceStatus.Saved))
                        });
                    }
                });
            }

            storeDispatch(setShowConnectorAssignment(true));
        }

    }, [state, polarityType, polarityState.editAssignmentMap, polarityDispatch, storeDispatch, groupId, currentBuild, sessionId, isPropagationActive, propagationOptions])

    const updateUserFiberMap = useCallback(async (name: string) => {
        if (polarityState.editAssignmentMap) {
            const userMap = userFiberMaps.find(m => m.name === name)
            const fiberMappingData: IFiberMappingData = {
                name,
                mapping: state.mapping,
                unused: state.unused
            };

            const updatedMap = updateConnectorAssignmentMap({ ...polarityState.editAssignmentMap }, fiberMappingData)

            if (userMap && updatedMap.fiberMap) {
                const updatedFiberMap: CustomFiberMapData = {
                    ...updatedMap.fiberMap,
                    key: `${CUSTOM_MAP_KEY}`,
                    id: userMap.id,
                    name,
                    versionDate: getCurrentISODate(),
                    sourceIndices: updatedMap.fiberMap.sourceIndices.map(s => {
                        return {...s, id: 0}
                    }),
                    destinationIndices: updatedMap.fiberMap.destinationIndices.map(d => {
                        return {...d, id: 0}
                    })
                }


                const res = await new BuildService().updateUserPolarityFiberMap(updatedFiberMap)
                if (res.succesful && res.data) {
                    storeDispatch(updateUserFiberMaps(updatedFiberMap))
                }
            }

        }
    }, [state, polarityState.editAssignmentMap, userFiberMaps, storeDispatch])

    const onConnectorSave = useCallback(async () => {
        await saveFiberMap();
        onDialogClose();
    }, [onDialogClose, saveFiberMap]);

    const onSaveAs = useCallback(async (value: string) => {
        setFiberMapName(value);
        if (userFiberMaps.find(f => f.name.toUpperCase() === value.toUpperCase())) {
            setSaveNewDialogOpen(true)
            setSaveDialogOpen(false)
        }
        else {
            const versionDate = getCurrentISODate();
            await saveFiberMap(value, versionDate);
            onDialogClose();
        }

    }, [onDialogClose, saveFiberMap, userFiberMaps])

    const onUpdateFiberMapConfirm = useCallback(async () => {
        await updateUserFiberMap(fiberMapName)
        setSaveNewDialogOpen(false)
        onDialogClose();
    }, [fiberMapName, updateUserFiberMap, onDialogClose])

    const onUpdateFiberMapClose = useCallback(() => {
        setSaveNewDialogOpen(false)
        setSaveDialogOpen(true)
        setFiberMapName(polarityState.editAssignmentMap?.fiberMap?.name ?? polarityType)
    }, [polarityState.editAssignmentMap, polarityType])


    const fiberMappingSaveDialogProps: FiberMappingSaveDialogProps = useMemo(() => {
        return {
            saveProps: {
            label: t(LocalizationKeys.Save),
            onClick: onSaveAs
            },
            cancelProps: {
                label: t(LocalizationKeys.Cancel),
                onClick: () => setSaveDialogOpen(false)
            },
            maxCharacterCount: POLARITY_MAX_CHARACTER,
            value: fiberMapName,
            props: {
                open: saveDialogOpen,
                onClose: onDialogClose,
            },
            type: DialogTypes.Confirm,
            inputFieldLabel: "Polarity Name",
            title: "New Custom Polarity",
            restrictions: [
                {
                    restrictedWords: CUSTOM_POLARITY_RESERVED_WORDS,
                    message: {
                        key: LocalizationKeys.InvalidWord,
                        showValue: true
                    },
                    isCaseSensitive: false
                },
                {
                    restrictedWords: Object.keys(userFiberMapNames),
                    message: {
                        key: LocalizationKeys.PolarityNameExists,
                        showValue: false
                    },
                    isCaseSensitive: true
                }
            ]
        }
    }, [onSaveAs, onDialogClose, fiberMapName, saveDialogOpen, userFiberMapNames, t]);

    const updateFiberMapDialogProps: IGenericDialogProps = {
        title: t(LocalizationKeys.SaveNewVersion),
        display: saveNewDialogOpen,
        message: t(LocalizationKeys.SaveNewVersionMessage),
        onConfirm: onUpdateFiberMapConfirm,
        onClose: onUpdateFiberMapClose,
        closable: true,
        confirmText: t(LocalizationKeys.Save)
    }


    return { saveDisabled, saveAsDisabled, onSave: onConnectorSave, fiberMappingSaveDialogProps, updateFiberMapDialogProps, onSaveAsClick };
}

function getPolarityTypeName(polarityState: IPolarityState, state: IFiberMappingState, t: TFunction) {
    const editMap = polarityState.editAssignmentMap
    if (editMap && editMap.fiberMap && editMap.fiberMap.name.length && !state.isModified) {
        return editMap.fiberMap.name
    }
    const polarityDescription = polarityState.polarity?.description ?? "";

    return polarityState.polarity && !state.isModified ? polarityDescription : t(LocalizationKeys.Custom);
}

function getPolarityType(polarityState: IPolarityState, polarityName: string, t: TFunction) {
        const polarityVersionDate = polarityState.editAssignmentMap?.fiberMap?.versionDate ? 
        isoToDateString(polarityState.editAssignmentMap?.fiberMap?.versionDate) : ""
    const polarityString = polarityVersionDate.length > 0 ? `${polarityName} (${polarityVersionDate})` : polarityName
    const polarityType = t(LocalizationKeys.PolarityType, { polarityType: polarityString });

    return polarityType;
}

export function isoToDateString(isoString: string) : string {
    const date = new Date(isoString) 
    const dateTime = date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit' });
    const dateString = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()} ${dateTime}`
    return dateString
}
