export const DROP_MAX_CONNECTORS_PREEXPAND = 10;

export const TETHER_BORDER_WIDTH = 14;
export const TETHER_CABLE_WIDTH = 12;
export const TETHER_SHADOW_WIDTH = 4;

export const TRUNK_BORDER_WIDTH = 20;
export const TRUNK_CABLE_WIDTH = 18;
export const TRUNK_SHADOW_WIDTH = 5;
export const TRUNK_SHADOW_ALPHA = 0.18;

export const DropContainer = "drop-container";
export const DropBaseContainer ="drop-base-container";
export const TetherTrunk = "tether-trunk";
export const DropHorizontalTrunk = "drop-horizontal-trunk"
export const PositionLabel = "position-label";