import { WebService } from './web-service';
import { AuthenticationService } from './authentication-service';
import { Utils } from '../../utils';
import { OktaService } from './okta-service';
import { ProxyConfigUtils } from './proxy-config';

export abstract class SecuredService extends WebService {
    constructor() {
        super();
        if (Utils.isDevelopmentEnvironment()) {
            const soldTo = (window as any).soldTo;
            if (soldTo) {
                this.addDynamicHeader('SoldTo', () => soldTo);
            }
        }
        else if (Utils.isHybrisEnvironment()) {
            const hybrisCustomer = (window as any).hybriscustomer;
            const { customerUid, accountUid, accountName, soldTo, unitOfMeasure } = hybrisCustomer;
            this.addDynamicHeader('X-Hybris-UID', () => customerUid ?? null);
            this.addDynamicHeader('X-Hybris-AccountName', () => accountName ?? null);
            this.addDynamicHeader('X-Hybris-AccountUid', () => accountUid ?? null);
            this.addDynamicHeader('X-Hybris-SoldTo', () => soldTo ?? null);
            this.addDynamicHeader('X-Hybris-UOM', () => unitOfMeasure === "FOT" ? "ft" : "m");
        }

        const implementationEnvironment = ProxyConfigUtils.getImplementationEnvironment();
        if (implementationEnvironment && implementationEnvironment.length > 0) {
            const proxyConfig = ProxyConfigUtils.getProxyConfig();
            this.addDynamicHeader('sessionId', () => OktaService.getOktaSessionId() ?? null);
            this.addDynamicHeader('client_id', () => proxyConfig.clientId ?? null);
            this.addDynamicHeader('client_secret', () => proxyConfig.clientSecret ?? null);
            this.addDynamicHeader('implementationEnvironment', () => implementationEnvironment);
        }

        this.addDynamicHeader('Authorization', () => {
            const token = AuthenticationService.getToken();
            if (!token || !token.raw || token.isExpired()) {
                return null;
            }
            return 'Bearer ' + token.raw;
        });
    }
}
