import { useCallback, useContext, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../../../../../../locales/types";
import { getBuildInfo, setBuildLoaded } from "../../../../../../../../../redux/build/reducers";
import { DialogReducer, showDialog } from "../../../../../../../../../redux/dialog/reducer";
import { IDialogContext, initialDialogState } from "../../../../../../../../../redux/dialog/types";
import { totalBuildCountSelector } from "../../../../../../../../../redux/project-manager/selectors";
import { AppDispatch } from "../../../../../../../../../redux/reducers";
import { buildIdSelector, buildSessionIdSelector, buildsPageIndexSelector } from "../../../../../../../../../selectors/build.selectors";
import { edsSessionService } from '../../../../../../../../../services/eds-session/eds-session-service';
import { useWebLoad } from "../../../../../../header/components/status/hooks";
import { setStatusState } from "../../../../../../header/components/status/redux/reducer";
import { WorkspaceStatus } from "../../../../../../header/components/status/redux/types";
import { deleteProject, setRecentProjectId, setSelectedBuildId, showCableInfo } from "../../../../../redux/reducers";
import { useProjectDrawer } from "../../../hooks";
import { setReloading } from "../../../redux/reducers";
import { ProjectDrawerContext } from "../../../redux/types";
import { useCreateButton } from "../../sub-header/hooks";
import { useBuildInfo } from "../build-info/hooks";

export const useBuildToolbar = (projectId: number) => {
    const { triggerDuplicate, dialogContext } = useDuplicate();
    const { triggerDetails } = useDetails();
    const { triggerInfo } = useInfo();
    const { triggerDelete } = useDelete();
    const { displayDialog, dialogMessage, dialogTitle, onDialogOpen, onDialogClose } = useDeleteDialog(projectId);

    return { dialogContext, triggerDuplicate, triggerDetails, triggerInfo, triggerDelete, displayDialog, dialogMessage, dialogTitle, onDialogOpen, onDialogClose };
}

const useDuplicate = () => {
    const [state, dispatch] = useReducer(DialogReducer, initialDialogState);
    const dialogContext: IDialogContext = { state, dispatch };

    const triggerDuplicate = useCallback(() => {
        dispatch(showDialog(true));
    }, []);

    return { dialogContext, triggerDuplicate };
}

const useDetails = () => {
    const dispatch = useDispatch<AppDispatch>();

    const triggerDetails = useCallback(async (buildId: number) => {
        await dispatch(getBuildInfo(buildId));
    }, [dispatch]);

    return { triggerDetails };
}

const useInfo = () => {
    const dispatch = useDispatch();

    const triggerInfo = useCallback((buildId: number) => {
        batch(() => {
            dispatch(setSelectedBuildId(buildId));
            dispatch(showCableInfo(true));
        });
    }, [dispatch]);

    return { triggerInfo };
}

const useDelete = () => {
    const currentBuildId = useSelector(buildIdSelector);
    const totalBuildCount = useSelector(totalBuildCountSelector);
    const pageIndex = useSelector(buildsPageIndexSelector);
    const buildSessionId = useSelector(buildSessionIdSelector);
    const { state: { search }, dispatch } = useContext(ProjectDrawerContext);
    const { triggerCreate } = useCreateButton();
    const { onDrawerClose } = useProjectDrawer();
    const { dispatchBuild } = useWebLoad();
    const storeDispatch = useDispatch<AppDispatch>();

    const triggerDelete = useCallback(async (buildId: number) => {
        if (currentBuildId === buildId && totalBuildCount > 1) {
            batch(() => {
                storeDispatch(setStatusState(WorkspaceStatus.Busy));
                storeDispatch(setBuildLoaded(false));
                onDrawerClose();
            });
        }

        if (buildSessionId) {
            edsSessionService.endSession(buildSessionId);
        }

        dispatch(setReloading(true));
        const recentBuild = await storeDispatch(deleteProject(buildId, pageIndex, search));
        if (recentBuild) {
            batch(() => {
                dispatch(setRecentProjectId(recentBuild.id ?? 0));
                dispatchBuild(recentBuild, !buildSessionId);
            });
        } else {
            triggerCreate();
        }
        dispatch(setReloading(false));

    }, [currentBuildId, totalBuildCount, buildSessionId, dispatch, storeDispatch, pageIndex, search, onDrawerClose, dispatchBuild, triggerCreate]);

    return { triggerDelete };
}

const useDeleteDialog = (projectId: number) => {
    const { t } = useTranslation();
    const [displayDialog, setDisplayDialog] = useState(false);
    const { name } = useBuildInfo(projectId);

    const onDialogOpen = useCallback(() => {
        setDisplayDialog(true);
    }, []);

    const onDialogClose = useCallback(() => {
        setDisplayDialog(false);
    }, []);

    const dialogMessage = t(LocalizationKeys.DeleteMessage, { content: name });
    const dialogTitle = t(LocalizationKeys.DeleteCable);

    return { displayDialog, dialogMessage, onDialogOpen, onDialogClose, dialogTitle };
}