import { IReportSection } from "../../types";
import { useState, useEffect } from "react";
import { useCheckBoxInput } from "../../../../../../../../../ui/checkbox/hooks";
import { useRadioInput, useRadioGroup } from "../../../../../../../../../ui/radio/hooks";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../../../../locales/types";

export const useDrawingSection = (name: string, label: string, options: IReportSection, disabled?: boolean) => {
    const { t } = useTranslation();
    const [radioOption, setRadioOption] = useState<string>(options.collapsed ? t(radioOptions[0]) : t(radioOptions[1]));

    useEffect(() => {
        setRadioOption(options.collapsed ? t(radioOptions[0]) : t(radioOptions[1]));
    }, [options.collapsed, t]);

    const checkBox = useCheckBoxInput(name, label, options.include, disabled);
    const radioGroup = useRadioGroup(name, radioOption, !checkBox.checked);
    const collapsedRadio = useRadioInput(name, t(radioOptions[0]), radioGroup.value, checkBox.disabledChildren);
    const expandedRadio = useRadioInput(name, t(radioOptions[1]), radioGroup.value, checkBox.disabledChildren);

    return { checkBox, radioGroup, collapsedRadio, expandedRadio };
}

export const radioOptions = [LocalizationKeys.CollapsedTether, LocalizationKeys.ExpandedTether];