import React from 'react';
import { InputField } from '../../../../../../../../../ui/input/types';
import { FilledTextField } from '@orbit/text-field';
import { getDateNowString } from '../../../../../../../../../ui/input/hooks';

export const FooterFields = (props: { fields: InputField[] }) => {
    const { fields } = props;
    const [date, revision, siteLocation, drawn, notes] = fields;
    const topFields = [siteLocation, drawn];
    return <div className="field-container">
    {topFields.map(f =>
            <FilledTextField palette='primary'
                className='field'
                label={f.label}
                helperText={""}
                maxLength={f.maxLength}    
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => f.onChange && f.onChange(e)}/>)}
            <FilledTextField palette='primary'
                multiline
                className='field'
                label={notes.label}
                helperText={""}
                maxLength={notes.maxLength}
                minRows={4}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => notes.onChange && notes.onChange(e)}/>
    <div className="horizontal-field-container">
        <div className="date-field">
            <FilledTextField palette='primary'
                className='field'
                label={date.label}
                helperText={""}
                type="date"
                defaultValue={getDateNowString()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => date.onChange && date.onChange(e)}/>
        </div>
        <div className="revision-container">
            <FilledTextField palette='primary'
                className='field'
                label={revision.label}
                helperText={""}
                maxLength={revision.maxLength}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => revision.onChange && revision.onChange(e)}/>
        </div>
    </div>
</div>
}