import { IApiResult } from './api-result';
import { BuildPropagation, IBuildData, IBuildInfo } from '../redux/build/types';
import { SecuredService } from './secured-service';
import { BuildDTO, SourceDTO, ConnectorGroupDTO, DestinationDTO, ConnectorTypeDTO, ConnectorDTO, PolarityResponse, BuildsByPolarityResponse, IConnectorsUpdateResponse } from './types';
import { ISourceData } from '../redux/build/source/types';
import { IConnectorData, IConnectorGroupData } from '../redux/build/connector/types';
import { IUnitOfMeasure, convertTo, Units } from '../components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure';
import { IDestinationData } from '../redux/build/destination/types';
import i18next from 'i18next'
import { LocalizationKeys } from '../../locales/types';
import { CustomFiberMapData, IFiberMapData } from '../redux/build/connector/polarity/fiber-map/types';
import { BuildPolarity, BuildPolarityPair, PolarityConfig, toPolarityFiberMapDTO, toAddPolarityRequestDTO, toUpdatePolarityRequestDTO } from '../redux/build/connector/polarity/types';
import { IConnectorAssignmentMap } from '../components/overlay/components/polarity/connector-assignment/redux/types';
import { PolarityAssignment } from '../components/overlay/components/polarity/connector-assignment/types';
import { IPropagationOptions, IPropagationResult } from '../components/overlay/components/polarity/propagation/redux/types';

export class BuildService extends SecuredService {
    private readonly baseUrl = '/api/build';

    public constructor() {
        super();
    }

    public async getBuild(id: number): Promise<IApiResult<IBuildData>> {
        const res = await this.get(`${this.baseUrl}/${id}`);
        if (res.data) {
            const buildData = ToBuildData(res.data);
            res.data = buildData;
        }

        return res;
    }

    public async getRecentBuild(id: number): Promise<IApiResult<IBuildData>> {
        const res = await this.get(`${this.baseUrl}/recent/${id}`);
        if (res.data) {
            const buildData = ToBuildData(res.data);
            res.data = buildData;
        }

        return res;
    }

    public async getAllBuildsForUser(): Promise<IApiResult<IBuildInfo>> {
        return await this.get(`${this.baseUrl}/all/user`);
    }

    public async getAllBuildsForGroup(): Promise<IApiResult<IBuildInfo[]>> {
        return await this.get(`${this.baseUrl}/all/group`);
    }

    public async addBuild(build: IBuildData): Promise<IApiResult<IBuildData | undefined>> {
        const buildDTO = ToBuildDTO(build);
        const res = await this.post(this.baseUrl, buildDTO);

        if (res.data) {
            res.data = ToBuildData(res.data);
        }
        else {
            res.data = undefined;
        }

        return res;
    }

    public async updateBuild(build: IBuildData): Promise<IApiResult<BuildDTO>> {
        const buildDto = ToBuildDTO(build);
        return this.put<BuildDTO>(this.baseUrl, buildDto);
    }

    public async updateBuildCatalogCode(buildId: number, catalogCode: string) {
        return this.put<void>(`${this.baseUrl}/catalogCode`, { buildId, catalogCode });
    }

    public async deleteBuild(id: number): Promise<IApiResult<void>> {
        return this.delete<void>(`${this.baseUrl}/${id}`);
    }

    public async updateBuildPolarityDefinitions(buildId: number, buildPolarity: BuildPolarity[]): Promise<IApiResult<BuildPolarity[]>> {
        return this.put(`${this.baseUrl}/polarity/${buildId}`, buildPolarity)
    }

    public async getPolarityConfigsByBuildId(buildId: number): Promise<IApiResult<PolarityConfig[]>> {
        return this.get(`${this.baseUrl}/polarity/${buildId}`)
    }

    public async getUserBuildPolarityDefinitions(): Promise<IApiResult<BuildPolarityPair[]>> {
        return this.get(`${this.baseUrl}/polarity/user`)
    }

    public async getUserBuildPolarityDefinitionsByGroupId(): Promise<IApiResult<BuildPolarityPair[]>> {
        return this.get(`${this.baseUrl}/polarity/group`);
    }

    public async addPolarityConnectorMaps(buildId: number, assignment: PolarityAssignment, maps: IConnectorAssignmentMap[], options: IPropagationOptions): Promise<IApiResult<PolarityResponse>> {
        return this.post(`${this.baseUrl}/polarity/maps/build/${buildId}`, toAddPolarityRequestDTO(buildId, assignment, maps, options));
    }

    public async deletePolarityConnectorMaps(buildId: number): Promise<IApiResult<boolean>> {
        return this.delete(`${this.baseUrl}/polarity/maps/build/${buildId}`)
    }

    public async deletePolarityConnectorMap(mapId: number): Promise<IApiResult<PolarityResponse>> {
        return this.delete(`${this.baseUrl}/polarity/map/${mapId}`)
    }

    public async addOrUpdatePolarityConnectorMap(buildId: number, connectorMap: IConnectorAssignmentMap, options: IPropagationOptions) : Promise<IApiResult<PolarityResponse>> {
        return this.put(`${this.baseUrl}/polarity/map`, toUpdatePolarityRequestDTO(buildId, connectorMap, options));
    }

    public async applyPropagation(propagation: BuildPropagation) : Promise<IApiResult<IPropagationResult>> {
        return this.post(`${this.baseUrl}/polarity/propagation`, propagation);
    }
    
    public async getPolarityFiberMaps(): Promise<IApiResult<IFiberMapData[]>> {
        return this.get(`${this.baseUrl}/polarity/fiberMaps`);
    }

    public async getUserPolarityFiberMaps(): Promise<IApiResult<CustomFiberMapData[]>> {
        return this.get(`${this.baseUrl}/polarity/fiberMaps/user`);
    }

    public async getUserPolarityFiberMapsByGroupId(): Promise<IApiResult<CustomFiberMapData[]>> {
        return this.get(`${this.baseUrl}/polarity/fiberMaps/group`);
    }
    
    public async updateConnectors(connectors: IConnectorData[], propagation?: BuildPropagation): Promise<IApiResult<IConnectorsUpdateResponse>> {
        return this.post(`${this.baseUrl}/connectors`, { connectors: connectors.map(c => ToConnectorDTO(c)), propagation });
    }

    public async updateUnit(buildId: number, unit: string): Promise<IApiResult<string>> {
        return this.post(`${this.baseUrl}/current/unit`, { buildId, unit });
    }

    public async getBuildsByPolarity(mapId: number): Promise<IApiResult<BuildsByPolarityResponse>> {
        return this.get(`${this.baseUrl}/polarity/builds/${mapId}`);
    }

    public async deleteUserPolarityFiberMap(buildId: number, mapId: number): Promise<IApiResult<PolarityResponse>> {
        return this.delete(`${this.baseUrl}/polarity/fiberMap/${buildId}/${mapId}`);
    }

    public async updateUserPolarityFiberMap(fiberMap: CustomFiberMapData): Promise<IApiResult<CustomFiberMapData>> {
        return this.put(`${this.baseUrl}/polarity/fiberMap/user`, toPolarityFiberMapDTO(fiberMap));
    }

    public async updateUserPolarityFiberMapName(mapId: number, newName: string): Promise<IApiResult<CustomFiberMapData>> {
        return this.put(`${this.baseUrl}/polarity/fiberMap/name/${mapId}`, { mapId, newName });
    }
}

export function ToUnitofMeasure(valueIn: number | undefined): IUnitOfMeasure | undefined {
    if (valueIn === undefined) {
        return undefined;
    }

    return {
        value: valueIn,
        unit: Units.UoMInches,
    };
}

function ToConnectorGroupData(dto: ConnectorGroupDTO): IConnectorGroupData {
    const { staggerIn, connectors, lengthBIn } = dto;
    return {
        ...dto,
        lengthB: ToUnitofMeasure(lengthBIn),
        stagger: ToUnitofMeasure(staggerIn),
        connectors: connectors!.slice().sort((a, b) => a.position! > b.position! ? 1 : -1)!.map(c => ToConnectorData(c))
    }
}

function ToConnectorData(dto: ConnectorDTO): IConnectorData {
    const { staggerIn } = dto;
    return {
        ...dto,
        stagger: ToUnitofMeasure(staggerIn),
    }
}

export function ToSourceData(dto: SourceDTO): ISourceData {
    const { lengthAIn, lengthBIn, groups } = dto;
    return {
        ...dto,
        lengthA: ToUnitofMeasure(lengthAIn),
        lengthB: ToUnitofMeasure(lengthBIn),
        groups: groups!.slice().sort((a, b) => a.position! > b.position! ? 1 : -1)!.map(c => ToConnectorGroupData(c)),
    }
}

export function ToDestinationData(dto: DestinationDTO): IDestinationData {
    const { position, lengthAIn, lengthBIn, groups } = dto;
    return {
        ...dto,
        position: position!,
        lengthA: ToUnitofMeasure(lengthAIn!),
        lengthB: ToUnitofMeasure(lengthBIn!),
        groups: groups!.slice().sort((a, b) => a.position! > b.position! ? 1 : -1)!.map(c => ToConnectorGroupData(c))
    }
}

export function ToBuildData(dto: BuildDTO): IBuildData {
    const { lastModified, source, destinations } = dto;

    let formattedLastModified: string;
    if (lastModified && lastModified === i18next.t(LocalizationKeys.DefaultDate)) {
        formattedLastModified = i18next.t(LocalizationKeys.Unknown);
    } else {
        let lastModifiedUTCString = lastModified!;
        if (lastModifiedUTCString.charAt(lastModifiedUTCString.length - 1) !== i18next.t(LocalizationKeys.Zulu)) {
            lastModifiedUTCString = lastModified!.concat(i18next.t(LocalizationKeys.Zulu)); // Backend date is not specified as UTC, we need to interpret it ourselves
        }

        // Provide the local date time in 24 hours format
        formattedLastModified = new Date(lastModifiedUTCString).toString();
    }

    const data: IBuildData = {
        ...dto,
        lastModified: formattedLastModified,
        source: ToSourceData(source!),
        destinations: [...destinations!].sort((a, b) => a.position! > b.position! ? 1 : -1)!.map(d => ToDestinationData(d)),
    };

    return data;
}

export function ToBuildDTO(data: IBuildData): BuildDTO {
    return {
        ...data,
        customTag: "",
        partNumber: "",
        lastModified: getCurrentISODate(),
        source: ToSourceDTO(data.source),
        destinations: [...data.destinations].sort((a, b) => a.position! > b.position! ? 1 : -1)!.map(d => ToDestinationDTO(d))
    };
}

export function getCurrentISODate() {
    let date = new Date();
    return date.toISOString();
}

export function ToConnectorGroupDTO(data: IConnectorGroupData): ConnectorGroupDTO {
    const {
        lengthB,
        stagger,
        connectors,
        ...props
    } = data;

    return {
        ...props,
        lengthBIn: convertTo(lengthB).value,
        staggerIn: convertTo(stagger).value,
        connectors: connectors.map(c => ToConnectorDTO(c))
    };
}

export function ToConnectorDTO(data: IConnectorData): ConnectorDTO {
    const {
        stagger,
        ...props
    } = data;

    return {
        ...props,
        staggerIn: convertTo(stagger).value,
    };
}

export function ToConnectorTypeDTO(data: IConnectorData): ConnectorTypeDTO {
    return {
        type: data.type
    }
}

export function ToDestinationDTO(data: IDestinationData): DestinationDTO {
    const {
        lengthA,
        lengthB,
        groups,
        ...props
    } = data;

    return {
        ...props,
        lengthAIn: convertTo(lengthA).value,
        lengthBIn: convertTo(lengthB).value,
        groups: groups.map(g => ToConnectorGroupDTO(g)),
    };
}

export function ToSourceDTO(data: ISourceData): SourceDTO {
    const {
        lengthA,
        lengthB,
        groups,
        ...props
    } = data;

    return {
        ...props,
        lengthAIn: convertTo(lengthA).value,
        lengthBIn: convertTo(lengthB).value,
        groups: groups.map(g => ToConnectorGroupDTO(g)),
    }
}

export function ExtractGroupProjectIds(builds: IBuildInfo[], groupId: number): number[] {
    if (groupId === -1) return [];

    return builds.map((b) => {
        if (b.groupId && b.groupId === groupId) {
            return b.buildId ? b.buildId : -1;
        }
        return -1;
    }).filter(b => b !== -1);
}

export function ExtractProjectIds(builds: IBuildInfo[]): number[] {
    return builds.map((b) => {
        return b.buildId ? b.buildId : -1;
    }).filter(b => b !== -1);
}