import { Graphics, RoundedRectangle } from "pixi.js";
import { useCallback } from "react";
import { InteractiveRectangle, IRectangleDrawingProps, IRectangleGraphics } from "./types";

export const useInteractiveRectangle = ({ bounds, layout: { color, borderColor, alpha } , interactive, onMouseClick, onMouseOut, onMouseOver }: IRectangleDrawingProps) => {
    const rectangleGraphics: IRectangleGraphics = {
        name: InteractiveRectangle, 
        buttonMode: interactive, 
        preventRedraw: false, 
        bounds,
        alpha, 
        interactive, 
        interactiveChildren: interactive,
        pointertap: onMouseClick,
        pointerout: onMouseOut,
        pointerover: onMouseOver
    };

    const drawRectangle = useCallback((grfx: Graphics) => {
        grfx.clear();

        if (color) {
            grfx.beginFill(color, 0.33);
            grfx.lineStyle(1, borderColor);
            grfx.drawRoundedRect(bounds.x, bounds.y, bounds.width, bounds.height, 8);
            grfx.endFill();
        } else {
            grfx.lineStyle(1, borderColor);
            grfx.drawRoundedRect(bounds.x, bounds.y, bounds.width, bounds.height, 8);
        }

        grfx.alpha = alpha;
        grfx.hitArea = new RoundedRectangle(bounds.x, bounds.y, bounds.width, bounds.height, 8);
    }, [color, alpha, borderColor, bounds]);

    return { rectangleGraphics, bounds, borderColor, alpha, color, drawRectangle };
};