import { Identifiable } from "../../../../services/types"

export interface IOrgLabelBundle {
    schemeBundle: LabelBundle;
}

export interface ILabelBundle {
    labelSchemes: LabelScheme[];
}

export interface ILabelScheme {
    bundleId: number;
    side: string;
    name: string;
    sequences: LabelSequence[];
}

export interface ILabelSequence {
    schemeId: number;
    prefix: string;
    suffix: string;
    name: string;
    symbol: string | number;
    increment: number;
    position: number;
    isEditable?: boolean;
}

export type LabelSequence = ILabelSequence & Identifiable;
export type LabelScheme = ILabelScheme & Identifiable;
export type LabelBundle = ILabelBundle & Identifiable;
export type OrgLabelBundle = IOrgLabelBundle & Identifiable;

export interface IAppLabelScheme {
    orgBundles: OrgLabelBundle[];
    appBundle: LabelBundle;
    userBundle: LabelBundle;
};

export const initialScheme: LabelScheme = {
    id: -1,
    bundleId: -1,
    side: "",
    name: "",
    sequences: []
};

export const initialBundle: LabelBundle = {
    id: -1,
    labelSchemes: []
};

export const initialAppLabelScheme: IAppLabelScheme = {
    orgBundles: [],
    appBundle: initialBundle,
    userBundle: initialBundle  
};