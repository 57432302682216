import { Graphics } from '@inlet/react-pixi';
import * as Pixi from 'pixi.js';
import React from 'react';
import { Rectangle } from '../../../interaction/types';
import { useInteractiveRectangle } from './hooks';
import { InteractiveRectangle as name, IRectangleDrawingProps, IRectangleGraphics } from './types';

export const InteractiveRectangle: React.FC<IRectangleDrawingProps> = (props: IRectangleDrawingProps) => {
    const { rectangleGraphics, drawRectangle } = useInteractiveRectangle(props);
    return <Graphics draw={drawRectangle} {...rectangleGraphics} />;
}

export function getRectangleGraphics(props: IRectangleDrawingProps): IRectangleGraphics {
    const { bounds, layout, interactive, onMouseClick, onMouseOut, onMouseOver } = props;
    const { alpha } = layout;

    const rectangleGraphics: IRectangleGraphics = {
        name,
        buttonMode: true,
        preventRedraw: false,
        bounds,
        alpha,
        interactive,
        interactiveChildren: interactive,
        pointertap: onMouseClick,
    };

    if (onMouseOut && onMouseOver) {
        return { ...rectangleGraphics, pointerover: onMouseOver, pointerout: onMouseOut }
    }

    return rectangleGraphics;
}

export function drawRectangle(grfx: Pixi.Graphics, bounds: Rectangle, borderColor: number, alpha: number, color: number, fillAlpha?: number) {
    grfx.clear();

    grfx.beginFill(color, 0.33);
    grfx.lineStyle(1, borderColor);
    grfx.drawRoundedRect(bounds.x, bounds.y, bounds.width, bounds.height, 8);

    grfx.alpha = alpha;
    grfx.endFill();
}

export default InteractiveRectangle;