import { PixiComponent } from "@inlet/react-pixi";
import { AnyAction } from '@reduxjs/toolkit';
import { Viewport as PixiViewport } from "pixi-viewport";
import React, { createContext, ReactNode } from 'react';
import { ViewportBackground } from './background/ViewportBackground';
import { useViewport } from './hooks';

export interface IViewportState {
    scale: number;
    increment?: { scale: number };
    isDragging: boolean;
}
export const initialViewportState: IViewportState = {
    scale: 1,
    isDragging: false
}

interface IViewportInstance {
    viewport?: PixiViewport
}

export interface IViewportComponentProps {
    children?: ReactNode
}

export type ViewportProps = IViewportInstance & Partial<IViewportState> & React.PropsWithChildren<{}>;

export interface IViewportContext {
    state: IViewportState,
    dispatch: React.Dispatch<AnyAction>
}

const initialViewportContext: IViewportContext = {
    state: initialViewportState,
    dispatch: (_: AnyAction) => {}
}

export const ViewportContext = createContext<IViewportContext>(initialViewportContext)

const ReactViewport = PixiComponent<ViewportProps, PixiViewport>('Viewport', {
    create: (props) => {
        return props.viewport || new PixiViewport();
    }
})

export const Viewport: React.FC<IViewportComponentProps> = ({children}) => {
    const { viewportRef } = useViewport();
    return (        
            <ReactViewport viewport={viewportRef.current}>
                <ViewportBackground />
                {children}
            </ReactViewport>
    )

}