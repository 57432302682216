import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next"
import { batch, useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../locales/types";
import { setViewportContext } from "../../../../../pixi/components/viewport/redux/reducers";
import { ViewportStatus } from "../../../../../pixi/components/viewport/redux/types";
import { IDialogHeaderProps } from "../../../../../ui/dialog/header/types"
import { setShowAboutApplication, setShowThirdPartyCopyright } from "../../redux/reducers";
import { showAboutApplicationSelector } from "../../redux/selectors";
import { IAboutApplicationDialogProps } from "./types";

import config from '../../../../../config.json'
const versionNumber = config.buildDate

export const useAboutThisApplication = () => {
    const showAboutApplication = useSelector(showAboutApplicationSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onThirdPartyButtonClick = useCallback(() => {
        batch(() => {
            dispatch(setShowAboutApplication(false));
            dispatch(setShowThirdPartyCopyright(true));
        })
    }, [dispatch])

    const closeDialog = useCallback(() => {
        batch(() => {
            if (showAboutApplication) {
                dispatch(setShowAboutApplication(false));
            }
            dispatch(setViewportContext(ViewportStatus.Active))
        })
    }, [showAboutApplication, dispatch])

    const { dialogBodyProps, dialogHeaderProps } = useMemo(() => {
        const lastBuild = `Last Build: ${versionNumber ?? t(LocalizationKeys.Unknown)}`;
        const dialogBodyProps: IAboutApplicationDialogProps = {
            title: t(LocalizationKeys.EDSTitle),
            lastBuild,
            thirdPartyButtonTitle: t(LocalizationKeys.ThirdPartyOSSCopyright),
            corningCopyright: t(LocalizationKeys.CorningCopyright),
            onThirdPartyButtonClick
        }

        const dialogHeaderProps: IDialogHeaderProps = {
            title: t(LocalizationKeys.AboutThisApplication),
            closable: true,
            onClose: closeDialog
        }
        return { dialogBodyProps, dialogHeaderProps }
    }, [t, onThirdPartyButtonClick, closeDialog])

    return { showAboutApplication, dialogBodyProps, dialogHeaderProps, closeDialog }
}