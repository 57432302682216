import React from "react";
import { WarningSnackbar } from "./components/warning-snackbar/WarningSnackbar";
import { WarningsCard } from "./components/warnings-card/WarningsCard";

export const Warnings = () => {
    return (
        <>
            <WarningsCard />
            <WarningSnackbar />
        </>
    );
};
