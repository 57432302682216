import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types";
import { setIsDraggingReducer, setViewportContextReducer, setScaleReducer, setPositionReducer, setResizeReducer, zoomToMouseReducer, zoomInAction, zoomOutAction } from "./actions";

const slice = createSlice({
    name: 'viewport', 
    initialState,
    reducers: {
        setIsDragging: setIsDraggingReducer,
        setViewportContext: setViewportContextReducer,
        setScale: setScaleReducer,
        setPosition: setPositionReducer,
        setResize: setResizeReducer,
        zoomToMouse: zoomToMouseReducer,
        zoomIn: zoomInAction,
        zoomOut: zoomOutAction,
        
    }
})

export const ViewportReducer = slice.reducer;
export const { setIsDragging, setViewportContext, setScale, setPosition, setResize, zoomToMouse, zoomIn, zoomOut } = slice.actions;