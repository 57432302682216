import React from 'react';
import { usePolarityAssignmentRow } from "./hooks";
import { IPolarityAssignmentRowProps } from "./types";
import { DataTableCell, DataTableRow } from '@rmwc/data-table';
import { ToolItem } from '../../../../../ToolItem';
import { PadlockIcon } from '../../../../../../../../ui/icons/PadlockIcon';

export const PolarityAssignmentRow: React.FC<IPolarityAssignmentRowProps> = (props) => {
    const { data, tableClassName, editButtonClassName, deleteButtonClassName, onRowClick, onEdit, onDelete, onButtonCellClick } = usePolarityAssignmentRow(props);

    if (!data) return null;

    return (
        <DataTableRow onClick={onRowClick} className={tableClassName} key={data.key} >
            <DataTableCell>
                <div className="polarity-row">
                    <div className="polarity-icon">
                        { data.applied ? 
                            <i className="material-icons applied">
                                {"check_circle"}
                            </i> : 
                            <PadlockIcon locked={!data.editable} />
                        }
                    </div>
                    <div className="polarity-description">
                        <span className="polarity-name">{data.description}</span>
                        <span className="polarity-revision">{data.creationInfo}</span>
                    </div>
                </div>
            </DataTableCell>
            <DataTableCell className={editButtonClassName} onClick={data.editable ? onButtonCellClick : undefined}>
                <ToolItem icon="edit" onClick={onEdit}/>
            </DataTableCell>
            <DataTableCell className={deleteButtonClassName} onClick={data.editable ? onButtonCellClick : undefined}>
                <ToolItem icon="delete" onClick={onDelete} disabled={!data.canDelete}/>
            </DataTableCell>
        </DataTableRow>
    )
}