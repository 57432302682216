import { DrawerHeaderProps } from './types';
import { ToolItem } from '../../../../workspace/components/overlay/ToolItem';

import './DrawerHeader.scss';

export const DrawerHeader = (props: DrawerHeaderProps) => {
    const { onClose, logo: logoSrc } = props;
    return (
        <div className="drawer-header">
            <div className="corning-logo">
                {logoSrc ? <img alt={"logo"} src={logoSrc} /> : null}
            </div>
            <ToolItem icon={"close"} onClick={onClose} />
        </div>
    );
}