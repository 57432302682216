import { createSlice } from "@reduxjs/toolkit"
import { setSelectedWizardPresetAction, setUserWizardPresetAction, removeWizardPresetAction, addWizardPresetAction } from "./actions";
import { initialUserWizardPreset } from "./types";

const userWizardPresetSlice = createSlice({
    name: 'userWizardPreset',
    initialState: initialUserWizardPreset,
    reducers: {
        setUserWizardPreset: setUserWizardPresetAction,
        setSelectedWizardPreset: setSelectedWizardPresetAction,
        removePreset: removeWizardPresetAction,
        addPreset: addWizardPresetAction,
    }
})

export const UserWizardPresetReducer = userWizardPresetSlice.reducer;
export const { setUserWizardPreset, setSelectedWizardPreset, removePreset, addPreset } = userWizardPresetSlice.actions;