import React from "react";
import { usePropagation } from "./hooks";
import { Checkbox } from "@orbit/checkbox";
import { Dialog } from "@orbit/dialog";

import './Propagation.scss';

export const Propagation: React.FC = () => {
    const { dialogProps, checkboxes } = usePropagation();
    return (
        <Dialog {...dialogProps}>
            <div className="propagation-options">
                <Checkbox {...checkboxes.trigger} />
                <Checkbox {...checkboxes.label} />
            </div>
        </Dialog>
    )
}