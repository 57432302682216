import { PolarityMap } from "../../../../../redux/build/connector/polarity/types";
import { IFiberMap } from "../fiber-mapping/redux/types";
import { Dispatch, createContext } from "react";
import { AnyAction } from "redux";
import { IConnectorAssignmentMap } from "../connector-assignment/redux/types";

export interface IConnectorAssignment {
    source: ISourceAssignment,
    destinations: IDestinationAssignment[];
}
export interface IConnectorAssignmentIndex {
    index: number,
    fiberCount: number,
    connectorType: string,
}
export interface ISourceAssignment {
    currentIndex: number;
    connectors: IConnectorAssignmentIndex[];
}

export interface IDestinationAssignment {
    tapIndex: number, 
    connectors: IConnectorAssignmentIndex[];
}

export const initialSourceAssignment: ISourceAssignment = {
    currentIndex: -1,
    connectors: []
}

export const initialConnectorAssignment: IConnectorAssignment = {
    source: initialSourceAssignment,
    destinations: []
}

export type ConnectorAssignmentRelationship = "OneToOne" | "OneToMany" | "ManyToOne" | "ManyToMany";

export interface IPolarityState {
    polarity?: PolarityMap;
    connectorAssignment: IConnectorAssignment;
    fiberMapping: IFiberMap[];
    assignmentMapping: IConnectorAssignmentMap[];
    editAssignmentMap?: IConnectorAssignmentMap;
}

export const initialPolarityState: IPolarityState = {
    connectorAssignment: initialConnectorAssignment,
    fiberMapping: [],
    assignmentMapping: []
}

export interface IPolarityContext {
    state: IPolarityState;
    dispatch: Dispatch<AnyAction>;
}

export const initialPolarityContext: IPolarityContext = {
    state: initialPolarityState,
    dispatch: () => {}
}

export const PolarityContext = createContext(initialPolarityContext);