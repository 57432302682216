import i18n from 'i18next'
import ExitFullscreenIcon from '../../../../resources/icons/icon_exit_fullscreen.svg';
import EnterFullscreenIcon from '../../../../resources/icons/icon_enter_fullscreen.svg';
import { useCallback, useEffect, useState } from 'react';
import { LocalizationKeys } from '../../../../../../../../../locales/types';
import { useSelector } from 'react-redux';
import { hybrisCustomerSelector } from '../../../../../../../authentication/redux/selectors';

export const useFullscreenButton = () => {
    const hybrisCustomer = useSelector(hybrisCustomerSelector);
    const showButton = !!hybrisCustomer;

    const [isFullscreen, setIsFullscreen] = useState(true);
    const [fullscreenInfo, setFullscreenInfo] = useState(getFullscreenInfo(isFullscreen));
    const { icon, label } = fullscreenInfo;

    useEffect(() => {
        setIsFullscreen(!!(window as any).designerFullScreen);
    }, []);

    useEffect(() => {
        setFullscreenInfo(getFullscreenInfo(isFullscreen));
    }, [isFullscreen]);

    const toggleFullscreen = useCallback(() => {
        window.parent.postMessage("ctcm.designer.toggleFullscreen", window.location.origin);
        setIsFullscreen(!isFullscreen);
        setFullscreenInfo(getFullscreenInfo(!isFullscreen));
    }, [isFullscreen]);

    return { showButton, icon, label, toggleFullscreen };
};

const getFullscreenInfo = (isFullscreen: boolean): { icon: string, label: string } => {
    let icon = null;
    let label = "";
    if (isFullscreen) {
        icon = ExitFullscreenIcon;
        label = i18n.t(LocalizationKeys.ExitFullscreen);
    }
    else {
        icon = EnterFullscreenIcon;
        label = i18n.t(LocalizationKeys.EnterFullscreen);
    }

    return { icon, label };
};