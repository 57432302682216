import { useContext } from "react"
import { useSelector } from "react-redux";
import { Unit } from "../../../../workspace/components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure";
import { IDestinationData } from "../../../../workspace/redux/build/destination/types";
import { ISourceData } from "../../../../workspace/redux/build/source/types";
import { BuildContext } from "../../../../workspace/redux/build/types";
import { reportDocumentSelector } from "../../../../workspace/redux/document/selectors";
import { fiberTypeTrunkColorSelector } from "../../../../workspace/redux/ssc/selectors";
import { getTrunkContext } from "../../../../workspace/selectors/build.selectors";

export const useDropGraphic = () => {
    const { drops } = useSelector(reportDocumentSelector);
    const currentBuild = useContext(BuildContext);
    const { id: trunkColor } = useSelector(fiberTypeTrunkColorSelector);

    const dropGraphic = drops ? drops.map(d => {
        const source: ISourceData = d.prevTrunk.position ? currentBuild.source : { ...currentBuild.source, ...d.prevTrunk, isCollapsed: true, groups: d.prevTrunk.groups! };
        let destinations: IDestinationData[] = [d.drop];
        if (d.prevTrunk.position !== 0) {
            const previousDestination: IDestinationData = { ...d.prevTrunk, position: d.prevTrunk.position!, groups: d.prevTrunk.groups! }
            const nextDestination = currentBuild.destinations.find(b => b.position === d.drop.position + 1);
            destinations = nextDestination ? [...destinations, previousDestination, nextDestination] : [...destinations, previousDestination];
        }

        const build = { ...currentBuild, source, destinations }

        const dropContext = getTrunkContext(d.drop, currentBuild.unit as Unit, true, trunkColor);
        const prevTrunkContext = getTrunkContext(d.prevTrunk, currentBuild.unit as Unit, true, trunkColor);

        return { drop: d.drop, dropContext, prevTrunkContext, prevTrunk: d.prevTrunk, build, key: 'dropKey' + d.drop.position }
    }) : []

    return dropGraphic;
}