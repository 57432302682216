import { createSlice } from "@reduxjs/toolkit";
import { initialState } from './types';
import { setWizardDisplayAction, setCurrentStepAction, enableNextStepAction,
         setApplyEditChangesAction, restartWizardAction, saveBuildDataAction, setDropCheckboxDisabledAction } from "./actions";

const wizardSlice = createSlice({
    name: "wizard",
    initialState,
    reducers: {
        setWizardDisplay: setWizardDisplayAction,
        setCurrentStep: setCurrentStepAction,
        enableNextStep: enableNextStepAction,
        setApplyEditChanges: setApplyEditChangesAction,
        restartWizard: restartWizardAction,
        saveBuildData: saveBuildDataAction,
        setDropCheckboxDisabled: setDropCheckboxDisabledAction
    }
});

export const WizardReducer = wizardSlice.reducer;
export const { setWizardDisplay, setCurrentStep, enableNextStep, setApplyEditChanges, restartWizard, saveBuildData, setDropCheckboxDisabled } = wizardSlice.actions;