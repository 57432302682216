export const PolarityMMC: React.FC = () => {
    return (
        <svg id="MMC16" xmlns="http://www.w3.org/2000/svg" width={1800} height={2560} viewBox="0 0 180 256" fill="none">
            <rect x="56.1824" y="1" width="31.8343" height="254" fill="white" stroke="black" strokeWidth="2"/>
            <rect x="1" y="-1" width="6" height="214.211" transform="matrix(-1 -3.22089e-10 1.82086e-10 1 95 21.8948)" fill="white" stroke="black" strokeWidth="2"/>
            <rect x="1" y="-1" width="31.8343" height="187.684" transform="matrix(-1 -3.22089e-10 1.82086e-10 1 57.1934 35.158)" fill="white" stroke="black" strokeWidth="2"/>
            <rect x="1" y="-1" width="31.8343" height="55.0526" transform="matrix(-1 -3.22089e-10 1.82086e-10 1 57.1934 66)" fill="#BDBDBD" stroke="black" strokeWidth="2"/>
            <rect x="93.9724" y="1" width="82" height="254" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_1" x="171.055" y="12" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_2" x="171.055" y="27" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_3" x="171.055" y="42" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_4" x="171.055" y="57" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_5" x="171.055" y="72" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_6" x="171.055" y="87" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_7" x="171.055" y="102" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_8" x="171.055" y="117" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_9" x="171.055" y="177" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_10" x="171.055" y="132" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_11" x="171.055" y="192" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_12" x="171.055" y="147" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_13" x="171.055" y="207" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_14" x="171.055" y="162" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_15" x="171.055" y="222" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
            <rect id="pin_16" x="171.055" y="237" width="7.94475" height="8" fill="white" stroke="black" strokeWidth="2"/>
        </svg>
    )
};