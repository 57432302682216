import { IPoint } from "../../../../../../../pixi/types";

export interface ZoomArgs {
    newScale: IPoint,
    cursor: IPoint
}

export interface IZoomState {
    zoom: IPoint;
}

export const initialState: IZoomState = {
    zoom: { 
        x: 0.9643,
        y: 0.9643
    }
}