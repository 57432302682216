import { createSelector } from "reselect";
import { viewportContextSelector } from "../../../../../../../../pixi/components/viewport/redux/selectors";
import { wizardDisplaySelector } from "../../../../wizard/redux/selectors";
import { workspaceSelector } from "../../../../../../../selectors/root.selectors";
import { WorkspaceStatus } from "./types";
import { ViewportStatus } from "../../../../../../../../pixi/components/viewport/redux/types";

export const statusSelector = createSelector(
    workspaceSelector,
    workspace => workspace.status
);

export const currentStatusSelector = createSelector(
    statusSelector,
    status => status.currentStatus
);

export const texturesLoadedSelector = createSelector(
    statusSelector,
    status => status.textureStatus === WorkspaceStatus.Ready
);

export const disabledUISelector = createSelector(
    currentStatusSelector,
    viewportContextSelector,
    wizardDisplaySelector,
    (currentStatus, { context }, wizardDisplay) => currentStatus === WorkspaceStatus.InitialLoad || currentStatus === WorkspaceStatus.Busy || currentStatus === WorkspaceStatus.Loading || context === ViewportStatus.Editing || wizardDisplay
);