import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react"
import { setIsCollapsed } from "../../../../../../../../../redux/build/reducers";
import { toolbarSelectionSelector } from "../../../redux/selectors";

export const useExpandButton = () => {
    const { selected } = useSelector(toolbarSelectionSelector);
    const dispatch = useDispatch();

    const triggerExpand = useCallback(() => {
        dispatch(setIsCollapsed!(selected));
    }, [dispatch, selected]);

    return { triggerExpand };
}