import { useCallback, useState } from "react";
import { useDispatch } from 'react-redux';
import { PageResizeArgs } from './types';
import { setResize } from '../../../../../../../pixi/components/viewport/redux/reducers';

export function usePageResize() {
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
    const [buttonText, setButtonText] = useState("Fit to source");
    const [pageResizeValue, setPageResizeValue] = useState(2);
    const dispatch = useDispatch();

    const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget);
    };

    const onMenuClose = (event: any, value: number) => {
        setAnchorElement(null);
        if (value > -1) {
            setButtonText(event!.currentTarget.id);
            setPageResizeValue(value);
            dispatch(setResize({ update: true, value: value }));
        }
    }

    const onResize = useCallback(() => {
        const pageResizeArgs: PageResizeArgs = {
            update: true,
            value: pageResizeValue
        }
        dispatch(setResize(pageResizeArgs));
    }, [pageResizeValue, dispatch]);

    return { anchorElement, buttonText, onButtonClick, onMenuClose, onResize };
}