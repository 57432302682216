import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux"
import { AnyAction } from "redux";
import { LocalizationKeys } from "../../../../../../locales/types";
import { addOrUpdateHighlights, setHighlights } from "../../../../../../pixi/components/decorators/bounds/components/selection/redux/reducer";
import { IHighlight, IHighlightColor } from "../../../../../../pixi/components/decorators/bounds/components/selection/redux/types";
import { ICollapsibleDialogProps } from "../../../../../../ui/dialog/collapsible/types";
import { IGenericDialogProps } from "../../../../../../ui/dialog/generic-dialog/types";
import { IDialogHeaderProps } from "../../../../../../ui/dialog/header/types";
import { NavigationBarReducer, setCurrentIndex } from "../../../../../../ui/navigation/store/reducer";
import { INavigationBarState, initialNavigationBarState } from "../../../../../../ui/navigation/store/types";
import { INavigationBarProps } from "../../../../../../ui/navigation/types";
import { setFilteredPolarityList } from "../../../../../redux/build/connector/polarity/reducer";
import { filteredPolarityMapsSelector, polaritySelector, userFiberMapsNameSelector } from "../../../../../redux/build/connector/polarity/selectors";
import { CustomMap, CUSTOM_MAP_KEY, PolarityMap } from '../../../../../redux/build/connector/polarity/types';
import { IDestinationData } from "../../../../../redux/build/destination/types";
import { buildSessionIdSelector, currentSourceSelector, currentTAPsSelector } from '../../../../../selectors/build.selectors';
import { BuildService } from "../../../../../services/build-service";
import { setEnableFiberMapsOptions, setEnablePolarityDiagramOption, setShowConnectorAssignment, setShowFiberMapping, setShowPolarity } from "../../../redux/reducers";
import { enableFiberMapsOptionSelector, enablePolarityDiagramOptionSelector, showConnectorAssignmentSelector, showPolarityConfigSelector } from "../../../redux/selectors"
import { IToolItemProps } from "../../../ToolItem";
import { getConnectorType } from "../../wizard/redux/types";
import { addAssignedMaps, prepareFiberMapping, registerMaps, resetAssignmentMap } from "../redux/reducer";
import { ConnectorAssignmentReducer, resetAll, resetSelection, setAssignmentMapping, setPolarityType, handleSelectAll, setConnectorRows, handleSelection, handleHoverConnectorSet, disabledConnectorRows, handleAssignmentMethod, setPolarityAssignment } from "./redux/reducer";
import { IPolarityContext, PolarityContext } from "../redux/types";
import { IConnectorAssignmentContext, IConnectorAssignmentMap, IConnectorAssignmentState, IConnectorMap, initialConnectorAssignmentState } from "./redux/types";
import { PolarityAssignments, ITAPNavigationBarProps, ITAPNavigationMap } from "./types";
import { AssignedIndexCodes, CustomFiberMapData, IFiberMapIndex, Rx, Tx } from "../../../../../redux/build/connector/polarity/fiber-map/types";
import { generateFiberMappingData, updateConnectorAssignmentMap } from "../redux/actions";
import { checkForAssignedFibers } from "../../../redux/actions";
import { hoveredConnectorSelector, lastSelectedConnectorsSelector } from "../../../../../../pixi/components/decorators/bounds/components/selection/redux/selectors";
import { sscSessionBusySelector, sscBuildPolaritySelector, sscDefaultBuildPolaritiesSelector } from "../../../../../redux/ssc/selectors";
import { toApplyPolarityRequest } from "../../../../../services/eds-session/types";
import { currentBuildSelector } from "../../../../../selectors/root.selectors";
import { EDSSessionService } from "../../../../../services/eds-session/eds-session-service";
import { setConfigStatus, setSessionBusy, setSessionWarnings } from "../../../../../redux/ssc/reducer";
import { currentStatusSelector } from "../../header/components/status/redux/selectors";
import { WorkspaceStatus } from "../../header/components/status/redux/types";
import { authenticationSelector, isAnonymousUserSelector } from "../../../../authentication/redux/selectors";
import { useCheckBoxInput } from "../../../../../../ui/checkbox/hooks";
import { setStatusState } from "../../header/components/status/redux/reducer";
import { resetPolarityAssignment, resetPolarityDescription, setPolarityDescription, updateSpecificConnectors } from "../../../../../redux/build/reducers";
import { SelectChangeEvent } from "@mui/material";
import { ButtonProps } from "@orbit/button";
import { AppDispatch } from "../../../../../redux/reducers";
import { showPropagationDialog } from "../propagation/redux/reducer";
import { isPropagationActiveSelector, propagationOptionsSelector } from "../propagation/redux/selectors";
import { setPropagationNotification } from "../../notification/store/reducer";

export const useConnectorAssignmentDialog = () => {
    const storeDispatch = useDispatch<AppDispatch>();
    const [navigationBarState, navigationBarDispatch] = useReducer(NavigationBarReducer, initialNavigationBarState);
    const [connectorAssignmentState, connectorAssignmentDispatch] = useReducer(ConnectorAssignmentReducer, initialConnectorAssignmentState);
    const assignmentContext: IConnectorAssignmentContext = { state: connectorAssignmentState, dispatch: connectorAssignmentDispatch };
    const isPropagationActive = useSelector(isPropagationActiveSelector);
    const { t } = useTranslation();
    const display = useSelector(showConnectorAssignmentSelector);
    const displayPolarityDialog = useSelector(showPolarityConfigSelector);
    const currentBuild = useSelector(currentBuildSelector)
    const currentStatus = useSelector(currentStatusSelector);
    const sscSessionBusy = useSelector(sscSessionBusySelector);
    const polarityContext = useContext(PolarityContext);
    const { userId, groupId } = useSelector(authenticationSelector);
    const { fiberMaps } = useSelector(polaritySelector);
    const enableFiberMapsOption = useSelector(enableFiberMapsOptionSelector);
    const enablePolarityDiagramOption = useSelector(enablePolarityDiagramOptionSelector);
    const userFiberMaps = useSelector(userFiberMapsNameSelector)
    const propagationOptions = useSelector(propagationOptionsSelector);
    const sessionId = useSelector(buildSessionIdSelector);
    const isAnonymous = useSelector(isAnonymousUserSelector);
    const hasCatalogCode = currentBuild && currentBuild.catalogCode.length > 0;
    const locked = currentStatus === WorkspaceStatus.Locked || currentStatus === WorkspaceStatus.Saving || currentStatus === WorkspaceStatus.Loading || currentStatus === WorkspaceStatus.InitialLoad;
    const editable = !hasCatalogCode && !locked && !sscSessionBusy;
    const hasNoAssigments = connectorAssignmentState.assignmentMapping.length === 0
    const resetAllDisabled = hasNoAssigments || !editable;

    const { leftTAP, rightTAP, previousDisabled, nextDisabled, navigationBarProps } = useTAPNavigationBar(navigationBarState, navigationBarDispatch);
    const { sourceDataRows, leftContainerTitle, leftTAPDataRows, rightContainerTitle, rightTAPDataRows } = useAssignmentData(leftTAP, rightTAP, assignmentContext, editable);
    const { filteredPolarityMaps, polarityType, onPolarityChange } = useFilteredPolarityMaps(connectorAssignmentState, connectorAssignmentDispatch);
    const { filteredPolarityAssignments, polarityAssignment, onAssignmentMethodChange } = useFilteredPolarityAssignments(assignmentContext);
    const { onResetAll, resetAllDialogProps } = useResetAllProps(currentBuild?.id ?? 0, sessionId, polarityContext, connectorAssignmentDispatch);
    const { onCheckBoxChange: selectAllCheckBoxChanged, ...selectConnectorsCheckbox } = useCheckBoxInput(
        "select-all-connectors",
        t(LocalizationKeys.SelectAllConnectors),
        connectorAssignmentState.selectAllSelection,
        connectorAssignmentState.selectAllDisabled || !editable
    )

    const onCheckBoxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        selectAllCheckBoxChanged(e)
        connectorAssignmentDispatch(handleSelectAll(e.currentTarget.checked))
    }, [selectAllCheckBoxChanged, connectorAssignmentDispatch])

    useEffect(() => {
        const fiberMapsOptionEnabled = checkForAssignedFibers(polarityContext.state.assignmentMapping);
        if (fiberMapsOptionEnabled !== enableFiberMapsOption) {
            storeDispatch(setEnableFiberMapsOptions(fiberMapsOptionEnabled));
        }

        if (fiberMaps) {
            const atLeastOneFiberMapped = polarityContext.state.assignmentMapping.some((m) => m.fiberMap?.destinationIndices.some((i) => i.assignedIndex !== -1));
            if (atLeastOneFiberMapped !== enablePolarityDiagramOption) {
                storeDispatch(setEnablePolarityDiagramOption(atLeastOneFiberMapped));
            }
        }
        connectorAssignmentDispatch(setAssignmentMapping(polarityContext.state.assignmentMapping))
    }, [polarityContext.state.assignmentMapping, enableFiberMapsOption, enablePolarityDiagramOption, fiberMaps, connectorAssignmentDispatch, storeDispatch])

    const onClose = useCallback(() => {
        storeDispatch(setShowConnectorAssignment(false));
    }, [storeDispatch]);

    useEffect(() => {
        if (!displayPolarityDialog && !display) {
            navigationBarDispatch(setCurrentIndex(0));
            connectorAssignmentDispatch(resetSelection());
        }
    }, [displayPolarityDialog, display, connectorAssignmentDispatch, navigationBarDispatch])

    const headerProps: IDialogHeaderProps = {
        title: t(LocalizationKeys.ConnectorAssignment),
        collapsible: true
    };

    const dialogProps: ICollapsibleDialogProps = {
        display: display,
        className: "connector-assignment-dialog",
        headerProps
    };

    const onShowPolarity = useCallback(() => {
        storeDispatch(setShowPolarity(true));
        onClose();
    }, [storeDispatch, onClose]);

    const selectionValid = !(connectorAssignmentState.sourceSelection.length === 0 || connectorAssignmentState.destinationSelection.length === 0);
    const polarityDisabled  = !selectionValid || !editable || (connectorAssignmentState.assignedSelection.length > 0);
    const polarityClassName = polarityDisabled ? "dropdown disabled" : "dropdown"
    const assignmentDisabled = !editable || !connectorAssignmentState.selectAllSelection;
    const assignmentClassName = assignmentDisabled ? "dropdown method disabled" : "dropdown method";

    const applyDisabled = !selectionValid || polarityType === "-1" || !editable || (connectorAssignmentState.assignedSelection.length > 0);

    const applyAssignment = useCallback(async (polarityMap: PolarityMap, connectorAssignmentMaps: IConnectorAssignmentMap[]) => {
        const source = currentBuild!.source
        const destinations = currentBuild!.destinations;
        const applyPolarityRequest = toApplyPolarityRequest(connectorAssignmentMaps, destinations, source, polarityMap, sessionId ?? "");
        try {
            storeDispatch(setSessionBusy(true));
            storeDispatch(setStatusState(WorkspaceStatus.Saving));
            const res = applyPolarityRequest && await new EDSSessionService().applyPolarity(applyPolarityRequest);
            if (res && res.succesful && res.data) {
                storeDispatch(setConfigStatus({ buildId: currentBuild?.id, configStatus: res.data.configStatus}))
                storeDispatch(setSessionWarnings({ buildId: currentBuild?.id, warnings: res.data.warnings }));
            }

            const customMap = res.data && res.data.fiberMaps.length > 0 ? res.data.fiberMaps[0] : generateEmptyCustomMap(connectorAssignmentMaps[0], t(LocalizationKeys.Custom));
            const updatedAssignments = connectorAssignmentMaps.map((connectorMap) => {
                const fiberMap = connectorMap.fiberMap ? connectorMap.fiberMap : customMap;
                const { fiberMapping: mapping, unused, name } = generateFiberMappingData(connectorMap, fiberMap);
                const updatedAssignment = updateConnectorAssignmentMap(connectorMap, { mapping, unused, name })

                return {
                    polarityType: updatedAssignment.polarityType,
                    id: 0,
                    sourceMapping: updatedAssignment.sourceMapping,
                    destinationMapping: updatedAssignment.destinationMapping,
                    fiberMap: {
                        ...updatedAssignment.fiberMap!,
                        id: 0,
                        userId,
                        groupId,
                        key: applyPolarityRequest.assignments[0].polarityId ?? `${CUSTOM_MAP_KEY}`
                    }
                }
            });

            connectorAssignmentDispatch(resetSelection());
            polarityContext.dispatch(addAssignedMaps(updatedAssignments));
                
            if (currentBuild && currentBuild.id !== undefined) {
                const { succesful, data } = await new BuildService().addPolarityConnectorMaps(currentBuild.id, polarityAssignment ?? PolarityAssignments.Standard, updatedAssignments, propagationOptions);
                if (succesful && data) {
                    const { connectorAssignments, polarityDescription, propagationResult } = data;
                    batch(() => {
                        storeDispatch(setPolarityAssignment(polarityAssignment ?? PolarityAssignments.Standard));
                        storeDispatch(setPolarityDescription(polarityDescription!));
                        if (propagationResult) {
                            const { status, connectors } = propagationResult;
                            storeDispatch(updateSpecificConnectors(connectors));
                            storeDispatch(setPropagationNotification({ status, ...propagationOptions }));
                        }
                    })
                    const newConnections = connectorAssignments!.slice(-updatedAssignments.length);
                    polarityContext.dispatch(registerMaps(newConnections));
                }
            }

            storeDispatch(setStatusState(WorkspaceStatus.Saved))
            storeDispatch(setSessionBusy(false));
        }
        catch {
            storeDispatch(setStatusState(WorkspaceStatus.Saved))
            storeDispatch(setSessionBusy(false));
        }
    }, [userId, groupId, polarityAssignment, polarityContext, currentBuild, sessionId, storeDispatch, t, connectorAssignmentDispatch, propagationOptions])

    const onApplyClick = useCallback(() => {
        const { sourceSelection, destinationSelection } = connectorAssignmentState;
        if (connectorAssignmentState.sourceSelection.length && connectorAssignmentState.destinationSelection.length) {
            const polarityMap = filteredPolarityMaps.find(f => f.customKey === polarityType) ?? { ...CustomMap }
            const fiberMap = polarityType ? userFiberMaps[polarityType] : undefined;
            const connectorAssignmentMaps: IConnectorAssignmentMap[] = generateConnectorAssignmentMap(sourceSelection, destinationSelection, polarityMap, fiberMap);

            if (polarityType === `${CUSTOM_MAP_KEY}` && connectorAssignmentMaps.length === 1) {
                const assignmentMap = connectorAssignmentMaps[0];
                const connectorIndex = Math.min(...assignmentMap.sourceMapping.map(s => s.index));
                batch(() => {
                    polarityContext.dispatch(prepareFiberMapping({ connectorIndex, assignmentMap }));
                    storeDispatch(setShowFiberMapping(true));
                    storeDispatch(setShowConnectorAssignment(false));
                })
            } else {
                applyAssignment(polarityMap, connectorAssignmentMaps);
            }
        }
    }, [connectorAssignmentState, filteredPolarityMaps, polarityContext, polarityType, userFiberMaps, applyAssignment, storeDispatch]);

    const onPropagateClick = useCallback(() => {
        storeDispatch(showPropagationDialog(true));
    }, [storeDispatch]);

    const propagateButtonProps: ButtonProps = {
        className: "propagate-button",
        onClick: onPropagateClick
    };

    const propagationStatus = isPropagationActive ? t(LocalizationKeys.On) : t(LocalizationKeys.Off);
    const propagate = {
        display: !isAnonymous,
        button: propagateButtonProps,
        label: `${t(LocalizationKeys.Propagate)}: ${propagationStatus}`
    }

    return {
        dialogProps, assignmentContext, editable, navigationBarProps,
        sourceProps: { rows: sourceDataRows },
        leftTAPProps: { previousDisabled, onPreviousClick: navigationBarProps.onPreviousClick, title: leftContainerTitle, rows: leftTAPDataRows },
        rightTAPProps: { nextDisabled, onNextClick: navigationBarProps.onNextClick, title: rightContainerTitle, rows: rightTAPDataRows, display: rightTAPDataRows.length > 0 },
        resetAllProps: { disabled: resetAllDisabled, onClick: onResetAll, dialogProps: resetAllDialogProps },
        polarityProps: { className: polarityClassName, disabled: polarityDisabled, type: polarityType, onChange: onPolarityChange, onShowPolarity, maps: filteredPolarityMaps },
        assignmentProps: { className: assignmentClassName, assignment: polarityAssignment, disabled: !editable || !selectConnectorsCheckbox.checked, onChange: onAssignmentMethodChange, assignments: filteredPolarityAssignments },
        applyProps: { disabled: applyDisabled, onClick: onApplyClick },
        selectConnectorsCheckbox: { ...selectConnectorsCheckbox, onCheckBoxChange },
        propagate
    }
}

const useAssignmentData = (leftTAP: IDestinationData | undefined, rightTAP: IDestinationData | undefined, context: IConnectorAssignmentContext, editable: boolean) => {
    const { state, dispatch } = context;
    const source = useSelector(currentSourceSelector);
    const taps = useSelector(currentTAPsSelector)
    const workspaceSelection = useSelector(lastSelectedConnectorsSelector);
    const workspaceHover = useSelector(hoveredConnectorSelector)
    const { t } = useTranslation();
    const storeDispatch = useDispatch();

    useEffect(() => {
        if (source) {
            dispatch(setConnectorRows({ source, taps }))
        }
        
        dispatch(disabledConnectorRows(!editable))
    }, [source, taps, dispatch, editable])

    useEffect(() => {
        const connectorMap = {
            index: workspaceSelection.index + 1, position: workspaceSelection.position,
            connectorType: '', fiberCount: 0,
            orderIndex: -1, unassignedFibers: -1,
        }
        dispatch(handleSelection({ connectorMap }))
    }, [dispatch, workspaceSelection])

    useEffect(() => {
        let connectors: IHighlight[] = state.assignmentMapping.flatMap(a => {
            const source: IHighlight[] = a.sourceMapping.flatMap(s => ({ position: s.position, index: s.index - 1, status: "Assigned", color: getSelectionColor(state, s.position, s.index - 1) }));
            const destination: IHighlight[] = a.destinationMapping.flatMap(d => ({ position: d.position, index: d.index - 1, status: "Assigned", color: getSelectionColor(state, d.position, d.index - 1) }));
            return source.concat(destination);
        });

        if (state.sourceSelection.length || state.destinationSelection.length) {
            if (state.assignedSelection.length) {
                connectors = state.assignedSelection.map(s => ({ position: s.position, index: s.index - 1, status: "AssignedSelected", color: getSelectionColor(state, s.position, s.index - 1) }));
            }
            else {
                const selection = [...state.sourceSelection, ...state.destinationSelection];
                const selectedConnectors: IHighlight[] = selection.map(c => ({ position: c.position, index: c.index - 1, status: "Selected", color: getSelectionColor(state, c.position, c.index - 1) }));
                connectors.push(...selectedConnectors);
            }
        }
        storeDispatch(setHighlights(connectors));
    }, [state, storeDispatch])

    useEffect(() => {
        dispatch(handleHoverConnectorSet(workspaceHover))
    }, [workspaceHover, dispatch])

    useEffect(() => {
        const connectors: IHighlight[] = state.hoveredConnectorMap.map(c => ({ position: c.position, index: c.index, color: c.color, status: "Hovered" }));
        if (connectors.length > 0) {
            storeDispatch(addOrUpdateHighlights(connectors));
        }
    }, [state.hoveredConnectorMap, storeDispatch])

    const sourceDataRows = context.state.rows.length ? context.state.rows[0] : []
    const leftTAPDataRows = leftTAP && context.state.rows.length ? context.state.rows[leftTAP.position] ?? [] : []
    const rightTAPDataRows = rightTAP && context.state.rows.length ? context.state.rows[rightTAP.position] ?? [] : []

    const leftContainerTitle = leftTAP?.position ? t(LocalizationKeys.TapNumber, { tapNumber: leftTAP.position }) : '';
    const rightContainerTitle = rightTAP?.position ? t(LocalizationKeys.TapNumber, { tapNumber: rightTAP.position }) : '';

    return { sourceDataRows, leftContainerTitle, leftTAPDataRows, rightContainerTitle, rightTAPDataRows }
}

const useTAPNavigationBar = (state: INavigationBarState, dispatch: React.Dispatch<AnyAction>) => {
    const taps = useSelector(currentTAPsSelector);
    const { currentIndex, disabled } = state;
    const { t } = useTranslation();

    const tapsNavigationMapping = useMemo(() => {
        const tapsNavigationMapping: ITAPNavigationMap[] = [];
        for (let i = 0; i < taps.length; i++) {
            const tap = taps[i];
            if (tap.position % 2 === 0) {
                continue;
            }
            const nextTap = taps[i + 1];
            tapsNavigationMapping.push({
                leftTAP: tap,
                rightTAP: nextTap,
                label: nextTap ? tap.position + "-" + nextTap.position : tap.position.toString()
            });
        }

        return tapsNavigationMapping;
    }, [taps])

    const onNavigationButtonClick = useCallback((index: number) => {
        dispatch(setCurrentIndex(index));
    }, [dispatch]);

    const onFirstClick = useCallback(() => {
        const firstIndex = 0;
        onNavigationButtonClick(firstIndex);
    }, [onNavigationButtonClick]);

    const onPreviousClick = useCallback(() => {
        const previousIndex = currentIndex ? currentIndex - 1 : 0;
        onNavigationButtonClick(previousIndex);
    }, [currentIndex, onNavigationButtonClick]);

    const onNextClick = useCallback(() => {
        const nextIndex = (currentIndex + 1) % tapsNavigationMapping.length;
        onNavigationButtonClick(nextIndex);
    }, [currentIndex, tapsNavigationMapping, onNavigationButtonClick]);

    const onLastClick = useCallback(() => {
        const lastIndex = tapsNavigationMapping.length - 1;
        onNavigationButtonClick(lastIndex);
    }, [tapsNavigationMapping, onNavigationButtonClick]);

    const buttons: IToolItemProps[] = useMemo(() => tapsNavigationMapping.map((b, i) => {
        return {
            tooltip: t(LocalizationKeys.TapsNumber, { tapNumber: b.label }),
            tooltipAlign: "bottom",
            text: b.label,
            onClick: () => onNavigationButtonClick(i),
        }
    }), [tapsNavigationMapping, onNavigationButtonClick, t]);

    const navigationBarProps: INavigationBarProps = useMemo(() => ({
        context: { state, dispatch },
        displayThreshold: 10,
        onFirstClick,
        onPreviousClick,
        buttons,
        onNextClick,
        onLastClick
    }), [state, dispatch, onFirstClick, onPreviousClick, buttons, onNextClick, onLastClick]);

    const tapNavigationBarProps: ITAPNavigationBarProps = useMemo(() => {
        const previousDisabled = currentIndex === 0 || disabled;
        const nextDisabled = buttons ? currentIndex + 1 === buttons.length : disabled;

        const tapMap = tapsNavigationMapping[currentIndex];

        return {
            navigationBarProps,
            leftTAP: tapMap.leftTAP,
            rightTAP: tapMap.rightTAP,
            previousDisabled,
            nextDisabled
        }
    }, [navigationBarProps, tapsNavigationMapping, buttons, currentIndex, disabled])

    return tapNavigationBarProps;
}

export const useFilteredPolarityMaps = (connectorAssignmentState: IConnectorAssignmentState, connectorAssignmentDispatch: React.Dispatch<AnyAction>) => {
    const storeDispatch = useDispatch();
    const selectedPolarityMaps = useSelector(filteredPolarityMapsSelector);
    const display = useSelector(showConnectorAssignmentSelector);
    const sessionPolarityMaps = useSelector(sscBuildPolaritySelector);
    const defaultBuildPolarities = useSelector(sscDefaultBuildPolaritiesSelector)
    const { polarityType } = connectorAssignmentState;

    const onPolarityChange = useCallback((e: SelectChangeEvent<string>) => {
        const polarity = e.target.value;
        connectorAssignmentDispatch(setPolarityType(polarity));
    }, [connectorAssignmentDispatch]);

    useEffect(() => {
        // filter list based on selection
        if (display) {
            if (connectorAssignmentState.sourceSelection.length && connectorAssignmentState.destinationSelection.length) {
                const sourceConnectors = connectorAssignmentState.sourceSelection.map(s => getConnectorType(s.connectorType))
                const destinationConnectors = connectorAssignmentState.destinationSelection.map(d => getConnectorType(d.connectorType))
                storeDispatch(setFilteredPolarityList({ sourceConnectors: sourceConnectors, destinationConnectors: destinationConnectors, polarityConfigs: sessionPolarityMaps }))
            }
            else {
                storeDispatch(setFilteredPolarityList({ sourceConnectors: [], destinationConnectors: [], polarityConfigs: sessionPolarityMaps }))
            }
        }
    }, [connectorAssignmentState.sourceSelection, connectorAssignmentState.destinationSelection, storeDispatch, connectorAssignmentDispatch, display, sessionPolarityMaps])

    useEffect(() => {
        const filteredPolarityMaps = selectedPolarityMaps;
        const defaultPolarity = defaultBuildPolarities.find(p => filteredPolarityMaps.some(f => f.customKey === p.polarityMap?.customKey))
        const defaultPolarityType = defaultPolarity?.polarityMap?.customKey ? defaultPolarity.polarityMap.customKey : `${CustomMap.key}`
        if (!polarityType && defaultPolarity) {
            connectorAssignmentDispatch(setPolarityType(defaultPolarityType)); // set default
        }
        else if (!polarityType && filteredPolarityMaps.length) {
            connectorAssignmentDispatch(setPolarityType(filteredPolarityMaps[0].customKey));
        }

    }, [selectedPolarityMaps, polarityType, defaultBuildPolarities, connectorAssignmentDispatch])

    const filteredPolarityMaps = [...selectedPolarityMaps, CustomMap]

    return { filteredPolarityMaps, polarityType, onPolarityChange }
}

export const useFilteredPolarityAssignments = ({ state, dispatch }: IConnectorAssignmentContext) => {
    const currentBuild = useSelector(currentBuildSelector);
    const { polarityAssignment, filteredPolarityAssignments } = state;

    useEffect(() => {
        if (currentBuild && currentBuild.polarityAssignment && currentBuild.polarityAssignment.length > 0) {
            if (polarityAssignment !== currentBuild.polarityAssignment) {
                dispatch(setPolarityAssignment(currentBuild.polarityAssignment));
            }
        }
    }, [polarityAssignment, currentBuild, dispatch]);
    
    const onAssignmentMethodChange = useCallback((e: SelectChangeEvent<string>) => {
        const assignment = e.target.value;
        dispatch(handleAssignmentMethod(assignment));
    }, [dispatch]);


    return { filteredPolarityAssignments, polarityAssignment, onAssignmentMethodChange };
};

function useResetAllProps(currentBuildId: number, sessionId: string | undefined, polarityContext: IPolarityContext, connectorAssignmentDispatch: React.Dispatch<AnyAction>) {
    const { t } = useTranslation();
    const storeDispatch = useDispatch();
    const [showResetDialog, setShowResetDialog] = useState(false);

    const onResetAll = useCallback(() => {
        setShowResetDialog(true);
    }, []);

    const onResetClose = useCallback(() => {
        setShowResetDialog(false);
    }, []);

    const onResetConfirm = useCallback(async () => {
        setShowResetDialog(false);
        if (sessionId) {
            storeDispatch(setStatusState(WorkspaceStatus.Saving));
            storeDispatch(setSessionBusy(true));
            try {
                const res = await new BuildService().deletePolarityConnectorMaps(currentBuildId);
                const sscRes = await new EDSSessionService().deletePolarityAllMap(sessionId);
                batch(() => {
                    if (sscRes.succesful && sscRes.data) {
                        storeDispatch(setSessionWarnings({ buildId: currentBuildId, warnings: sscRes.data.warnings }));
                        storeDispatch(setConfigStatus({ buildId: currentBuildId, configStatus: sscRes.data.configStatus }));
                    }
                });
                if (res.succesful) {
                    batch(() => {
                        storeDispatch(resetPolarityDescription());
                        storeDispatch(resetPolarityAssignment());
                    });
                    polarityContext.dispatch(resetAssignmentMap());
                    connectorAssignmentDispatch(resetAll());
                    onResetClose();
                }
            }
            finally {
                storeDispatch(setStatusState(WorkspaceStatus.Saved));
                storeDispatch(setSessionBusy(false));
            }
        }
    }, [storeDispatch, connectorAssignmentDispatch, onResetClose, polarityContext, currentBuildId, sessionId]);

    const resetAllDialogProps: IGenericDialogProps = {
        title: t(LocalizationKeys.ResetConnectorAssignment),
        display: showResetDialog,
        message: t(LocalizationKeys.ResetConnectorAssignmentMessage),
        closable: true,
        onClose: onResetClose,
        confirmText: t(LocalizationKeys.Reset),
        onConfirm: onResetConfirm,
        critical: true
    };
    return { onResetAll, resetAllDialogProps };
}

function generateConnectorAssignmentMap(sourceSelection: IConnectorMap[], destinationSelection: IConnectorMap[], polarityMap: PolarityMap, fiberMap?: CustomFiberMapData) : IConnectorAssignmentMap[] {
    const polarityType = polarityMap.customKey ?? `${polarityMap.key}`;
    const fiberMapKey = getFiberMapKey(polarityType);

    const sourceConnectorCount = !fiberMap && polarityMap.key === CustomMap.key ? sourceSelection.length : polarityMap.sourceConnectors.length;
    const destinationConnectorCount = !fiberMap && polarityMap.key === CustomMap.key ? destinationSelection.length : polarityMap.destinationConnectors.length;
    const sourceMaps = sourceSelection.length / sourceConnectorCount;
    const destinationMaps = destinationSelection.length / destinationConnectorCount;
    const maxMapCount = Math.ceil(Math.min(sourceMaps, destinationMaps));

    const connectorAssignmentMaps: IConnectorAssignmentMap[] = [];
    for (let i = 0; i < maxMapCount; i++) {
        const sourceMapping = selectionToConnectorMap(sourceConnectorCount, i, sourceSelection, fiberMapKey);
        const destinationMapping = selectionToConnectorMap(destinationConnectorCount, i, destinationSelection, fiberMapKey);
        const connectorAssignmentMap: IConnectorAssignmentMap = {
            sourceMapping,
            destinationMapping,
            polarityType: polarityType ?? `${CUSTOM_MAP_KEY}`,
            fiberMap
        };

        connectorAssignmentMaps.push(connectorAssignmentMap);
    }

    return connectorAssignmentMaps;
}

function selectionToConnectorMap(connectorCount: number, i: number, connectorSelection: IConnectorMap[], fiberMapKey: number) {
    const startIndex = i * connectorCount;
    const endIndex = startIndex + connectorCount;
    const mapping: IConnectorMap[] = connectorSelection.slice(startIndex, endIndex).map((s, i) => {
        return {
            ...s,
            id: 0,
            unassignedFibers: fiberMapKey === CUSTOM_MAP_KEY ? s.fiberCount : 0,
            orderIndex: i,
        };
    });

    return mapping;
}

export function generateEmptyCustomMap(assignmentMap: IConnectorAssignmentMap, name?: string): CustomFiberMapData {

    const sourceIndices = [...assignmentMap.sourceMapping]
                            .sort((a, b) => a.orderIndex > b.orderIndex ? 1 : -1)
                            .flatMap((s, index) => toFiberMapIndices(s, index));

    const destinationIndices = [...assignmentMap.destinationMapping]
                                .sort((a, b) => a.orderIndex > b.orderIndex ? 1 : -1)
                                .flatMap((d, index) => toFiberMapIndices(d, index));

    return {
        destinationIndices,
        sourceIndices,
        name: name || "Custom",
        key: `${CUSTOM_MAP_KEY}`,
        fiberCount: sourceIndices.length,
    }
}

function toFiberMapIndices(connectorMap: IConnectorMap, connectorMapIndex: number) {
    const fiberIndices: IFiberMapIndex[] = [];
    const fiberCount = connectorMap.fiberCount === 8 ? 12 : connectorMap.fiberCount;
    const pinIndex = connectorMapIndex * fiberCount;
    for (let i = 0; i < fiberCount; i++) {
        const fiberIndex: IFiberMapIndex = {
            assignedIndex: AssignedIndexCodes.Unassigned,
            index: pinIndex + i,
            connectorType: connectorMap.connectorType,
            transceiver: connectorMap.position === 0 ? Tx : Rx
        }

        fiberIndices.push(fiberIndex)
    }

    return fiberIndices;
}

function getFiberMapKey(polarityType: string | undefined): number {
    if (!polarityType) return CUSTOM_MAP_KEY;

    const polarityTypeInt = parseInt(polarityType);
    if (isNaN(polarityTypeInt)) return CUSTOM_MAP_KEY;

    return polarityTypeInt;
}

function getSelectionColor(state: IConnectorAssignmentState, position: number, index: number): IHighlightColor | undefined {
    return state.rows[position] ? state.rows[position][index]?.color : undefined;
}
