import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { SnackbarTypes } from '../../../../../../../../../ui/snackbar/generic/redux/types';
import { acknowledgeWarningGroup } from '../../redux/reducer';
import { unacknowledgedWarningGroupsSelector } from '../../redux/selectors';
import { IWarning, WarningSeverity } from '../../redux/types';
import { LocalizationKeys } from '../../../../../../../../../locales/types';

export const useWarningSnackbar = () => {
    const warningGroups = useSelector(unacknowledgedWarningGroupsSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const groupIndex = warningGroups.length - 1;
    const warningGroup = warningGroups[groupIndex];

    const type = warningGroup?.warnings?.find((w) => w.severity === WarningSeverity.Error) ? SnackbarTypes.Error : SnackbarTypes.Warning;
    const open = warningGroups.length > 0;
    const message = warningGroup?.warnings ? getWarningGroupMessage(t, warningGroup.warnings) : undefined;
    
    const onClose = () => {
        if (warningGroup) {
            dispatch(acknowledgeWarningGroup(warningGroup.id));
        }
    };
    
    useEffect(() => {
        if (warningGroup?.warnings?.length > 1) {
            setTimeout(() => {
                onClose();
            }, 60000);
        }
    });

    return {
        type,
        open,
        message,
        onClose,
    };
};

function getWarningGroupMessage(t: TFunction, warnings: IWarning[]): string {
    const warningCount = warnings.length;

    if (warningCount === 1) {
        return t(warnings[0].problemText);
    }

    const errorCount = warnings.filter((w) => w.severity === WarningSeverity.Error).length;

    if (errorCount === warningCount) {
        return t(LocalizationKeys.WarningSnackbarMessageMultipleErrors, { errorCount });
    }

    if (errorCount === 0) {
        return t(LocalizationKeys.WarningSnackbarMessageMultipleWarnings, { warningCount });
    }

    return t(LocalizationKeys.WarningSnackbarMessageErrorsAndWarnings, { errorCount, warningCount: warningCount - errorCount });
}
