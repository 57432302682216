export interface IEditToolbarState {
    display: boolean,
    selection: ISelectionData
}

export interface ISelectionData {
    hovered: number,
    selected: number
}

export const initialState: IEditToolbarState = {
    display: false,
    selection: {
        hovered: -1,
        selected: -1
    }
}