import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../locales/types";
import { IConnectorGroupData, getNbConnectors } from "../../../redux/build/connector/types";
import { unitsOfMeasureContainerUnitSelector } from "../../overlay/components/header/components/units-of-measure-container/redux/selectors";
import { IUnitOfMeasure, convertToDisplay, convertTo, getUnitsName } from "../../overlay/components/header/components/units-of-measure-container/UnitsOfMeasure";
import { getConnectorType } from "../../overlay/components/wizard/redux/types";
import { currentBuildSelector } from '../../../selectors/root.selectors';

export const useBuildPlanTable = () => {
    const currentBuild = useSelector(currentBuildSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const { t } = useTranslation();

    const feederRows = useMemo(() => {
        const { source } = currentBuild!;
        const connectorType = getConnectorType(source.groups[0].type!).description;
        const connectorTotal = source.groups.map(g => g.connectors).flat().length;
        const stagger = source.groups[0].stagger!;
        const b0 = source.lengthB!

        const pce = t(LocalizationKeys.PCE);

        const titleRow = { className: "subsection", data: { description: `${t(LocalizationKeys.Feeder) + t(LocalizationKeys.Colon)}`, quantity: "", uom: "" } }
        const connectorRow = { className: "", data: { description: `${connectorType}`, quantity: `${connectorTotal}`, uom: pce } }

        const groupRows = source.groups.map(g => {
            const length: IUnitOfMeasure = source.customBLength && g.lengthB ? g.lengthB : { value: b0.value + (g.position! * stagger.value), unit: source.lengthB!.unit }
            const lengthString = convertToDisplay(length, unit) + unit;
            return {
                className: "",
                data: {
                    description: `${lengthString + ' ' + t(LocalizationKeys.SingleModeDrops)}`,
                    quantity: `${g.connectors.length}`,
                    uom: pce
                }
            }
        })
        return [titleRow, connectorRow, ...groupRows];
    }, [currentBuild, t, unit])

    const tapRows = useMemo(() => {
        const { destinations, source } = currentBuild!;
        const { gripOnEnd } = source;
        const a0 = source.lengthA!;
        let aLength = a0.value;
        const spilloutRows = [];
        const pce = t(LocalizationKeys.PCE);

        const titleRow = { className: "subsection", data: { description: `${t(LocalizationKeys.Taps) + t(LocalizationKeys.Colon)}`, quantity: destinations.length, uom: "" } }
        for (const { position, lengthA } of destinations) {
            const length = convertTo({ value: aLength, unit: a0.unit }, unit);
            spilloutRows.push({
                className: "",
                data: {
                    description: t(LocalizationKeys.BuildPlanFurcationSpillout, { position }),
                    quantity: convertToDisplay(length, unit),
                    uom: getUnitsName(unit, true)
                }
            })

            aLength += lengthA!.value;
        }

        const connectorGroupKvp: { [index: string]: { group: IConnectorGroupData, length: IUnitOfMeasure }[] } = {}
        for (const { groups, lengthB, customBLength } of destinations) {
            const b = lengthB!;
            const stagger = groups[0].stagger!;
            for (const group of groups) {
                if (group.connectors.length > 0) {
                    const connectorType = group.connectors[0].type!;
                    const length: IUnitOfMeasure = customBLength && group.lengthB ? group.lengthB : { value: b.value + stagger.value * (group.position!), unit: b.unit }
                    if (!connectorGroupKvp[connectorType]) {
                        connectorGroupKvp[connectorType] = [{ group, length }]
                    }
                    else {
                        connectorGroupKvp[connectorType].push({ group, length })
                    }
                }
            }
        }

        let lengthRowPair: { [k: number]: { className: string, data: { description: string, quantity: number, uom: string } } } = {}
        const dropSpillOutRows = [];
        const connectorTypes = Object.keys(connectorGroupKvp);
        for (const connectorType of connectorTypes) {
            const connectorGroups = connectorGroupKvp[connectorType];
            lengthRowPair = {};
            for (const { group, length } of connectorGroups) {
                if (!lengthRowPair[length.value]) {
                    const lengthString = convertToDisplay(length, unit) + unit;
                    lengthRowPair[length.value] = {
                        className: "",
                        data: {
                            description: `${lengthString + ' ' + t(LocalizationKeys.SingleModeDrops)}`,
                            quantity: group.connectors.length,
                            uom: pce
                        }
                    }
                }
                else {
                    lengthRowPair[length.value].data.quantity += group.connectors.length;
                }
            }

            const connectorTitleRow = { className: "", data: { description: getConnectorType(connectorType).description!, quantity: getNbConnectors(connectorGroups.map(cg => cg.group)), uom: pce } }
            const lengthRows = Object.values(lengthRowPair)
            const spillOutRows = [connectorTitleRow, ...lengthRows]
            dropSpillOutRows.push(...spillOutRows);
        }

        if (gripOnEnd) {
            const gripRow = {
                className: "",
                data: {
                    description: t(LocalizationKeys.PullingGrip),
                    quantity: 1,
                    uom: t(LocalizationKeys.PCE)
                }
            }
            dropSpillOutRows.push(gripRow)
        }

        return [titleRow, ...spilloutRows, ...dropSpillOutRows];
    }, [currentBuild, t, unit]);

    const buildPlanTables = useMemo(() => {
        const tableRows = [...feederRows, ...tapRows];
        const page1rowLimit = 18;
        if (tableRows.length > page1rowLimit) {
            let table1 = tableRows.slice(0, page1rowLimit);
            let table2 = tableRows.slice(page1rowLimit);
            if (table1.length > 0 && table1[table1.length - 1].className === "subsection") {
                const lastRow = table1.pop();
                table2 = [lastRow!, ...table2];
            }
            return [table1, table2]
        }
        else {
            return [tableRows, []]
        }
    }, [feederRows, tapRows]);

    return { buildPlanTables }
}