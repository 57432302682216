import React from 'react';
import { FilledSelect as Select, SelectItem as Option } from '@orbit/select';
import { useConfigurationType } from './hooks';

export const ConfigurationType: React.FC<{ disabled: boolean }> = (props: { disabled: boolean }) => {
    const { disabled: locked } = props;
    const { label, disabled, configurationType, onConfigurationTypeChange, configurationTypeOptions } = useConfigurationType();
    return configurationTypeOptions.length <= 1 || !configurationType ? null : (
        <Select className="field" label={label} disabled={disabled || locked} value={configurationType.key} onChange={onConfigurationTypeChange} palette="primary" helperText="" units=''>
            {configurationTypeOptions.map(ct => <Option key={ct.key} value={ct.key}>{ct.description}</Option>)}
        </Select>
    );
};
