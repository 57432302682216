import { FilledTextField } from '@orbit/text-field'
import React from 'react';
import { useBLengthInput } from './hooks';
import { IBLengthInputProps } from './type';

export const BLengthInput: React.FC<IBLengthInputProps> = (props) => {
    const { inputField, label } = useBLengthInput(props);
    return (
        <div key={label}>
            <FilledTextField palette='primary'
                className='custom-b-length-field'
                disabled={inputField.disabled}
                label={inputField.label}
                helperText={inputField.helperText}
                error={!inputField.isValid} value={inputField.value} 
                onChange={(e) => inputField.onChange(e)}/>
        </div>
    )
}