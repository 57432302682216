import React from 'react';
import { ILineMarkerProps, initialState } from './types';
import { useLineMarker } from './hooks';
import { Container, Graphics, Text } from '@inlet/react-pixi';

export const LineMarker : React.FC<ILineMarkerProps> = (props: ILineMarkerProps = initialState) => {
    const { text, textStyle, textPosition, startLine, endLine } = useLineMarker(props);

    return (
        <Container>
            { startLine.display ? <Graphics draw={startLine.draw} /> : null }
            { endLine.display ? <Graphics draw={endLine.draw} /> : null }
            { text ? <Text {...textPosition} text={text}  style={textStyle} resolution={2} scale={0.5} />  : null }
        </Container>
    )
};