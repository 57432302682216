import React from "react";
import { useNotificationSnackbar } from "./hooks";
import { Alert } from "@orbit/snackbar";
import { Close } from "@mui/icons-material";
import { IconButton } from "@orbit/icon-button";
import ReactDOM from "react-dom";

export const NotificationSnackbar: React.FC = () => {
    const { notificationProps, closeIconProps } = useNotificationSnackbar();
    return (
        ReactDOM.createPortal(
            <Alert {...notificationProps} action={<IconButton {...closeIconProps} icon={<Close />} />} />,
        document.body)
    )    
}