import React from 'react';
import { IAutocompleteProps } from './types';
import { useGenericAutocomplete } from './hooks';
import { TextField, Autocomplete } from '@mui/material';

export const GenericAutocomplete = (props: IAutocompleteProps) => {
    const { open, disabled, options, value, onOpen, onClose, onValueChanged, onInputChanged, label, isInputValid, inputErrorText, onInputKeyDown, containerClassName } = useGenericAutocomplete(props);
    return (
        <div className={containerClassName}>
            <Autocomplete
                disableClearable
                open={open}
                disabled={disabled}
                options={options}
                value={value}
                onOpen={onOpen}
                onClose={onClose}
                onChange={onValueChanged}
                onInputChange={onInputChanged}
                renderInput={(params) => (
                    <div className={"generic-autocomplete-field-container"}>
                        <TextField {...params} disabled={disabled} label={label} error={!isInputValid} helperText={isInputValid ? undefined : inputErrorText} onKeyDown={onInputKeyDown} variant="filled"/>
                    </div>
                )}
            />
        </div>
    );
}