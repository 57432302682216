import React from 'react';
import { ICollapsibleDialogProps } from './types';
import { useCollapsibleDialog } from './hooks';
import { DialogContext } from '../../../workspace/redux/dialog/reducer';
import { DialogHeader } from '../header/DialogHeader';

export const CollapsibleDialog: React.FC<ICollapsibleDialogProps> = (props) => {
    const { context, dialogClassName, open, onClose, headerProps, collapsed, children } = useCollapsibleDialog(props);
    return open ? (
        <DialogContext.Provider value={context}>
                <div className={dialogClassName + " toggle-pointer-events collapse-dialog"}>
                    <DialogHeader onClose={onClose} {...headerProps} />
                    { collapsed ? null : children }
                </div>
        </DialogContext.Provider>
    ) : null
}