
import { Graphics } from "pixi.js";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { fiberTypeTrunkColorSelector } from "../../../../../workspace/redux/ssc/selectors";
import { IPoint, IPointEmpty } from "../../../../types";
import { IBaseProps, SpriteProps } from "../../types";
import { BORDER_COLOR, CABLE_COLOR } from "../connectors/components/furcation/types";
import { TETHER_BORDER_WIDTH, TETHER_CABLE_WIDTH, TETHER_SHADOW_WIDTH, TRUNK_SHADOW_ALPHA } from "./types";

export const useDropBase = ({ wamLocation }: IBaseProps) => {
    const { id: color, colorHex } = useSelector(fiberTypeTrunkColorSelector);

    const location: IPoint = wamLocation ? wamLocation : IPointEmpty;
    const tapTrunkSprite: SpriteProps = { color };
    const wamSprite: SpriteProps = { ...location, color };
 
    const drawTetherTrunk = useCallback((grfx: Graphics) => {
        grfx.clear();
    
        let lineX = 71;
        let lineY = 14.5;
    
        // Border
        grfx.moveTo(lineX, lineY);
        grfx.lineStyle(TETHER_BORDER_WIDTH, BORDER_COLOR);
        grfx.lineTo(location.x, lineY);
    
        // Cable
        grfx.moveTo(lineX, lineY);
        grfx.lineStyle(TETHER_CABLE_WIDTH, colorHex ? Number(colorHex) : CABLE_COLOR);
        grfx.lineTo(location.x, lineY);
    
        // Shadow
        lineY = 20;
        grfx.moveTo(lineX, lineY);
        grfx.lineStyle(TETHER_SHADOW_WIDTH, BORDER_COLOR, TRUNK_SHADOW_ALPHA);
        grfx.lineTo(location.x, lineY);
    }, [location.x, colorHex]);

    return { wamSprite, tapTrunkSprite, drawTetherTrunk };
};