import React, { Component } from "react";
import { WorkspaceState, connect } from "../../../../redux/reducers";
import { setWizardDisplay, setCurrentStep, enableNextStep, setApplyEditChanges, saveBuildData } from './redux/reducers';
import { setViewportContext } from '../../../../../pixi/components/viewport/redux/reducers';
import { ViewportStatus } from '../../../../../pixi/components/viewport/redux/types';
import { setToolbarDisplay } from '../footer/components/toolbar/redux/reducers';
import { setCurrentBuild, setupSource, setupDestination, setupAllDestinations } from '../../../../redux/build/reducers';
import { WizardHeader } from "./components/wizard-header/WizardHeader";
import { SourceSetup } from "./components/setup/source/SourceSetup";
import { ISourceSetupState } from "./components/setup/source/redux/types";
import { DestinationSetup } from './components/setup/destination/DestinationSetup';
import { IDestinationSetupState } from "./components/setup/destination/redux/types";
import { WizardFooter } from "./components/wizard-footer/WizardFooter";
import { setStatusState } from '../header/components/status/redux/reducer'
import { sscFiberCountSelector, sscConfigSessionBusySelector } from "../../../../redux/ssc/selectors";
import { currentBuildCatalogCodeSelector } from '../../../../selectors/build.selectors';
import { currentStatusSelector } from "../header/components/status/redux/selectors";
import { WorkspaceStatus } from "../header/components/status/redux/types";
import { SectionContext, Feeder, Drop } from "../../../../../pixi/components/build/types";

import "./Wizard.scss";

const mapStateToProps = (state: WorkspaceState) => {
    const { display, stepCount, currentStep, isNextEnabled, savedBuildData } = state.wizard;
    const { context } = state.viewport;
    const { selection } = state.toolbar;
    const { fiberCountOptions } = sscFiberCountSelector(state);
    const currentStatus = currentStatusSelector(state);
    const catalogCode = currentBuildCatalogCodeSelector(state);
    const sscSessionBusy = sscConfigSessionBusySelector(state);
    const hasCatalogCode = catalogCode.length > 0;
    const locked = currentStatus === WorkspaceStatus.Locked;
    const disabled = hasCatalogCode || locked || sscSessionBusy;
    return { display, stepCount, currentStep, isNextEnabled, context, selection, savedBuildData, fiberCountOptions, disabled };
}

const mapDispatchToProps = {
    setWizardDisplay,
    setViewportContext,
    setCurrentStep,
    enableNextStep,
    setApplyEditChanges,
    setBuildData: saveBuildData,
    setToolbarDisplay,
    setCurrentBuild,
    setupSource,
    setupDestination,
    setupAllDestinations,
    setStatusState,
};

type props = Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;
class WizardComponent extends Component<props> {
    public componentDidUpdate(prevProps: props) {
        const currentStepChanged = this.props.currentStep !== prevProps.currentStep;
        if (currentStepChanged && this.props.currentStep! > this.props.stepCount!) {
            this.props.setWizardDisplay!(false);

            if (this.props.context === ViewportStatus.Editing) {
                this.setIsEditing(false);
                this.props.setToolbarDisplay!(true);
            }
        }

        const selectionChanged = this.props.selection!.selected !== prevProps.selection!.selected
        if (this.props.context === ViewportStatus.Editing && selectionChanged) {
            this.setIsEditing!(false);
            this.resetBuildConfiguration();
        }
    }

    private getSetup() {
        if (this.props.currentStep === 1) {
            return (
                <SectionContext.Provider value={Feeder}>
                    <SourceSetup disabled={this.props.disabled} applyChanges={this.applySourceChanges} />
                </SectionContext.Provider>
            );
        }

        if (this.props.currentStep === 2) {
            return (
                <SectionContext.Provider value={Drop}>
                    <DestinationSetup disabled={this.props.disabled} applyChanges={this.applyDestinationChanges} />
                </SectionContext.Provider>
            );
        }
    }

    private setIsEditing = (isEditing: boolean) => {
        if (isEditing) {
            this.props.setViewportContext!(ViewportStatus.Editing);
            this.props.setWizardDisplay!(true);
            this.props.enableNextStep!(false);
        } else {
            this.props.setViewportContext!(ViewportStatus.Active);
            this.props.setApplyEditChanges!(false);
            this.props.setCurrentStep!(this.props.stepCount! + 1);
            this.props.setWizardDisplay!(false);
            this.props.setBuildData!(undefined);
        }
    }

    private applySourceChanges = (sourceSetup: ISourceSetupState) : void => {
        this.props.setupSource!({ sourceSetup, fiberCountOptions: this.props.fiberCountOptions ?? []});
    }

    private applyDestinationChanges = (destinationSetup: IDestinationSetupState) : void => {
        this.props.context === ViewportStatus.Editing ? this.props.setupDestination!(destinationSetup) : this.props.setupAllDestinations!(destinationSetup);
    }

    private resetBuildConfiguration = () => {
        this.props.setCurrentBuild!(this.props.savedBuildData!);
    }

    render() { 
        if (!this.props.display) {
            return null;
        }

        return (
            <div className="card-container toggle-pointer-events">
                <div className="wizard">
                    <WizardHeader />
                    {this.getSetup()}
                    <WizardFooter />
                </div>
            </div>
        );
    }
}

export const Wizard = connect(mapStateToProps, mapDispatchToProps)(WizardComponent)