import React from 'react';
import { INavigationBarProps } from './types';
import { useNavigationBar } from './hooks';
import { ToolItem } from '../../workspace/components/overlay/ToolItem';

import './NavigationBar.scss';

export const NavigationBar: React.FC<INavigationBarProps> = (props) => {
    const { firstProps, previousProps, buttons, nextProps, lastProps, showPreviousThreshold, showNextThreshold, thresholdProps } = useNavigationBar(props);
    return (
        <div className="navigation-bar">
            <ToolItem {...firstProps} />
            <ToolItem {...previousProps} />
            { showPreviousThreshold ? <ToolItem {...thresholdProps} /> : null }
            { 
                buttons?.map(b => {
                    return (
                        <ToolItem key={b.text} {...b} />
                    );
                })
            }
            { showNextThreshold ? <ToolItem {...thresholdProps} /> : null }
            <ToolItem {...nextProps} />
            <ToolItem {...lastProps} />
        </div>
    );
}