import { WorkspaceState } from '../redux/reducers';
import { createSelector } from 'reselect';

export const workspaceSelector = (state: WorkspaceState) => state;

export const buildStateSelector = (state: WorkspaceState) => state.builds;

export const currentBuildSelector = createSelector(
    buildStateSelector,
    state => state.currentBuild && {...state.currentBuild, catalogCode: state.currentBuild.catalogCode ?? ''}
);

export const buildsSelector = (state: WorkspaceState) => state.builds.builds;

export const projectManagerDataSelector = createSelector(
    workspaceSelector,
    workspace => workspace.projectManager.data
);