import { ContainedButton } from '@orbit/button'
import { useReportSettings } from './hooks';
import { DrawingSection } from './components/drawing-section/DrawingSection';
import { Dialog } from '@orbit/dialog';
import { DropSubSection } from './components/drop-settings/DropSettingsSection';
import { ToolItem } from '../../../../ToolItem';
import { FooterFields } from './components/footer-fields/FooterFields';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../../../../locales/types';
import { DiagramSectionFields } from './components/diagrams-section/DiagramsSection';

import './ReportSettings.scss'

export const ReportSettings = () => {
    const { t } = useTranslation();
    const { footerFields, drawingFields, diagramFields, closeWindow, showPrintDialog, printSelection, disablePrint, dropSection } = useReportSettings();
    const [overviewSection, feederSection] = drawingFields

    return (
        showPrintDialog ?
            <div className={"dialog-container"}>
                <Dialog
                    open={showPrintDialog}
                    onClose={closeWindow}
                    role={"alertdialog"}
                    className={"toggle-pointer-events report-card"}>
                    <div className="report-content">
                        <div id="report-title">
                            <label>{t(LocalizationKeys.PrintReport)}</label>
                            <ToolItem icon={"close"} onClick={closeWindow} alt={"Close"} />
                        </div>
                        <div className="horizontal-container">
                            <div className="report-side drawing-side">
                                {t(LocalizationKeys.DrawingFeatures)}
                                <div className="section-divider" />
                                <DrawingSection {...overviewSection} />
                                <DrawingSection {...feederSection} />
                                <DropSubSection {...dropSection} />
                                <div className="section-divider" />
                                <DiagramSectionFields diagrams={diagramFields} />
                            </div>
                            <div className="report-side footer-side">
                                {t(LocalizationKeys.Footer)}
                                <div className="section-divider" />
                                <FooterFields fields={footerFields} />
                            </div>
                        </div>
                        <div className="report-footer">
                            <ContainedButton className="print-button" onClick={printSelection} disabled={disablePrint}>{t(LocalizationKeys.PrintReport).toLocaleUpperCase()}</ContainedButton>
                        </div>
                    </div>
                </Dialog> </div> : null)
}