import JwtDecode from 'jwt-decode';
import { WebService } from './web-service';
import { IApiResult } from './api-result';
import { OktaServerConfig } from '../okta/okta.config';

interface IToken {
    iat: number; // issued at time
    exp: number; // expires
    sub: string; // identity
    eds_gid: string;
    given_name: string;
    family_name: string;
    email: string;
    raw?: string; // the token itself
}

export class AuthenticationService extends WebService {
    constructor() {
        super();
        this.isUnstableApi = false;
    }

    public oidConfig(): Promise<IApiResult<OktaServerConfig>> {
        return this.get('api/auth/oid');
    }

    public oidLogin(token: string): Promise<IApiResult<string>> {
        return this.post('api/auth/oid', { token });
    }

    public logout(): Promise<IApiResult<void>> {
        return this.post('api/auth/logout', {});
    }

    private static LS_TOKEN_KEY = 'token';
    public static saveToken(token: string | null): void {
        if (token) {
            window.localStorage.setItem(AuthenticationService.LS_TOKEN_KEY, token);
        }
        else {
            window.localStorage.removeItem(AuthenticationService.LS_TOKEN_KEY);
        }
    }

    public static getToken(): Token | null {
        const tokenStr = window.localStorage.getItem(AuthenticationService.LS_TOKEN_KEY);
        if (!tokenStr) {
            return null;
        }
        const token = Token.fromString(tokenStr);
        return token;
    }
}

export class Token implements IToken {
    public iat: number;
    public exp: number;
    public sub: string;
    public eds_gid: string;
    public given_name: string;
    public family_name: string;
    public email: string;
    public raw?: string;

    constructor(token: IToken, raw: string) {
        this.iat = token.iat;
        this.exp = token.exp;
        this.sub = token.sub;
        this.eds_gid = token.eds_gid;
        this.given_name = token.given_name;
        this.family_name = token.family_name;
        this.email = token.email;
        this.raw = raw;
    }

    public isExpired(): boolean {
        return !this.raw || !this.exp || this.exp * 1000 < Date.now();
    }

    public static fromString(tokenStr: string): Token | null {
        try {
            const itoken = JwtDecode<IToken>(tokenStr);
            return new Token(itoken, tokenStr);
        }
        catch {
            console.error('Unable to create token from string:', tokenStr);
            return null;
        }
    }
}
