import { createSelector } from "@reduxjs/toolkit";
import { getAnchorHeight, getConnectorAnchorPoint, getFurcationPoint, getHorizontalTrunkWidth, getStaggerPosition, getVerticalTapLocation, getWAMLocation } from "../../pixi/components/build/components/connectors/components/furcation/hooks";
import { COLLAPSED_FIRST_GROUPINDEX, COLLAPSED_LAST_GROUPINDEX, getSpriteLocation, IConnectorLegProps, STAGGER_OFFSET_Y } from "../../pixi/components/build/components/connectors/components/furcation/types";
import { CONNECTOR_STEP } from "../../pixi/components/build/components/connectors/types";
import { getFeederWidth } from "../../pixi/components/build/components/feeder-base/types";
import { Drop, Feeder, SpriteProps, TrunkProps } from "../../pixi/components/build/types";
import { Rectangle } from "../../pixi/components/decorators/interaction/types";
import { DropMarkStartB, ExternalSourceMarkStartB, IMarkerProps, InternalSourceMarkStartB, IPath } from "../../pixi/components/decorators/markers/types";
import { ITextPath } from "../../pixi/components/decorators/text/types";
import { getConnectorColor, getConnectorTexture, MetaSourceTrunkExternalTexture, MetaTAPTrunkTexture } from "../../pixi/factories/Texture";
import { IPoint, IPointEmpty } from "../../pixi/types";
import { IColor } from "../../ui/dialog/color/types";
import { toolbarSelector } from '../components/overlay/components/footer/components/toolbar/redux/selectors';
import { unitsOfMeasureContainerUnitSelector } from "../components/overlay/components/header/components/units-of-measure-container/redux/selectors";
import { convertToDisplay, IUnitOfMeasure, Unit } from "../components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure";
import { ITriggerInfo } from "../components/overlay/components/reports/components/trigger/reducer/types";
import { ConfigurationType, ConnLC, getConnectorType, IConnectorType, Stagger0 } from "../components/overlay/components/wizard/redux/types";
import { extractBuildInfo } from "../redux/build/actions";
import { polaritySelector } from '../redux/build/connector/polarity/selectors';
import { getNbConnectors, IConnectorData, IConnectorGroupData } from "../redux/build/connector/types";
import { getGroupsWithIndex, IBuildData, ITrunkContext, TrunkData } from '../redux/build/types';
import { PAGE_SIZE } from "../redux/project-manager/types";
import { WorkspaceState } from "../redux/reducers";
import { fiberTypeTrunkColorSelector, sscBuildPolaritySelector, sscConnectorColorsSelector, sscConnectorTypeRecordSelector, sscDefaultTriggersColorSelector } from "../redux/ssc/selectors";
import { buildsSelector, currentBuildSelector } from "./root.selectors";

export const buildSelectorFactory = (projectId?: number) => {
    return createSelector(
        currentBuildSelector,
        buildsSelector,
        (currentBuild, builds) => {
            return builds.find(b => b.buildId === projectId) || extractBuildInfo(currentBuild!);
        }
    )
};

export const currentBuildInfoSelector = createSelector(
    currentBuildSelector,
    (build) => build && extractBuildInfo(build)
)

export const currentSourceSelector = createSelector(
    currentBuildSelector,
    currentBuild => currentBuild?.source
);

export const currentTAPsSelector = createSelector(
    currentBuildSelector,
    currentBuild => currentBuild ? currentBuild.destinations : []
);

export const currentTAPsSelectorFactory = (position: number) => {
    return createSelector(
        currentTAPsSelector,
        (taps) => taps.find(t => t.position === position)
    )
}

export const currentAvailabilitySelector = createSelector(
    currentBuildSelector,
    currentBuild => currentBuild?.availability
)

export const buildPositionsSelector = createSelector(
    currentBuildSelector,
    currentBuild => currentBuild ? [0, ...currentBuild.destinations.map(d => d.position)] : []
);

export const buildIdSelector = createSelector(
    currentBuildSelector,
    (build) => build ? build.id : undefined
);

export const buildSessionIdSelector = ((state: WorkspaceState) => state.builds.currentBuild?.sessionId);

export const currentBuildCatalogCodeSelector = createSelector(
    currentBuildSelector,
    (build) => build && build.catalogCode ? build.catalogCode : ""
);

export const currentBuildConfigurationTypeSelector = createSelector(
    currentBuildSelector,
    (build) => build?.configurationType ?? ConfigurationType.Patching
);

export const polarityDescriptionSelector = createSelector(
    currentBuildSelector,
    (build) => build?.polarityDescription ?? ""
)

export const connectorTypesSelector = createSelector(
    currentBuildSelector,
    currentBuild => {
        const connectorTypes: IConnectorType[] = [];
        if (currentBuild) {
            const feederConnectorType = currentBuild.source.groups[0].connectors[0].type
            if (feederConnectorType) {
                connectorTypes.push(getConnectorType(feederConnectorType));
            }
            const dstConnectors = currentBuild.destinations.map(d => d.groups[0].connectors[0]);
            for (const dstConnector of dstConnectors) {
                if (dstConnector.type) {
                    connectorTypes.push(getConnectorType(dstConnector.type));
                }
            }
        }
        return connectorTypes;
    }
)

export const buildInfoListSelectorFactory = (pinCurrentBuild: boolean = true) => {
    return createSelector(
        buildsSelector,
        currentBuildInfoSelector,
        (list, currentBuild) => {
            return pinCurrentBuild && currentBuild?.buildId ? [currentBuild, ...list.filter(b => b.buildId !== currentBuild.buildId)] : list
        }
    )
}

export const buildCountSelector = createSelector(
    buildsSelector,
    (builds) => builds.length
)

export const buildsPageIndexSelector = createSelector(
    buildCountSelector,
    (count) => Math.floor((count - 1) / PAGE_SIZE)
)

export const currentTAPsFibersSelector = createSelector(
    currentTAPsSelector,
    taps => taps.flatMap(t => t.groups).flatMap(g => g.connectors).map(c => getConnectorType(c.type!).fiberCount).reduce((a, b) => a + b, 0)
);

export const buildConnectorsSelector = createSelector(
    currentSourceSelector,
    currentTAPsSelector,
    (source, taps) => {
        const srcConnectors: IConnectorData[] = source ? getGroupsConnectors(source.groups) : [];
        const tapConnectors: IConnectorData[] = taps.flatMap(t => getGroupsConnectors(t.groups));
        return { srcConnectors, tapConnectors };
    }
);

export const getGroupsConnectors = (groups: IConnectorGroupData[]) => {
    return groups.flatMap(g => g.connectors);
}

export const totalNbrConnectorsSelector = createSelector(
    buildConnectorsSelector,
    ({ srcConnectors, tapConnectors }) => srcConnectors.length + tapConnectors.length
);

export const buildPolarityOptionSelector = createSelector(
    polaritySelector,
    sscBuildPolaritySelector,
    (polarity, sscList) => {
        return polarity.selectedConfig ? [polarity.selectedConfig] : sscList
    }
)

export const selectedTrunkSelector = createSelector(
    currentBuildSelector,
    toolbarSelector,
    (build, toolbar): TrunkData => {
        const { selected } = toolbar.selection;
        if (selected === -1) {
            return {};
        }

        return selected === 0
            ? { ...build?.source, position: selected }
            : build?.destinations.find(d => d.position === selected) as TrunkData;
    }
);

export const colorSelector = createSelector(
    selectedTrunkSelector,
    sscConnectorTypeRecordSelector,
    sscConnectorColorsSelector,
    (trunk, connectors, connectorColors) => {
        if (trunk && trunk?.groups) {
            const connectorType = trunk.groups.length > 0 ? trunk.groups[0].type : undefined
            const connector = connectorType ? connectors[connectorType] : undefined
            if (connector) {
                return connectorColors[connector.key]
            } else {
                return []
            }
        }
        return []
    }
)

export const triggerColorsInfoSelector = createSelector(
    selectedTrunkSelector,
    sscConnectorTypeRecordSelector,
    sscDefaultTriggersColorSelector,
    (trunk, connectorRecords, defaultColors) => {
        const triggerInfos: ITriggerInfo[] = [];
        if (trunk.groups) {
            for (const group of trunk.groups) {
                const conn = group.connectors[0];
                const key = connectorRecords[conn.type ?? ""].key;
                const defaultColor = defaultColors[key]
                if (conn.groupPosition !== undefined && conn.type) {
                    const color = conn.color ?? defaultColor.name;
                    triggerInfos.push({
                        groupPosition: conn.groupPosition + 1,
                        connectorType: conn.type,
                        color: getConnectorColor(conn.type, color)
                    });
                }
            }
        }
        return triggerInfos;
    }
);

export const buildColorsSelector = createSelector(
    connectorTypesSelector,
    sscConnectorTypeRecordSelector,
    sscConnectorColorsSelector,
    (buildConnectors, connectors, connectorColors) => buildConnectors.reduce((acc, cur) => {
        const connector = connectors[cur.type ?? ""]
        if (connector && connector.type && connectorColors[connector.key] && !acc[connector.type]) acc[connector.type] = connectorColors[connector.key]
        return acc;
    }, {} as Record<string, IColor[]>)
)

export const commonConnectorColorsSelector = createSelector(
    buildColorsSelector,
    (connectorColors) => {
        const connectorTypes = Object.keys(connectorColors);
        const minColors: IColor[] = Object.values(connectorColors).reduce((acc, colors) => {
            if (!colors || (acc.length && acc.length < colors.length)) return acc;
            return colors;
        }, new Array<IColor>())

        const commonColors = minColors.filter(color => connectorTypes.some(connectorType =>
            connectorColors[connectorType].some((connectorColor) => connectorColor.id === color.id)));
        return commonColors;
    }
)

export const trunksSelectorFactory = (initial: IPoint, offset?: IPoint) => {
    return createSelector(
        currentBuildSelector,
        unitsOfMeasureContainerUnitSelector,
        fiberTypeTrunkColorSelector,
        (build, unit, fiberTypeTrunkColor) => getTrunks(build, unit, fiberTypeTrunkColor.id, initial, offset)
    )
}

export const getTrunks = (build: IBuildData | undefined, unit: Unit, fiberTypeTrunkColor: string, initial: IPoint, offset?: IPoint) => {
    const trunks: TrunkProps[] = [];
    if (build) {
        const trunkData: TrunkData[] = [build.source, ...build.destinations];
        if (trunkData.length > 0) {

            let x = initial.x;
            let y = initial.y;

            const feederNbConnectors = getNbConnectors(build.source.groups);
            const feederIsCollapsed = (build.source.isCollapsed && feederNbConnectors > 2 && !build.source.customBLength) ?? false;
            const feederWidth = getFeederWidth(feederNbConnectors, feederIsCollapsed);
            if (offset) {
                x += offset.x;
                y += offset.y;
            }

            let dstPoint: IPoint = { x: x + feederWidth - 19, y: y - 16 };
            for (let i = 0; i < trunkData.length; i++) {
                const data = trunkData[i];
                const availability = build.availability ?? { sourceEnabled: false, enabledDestinations: [] };
                const trunkColor = fiberTypeTrunkColor;

                const { sourceEnabled, enabledDestinations } = availability;
                const enabled = data.position ? enabledDestinations.includes(data.position) : sourceEnabled;
                let trunkContext: ITrunkContext = getTrunkContext(data, unit, enabled, trunkColor);

                const { section, position, isCollapsed } = trunkContext;
                const legProps: IConnectorLegProps[] = isCollapsed ? getCollapsedGroupLegs(trunkContext) : getExpandedLegProps(trunkContext);

                const { pathStartB, pathB, pathEndB } = createBPaths(trunkContext, legProps);
                const staggerMarkerPaths = createStaggerMarkerPaths(trunkContext, legProps);
                const staggerLinePaths = createStaggerLinePaths(trunkContext, legProps);

                let markerProps: IMarkerProps = { pathStartB, pathB, pathEndB, staggerMarkerPaths, staggerLinePaths };
                if (position < trunkData.length && position > 0) {
                    const curr = trunkContext;
                    const prev = trunks[i - 1];
                    const prevWidth = MetaTAPTrunkTexture.width + getHorizontalTrunkWidth(prev.trunkContext.nbConnectors, prev.trunkContext.isCollapsed) - 24;
                    const x2 = position === 1 ? initial.x + (offset ? offset.x : 0) : dstPoint.x - prevWidth;

                    const distance = getDistance(prev, x2, curr, x, feederWidth);
                    const { pathA, pathEndA } = createAPaths(prev.trunkContext, trunkContext, distance);
                    markerProps = { ...markerProps, pathA, pathEndA };
                }

                if (section === Drop) {
                    if (position > 1) {
                        const prevTrunk = trunks[i - 1].trunkContext;
                        const width = MetaTAPTrunkTexture.width + getHorizontalTrunkWidth(prevTrunk.nbConnectors, prevTrunk.isCollapsed) - 24;
                        dstPoint.x = dstPoint.x + width;
                    }
                    x = dstPoint.x;
                    y = dstPoint.y;
                }

                trunkContext.markerProps = markerProps;
                trunks.push({ key: `cable-trunk-${i}`, x, y, trunkContext, legProps });
            }
        }
    }

    return trunks;
};

export const DropToTrunkProps = (previous: TrunkData, current: TrunkData, unit: Unit, trunkColor: string, coordinates: IPoint, offset?: IPoint): TrunkProps => {
    let { x, y, trunkContext: curr, legProps } = ToTrunkProps(current, unit, trunkColor, coordinates, offset);

    if (curr.position > 0) {
        const w2: IPoint = curr.wamLocation ?? IPointEmpty;
        const f2: IPoint = curr.furcationPoint ?? IPointEmpty;

        const prev: ITrunkContext = getTrunkContext(previous, unit, true, trunkColor);
        const f1: IPoint = prev.furcationPoint ?? IPointEmpty;
        const { width, height } = curr.position === 1 ? MetaSourceTrunkExternalTexture : MetaTAPTrunkTexture;
        const y = w2.y - 50;

        const offsetX = (getHorizontalTrunkWidth(prev.nbConnectors, prev.isCollapsed) - 24) + width;
        const startX = f1.x - offsetX;
        const pathStartA: IPath = curr.position === 1
            ? { start: { x: startX - 60, y: y + 36 + height }, end: { x: startX - 30, y: y + 36 + height } }
            : { start: { x: startX, y: y + 22 }, end: { x: startX, y: y - 10 } };

        const text: ITextPath = { text: `A${prev.position}:${prev.lengthA.value}`, position: "middle" };
        const pathA: IPath = curr.position === 1
            ? { start: { x: startX - 40, y: y + 36 + height }, end: { x: f2.x, y }, text }
            : { start: { x: startX, y }, end: { x: f2.x, y }, text };

        const pathEndA: IPath = { end: { x: f2.x, y: y + 22 }, start: { x: f2.x, y: y - 10 } };

        const { markerProps } = curr;
        curr = { ...curr, markerProps: { ...markerProps, pathStartA, pathA, pathEndA } };
    }

    return { x, y, trunkContext: curr, legProps };

}

export const ToTrunkProps = (data: TrunkData, unit: Unit, trunkColor: string, coordinates: IPoint, offset?: IPoint): TrunkProps => {
    const x = coordinates.x + (offset ? offset.x : 0);
    const y = coordinates.y + (offset ? offset.y : 0);
    let trunkContext: ITrunkContext = getTrunkContext(data, unit, true, trunkColor);
    const legProps: IConnectorLegProps[] = trunkContext.isCollapsed ? getCollapsedGroupLegs(trunkContext) : getExpandedLegProps(trunkContext);

    const { pathStartB, pathB, pathEndB } = createBPaths(trunkContext, legProps);
    const staggerLinePaths = createStaggerLinePaths(trunkContext, legProps);
    const staggerMarkerPaths = createStaggerMarkerPaths(trunkContext, legProps);
    const markerProps: IMarkerProps = { pathStartB, pathB, pathEndB, staggerLinePaths, staggerMarkerPaths };

    trunkContext = { ...trunkContext, markerProps };

    return { x, y, trunkContext, legProps };
}

export const getDistance = (prev: TrunkProps, x1: number, curr: ITrunkContext, x2: number, feederOffset: number) => {
    const position = curr.position ?? 0;
    const prevTrunk = prev.trunkContext;
    const prevLegs = prev.legProps;

    const f1 = prevTrunk.furcationPoint ?? IPointEmpty;
    const originX1 = x1 + f1.x;
    const legDistance = f1.x - prevLegs[0].location.x;

    const f2 = curr.furcationPoint ?? IPointEmpty;
    const originX2 = x2 + f2.x;

    const furcationDistance = originX2 - originX1;
    const distance = position === 1 ? f2.x - furcationDistance - feederOffset + 19 - legDistance : furcationDistance;

    return distance;
}

export const getTrunkContext = (data: TrunkData, unit: Unit, enabled: boolean, trunkColor: string): ITrunkContext => {
    const section = data.position ? Drop : Feeder;
    const position = data.position ?? 0;
    const groups = getGroupsWithIndex(data);
    const nbGroups = groups.length;
    const nbConnectors = getNbConnectors(groups);
    const isCollapsed = (data.isCollapsed && nbConnectors > 2 && !data.customBLength) ?? false;
    const wamLocation = getWAMLocation(nbConnectors, isCollapsed);
    const furcationPoint = getFurcationPoint(section, wamLocation);
    const anchorHeight = getAnchorHeight(section);
    const connectorAnchorPoint = getConnectorAnchorPoint(section, furcationPoint);
    const connectorType = groups[0].type ?? "";

    const defaultLength = Stagger0.value;
    const stagger = groups[0].stagger ?? defaultLength;

    const verticalTrunkSpriteProps: SpriteProps | undefined = section === Drop ? { ...getVerticalTapLocation(wamLocation), color: trunkColor } : undefined;

    return {
        section,
        position,
        unit,
        lengthA: data.lengthA ?? defaultLength,
        lengthB: data.lengthB ?? defaultLength,
        customBLength: data.customBLength ?? false,
        groups,
        isCollapsed,
        enabled,
        connectorType,
        stagger,
        nbConnectors,
        nbGroups,
        anchorHeight,
        furcationPoint,
        connectorAnchorPoint,
        wamLocation,
        verticalTrunkSpriteProps,
        markerProps: {}
    }
};

export const createAPaths = (prev: ITrunkContext, curr: ITrunkContext, length: number) => {
    const { position, furcationPoint, wamLocation } = curr;

    const wam: IPoint = wamLocation ?? IPointEmpty;
    const furcation: IPoint = furcationPoint ?? IPointEmpty;

    const isFeederLC = prev.connectorType === ConnLC.type;
    const markerStartB = position > 1 ? DropMarkStartB : (isFeederLC ? ExternalSourceMarkStartB : InternalSourceMarkStartB);

    const y = wam.y - 50;
    const text: ITextPath = { text: `A${prev.position}:${convertToDisplay(prev.lengthA, prev.unit)} ${prev.unit}`, position: "middle" };
    const pathStartA: IPath = { start: { x: furcation.x, y: y }, end: { x: furcation.x - length, y } };
    const pathA: IPath = position === 1
        ? { start: { x: length - (prev.isCollapsed ? 100 : 10), y: markerStartB.start.y + 16 }, end: { x: furcation.x, y }, text }
        : { start: { x: furcation.x - length, y }, end: { x: furcation.x, y }, text };
    const pathEndA: IPath = { start: { x: furcation.x, y: y + 22 }, end: { x: furcation.x, y: y - 10 } };

    return { pathStartA, pathA, pathEndA, text };
};

export const createBPaths = (curr: ITrunkContext, legs: IConnectorLegProps[]) => {
    const { position, section, unit, connectorType, lengthB, isCollapsed, customBLength, groups } = curr;
    const isLC = connectorType === ConnLC.type;
    const markerStartB = section === Feeder ? (isLC ? ExternalSourceMarkStartB : InternalSourceMarkStartB) : DropMarkStartB;

    const { height } = getConnectorTexture(connectorType);
    const offsetX = isCollapsed && legs.length > 2 ? 40 : 10;
    const startX = Math.min(...legs.map(m => m.location.x)) - offsetX;
    const endX = isCollapsed ? startX + 10 : legs[0].location.x;

    const pathStartB: IPath = { start: { x: startX - 10, y: markerStartB.start.y }, end: { x: endX, y: markerStartB.start.y } };

    const bY = legs[0].location.y + height;
    const bLength = markerStartB.start.y - bY;
    const pathB: IPath = { start: { x: startX, y: bY }, end: { x: startX, y: bY + bLength } };

    let lengthBValue = convertToDisplay(lengthB, unit);
    if (customBLength && groups.length > 0) {
        lengthBValue = convertToDisplay(groups[0].lengthB ?? Stagger0.value, unit);
    }

    const text: ITextPath = { text: `B${position}: ${lengthBValue} ${unit}`, position: "start" };
    const pathEndB: IPath = { start: { x: startX - 10, y: bY }, end: { x: endX, y: bY }, text };

    return { pathStartB, pathB, pathEndB };

};

export const createStaggerMarkerPaths = (curr: ITrunkContext, legProps: IConnectorLegProps[]): IPath[] => {
    const { connectorType, stagger, customBLength, isCollapsed } = curr;
    if (stagger.value === 0 && !customBLength) return [];

    const { height } = getConnectorTexture(connectorType);

    const isLC = connectorType === ConnLC.type;
    const yOffset = isCollapsed && isLC ? 36 : undefined;

    return isCollapsed
        ? getCollapsedStaggerMarkers(height, curr, legProps, yOffset)
        : getExpandedStaggerMarkers(height, curr, legProps);
};

export const getCollapsedStaggerMarkers = (height: number, context: ITrunkContext, legProps: IConnectorLegProps[], yOffset?: number): IPath[] => {
    if (legProps.length < 2) return [];

    const offsetX = legProps.length > 2 ? 40 : 10;
    const x1 = Math.min(...legProps.map(m => m.location.x)) - offsetX;
    const y1 = legProps[legProps.length * 0.5].location.y + height * 2 + (yOffset ?? 0);
    const y2 = legProps[0].location.y + height + 1;

    const start: IPoint = { x: x1, y: y1 };
    const end: IPoint = { x: x1, y: y2 };

    const { unit, nbGroups, stagger } = context;
    const text: ITextPath = { text: `(${nbGroups - 1} x ${convertToDisplay(stagger, unit)} ${unit})`, position: "middle" };

    return [{ start, end, text }];
}

export const getExpandedStaggerMarkers = (height: number, context: ITrunkContext, legProps: IConnectorLegProps[]) => {
    const { nbGroups, nbConnectors } = context;
    if (nbGroups <= 1 && legProps.length === 0) return [];

    const connectorsPerGroup = nbConnectors / nbGroups;
    const filteredLegs = [...legProps].filter((m, i, self) => self.findIndex(s => s.staggerPosition === m.staggerPosition) === i);
    const nbStaggerLines = filteredLegs.length - 1;
    const x1 = Math.min(...legProps.map(m => m.location.x)) - 10;

    const staggerMarkerPaths: IPath[] = [];
    for (let curr = 0; curr < nbStaggerLines; curr++) {
        const next = curr + 1;

        const currStagger = STAGGER_OFFSET_Y * curr;
        const y2 = legProps[curr * connectorsPerGroup].location.y + currStagger + height;

        const nextStagger = next * STAGGER_OFFSET_Y;
        const y1 = legProps[next * connectorsPerGroup - 1].location.y + nextStagger + height;

        const start: IPoint = { x: x1, y: y1 };
        const end: IPoint = { x: x1, y: y2 };

        staggerMarkerPaths.push({ start, end });
    }

    return staggerMarkerPaths;
}

export const createStaggerLinePaths = (context: ITrunkContext, legProps: IConnectorLegProps[]): IPath[] => {
    const { connectorType, stagger, customBLength, isCollapsed } = context;
    if (stagger.value === 0 && !customBLength) return [];

    const { height } = getConnectorTexture(connectorType);
    const isLC = connectorType === ConnLC.type
    const yOffset = isCollapsed && isLC ? 36 : undefined;

    return isCollapsed
        ? getCollapsedStaggerLines(height, context, legProps, yOffset)
        : getExpandedStaggerLines(height, context, legProps);
}

export const getCollapsedStaggerLines = (height: number, context: ITrunkContext, legProps: IConnectorLegProps[], yOffset?: number) => {
    if (legProps.length < 2) return [];

    const offsetX = legProps.length > 2 ? 110 : 20;
    const x1 = legProps[0].location.x - offsetX;
    const index = legProps.length * 0.5;
    const x2 = legProps[index].location.x;
    const y1 = legProps[index].location.y + height * 2 + (yOffset ?? 0);

    const start: IPoint = { x: x1, y: y1 };
    const end: IPoint = { x: x2, y: y1 };

    const { unit, lengthB, stagger, nbGroups } = context;
    const length: IUnitOfMeasure = { ...lengthB, value: lengthB.value + stagger.value * (nbGroups - 1) };
    const text: ITextPath = { text: `${convertToDisplay(length, unit)} ${unit}`, position: "start" };

    return [{ start, end, text }];
}

export const getExpandedStaggerLines = (height: number, context: ITrunkContext, legProps: IConnectorLegProps[]) => {
    if (legProps.length === 0) return [];

    const { unit, lengthB, stagger, customBLength, groups } = context;
    const filteredLegs = [...legProps].filter((m, i, self) => self.findIndex(s => s.staggerPosition === m.staggerPosition) === i);
    const nbStaggerLines = filteredLegs.length - 1;
    const x1 = legProps[0].location.x - 20;
    const staggerLinePaths: IPath[] = [];

    for (let i = 0; i < nbStaggerLines; i++) {
        const index = i + 1;
        const leg = filteredLegs[index]
        const staggerOffset = STAGGER_OFFSET_Y * leg.staggerPosition;
        const y1 = leg.location.y + staggerOffset + height;
        const x2 = leg.location.x;

        const start: IPoint = { x: x1, y: y1 };
        const end: IPoint = { x: x2, y: y1 };

        const length: IUnitOfMeasure = customBLength && groups.length > 0
            ? groups[index].lengthB ?? Stagger0.value
            : { ...lengthB, value: lengthB.value + stagger.value * leg.staggerPosition }

        const text: ITextPath = { text: `${convertToDisplay(length, unit)} ${unit}`, position: "start" };
        staggerLinePaths.push({ start, end, text });
    }

    return staggerLinePaths;
}

export const getCollapsedGroupLegs = (trunkContext: ITrunkContext): IConnectorLegProps[] => {
    const { section, stagger, connectorType, nbConnectors, nbGroups, groups } = trunkContext;
    const connectorsPerGroup = nbConnectors / nbGroups;
    const nbLegs = nbGroups >= 2 && nbConnectors > 4 ? 4 : 2;
    const outerStepIndex = nbLegs > 2 ? 1 : 0;
    const leftCount = Math.floor(nbLegs * 0.5);

    const connectorAnchorPoint = trunkContext.connectorAnchorPoint ?? IPointEmpty;
    const furcationPoint = trunkContext.furcationPoint ?? IPointEmpty;
    const anchorHeight = trunkContext.anchorHeight ?? 0;

    const connectors = groups.flatMap(g => g.connectors);
    const legs: IConnectorLegProps[] = [];
    for (let i = 1; i < nbLegs + 1; i++) {
        let x: number;
        let workspaceIndex: number;
        let connectorIndex: number;
        let connectorColor: string | undefined;
        if (i <= leftCount) {
            const offset = i % 2 === 0 ? (i + outerStepIndex) * CONNECTOR_STEP : i * CONNECTOR_STEP;
            x = furcationPoint ? furcationPoint.x - offset : 0;
            workspaceIndex = COLLAPSED_FIRST_GROUPINDEX;
            connectorIndex = i === leftCount ? 0 : connectorsPerGroup - 1;
            connectorColor = connectors[connectorIndex].color;
        } else {
            const offset = i % 2 === 0 ? (i - leftCount + outerStepIndex) * CONNECTOR_STEP : (i - leftCount) * CONNECTOR_STEP;
            x = furcationPoint ? furcationPoint.x + offset : 0;
            workspaceIndex = COLLAPSED_LAST_GROUPINDEX;
            connectorIndex = i === nbLegs ? nbConnectors - 1 : nbConnectors - connectorsPerGroup;
            connectorColor = connectors[connectorIndex].color;
        }

        const offsetY = section === Drop ? 18.5 : 0;
        const location: IPoint = connectorAnchorPoint ? { x, y: connectorAnchorPoint.y + offsetY } : { x, y: offsetY };
        const legEndpoint: IPoint = furcationPoint ? { x, y: furcationPoint.y + offsetY } : { x, y: offsetY };
        
        legs.push({
            staggerPosition: getStaggerPosition(stagger, workspaceIndex - 1),
            location: getSpriteLocation(connectorType, location, anchorHeight),
            legEndpoint,
            text: (connectorIndex + 1).toString(),
            connectorPosition: connectorIndex,
            connectorColor,
        });
    }

    return legs.sort((a, b) => a.staggerPosition >= b.staggerPosition ? 1 : -1);
}

export const getExpandedLegProps = (trunkContext: ITrunkContext): IConnectorLegProps[] => {
    const { section, stagger, customBLength, connectorType, nbConnectors, groups } = trunkContext;

    const connectorAnchorPoint = trunkContext.connectorAnchorPoint ?? IPointEmpty;
    const furcationPoint = trunkContext.furcationPoint ?? IPointEmpty;
    const anchorHeight = trunkContext.anchorHeight ?? 0;
    const leftCount = Math.floor(nbConnectors * 0.5);

    const connectors = groups.flatMap(g => g.connectors);
    return connectors.map((c, i) => {
        const location: IPoint = {
            x: connectorAnchorPoint.x + (i - leftCount) * CONNECTOR_STEP,
            y: connectorAnchorPoint.y
        };

        const offsetY = section === Drop ? 18.5 : 0;
        const legEndpoint: IPoint = {
            x: furcationPoint.x + (i - leftCount) * CONNECTOR_STEP,
            y: furcationPoint.y + offsetY
        };

        return {
            staggerPosition: getStaggerPosition(stagger, c.groupIndex, customBLength),
            location: getSpriteLocation(connectorType, location, anchorHeight),
            legEndpoint,
            text: (i + 1).toString(),
            connectorPosition: i,
            connectorColor: c.color,
        }
    }).sort((a, b) => a.staggerPosition >= b.staggerPosition ? 1 : -1);
}

export const getBounds = (x: number, y: number, width: number, height: number): Rectangle => {
    return { x, y, width, height };
}