import { BuildDocument } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";

export const setDocumentAction = (state: BuildDocument, action: PayloadAction<BuildDocument>) => {
    const doc = action.payload;
    
    state.notes = doc.notes;
    state.date = doc.date;
    state.drawn = doc.drawn;
    state.revision = doc.revision;
    state.siteLocation = doc.siteLocation;
    state.overview = doc.overview;
    state.feeder = doc.feeder;
    state.drops = doc.drops;
    state.polarity = doc.polarity;
}