import { IPoint } from '../../../types';
import { initialState as initialResizeState, PageResizeArgs } from '../../../../workspace/components/overlay/components/footer/components/resize/types';
import { initialState as initialZoomState } from '../../../../workspace/components/overlay/components/footer/components/zoom/types';

export const ViewportStatus = {
    Inactive: 'inactive',
    Active: 'active',
    Editing: 'editing',
    Creating: 'creating'
} as const

export type ViewportStatusTypes = typeof ViewportStatus [keyof typeof ViewportStatus];

export interface IViewportState {
    isDragging: boolean,
    context: ViewportStatusTypes
    position: IPoint,
    scale: IPoint,
    resize: PageResizeArgs,
    scaleIncrement: number,
    readonly minScale: number
}

export const initialState: IViewportState = {
    isDragging: false,
    context: ViewportStatus.Inactive,
    position: { x: 819.8495, y: -73.0261 },
    scale: initialZoomState.zoom,
    resize: initialResizeState.resize,
    scaleIncrement: 0,
    minScale: 0.2
}