import { Container } from '@inlet/react-pixi';
import { CableProps } from './types';
import { Trunk } from '../trunk/Trunk';

export const Cable: React.FC<CableProps> = ({ display, trunks }: CableProps) => {
    if (!display) {
        return null;
    }

    return (
        <Container name="eds-container">
            { trunks.map((t, i) => <Trunk key={t.key} {...t} /> ) }
        </Container>
    );
}