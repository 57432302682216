import { INavigationBarProps } from "./types";
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from "../../locales/types";
import { useCallback, useMemo } from "react";
import { setCurrentIndex } from "./store/reducer";
import { IToolItemProps } from "../../workspace/components/overlay/ToolItem";

export const useNavigationBar = (props: INavigationBarProps) => {
    const { context, displayThreshold, onFirstClick, onPreviousClick, buttons, onNextClick, onLastClick } = props;
    const { state, dispatch } = context;
    const { currentIndex, disabled } = state;
    const { t } = useTranslation();

    const onFirstCallback = useCallback(() => {
        if (onFirstClick) {
            onFirstClick();
        }
        dispatch(setCurrentIndex(0));
    }, [onFirstClick, dispatch]);

    const onPreviousCallback = useCallback(() => {
        if (onPreviousClick) {
            onPreviousClick();
        }
        dispatch(setCurrentIndex(currentIndex - 1));
    }, [onPreviousClick, dispatch, currentIndex]);

    const onNextCallback = useCallback(() => {
        if (onNextClick) {
            onNextClick();
        }
        dispatch(setCurrentIndex(currentIndex + 1));
    }, [onNextClick, dispatch, currentIndex]);

    const onLastCallback = useCallback(() => {
        if (onLastClick) {
            onLastClick();
        }
        if (buttons && buttons.length > 0) {
            dispatch(setCurrentIndex(buttons.length - 1));
        }
    }, [onLastClick, buttons, dispatch]);

    const labelBottom = true;
    const firstPreviousDisabled = currentIndex === 0 || disabled;
    const firstProps: IToolItemProps = {
        icon: "first_page",
        disabled: firstPreviousDisabled,
        tooltip: t(LocalizationKeys.First),
        labelBottom,
        onClick: onFirstCallback
    }
    const previousProps: IToolItemProps = {
        icon: "chevron_left",
        disabled: firstPreviousDisabled,
        tooltip: t(LocalizationKeys.Previous),
        labelBottom,
        onClick: onPreviousCallback
    }
    const nextLastDisabled = buttons ? currentIndex + 1 === buttons.length : disabled;
    const nextProps: IToolItemProps = {
        icon: "chevron_right",
        disabled: nextLastDisabled,
        tooltip: t(LocalizationKeys.Next),
        labelBottom,
        onClick: onNextCallback
    }
    const lastProps: IToolItemProps = {
        icon: "last_page",
        disabled: nextLastDisabled,
        tooltip: t(LocalizationKeys.Last),
        labelBottom,
        onClick: onLastCallback
    }
    const thresholdProps: IToolItemProps = {
        className: "threshold",
        icon: "more_horiz",
        disabled: true
    }

    let showPreviousThreshold = false;
    let showNextThreshold = false;
    if (displayThreshold && buttons && buttons.length > displayThreshold) {
        showPreviousThreshold = currentIndex + 1 > displayThreshold;
        showNextThreshold = currentIndex < displayThreshold;
    }

    const displayedButtons = useMemo(() => { 
        if (buttons && buttons.length > 0) {
            if (currentIndex >= 0 && buttons[currentIndex]) {
                buttons[currentIndex].className = "selected";
            }

            if (displayThreshold && buttons.length > displayThreshold) {
                const firstChildIndex = Math.floor(currentIndex / displayThreshold) * displayThreshold;
                const lastChildIndex = firstChildIndex + displayThreshold;
                return buttons.slice(firstChildIndex, lastChildIndex);
            } else {
                return buttons;
            }
        }
    }, [buttons, currentIndex, displayThreshold]);
    
    return { firstProps, previousProps, buttons: displayedButtons, nextProps, lastProps, showPreviousThreshold, showNextThreshold, thresholdProps };
}