import React from 'react';
import CorningLogo from "../../resources/Corning_Logo_301Blue.svg";
import MenuIcon from "../../resources/icons/icon_menu.svg";
import { useProjectButton } from './hooks';
import { ToolItem } from '../../../../ToolItem';
import { LocalizationKeys } from '../../../../../../../locales/types';
import { useTranslation } from 'react-i18next';

export const ProjectButton = () => {
    const { t } = useTranslation();
    const { name, catalogCode, disabled, openDrawer } = useProjectButton();

    return (
        <div className='button-container background-blur current-project toggle-pointer-events'>
            <ToolItem source={MenuIcon} alt="Menu" tooltip="Menu" labelBottom={true} onClick={openDrawer} disabled={disabled} />
            <img id="corning-logo" src={CorningLogo} alt={"a corning logo"} width={110} height={16}></img>
            <label className="eds-title">{t(LocalizationKeys.EDSTitle)}</label>
            <>
                <div className="vertical-divider" />
                <label className="title">{name}</label>
                {catalogCode.length ? <label className="sub-title">{catalogCode}</label> : null}
            </>
        </div>
    );
}