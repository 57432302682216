import { Orientation } from "@mui/material";
import { createContext } from "react";
import { IPoint } from "../../../types";
import { ITextPath } from "../text/types";

export const MARKER_FILL_COLOR = 0x000000;
export const MARKER_LINE_WIDTH = 1;
export const MARKER_FONT_SIZE = 32;

export const REPORT_MARKER_FILL_COLOR = 0x000000;
export const REPORT_MARKER_LINE_WIDTH = 1.5;
export const REPORT_MARKER_FONT_SIZE = 32;

export interface IPath {
    start: IPoint,
    end: IPoint,
    text?: ITextPath
};

export interface IMarkerProps {
    pathStartA?: IPath;
    pathA?: IPath;
    pathEndA?: IPath;
    pathStartB?: IPath;
    pathB?: IPath;
    pathEndB?: IPath;
    staggerLinePaths?: IPath[];
    staggerMarkerPaths?: IPath[];
};

export interface IMarkerStyleContext {
    color: number,
    thickness: number,
    fontSize: number,
    displayA: boolean
}

export const MarkerStyleContext = createContext<IMarkerStyleContext>({ color: MARKER_FILL_COLOR, thickness: MARKER_LINE_WIDTH, fontSize: MARKER_FONT_SIZE, displayA: true})

export const overviewReportStyle: IMarkerStyleContext = {
    color: REPORT_MARKER_FILL_COLOR,
    thickness: REPORT_MARKER_LINE_WIDTH,
    fontSize: REPORT_MARKER_FONT_SIZE,
    displayA: true,
}

export const feederReportStyle: IMarkerStyleContext = {
    color: REPORT_MARKER_FILL_COLOR,
    thickness: REPORT_MARKER_LINE_WIDTH,
    fontSize: REPORT_MARKER_FONT_SIZE + 8,
    displayA: false,
}

export const dropReportStyle: IMarkerStyleContext = {
    color: REPORT_MARKER_FILL_COLOR,
    thickness: REPORT_MARKER_LINE_WIDTH + 0.5,
    fontSize: REPORT_MARKER_FONT_SIZE + 8,
    displayA: false,
}

export const defaultStyle: IMarkerStyleContext = {
    color: MARKER_FILL_COLOR,
    thickness: MARKER_LINE_WIDTH,
    fontSize: MARKER_FONT_SIZE,
    displayA: true,
}

export const InternalFeederBPath: IMarkerPath = { start: { x: -112, y: 130 } , length: 354.5 };
export const ExternalFeederBPath: IMarkerPath = { start: { x: -112, y: 88 }, length: 439 };
export const FeederSPath: IMarkerPath = { start: { x: -112, y: 308.5 } , length: 47.5 };

export const SourceMarkStartA: IMarkerPath = { start: { x: -230, y: 260 }, length: 500, orientation: 'horizontal' }
export const SourceMarkEndA: IMarkerPath = { start: { x: 508.5, y: -46 }, length: 31, orientation: 'vertical' }
export const InternalSourceMarkStartB: IMarkerPath = { start: { x: -230, y: 258 }, length: 230, orientation: 'horizontal' }
export const ExternalSourceMarkStartB: IMarkerPath = { start: { x: -230, y: 174 }, length: 230, orientation: 'horizontal' }
export const SourceMarkEndB: IMarkerPath = { start: { x: -230, y: 616 }, length: 228, orientation: 'horizontal' }
export const SourceMarkEndS: IMarkerPath = { start: { x: -230, y: 666 }, length: 25, orientation: 'horizontal' }

export const DropAPath: IMarkerPath = { start: { x: 150, y: -10 } , length: 508 };
export const DropBPath: IMarkerPath = { start: { x: 36, y: 26 } , length: 578 };
export const DropSPath: IMarkerPath = { start: { x: 36, y: 316 } , length: 47.5 };

export const DropMarkStartA: IMarkerPath = { start: { x: 300, y: -30 }, length: 31, orientation: 'vertical' }
export const DropMarkEndA: IMarkerPath = { start: { x: 749.5, y: -30 }, length: 31, orientation: 'vertical' }
export const DropMarkStartB: IMarkerPath = { start: { x: 65, y: 50 }, length: 130, orientation: 'horizontal' }
export const DropMarkEndB: IMarkerPath = { start: { x: 65, y: 631 }, length: 172, orientation: 'horizontal' }
export const DropMarkEndS: IMarkerPath = { start: { x: 65, y: 681 }, length: 25, orientation: 'horizontal' }

export interface IMarkerText {
    textA?: string,
    textB?: string,
    textS?: string,
}

export interface IMarkerDisplay {
    displayS?: boolean,
    displayA?: boolean
}

export interface IMarkerPath {
    start: IPoint;
    length: number,
    orientation?: Orientation
}

export const Markers = "markers-container";