import { ConnLC, ConnMMC16_NP, ConnMTP12_NP, ConnMTP24_NP, ConnMTP8_NP, IConnectorType } from "../../../../../components/overlay/components/wizard/redux/types";

export interface IFiberMapData {
    key: string;
    name: string;
    fiberCount: number;
    sourceIndices: IFiberMapIndex[];
    destinationIndices: IFiberMapIndex[];
}

export interface IConnectorModel {
    id: string;
    index: number;
    name?: string;
    fiberCount: number;
    fiberIndices: IFiberMapIndex[];
}

export interface IFiberMapIndex {
    id?: number,
    index: number;
    assignedIndex: number;
    transceiver?: number;
    connector?: IConnectorModel;
    connectorType?: string;
}

export interface IDocumentFiberMap {
    id?: number;
    sessionId: string;
    name: string;
    accessPointIndices: number[];
}

export type CustomFiberMapData = {
    id?: number,
    userId?: number,
    groupId?: number,
    versionDate?: string,
} & IFiberMapData;

export const AssignedIndexCodes = {
    Unassigned: -1,
    Blocked: -2,
} as const;

export type AssignedIndexCode = typeof AssignedIndexCodes[keyof typeof AssignedIndexCodes]
export const Tx = 0;
export const Rx = 1;

export function connectorModelToConnectorType(model: IConnectorModel | undefined): IConnectorType | undefined {
    if (!model) return undefined
    switch (model.fiberCount) {
        case 24:
            return ConnMTP24_NP
        case 16:
            return ConnMMC16_NP
        case 12:
            return ConnMTP12_NP
        case 8:
            return ConnMTP8_NP
        case 2:
            return ConnLC
        default:
            break;
    }
}

export function connectorTypeToConnectorModel(connectorType: IConnectorType) : IConnectorModel | undefined{
    return {
        fiberCount: connectorType.fiberCount === 8 ? 12 : connectorType.fiberCount,
        id: connectorType.description || "",
        fiberIndices: [],
        index: -1
    }
}