import { IDialogContext } from "../../../workspace/redux/dialog/types";

export interface IColor {
    id: string;
    hex: string;
    name: string;
}

export const AQ = "AQ";
export const BK = "BK";
export const BL = "BL";
export const BR = "BR";
export const GR = "GR";
export const OR = "OR";
export const RD = "RD";
export const RS = "RS";
export const SL = "SL";
export const VI = "VI";
export const WH = "WH";
export const YL = "YL";
export const MS = "MS";
export const LM = "LM";
export const BG = "BG";
export const OV = "OV";
export const MG = "MG";

export const Blue: IColor = { id: BL, hex: "#045EAA", name: "Blue" };
export const Orange: IColor = { id: OR, hex: "#F26623", name: "Orange" };
export const Green: IColor = { id: GR, hex: "#299145", name: "Green" };
export const Brown: IColor = { id: BR, hex: "#754C29", name: "Brown" };
export const Slate: IColor = { id: SL, hex: "#808285", name: "Slate" };
export const White: IColor = { id: WH, hex: "#F1F1F2", name: "White" };
export const Red: IColor = { id: RD, hex: "#C42032", name: "Red" };
export const Black: IColor = { id: BK, hex: "#231F20", name: "Black" };
export const Yellow: IColor = { id: YL, hex: "#F9ED3C", name: "Yellow" };
export const Violet: IColor = { id: VI, hex: "#662D91", name: "Violet" };
export const Rose: IColor = { id: RS, hex: "#F0BCD7", name: "Rose" };
export const Aqua: IColor = { id: AQ, hex: "#89CADF", name: "Aqua" };
export const Mustard: IColor = { id: MS, hex: "#E3A433", name: "Mustard" };
export const Lime: IColor = { id: LM, hex: "#2FEA36", name: "Lime" };
export const Beige: IColor = { id: BG, hex: "#BEAF79", name: "Beige" };
export const Olive: IColor = { id: OV, hex: "#777731", name: "Olive" };
export const Magenta: IColor = { id: MG, hex: "#FF00FF", name: "Magenta" };

export const getTextColor = (color: IColor): IColor => {
    switch (color) {
        case White:
        case Yellow:
        case Rose:
        case Aqua:
            return Black;
        default:
            return White;
    }
}

export const hexStringToNumber = (hex: string) => parseInt(hex.slice(1), 16);

export interface IColorDialogProps {
    context: IDialogContext;
    preventOutsideDismiss?: boolean;
    currentColor?: IColor;
    colors: IColor[];
    onColorButtonClick: (color: IColor) => void;
    onResetButtonClick?: () => void;
    className?: string;
    displayFooter: boolean;
}

export interface IColorButtonProps {
    onClick: (color: IColor) => void;
    color: IColor;
    selected: boolean;
}