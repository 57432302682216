import { createSlice } from "@reduxjs/toolkit";
import { initialDialogState, initialDialogContext } from "./types";
import { collapseDialogAction, setDialogStateAction, showDialogAction } from "./actions";
import { createContext } from "react";

const dialogSlice = createSlice({
    name: "dialog",
    initialState: { ...initialDialogState },
    reducers: {
        setDialogState: setDialogStateAction,
        showDialog: showDialogAction,
        collapseDialog: collapseDialogAction,
    }
});

export const DialogReducer = dialogSlice.reducer;
export const { setDialogState, showDialog, collapseDialog } = dialogSlice.actions;

export const DialogContext = createContext(initialDialogContext);