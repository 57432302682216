import { SelectChangeEvent } from "@mui/material";
import { TextFieldProps } from "@orbit/text-field";
import { MainPalettes } from "@orbit/theme-provider";
import { ChangeEvent, KeyboardEvent, useCallback, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../../../../../locales/types";
import { setViewportContext } from "../../../../../../../../../pixi/components/viewport/redux/reducers";
import { ViewportStatus } from "../../../../../../../../../pixi/components/viewport/redux/types";
import { setBuildLoaded, setCurrentBuild } from "../../../../../../../../redux/build/reducers";
import { IBuildData } from "../../../../../../../../redux/build/types";
import { useWizardPreset } from "../../../../../../../../redux/build/wizard/wizard-preset/hooks";
import { totalBuildCountSelector } from "../../../../../../../../redux/project-manager/selectors";
import { AppDispatch } from "../../../../../../../../redux/reducers";
import { useSscConfigSession } from "../../../../../../../../redux/ssc/hooks";
import { setSessionWarnings, storeBuildSession } from "../../../../../../../../redux/ssc/reducer";
import { buildsPageIndexSelector } from "../../../../../../../../selectors/build.selectors";
import { currentBuildSelector, projectManagerDataSelector } from "../../../../../../../../selectors/root.selectors";
import { ProjectManagerService } from "../../../../../../../../services/projectmanager-service";
import { setResize } from "../../../../../footer/components/resize/legacy/redux/reducers";
import { PageResizeEnum } from "../../../../../footer/components/resize/legacy/redux/types";
import { useWorkspace } from "../../../../../header/components/status/hooks";
import { setStatusState } from "../../../../../header/components/status/redux/reducer";
import { WorkspaceStatus } from "../../../../../header/components/status/redux/types";
import { saveBuildData } from "../../../../../wizard/redux/reducers";
import { updateSearch, updateSortOrder, updateSortType } from "../../../../redux/reducers";
import { useProjectDrawer } from "../../hooks";
import { setReachedLastPage, setReloading, setSearch } from "../../redux/reducers";
import { ProjectDrawerContext } from "../../redux/types";

const SEARCH_DELAY_MS = 300;

export const useDrawerSubHeader = () => {
    const { cableLabel } = useCableLabel();
    const { searchTextFieldProps } = useSearchBar();
    const { sortType, isSortAscending, onSelectChange, triggerSort } = useCableSorter();
    const { triggerCreate } = useCreateButton();

    return { cableLabel, sortType, isSortAscending, searchTextFieldProps, onSelectChange, triggerSort, triggerCreate };
}

export const useSearchBar = () => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(ProjectDrawerContext);
    const [searchField, setSearchField] = useState(state.search);
    const timer = useRef<NodeJS.Timer | undefined>();
    const storeDispatch = useDispatch<AppDispatch>();
    const currentActiveRequest = useRef<ProjectManagerService | undefined>();

    const onSearch = useCallback(async (search: string) => {
        dispatch(setReloading(true));
        await storeDispatch(updateSearch(search, currentActiveRequest));
        if (!currentActiveRequest.current) {
            // Only change loading state when there are no pending search requests
            batch(() => {
                dispatch(setReloading(false));
                dispatch(setReachedLastPage(false));
                dispatch(setSearch(search));
            });
        }
    }, [dispatch, storeDispatch]);

    const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSearchField(e.target.value);
        clearTimeout(timer.current);
        timer.current = setTimeout(() => onSearch(e.target.value), SEARCH_DELAY_MS);
    }, [onSearch, timer]);

    const onKeyDown = useCallback(async (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            await onSearch(searchField);
        }
    }, [onSearch, searchField]);

    const searchTextFieldProps: TextFieldProps = {
        palette: MainPalettes.primary,
        value: searchField,
        placeholder: t(LocalizationKeys.SearchByNameOfDesigner),
        size: "small",
        adornmentPosition: "end",
        autoComplete: "off",
        onChange,
        onKeyDown,
    };

    return { searchTextFieldProps, onSearch }
}

export const useCableLabel = () => {
    const totalBuildCount = useSelector(totalBuildCountSelector);
    const { t } = useTranslation();
    const cableLabel = `${totalBuildCount} ${t(LocalizationKeys.Cables)}`;
    return { cableLabel };
}

export const useCreateButton = () => {
    const currentBuild = useSelector(currentBuildSelector);
    const { setWorkspace } = useWorkspace();
    const { onDrawerClose } = useProjectDrawer();
    const { selectedPresetBuild } = useWizardPreset();
    const { createConfigSession, sscSession } = useSscConfigSession();
    const dispatch = useDispatch();

    const triggerCreate = useCallback(async () => {
        const session = sscSession.sessionId.length ? sscSession : await createConfigSession()
        if (session) {
            batch(() => {
                dispatch(storeBuildSession(currentBuild?.id ?? 0))
                dispatch(setSessionWarnings({ buildId: 0, warnings: [] }));
                dispatch(setViewportContext(ViewportStatus.Creating));
                dispatch(saveBuildData(currentBuild));
                const selectedBuildData: IBuildData = { ...selectedPresetBuild };
                dispatch(setCurrentBuild(selectedBuildData));
                dispatch(setStatusState(WorkspaceStatus.Ready));
                dispatch(setBuildLoaded(true));
                setWorkspace();
                dispatch(setResize({ update: true, value: PageResizeEnum.FitToSource }));
                onDrawerClose();
            });
        }
    }, [currentBuild, selectedPresetBuild, setWorkspace, onDrawerClose, dispatch, createConfigSession, sscSession]);

    return { triggerCreate };
}

export const useCableSorter = () => {
    const { sortType, isSortAscending } = useSelector(projectManagerDataSelector);
    const pageIndex = useSelector(buildsPageIndexSelector);
    const { state: { search }, dispatch } = useContext(ProjectDrawerContext);

    const storeDispatch = useDispatch<AppDispatch>();

    const onSelectChange = useCallback(async (e: SelectChangeEvent<string>) => {
        const newType = e.target.value;
        dispatch(setReloading(true));
        await storeDispatch(updateSortType(newType, pageIndex, search));
        dispatch(setReloading(false));
    }, [dispatch, storeDispatch, pageIndex, search]);

    const triggerSort = useCallback(async () => {
        const newOrder = !isSortAscending;
        dispatch(setReloading(true));
        await storeDispatch(updateSortOrder(newOrder, pageIndex, search));
        dispatch(setReloading(false));
    }, [isSortAscending, dispatch, storeDispatch, pageIndex, search]);

    return { sortType, isSortAscending, onSelectChange, triggerSort };
}

export function areArraysEquals(a: any[], b: any[]) {
    if (a.length !== b.length) {
        return false;
    }

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    return true;
}