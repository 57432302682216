import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { setStatusState } from '../../components/overlay/components/header/components/status/redux/reducer';
import { WorkspaceStatus } from '../../components/overlay/components/header/components/status/redux/types';
import { IUnitOfMeasure } from '../../components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure';
import { setSelectedBuild, showCableDetails } from '../../components/overlay/components/projects/redux/reducers';
import { BuildService, ToBuildData } from '../../services/build-service';
import { ProjectManagerService } from '../../services/projectmanager-service';
import { Identifiable } from '../../services/types';
import { checkResult, createAsyncAction, extractData } from '../actions';
import {
    addBuildReducer, addBuildsReducer, addEnabledDestinationReducer, collapseAllReducer, deleteBuildReducer, expandAllReducer, getBuildReducer, moveDestinationLeftReducer, moveDestinationRightReducer, removeEnabledDestinationReducer, resetPolarityAssignmentReducer, resetPolarityDescriptionReducer, setBuildInfoAction, setBuildLoadedReducer, setBuildsReducer, setCatalogCodeAction, setConfigurationTypeAction, setConnectorAction,
    setCurrentBuildAsymmetricAction, setCurrentBuildReducer, setCurrentBuildSessionIdAction, setCustomBLengthAction, setDestinationsEnabledReducer, setFlameRatingAction, setGroupBLengthAction, setIsCollapsedReducer, setPolarityAssignmentReducer, setPolarityDescriptionReducer, setSourceEnabledReducer, updateAllConnectorsAction, updateAllDestinationsReducer, updateBuildListEntryReducer, updateBuildReducer, updateConnectorGroupsAction, updatedBuildInfoReducer, updateDestinationReducer, updatePositionedConnectorsAction, updateSourceReducer
} from './actions';
import { IConnectorData } from './connector/types';
import { BuildPropagation, IBuildData, IBuildInfo, initialState } from './types';
import { setNotification, setPropagationNotification } from '../../components/overlay/components/notification/store/reducer';
import { LocalizationKeys } from '../../../locales/types';
import { AlertPalettes } from '@orbit/snackbar';
import { t } from 'i18next';
import { PropagationStatuses } from '../../components/overlay/components/polarity/propagation/redux/types';


export const updateConnectorsAsync = createAsyncThunk(
    'build/updateConnectorsAsync',
    async (connectors: IConnectorData[], thunkApi) => {
        const service = new BuildService();
        const res = await service.updateConnectors(connectors);
        return res.succesful && res.data ? res.data.connectors : connectors;
    }
)

const buildSlice = createSlice({
    name: 'build',
    initialState,
    reducers: {
        getBuild: getBuildReducer,
        addBuild: addBuildReducer,
        addBuilds: addBuildsReducer,
        setBuilds: setBuildsReducer,
        updateAllConnectors: updateAllConnectorsAction,
        updatePositionedConnectors: updatePositionedConnectorsAction,
        updateBuildInfo: updatedBuildInfoReducer,
        updateBuild: updateBuildReducer,
        updateBuildListEntry: updateBuildListEntryReducer,
        deleteBuild: deleteBuildReducer,
        setCurrentBuild: setCurrentBuildReducer,
        setSourceEnabled: setSourceEnabledReducer,
        setDestinationEnabled: setDestinationsEnabledReducer,
        addEnabledDestination: addEnabledDestinationReducer,
        removeEnabledDestination: removeEnabledDestinationReducer,
        setupSource: updateSourceReducer,
        setupDestination: updateDestinationReducer,
        setupAllDestinations: updateAllDestinationsReducer,
        moveDestinationLeft: moveDestinationLeftReducer,
        moveDestinationRight: moveDestinationRightReducer,
        setBuildLoaded: setBuildLoadedReducer,
        setIsCollapsed: setIsCollapsedReducer,
        expandAll: expandAllReducer,
        collapseAll: collapseAllReducer,
        setBuildInfo: setBuildInfoAction,
        setConnector: setConnectorAction,
        setCurrentBuildAsymmetric: setCurrentBuildAsymmetricAction,
        updateAllConnectorLabels: updateConnectorGroupsAction,
        setGroupBLength: setGroupBLengthAction,
        setCustomBLength: setCustomBLengthAction,
        setFlameRating: setFlameRatingAction,
        setConfigurationType: setConfigurationTypeAction,
        setCatalogCode: setCatalogCodeAction,
        setPolarityDescription: setPolarityDescriptionReducer,
        resetPolarityDescription: resetPolarityDescriptionReducer,
        setPolarityAssignment: setPolarityAssignmentReducer,
        resetPolarityAssignment: resetPolarityAssignmentReducer,
        setCurrentBuildSessionId: setCurrentBuildSessionIdAction
    },
    extraReducers: (builder) => {
        builder.addCase(updateConnectorsAsync.pending, (state, action) => action.payload && updateAllConnectorsAction(state, { payload: action.payload, type: action.type }))
        builder.addCase(updateConnectorsAsync.fulfilled, (state, action) => action.payload && updateAllConnectorsAction(state, { payload: action.payload, type: action.type }))
    }
});

export const BuildReducer = buildSlice.reducer;

export const getBuild = (id: number) => {
    return createAsyncAction(async (dispatch) => {
        const service = new BuildService();
        const res = await service.getBuild(id);
        const build = extractData(res, dispatch);
        if (build) {
            dispatch(buildSlice.actions.getBuild(build));
        }
    });
};

export const getBuildInfo = (buildId: number) => {
    return createAsyncAction(async (dispatch) => {
        const res = await new BuildService().getBuild(buildId);
        if (res.succesful && res.data) {
            const buildData = res.data!;
            batch(() => {
                dispatch(setSelectedBuild(buildData));
                dispatch(showCableDetails(true));
            });
        }
    });
}

export const updateBuildInfo = (buildInfo: IBuildInfo) => {
    return createAsyncAction(async (dispatch) => {
        const service = new ProjectManagerService();
        const res = await service.updatedBuildInfo(buildInfo);
        if (checkResult(res, dispatch)) {
            const buildInfo = res.data!;
            dispatch(buildSlice.actions.updateBuildInfo(buildInfo));
        }
    });
}

export const updateBuild = (build: IBuildData) => {
    return createAsyncAction(async (dispatch) => {
        const service = new BuildService();
        const newIds = !(hasBuildIds(build));
        const res = await service.updateBuild(build);
        if (checkResult(res, dispatch)) {
            const buildResponse = ToBuildData(res.data!);
            const updatedBuild: IBuildData = { ...buildResponse, source: buildResponse.source!, destinations: buildResponse.destinations! }
            if (newIds) {
                dispatch(buildSlice.actions.updateBuild(updatedBuild));
            }
            dispatch(updateBuildListEntry(updatedBuild));
            return updatedBuild;
        }
    });
};

export const updateConnectors = (connectors: IConnectorData[]) => {
    return createAsyncAction(async (dispatch) => {
        if (connectors.length > 0) {
            const service = new BuildService();
            const res = await service.updateConnectors(connectors);
            const data = extractData(res, dispatch);
            if (data) {
                const { connectors } = data;
                dispatch(buildSlice.actions.updateAllConnectors(connectors));
            }
        }
    });
}

export const updatePositionedConnectors = (connectors: IConnectorData[], propagation?: BuildPropagation) => {
    return createAsyncAction(async (dispatch) => {
        if (connectors.length > 0) {
            dispatch(setStatusState(WorkspaceStatus.Saving));
            const service = new BuildService();
            try {
                const res = await service.updateConnectors(connectors, propagation);
                const data = extractData(res, dispatch);
                if (data) {
                    const { connectors, propagationResult } = data;
                    const connectorsToUpdate = connectors;
                    if (propagation && propagationResult) {
                        const { status, connectors } = propagationResult;
                        connectorsToUpdate.push(...connectors);
                        dispatch(setPropagationNotification({ status, ...propagation.options }));
                    }
                    dispatch(updateSpecificConnectors(connectorsToUpdate));
                }
            }
            finally {
                dispatch(setStatusState(WorkspaceStatus.Saved));
            }
        }
    });
}

export const applyPropagation = (propagation: BuildPropagation) => {
    return createAsyncAction(async (dispatch) => {
        dispatch(setStatusState(WorkspaceStatus.Saving));
        const service = new BuildService();
        const res = await service.applyPropagation(propagation);
        const propagationResult = extractData(res, dispatch);
        if (propagationResult) {
            const { status, connectors } = propagationResult;
            if (connectors.length > 0) {
                dispatch(updateSpecificConnectors(connectors));
            }
            if (connectors.length === 0 && status === PropagationStatuses.Success) {
                dispatch(setNotification({ message: t(LocalizationKeys.PropagationIdenticalMessage), palette: AlertPalettes.info }));
            } else {
                dispatch(setPropagationNotification({ status, ...propagation.options }));
            }
        }
        dispatch(setStatusState(WorkspaceStatus.Saved));
    });
}

const hasBuildIds = (build: IBuildData) => {
    const source = build.source;
    const destinations = build.destinations;
    const sourceGroups = source.groups;
    const destinationGroups = destinations.map(d => d.groups).reduce((a, b) => [...a, ...b], [])
    const groups = [...sourceGroups, ...destinationGroups]
    const connectors = groups.map(g => g.connectors).reduce((a, b) => [...a, ...b], [])

    const lengths = [
        source.lengthA, ...destinations.map(d => d.lengthA),
        source.lengthB, ...destinations.map(d => d.lengthB), ...groups.map(g => g.lengthB),
        ...groups.map(g => g.stagger)
    ].filter(l => l !== undefined).map(l => l as IUnitOfMeasure);

    return hasIds(build, source, ...destinations, ...groups, ...connectors, ...lengths);
}

export const hasIds = (...ids: Partial<Identifiable>[]) => {
    return ids.map(i => i.id).every(a => !!a)
}


export const { setCurrentBuild, setSourceEnabled, setDestinationEnabled, addEnabledDestination,
    removeEnabledDestination, setupSource, setupDestination, setupAllDestinations, moveDestinationLeft,
    moveDestinationRight, setBuildLoaded,
    setIsCollapsed, expandAll, collapseAll, setBuildInfo, addBuild, setBuilds, addBuilds, setConnector,
    setCurrentBuildAsymmetric, updateAllConnectorLabels, updateBuildListEntry, setGroupBLength, setCustomBLength,
    setFlameRating, setConfigurationType, setCatalogCode, deleteBuild, setPolarityDescription, resetPolarityDescription, updatePositionedConnectors: updateSpecificConnectors, setPolarityAssignment, resetPolarityAssignment, setCurrentBuildSessionId
} = buildSlice.actions;

export const BuildActions = buildSlice.actions;