import React from "react";
import { Container, withFilters } from "@inlet/react-pixi";
import { SelectionBox } from "../../../decorators/bounds/components/selection/SelectionBox";
import { ConnectorFurcation } from "../connectors/components/furcation/ConnectorFurcation";
import { Markers } from "../../../decorators/markers/Markers";
import { TrunkContext } from "../../../../../workspace/redux/build/types";
import { TrunkProps, Feeder } from "../../types";
import { TAPVerticalTrunkSprite } from "../../EDSSprites";
import { DropBase } from "../drop-base/DropBase";
import { Label } from "../../Labels";
import BoundingBox from "../../../decorators/bounds/BoundingBox";
import { FeederBase } from "../feeder-base/FeederBase";
import { useTrunk } from "./hooks";
import * as Pixi from "pixi.js";

const Greyscale = withFilters(Container, { matrix: Pixi.filters.ColorMatrixFilter });

export const Trunk : React.FC<TrunkProps> = (props: TrunkProps) => {
    const { 
        x, y, section, ContainerName,
        trunkContext, greyScaleFilter, 
        buffer, furcationProps, verticalTrunkSpriteProps, 
        positionLabelProps, furcationLabelProps, baseProps } = useTrunk(props);

    return (
        <Container name={ContainerName}>
            <TrunkContext.Provider value={trunkContext}>
                <BoundingBox buffer={buffer}>
                    <Greyscale x={x} y={y} matrix={greyScaleFilter}>
                        <Markers />
                        <ConnectorFurcation {...furcationProps} />
                        { verticalTrunkSpriteProps ? <TAPVerticalTrunkSprite {...verticalTrunkSpriteProps} /> : null }
                        { section === Feeder ? <FeederBase {...baseProps} /> : <DropBase {...baseProps} /> }
                        { positionLabelProps ? <Label {...positionLabelProps} /> : null }
                        <Label {...furcationLabelProps} />
                        <SelectionBox />
                    </Greyscale>
                </BoundingBox>
            </TrunkContext.Provider>
        </Container>
    );
}