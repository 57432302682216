import { useSelector, useDispatch } from "react-redux";
import { currentBuildSelector } from "../../../../../../../../selectors/root.selectors";
import { showActionToolbarSelector } from "../../redux/selectors";
import { useMemo, useCallback, useReducer, useState, useEffect } from "react"
import { collapseAll, expandAll, updateConnectors } from "../../../../../../../../redux/build/reducers";
import { sscSessionBusySelector } from "../../../../../../../../redux/ssc/selectors";
import { initialDialogState } from "../../../../../../../../redux/dialog/types";
import { DialogReducer, showDialog } from "../../../../../../../../redux/dialog/reducer";
import { IColor, IColorDialogProps } from "../../../../../../../../../ui/dialog/color/types";
import { IActionToolbarProps } from "./types";
import { IConnectorGroupData } from "../../../../../../../../redux/build/connector/types";
import { IGenericDialogProps } from "../../../../../../../../../ui/dialog/generic-dialog/types";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../../../../locales/types";
import { IBuildData } from "../../../../../../../../redux/build/types";
import { useSscBuildSession } from "../../../../../../../../redux/ssc/hooks";
import { ISourceData } from "../../../../../../../../redux/build/source/types";
import { IDestinationData } from "../../../../../../../../redux/build/destination/types";
import { commonConnectorColorsSelector } from "../../../../../../../../selectors/build.selectors";
import { showConnectorReportSelector } from "../../../../../../redux/selectors";
import { AppDispatch } from "../../../../../../../../redux/reducers";

export const useActionToolbar = () => {
    const currentBuild = useSelector(currentBuildSelector);
    const sscBusy = useSelector(sscSessionBusySelector);
    const showConnectorReport = useSelector(showConnectorReportSelector);
    const showToolbar = useSelector(showActionToolbarSelector);
    const { colorDialogProps, confirmResetDialogProps, onColorPaletteClick } = useColorResetDialog();

    const storeDispatch = useDispatch();

    const triggerCollapseAll = useCallback(() => {
        storeDispatch(collapseAll());
    }, [storeDispatch]);

    const triggerExpandAll = useCallback(() => {
        storeDispatch(expandAll());
    }, [storeDispatch]);

    const actionToolbarProps: IActionToolbarProps = useMemo(() => {
        let showCollapse = false;
        if (currentBuild) {
            let nbCollapsed = currentBuild.source.isCollapsed ? 1 : 0;

            const collapsedDestinations = currentBuild.destinations.filter(d => d.isCollapsed);
            nbCollapsed += collapsedDestinations.length;

            showCollapse = nbCollapsed <= (currentBuild.destinations.length + 1) * 0.5;
        }

        return {
            showCollapse,
            showToolbar,
            disableColorPalette: sscBusy || (!!currentBuild?.catalogCode?.length) || showConnectorReport,
            onColorPaletteClick,
            triggerCollapseAll,
            triggerExpandAll   
        }
    }, [currentBuild, showToolbar, showConnectorReport, sscBusy, triggerCollapseAll, triggerExpandAll, onColorPaletteClick])

    return { actionToolbarProps, colorDialogProps, confirmResetDialogProps };
}

const useColorResetDialog = () => {
    const { t } = useTranslation();
    const storeDispatch = useDispatch<AppDispatch>();
    const currentBuild = useSelector(currentBuildSelector);
    const colors = useSelector(commonConnectorColorsSelector);
    const { updateConnectorMaterials } = useSscBuildSession();

    const [state, dispatch] = useReducer(DialogReducer, initialDialogState);

    const [currentColor, setCurrentColor] = useState<IColor | undefined>(undefined);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    useEffect(() => {
        if (!state.props.open && !showConfirmDialog && currentColor) {
            setCurrentColor(undefined);
        }
    }, [currentColor, showConfirmDialog, state.props.open])

    const onColorButtonClick = useCallback((color: IColor) => {
        setCurrentColor(color);
        setShowConfirmDialog(true);
        dispatch(showDialog(false));
    }, [dispatch])

    const onResetButtonClick = useCallback(() => {
        setCurrentColor(undefined);
        setShowConfirmDialog(true);
        dispatch(showDialog(false));
    }, [dispatch])

    const onResetConfirm = useCallback(() => {
        if (currentBuild) {
            const updatedSource = getUpdatedSource(currentBuild.source, currentColor);
            const updatedDestinations = currentBuild.destinations.map(d => getUpdatedDestination(d, currentColor));

            const updatedSourceConnectors = updatedSource.groups.flatMap(g => g.connectors);
            const updatedDestinationConnectors = updatedDestinations.flatMap(d => d.groups.flatMap(g => g.connectors));

            const updatedConnectors = [...updatedSourceConnectors, ...updatedDestinationConnectors];
            storeDispatch(updateConnectors(updatedConnectors));

            const updatedBuild: IBuildData = {
                ...currentBuild,
                source: updatedSource,
                destinations: updatedDestinations
            }

            updateConnectorMaterials(updatedBuild);
        }
        setShowConfirmDialog(false);
    }, [currentBuild, currentColor, storeDispatch, updateConnectorMaterials]);

    const onConfirmResetDialogClose = useCallback(() => {
        setShowConfirmDialog(false);
    }, [])

    const onColorPaletteClick = useCallback(() => {
        dispatch(showDialog(true));
    }, [dispatch])

    const confirmResetDialogProps: IGenericDialogProps = useMemo(() => {
        return {
            id: "confirm-color-reset-dialog",
            title: t(LocalizationKeys.ConnectorSelection),
            closable: true,
            display: showConfirmDialog,
            message: t(LocalizationKeys.ResetConnectorColors),
            onClose: onConfirmResetDialogClose,
            confirmText: t(LocalizationKeys.Apply),
            onConfirm: onResetConfirm,
        }
    }, [showConfirmDialog, t, onResetConfirm, onConfirmResetDialogClose])

    const colorDialogProps: IColorDialogProps = useMemo(() => {
        return {
            context: { state, dispatch },
            preventOutsideDismiss: true,
            className: "action-toolbar",
            displayFooter: true,
            colors: colors,
            currentColor,
            onColorButtonClick,
            onResetButtonClick
        }
    }, [currentColor, state, dispatch, colors, onColorButtonClick, onResetButtonClick]);

    return { colorDialogProps, confirmResetDialogProps, onColorPaletteClick };
}

const getUpdatedSource = (source: ISourceData, color?: IColor): ISourceData => {
    return { 
        ...source, 
        lengthA: source.lengthA,
        lengthB: source.lengthB,
        groups: resetColors(source.groups, color) 
    }
}

const getUpdatedDestination = (destination: IDestinationData, color?: IColor): IDestinationData => {
    return { ...destination, groups: resetColors(destination.groups, color) };
}

const resetColors = (groups: IConnectorGroupData[], color?: IColor): IConnectorGroupData[] => {
    return groups.map(g => {
        return { 
            ...g, 
            position: g.position,
            stagger: g.stagger,
            lengthB: g.lengthB,
            connectors: g.connectors.map(c => { 
                return { 
                    ...c,  
                    stagger: c.stagger,
                    color: color && color.name !== c.color ? color.name : c.defaultColor,
                }
            })
        }
    })
}