import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { BuildDocument, DocumentDrop } from '../../redux/document/types';
import i18next, { TFunction } from 'i18next';
import { LocalizationKeys } from '../../../locales/types';
import { Canvg } from 'canvg';
import { chipHeight, chipOriginalHeight, chipOriginalWidth, chipWidth } from '../../../ui/dialog/color/chip/ColorChip';
import { IChip } from './types';

const pagesize = [2000, 1294];
const widthIndex = 0;
const heightIndex = 1;
const pixelConversionFactor = 1;
const padding = 60;
const cellPadding = 10;
const tableMargin = 70;

const topLeft = { x: padding, y: padding };
const boxWidth = pagesize[widthIndex] - (2 * padding);

const fontScale = 4.76;
const fontSize = 16 * fontScale;
const sectionFontSize = 12 * fontScale;
const footerFontSize = 11.5 * fontScale;
const footerContentFontSize = 11 * fontScale;
const boxHeight = pagesize[heightIndex] * 0.051;
const boxCompanyWidth = boxWidth / 8;
const boxPageWidth = (boxWidth / 12);
const boxPageStart = topLeft.x + boxWidth - boxPageWidth;

const boxTitleStart = topLeft.x + boxCompanyWidth;
const boxTitleWidth = boxWidth - boxCompanyWidth - boxPageWidth;

const footerStart = { x: padding, y: pagesize[heightIndex] - padding - boxHeight }

const pageContentStart = topLeft.y + boxHeight + 65;

export const createPDF = async (settings: BuildDocument, translate: TFunction) => {
    var doc = new jsPDF();
    doc.deletePage(1);

    const fiberColorChips = await generateChips("#fiber-position-chip-container");
    const connectorColorChips = await generateChips("#connector-chip-container");

    if (settings.overview) {
        await createOverviewPage(doc, settings, translate);
    }

    if (settings.feeder) {
        createFeederPage(doc, settings, translate, connectorColorChips);
    }
    if (settings.drops) {
        createDropPages(doc, settings, translate, connectorColorChips);
    }
    
    if (settings.connector && settings.connector.include) {
        createLabelSummaryPage(doc, translate);
    }
    
    if (settings.polarity) {
        createPolarityDiagramPages(doc, settings, translate);
    }

    if (settings.fiberMap && settings.fiberMap.include) {
        await createFiberMapPages(doc, fiberColorChips);
    }

    if (settings.buildPlan && settings.buildPlan.include) {
        createBuildPlanPages(doc, settings, translate);
    }

    let fileName = "EDGE-DS-Report";

    if (settings.fileName) {
        fileName += "-" + settings.fileName;
    }

    if (settings.catalogCode) {
        fileName += "-" + settings.catalogCode;
    }

    if (settings.revision) {
        fileName += "-" + settings.revision;
    }

    for (let i = 0; i < doc.internal.pages.length - 1; i++) {
        doc.setPage(i + 1);
        addHeaderFooter(doc, i + 1, doc.internal.pages.length - 1, settings, translate);
    }

    fileName = fileName.replace('.', '_');

    if (settings.draft) {
        fileName += translate(LocalizationKeys.ReportDraft).replace(/ /g, "").toLocaleUpperCase();
    }
    
    doc.save(fileName + ".pdf");
};

export const addHeaderFooter = (doc: jsPDF, pageNumber: number, pageCount: number, settings: BuildDocument, translate: TFunction) => {
    doc.setTextColor('black');
    createHeader(doc, pageNumber, pageCount, settings, translate);
    createFooter(doc, settings, translate);
}

export async function createOverviewPage(doc: jsPDF, settings: BuildDocument, translate: TFunction) {
    doc.addPage([pagesize[widthIndex] * pixelConversionFactor, pagesize[heightIndex] * pixelConversionFactor], 'l')
    await createOverview(doc, settings, translate);
}

export function createFeederPage(doc: jsPDF, settings: BuildDocument, translate: TFunction, chips: IChip[]) {
    doc.addPage([pagesize[widthIndex] * pixelConversionFactor, pagesize[heightIndex] * pixelConversionFactor], 'l')
    createFeederSection(doc, settings, translate);
    doc.addPage([pagesize[widthIndex] * pixelConversionFactor, pagesize[heightIndex] * pixelConversionFactor], 'l')
    createFeederTablePage(doc, translate, chips);
}

async function createLabelSummaryPage(doc: jsPDF, translate: TFunction) {
    doc.addPage([pagesize[widthIndex] * pixelConversionFactor, pagesize[heightIndex] * pixelConversionFactor], 'l')
    createLabelTablePage(doc, translate)
}

export async function createDropPages(doc: jsPDF, settings: BuildDocument, translate: TFunction, chips: IChip[]) {
    if (settings.drops) {
        for (const drop of settings.drops) {
            doc.addPage([pagesize[widthIndex] * pixelConversionFactor, pagesize[heightIndex] * pixelConversionFactor], 'l')
            createDropSection(doc, drop, translate);
            doc.addPage([pagesize[widthIndex] * pixelConversionFactor, pagesize[heightIndex] * pixelConversionFactor], 'l')
            createDropTablePage(doc, drop, translate, chips);
        }
    }
}

function createDropSection(doc: jsPDF, documentDrop: DocumentDrop, translate: TFunction) {
    const { drop } = documentDrop;
    const dropString = translate(LocalizationKeys.DropPosition, { position: drop.position })
    let textStart = pageContentStart;
    // title
    doc.setFontSize(fontSize);
    doc.setTextColor(0, 82, 147);
    doc.text(dropString, topLeft.x, textStart);

    textStart = textStart + 30;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor('black');
    doc.text(documentDrop.description!, topLeft.x + 5, textStart);

    // sub
    textStart = textStart + 40;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(0, 82, 147);
    doc.text(translate(LocalizationKeys.TrunkDrawing, { trunk: dropString }), topLeft.x, textStart);

    const dropImage = getPixiRaster(`drop${drop.position}`);
    if (dropImage) {
        let width = boxWidth;
        let maxHeight = pagesize[widthIndex] * 0.40;
        let ratio = dropImage.width / width;
        let height = dropImage.height / ratio;
        if (height > maxHeight) {
            height = maxHeight;
            ratio = dropImage.height / height;
            width = dropImage.width / ratio;
        }

        const imageX = (pagesize[widthIndex] - width) / 2

        doc.addImage(dropImage, "JPEG", imageX, textStart + 30, width, height);
        textStart = textStart + height;
        textStart = textStart + 70;

        createRepresentativeExample(doc, textStart, translate);
    }
}

function createDropTablePage(doc: jsPDF, documentDrop: DocumentDrop, translate: TFunction, chips: IChip[]) {
    const { drop } = documentDrop;
    const dropString = translate(LocalizationKeys.APPosition, { position: drop.position })


    let textStart = pageContentStart;
    const tableId = `#drop-${drop.position}-table`;
    // title
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(0, 82, 147);
    doc.text(translate(LocalizationKeys.TrunkConnectorTable, { trunk: dropString }), topLeft.x, textStart);
    textStart = textStart + 20;
    createTrunkTable(doc, tableId, textStart, chips);
}

function createTrunkTable(doc: jsPDF, tableId: string, textStart: number, chips: IChip[]) {
    const offscreen = document.body.querySelector("#offscreen-container") as HTMLDivElement;
    if (offscreen) {
        const table = offscreen.querySelector(tableId) as HTMLTableElement;

        if (table) {
            autoTable(doc, {
                html: table,
                tableLineColor: 0x000000,
                tableLineWidth: 1,
                startY: textStart,
                styles: {
                    fontSize: sectionFontSize,
                    fillColor: 0xFFFFFF,
                    textColor: 0x000000,
                    lineColor: 0x000000,
                    lineWidth: 1,
                    valign: "middle",
                    cellPadding: {
                        left: cellPadding,
                        right: cellPadding,
                        top: cellPadding / 4,
                        bottom: cellPadding / 4,
                    },
                },
                headStyles: {
                },
                alternateRowStyles: {
                    fillColor: 0xFFFFFF,
                },
                willDrawCell: (data) => {
                    const columnIndex = data.column.index;
                    if (data.cell.section === "head" && (columnIndex === 0 || columnIndex === 1 || columnIndex === 3)) {
                        data.cell.styles.halign = "right";
                    }
                },
                didDrawCell: function(data) {
                    const columnIndex = data.column.index;
                    if (data.cell.section === 'body' && (columnIndex === 3)) {
                        const colorIndex = Number(data.cell.text[0]);
                        const chip = chips.find(c => c.index === colorIndex);
                        if (chip) {
                            const chipOffsetY = (data.cell.height - chipOriginalHeight) * 0.5;
                            doc.addImage({
                                imageData: chip.imageData,
                                format: "PNG",
                                x: data.cell.x + 8,
                                y:data.cell.y + chipOffsetY,
                                width: chipOriginalWidth,
                                height: chipOriginalHeight,
                                compression: "FAST",
                            })
                        }
                    }
                },
                columnStyles: {
                    0: { halign: "right" },
                    1: { halign: "right" },
                    2: { halign: "left", cellWidth: boxWidth * 0.25 },
                    3: { halign: "left", cellWidth: boxWidth * 0.1 },
                    4: { halign: "right", cellWidth: boxWidth * 0.1 },
                    5: { halign: "left" },
                    6: { cellWidth: boxWidth * 0.295 }
                },
                margin: { left: tableMargin, right: tableMargin, bottom: boxHeight * 3, top: textStart }
            });
        }
    }
}

function createLabelTable(doc: jsPDF, tableId: string, textStart: number, leftMargin: number, rightMargin: number) {
    const offscreen = document.body.querySelector("#offscreen-container") as HTMLDivElement;
    if (offscreen) {
        const table = offscreen.querySelector(tableId) as HTMLTableElement;
        const tableWidth = boxWidth * 0.48
        if (table) {
            autoTable(doc, {
                html: table,
                tableLineColor: 0x000000,
                tableLineWidth: 1,
                startY: textStart,
                tableWidth: tableWidth,
                styles: { fontSize: sectionFontSize, fillColor: 0xFFFFFF, textColor: 0x000000, lineColor: 0x000000, lineWidth: 1, valign: "middle", cellPadding: { left: cellPadding, right: cellPadding } },
                headStyles: {
                },
                alternateRowStyles: {
                    fillColor: 0xFFFFFF,
                },
                willDrawCell: (data) => {
                    const columnIndex = data.column.index;
                    const rowIndex = data.row.index;

                    if (data.cell.section === "head") {
                        data.cell.styles.valign = "bottom"
                        if (columnIndex === 0 && rowIndex === 0) {
                            data.row.height = 40;
                            data.cell.height = 40;
                            data.cell.styles.valign = "middle";
                        }
                    }
                },
                columnStyles: {
                    0: { halign: "right", cellWidth: tableWidth * 0.11 },
                    1: { halign: "right", cellWidth: tableWidth * 0.11 },
                    2: { halign: "left", cellWidth: tableWidth * 0.31 },
                    3: { halign: "right", cellWidth: tableWidth * 0.07 },
                    4: { halign: "left", cellWidth: tableWidth * 0.25 },
                    5: { halign: "left", cellWidth: tableWidth * 0.15 }
                },
                margin: { left: leftMargin, right: rightMargin, bottom: boxHeight * 3, top: textStart }
            });
        }
    }
}

function createFeederTablePage(doc: jsPDF, translate: TFunction, chips: IChip[]) {
    const tableId = "#feeder-connector-table";
    let textStart = pageContentStart;
    // title
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(0, 82, 147);
    doc.text(translate(LocalizationKeys.TrunkConnectorTable, { trunk: translate(LocalizationKeys.Feeder) }), topLeft.x, textStart);

    textStart = textStart + 20;
    createTrunkTable(doc, tableId, textStart, chips);
}

function createFeederSection(doc: jsPDF, settings: BuildDocument, translate: TFunction) {
    let textStart = pageContentStart;
    // title
    doc.setFontSize(fontSize);
    doc.setTextColor(0, 82, 147);
    doc.text(translate(LocalizationKeys.TrunkSide, { trunk: translate(LocalizationKeys.Feeder) }), topLeft.x, textStart);

    textStart = textStart + 30;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor('black');
    doc.text(settings.feeder!.description!, topLeft.x + 5, textStart);

    // sub
    textStart = textStart + 40;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(0, 82, 147);
    doc.text(translate(LocalizationKeys.TrunkDrawing, { trunk: translate(LocalizationKeys.Feeder) }), topLeft.x, textStart);

    const sourceImage = getPixiRaster("source-container");
    if (sourceImage) {
        let width = boxWidth;
        let maxHeight = pagesize[widthIndex] * 0.40;
        let ratio = sourceImage.width / width;
        let height = sourceImage.height / ratio;
        if (height > maxHeight) {
            height = maxHeight;
            ratio = sourceImage.height / height;
            width = sourceImage.width / ratio;
        }

        const imageX = (pagesize[widthIndex] - width) / 2

        doc.addImage(sourceImage, "JPEG", imageX, textStart + 30, width, height);
        textStart = textStart + height;
        textStart = textStart + 70;

        createRepresentativeExample(doc, textStart, translate);
    }
}

function createLabelTablePage(doc: jsPDF, translate: TFunction) {
    const feederTableId = "#feeder-label-table";
    const dropTableId = "#drop-label-table";
    let textStart = pageContentStart;
    // title
    doc.setFontSize(fontSize);
    doc.setTextColor(0, 82, 147);
    doc.text(translate(LocalizationKeys.ConnectorAndLabelSummary), topLeft.x, textStart);

    textStart = textStart + 20;
    const pageStart = doc.getCurrentPageInfo().pageNumber;

    createLabelTable(doc, feederTableId, textStart, tableMargin, (pagesize[widthIndex] * 0.5) + tableMargin);
    const feederPageEnd = doc.getCurrentPageInfo().pageNumber;
    doc.setPage(pageStart);

    createLabelTable(doc, dropTableId, textStart, (pagesize[widthIndex] * 0.5), tableMargin);
    const dropPageEnd = doc.getCurrentPageInfo().pageNumber;
    const pageEnd = feederPageEnd > dropPageEnd ? feederPageEnd : dropPageEnd;
    doc.setPage(pageEnd);
}

function getPixiRaster(componentName: string) {
    let offscreen = document.body.querySelector('#offscreen-container');
    if (offscreen) {
        const componentImages = offscreen.getElementsByClassName(componentName);
        const componentImage = componentImages[0];
        return componentImage as HTMLCanvasElement;
    }
}

async function createOverview(doc: jsPDF, settings: BuildDocument, translate: TFunction) {
    let textStart = pageContentStart;

    // title
    doc.setFontSize(fontSize);
    doc.setTextColor(0, 82, 147);
    doc.text(translate(LocalizationKeys.CableOverview), topLeft.x, textStart);

    textStart = textStart + 30;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor('black');
    doc.text(settings.overview?.description ?? "", topLeft.x + 15, textStart);

    // sub
    textStart = textStart + 40;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(0, 82, 147);
    doc.text(translate(LocalizationKeys.CableDrawing), topLeft.x, textStart);

    const edsImage = getPixiRaster("eds-container");
    if (edsImage) {
        let width = boxWidth;
        let maxHeight = pagesize[widthIndex] * 0.25;
        let ratio = edsImage.width / width;
        let height = edsImage.height / ratio;
        if (height > maxHeight) {
            height = maxHeight;
            ratio = edsImage.height / height;
            width = edsImage.width / ratio;
        }

        const imageX = (pagesize[widthIndex] - width) / 2

        doc.addImage(edsImage, "JPEG", imageX, textStart + 30, width, height);
        textStart = textStart + height;
        textStart = textStart + 70;

        doc.setFontSize(sectionFontSize);
        doc.setTextColor(0, 82, 147);
        doc.text(translate(LocalizationKeys.CableTrunkLengths), topLeft.x, textStart + 65);

        createRepresentativeExample(doc, textStart, translate);
        textStart = textStart + 15;

        const offscreen = document.body.querySelector("#offscreen-container") as HTMLDivElement;
        if (offscreen) {
            const table = offscreen.querySelector("#cable-trunk-length-table") as HTMLTableElement;
            const vertical = boxHeight * 3;
            if (table) {
                autoTable(doc, {
                    html: table,
                    tableLineColor: 0x000000,
                    tableLineWidth: 1,
                    startY: textStart + 75,
                    styles: { fontSize: sectionFontSize, fillColor: 0xFFFFFF, textColor: 0x000000, lineColor: 0x000000, lineWidth: 1, halign: "left", cellPadding: { left: cellPadding, right: cellPadding } },
                    alternateRowStyles: {
                        fillColor: 0xFFFFFF,
                    },
                    willDrawCell: (data) => {
                        const columnIndex = data.column.index;
                        if (data.cell.section === "head" && (columnIndex === 0 || columnIndex === 2 || columnIndex === 3)) {
                            data.cell.styles.halign = "right";
                        }
                    },
                    columnStyles: {
                        0: { halign: "right" },
                        1: { halign: "left" },
                        2: { halign: "right" },
                        3: { halign: "right" },
                        4: { cellWidth: boxWidth * 0.5, }
                    },
                    margin: { left: tableMargin, right: tableMargin, bottom: vertical, top: vertical }
                });
            }
        }
    }
}

function createFooter(doc: jsPDF, settings: BuildDocument, translate: TFunction) {
    const { siteLocation, drawn, notes, date, revision, units } = settings;
    const colon = translate(LocalizationKeys.Colon);
    // footer 
    doc.rect(footerStart.x, footerStart.y, boxWidth, boxHeight);
    const textStartY = footerStart.y + 25;

    let sectionStart = footerStart.x;
    doc.setFontSize(footerFontSize);

    // siteLocation
    const siteLocationStart = sectionStart;
    const siteLocationWidth = boxWidth / 8;
    doc.rect(sectionStart, footerStart.y, siteLocationWidth, boxHeight)
    doc.setTextColor('black');
    doc.text(translate(LocalizationKeys.Location).toLocaleUpperCase() + colon, sectionStart + 2, textStartY)
    sectionStart = sectionStart + siteLocationWidth;

    //drawn
    const drawStart = sectionStart;
    const drawBoxWidth = boxWidth / 9;
    doc.rect(sectionStart, footerStart.y, drawBoxWidth, boxHeight)
    doc.setTextColor('black');
    doc.text(translate(LocalizationKeys.DrawingBy).toLocaleUpperCase() + colon, sectionStart + 2, textStartY)
    sectionStart = sectionStart + drawBoxWidth;

    // approval date
    const approvalDateStart = sectionStart;
    const formattedDate = new Date(date!.replace(/-/g, '/'));
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0');;
    const day = String(formattedDate.getDate()).padStart(2, '0')
    const year = formattedDate.getFullYear();
    const dateText = `${month}/${day}/${year}`
    doc.rect(sectionStart, footerStart.y, drawBoxWidth, boxHeight)
    doc.text(translate(LocalizationKeys.Date).toLocaleUpperCase() + colon, sectionStart + 2, textStartY)
    sectionStart = sectionStart + drawBoxWidth;

    // revision
    const revisionWidth = boxWidth / 9;
    const revisionStart = sectionStart;
    doc.rect(sectionStart, footerStart.y, revisionWidth, boxHeight)
    doc.text(translate(LocalizationKeys.Revision).toLocaleUpperCase() + colon, sectionStart + 2, textStartY)
    sectionStart = sectionStart + revisionWidth;

    //units
    const unitsWidth = boxWidth / 9;
    const unitsStart = sectionStart;
    doc.rect(sectionStart, footerStart.y, unitsWidth, boxHeight)
    doc.text(translate(LocalizationKeys.Units).toLocaleUpperCase() + colon, sectionStart + 2, textStartY)
    sectionStart = sectionStart + unitsWidth;

    const remainingSpace = boxWidth - (sectionStart - footerStart.x)

    //notes
    const notesWidth = boxWidth - (3 * drawBoxWidth) - unitsWidth - siteLocationWidth;
    const notesTextLength = doc.getTextWidth(notes!) + 5;
    const maxNotesWidth = Math.max(notesWidth, notesTextLength);
    sectionStart = sectionStart + remainingSpace - maxNotesWidth;
    const notesStart = sectionStart;
    doc.rect(sectionStart, footerStart.y, maxNotesWidth, boxHeight)
    doc.text(translate(LocalizationKeys.Notes).toLocaleUpperCase() + colon, sectionStart + 2, textStartY)

    // sections content
    doc.setFontSize(footerContentFontSize);

    doc.text(siteLocation ?? "", siteLocationStart + 2, textStartY + 20)
    doc.text(drawn ?? "", drawStart + 2, textStartY + 20)
    doc.text(dateText, approvalDateStart + 2, textStartY + 20)
    doc.text(`${revision}`, revisionStart + 2, textStartY + 20)
    doc.text(units ?? "", unitsStart + 2, textStartY + 20)
    doc.text(notes ?? "", notesStart + 2, textStartY + 20)
}

function createHeader(doc: jsPDF, pageNumber: number, pageCount: number, settings: BuildDocument, translate: TFunction) {
    doc.rect(topLeft.x, topLeft.y, boxWidth, boxHeight);
    const textStart = topLeft.y + 45;

    // Company
    doc.rect(topLeft.x, topLeft.y, boxCompanyWidth, boxHeight);
    const offscreen = document.body.querySelector('#offscreen-container') as HTMLDivElement;
    if (offscreen) {
        const logo = offscreen.querySelector("#corning-secondary-logo") as HTMLImageElement;
        if (logo) {
            const width = boxCompanyWidth - padding;
            const height = (width / logo.width) * logo.height;
            const y = topLeft.y + (boxHeight - height) / 2;
            const x = topLeft.x + (padding / 2);
            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");
            if (ctx) {
                ctx.drawImage(logo, 0, 0);
                doc.addImage(canvas.toDataURL(), "PNG", x, y, width, height, '', 'FAST')
            }
            canvas.remove();
        }
    }

    // PageCount
    const pageCountText = `${pageNumber}/${pageCount}`;
    const pageCountTextWidth = doc.getTextWidth(pageCountText)
    let pageCountStart = boxPageStart + ((boxPageWidth - pageCountTextWidth - 30) / 2);
    doc.rect(boxPageStart, topLeft.y, boxPageWidth, boxHeight);
    doc.setFontSize(sectionFontSize);
    doc.text(pageCountText, pageCountStart, textStart);

    // Page Title
    doc.setFontSize(fontSize);
    const pageTitle = translate(LocalizationKeys.ReportPageTitle) + (settings.draft ? translate(LocalizationKeys.ReportDraft) : "");
    const pageTitleWdith = doc.getTextWidth(pageTitle);
    const pageTitleStart = boxTitleStart + (boxTitleWidth - pageTitleWdith) / 2;
    doc.text(pageTitle, pageTitleStart, textStart);
}

function createPolarityEntries(doc: jsPDF, startX: number, startY: number, settings: BuildDocument, translate: TFunction) {
    if (!settings.polarity) return;

    const polarityContainer = document.body.querySelector("#polarity-container") as HTMLDivElement;
    if (!polarityContainer) return;

    const images = Array.from(polarityContainer.getElementsByTagName("img"));

    const fontSize = sectionFontSize;
    const unscaledFont = fontSize / fontScale;
    const fontXOffset = fontSize * 0.1;

    const boxHeight = pagesize[heightIndex] * 0.3;
    const maxWidth = boxWidth - boxCompanyWidth;
    const apColWidth = boxCompanyWidth;

    const headerHeight = fontSize * 0.8;

    const tableIndent = headerHeight;
    const halfTableIndent = tableIndent * 0.5;

    const maxImageHeight = boxHeight * 0.8;
    const maxImageWidth = maxWidth * 0.8

    let yOffset = 0;

    const textStart = startY + (fontSize * 0.5);

    doc.setFontSize(fontSize);
    doc.setTextColor('black');

    const sortedAppliedFiberMaps = [...settings.polarity.documentFiberMaps];
    for (const appliedFiberMap of sortedAppliedFiberMaps) {
        const image = images.find(i => i.id === `polarity-image-${appliedFiberMap.sessionId}`) 
                   ?? images.find(i => i.id === `polarity-image-${appliedFiberMap.id}`);

        const accessPoints = [...appliedFiberMap.accessPointIndices].sort((a,b) => a - b).map((i) => i.toString());
        const textStringHeight = (accessPoints.length * unscaledFont) + (((accessPoints.length - 1) * (unscaledFont * 1.5)));

        let scaledBoxHeight: number;
        let imageWidth: number = 0;
        let imageHeight: number = 0;

        if (image) {
            // Scale image
            imageWidth = image.width;
            imageHeight = image.height;
            if (imageHeight > maxImageHeight) {
                // Scale down the image to fit
                const scale = maxImageHeight / imageHeight;
                imageHeight *= scale;
                imageWidth *= scale;
            }

            if (imageWidth > maxImageWidth) {
                const scale = maxImageWidth / imageWidth;
                imageHeight *= scale;
                imageWidth *= scale;
            }

            scaledBoxHeight = textStringHeight > imageHeight ? textStringHeight + headerHeight * 0.5 : imageHeight + headerHeight * 0.35;
        }
        else {
            scaledBoxHeight = Math.max(textStringHeight + headerHeight * 0.5, headerHeight);
        }

        // Move to a new page if the next diagram willl overlap the footer.
        if ((startY + yOffset + scaledBoxHeight + headerHeight) > footerStart.y) {
            doc.addPage([pagesize[widthIndex] * pixelConversionFactor, pagesize[heightIndex] * pixelConversionFactor], 'l')
            yOffset = 0;
        }
    
        // Header
        doc.rect(startX, startY + yOffset, boxCompanyWidth, headerHeight);
        doc.rect(startX + apColWidth, startY + yOffset, boxWidth - boxCompanyWidth, headerHeight);
        doc.text(translate(LocalizationKeys.AccessPoints), startX + fontXOffset, textStart + yOffset);
        doc.text(appliedFiberMap.name, startX + fontXOffset + apColWidth, textStart + yOffset);
        doc.text(accessPoints, startX + apColWidth - (fontSize * 0.7), textStart + yOffset + headerHeight)

        // Polarity image
        doc.rect(startX, startY + yOffset + headerHeight, boxCompanyWidth, scaledBoxHeight);
        doc.rect(startX + apColWidth, startY + yOffset + headerHeight, boxWidth - boxCompanyWidth, scaledBoxHeight);
        if (image) {
            doc.addImage({ 
                imageData: image, 
                format: "PNG",
                x: startX + apColWidth + 20,
                y: startY + yOffset + headerHeight + 10,
                width: imageWidth,
                height: imageHeight,
                compression: "FAST"
            });
        }
        else {
            doc.text(translate(LocalizationKeys.NoImageAvailable), startX + apColWidth + 20, textStart + yOffset + headerHeight)
        }

        yOffset += scaledBoxHeight + headerHeight + halfTableIndent;
    }
}

// Create polarity page
function createPolarityDiagramPages(doc: jsPDF, settings: BuildDocument, translate: TFunction) {
    const textStart = pageContentStart;

    doc.addPage([pagesize[widthIndex] * pixelConversionFactor, pagesize[heightIndex] * pixelConversionFactor], 'l')
    doc.setFontSize(fontSize);
    doc.setTextColor(0, 82, 147);
    doc.text(translate(LocalizationKeys.PolarityDiagram), topLeft.x, textStart);

    createPolarityEntries(doc, topLeft.x + 10, textStart + 20, settings, translate);
}

function createBuildPlanPages(doc: jsPDF, settings: BuildDocument, translate: TFunction) {
    doc.addPage([pagesize[widthIndex] * pixelConversionFactor, pagesize[heightIndex] * pixelConversionFactor], 'l')
    createBuildPlanSummaryPage(doc, settings, translate);
    createBuildPlanTables(doc);
}

function createBuildPlanSummaryPage(doc: jsPDF, settings: BuildDocument, translate: TFunction) {
    let textStart = pageContentStart;
    doc.setFontSize(fontSize);
    doc.setTextColor(0, 82, 147);
    doc.text(translate(LocalizationKeys.BuildPlan), topLeft.x, textStart);

    textStart += 60;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(0, 82, 147);
    doc.text(translate(LocalizationKeys.Summary), topLeft.x + 15, textStart);

    const { apConnectorTypes, apCount, assemblyLength, feederConnectorType, fiberCount } = settings.buildPlan!.summary!;
    const fields = [fiberCount, feederConnectorType, apConnectorTypes, apCount, assemblyLength]

    const fieldsText = fields.map(f => '\u2022    ' + f! + '\n').reduce((a, b) => a + b, "")

    textStart += 30;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor('black');
    doc.text(fieldsText, topLeft.x + 45, textStart);

    textStart += 170;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(0, 82, 147);
    doc.text(translate(LocalizationKeys.Table), topLeft.x + 15, textStart);
}

function createBuildPlanTables(doc: jsPDF) {
    const tableWidth = boxWidth * 0.985;
    createBuildPlanTable(
        doc,
        "#build-plan-table1",
        pagesize[heightIndex] / 2 - 140,
        tableWidth,
        { top: 0, bottom: 0, right: tableMargin, left: tableMargin })
    
    if (getOffscreenTable("#build-plan-table2")) {
        doc.addPage([pagesize[widthIndex] * pixelConversionFactor, pagesize[heightIndex] * pixelConversionFactor], 'l')
    
        createBuildPlanTable(
            doc,
            "#build-plan-table2",
            pageContentStart,
            tableWidth,
            { top: 0, bottom: 2.5 * boxHeight, right: tableMargin, left: tableMargin })
    }
}

function createBuildPlanTable(
    doc: jsPDF,
    tableSelector: string,
    textStart: number,
    tableWidth: number,
    margin: { top: number, bottom: number, left: number, right: number }) {

    const table = getOffscreenTable(tableSelector)
    const sectionFillColor: [number, number, number] = [224, 224, 224]
    if (table) {
        autoTable(doc, {
            html: table,
            tableLineColor: 0x000000,
            tableLineWidth: 1,
            startY: textStart,
            tableWidth: tableWidth,
            styles: { fontSize: sectionFontSize, fillColor: 0xFFFFFF, textColor: 0x000000, lineColor: 0x000000, lineWidth: 1, valign: "middle", cellPadding: { left: cellPadding, right: cellPadding, top: cellPadding / 4, bottom: cellPadding / 4 } },
            headStyles: {
                halign: "left"
            },
            alternateRowStyles: {
                fillColor: 0xFFFFFF,
            },
            willDrawCell: (data) => {
                if ((data.cell.raw as HTMLTableCellElement).className === "subsection") {
                    data.cell.styles.fillColor = sectionFillColor;
                }
            },
            didParseCell: (data) => {
                if ((data.cell.raw as HTMLTableCellElement).className === "subsection") {
                    data.cell.styles.fillColor = sectionFillColor;
                }
            },
            columnStyles: {
                0: { halign: "left", cellWidth: tableWidth * 0.34 },
                1: { halign: "left", cellWidth: tableWidth * 0.33 },
                2: { halign: "left", cellWidth: tableWidth * 0.33 },
            },
            margin
        });
    }
}

function createFiberMapPages(doc: jsPDF, chips: IChip[]) {
    doc.addPage([pagesize[widthIndex] * pixelConversionFactor, pagesize[heightIndex] * pixelConversionFactor], 'l')
    
    doc.setFontSize(fontSize);
    doc.setTextColor(0, 82, 147);
    doc.text(i18next.t(LocalizationKeys.FiberMap), topLeft.x, pageContentStart);

    createFiberMapTable(doc, chips);
}

function createFiberMapTable (doc: jsPDF, chips: IChip[]) {
    const marginTop = topLeft.y + boxHeight + 20;
    const table = getOffscreenTable("#fiber-map-table");
    if (table) {
        autoTable(doc, {
            html: table,
            tableLineColor: 0x000000,
            tableLineWidth: 0,
            startY: pageContentStart + 20,
            showHead: "everyPage",
            showFoot: "never",
            pageBreak: "auto",
            styles: { 
                fontSize: sectionFontSize,
                fillColor: 0xFFFFFF,
                textColor: 0x000000,
                lineColor: 0x000000,
                lineWidth: 1,
                valign: "middle",
                cellPadding: { 
                    left: cellPadding,
                    right: cellPadding,
                    top: cellPadding / 4,
                    bottom: cellPadding / 4 
                },
                
            },
            willDrawCell: (data) => {
                const columnIndex = data.column.index;
                if (data.cell.section === "head" && (columnIndex === 1 || columnIndex === 5 || columnIndex === 7)) {
                    data.cell.styles.halign = "right";
                }
            },
            didDrawCell: function(data) {
                const columnIndex = data.column.index;
                if (data.cell.section === 'body' && (columnIndex === 4 || columnIndex === 10)) {
                    const fiberIndex = Number(data.cell.text[0]) - 1;
                    const chip = chips.find(c => c.index === fiberIndex % 12);
                    if (chip) {
                        const chipOffsetY = (data.cell.height - chipOriginalHeight) * 0.5;
                        doc.addImage({
                            imageData: chip.imageData,
                            format: "PNG",
                            x: data.cell.x + 8,
                            y:data.cell.y + chipOffsetY,
                            width: chipOriginalWidth,
                            height: chipOriginalHeight,
                            compression: "FAST",
                        })
                    }
                }
            },
            didParseCell: (data) => {
                const columnIndex = data.column.index;
                if (columnIndex === 5) {
                    data.cell.styles.fillColor = [224, 224, 224];
                }
            },
            columnStyles: {
                0: { halign: "left", cellWidth: boxWidth * 0.07 },
                1: { halign: "right", cellWidth: boxWidth * 0.06 },
                2: { halign: "left" },
                3: { halign: "left" },
                4: { halign: "right" },
                5: { halign: "right", cellWidth: boxWidth * 0.06 },
                6: { halign: "left", cellWidth: boxWidth * 0.07 },
                7: { halign: "right", cellWidth: boxWidth * 0.06 },
                8: { halign: "left" },
                9: { halign: "left" },
                10: { halign: "right" },
            },
            margin: { left: tableMargin, right: tableMargin, bottom: boxHeight * 2, top: marginTop },
        });
    }
}

function createRepresentativeExample(doc: jsPDF, y: number, translate: TFunction) {
    const [width] = pagesize;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor('black');
    doc.text(translate(LocalizationKeys.ReportRepresentativeExample), width - padding, y, { align: "right"});
}

async function generateChips(container: string): Promise<IChip[]> {
    const offscreen = document.body.querySelector("#offscreen-container") as HTMLDivElement;
    const fiberPositionChipContainer = offscreen.querySelector(container) as HTMLDivElement;
    const chipSVGs = fiberPositionChipContainer.getElementsByTagName("svg");

    const canvas = document.createElement('canvas');
    canvas.width = chipWidth;
    canvas.height = chipHeight;
    const ctx = canvas.getContext('2d')!;

    const chips: { index: number, imageData: string }[] = [];
    for (let index = 0; index < chipSVGs.length; index++) {
        const svg = chipSVGs[index];
        const image = await Canvg.from(ctx, svg.innerHTML);
        image.start();
        const imageData = canvas.toDataURL('image/png');
        chips.push({ index, imageData })
    }

    return chips;
}

function getOffscreenTable(tableSelector: string) {
    const offscreen = document.body.querySelector<HTMLDivElement>("#offscreen-container");
    let table = null;
    if (offscreen) {
        table = offscreen.querySelector<HTMLTableElement>(tableSelector)
    }

    return table;
}