import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sscSessionBusySelector, sscSessionConfigStatusSelector } from '../../../../../../redux/ssc/selectors';
import { LocalizationKeys } from '../../../../../../../locales/types';
import { disabledUISelector } from "../status/redux/selectors";
import { useCallback } from "react";
import { edsSessionService } from '../../../../../../services/eds-session/eds-session-service';
import { buildSessionIdSelector, currentBuildCatalogCodeSelector } from '../../../../../../selectors/build.selectors';
import { showViewProductSelector } from "../../../../redux/selectors";
import { setShowAnonymousUserWarning, setShowViewProduct } from "../../../../redux/reducers";
import { hybrisCustomerSelector, isAnonymousUserSelector } from '../../../../../authentication/redux/selectors';
import { GoogleTagManager, TagManagerConfigType } from '../../../../../../../tag-manager';
import { BuildService } from "../../../../../../services/build-service";
import { currentBuildSelector } from '../../../../../../selectors/root.selectors';
import { setCatalogCode } from "../../../../../../redux/build/reducers";
import { setSessionBusy, setSessionWarnings } from "../../../../../../redux/ssc/reducer";
import { setStatusState } from "../status/redux/reducer";
import { WorkspaceStatus } from "../status/redux/types";
import { setNotification } from "../../../notification/store/reducer";
import { AlertPalettes } from "@orbit/snackbar";

export const useViewProduct = () => {
    const { t } = useTranslation();
    const sessionBusy = useSelector(sscSessionBusySelector);
    const sessionConfigStatus = useSelector(sscSessionConfigStatusSelector);
    const disableUI = useSelector(disabledUISelector);
    const isAnonymousUser = useSelector(isAnonymousUserSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const label = t(LocalizationKeys.ViewProduct).toLocaleUpperCase();
    const tooltip = currentBuild?.catalogCode?.length ? t(LocalizationKeys.ExitToProductPage) : t(LocalizationKeys.GetCatalogCode);

    const dispatch = useDispatch();

    const disableButton = disableUI || sessionBusy || (!sessionConfigStatus && !currentBuild?.catalogCode?.length);

    const showViewProductDialog = useCallback(async () => {
        if (isAnonymousUser) {
            dispatch(setShowAnonymousUserWarning(true))
        }
        else {
            dispatch(setShowViewProduct(true));
        }
    }, [dispatch, isAnonymousUser]);

    return {
        label, tooltip, disableButton, showViewProductDialog
    };
};

export const useViewProductDialog = () => {
    const { t } = useTranslation();
    const sessionId = useSelector(buildSessionIdSelector);
    const customer = useSelector(hybrisCustomerSelector);
    const showViewProduct = useSelector(showViewProductSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const currentBuildCatalogCode = useSelector(currentBuildCatalogCodeSelector);
    const message = currentBuild?.catalogCode?.length ? t(LocalizationKeys.ViewProductRedirectMessage) : t(LocalizationKeys.ViewProductDialogMessage);
    const dispatch = useDispatch();

    const closeDialog = useCallback(() => {
        dispatch(setShowViewProduct(false))
    }, [dispatch]);

    const navigateToPDP = useCallback((catalogCode: string) => {
        if (customer && catalogCode.length) {
            const portals = customer.isAnonymous ? "" : "portals/";
            window.location.href = `/optical-communications/US/en/${portals}p/${catalogCode}`;
        }
    }, [customer]);

    const generateCatalogCodeAnd3C = useCallback(async () => {
        closeDialog();
        if (!sessionId) {
            navigateToPDP(currentBuildCatalogCode);
            return;
        }
        dispatch(setSessionBusy(true));
        dispatch(setStatusState(WorkspaceStatus.Saving))
        let catalogCode = currentBuildCatalogCode;
        if (!catalogCode.length) {
            const res = await edsSessionService.finalizeBuild(sessionId);
            if (res.succesful && res.data?.catalogCode) {
                catalogCode = res.data.catalogCode;
                if (currentBuild?.id) {
                    const service = new BuildService();
                    await service.updateBuildCatalogCode(currentBuild.id, catalogCode);
                }
                dispatch(setCatalogCode(catalogCode));
                dispatch(setSessionWarnings({ buildId: currentBuild?.id, warnings: [] }));
                if (customer) {
                    GoogleTagManager.event(TagManagerConfigType.Create, catalogCode, customer.account.uid, customer.account.name);
                }
                await edsSessionService.endSession(sessionId);
            }
            else {
                dispatch(setNotification({ message: t(LocalizationKeys.ErrorRetrievingCatalogCode), palette: AlertPalettes.error }));
            }
        }
        dispatch(setSessionBusy(false));
        dispatch(setStatusState(WorkspaceStatus.Saved))
        navigateToPDP(catalogCode);
    }, [dispatch, sessionId, customer, currentBuild, currentBuildCatalogCode, closeDialog, navigateToPDP, t]);

    const dialogHeaderProps = {
        title: t(LocalizationKeys.ViewProduct),
        closable: true
    };

    const continueProps = {
        label: t(LocalizationKeys.Continue),
        onClick: generateCatalogCodeAnd3C
    };

    return {
        showViewProduct, dialogHeaderProps, message, continueProps, closeDialog,
    };
};
