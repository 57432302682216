import React from "react";
import { useInputDialog } from "./hooks";
import { IInputDialogProps } from "./types";
import { DialogHeader } from '../../../../../../../../../../ui/dialog/header/DialogHeader';
import { FilledTextField } from '@orbit/text-field'
import { ContainedButton, TextButton } from '@orbit/button'
import { Dialog } from "@orbit/dialog";

import "./InputDialog.scss";

export const InputDialog: React.FC<IInputDialogProps> = (props) => {
    const { open, inputDisabled, maxCharacterCount, inputValue, onInputChanged, cancelProps, onCancelClick, saveProps, onAcceptClick, headerProps, helperText, inputFieldLabel } = useInputDialog(props);

    if (!open) {
        return null;
    }
    return (
        <Dialog className="input-dialog toggle-pointer-events" open={open} preventOutsideDismiss={true} onClose={onCancelClick} >
            <DialogHeader {...headerProps} />
            <div className="dialog-container">
                <FilledTextField palette="primary"
                    label={inputFieldLabel}
                    maxLength={maxCharacterCount}
                    value={inputValue}
                    helperText={inputDisabled ? helperText : ""}
                    error={inputDisabled}
                    onChange={e => onInputChanged(e)}/>
            </div>
            <div className="dialog-button-container">
                <TextButton className="cancel-button" onClick={onCancelClick}>{cancelProps.label}</TextButton>
                <ContainedButton className="save-button" disabled={inputDisabled} onClick={onAcceptClick} >{saveProps.label}</ContainedButton>
            </div>
        </Dialog>
    )
}