import * as PIXI from "pixi.js";
import { useCallback, useEffect, useState } from "react";
import { useText } from "../../../text/hooks";
import { drawArrow } from "../../hooks";
import { ILineMarkerProps } from "../line/types";

export const useBezierMarker = ({ path: { start, end, text: textPath }, thickness, color }: ILineMarkerProps) => {
    const { text, textStyle, textSize } = useText(textPath?.text);
    const [textPosition, setTextPosition] = useState({ x: start.x, y: end.y });

    useEffect(() => {
        const x = start.x - (textSize.width / 2) + 10;
        const y = end.y + 70;
        setTextPosition({ x, y })
    }, [textSize, start.x, end.y]);

    const drawBezierMarker = useCallback((g: PIXI.Graphics) => {
        g.clear();

        let p1 = { x: start.x, y: start.y };
        let p2 = { x: p1.x - 5, y: end.y + 102 };
        let p3 = { x: p1.x + 4.5, y: end.y + 92 };
        g.lineStyle(thickness, color);
        g.moveTo(p1.x, p1.y);
        g.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, p3.x, p3.y);
    
        p1 = { x: p3.x + 10, y: end.y + 67 };
        p2 = { x: p1.x + 20, y: end.y };
        p3 = { x: p1.x + 140, y: end.y };
        g.moveTo(p1.x, p1.y);
        g.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, p3.x, p3.y);
        g.lineTo(end.x, end.y);

        // Draw arrows
        drawArrow(g, end, "right", thickness, color);
        drawArrow(g, start, "bottom", thickness, color);

    }, [start, end, thickness, color]);

    return { text, textStyle, textPosition, drawBezierMarker };
};