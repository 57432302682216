import * as Pixi from 'pixi.js';
import { useCallback } from "react";
import { pixi2Image, getPixiDisplayObjects, extractImages } from "./CanvasExtactor";
import { ComponentName } from "../build/types";

const removeOffscreenElements = () => {
    let offscreen = document.querySelector('#offscreen-container');
    if (offscreen) {
        let imageContainer = document.querySelector('#eds-report-images');
        if (imageContainer) {
            for (let i = imageContainer.children.length - 1; i > -1; i--) {
                let image = imageContainer.children[i] as Node;
                if (imageContainer.children[i].id !== "cable-trunk-length-table" || "" )
                imageContainer.removeChild(image);
            }   
        }
    }
};

const pixiCanvas2htmlCanvas = (...componentNames: ComponentName[]) => {
    const imageElements = pixi2Image(...componentNames);
    const imageElementsValue = Object.values(imageElements).reduce((a, b) => [...a, ...b], []);

    styleImageElements(imageElementsValue);

    return imageElements;
}

const styleImageElements = (imageElements: HTMLCanvasElement[]) => {
    imageElements.forEach((imageElement) => {        
        imageElement.setAttribute("style", "background-color:white;")
    });
}

const queryBuildComponents = (...componentNames: ComponentName[]) => {
    return getPixiDisplayObjects(...componentNames);
}

const getDropGraphics = () => {
    const displayObjects = queryBuildComponents("document-drop")
    let dropContainers = displayObjects["document-drop"];

    if (dropContainers.length > 0) {
        return (dropContainers[0] as Pixi.Container).children
    }

    return [];
}

const getDropImageElements = () => {
    const dropGraphics = getDropGraphics();
    const imageElements = extractImages(dropGraphics)

    styleImageElements(imageElements);

    return imageElements;
}

export type PixiImageMap = ReturnType< typeof pixiCanvas2htmlCanvas>


export const useCanvasExtract = () => {
    const extractCanvas = useCallback(() => {
        let offscreen = document.querySelector('#offscreen-container');
        if (offscreen) {
            removeOffscreenElements();
            let imageContainer = document.querySelector('#eds-report-images');
            if (imageContainer) {
                const imageElements = pixiCanvas2htmlCanvas("eds-container", "source-container");
                const dropImages = getDropImageElements();
                let imageElementsValue = Object.values(imageElements).reduce((a, b) => [...a, ...b], []);
                imageElementsValue.push(...dropImages);
                imageElementsValue.forEach((imageElement) => {
                    imageContainer!.appendChild(imageElement);
                });
            }
        }
    }, [])

    return { extractCanvas, pixiCanvas2image: pixiCanvas2htmlCanvas };
}