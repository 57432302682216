import { IConnectorReportState } from './types';
import { PayloadAction } from "@reduxjs/toolkit";
import { IConnectorAssignmentMap } from '../../polarity/connector-assignment/redux/types';

export const setSelectedRowsAction = (state: IConnectorReportState, action: PayloadAction<number[]>) => {
    state.selectedRowIds = action.payload;
};

export const toggleRowSelectionAction = (state: IConnectorReportState, action: PayloadAction<number>) => {
    const rowId = action.payload;
    if (state.selectedRowIds.includes(rowId)) {
        state.selectedRowIds = state.selectedRowIds.filter(r => r !== rowId);
    }
    else {
        state.selectedRowIds.push(rowId);
    }
};

export const clearSelectionAction = (state: IConnectorReportState) => {
    state.selectedRowIds = [];
};

export const setLabelChangedAction = (state: IConnectorReportState, action: PayloadAction<boolean>) => {
    state.labelChanged = action.payload;
};

export const setTriggerColorsChangedAction = (state: IConnectorReportState, action: PayloadAction<boolean>) => {
    state.triggerColorsChanged = action.payload;
};

export const setPropagationMappingAction = (state: IConnectorReportState, action: PayloadAction<IConnectorAssignmentMap[]>) => {
    state.propagationMapping = action.payload;
};