import { IDialogState } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";

export const setDialogStateAction = (state: IDialogState, action: PayloadAction<Partial<IDialogState>>) => {
    const newState = action.payload;
    if (newState.type !== undefined) state.type = newState.type;
    if (newState.props !== undefined) state.props = newState.props;
};

export const showDialogAction = (state: IDialogState, action: PayloadAction<boolean>) => {
    const showDialog = action.payload;
    state.props.open = showDialog;
    if (showDialog && state.props.collapsed) {
        state.props.collapsed = false;
    }
};

export const collapseDialogAction = (state: IDialogState, action: PayloadAction<boolean>) => {
    state.props.collapsed = action.payload;
};