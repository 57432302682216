import { Container } from "@inlet/react-pixi";
import { LineMarker } from "../../line/LineMarker";
import { ILengthProps } from "../types";

export const BLength: React.FC<ILengthProps> = (props: ILengthProps) => {
    const { start, line, end } = props;

    return (
        <Container>
            { start ? <LineMarker {...start} /> : null }
            { line ? <LineMarker {...line} /> : null }
            { end ? <LineMarker {...end} /> : null }
        </Container>
    )
};