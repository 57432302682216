import { useContext } from "react";
import { MarkerStyleContext } from "./types";
import { TrunkContext } from "../../../../workspace/redux/build/types";
import { ILengthProps } from "./components/length/types";
import { ILineMarkerProps, Side, Sides } from "./components/line/types";
import { Graphics } from "pixi.js";
import { IPoint } from "../../../types";

export const useMarkers = () => {
    const { position, markerProps } = useContext(TrunkContext);
    const { thickness, color: markerColor } = useContext(MarkerStyleContext);
    const { pathStartA, pathStartB, 
            pathA, pathB, 
            pathEndA, pathEndB, 
            staggerMarkerPaths, staggerLinePaths } = markerProps;

    const startA: ILineMarkerProps | undefined = pathStartA ? {
        path: pathStartA,
        orientation: "vertical",
        thickness,
        color: markerColor
    } : undefined;

    const a: ILineMarkerProps | undefined = pathA ? {
        path: pathA,
        orientation: "horizontal",
        direction: "bidirectional",
        thickness,
        color: markerColor
    } : undefined;

    const endA: ILineMarkerProps | undefined = pathEndA ? {
        path: pathEndA,
        orientation: "vertical",
        thickness,
        color: markerColor
    } : undefined;
    
    const aLengthProps: ILengthProps = {
        position,
        start: startA,
        line: a,
        end: endA
    };

    const startB: ILineMarkerProps | undefined = pathStartB ? {
        path: pathStartB,
        orientation: "horizontal", 
        thickness,
        color: markerColor
    } : undefined;

    const b: ILineMarkerProps | undefined = pathB ? {
        path: pathB,
        orientation: "vertical",
        direction: "bidirectional",
        thickness,
        color: markerColor
    } : undefined;

    const endB: ILineMarkerProps | undefined = pathEndB ? {
        path: pathEndB,
        orientation: "horizontal",
        thickness,
        color: markerColor
    } : undefined;

    const bLengthProps: ILengthProps = {
        position,
        start: startB,
        line: b,
        end: endB
    };

    const staggerMarkers: ILineMarkerProps[] = staggerMarkerPaths && staggerMarkerPaths.length > 0 
        ? staggerMarkerPaths.map((m, i) => ({ key: `stagger-marker-${i}`, path: m, color: markerColor, thickness, orientation: "vertical", direction: "end" }))
        : [];

    const staggerLines: ILineMarkerProps[] = staggerLinePaths && staggerLinePaths.length > 0
        ? staggerLinePaths.map((m, i) => ({ key: `stagger-line-${i}`, path: m, color: markerColor, thickness, orientation: "horizontal" }))
        : [];

    return { aLengthProps, bLengthProps, staggerLines, staggerMarkers };
};

export const drawArrow = (g: Graphics, point: IPoint, side: Side, thickness: number, color: number) => {
    const offX = 6;
    const offY = 3;
    const { x, y } = point;
    let start = { x, y };
    let end = { x, y };

    calculateArrowPoints(side, start, offY, end, offX);

    g.lineStyle(thickness, color);
    g.moveTo(start.x, start.y);
    g.lineTo(x, y);
    g.lineTo(end.x, end.y);
};

const calculateArrowPoints = (side: string, start: IPoint, offY: number, end: IPoint, offX: number) => {
    if (side === Sides.Left || side === Sides.Right) {
        start.y += offY;
        end.y -= offY;
        if (side === Sides.Right) {
            start.x -= offX;
            end.x -= offX;
        }
        else {
            start.x += offX;
            end.x += offX;
        }
    }
    else if (side === Sides.Top || side === Sides.Bottom) {
        start.x += offY;
        end.x -= offY;
        if (side === Sides.Bottom) {
            start.y -= offX;
            end.y -= offX;
        }
        else {
            start.y += offX;
            end.y += offX;
        }
    }
}
