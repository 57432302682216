import { useConnectorReport } from './hooks';
import { Table, TableHead, TableRow, TableCell, TableBody, LinearProgress } from '@mui/material';
import { ToolItem } from '../../../../ToolItem';
import { NavigationBar } from '../../../../../../../ui/navigation/NavigationBar';
import { Checkbox } from "@orbit/checkbox";
import { ConnectorRow } from './row/ConnectorRow';
import { ColorDialog } from '../../../../../../../ui/dialog/color/ColorDialog';
import { CollapsibleDialog } from '../../../../../../../ui/dialog/collapsible/CollapsibleDialog';

import './ConnectorReport.scss';

export const ConnectorReport = () => {
    const { footnote, manageLabelScheme, rows, onManageClick, navigationBarProps, masterCheckboxProps, tableHeaderProps, colorDialogProps, collapsibleDialogProps, editDisabled, loading, triggerManagementProps } = useConnectorReport();
    return (
        <CollapsibleDialog {...collapsibleDialogProps} >
            <ColorDialog {...colorDialogProps} />
            {loading ? <LinearProgress /> : null}
            <div className="table-container">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className="selection-column">
                                <Checkbox palette="primary" {...masterCheckboxProps} />
                            </TableCell>
                            <TableCell align="right" className="connector-column">{tableHeaderProps.connectorTitle}</TableCell>
                            <TableCell align="right" className="group-column">{tableHeaderProps.groupTitle}</TableCell>
                            <TableCell align="left" className="connector-color-column">
                                <div className="table-header-container">
                                    {tableHeaderProps.connectorColorTitle}
                                    <ToolItem icon="palette" disabled={tableHeaderProps.connectorColorDisabled} onClick={tableHeaderProps.connectorColorClick} />
                                </div>
                            </TableCell>
                            <TableCell align="left" className="label-column">{tableHeaderProps.labelTitle}</TableCell>
                            <TableCell align="right" className="b-length-column">{tableHeaderProps.bTitle}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => <ConnectorRow key={row.data.id} {...row} />)}
                    </TableBody>
                </Table>
            </div>
            <NavigationBar {...navigationBarProps}/>
            <div className="connector-report-footer">
                <div className="footnote">{footnote}</div>
                <div>
                    { triggerManagementProps.display && <ToolItem {...triggerManagementProps.buttonProps} /> }
                    <ToolItem className="manage-button" text={manageLabelScheme} onClick={onManageClick} disabled={editDisabled} />
                </div>
            </div>
        </CollapsibleDialog>
    );
}