import { createContext } from "react";
import { TrunkData } from "../../../../../../../../redux/build/types";

export interface IBLengthProps {
    trunk: TrunkData;
    disabled?: boolean;
}

interface ICustomBLengthContext {
    trunk: TrunkData;
    isCustom: boolean
}

const initialContext: ICustomBLengthContext = {
    trunk: {},
    isCustom: false
}

export const BLengthContext = createContext(initialContext)
