import { IPropagationOptions, PropagationState } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";

export const showPropagationDialogAction = (state: PropagationState, action: PayloadAction<boolean>) => {
    state.showDialog = action.payload;
};

export const setPropagationStateAction = (state: PropagationState, action: PayloadAction<IPropagationOptions>) => {
    const { propagateColors, propagateLabels } = action.payload;
    state.propagateColors = propagateColors;
    state.propagateLabels = propagateLabels;
    state.isActive = propagateColors || propagateLabels;
};

export const setColorsPropagationStateAction = (state: PropagationState, action: PayloadAction<boolean>) => {
    state.propagateColors = action.payload;
};

export const setLabelsPropagationStateAction = (state: PropagationState, action: PayloadAction<boolean>) => {
    state.propagateLabels = action.payload;
};

export const resetPropagationAction = (state: PropagationState) => {
    state.isActive = false;
    state.propagateColors = false;
    state.propagateLabels = false;
};