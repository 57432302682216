import { AuthenticationContext, AdalConfig, runWithAdal } from 'react-adal';
import { detect } from 'detect-browser';
import MobileDetect from 'mobile-detect';

const config = {
    devAPIBaseUrl: 'https://localhost:5001/',
    prodAPIBaseUrl: ''
};

const authConfig:AdalConfig = {
    // DNA
    tenant: '503a95f5-ea35-4de1-93de-f9422ae99d05',
    clientId: '9059fe06-0381-4d96-a8cf-62d35fe06bfc', // clientID of the application

    //redirectUri: '',
    endpoints: {
        //api: '9059fe06-0381-4d96-a8cf-62d35fe06bfc' // clientID of the API
    },
    //cacheLocation: 'localStorage'
};

export const authContext = new AuthenticationContext(authConfig);

export const detectBrowser = (): void => {
    const browser = detect();
    const md = new MobileDetect(window.navigator.userAgent);

    if (md.phone()) {
        window.alert('The EDS tool is not optimized for mobile viewing. For the best experience, please use the desktop version.');
    }
    else if (!(browser && (browser.name === 'chrome' || browser.name === 'safari' || browser.name === 'firefox' || browser.name === 'edge' || browser.name === 'edge-chromium'))) {
        window.alert('The EDS tool is optimized for Chrome, Edge, Firefox, and Safari. You may experience issues with other browsers.');
    }
}

export const isDemoBuild = (): boolean => {
    return process.env.REACT_APP_ENV === "demo" || process.env.NODE_ENV === "development" || process.env.REACT_APP_ENV === "dev";
}

export class Utils {
    static runWithAuthentication(app: ()=> void) {
        runWithAdal(authContext, app, false);
    }


    static isDevelopmentEnvironment() {
        return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    }

    static isHybrisEnvironment = (): boolean => {
        return !!(window as any).hybriscustomer;
    }

    static getToken() {
        return authContext.getCachedToken(authConfig.clientId);
    }

    static getUser() {
        return authContext.getCachedUser();
    }

    static getAPIBaseUrl() {
        return this.isDevelopmentEnvironment() ? config.devAPIBaseUrl : config.prodAPIBaseUrl;
    }

    static authenticatedFetch(query: string, body: any = undefined) {
        var url = Utils.getAPIBaseUrl() + query;
        var opts: RequestInit = {
            //method: method,
            headers: {
                'Authorization': 'Bearer ' + Utils.getToken()
            }
        }
        if (body) {
            opts.body = JSON.stringify(body);
            const headers = opts.headers as Headers;
            headers.set('Content-Type', 'application/json');
        }
        return fetch(url, opts)
            .then(response => response.json())
            .catch(console.error);
    }
}

