import { createSelector } from "@reduxjs/toolkit";
import { workspaceSelector } from "../../selectors/root.selectors";
import { Sorts } from "../../components/overlay/components/projects/redux/types";

export const projectManagerSelector = createSelector(
    workspaceSelector,
    workspace => workspace.projectManager
);

const projectManagerDataSelector = createSelector(
    projectManagerSelector,
    (pM) => pM.data
);

export const totalBuildCountSelector = createSelector(
    projectManagerDataSelector,
    pmData => pmData.totalBuildCount ?? 0
)

export const projectManagerSortTypeSelector = createSelector(
    projectManagerDataSelector,
    data => { return { sortType: data.sortType && data.sortType.length ? data.sortType : Sorts.DateModified, ascending: data.isSortAscending ?? true} }
)


export const displayProjectManagerSelector = createSelector(
    projectManagerSelector,
    (pM) => pM.display
);

export const showCableDetailsSelector = createSelector(
    projectManagerSelector,
    (pM) => pM.showDetails
);

export const showCableInfoSelector = createSelector(
    projectManagerSelector,
    (pM) =>  pM.showInfo
);

export const selectedBuildSelector = createSelector(
    projectManagerSelector,
    (pM) => pM.selectedBuild
);

export const selectedBuildIdSelector = createSelector(
    projectManagerSelector,
    (pM) => pM.selectedBuildId
);

export const isConnectorEditabledSelector = createSelector(
    projectManagerDataSelector,
    (data) => data.editableConnectors
)