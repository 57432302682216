import React from "react";
import { useTranslation } from "react-i18next"
import { LocalizationKeys } from "../../../../../../../../locales/types";

export const Locked = () => {
    const { t } = useTranslation();

    return (
    <svg id="Status_loading" data-name="Status loading" xmlns="http://www.w3.org/2000/svg" width="101" height="32" viewBox="0 0 101 32">
        <rect id="Rectangle_610" data-name="Rectangle 610" width="101" height="32" rx="16" fill="#aaa" opacity="0.8"/>
        <text id="Locked" transform="translate(44 21)" fill="#fdfdfd" font-size="14" font-family="TheSansC4s" font-weight="300">
            <tspan x="0" y="0">{t(LocalizationKeys.Locked)}</tspan>
        </text>
        <g id="icon_lock" data-name="icon lock" transform="translate(12 4)">
            <path id="Path_133" data-name="Path 133" d="M0,0H24V24H0Z" fill="none"/>
            <path id="Path_134" data-name="Path 134" d="M18,8H17V6A5,5,0,0,0,7,6V8H6a2.006,2.006,0,0,0-2,2V20a2.006,2.006,0,0,0,2,2H18a2.006,2.006,0,0,0,2-2V10A2.006,2.006,0,0,0,18,8Zm-6,9a2,2,0,1,1,2-2A2.006,2.006,0,0,1,12,17Zm3.1-9H8.9V6a3.1,3.1,0,0,1,6.2,0Z" fill="#fdfdfd"/>
        </g>
    </svg>
    )
}