import React from 'react';
import { ToolItem } from "../../../../../../../ToolItem";
import { useCollapseButton } from "./hooks";
import icon_collapse_disabled from '../resources/icons/icon_collapse_disabled.svg'
import icon_collapse from '../resources/icons/icon_collapse.svg'


export const CollapseButton = (props: { tooltip?: string, disabled?: boolean, onClick?: () => void }) => {
    const { disabled, tooltip, onClick } = props;
    const { triggerCollapse } = useCollapseButton();
    return (
        <ToolItem source={getCollapseIcon(disabled)} alt="Collapse" tooltip={tooltip ? tooltip : "Collapse"} labelTop={true} disabled={disabled} onClick={onClick ? onClick : triggerCollapse} />    
    );
}

function getCollapseIcon(disabled?: boolean) : string {
    return disabled ? icon_collapse_disabled : icon_collapse;
}