import { createSelector } from "@reduxjs/toolkit";
import { workspaceSelector } from "../../../selectors/root.selectors";

export const authenticationSelector = createSelector(
    workspaceSelector,
    workspace => workspace.authentication
);

export const loggedInSelector = createSelector(
    authenticationSelector,
    authentication => authentication.loggedIn
);

export const userIdSelector = createSelector(
    authenticationSelector,
    (authentication) => authentication.userId
);

export const hybrisCustomerSelector = createSelector(
    authenticationSelector,
    (authentication) => authentication.customer
);

export const isAnonymousUserSelector = createSelector(
    authenticationSelector,
    (authentication) => !!authentication.customer?.isAnonymous
);

export const groupIdSelector = createSelector(
    authenticationSelector, 
    (authentication) => authentication.groupId
);