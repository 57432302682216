import { createSlice } from "@reduxjs/toolkit";
import { resetHoveredConnectorAction,
        setHoveredConnectorAction,
        setLastClickedConnectorAction,
        setHighlightsAction, 
        addOrUpdateHighlightsAction} from "./actions";
import { initialConnectorsState } from "./types";

const connectorHighlightsSlice = createSlice({
    name: "connector-highlights",
    initialState: initialConnectorsState,
    reducers: {
        setHoveredConnector: setHoveredConnectorAction,
        resetHoveredConnector: resetHoveredConnectorAction, 
        setLastClickedConnector: setLastClickedConnectorAction,
        setHighlights: setHighlightsAction,
        addOrUpdateHighlights: addOrUpdateHighlightsAction
    }
});

export const ConnectorHighlightsReducer = connectorHighlightsSlice.reducer;

export const { 
    setHoveredConnector,
    resetHoveredConnector,
    setLastClickedConnector,
    setHighlights,
    addOrUpdateHighlights
} = connectorHighlightsSlice.actions;