import AtoA from './resources/Type A-A LC-LC.png';
import AtoB from './resources/Type A-B LC-LC.png';
import TypeUMTP8 from './resources/Type-U MTP-LC 8f.png';
import TypeUMTP12 from './resources/Type U MTP_LC 12f Breakout.png';
import TypeAMTP24 from './resources/Type A MTP_LC 24f Breakout.png';
import TypeBMTP8 from './resources/Type B MTP_MTP 8f.png';
import TypeAMTP8 from './resources/Type A MTP_MTP 8f.png';
import Mesh4x4 from './resources/Type 4x4 Mesh MTP_MTP.png';
import TypeBMTP12 from './resources/Type B MTP_MTP 8f.png';
import TypeAMTP12 from './resources/Type A MTP_MTP.png';
import TypeAMTP24MTP4 from './resources/Type A MTP24_MTP24.png';
import TypeBMTP24MTP24 from './resources/Type B MTP24_MTP24.png';
import TypeYMTP24MTP24 from './resources/Type Y MTP24_MTP24.png';
import TypeZMTP24MTP24 from './resources/Type Z MTP24_MTP24.png';
import TypeAMTP24MTP8 from './resources/Type A MTP24_MTP8.png';
import TypeAMTP24MTP12 from './resources/Type A MTP24_MTP12.png';
import TypeBMTP24MTP12 from './resources/Type B MTP24_MTP12.png';
import TypeLMTP24MTP12 from './resources/Type L MTP24_MTP12.png';


import { Identifiable, AddPolarityRequestDTO, UpdatePolarityRequestDTO } from "../../../../services/types";
import { ConnLC, ConnMTP12_NP, ConnMTP24_P, ConnMTP8_NP, IConnectorType } from '../../../../components/overlay/components/wizard/redux/types';
import { AssignedIndexCodes, connectorModelToConnectorType, CustomFiberMapData, IDocumentFiberMap, IFiberMapData, IFiberMapIndex } from './fiber-map/types';
import { IConnectorAssignmentMap, IConnectorMap } from '../../../../components/overlay/components/polarity/connector-assignment/redux/types';
import { getCurrentISODate } from '../../../../services/build-service';
import { TFunction } from 'i18next';
import { LocalizationKeys } from '../../../../../locales/types';
import { PolarityAssignment } from '../../../../components/overlay/components/polarity/connector-assignment/types';
import { IPropagationOptions } from '../../../../components/overlay/components/polarity/propagation/redux/types';

interface IPolarityConfig {
    from: IConnectorType,
    to: IConnectorType,
    description: string,
    polarityMaps: PolarityMap[]
}

interface IBuildPolarity {
    from: IConnectorType,
    to: IConnectorType,
    description: string,
    polarityMap: PolarityMap
}

interface IForceReload {
    force: boolean;
    buildId?: number;
}

export interface IPolarityMap {
    userId: number,
    key: number,
    customKey: string,
    description: string,
    descriptionLocalizationKey: string,
    imageUri: string,
    sourceConnectors: IConnectorType[],
    destinationConnectors: IConnectorType[],
    versionDate: string,
    sessionId: string,
    demoKey: string,
    sessionKey: string
}

interface IRequiredPolarityMapFields {
    sourceConnectors: IConnectorType[],
    destinationConnectors: IConnectorType[],
}

const mtp8 = { ...ConnMTP8_NP };
const mtp12 = { ...ConnMTP12_NP };
const lc = { ...ConnLC };
const mtp24 = { ...ConnMTP24_P };

interface IBuildPolarityPair {
    buildId: number,
    polarities: BuildPolarity[]
}

export interface IConnectoryCombination {
    source: IConnectorType,
    destination: IConnectorType
}

export type PolarityConfig = Partial<IPolarityConfig & Identifiable>
export type PolarityMap = Partial<IPolarityMap & Identifiable> & Required<IRequiredPolarityMapFields>
export type BuildPolarity = Partial<IBuildPolarity & Identifiable>
export type BuildPolarityPair = Partial<IBuildPolarityPair>;

export const initialPolarityMap: PolarityMap = {
    key: -1,
    description: "",
    imageUri: "",
    sourceConnectors: [],
    destinationConnectors: []
}

export interface IPolarityConnectorMapDTO {
    id?: number,
    polarityMap: string,
    connectors: IConnectorMap[],
}

export interface IPolarityFiberMapDTO {
    id?: number,
    userId?: number,
    groupId?: number,
    name: string,
    connectorFiberPinMapping: IConnectorFiberPinMapDTO[],
    versionDate: string,
    polarityKey?: string
}

export interface IConnectorFiberPinMapDTO {
    id?: number,
    assignedIndex: number,
    index: number,
    blocked: boolean,
    connectorType: string,
    position?: number,
}

export interface IPolarityBuildDTO {
    id?: number,
    polarityConnectorMaps: IPolarityConnectorMapDTO[]
}

export function toAddPolarityRequestDTO(buildId: number, assignment: PolarityAssignment, maps: IConnectorAssignmentMap[], options: IPropagationOptions) : AddPolarityRequestDTO {
    const fiberMap = maps.length > 0 ? maps[0].fiberMap : undefined;
    return {
        buildId,
        assignment,
        maps: maps.map(m => toPolarityConnectorMapDTO(m)),
        fiberMap: toPolarityFiberMapDTO(fiberMap),
        options
    }
}



export function toUpdatePolarityRequestDTO(buildId: number, connectorMap: IConnectorAssignmentMap, options: IPropagationOptions) : UpdatePolarityRequestDTO {
    return {
        buildId,
        connectorMap: toPolarityConnectorMapDTO(connectorMap),
        fiberMap: toPolarityFiberMapDTO(connectorMap.fiberMap),
        options
    }
}

export function toPolarityConnectorMapDTO(map: IConnectorAssignmentMap): IPolarityConnectorMapDTO {
    return {
        id: map.id,
        polarityMap: map.polarityType,
        connectors: [...map.sourceMapping, ...map.destinationMapping],
    }
}

export function toPolarityFiberMapDTO(fiberMap?: CustomFiberMapData): IPolarityFiberMapDTO | undefined {
    if (!fiberMap) return undefined;
    
    return {
        id: fiberMap.id ?? 0,
        name: fiberMap.name.length > 0 ? fiberMap.name : "Custom",
        versionDate: fiberMap.versionDate ?? getCurrentISODate(),
        userId: fiberMap.userId,
        groupId: fiberMap.groupId,
        connectorFiberPinMapping: toConnectorFiberPinMappingListDTO(fiberMap),
        polarityKey: GetPolarityKey(fiberMap.key)
    }
}

export function toConnectorFiberPinMappingListDTO(fiberMap: CustomFiberMapData): IConnectorFiberPinMapDTO[] {
    const { sourceIndices, destinationIndices } = fiberMap;

    if (sourceIndices.length === 0 && destinationIndices.length === 0) return [];

    const sourcePinMaps = sourceIndices.map(s => toFiberPinMappingDTO(s));
    const destinationPinMaps = destinationIndices.map(d => toFiberPinMappingDTO(d));

    return [...sourcePinMaps, ...destinationPinMaps];
}

function toFiberPinMappingDTO(fiberIndex: IFiberMapIndex) : IConnectorFiberPinMapDTO {
    const { connector } = fiberIndex;
    const connectorType = connectorModelToConnectorType(connector);
    const connectorTypeString = connectorType && connectorType.type ? connectorType.type : "";

    return {
        id: fiberIndex.id,
        blocked: fiberIndex.assignedIndex === AssignedIndexCodes.Blocked,
        assignedIndex: fiberIndex.assignedIndex,
        index: fiberIndex.index,
        connectorType: fiberIndex.connectorType || connectorTypeString,
        position: fiberIndex.transceiver
    }
}

export function toConnectorAssignmentMap(dto: IPolarityConnectorMapDTO): IConnectorAssignmentMap {
    const sourceMapping: IConnectorMap[] = dto.connectors.filter(c => c.position === 0).map(c => {
        return {
            ...c,
        }
    });
    const destinationMapping: IConnectorMap[] = dto.connectors.filter(c => c.position !== 0).map(c => {
        return {
            ...c,
        }
    });

    return {
        id: dto.id,
        polarityType: dto.polarityMap,
        sourceMapping,
        destinationMapping
    }
}

export function toAssignmentMapping(map: IPolarityBuildDTO): IConnectorAssignmentMap[] {
    return map.polarityConnectorMaps.map(m => toConnectorAssignmentMap(m))
}

export const SELECT_MAP_KEY = -1;
export const ATOA_KEY = 0;
export const ATOB_KEY = 1;
export const TYPEU_MTP8_KEY = 2;
export const TYPEU_MTP12_KEY = 3;
export const TYPEA_MTP24 = 4;
export const TYPEB_MTP8 = 5;
export const TYPEA_MTP8 = 6;
export const MESH_4x4_MAP = 7;
export const TYPEB_MTP12 = 8;
export const TYPEA_MTP12 = 9;
export const TYPEB_MTP8ToLC = 10;
export const TYPEB_MTP12ToLC = 11;
export const TYPEA_MTP24ToMTP4 = 12;
export const TYPEB_MTP24ToMTP24 = 13;
export const TYPEY_MTP24ToMTP24 = 14;
export const TYPEZ_MTP24ToMTP24 = 15;
export const TYPEA_MTP24ToMTP8 = 16;
export const TYPEA_MTP24ToMTP12 = 17;
export const TYPEB_MTP24ToMTP12 = 18;
export const TYPEL_MTP24ToMTP12 = 19;
export const CUSTOM_MAP_KEY = 9999;
export const SESSION_MAP_KEY = 9000;

const Type_A_A = 'Type A-A'
const Type_A_B = 'Type A-B'
const MTP8_LC_Type_U = 'MTP8_LC Type U'
const MTP12_LC_Type_U = 'MTP12_LC Type U'
const MTP24_Type_A = 'MTP24 Type A'
const MTP8_Type_B = 'MTP8 Type B'
const MTP8_Type_A = 'MTP8 Type A'
const MTP8_Type_4x4_Mesh = 'MTP8 Type 4x4 Mesh'
const MTP12_Type_B = 'MTP12 Type B'
const MTP12_Type_A = 'MTP12 Type A'
const MTP8_LC_Type_B = 'MTP8_LC Type B'
const MTP12_LC_Type_B = 'MTP12_LC Type B'
const MTP24_MTP24_Type_A = 'MTP24_MTP24 Type A'
const MTP24_MTP24_Type_B = 'MTP24_MTP24 Type B'
const MTP24_MTP24_Type_Y = 'MTP24_MTP24 Type Y'
const MTP24_MTP24_Type_Z = 'MTP24_MTP24 Type Z'
const MTP24_MTP8_Type_A = 'MTP24_MTP8 Type A'
const MTP24_MTP12_TYPEA = 'MTP24_MTP12 Type A'
const MTP24_MTP12_TYPEB = 'MTP24_MTP12 Type B'
const MTP24_MTP12_TYPEL = 'MTP24_MTP12 Type L'

export const SelectMap: PolarityMap = { descriptionLocalizationKey: LocalizationKeys.SelectPolarity, key: SELECT_MAP_KEY, destinationConnectors: [], sourceConnectors: [] }
export const AtoAmap: PolarityMap = { description: "A to A", imageUri: AtoA, key: ATOA_KEY, sourceConnectors: [lc], destinationConnectors: [lc], sessionId: '02_02_A', sessionKey: "02F-to-02F (Type A-A)", demoKey: Type_A_A }
export const AtoBMap: PolarityMap = { description: "A to B", imageUri: AtoB, key: ATOB_KEY, sourceConnectors: [lc], destinationConnectors: [lc], sessionId: '02_02_B', sessionKey: "02F-to-02F (Type A-B)", demoKey: Type_A_B }
export const TypeUMTP8Map: PolarityMap = { description: "Type U", imageUri: TypeUMTP8, key: TYPEU_MTP8_KEY, sourceConnectors: [mtp8], destinationConnectors: new Array(4).fill(lc), sessionId: '08_02X4_U', sessionKey: "08F-to-02x4F (Type U)", demoKey: MTP8_LC_Type_U }
export const TypeUMTP12Map: PolarityMap = { description: "Type U", imageUri: TypeUMTP12, key: TYPEU_MTP12_KEY, sourceConnectors: [mtp12], destinationConnectors: new Array(6).fill(lc), sessionId: '12_02X6_U', sessionKey: "12F-to-02x6F (Type U)", demoKey: MTP12_LC_Type_U }
export const TypeAMTP24Map: PolarityMap = { description: "Type A", imageUri: TypeAMTP24, key: TYPEA_MTP24, sourceConnectors: [mtp24], destinationConnectors: new Array(12).fill(lc), sessionId: '24_02X12_A', sessionKey: "24F-to-02Fx12 (Type A)", demoKey: MTP24_Type_A }
export const TypeBMTP8Map: PolarityMap = { description: "Type B", imageUri: TypeBMTP8, key: TYPEB_MTP8, sourceConnectors: [mtp8], destinationConnectors: [mtp8], sessionId: '08_08_B', sessionKey: "08F-to-08F (Type B)", demoKey: MTP8_Type_B }
export const TypeAMTP8Map: PolarityMap = { description: "Type A", imageUri: TypeAMTP8, key: TYPEA_MTP8, sourceConnectors: [mtp8], destinationConnectors: [mtp8], sessionId: '08_08_A', sessionKey: "08F-to-08F (Type A)", demoKey: MTP8_Type_A }
export const Mesh4x4Map: PolarityMap = { description: "Type 4x4 Mesh", imageUri: Mesh4x4, key: MESH_4x4_MAP, sourceConnectors: new Array(4).fill(mtp8), destinationConnectors: new Array(4).fill(mtp8), sessionId: '08X4_08X4_B_4X4', sessionKey: "08Fx4-to-08Fx4 (Type B, 4x4 Mesh)", demoKey: MTP8_Type_4x4_Mesh }
export const TypeBMTP12Map: PolarityMap = { description: "Type B", imageUri: TypeBMTP12, key: TYPEB_MTP12, sourceConnectors: [mtp12], destinationConnectors: [mtp12], sessionId: '12_12_B', sessionKey: "12F-to-12F (Type B)", demoKey: MTP12_Type_B}
export const TypeAMTP12Map: PolarityMap = { description: "Type A", imageUri: TypeAMTP12, key: TYPEA_MTP12, sourceConnectors: [mtp12], destinationConnectors: [mtp12], sessionId: '12_12_A', sessionKey: "12F-to-12F (Type A)", demoKey: MTP12_Type_A }
export const TypeBMTP8ToLC: PolarityMap = { description: "Type B", key: TYPEB_MTP8ToLC, sourceConnectors: [mtp8], destinationConnectors: new Array(4).fill(lc), demoKey: MTP8_LC_Type_B }
export const TypeBMTP12ToLC: PolarityMap = { description: "Type B", key: TYPEB_MTP12ToLC, sourceConnectors: [mtp12], destinationConnectors: new Array(6).fill(lc), demoKey: MTP12_LC_Type_B }
export const TypeAMTP24ToMTP24: PolarityMap = { description: "Type A", imageUri: TypeAMTP24MTP4, key: TYPEA_MTP24ToMTP4, sourceConnectors: [mtp24], destinationConnectors: [mtp24], sessionId: '24_24_A', sessionKey: "24F-to-24F (Type A)", demoKey: MTP24_MTP24_Type_A }
export const TypeBMTP24ToMTP24: PolarityMap = { description: "Type B", imageUri: TypeBMTP24MTP24, key: TYPEB_MTP24ToMTP24, sourceConnectors: [mtp24], destinationConnectors: [mtp24], sessionId: '24_24_B', sessionKey: "24F-to-24F (Type B)", demoKey: MTP24_MTP24_Type_B }
export const TypeYMTP24ToMTP24: PolarityMap = { description: "Type Y", imageUri: TypeYMTP24MTP24, key: TYPEY_MTP24ToMTP24, sourceConnectors: [mtp24], destinationConnectors: [mtp24], sessionId: '24_24_Y', sessionKey: "24F-to-24F (Type Y)", demoKey: MTP24_MTP24_Type_Y }
export const TypeZMTP24ToMTP24: PolarityMap = { description: "Type Z", imageUri: TypeZMTP24MTP24, key: TYPEZ_MTP24ToMTP24, sourceConnectors: [mtp24], destinationConnectors: [mtp24], sessionId: '24_24_Z', sessionKey: "24F-to-24F (Type Z)", demoKey: MTP24_MTP24_Type_Z }
export const TypeAMTP24ToMTP8: PolarityMap = { description: "Type A", imageUri: TypeAMTP24MTP8, key: TYPEA_MTP24ToMTP8, sourceConnectors: [mtp24], destinationConnectors: new Array(3).fill(mtp8), sessionId: '24_08X03_A', sessionKey: "24F-to-08Fx3 (Type A)", demoKey: MTP24_MTP8_Type_A }
export const TypeAMTP24ToMTP12: PolarityMap = { description: "Type A", imageUri: TypeAMTP24MTP12, key: TYPEA_MTP24ToMTP12, sourceConnectors: [mtp24], destinationConnectors: new Array(2).fill(mtp12), sessionId: '24_12X02_A', sessionKey: "24F-to-12Fx2 (Type A)", demoKey: MTP24_MTP12_TYPEA }
export const TypeBMTP24ToMTP12: PolarityMap = { description: "Type B", imageUri: TypeBMTP24MTP12, key: TYPEB_MTP24ToMTP12, sourceConnectors: [mtp24], destinationConnectors: new Array(2).fill(mtp12), sessionId: '24_12X02_B', sessionKey: "24F-to-12Fx2 (Type B)", demoKey: MTP24_MTP12_TYPEB }
export const TypeLMTP24ToMTP12: PolarityMap = { description: "Type L", imageUri: TypeLMTP24MTP12, key: TYPEL_MTP24ToMTP12, sourceConnectors: [mtp24], destinationConnectors: new Array(2).fill(mtp12), sessionId: '24_12X02_L', sessionKey: "24F-to-12Fx2 (Type L)", demoKey: MTP24_MTP12_TYPEL }
export const CustomMap: PolarityMap = { descriptionLocalizationKey: LocalizationKeys.Custom, key: CUSTOM_MAP_KEY, destinationConnectors: [], sourceConnectors: [] }

interface IPolarityState {
    configs: BuildPolarity[];
    buildConfigList: BuildPolarityPair[];
    selectedConfig: PolarityConfig;
    fiberMaps: IFiberMapData[];
    userFiberMaps: CustomFiberMapData[];
    documentFiberMaps: IDocumentFiberMap[];
    forceConnectorMapReload: IForceReload;
    connectorAssignments: IConnectorAssignmentMap[];
}

export type PolarityState = Partial<IPolarityState & Identifiable>

export const initialPolarityState: PolarityState = {
    configs: [],
    buildConfigList: [],
    selectedConfig: undefined,
    fiberMaps: [],
    userFiberMaps: [],
    forceConnectorMapReload: { force: false }
}

export const LCToLCMapList: PolarityMap[] = [
    AtoAmap,
    AtoBMap,
];

export const MTPtoLCMapList: PolarityMap[] = [
    TypeUMTP8Map,
    TypeUMTP12Map,
    TypeAMTP24Map,
    TypeBMTP8ToLC,
    TypeBMTP12ToLC
];

export const MTPtoMTPMapList: PolarityMap[] = [
    TypeBMTP8Map,
    TypeAMTP8Map,
    Mesh4x4Map,
    TypeBMTP12Map,
    TypeAMTP12Map,
    TypeAMTP24ToMTP24,
    TypeBMTP24ToMTP24,
    TypeYMTP24ToMTP24,
    TypeZMTP24ToMTP24,
    TypeAMTP24ToMTP8,
    TypeAMTP24ToMTP12,
    TypeBMTP24ToMTP12,
    TypeLMTP24ToMTP12
];

export const PolarityMapList: PolarityMap[] = [...LCToLCMapList, ...MTPtoLCMapList, ...MTPtoMTPMapList]
export const PolarityMaps: { [key: string]: PolarityMap } = {};
PolarityMapList.forEach(m => PolarityMaps[m.sessionId!] = m);

export function getPolarityMap(source: IConnectorType[], destination: IConnectorType[], customList?: PolarityMap[]) {
    const polarityMapList = customList ? customList : PolarityMapList

    return source.length && destination.length ? polarityMapList.filter(m => {
        const validSourceMatch = matchConnectorTypeSequence(source, m.sourceConnectors);
        if (!validSourceMatch) return validSourceMatch
        const validDestinationeMatch = matchConnectorTypeSequence(destination, m.destinationConnectors);
        if (!validDestinationeMatch) return validDestinationeMatch
        return true;
    }) : []
}


function matchConnectorTypeSequence(connectors: IConnectorType[], mapConnectors: IConnectorType[]) {
    let validMatch = true;
    for (let i = 0; i < connectors.length; i++) {
        const sourceConnectorType = connectors[i];
        const sourceConnectorMapType = mapConnectors[i % mapConnectors.length]
        if (sourceConnectorType.fiberCount !== sourceConnectorMapType.fiberCount) {
            validMatch = false;
            break;
        }
    }

    return validMatch;
}

export function GetPolarityImgURL(key: number) {
    return PolarityMaps[key]?.imageUri;
}

export function GetPolarityMapSessionImgUrl(id: string) {
    return PolarityMapList.find(m => m.sessionId === id || id === m.demoKey || id === m.sessionKey)?.imageUri;
}

export const GetPolarityKey = (key: string) => {
    if (key.length === 0) return `${CUSTOM_MAP_KEY}`;

    const map = PolarityMapList.find(p => p.customKey === key || p.demoKey === key || p.sessionKey === key);
    return map ? `${map.key}` : key === `${CUSTOM_MAP_KEY}` ? key : `${SESSION_MAP_KEY}`;
}

export const GetPolaritySessionId = (key: string) => {
    if (key.length === 0) return `${SESSION_MAP_KEY}`;

    return PolarityMapList.find(p => p.customKey === key || p.demoKey === key || p.sessionKey === key)?.sessionId ?? key;
}

export const generateConnectorType = (fiberCount: number): IConnectorType => {
    switch (fiberCount) {
        case 24:
            return mtp24;
        case 12:
            return mtp12;
        case 8:
            return mtp8;
        case 2:
            return lc;
        default:
            return {
                description: '',
                fiberCount,
                key: '',
            };
    }
}

export const getPolarityMapPolarityType = (polarityMap: PolarityMap): string => {
    if (polarityMap.key === CUSTOM_MAP_KEY || polarityMap.key === SESSION_MAP_KEY) {
        return polarityMap.customKey ?? polarityMap.key?.toString();
    }

    return polarityMap.key?.toString() ?? "";
}

export const getPolarityMapDescription = (polarityMap: PolarityMap, t: TFunction): string => {
    if (polarityMap.description) return polarityMap.description;
    if (polarityMap.descriptionLocalizationKey) return t(polarityMap.descriptionLocalizationKey);
    return "";
}
