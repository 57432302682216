import { createSelector } from "@reduxjs/toolkit";
import { ConfigurationType } from "../../../../components/overlay/components/wizard/redux/types";
import { workspaceSelector } from "../../../../selectors/root.selectors";

const presetSelector = createSelector(
    workspaceSelector,
    workspace => ({ ...workspace.userWizard, configType: workspace.builds.currentBuild?.configurationType })
)

const configTypeSelector = createSelector(
    workspaceSelector,
    workspace => workspace.builds.currentBuild?.configurationType ?? ConfigurationType.Patching
)
const wizarPresetsSelector = createSelector(
    presetSelector,
    preset => preset.wizardPresets ?? []
)
export const selectedPresetSelector = createSelector(
    configTypeSelector,
    wizarPresetsSelector,
    (configType, wizardPresets) => wizardPresets.find(w => w.configurationType === configType)
)

export const selectedPresetConfigurationTypeSelector = createSelector(
    selectedPresetSelector,
    selected => selected?.configurationType ?? ConfigurationType.Patching
)

export const selectedPresetFiberCountSelector = createSelector(
    selectedPresetSelector,
    (selected) => selected?.feederPreset?.fiberCount ?? 0
)