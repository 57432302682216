import { InteractiveRectangle } from "../decorators/bounds/components/rectangle/types";
import { SOURCE_TRUNK, TAP_TRUNK, WAM, TAP_VERTICAL_TRUNK } from '../../factories/Texture'
import { SourceContainer, FeederHorizontalTrunk, FeederContainer, FeederBaseContainer } from './components/feeder-base/types'
import { TetherTrunk, DropHorizontalTrunk, PositionLabel, DropContainer, DropBaseContainer } from './components/drop-base/types';
import { FurcationContainer, ConnectorTypeLabel, ExpandMarkers, ConnectorIndexLabel, Leg, LegContainer, IConnectorLegProps } from './components/connectors/components/furcation/types'
import { DocumentGraphicsContainer, DocumentDropContainer } from '../document-graphics/types'
import { IPoint, pointEqual } from "../../types";
import { ITrunkContext } from "../../../workspace/redux/build/types";
import { createContext, PropsWithChildren } from "react";
import { Markers } from "../decorators/markers/types";

export const EDSContainer = "eds-container";
export const Feeder = "Feeder";
export const Drop = "Drop";

export type Section = typeof Feeder | typeof Drop;
export const SectionContext = createContext(Feeder);

export const SourceComponents = {
    SourceContainer, FeederContainer, FeederBaseContainer,
    FeederHorizontalTrunk, SOURCE_TRUNK, TAP_TRUNK, TetherTrunk,
} as const;

export const DestinationComponents = {
    DropContainer, DropBaseContainer,
    DropHorizontalTrunk,
    TAP_VERTICAL_TRUNK,
    WAM,
} as const;

export const MarkerComponents = {
    PositionLabel,
    ConnectorTypeLabel,
    ConnectorIndexLabel,
    Markers,
    ExpandMarkers
} as const;

export const FurcationComponents = {
    FurcationContainer, LegContainer,
    Leg,
} as const;

export const InteractiveComponents = {
    InteractiveRectangle,
} as const;

export const ComponentNames =  {
    ...SourceComponents,
    ...DestinationComponents,
    ...MarkerComponents,
    ...FurcationComponents,
    ...InteractiveComponents,
    EDSContainer,
    DocumentGraphicsContainer,
    DocumentDropContainer
} as const;

export type DestinationComponent = typeof DestinationComponents [keyof typeof DestinationComponents];
export type MarkerComponent = typeof MarkerComponents [keyof typeof MarkerComponents];
export type FurcationComponent = typeof FurcationComponents [keyof typeof FurcationComponents];
export type InteractiveComponent = typeof InteractiveComponents [keyof typeof InteractiveComponents];
export type SourceComponent = typeof SourceComponents [keyof typeof SourceComponents];
export type ComponentName = typeof ComponentNames [keyof typeof ComponentNames];

export type LabelProps = {
    text: string;
    name?: string;
    fontSize?: number;
    color: number;
} & IPoint;

export type TrunkProps = {
    key?: string;
    trunkContext: ITrunkContext;
    legProps: IConnectorLegProps[];
} & IPoint;

export type SpriteProps = {
    color?: string;
} & Partial<IPoint>;

export interface IBaseProps {
    connectorType: string;
    wamLocation?: IPoint;
};

export const areBaseEqual = (prev: PropsWithChildren<IBaseProps>, next: PropsWithChildren<IBaseProps>) => {
    const connectorType = prev.connectorType === next.connectorType;
    const wamLocation = pointEqual(prev.wamLocation, next.wamLocation);

    return connectorType && wamLocation;
}

export { InteractiveRectangle } from "../decorators/bounds/components/rectangle/types";
export { SOURCE_TRUNK, TAP_TRUNK, WAM, TAP_VERTICAL_TRUNK } from '../../factories/Texture'
export { SourceContainer, FeederHorizontalTrunk, FeederContainer, FeederBaseContainer } from './components/feeder-base/types'
export { TetherTrunk, DropHorizontalTrunk, PositionLabel, DropContainer, DropBaseContainer } from './components/drop-base/types';
export { FurcationContainer, ConnectorTypeLabel, ExpandMarkers } from './components/connectors/components/furcation/types'
export { LegContainer, Leg, ConnectorIndexLabel } from './components/connectors/components/furcation/types'