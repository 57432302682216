import React from 'react';
import { TAPTrunkSprite, WAMSprite } from '../../EDSSprites';
import { Container, Graphics } from '@inlet/react-pixi';
import { DropBaseContainer, TetherTrunk} from './types';
import { HorizontalTrunk } from '../trunk/horizontal/HorizontalTrunk';
import { useDropBase } from './hooks';
import { areBaseEqual, IBaseProps } from '../../types';
import { withMemoization } from '../../../../memoization/Memoization';

export const DropBaseGraphics : React.FC<IBaseProps> = (props) => {
    const { wamSprite, tapTrunkSprite, drawTetherTrunk } = useDropBase(props);
    return (
        <Container name={DropBaseContainer}>
            <Graphics name={TetherTrunk} draw={drawTetherTrunk} />
            <TAPTrunkSprite {...tapTrunkSprite} />
            <HorizontalTrunk/>
            <WAMSprite {...wamSprite}/>
        </Container>
    )
}

export const DropBase = withMemoization(DropBaseGraphics, areBaseEqual);


