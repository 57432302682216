import { IAutocompleteProps } from "./types";
import { useState, useEffect } from "react"

export const useGenericAutocomplete = (props: IAutocompleteProps) => {
    const { open, disabled, options, value, onOpen, onClose, onValueChanged, onInputChanged, handleOptionSelected, label, isInputValid, inputErrorText, onInputKeyDown } = props;
    const [containerClassName, setContainerClassName] = useState("generic-autocomplete-container");

    useEffect(() => {
        if (isInputValid) {
            setContainerClassName("generic-autocomplete-container");
        } else {
            setContainerClassName("generic-autocomplete-container-invalid")
        }
    }, [isInputValid]);

    return { open, disabled, options, value, onOpen, onClose, onValueChanged, onInputChanged, handleOptionSelected, label, isInputValid, inputErrorText, onInputKeyDown, containerClassName };
}