import { createSelector } from "reselect";
import { WorkspaceState } from "../../../../workspace/redux/reducers";
import { workspaceSelector } from "../../../../workspace/selectors/root.selectors";

export const viewportIncrementSelector = (state: WorkspaceState) => state.viewport.scaleIncrement

export const viewportSelector = createSelector(
    workspaceSelector,
    workspace => workspace.viewport
); 

export const viewportContextSelector = createSelector(
    viewportSelector,
    viewport => ({ context: viewport.context, isDragging: viewport.isDragging })
);