import { IConnectorAssignmentMap } from "../components/overlay/components/polarity/connector-assignment/redux/types";
import { LabelCustomizationOptions } from "../components/overlay/components/reports/components/label/types";
import { IBuildData, IBuildInfo } from "../redux/build/types";
import { IProjectBuildResponse, IProjectIdData, IProjectManagerData } from "../redux/project-manager/types";
import { IApiResult } from "./api-result";
import { getCurrentISODate, ToBuildData, ToBuildDTO } from "./build-service";
import { SecuredService } from "./secured-service";
import { BuildInfoDTO, IConnectorsUpdateResponse, ProjectDTO, ProjectManagerDTO } from "./types";
import { IPropagationOptions } from "../components/overlay/components/polarity/propagation/redux/types";

export class ProjectManagerService extends SecuredService {
    private readonly baseUrl = '/api/projectmanager';

    public async getProjectManager(): Promise<IApiResult<IProjectManagerData>> {
        const res = await this.get(`${this.baseUrl}`);
        if (res.succesful && res.data) res.data = ToProjectManagerData(res.data);
        return res;
    }

    public async getProject(projectId: number): Promise<IApiResult<IBuildData>> {
        const res = await this.get(`${this.baseUrl}/project/${projectId}`);
        if (res.succesful && res.data) res.data = ToBuildData(res.data);
        return res;
    }

    public async getConnectorAssignments(projectId: number): Promise<IApiResult<IConnectorAssignmentMap[]>> {
        return await this.get(`${this.baseUrl}/connectorAssignments/${projectId}`);
    }

    public async updatedBuildInfo(buildInfo: IBuildInfo): Promise<IApiResult<BuildInfoDTO>> {
        return this.put<BuildInfoDTO>(`${this.baseUrl}/info`, ToBuildInfoDTO(buildInfo))
    }

    public async getBuildsForGroupByPage(pageNumber: number, search?: string): Promise<IApiResult<IBuildInfo[]>> {
        return await this.get(`${this.baseUrl}/all/group/${pageNumber}`, { search });
    }

    public async updateSortType(sortType: string, pageIndex: number, search?: string): Promise<IApiResult<IBuildInfo[]>> {
        return await this.post(`${this.baseUrl}/sorttype`, { sortType, pageIndex, search });
    }

    public async updateSortOrder(isSortAscending: boolean, pageIndex: number, search?: string): Promise<IApiResult<IBuildInfo[]>> {
        return await this.post(`${this.baseUrl}/sortorder`, { isSortAscending, pageIndex, search });
    }

    public async unlockBuild(id: number): Promise<IApiResult<IBuildData>> {
        const res = await this.post(`${this.baseUrl}/unlock/${id}`, id);
        if (res.succesful && res.data) res.data = ToBuildData(res.data);
        return res;
    }

    public async lockBuild(id: number): Promise<IApiResult<IBuildData>> {
        const res = await this.post(`${this.baseUrl}/lock/${id}`, id);
        if (res.succesful && res.data) res.data = ToBuildData(res.data);
        return res;
    }

    public async addBuild(build: IBuildData): Promise<IApiResult<IBuildData>> {
        const res = await this.post(`${this.baseUrl}/build`, ToBuildDTO(build));
        if (res.succesful && res.data) res.data = ToBuildData(res.data);
        return res;
    }

    public async applyLabelScheme(build: IBuildData, schemeId: number, trunkPosition: number, options: LabelCustomizationOptions, propagationOptions: IPropagationOptions): Promise<IApiResult<IConnectorsUpdateResponse>> {
        return await this.post(`${this.baseUrl}/labelScheme`, { build: ToBuildDTO(build), schemeId, trunkPosition, options, propagationOptions });
    }

    public async duplicateProject(buildId: number, buildName: string, pageIndex: number, search?: string): Promise<IApiResult<IProjectBuildResponse>> {
        const res = await this.post(`${this.baseUrl}/duplicate`, { buildId, buildName, pageIndex, search });
        if (res.succesful && res.data) res.data.build = ToBuildData(res.data.build);
        return res;
    }

    public async updateRecentProject(recentProjectId: number): Promise<IApiResult<IProjectBuildResponse>> {
        const res = await this.put(`${this.baseUrl}/update/${recentProjectId}`, { recentProjectId });
        if (res.succesful && res.data && res.data.build) res.data.build = ToBuildData(res.data.build);
        return res;
    }

    public async deleteBuild(buildId: number, pageIndex: number, search?: string): Promise<IApiResult<IProjectBuildResponse>> {
        const res = await this.delete(`${this.baseUrl}/project/${buildId}/page/${pageIndex}`, { search });
        if (res.succesful && res.data && res.data.build) res.data.build = ToBuildData(res.data.build);
        return res;
    }
}

export function ToProjectManagerDTO(data: IProjectManagerData): ProjectManagerDTO {
    return {
        sortType: data.sortType,
        isSortAscending: data.isSortAscending,
        projectIds: data.projectIds ?? [],
        recentProjectId: data.recentProjectId,
        totalBuildCount: data.totalBuildCount ?? 0,
    };
}

export function ToBuildInfoDTO(data: IBuildInfo): BuildInfoDTO {
    return {
        buildId: data.buildId,
        partNumber: data.partNumber,
        description: data.description,
        name: data.name,
        lastModified: getCurrentISODate()
    }
}

export function ToProjectDTO(data: IProjectIdData): ProjectDTO {
    return {
        id: data.id,
        projectId: data.projectId
    }
}

export function ToProjectManagerData(dto: ProjectManagerDTO): IProjectManagerData {
    return {
        projectIds: dto.projectIds!,
        sortType: dto.sortType,
        isSortAscending: dto.isSortAscending,
        builds: dto.builds!,
        recentProjectId: dto.recentProjectId!,
        totalBuildCount: dto.totalBuildCount,
    }
}

export function ToProjectIdsData(dtos: ProjectDTO[]): number[] {
    return dtos.map(b => b.projectId);
}

export function ToProjectIdData(dto: ProjectDTO): IProjectIdData {
    return {
        id: dto.id,
        projectId: dto.projectId
    }
}