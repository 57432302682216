import { createSlice } from "@reduxjs/toolkit";
import { initialState } from './types';
import { logoutReducer, loginSuccessfulReducer, loginFailedReducer, setUserIdReducer } from './actions';
import { createAsyncAction } from '../../../redux/actions';
import { AuthenticationService } from "../../../services/authentication-service";
import { Okta } from '../../../okta/okta';

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        logout: logoutReducer,
        loginSuccessful: loginSuccessfulReducer,
        loginFailed: loginFailedReducer,
        setUserId: setUserIdReducer
    }
});

export const AuthenticationReducer = authenticationSlice.reducer;
export const { loginSuccessful, loginFailed, setUserId } = authenticationSlice.actions;

export const login = () => {
    return createAsyncAction(async (dispatch) => {
        dispatch(authenticationSlice.actions.logout(undefined));
        await Okta.loginWithRedirect();
    });
};

export const renewToken = () => {
    return createAsyncAction(async (dispatch) => {
        const idToken = await Okta.renewToken();
        if (idToken) {
            const service = new AuthenticationService();
            const res = await service.oidLogin(idToken);
            if (res.succesful && res.data) {
                dispatch(authenticationSlice.actions.loginSuccessful(res.data));
            }
        }
    });
};