import React, { useContext } from 'react';
import { BLengthContext } from '../../../types';


export const UniformBLengthLength: React.FC = (props) => {
    const { isCustom } = useContext(BLengthContext)
    return (
        <div hidden={isCustom}>
            {props.children}
        </div>
    )
}