import React from "react"
import { ConnectorAssignmentRowProps } from "./types"
import { useConnectorAssignmentRow } from "./hooks"
import { DataTableCell, DataTableRow } from "@rmwc/data-table"
import { TableRowSelectionIcon } from "../../../../../../../ui/icons/TableRowSelectionIcon"
import { IIconProps } from "../../../../../../../ui/icons/types"
import { DEFAULT_BLUE, ORANGE } from "../../../../../../../pixi/components/decorators/bounds/components/selection/redux/types"
import { IconButton } from "@orbit/icon-button"
import { Cancel, Edit, Link, LinkOff, Visibility } from "@mui/icons-material"

export const ConnectorAssignmentRow: React.FC<ConnectorAssignmentRowProps> = (props) => {
    const { editable, data, onRowClick, onButtonCellClick, assignedIconProps, hybrisCustomer, deleteIconProps, editIconProps, propagation } = useConnectorAssignmentRow(props);
    
    const fill = (data.highlighted && data.assigned) ? (data.color ? data.color.fill : DEFAULT_BLUE.fill) : (data.color ? data.color.fill : ORANGE.fill );
    const stroke = (data.highlighted && data.assigned) ? (data.color ? data.color.stroke : DEFAULT_BLUE.stroke ) : ( data.color ? data.color.stroke : ORANGE.stroke );
    const selectionIconProps: IIconProps = { text: data.selectionLabel, fill, stroke };
    const hybrisTableCell = !!hybrisCustomer ? " hybris-cell" : "";
    const deleteButtonClassName = data.disabled ? `delete-button editDisabled${hybrisTableCell}` : `delete-button${hybrisTableCell}`;
    const editButtonClassName = `edit-button${hybrisTableCell}`;
    const editButtonIcon = data.disabled ? <Visibility /> : <Edit />;

    return (
        <DataTableRow key={data.id} className={data.className} onClick={!data.disabled ? onRowClick : undefined}>
            <DataTableCell className="assignment-icon">
                {
                    data.assigned && !data.highlighted ?
                        <i className={assignedIconProps.className}>
                            {assignedIconProps.icon}
                        </i> : 
                        <TableRowSelectionIcon {...selectionIconProps} /> 
                }
            </DataTableCell>
            <DataTableCell className="connector-info">
                {data.id + ' | ' + data.fiberAssignmentLabel}
            </DataTableCell>
            {
                editable ? 
                <>
                    <DataTableCell className={deleteButtonClassName} onClick={data.assigned && !data.selected ? onButtonCellClick : undefined}>
                        <IconButton {...deleteIconProps} icon={<Cancel />} />
                    </DataTableCell>
                    <DataTableCell className={editButtonClassName} onClick={data.assigned && !data.selected ? onButtonCellClick : undefined}>
                        <IconButton {...editIconProps} icon={editButtonIcon} />
                    </DataTableCell>
                </> : null
            }
            {
                propagation.active ?
                <DataTableCell className="propagation-cell">
                    <IconButton {...propagation.iconProps} icon={propagation.active && propagation.successful ? <Link /> : <LinkOff />}  />
                </DataTableCell> : null
            }
        </DataTableRow>
    )
}