import React from 'react';
import i18n from 'i18next';
import { LocalizationKeys } from '../../../../../../../../locales/types';
import { WorkspaceState, connect } from '../../../../../../../redux/reducers';
import { enableNextStep, setCurrentStep } from "../../../redux/reducers";
import { FilledSelect as Select, SelectItem as Option } from '@orbit/select';
import { FilledTextField } from "@orbit/text-field"
import { StaggerLengthTypes, IConnectorType, StaggerLength, getConnectorType, validateStaggerValue, ConnectorTypes } from '../../../redux/types';
import { IDestinationSetupState, IDestinationSetupProps, getConnectorsPerGroupCount, getAsymmetricAPCount, getMaxGroupCount, getMaxAsymmetricAPCount } from './redux/types';
import { IDestinationData } from '../../../../../../../redux/build/destination/types';
import { ViewportStatus } from '../../../../../../../../pixi/components/viewport/redux/types';
import { getUnitsName, roundToDecimalPoint, convertToDisplay, decimalPlacesBasedOnUnit, getDecimalPlaces } from '../../../../header/components/units-of-measure-container/UnitsOfMeasure';
import { validateWholeNumber, validateUnSignedDecimal } from '../../../redux/types';
import { DropCheckbox } from '../components/drop-checkbox/DropCheckbox';
import { StaggerAutocomplete } from '../components/stagger/StaggerAutocomplete';
import { BLengthSection } from '../components/blength-section/BLengthSection';
import { UniformBLengthLength } from '../components/blength-section/components/blength-custom-section/uniform/UniformBLength';
import { CustomBLength } from '../components/blength-section/components/blength-custom-section/custom/CustomBLength';
import { sscDistributionOptionsLegRangeSelector } from '../../../../../../../redux/ssc/selectors';
import { isConnectorEditabledSelector } from '../../../../../../../redux/project-manager/selectors';

export function mapStateToProps(state: WorkspaceState) {
    const { source, destinations, isAsymmetric } = state.builds.currentBuild!;
    let currentDestination = destinations[0];
    const { unit } = state.unitsOfMeasureContainer;
    const fiberCount = state.builds.currentBuild!.source.fiberCount ?? 1;
    const { context } = state.viewport;
    const { applyEditChanges, stepCount, isNextEnabled } = state.wizard;
    const { selection } = state.toolbar;
    if (selection.selected > 0) {
        currentDestination = destinations[selection.selected - 1];
    }

    const maxDecimalPlaces = decimalPlacesBasedOnUnit(unit);
    const sscDistributionOptions = sscDistributionOptionsLegRangeSelector(state)
    const isConnectorEditabled = isConnectorEditabledSelector(state);
    return { source, destinations, currentDestination, fiberCount, context, applyEditChanges, stepCount, isNextEnabled, selection, unit, maxDecimalPlaces, isAsymmetric, sscDistributionOptions, isConnectorEditabled };
}

const mapDispatchToProps = {
    enableNextStep,
    setCurrentStep
}

export type props = Partial<ReturnType<typeof mapStateToProps>> & Partial<typeof mapDispatchToProps> & IDestinationSetupProps;


export class DestinationSetupComponent extends React.Component<props, IDestinationSetupState> {
    private aLength = '';
    private bLength = '';
    private sLength = '';
    private dropMaxFiber = 0;

    private aLengthErrorText = '';
    private bLengthErrorText = '';
    private sLengthErrorText = '';

    private isValidALength = true;
    private isValidBLength = true;
    private isValidSLength = true;

    private isStaggerEnabled = true;
    private isConvertingUnits = true;

    constructor(props: props) {
        super(props);
        const defaultConnectorType = ConnectorTypes[0];
        const selectedPosition = this.props.selection!.selected;
        const destination = this.getDestinationData();
        const accessPoints = this.props.destinations?.length ?? 0;
        const connectorTypes = this.props.sscDistributionOptions!.connectorTypes ?? [];
        const connectorType = connectorTypes.find(x => x.type === destination.groups[0].type) || connectorTypes[0] || defaultConnectorType;
        const groupCount = destination.groups.length;
        const nbConnectorsPerGroup = destination.groups[0].connectors.length;
        const dropFiberCount = destination.groups.flatMap(g => g.connectors).map(c => getConnectorType(c.type || "").fiberCount).reduce((a, b) => a + b, 0)
        this.dropMaxFiber = Math.min(dropFiberCount, this.props.sscDistributionOptions!.dropFiberCountRange.max)
        const stagger = StaggerLengthTypes.find(x => x.value === destination.groups[0].stagger)!;
        this.state = this.validateConnectorType(connectorType, {
            fiberCount: this.props.fiberCount ?? 0,
            position: selectedPosition,
            connectorType: connectorType,

            accessPoints: accessPoints,
            accessPointsFieldValue: accessPoints?.toString() ?? "",
            isAccessPointsValid: true,
            accessPointsErrorText: "",

            groupCount: groupCount,
            groupCountFieldValue: groupCount.toString(),
            isGroupCountValid: true,
            groupCountErrorText: '',

            nbConnectorsPerGroup: nbConnectorsPerGroup,
            nbConnectorsPerGroupFieldFieldValue: nbConnectorsPerGroup.toString(),
            isNbConnectorsPerGroupValid: true,
            nbConnectorsPerGroupErrorText: '',

            alength: destination.lengthA,
            blength: destination.lengthB,
            slength: stagger === undefined ? destination.groups[0].stagger! : stagger.value,
        })

        this.aLength = destination?.lengthA && this.props.unit ?
            convertToDisplay(destination.lengthA, this.props.unit) : '';
        this.bLength = destination?.lengthB && this.props.unit ?
            convertToDisplay(destination.lengthB, this.props.unit) : '';
        this.sLength = destination?.groups[0].stagger && this.props.unit ?
            convertToDisplay(destination.groups[0].stagger, this.props.unit) : '';

        const isEditing = this.props.context === ViewportStatus.Editing
        this.props.applyChanges!(isEditing ? { ...this.state, fiberCount: this.dropMaxFiber } : this.state);
        this.validateALength(this.aLength, false)
        this.validateBLength(this.bLength, false)
        this.enableSLength(this.state.groupCountFieldValue);
    }

    public componentDidUpdate(prevProps: props, prevState: IDestinationSetupState) {
        let isSetupValid = this.state.isAccessPointsValid && this.state.isGroupCountValid && this.state.isNbConnectorsPerGroupValid;
        const isEditing = this.props.context === ViewportStatus.Editing
        const triggerAsymmetric = prevProps.isAsymmetric !== this.props.isAsymmetric && this.props.isAsymmetric && !isEditing
        const state = triggerAsymmetric ? this.validateConnectorType(this.state.connectorType, { ...this.state, fiberCount: this.props.fiberCount ?? 1 }) : this.state
        if (triggerAsymmetric) {
            this.setState(state)
        }
        const dynamicUpdate = prevState !== state && isSetupValid;
        if (dynamicUpdate) {
            this.props.applyChanges!(isEditing ? { ...this.state, fiberCount: this.dropMaxFiber } : this.state);
        }

        let customB = undefined;
        if (this.props.selection && this.props.selection.selected > 0) {
            const destination = this.props.destinations!.find(d => d.position === this.props.selection!.selected)
            customB = !destination!.customBLength || !destination!.groups.some(g => !g.lengthB) ? "valid" : "";
        }

        const requiredProps = { alength: this.aLength, blength: this.bLength, groupCount: state.groupCountFieldValue, connectorCount: state.nbConnectorsPerGroupFieldFieldValue, accessPoints: state.accessPointsFieldValue, customB };
        let missingProps = Object.values(requiredProps).filter(p => p !== undefined && p.length === 0);
        if (missingProps.length === 0) {
            this.props.enableNextStep!(true);
        } else {
            if (this.props.isNextEnabled) {
                this.props.enableNextStep!(false);
            }
        }

        this.updateConnectorType(prevProps);

        if (!isSetupValid || !this.isValidALength || !this.isValidBLength || !this.isValidSLength) {
            this.props.enableNextStep!(false);
        }

        if (this.props.unit !== prevProps.unit) {
            this.ConvertValuesByUnits();
            if (state.groupCountFieldValue && state.isGroupCountValid && state.isNbConnectorsPerGroupValid) {
                this.validateSLength(this.sLength)
            }
        }

        const applyChanges = this.props.applyEditChanges;
        if (applyChanges) {
            this.props.setCurrentStep!(this.props.stepCount! + 1);
        }
    }

    private ConvertValuesByUnits = () => {
        this.isConvertingUnits = true;
        let destination = this.getDestinationData();
        this.aLength = this.aLength.length && destination.lengthA && this.props.unit ?
            convertToDisplay(destination.lengthA, this.props.unit) : "";
        this.bLength = this.aLength.length && destination.lengthB && this.props.unit ?
            convertToDisplay(destination.lengthB, this.props.unit) : "";
        this.sLength = this.aLength.length && destination.groups[0].stagger && this.props.unit ?
            convertToDisplay(destination.groups[0].stagger, this.props.unit) : "";

        this.validateALength(this.aLength, false);
        this.validateBLength(this.bLength, false);
        this.setState({}); // toggle a state change to render the updated A and B values
    }

    private validateConnectorType = (connectorType: IConnectorType, newState?: IDestinationSetupState) => {
        let newDestinationState: IDestinationSetupState = {
            ...(newState || this.state),
            connectorType,
            isAccessPointsValid: true,
            accessPointsErrorText: ''
        };
        if (newDestinationState.groupCount && newDestinationState.nbConnectorsPerGroup && newDestinationState.accessPoints) {
            newDestinationState = this.validateAccessPoints(newDestinationState.accessPointsFieldValue ?? "1", newDestinationState)
        }

        return newDestinationState;
    }

    private validateAccessPoints = (accessPointsFieldValue: string, newState?: IDestinationSetupState) => {
        let accessPoints = Number(accessPointsFieldValue);
        let newDestinationState: IDestinationSetupState = {
            ...(newState || this.state),
            accessPoints,
            accessPointsFieldValue,
            isAccessPointsValid: true,
            accessPointsErrorText: ''
        }

        const { min: minValidAPCount, max: maxValidAPCount } = this.props.sscDistributionOptions!.accessPointRange
        const { min: minValidGroupCount } = this.props.sscDistributionOptions!.groupCounts
        const { min: minValidCPGCount } = this.props.sscDistributionOptions!.connectorsPerGroupCounts
        const maxDropFiber = this.props.sscDistributionOptions?.dropFiberCountRange.max ?? 1
        let maxAsymmetricAccessPoints = getMaxAsymmetricAPCount(minValidGroupCount, minValidCPGCount, newDestinationState.connectorType, this.props.fiberCount ?? 1, maxDropFiber)
        let maxAccessPoints = Math.min(maxValidAPCount, maxAsymmetricAccessPoints)
        maxAccessPoints = Math.max(maxAccessPoints, minValidAPCount)
        const isEditing = this.props.context === ViewportStatus.Editing
        if (isEditing || !this.props.isAsymmetric) {
            if (accessPoints < minValidAPCount) {
                newDestinationState.accessPointsErrorText = i18n.t(LocalizationKeys.ValueLesserThan, { value: minValidAPCount });
                newDestinationState.isAccessPointsValid = false;
            }
            else if (accessPoints > maxAccessPoints) {
                newDestinationState.accessPointsErrorText = i18n.t(LocalizationKeys.ValueGreaterThan, { value: maxAccessPoints });
                newDestinationState.isAccessPointsValid = false;
            }

            if (accessPointsFieldValue === "") {
                newDestinationState.accessPointsErrorText = i18n.t(LocalizationKeys.MissingValue);
                newDestinationState.isAccessPointsValid = false;
            }
        }
        else if (!isEditing && this.props.isAsymmetric) {
            maxAsymmetricAccessPoints = getMaxAsymmetricAPCount(newDestinationState.groupCount ?? minValidGroupCount, newDestinationState.nbConnectorsPerGroup ?? minValidCPGCount, newDestinationState.connectorType, this.props.fiberCount ?? 1, maxDropFiber)
            maxAccessPoints = Math.min(maxValidAPCount, maxAsymmetricAccessPoints)
            maxAccessPoints = Math.max(maxAccessPoints, minValidAPCount)
            accessPoints = Math.min(accessPoints, maxAccessPoints)
            newDestinationState.accessPoints = accessPoints
            newDestinationState.accessPointsFieldValue = accessPoints.toString()
        }

        if (newDestinationState.groupCountFieldValue) {
            newDestinationState = this.validateGroupCount(newDestinationState.groupCountFieldValue, newDestinationState)
        }

        return newDestinationState;
    }

    private validateGroupCount(groupCountFieldValue: string, newState?: IDestinationSetupState) {
        let groupCount = Number(groupCountFieldValue);
        let newDestinationState: IDestinationSetupState = {
            ...(newState || this.state),
            groupCountFieldValue,
            groupCount,
            groupCountErrorText: '',
            isGroupCountValid: true,
        }

        if (!newDestinationState.isAccessPointsValid) return newDestinationState

        const groupCountRange = this.props.sscDistributionOptions!.groupCounts;
        const accessPoints = newDestinationState.accessPoints ?? 1,
            connectorType = newDestinationState.connectorType;
        const fiberCount = this.props.fiberCount ?? 1
        const minAccessPoint = Math.max(accessPoints - 1, this.props.sscDistributionOptions?.accessPointRange.min ?? 1)
        let maxGroupCount = getMaxGroupCount(minAccessPoint, connectorType, fiberCount ?? 1)
        maxGroupCount = Math.min(groupCountRange.max, maxGroupCount);
        if (groupCountFieldValue === "" || !groupCount) {
            newDestinationState.groupCountErrorText = i18n.t(LocalizationKeys.MissingValue);
            newDestinationState.isGroupCountValid = false;
        } else if (groupCount < groupCountRange.min) {
            newDestinationState.groupCountErrorText = i18n.t(LocalizationKeys.ValueLesserThan, { value: groupCountRange.min });
            newDestinationState.isGroupCountValid = false;
        }
        else if (groupCount > maxGroupCount) {
            newDestinationState.groupCountErrorText = i18n.t(LocalizationKeys.ValueGreaterThan, { value: maxGroupCount });
            newDestinationState.isGroupCountValid = false;
        }
        else if (this.props.context === ViewportStatus.Editing) { // On edit, connectors must use all available fiber
            const fiberRemainder = this.dropMaxFiber % (connectorType.fiberCount * groupCount);
            if (fiberRemainder) {
                const connectorCount = Math.floor(this.dropMaxFiber / connectorType.fiberCount)
                newDestinationState.groupCountErrorText = i18n.t(LocalizationKeys.AccessPointsConnectorError, { count: connectorCount });
                newDestinationState.isGroupCountValid = false;
            }
            else {
                const newConnectorsPerGroup = Math.floor(this.dropMaxFiber / (connectorType.fiberCount * groupCount))
                newDestinationState.nbConnectorsPerGroup = newConnectorsPerGroup
                newDestinationState.nbConnectorsPerGroupFieldFieldValue = newConnectorsPerGroup.toString()
            }
        }

        if (newDestinationState.nbConnectorsPerGroup) {
            newDestinationState = this.validateConnectorsPerGroupCount(newDestinationState.nbConnectorsPerGroupFieldFieldValue, newDestinationState)
        }

        return newDestinationState;
    }

    private validateConnectorsPerGroupCount(nbConnectorsPerGroupFieldFieldValue?: string, newState?: IDestinationSetupState) {
        let connectorsPerGroup = Number(nbConnectorsPerGroupFieldFieldValue) || 0;
        const { min: minValidAPCount, max: maxValidAPCount } = this.props.sscDistributionOptions!.accessPointRange
        const { min: minValidCPGCount, max: maxValidCPGCount } = this.props.sscDistributionOptions!.connectorsPerGroupCounts
        const isEditing = this.props.context === ViewportStatus.Editing
        const newDestinationState: IDestinationSetupState = {
            ...(newState || this.state),
            nbConnectorsPerGroup: connectorsPerGroup,
            nbConnectorsPerGroupFieldFieldValue,
            isNbConnectorsPerGroupValid: true,
            nbConnectorsPerGroupErrorText: ''
        }

        if (!(newDestinationState.isAccessPointsValid && newDestinationState.isGroupCountValid)) return newDestinationState

        const groupCount = newDestinationState.groupCount,
            connectorType = newDestinationState.connectorType,
            fiberCount = !isEditing ? this.props.fiberCount ?? 1 : this.dropMaxFiber,
            accessPoints = !isEditing ? newDestinationState.accessPoints : 1;

        if (fiberCount && groupCount && accessPoints) {
            let maxNbConnectorsPerGroup = getConnectorsPerGroupCount(accessPoints, groupCount, connectorType, fiberCount)
            let minNbConnectorsPerGroup = getConnectorsPerGroupCount(Math.min(accessPoints, maxValidAPCount), groupCount, connectorType, fiberCount)
            if (!isEditing && this.props.isAsymmetric && connectorsPerGroup > 0) {
                const maxDropFiber = this.props.sscDistributionOptions?.dropFiberCountRange.max ?? 1
                const accessPoints = getAsymmetricAPCount(
                    groupCount, connectorsPerGroup, connectorType,
                    fiberCount, minValidAPCount, maxValidAPCount, maxDropFiber
                );
                
                maxNbConnectorsPerGroup = getConnectorsPerGroupCount(Math.max(accessPoints - 1, minValidAPCount), groupCount, connectorType, fiberCount)
                minNbConnectorsPerGroup = getConnectorsPerGroupCount(Math.min(accessPoints, maxValidAPCount), groupCount, connectorType, fiberCount)
                newDestinationState.accessPoints = accessPoints
                newDestinationState.accessPointsFieldValue = accessPoints.toString()
                newDestinationState.isAccessPointsValid = true;
                newDestinationState.accessPointsErrorText = '';
            }

            maxNbConnectorsPerGroup = Math.min(maxNbConnectorsPerGroup, maxValidCPGCount)
            minNbConnectorsPerGroup = Math.max(minNbConnectorsPerGroup, minValidCPGCount)
            if (nbConnectorsPerGroupFieldFieldValue === "" || !connectorsPerGroup) {
                newDestinationState.nbConnectorsPerGroupErrorText = i18n.t(LocalizationKeys.MissingValue);
                newDestinationState.isNbConnectorsPerGroupValid = false;
            }
            else if (this.props.context === ViewportStatus.Editing) {
                const fiberRemainder = this.dropMaxFiber - (groupCount * connectorType.fiberCount * connectorsPerGroup)
                if (fiberRemainder) {
                    newDestinationState.nbConnectorsPerGroupErrorText = i18n.t(LocalizationKeys.InvalidEntry);
                    newDestinationState.isNbConnectorsPerGroupValid = false;
                }
            }
            else if (connectorsPerGroup > maxNbConnectorsPerGroup) {
                newDestinationState.nbConnectorsPerGroupErrorText = i18n.t(LocalizationKeys.ValueGreaterThan, { value: maxNbConnectorsPerGroup });
                newDestinationState.isNbConnectorsPerGroupValid = false;
            }
            else if (connectorsPerGroup < minNbConnectorsPerGroup) {
                newDestinationState.nbConnectorsPerGroupErrorText = i18n.t(LocalizationKeys.ValueLesserThan, { value: minNbConnectorsPerGroup });
                newDestinationState.isNbConnectorsPerGroupValid = false;
            }
        }

        return newDestinationState;
    }

    private validateALength = (aLength: string, setValue: boolean = false) => {
        if (!validateUnSignedDecimal(aLength)) {
            return;
        }

        if (this.props.maxDecimalPlaces !== undefined && getDecimalPlaces(aLength) > this.props.maxDecimalPlaces) {
            return;
        }

        const aLengthRange = this.props.sscDistributionOptions!.aLengthRange;
        let valid = true;
        let value = Number(aLength);
        if (aLength === '') {
            this.aLengthErrorText = i18n.t(LocalizationKeys.MissingValue);
            valid = false;
        } else if (value < aLengthRange.min) {
            this.aLengthErrorText = i18n.t(LocalizationKeys.ValueLesserThan, { value: roundToDecimalPoint(aLengthRange.min, 4, false) }) + " " + this.props.unit!;
            valid = false;
        } else if (value > aLengthRange.max) {
            this.aLengthErrorText = i18n.t(LocalizationKeys.ValueLesserThan, { value: roundToDecimalPoint(aLengthRange.max, 4, false) }) + " " + this.props.unit!;
            valid = false;
        }

        this.isValidALength = valid;
        this.aLength = aLength;
        if (setValue) {
            this.setState({ alength: { value: value, unit: this.props.unit! } });
        }
    }

    private validateBLength = (bLength: string, setValue: boolean = false) => {
        if (!validateUnSignedDecimal(bLength)) {
            return;
        }

        if (this.props.maxDecimalPlaces !== undefined && getDecimalPlaces(bLength) > this.props.maxDecimalPlaces) {
            return;
        }

        const { legRange } = this.props.sscDistributionOptions!
        let valid = true;
        let value = Number(bLength);
        if (bLength === '') {
            this.bLengthErrorText = i18n.t(LocalizationKeys.MissingValue);
            valid = false;
        } else if (value < legRange.min) {
            this.bLengthErrorText = i18n.t(LocalizationKeys.ValueLesserThan, { value: roundToDecimalPoint(legRange.min, 4, false) }) + " " + this.props.unit!;
            valid = false;
        }
        else if (value > legRange.max) {
            this.bLengthErrorText = i18n.t(LocalizationKeys.ValueGreaterThan, { value: roundToDecimalPoint(legRange.max, 4, false) }) + " " + this.props.unit!;
            valid = false;
        }

        this.isValidBLength = valid;
        this.bLength = bLength;
        if (setValue) {
            this.setState({ blength: { value: value, unit: this.props.unit! } });
        }

        this.validateSLength(this.sLength)
    }

    private validateSLength(sLength: string) {
        let value = Number.parseFloat(sLength);

        const sLengthRange = this.props.sscDistributionOptions!.staggerRange;
        const bLengthRange = this.props.sscDistributionOptions!.legRange;
        const bLengthValue = Number.parseFloat(this.bLength) ?? 0;
        const groupCount = Number.parseInt(this.state.groupCountFieldValue ?? "") ?? 0;

        const { sLengthErrorText, valid } = validateStaggerValue({
            bLengthRange,
            bLengthValue,
            groupCount,
            sLengthRange,
            sLengthValue: value,
            unit: this.props.unit!
        })

        this.sLengthErrorText = sLengthErrorText;

        this.isValidSLength = valid;
        return { valid, value };
    }

    private enableSLength(groupCount?: string) {
        const groupFieldValue = this.state.groupCountFieldValue ?? ""
        const count = groupCount ? groupCount : groupFieldValue;
        const enabled = count.length > 0 && count !== "1" && count !== "0";
        const currentVal = this.state.slength.value;

        this.isStaggerEnabled = enabled;

        if (!enabled && currentVal !== 0) {
            this.sLength = "0";
            this.setState({ slength: StaggerLength.Stagger0.value })
        }
    }

    private onConnectorTypeChange = (e: React.ChangeEvent<any>): void => {
        const target = e.target as HTMLSelectElement | HTMLOptionElement;
        const currentValue = this.state.connectorType.key;
        if (currentValue === target.value) {
            return;
        }

        const selectedConnectorType = this.props.sscDistributionOptions!.connectorTypes.find(x => x.key === target.value)!;

        const newDestinationState = this.validateConnectorType(selectedConnectorType)
        this.setState(newDestinationState)
    }

    private onAccessPointsChange = (e: React.ChangeEvent<any>): void => {
        if (!validateWholeNumber(e.currentTarget.value)) {
            return;
        }
        const newDestinationState = this.validateAccessPoints(e.currentTarget.value);
        this.setState(newDestinationState)
    }

    private onGroupCountChange = (e: React.ChangeEvent<any>): void => {
        let entry = e.currentTarget.value;
        if (!validateWholeNumber(entry)) {
            return;
        }

        const newDestinationState = this.validateGroupCount(entry);

        this.setState(newDestinationState)
        if (newDestinationState.isGroupCountValid && newDestinationState.isNbConnectorsPerGroupValid) {
            this.validateSLength(this.sLength);
            this.enableSLength(entry);
        }
    }

    private onNbConnectorsPerGroupChange = (e: React.FormEvent<any>): void => {
        let entry = e.currentTarget.value;
        if (!validateWholeNumber(entry) && !entry.length) {
            return;
        }

        const newDestinationState = this.validateConnectorsPerGroupCount(entry)
        this.setState(newDestinationState)

        if (this.state.groupCountFieldValue && newDestinationState.isGroupCountValid && newDestinationState.isNbConnectorsPerGroupValid) {
            this.validateSLength(this.sLength)
        }
    }

    private onALengthChange = (e: React.ChangeEvent<any>): void => {
        this.validateALength(e.currentTarget.value, true);
    }

    private onBLengthChange = (e: React.ChangeEvent<any>): void => {
        this.validateBLength(e.currentTarget.value, true);
    }

    private onStaggerValueChanged = (e: React.ChangeEvent<{}>, sLength: string): void => {
        if (sLength !== this.state.slength.value.toString()) {
            this.isValidSLength = true;
            this.sLength = sLength;
            this.setState({ slength: { value: Number(sLength), unit: this.props.unit! } })
        }
    };

    private onStaggerInputChanged = (e: React.ChangeEvent<{}>, sLength: string): void => {
        if (getDecimalPlaces(sLength) > decimalPlacesBasedOnUnit(this.props.unit!)) {
            return;
        }

        if (!validateUnSignedDecimal(sLength)) {
            if (this.isValidSLength) {
                this.isValidSLength = false;
                this.sLengthErrorText = i18n.t(LocalizationKeys.InvalidEntry);
                this.setState({});
            }
            return;
        } else if (this.isConvertingUnits) {
            this.isConvertingUnits = !this.isConvertingUnits;
            return;
        }

        if (sLength !== this.sLength) {
            let { valid, value } = this.validateSLength(sLength);

            this.sLength = sLength;
            this.isValidSLength = valid;
            this.setState({ slength: { value: value, unit: this.props.unit! } })
        }
    }

    private getConnectorTypeOptions = (): JSX.Element[] => {
        let filteredConnectorTypes: IConnectorType[] = this.getConnectorTypes();

        return filteredConnectorTypes.map(c => <Option key={c.key} value={c.key}>{c.description}</Option>);
    }

    private updateConnectorType = (prevProps: props) => {
        const oldConnectorTypes = prevProps.sscDistributionOptions!.connectorTypes;
        const newConnectorTypes = this.props.sscDistributionOptions!.connectorTypes;
        const connectorTypesChanged = oldConnectorTypes !== newConnectorTypes;

        if (connectorTypesChanged) {
            const connectorType = !connectorTypesChanged || newConnectorTypes.some(x => x.type === this.state.connectorType.type) ? this.state.connectorType : newConnectorTypes[0];
            this.setState({ connectorType });
        }
    }

    private getDestinationData = (): IDestinationData => {
        let selectedPosition = this.props.selection!.selected;
        let destination: IDestinationData;
        if (selectedPosition > 0) {
            destination = this.props.destinations!.find(x => x.position === selectedPosition)!;
            let previousALength = destination.lengthA;
            if (selectedPosition === 1) {
                previousALength = this.props.source!.lengthA;
            }
            else {
                previousALength = this.props.destinations!.find(x => x.position === selectedPosition - 1)!.lengthA;
            }
            destination = { ...destination, lengthA: previousALength };
        } else {
            destination = this.props.destinations![0];
        }
        return destination;
    }

    private GetDstFiberCount = (tap: IDestinationData) => {
        const tapFiberCount = tap.groups.map(g => g.connectors).flat().map(c => getConnectorType(c.type!).fiberCount).reduce((a, b) => a + b, 0)
        return tapFiberCount;
    }

    private getConnectorTypes() {
        let filteredConnectorTypes: IConnectorType[] = [];
        const connectorTypes = this.props.sscDistributionOptions!.connectorTypes;
        if (this.props.context === ViewportStatus.Editing) {
            filteredConnectorTypes = connectorTypes.filter(x => Math.floor(this.dropMaxFiber / x.fiberCount) > 0);
        } else {
            const { min: minValidGroupCount } = this.props.sscDistributionOptions!.groupCounts;
            const { min: minValidAPCount } = this.props.sscDistributionOptions!.accessPointRange;
            const { min: minValidCPG } = this.props.sscDistributionOptions!.connectorsPerGroupCounts;
            const fiberCount = this.state.fiberCount;
            filteredConnectorTypes = connectorTypes.filter(c => c.fiberCount * minValidAPCount * minValidCPG * minValidGroupCount <= fiberCount);
        }
        return filteredConnectorTypes;
    }

    render() {
        const isEditing = this.props.context === ViewportStatus.Editing;
        const isAsymmetric = this.props.isAsymmetric;
        const apContainerClassName = isEditing ? "field-container" : "field-container horizontal";
        const apFieldClassName = isEditing ? "field field-disabled" : "field";
        const apFieldDisabled = isEditing || isAsymmetric;
        const cpgDisabled = isEditing;
        const connectorTypesDisabled = isEditing && !this.props.isConnectorEditabled

        const position = isEditing ? this.state.position : i18n.t(LocalizationKeys.UnknownTAPPosition);
        const aPosition = isEditing ? this.state.position! - 1 : i18n.t(LocalizationKeys.UnknownTAPPosition);
        const unit = getUnitsName(this.props.unit!);
        const disabled = !!this.props.disabled;
        return (
            <div className="setup">
                <div className="field-container">
                    <Select label={i18n.t(LocalizationKeys.ConnectorType)} className="field" onChange={this.onConnectorTypeChange} value={this.state.connectorType.key} margin="dense" disabled={disabled || connectorTypesDisabled} palette="primary" helperText="" units="">
                        {this.getConnectorTypeOptions()}
                    </Select>
                </div>
                <div className={apContainerClassName}>
                    <div className="ap-field field">
                        <FilledTextField palette='primary'
                            className={apFieldClassName}
                            disabled={apFieldDisabled || disabled}
                            label={i18n.t(LocalizationKeys.NbAccessPoints)}
                            helperText={this.state.isAccessPointsValid ? "" : this.state.accessPointsErrorText}
                            error={!this.state.isAccessPointsValid} value={this.state.accessPointsFieldValue} 
                            onChange={(e) => this.onAccessPointsChange(e)}/>
                    </div>
                    <DropCheckbox />
                </div>
                <div className="field-container horizontal">
                    <div className="group-field field">
                        <FilledTextField palette='primary'
                            disabled={disabled}
                            label={i18n.t(LocalizationKeys.NbGroups)}
                            helperText={this.state.isGroupCountValid ? "" : this.state.groupCountErrorText}
                            error={!this.state.isGroupCountValid} value={this.state.groupCountFieldValue} 
                            onChange={(e) => this.onGroupCountChange(e)}/>
                    </div>
                    <div className="connector-separator">
                        X
                    </div>
                    <div className="cpg-field field">
                        <FilledTextField palette='primary'
                            disabled={cpgDisabled || disabled}
                            label={i18n.t(LocalizationKeys.NbCPG)}
                            helperText={this.state.isNbConnectorsPerGroupValid ? "" : this.state.nbConnectorsPerGroupErrorText}
                            error={!this.state.isNbConnectorsPerGroupValid} value={this.state.nbConnectorsPerGroupFieldFieldValue} 
                            onChange={(e) => this.onNbConnectorsPerGroupChange(e)}/>
                    </div>
                </div>
                <div className="field-container">
                    <FilledTextField palette='primary'
                        disabled={true}
                        label={i18n.t(LocalizationKeys.NbDstConnectors)}
                        helperText={""}
                        value={this.state.groupCount! * this.state.nbConnectorsPerGroup!}/>
                </div>
                <div className="field-container">
                    <FilledTextField palette='primary'
                        disabled={disabled}
                        label={i18n.t(LocalizationKeys.LengthAWithUnit, { position: aPosition, unit: unit })}
                        helperText={this.isValidALength ? "" : this.aLengthErrorText}
                        error={!this.isValidALength} value={this.aLength} 
                        onChange={(e) => this.onALengthChange(e)}/>
                </div>
                <div className="field-container">
                    <BLengthSection trunk={{ ...this.props.currentDestination }} disabled={disabled}>
                        <UniformBLengthLength>
                            <div className="field-container horizontal">
                                <div className="lengthB-field field">
                                    <FilledTextField palette='primary'
                                        disabled={disabled}
                                        label={i18n.t(LocalizationKeys.LengthBWithUnit, { position: position, unit: unit })}
                                        helperText={this.isValidBLength ? "" : this.bLengthErrorText}
                                        error={!this.isValidBLength} value={this.bLength} 
                                        onChange={(e) => this.onBLengthChange(e)}/>
                                </div>
                                <StaggerAutocomplete
                                    value={this.sLength}
                                    position={position!}
                                    disable={!this.isStaggerEnabled || disabled}
                                    onValueChanged={this.onStaggerValueChanged}
                                    onInputChanged={this.onStaggerInputChanged}
                                    isInputValid={this.isValidSLength}
                                    inputErrorText={this.sLengthErrorText}
                                />
                            </div>
                        </UniformBLengthLength>
                        <CustomBLength disabled={disabled} />
                    </BLengthSection>
                </div>
            </div>
        );
    }
}

export const DestinationSetup = connect(mapStateToProps, mapDispatchToProps)(DestinationSetupComponent)