import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { useOpenIdCallback } from './hooks';
import { LocalizationKeys } from '../../../../../locales/types';

import './OpenIdCallback.scss';

export const OpenIdCallback = (): JSX.Element => {
    const { t } = useTranslation();
    const { successful, reason, isLogout } = useOpenIdCallback();

    let content;
    if (isLogout) {
        content = <Navigate to="/" />;
    }
    else if (successful === undefined) {
        content = <h1>{t(LocalizationKeys.Processing)}</h1>;
    }
    else if (successful) {
        content = <Navigate to="/" />;
    }
    else {
        content = <div><h1>{t(LocalizationKeys.OpenIdFailed)}</h1><h2>{reason}</h2></div>;
    }
    return (
        <div className="login-container">
            <div className="login-content">
                <div className="message">{content}</div>
            </div>
        </div>
    );
};
