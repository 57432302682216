import React from 'react';
import { useRepresentativeExample } from './hooks';

export const RepresentativeExample = () => {
    const { label, show } = useRepresentativeExample();

    return !show ? null : (
        <div className="representative-example">{label}</div>
    );
};
