import { useEffect } from "react"
import { useApp } from "@inlet/react-pixi"
import { initializeCanvasExtractor } from "../canvas-extractor/CanvasExtactor";

export const useInitializer = () => {
    const app = useApp();
    useEffect(() => {
        if (app) {
            initializeCanvasExtractor(app);
        }
    }, [app])
}