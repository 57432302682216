import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../../../../../locales/types";
import { setViewportContext } from "../../../../../../../../../pixi/components/viewport/redux/reducers";
import { ViewportStatus } from "../../../../../../../../../pixi/components/viewport/redux/types";
import { setShowAboutApplication } from "../../../../../../redux/reducers";
import { showConnectorReportSelector } from "../../../../../../redux/selectors";
import { IToolItemProps } from "../../../../../../ToolItem";
import { wizardDisplaySelector } from "../../../../../wizard/redux/selectors";
import { currentStatusSelector } from "../../../status/redux/selectors";
import { WorkspaceStatus } from "../../../status/redux/types";
import AboutIcon from '../../../../resources/icons/icon_about.svg';

export const useAboutThisApplicationButton = () : IToolItemProps => {
    const { t } = useTranslation();
    const currentStatus = useSelector(currentStatusSelector);
    const wizardDisplay = useSelector(wizardDisplaySelector);
    const connectorReportDisplay = useSelector(showConnectorReportSelector);
    const [state, setState] = useState<IToolItemProps>({});
    const dispatch = useDispatch();

    const onClick = useCallback(() => {
        dispatch(setViewportContext(ViewportStatus.Inactive));
        dispatch(setShowAboutApplication(true));
    }, [dispatch])

    useEffect(() => {
        const isLoading = currentStatus === WorkspaceStatus.Loading || currentStatus === WorkspaceStatus.InitialLoad;
        const disabled = wizardDisplay || connectorReportDisplay || isLoading;
        setState({
            disabled,
            alt: t(LocalizationKeys.AboutThisApplication),
            tooltip: t(LocalizationKeys.AboutThisApplication)
        });
    }, [t, connectorReportDisplay, wizardDisplay, currentStatus])

    return { ...state, source: AboutIcon, onClick }

}