import React from 'react';
import { useHeader } from './hooks';
import { ProjectButton } from './components/project/ProjectButton';
import { StatusIcon } from './components/status/StatusIcon';
import { HeaderActions } from './components/HeaderActions';

import './Header.scss';

export const Header: React.FC = () => {
    const { headerClassName } = useHeader();
    return (
        <div className={headerClassName}>
            <div className='header-start'>
                <ProjectButton />
                <StatusIcon />
            </div>
            <div className='header-end'>
                <HeaderActions />
            </div>
        </div>
    );
}