import { AuthenticationService } from "../../../services/authentication-service";
import { Unit } from "../../overlay/components/header/components/units-of-measure-container/UnitsOfMeasure";

export interface IAuthenticationState {
    loggedIn: boolean;
    failed: boolean;
    token?: string;
    email?: string;
    issuedAt?: number;
    expiresAt?: number;
    userId?: number;
    customer?: HybrisCustomer;
    firstName?: string;
    given_name?: string;
    lastName?: string;
    groupId?: number;
}

export const initialToken = AuthenticationService.getToken();
export const initialState: IAuthenticationState = {
    loggedIn: false,
    failed: false 
};

interface HybrisAccount {
    uid: string;
    name: string;
    soldTo: string;
    unitOfMeasure: Unit;
}

interface HybrisCustomer {
    uid: string | undefined;
    name: string;
    email: string;
    account: HybrisAccount;
    isAnonymous: boolean;
}