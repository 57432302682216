import React from 'react';
import { useLCTemplate } from './hooks';
import { ConnectorPin } from './ConnectorPin';
import { ConnectorCasing } from './ConnectorCasing';
import { IConnectorTemplateProps } from './types';
import { LC } from '../../../../../../../../pixi/factories/Texture';

export const LCTemplate: React.FC<IConnectorTemplateProps> = (props) => {
    const { onClick, pins, label, disabled } = useLCTemplate(props);

    return (
        <svg width="146" height="134" viewBox="0 0 170 109" onClick={onClick}>
            <defs>
                <filter id="lcTemplateFilter" x="0" y="19" width="146" height="90" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" in="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="b" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="b" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g transform="translate(21 1)">
                { disabled ? null : <ConnectorCasing width={128} height={72} fill="#e5ebeb" filter="url(#lcTemplateFilter)" /> }
                <text transform="translate(34 17)" stroke="rgba(0,0,0,0)" fontSize="16px" fontFamily="TheSansC4s">
                    <tspan x="0" y="0">A</tspan>
                </text>
                <text transform="translate(90 17)" stroke="rgba(0,0,0,0)" fontSize="16px" fontFamily="TheSansC4s" fontWeight="bold">
                    <tspan x="0" y="0">B</tspan>
                </text>
                <text transform="translate(64 115)" stroke="rgba(0,0,0,0)" fontSize="16px" fontFamily="TheSansC4s" textAnchor="middle">
                    <tspan x="0" y="0">{label}</tspan>
                </text>
                {
                    pins.map(p => {
                        return (
                            <g key={p.index} transform={`translate(${p.position.x}, ${p.position.y})`}>
                                <ConnectorPin connectorType={LC} pinIndex={p.index} disabled={p.disabled} />
                            </g>
                        )
                    })
                }
                { disabled ? <ConnectorCasing width={128} height={72} fill="#202020" opacity={0.4} filter="url(#lcTemplateFilter)" /> : null }
            </g>
        </svg>
    )
}