import React from "react";
import { useWizardHeader } from "./hooks";
import { ToolItem } from "../../../../ToolItem";

import './WizardHeader.scss';

export const WizardHeader = () => {
    const { title, subtitle, isClosable, onClose } = useWizardHeader();
    return ( 
        <div className="wizard-header">
            <div className={"wizard-header-title-container"}>
                <div className="wizard-header-title">{title}</div> 
                <div className="wizard-header-subtitle">{subtitle}</div>
            </div>
            { isClosable ? <ToolItem icon={"close"} alt="Close" onClick={onClose} /> : null }
        </div>
    );
}