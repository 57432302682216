import { ICheckboxInput } from "./types";
import { useState, useCallback, useEffect } from "react";

export const useCheckBoxInput = (name: string, label: string, value: boolean = true, disabled?: boolean): ICheckboxInput => {
    const [checked, setChecked] = useState(value);
    const [disabledChildren, setDisabledChildren] = useState(!value);
    const [labelClass, setLabelClass] = useState("");
    const [className, setClassName] = useState("checkbox");

    const onCheckBoxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(e.currentTarget.checked);
        setDisabledChildren(!e.currentTarget.checked);
    }, [])

    const onLabelMouseDown = useCallback((e: React.MouseEvent<HTMLLabelElement>) => {
        if (!disabled) {
            setChecked(!checked);
            setDisabledChildren(checked);
        }

    }, [checked, disabled])

    useEffect(() => {
        if (!disabled) {
            setLabelClass(checked ? "label-selected" : "")
            setClassName(checked ? "checkbox-selected" : "checkbox")
        }
        else {
            setClassName("checkbox-disabled")
        }
    }, [checked, disabled])

    useEffect(() => {
        setChecked(value)
        setDisabledChildren(!value)
    }, [value])

    return { checked, labelClass, name, label, disabledChildren, disabled, onCheckBoxChange, className, onLabelMouseDown }
}