import { Utils } from './utils';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import Workspace from './workspace/Workspace';
import { Login } from './workspace/components/authentication/components/login/Login';
import { OpenIdCallback } from './workspace/components/authentication/components/oid/OpenIdCallback';

const DevWorkspace = () => {
  return (
    <BrowserRouter>
      <Routes >
          <Route path="/login" element={<Login />} />
          <Route path="/oid_login" element={<OpenIdCallback />} />
          <Route path="/oid_logout" element={<OpenIdCallback />} />
          <Route path="/" element={<Workspace />}/>
      </Routes>
    </BrowserRouter>
)}

const HybrisWorkspace = () => {
  return (
      <Workspace />
  )
}

const App = () => Utils.isHybrisEnvironment() ? <HybrisWorkspace/> : <DevWorkspace />

export default App;