import { Dispatch } from "react";
import { AnyAction } from "redux";

export interface IDialogProps {
    open: boolean;
    collapsed?: boolean;
    onClose?: () => void;
}

export const DialogTypes = {
    Confirm: "Confirm"
} as const;

export type DialogType = typeof DialogTypes [keyof typeof DialogTypes]

export interface IDialogState {
    type: DialogType,
    props: IDialogProps,
}

export const initialDialogState: IDialogState = {
    type: DialogTypes.Confirm,
    props: {
        open: false
    }
}

export interface IDialogContext {
    state: IDialogState;
    dispatch: Dispatch<AnyAction>;
}

export const initialDialogContext: IDialogContext = {
    state: initialDialogState,
    dispatch: () => {}
}

