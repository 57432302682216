import { CircularProgress, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Drawer } from '../../../../../../../ctcm-components/drawer/Drawer';
import { LocalizationKeys } from '../../../../../../../locales/types';
import CorningLogo from '../../../projects/components/drawer/resources/images/image_corning_drawer_logo.png';
import { DebugMenu } from './components/debug-menu/DebugMenu';
import { ProjectManagerRow } from './components/row/ProjectManagerRow';
import { DrawerSubHeader } from './components/sub-header/DrawerSubHeader';
import { useProjectDrawer } from './hooks';
import { ProjectDrawerContext } from './redux/types';

import "./ProjectDrawer.scss";

export const ProjectDrawer = () => {
    const { t } = useTranslation();
    const { open, projects, loading, reachedLastPage, reloading, drawerStyle, context, containerRef, onDrawerClose, onScroll } = useProjectDrawer();
    return (
        <ProjectDrawerContext.Provider value={context}>
            <Drawer
                open={open}
                onClose={onDrawerClose}
                logo={CorningLogo}
                style={drawerStyle}
            >
                <DrawerSubHeader />
                <Divider />
                <div className="rows-container" onScroll={onScroll} ref={containerRef}>
                    {
                        reloading ? null :
                            projects.map(({ projectId, selected, deleteDisabled, editDisabled }, index) => {
                                return (<ProjectManagerRow key={index} projectId={projectId} selected={selected} deleteDisabled={deleteDisabled} editDisabled={editDisabled} />);
                            })
                    }
                    {!reachedLastPage && !loading ? <span className="scroll">{t(LocalizationKeys.LoadMoreCables)}</span> : null}
                    {loading ?
                        <div className="circular-progress-container">
                            <CircularProgress />
                        </div> : null
                    }
                    <DebugMenu />
                </div>
            </Drawer>
        </ProjectDrawerContext.Provider>
    );
}