import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLabelSchemes } from "../../redux/build/connector/labelscheme/hooks";
import { useWizardPresetLoad } from "../../redux/build/wizard/wizard-preset/hooks";
import { useProjectManager } from "../../redux/project-manager/hooks";
import { useSscConfigSession } from "../../redux/ssc/hooks";
import { authenticationSelector } from "../authentication/redux/selectors";
import { setStatusState } from "./components/header/components/status/redux/reducer";
import { currentStatusSelector } from "./components/header/components/status/redux/selectors";
import { WorkspaceStatus } from "./components/header/components/status/redux/types";

export const useWebWorkspace = () => {
    const currentStatus = useSelector(currentStatusSelector);
    const { loggedIn } = useSelector(authenticationSelector);

    const { loadWizardPresets } = useWizardPresetLoad();
    const { loadOrgSchemes } = useLabelSchemes();
    const { loadRecentProject, loadProjectManager } = useProjectManager();
    const { createConfigSession } = useSscConfigSession();

    const dispatch = useDispatch();

    useEffect(() => {
        if (loggedIn) {
            dispatch(setStatusState(WorkspaceStatus.InitialLoad))
        }
    }, [loggedIn, dispatch]);

    useEffect(() => {
        if (currentStatus === WorkspaceStatus.InitialLoad) {
            createConfigSession();
        }

        // eslint-disable-next-line
    }, [currentStatus]);

    useEffect(() => {
        // All other calls concurrent
        if (currentStatus === WorkspaceStatus.Loading) {
            loadOrgSchemes();
            loadWizardPresets();
            loadProjectManager();
            loadRecentProject();
        }

        // eslint-disable-next-line
    }, [currentStatus])

    return { currentStatus };
}