import { useDispatch } from "react-redux"
import { useCallback } from "react";
import { LabelSchemeService } from "../../../../services/labelscheme/labelscheme-service";
import { setOrgBundles } from "./reducers";
import { extractData } from "../../../actions";

export const useLabelSchemes = () => {
    const storeDispatch = useDispatch();

    const loadOrgSchemes = useCallback(async () => {
        const res = await new LabelSchemeService().getOrgBundles();
        const orgBundles = extractData(res, storeDispatch);
        if (orgBundles) {
            storeDispatch(setOrgBundles(orgBundles));
        }
    }, [storeDispatch]);

    return { loadOrgSchemes };
};