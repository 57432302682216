import React from 'react';
import { useBuildInfo } from './hooks';

import './BuildInfo.scss';

export const BuildInfo = (props: { projectId: number }) => {
    const { projectId } = props;
    const { name, catalogCode, description, polarity } = useBuildInfo(projectId);

    return (
        <div className="build-info">
            <div className="build-name">
                {name}
            </div>
            <div className="build-part-number">
                {catalogCode}
            </div>
            <div className="build-description">
                {description}
            </div>
            <div className="build-polarity">
                {polarity}
            </div>
        </div>
    );
}