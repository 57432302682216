import React from "react";
import { Checkbox } from "@orbit/checkbox";
import classNames from "classnames";
import { CollapsibleDialog } from "../../../../../../../../../ui/dialog/collapsible/CollapsibleDialog";
import { WarningSeverity } from "../../redux/types";
import { useWarningsCard } from "./hooks";

import "./WarningsCard.scss";

export const WarningsCard = () => {
    const {
        display,
        headerProps,
        errorsCheckboxProps,
        warningsCheckboxProps,
        filteredWarnings,
    } = useWarningsCard();

    return (
        <CollapsibleDialog className="warnings-card" headerProps={headerProps} display={display}>
            <div className="filter-container">
                <Checkbox palette="primary" {...errorsCheckboxProps} />
                <Checkbox palette="primary" {...warningsCheckboxProps} />
            </div>
            <ul className="warnings-list">
                {filteredWarnings.map((w, i) => (
                    <li key={i} className={classNames("warning-item", { 
                        error: w.severity === WarningSeverity.Error, 
                        warning: w.severity === WarningSeverity.Warning 
                    })}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="warnings-icon-cell">
                                        <span className="material-icons">
                                            {w.severity === WarningSeverity.Error ? "cancel" : "warning"}
                                        </span>
                                    </td>
                                    <td className="problem-text">{w.problemText}</td>
                                </tr>
                                <tr>
                                    <td />
                                    <td className="solution-text">{w.solutionText}</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                ))}
            </ul>
        </CollapsibleDialog>
    );
};
