import { useContext, useEffect } from 'react';
import { DrawerContext } from '../drawer-context/DrawerContext';
import { DrawerActionTypes, DrawerContextProps } from '../drawer-context/types';

export const useDrawer = (props: DrawerContextProps) => {
    const [drawerContext, drawerDispatch] = useContext(DrawerContext);
    const { open: display } = drawerContext;

    useEffect(() => {
        if (drawerDispatch) {
            if (props.open !== undefined && props.open !== display) {
                drawerDispatch({ type: DrawerActionTypes.SetDisplay, payload: props.open });
            }
        }
    }, [drawerDispatch, props.open, display])

    return { display }
}