import React from 'react';
import { ConnectorTemplateProps } from './types';
import { useMTPTemplate } from './hooks';
import { ConnectorPin } from './ConnectorPin';
import { ConnectorCasing } from './ConnectorCasing';

export const MTPTemplate: React.FC<ConnectorTemplateProps> = (props) => {
    const { onClick, isMTP24, pins, label, disabled, type } = useMTPTemplate(props);
    return (
        <svg width="714" height="190" viewBox="0 0 714 165" onClick={onClick}>
            <defs>
                <filter id="mtpTemplateFilter" x="0" y="19" width="714" height="146" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" in="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="b" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="b" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g transform="translate(9 1)">
                { disabled ? null : <ConnectorCasing width={696} height={128} fill="#e5ebeb" filter="url(#mtpTemplateFilter)" /> }
                <path d="M8,0H152a8,8,0,0,1,8,8v8a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z" transform="translate(270 8)" fill="#000010" />
                <text transform="translate(34 17)" stroke="rgba(0,0,0,0)" fontSize="16px" fontFamily="TheSansC4s" fontWeight="bold">
                    <tspan x="0" y="0">1</tspan>
                </text>
                <text transform="translate(645 17)" stroke="rgba(0,0,0,0)" fontSize="16px" fontFamily="TheSansC4s" fontWeight="bold">
                    <tspan x="0" y="0">12</tspan>
                </text>
                <text transform="translate(348 173)" stroke="rgba(0,0,0,0)" fontSize="16px" fontFamily="TheSansC4s" textAnchor="middle">
                    <tspan x="0" y="0">{label}</tspan>
                </text>
                {
                    isMTP24 ?
                    <>
                        <text transform="translate(34 173)" stroke="rgba(0,0,0,0)" fontSize="16px" fontFamily="TheSansC4s" fontWeight="bold">
                            <tspan x="0" y="0">13</tspan>
                        </text>
                        <text transform="translate(645 173)" stroke="rgba(0,0,0,0)" fontSize="16px" fontFamily="TheSansC4s" fontWeight="bold">
                            <tspan x="0" y="0">24</tspan>
                        </text>
                    </> : null
                }
                {
                    pins.map(p => {
                        return (
                            <g key={p.index} transform={`translate(${p.position.x}, ${p.position.y})`}>
                                <ConnectorPin connectorType={type} pinIndex={p.index} disabled={p.disabled} />
                            </g>
                        )
                    })
                }
                { disabled ? <ConnectorCasing width={696} height={128} fill="#202020" opacity={0.4} filter="url(#mtpTemplateFilter)" /> : null }
            </g>
        </svg>
    )
}