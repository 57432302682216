import React from "react";
import { renderToString } from "react-dom/server";

export const MTP24BootColor = "#231F20";
export const MTPBootColor = "#484646"

export const MTPSVG = (housingColorHex: string, bootColorHex: string, pinned: boolean, scale?: number) => {
    let width = 260;
    let height = 1000;
    if (scale) {
        width *= scale;
        height *= scale;
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 100">
            { 
                pinned && 
                <>
                    <g stroke="#484646" transform="translate(8 93)">
                        <rect stroke="none" width="3" height="7"/>
                        <rect fill="none" x="0.5" y="0.5" width="2" height="6"/>
                    </g>
                    <g stroke="#484646" transform="translate(15 93)">
                        <rect stroke="none" width="3" height="7"/>
                        <rect fill="none" x="0.5" y="0.5" width="2" height="6"/>
                    </g>
                </>
            }
            <g fill="#606060" transform="translate(-1144 -1449)">
                <path stroke="none" d="M 1163.5 1542.5 L 1150.5 1542.5 L 1150.5 1539 L 1150.5 1538.5 L 1150 1538.5 L 1147.5 1538.5 L 1147.5 1528 L 1147.5 1527.5 L 1147 1527.5 L 1145.5 1527.5 L 1145.5 1495.181030273438 L 1150.234130859375 1489.5 L 1163.765869140625 1489.5 L 1168.5 1495.181030273438 L 1168.5 1527.5 L 1167 1527.5 L 1166.5 1527.5 L 1166.5 1528 L 1166.5 1538.5 L 1164 1538.5 L 1163.5 1538.5 L 1163.5 1539 L 1163.5 1542.5 Z"/>
                <path stroke="none" fill="#484646" d="M 1150.468383789062 1490 L 1146 1495.362060546875 L 1146 1527 L 1147 1527 L 1148 1527 L 1148 1528 L 1148 1538 L 1150 1538 L 1151 1538 L 1151 1539 L 1151 1542 L 1163 1542 L 1163 1539 L 1163 1538 L 1164 1538 L 1166 1538 L 1166 1528 L 1166 1527 L 1167 1527 L 1168 1527 L 1168 1495.362060546875 L 1163.531616210938 1490 L 1150.468383789062 1490 M 1150 1489 L 1164 1489 L 1169 1495 L 1169 1528 L 1167 1528 L 1167 1539 L 1164 1539 L 1164 1543 L 1150 1543 L 1150 1539 L 1147 1539 L 1147 1528 L 1145 1528 L 1145 1495 L 1150 1489 Z"/>
            </g>
            <g transform="translate(-1144 -1449)">
                <path stroke="none" fill={bootColorHex} d="M 1163.5 1489.5 L 1150.5 1489.5 L 1150.5 1479.02490234375 L 1153.452514648438 1449.5 L 1160.547485351562 1449.5 L 1163.5 1479.02490234375 L 1163.5 1489.5 Z"/>
                <path stroke="none" fill="#484646" d="M 1153.905029296875 1450 L 1151 1479.049926757812 L 1151 1489 L 1163 1489 L 1163 1479.049926757812 L 1160.094970703125 1450 L 1153.905029296875 1450 M 1153 1449 L 1161 1449 L 1164 1479 L 1164 1490 L 1150 1490 L 1150 1479 L 1153 1449 Z"/>
            </g>
            <g stroke="#484646" fill={housingColorHex} transform="translate(0 53)">
                <rect stroke="none" width="26" height="27"/>
                <rect fill="none" x="0.5" y="0.5" width="25" height="26"/>
            </g>
            <g stroke="#484646" fill="none" transform="translate(3 71)">
                <rect stroke="none" width="20" height="6"/>
                <rect fill="none" x="0.5" y="0.5" width="19" height="5"/>
            </g>
            <g stroke="#484646" fill="none" transform="translate(24 65)">
                <rect stroke="none" width="2" height="2"/>
                <rect fill="none" x="0.5" y="0.5" width="1" height="1"/>
            </g>
            <g stroke="#484646" fill="none" transform="translate(0 65)">
                <rect stroke="none" width="2" height="2"/>
                <rect fill="none" x="0.5" y="0.5" width="1" height="1"/>
            </g>
            <g stroke="#484646" fill="none" transform="translate(24 61)">
                <rect stroke="none" width="2" height="2"/>
                <rect fill="none" x="0.5" y="0.5" width="1" height="1"/>
            </g>
            <g stroke="#484646" fill="none" transform="translate(0 61)">
                <rect stroke="none" width="2" height="2"/>
                <rect fill="none" x="0.5" y="0.5" width="1" height="1"/>
            </g>
            <g stroke="#484646" fill="none" transform="translate(24 57)">
                <rect stroke="none" width="2" height="2"/>
                <rect fill="none" x="0.5" y="0.5" width="1" height="1"/>
            </g>
            <g stroke="#484646" fill="none" transform="translate(0 57)">
                <rect stroke="none" width="2" height="2"/>
                <rect fill="none" x="0.5" y="0.5" width="1" height="1"/>
            </g>
            <g fill="none" transform="translate(8 59)">
                <path stroke="none" d="M5,0l5,7H0Z"/>
                <path stroke="none" fill="#484646" d="M 5 1.720461845397949 L 1.94318675994873 6 L 8.05681324005127 6 L 5 1.720461845397949 M 5 0 L 10 7 L 0 7 L 5 0 Z"/>
            </g>
            <rect fill="#484646" width="1" height="14" transform="translate(3 55)"/>
            <rect fill="#484646" width="1" height="14" transform="translate(22 55)"/>
            <rect fill="#484646" width="20" height="1" transform="translate(3 47)"/>
            <rect fill="#484646" width="20" height="1" transform="translate(3 89)"/>
            <rect fill="#484646" width="14" height="1" transform="translate(6 30)"/>
            <rect fill="#484646" width="12" height="1" transform="translate(7 22)"/>
            <rect fill="#484646" width="10" height="1" transform="translate(8 14)"/>
            <rect fill="#484646" width="8" height="1" transform="translate(9 7)"/>
        </svg>
    )
} 

export const getMTPSVGString = (housingColorHex: string, bootColorHex: string, pinned: boolean): string => {
    const mtpUnibootSvg = MTPSVG(housingColorHex, bootColorHex, pinned);
    return renderToString(mtpUnibootSvg);
};