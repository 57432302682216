import { Unit, UoMInches } from '../UnitsOfMeasure';

export interface IUnitsOfMeasureContainerState {
    display: boolean,
    unit: Unit,
}

export const initialState: IUnitsOfMeasureContainerState = {
    display: false,
    unit: UoMInches,
}