import React, { createContext } from "react"
import Pixi from "pixi.js"
import { Rectangle } from "../interaction/types"
import { Container } from "@inlet/react-pixi"
import { useBoundingBox } from "./hooks"

export interface IBoundingBoxContext {
    ref: React.RefObject<Pixi.Container>,
    bounds: Rectangle,
    isMouseOver: boolean
}

const initialBounds: IBoundingBoxContext = {
    ref: React.createRef(),
    bounds: { x: 0, y: 0, width: 0, height: 0 },
    isMouseOver: false
}

export const BoundingBoxContext = createContext(initialBounds)

export const BoundingBox = (props: { children: JSX.Element[] | JSX.Element, buffer: Rectangle }) => {
    const { children, buffer } = props
    const { bounds, containerRef, isMouseOver } = useBoundingBox(buffer);
    
    return (
        <BoundingBoxContext.Provider value={{ ref: containerRef, bounds: bounds, isMouseOver: isMouseOver }}>
            <Container name={"bounding-box-container"} interactive={true} interactiveChildren={true} ref={containerRef}>
                {children}
            </Container>
        </BoundingBoxContext.Provider>
    );
}

export default BoundingBox;