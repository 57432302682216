import React from 'react';
import { ITemplateProps } from './types';

import './Template.scss';

export const Template: React.FC<ITemplateProps> = ({ id, name, desc, selected, onClick }) => {
    const className = selected ? "template selected" : "template";
    return (
        <div className={className} onClick={() => onClick(id)}>
            <div className="info">
                <div className="name">
                    {name}
                </div>
                <div className="description">
                    {desc}
                </div>
            </div>
        </div>
    )
}