import { createContext, Dispatch } from "react";

export interface INavigationMap {
    nbIndex: number;
    currentIndex: number;
}

export interface IFiberMappingNavigation {
    source: INavigationMap;
    destination: IDestinationNavigationMap;
    selectedPinIndex: number;
}

export interface IDestinationNavigationMap {
    taps: INavigationMap;
    connectors: INavigationMap;
}

export interface ISourcePinMap {
    index: number; // 1 based
    connectorIndex: number;
}

export interface IDestinationPinMap {
    index: number;
    tapIndex: number;
    connectorIndex: number;
}

export interface IFiberMap {
    sourcePin: ISourcePinMap;
    destinationPin: IDestinationPinMap;
}

export interface IFiberMappingState {
    navigation: IFiberMappingNavigation;
    mapping: IFiberMap[];
    unused: {
        sourcePins: ISourcePinMap[],
        destinationPins: IDestinationPinMap[]
    },
    isModified: boolean;
}

export interface IFiberMappingData {
    groupId?: number;
    key?: string,
    name: string,
    mapping: IFiberMap[],
    unused: {
        sourcePins: ISourcePinMap[],
        destinationPins: IDestinationPinMap[]
    },
    versionDate?: string;
}

export const initialNavigationMap: INavigationMap = {
    nbIndex: 0,
    currentIndex: -1
}

export const initialFiberMappingState: IFiberMappingState = {
    navigation: {
        source: initialNavigationMap,
        destination: {
            taps: initialNavigationMap,
            connectors: initialNavigationMap
        },
        selectedPinIndex: -1
    },
    mapping: [],
    unused: {
        sourcePins: [],
        destinationPins: []
    },
    isModified: false,
}

export interface IFiberMappingContext {
    state: IFiberMappingState;
    dispatch: Dispatch<any>;
}

export const initialFiberMappingContext: IFiberMappingContext = {
    state: initialFiberMappingState,
    dispatch: () => { }
}

export const FiberMappingContext = createContext(initialFiberMappingContext);

