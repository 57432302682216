import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { IFiberMapRowProps } from "./types";
import { generateFiberMapRows } from "../../../overlay/components/polarity/redux/actions";
import { currentStatusSelector } from "../../../overlay/components/header/components/status/redux/selectors";
import { WorkspaceStatus } from "../../../overlay/components/header/components/status/redux/types";
import { connectorAssignmentSelector } from "../../../../redux/build/connector/polarity/selectors";

export const useFiberMapTable = () => {
    const status = useSelector(currentStatusSelector);
    const [rows, setRows] = useState<IFiberMapRowProps[]>([]);
    const assignments = useSelector(connectorAssignmentSelector)

    const loadFiberMapRows = useCallback(() => {
        let fiberMapRows: IFiberMapRowProps[] = [];
        for (let assignment of assignments) {
            if (assignment.fiberMap) {
                fiberMapRows.push(...generateFiberMapRows(assignment, assignment.fiberMap));
            }
        }
        fiberMapRows = fiberMapRows.sort((a, b) => {
            if (a.sourceFiber.connectorIndex === b.sourceFiber.connectorIndex) {
                return a.sourceFiber.pinIndex > b.sourceFiber.pinIndex ? 1 : -1;
            }
            return a.sourceFiber.connectorIndex > b.sourceFiber.connectorIndex ? 1 : -1
        });
        setRows(fiberMapRows);
    }, [assignments])

    useEffect(() => {
        if (status === WorkspaceStatus.Reporting) {
            loadFiberMapRows();
        }
    }, [status, loadFiberMapRows])
    
    return { rows };
}