import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { IApiResult } from './api-result';
import { ProxyConfigUtils } from './proxy-config';

interface IHeader {
    name: string;
    value?: string;
    delegate?: () => string | null;
}

export abstract class WebService {
    protected isUnstableApi: boolean = true;
    protected client: AxiosInstance;
    private headers: IHeader[] = [];
    private CancelToken = axios.CancelToken;
    private cancelTokenSource: CancelTokenSource;

    constructor() {
        const baseURL = ProxyConfigUtils.getEndpoint();
        this.cancelTokenSource = this.CancelToken.source();
        this.client = axios.create({ baseURL });
        this.client.interceptors.request.use((config) => this.setHeaders(config));
        this.cancelRequestHandler();
    }

    protected async get<T = any>(url: string, params?: any): Promise<IApiResult<T>> {
        const req = this.client.get<IApiResult<T>>(url, { params, cancelToken: this.cancelTokenSource.token });
        return this.wrap('get', url, req);
    }
    protected async post<T = any>(url: string, data?: any, params?: any): Promise<IApiResult<T>> {
        const req = this.client.post<IApiResult<T>>(url, data, { params, cancelToken: this.cancelTokenSource.token });
        return this.wrap('post', url, req);
    }

    protected async put<T = any>(url: string, data: any, params?: any): Promise<IApiResult<T>> {
        const req = this.client.put<IApiResult<T>>(url, data, { params, cancelToken: this.cancelTokenSource.token });
        return this.wrap('put', url, req);
    }

    protected async patch<T = any>(url: string, data: any, params?: any): Promise<IApiResult<T>> {
        const req = this.client.post<IApiResult<T>>(url, data, { params, cancelToken: this.cancelTokenSource.token });
        return this.wrap('patch/post', url, req);
    }

    protected async delete<T = any>(url: string, params?: any): Promise<IApiResult<T>> {
        const req = this.client.delete<IApiResult<T>>(url, { params, cancelToken: this.cancelTokenSource.token });
        return this.wrap('delete', url, req);
    }

    public cancel = () => {
        this.cancelTokenSource.cancel();
    }

    public cancelRequestHandler() {
        // We can add listeners if needed
        window.addEventListener('beforeunload', () => {
            this.cancel();
        });
    }

    private async wrap<T>(verb: string, url: string, call: Promise<AxiosResponse<IApiResult<T>>>): Promise<IApiResult<T>> {
        verb = verb.toUpperCase();
        try {
            const res = await call;
            const apiResult = res.data;
            if (apiResult.succesful === undefined) {
                throw new Error(`An API call expects API response with the 'successful' property.\nCall was:\n\t'${verb} ${url}'\nGot:\n\t${JSON.stringify(apiResult)}`);
            }
            if (!apiResult.succesful) {
                const message = `${verb} ${url} - Not successful; ${apiResult.reason}`;
                console.warn(message);
                // if (this.isUnstableApi) {
                //     NotificationService.warning(message);
                // }
            }
            return apiResult;
        }
        catch (err: any) {
            const res = err.response;
            const code = res && res.status;
            const data = res && res.data;
            let reason = (data && data.reason) || err.message;
            if (code === 500 && typeof data === 'string') {
                reason = data;
            }

            console.error(`[${verb} ${url}] ${reason}`);
            // if (this.isUnstableApi) {
            //     const message = `${verb} ${url}<br/>${reason}`;
            //     NotificationService.error(message);
            // }
            return {
                succesful: false,
                code,
                reason,
            };
        }
    }

    protected addHeader(name: string, value: string): void {
        if (this.isHeaderAlreadyAdded(name)) {
            console.warn(`WebService.addHeader: Duplicate header name ${name}`);
            return;
        }
        this.headers.push({ name, value });
    }

    protected addDynamicHeader(name: string, delegate: () => string | null): void {
        if (this.isHeaderAlreadyAdded(name)) {
            console.warn(`WebService.addDynamicHeader: Duplicate header name ${name}`);
            return;
        }
        this.headers.push({ name, delegate });
    }

    private isHeaderAlreadyAdded(name: string): boolean {
        return this.headers.find((h) => h.name === name) !== undefined;
    }

    private setHeaders(config: AxiosRequestConfig): AxiosRequestConfig {
        for (const header of this.headers) {
            const name = header.name;
            const value = header.value || (header.delegate && header.delegate());
            if (value === null) {
                console.debug(`WebService.setHeaders: Skipping header '${name}' since value is null`);
                continue;
            }
            console.debug(`WebService.setHeaders: Setting header N='${header.name}', V='${value}', D=${!!header.delegate} for request '${config.url}'`);
            if (config.headers && value) config.headers[name] = value;
        }
        return config;
    }
}
