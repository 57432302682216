import { Container } from "@inlet/react-pixi";
import { FeederExternalFurcationSprite, FeederSprite } from "../../EDSSprites";
import { FeederBaseContainer } from './types';
import { useFeederBase } from './hooks';
import { HorizontalTrunk } from "../trunk/horizontal/HorizontalTrunk";
import { areBaseEqual, IBaseProps } from "../../types";
import { withMemoization } from "../../../../memoization/Memoization";

export const FeederBaseGraphics: React.FC<IBaseProps> = (props) => {
    const { externalFurcation, color } = useFeederBase(props);

    return (
        <Container name={FeederBaseContainer}>
            { externalFurcation ? <FeederExternalFurcationSprite color={color}/> : <FeederSprite color={color}/>}
            <HorizontalTrunk />
        </Container>
    );
};

export const FeederBase = withMemoization(FeederBaseGraphics, areBaseEqual);
