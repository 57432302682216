import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../../../../../locales/types';

export const Saved = () => {
    const { t } = useTranslation();

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="140" height="48" viewBox="0 0 140 48">
            <rect fill="#4d8809" opacity="0.8" width="110" height="30" rx="15" transform="translate(16 9)"/>
            <g transform="translate(28 12)">
                <rect fill="none" width="24" height="24"/>
                <path fill="#fdfdfd" d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM8.5,8A1.5,1.5,0,1,1,7,9.5,1.5,1.5,0,0,1,8.5,8ZM12,18a5.458,5.458,0,0,1-5-4H17A5.458,5.458,0,0,1,12,18Zm3.5-7A1.5,1.5,0,1,1,17,9.5,1.5,1.5,0,0,1,15.5,11Z"/>
            </g>
            <rect fill="none" width="140" height="48"/>
            <text fill="#fdfdfd" fontSize="14" fontFamily="TheSansC4s" fontWeight="300" transform="translate(60 29)">
                <tspan x="0" y="0">{t(LocalizationKeys.Saved)}</tspan>
            </text>
        </svg>
    );
};
