import React from 'react';
import { IDialogHeaderProps } from './types';
import { useDialogHeader } from './hooks';
import { ToolItem } from '../../../workspace/components/overlay/ToolItem';

import './DialogHeader.scss'

export const DialogHeader: React.FC<IDialogHeaderProps> = (props) => {
    const { icon, title, collapsible, collapseIcon, collapseTooltip, onCollapse, closable, closeTooltip, onClose } = useDialogHeader(props);
    return (
        <div className="dialog-header">
            <div className="dialog-header-title-container">
                { 
                    icon ?
                    <div className="dialog-header-icon">
                        {icon}
                    </div> : null
                }
                <div className="dialog-header-title">
                    {title}
                </div>
            </div>
            <div className="dialog-header-button-container">
                { collapsible ? <ToolItem className="collapse-button" icon={collapseIcon} tooltip={collapseTooltip} labelBottom={true} onClick={onCollapse} /> : null }
                { closable ? <ToolItem icon={"close"} tooltip={closeTooltip} labelBottom={true} onClick={onClose} /> : null }
            </div>
        </div>
    );
}