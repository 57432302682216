import { useEffect, useReducer } from "react";
import { DialogReducer, setDialogState } from "../../../../../../../redux/dialog/reducer";
import { initialDialogState } from "../../../../../../../redux/dialog/types";
import { IEditPolarityDialogProps } from "./types";

export const useEditPolarityNameDialog = ({ props: dialogProps, type }: IEditPolarityDialogProps) => {
    const [state, dispatch] = useReducer(DialogReducer, initialDialogState);

    useEffect(() => {
        dispatch(setDialogState({ props: dialogProps, type }));
    }, [dialogProps, type, dispatch])

    return {
        context: { state, dispatch }
     }
}