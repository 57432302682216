import { ConfigurationType, getConnectorType } from "../../components/overlay/components/wizard/redux/types";
import { IBuildData, TrunkData } from "../../redux/build/types";
import { FeederGrip, NoneGrip } from "../../redux/ssc/types";
import { IFiberMapData } from "../../redux/build/connector/polarity/fiber-map/types";
import { convertTo } from '../../components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure';
import { getNbConnectors } from "../../redux/build/connector/types";
import { getConnectorDefaultColor } from "../../../pixi/factories/Texture";
import { FiberColors } from "../../components/overlay/components/polarity/fiber-mapping/connector/templates/types";
import { IWarning } from '../../components/overlay/components/header/components/warnings/redux/types';
import { BuildDTO } from '../types';
import { getDefaultBuildDescription } from "../../components/overlay/components/projects/components/drawer/components/row/build-info/hooks";
import { BuildPolarity, PolarityMap } from "../../redux/build/connector/polarity/types";
import { IConnectorAssignmentMap, IConnectorMap } from "../../components/overlay/components/polarity/connector-assignment/redux/types";
import { IDestinationData } from "../../redux/build/destination/types";
import { ISourceData } from "../../redux/build/source/types";
import { IColor } from "../../../ui/dialog/color/types";

export interface IUpdateConfigurationRequest {
    sessionId: string;
    configurationType: string;
}
export interface IUpdateBuildInfoRequest {
    sessionId: string;
    description: string;
}
export interface IUpdateEDSRequest {
    reload: boolean,
    sessionId?: string,
    buildId?: number,
    flameRating?: string,
    description: string,
    configurationType: string,
    grip: string,
    fiberCount: number,
    feederEnd?: Partial<IUpdateFeederEndRequest>,
    distribution?: Partial<IUpdateDistributionRequest>
}
export interface IUpdateFeederEndRequest {
    drop: Partial<IUpdateDropMaterialRequest>
}

export interface IUpdateDistributionRequest {
    accessPointCount: number
    accessPoints: Partial<IUpdateAccessPointRequest>[]
    uiConnectorType: IUpdateConnectorTypeRequest
}

export interface IApplyPolarityRequest {
    sessionId: string,
    assignments: IApplyPolarityAssignment[];
}

export interface IApplyPolarityAssignment {
    polarityId?: string,
    polarityDescription: string,
    customMap?: IFiberMapData,
    feederConnectors: IPolarityConnectorRequest[],
    distributionConnectors: IPolarityConnectorRequest[],
}

export interface IPolarityConnectorRequest {
    tapPosition: number,
    fiberCount: number,
    groupPosition: number,
    connectorPosition: number,
    setOrderIndex: number,
}

export interface IUpdateAccessPointRequest {
    fiberCountLocal: number,
    distanceFromPrior: number,
    sequenceNumber: number,
    drop: Partial<IUpdateDropMaterialRequest>,
}

export interface IUpdateDropMaterialRequest {
    uiConnectorType: IUpdateConnectorTypeRequest,
    fiberCountLocal: number,
    furcationGroupCount: number,
    furcationGroupConnectorCount: number,
    sequenceNumberAssembly: number,
    furcationGroups: Partial<IUpdateFurcationGroupMaterialRequest>[],
}

export interface IUpdateConnectorTypeRequest {
    fiberCount: number,
    pinned?: boolean,
    id?: string
}

export interface IUpdateFurcationGroupMaterialRequest {
    connectorCount: number,
    furcationLegLength: number,
    sequenceNumber: number,
    furcationGroupLegs: Partial<IUpdateFurcationGroupLegMaterialRequest>[],
    staggerLength: number
}

export interface IUpdateFurcationGroupLegMaterialRequest {
    sequenceNumber: number,
    connector: Partial<IUpdateConnectorMaterialRequest>,
}

export interface IUpdateConnectorMaterialRequest {
    color: string,
    labelData: string,
}

export interface IApplyConnectorLabelSchemesRequest {
    build: BuildDTO,
    sessionId?: string,
    feederLabelScheme?: string,
    distributionLabelScheme?: string,
    polarityConfigurations: BuildPolarity[]
}

export interface IApplyConnectorLabelSchemesResponse {
    build: BuildDTO,
    sessionId: string,
    warnings: IWarning[],
    configStatus: string,
    polarityConfigurations: BuildPolarity[],
    connectorColors: Record<string, Record<string, IColor[]>>;
    defaultConnectorColors: Record<string, Record<string, IColor>>;
}

export function buildDataToEDSSessionRequest(sessionId: string | undefined, build: IBuildData, reload: boolean = true): IUpdateEDSRequest {
    const distribution = buildDataToDistributionRequest(build);
    const feederEnd = buildDataToFeederEndRequest(build);
    return {
        sessionId,
        reload,
        description: build.description?.length ? build.description : getDefaultBuildDescription(build),
        buildId: build.id,
        fiberCount: build.source.fiberCount!,
        flameRating: build.flameRating,
        configurationType: build.configurationType ?? ConfigurationType.Patching,
        grip: build.source.gripOnEnd ? FeederGrip : NoneGrip,
        distribution,
        feederEnd,
    };
}

function buildDataToDistributionRequest(build: IBuildData): Partial<IUpdateDistributionRequest> {
    const accessPoints: Partial<IUpdateAccessPointRequest>[] = [...build.destinations].filter(d => d.groups.length && d.groups.every(g => g.connectors.length)).map((d, i) => {
        const { type } = d.groups[0].connectors[0];
        const connectorType = type 
            ? getConnectorType(type)
            : { fiberCount: 0, pinned: false };
        const distanceFromPrior = convertTo(d.position === 1? build.source.lengthA : build.destinations[d.position - 2].lengthA).value;
        return {
            distanceFromPrior, 
            sequenceNumber: d.position,
            drop: {
                uiConnectorType: { ...connectorType, id: type },
                fiberCountLocal: getNbConnectors(d.groups) * connectorType.fiberCount,
                furcationGroupConnectorCount: d.groups[0].connectors.length,
                furcationGroupCount: d.groups.length,
                sequenceNumberAssembly: d.position + 1,
                furcationGroups: d.groups.map(g => ({
                    fiberCountLocal: g.connectors.length * connectorType.fiberCount,
                    connectorCount: g.connectors.length,
                    furcationLegLength: d.customBLength ? convertTo(g.lengthB).value : convertTo(d.lengthB).value + (convertTo(g.stagger).value * ((g.position ?? 0))),
                    sequenceNumber: (g.position ?? 0) + 1,
                    furcationGroupLegs: g.connectors.map((c) => ({
                        sequenceNumber: (c.position ?? 0) + 1,
                        connector: {
                            color: getColorId(c.color) ?? getConnectorDefaultColor(c.type ?? "").id,
                            labelData: c.label,
                        }
                    })),
                    staggerLength: d.customBLength ? 0 : convertTo(g.stagger).value
                })),
            }
        }
    });
    const { fiberCount, pinned, type } = getConnectorType(build.destinations[0].groups[0].connectors[0].type!);

    return {
        accessPointCount: build.destinations.length,
        uiConnectorType: { fiberCount, pinned, id: type },
        accessPoints
    };
}

function getColorId(color?: string) {
    if (!color) return undefined;

    const index = FiberColors.findIndex(c => c.name === color);
    return index >= 0 ? FiberColors[index].id : undefined;
}

function buildDataToFeederEndRequest(build: IBuildData): Partial<IUpdateFeederEndRequest> {
    const { fiberCount, pinned, type } = getConnectorType(build.source.groups[0].connectors[0].type!);
    return {
        drop: {
            fiberCountLocal: getNbConnectors(build.source.groups) * fiberCount,
            furcationGroupConnectorCount: getNbConnectors(build.source.groups) / build.source.groups.length,
            furcationGroupCount: build.source.groups.length,
            uiConnectorType: { fiberCount, pinned, id: type },
            furcationGroups: build.source.groups.map((g) => ({
                fiberCountLocal: g.connectors.length * fiberCount,
                connectorCount: g.connectors.length,
                furcationLegLength: build.source.customBLength ? convertTo(g.lengthB).value : convertTo(build.source.lengthB).value + (convertTo(g.stagger).value * ((g.position ?? 0))),
                sequenceNumber: (g.position ?? 0) + 1,
                furcationGroupLegs: g.connectors.map((c) => ({
                    sequenceNumber: (c.position ?? 0) + 1,
                    connector: {
                        color: getColorId(c.color) ?? getConnectorDefaultColor(c.type ?? "").id,
                        labelData: c.label,
                    }
                })),
                staggerLength: build.source.customBLength ? 0 : convertTo(g.stagger).value
            })),
        }
    };
}

export function toApplyPolarityRequest(connectorMaps: IConnectorAssignmentMap[], destinations: IDestinationData[], source: ISourceData, polarityMap: PolarityMap, sessionId: string): IApplyPolarityRequest {
    return {
        assignments: connectorMaps.map(connectorMap => toApplyPolarityAssignment(connectorMap, destinations, polarityMap, source)),
        sessionId
    };
}

function toApplyPolarityAssignment(connectorMap: IConnectorAssignmentMap, destinations: IDestinationData[], polarityMap: PolarityMap, source: ISourceData): IApplyPolarityAssignment {
    const polarityId = polarityMap.customKey ?? "";
    const polarityDescription = polarityMap.description ?? "";

    return {
        polarityId,
        polarityDescription,
        distributionConnectors: connectorMap.destinationMapping.map(d => {
            const tap = destinations.find(destination => destination.position === d.position);
            return connectorMapToApplyPolarityRequest(tap, d);
        }),

        feederConnectors: connectorMap.sourceMapping.map(s => {
            const tap = source;
            return connectorMapToApplyPolarityRequest(tap, s);
        }),
        customMap: connectorMap.fiberMap,
    }
}

function connectorMapToApplyPolarityRequest(trunk: TrunkData | undefined, connectorMap: IConnectorMap) {
    const groupLength = trunk!.groups![0].connectors.length;
    const connectorIndex = connectorMap.index - 1;
    const groupIndex = Math.floor(connectorIndex / groupLength);
    const connectorPositionIndex = connectorIndex % groupLength;
    return {
        connectorPosition: connectorPositionIndex + 1,
        tapPosition: trunk!.position ?? 0,
        fiberCount: connectorMap.fiberCount,
        groupPosition: groupIndex + 1,
        setOrderIndex: connectorMap.orderIndex
    };
}
