import { useState, useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import { ViewportStatus } from "../../../../../../../../../pixi/components/viewport/redux/types";
import { setCurrentBuildAsymmetric } from "../../../../../../../../redux/build/reducers";
import { workspaceSelector } from "../../../../../../../../selectors/root.selectors";

export const useDropCheckbox = () => {
    const { viewport, builds, wizard } = useSelector(workspaceSelector);
    const { isAsymmetric: isChecked } = builds.currentBuild!;
    const { context } = viewport;
    const { dropCheckboxDisabled: isDisabled } = wizard;
    const [isVisible, setIsVisible] = useState(true);
    const [labelClassName, setLabelClassName] = useState("ap-checkbox-label");
    const dispatch = useDispatch();

    const onCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        let checked = e.currentTarget.checked;
        dispatch(setCurrentBuildAsymmetric(checked));
    }, [dispatch]);

    useEffect(() => {
        if (context === ViewportStatus.Editing) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    }, [context]);

    useEffect(() => {
        if (isDisabled) {
            setLabelClassName("ap-checkbox-label-disabled");
        } else {
            setLabelClassName(isChecked ? "ap-checkbox-label-checked" : "ap-checkbox-label");
        }
    }, [isDisabled, isChecked]);

    const onLabelClick = useCallback(() => {
        if (!isDisabled) {
            dispatch(setCurrentBuildAsymmetric(!isChecked));
        }
    }, [isDisabled, dispatch, isChecked])

    return { isVisible, isDisabled, isChecked, onCheckboxChange, labelClassName, onLabelClick };
}