import { createSlice } from "@reduxjs/toolkit";
import { setLoadingMoreAction, setReachedLastPageAction, setReloadingAction, setSearchAction } from "./actions";
import { initialProjectDrawerState } from "./types";

const projectDrawerSlice = createSlice({
    name: "drawer",
    initialState: initialProjectDrawerState,
    reducers: {
        setSearch: setSearchAction,
        setReachedLastPage: setReachedLastPageAction,
        setLoadingMore: setLoadingMoreAction,
        setReloading: setReloadingAction,
    }
});

export const ProjectDrawerReducer = projectDrawerSlice.reducer;
export const { setSearch, setReachedLastPage, setLoadingMore, setReloading } = projectDrawerSlice.actions;