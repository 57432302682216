import { createSlice} from "@reduxjs/toolkit"
import { initialBuildDocState } from "./types";
import { setDocumentAction } from "./action";

const reportDocumentSlice = createSlice({
    name: 'report',
    initialState: initialBuildDocState,
    reducers: {
        setDocument: setDocumentAction
    }
})

export const ReportDocumentReducer = reportDocumentSlice.reducer;
export const { setDocument } = reportDocumentSlice.actions;

