import { useInteractions } from "./hooks";
import { InteractiveRectangle } from "../rectangle/InteractiveRectangle";
import { IRectangleDrawingProps } from "../rectangle/types";

export const SelectionBox = () => {
    const { display, interactive, bounds, layout, onMouseClick } = useInteractions();
    const rectangleProps: IRectangleDrawingProps = { interactive, bounds, layout, onMouseClick };
    if (!display) {
        return null;
    }

    return <InteractiveRectangle {...rectangleProps} />;
}