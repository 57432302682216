import { Identifiable } from "../../../../../services/types"
import { IUnitOfMeasure } from "../../../../../components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure"

interface IFeederPreset {
    fiberCount: number,
    connectorType: string,
    groups: number,
    connectorsPerGroup: number,
    aLength: IUnitOfMeasure,
    bLength: IUnitOfMeasure,
    staggerLength: IUnitOfMeasure
    gripOnEnd: boolean,
    customTag: string,
    flameRating: string
}

interface IDropPreset {
    connectorType: string,
    accessPointCount: number,
    groups: number,
    connectorsPerGroup: number,
    aLength: IUnitOfMeasure,
    bLength: IUnitOfMeasure,
    staggerLength: IUnitOfMeasure,
    calculateAccessPoints: boolean
}

interface IWizardPreset {
    configurationType: string,
    feederPreset: FeederPreset,
    dropPreset: DropPreset
}

interface IUserWizardPreset {
    selectedPreset?: WizardPreset,
    wizardPresets?: WizardPreset[];
}

export type FeederPreset = Partial<IFeederPreset & Identifiable>
export type DropPreset = Partial<IDropPreset & Identifiable>
export type UserWizardPreset = Partial<IUserWizardPreset & Identifiable>
export type WizardPreset = Partial<IWizardPreset & Identifiable>

export const initialUserWizardPreset: UserWizardPreset = {
}