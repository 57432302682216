import React, { useReducer } from 'react';
import { ModalDrawer } from './components/modal-drawer/ModalDrawer';
import { DrawerContext } from './components/drawer-context/DrawerContext';
import { DrawerReducer } from './components/drawer-context/reducer';
import { initialDrawerContextProps } from './components/drawer-context/types';
import { DrawerProps } from './components/types';

export const Drawer: React.FC<DrawerProps> = (props) => {
    const [state, dispatch] = useReducer(DrawerReducer, initialDrawerContextProps);
    return (
        <DrawerContext.Provider value={[state, dispatch]}>
            <ModalDrawer className={props.className} open={props.open} anchor={props.anchor} onClose={props.onClose} logo={props.logo} style={props.style}>
                {props.children}
            </ModalDrawer>
        </DrawerContext.Provider>
    )
}