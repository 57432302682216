import React from 'react';
import { usePolarityToolbar } from './hooks';
import { ToolItem } from '../../../ToolItem';
import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material';

import './PolarityToolbar.scss';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    gap: "6px",
    '& .MuiToggleButtonGroup-grouped': {
        borderColor: "transparent",
        borderRadius: "6px",
        '&.Mui-disabled': {
            borderColor: "transparent",
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

export const PolarityToolbar: React.FC = () => {
    const { display, progressProps, connectorAssignmentProps, fiberMappingProps, closeProps, buttonGroupValue } = usePolarityToolbar();
    if (!display) {
        return null;
    }
    return (
        <div className="polarity-toolbar toggle-pointer-events">
            <div className="progress-container">
                <div className="assigned-fibers">
                    <div className="title">
                        {progressProps.title}
                    </div>
                    <div className="percentage">
                        {progressProps.percentageText}
                    </div>
                </div>
                <div className="progress-bar">
                    <span style={{ width: `${progressProps.percentage}%` }} />
                </div>
            </div>
            <div className="vertical-divider" />
            <StyledToggleButtonGroup exclusive value={buttonGroupValue}>
                <ToggleButton value={connectorAssignmentProps.text} className={connectorAssignmentProps.className} onClick={connectorAssignmentProps.onClick}>
                    {connectorAssignmentProps.text}
                </ToggleButton>
                <ToggleButton value={fiberMappingProps.text} className={fiberMappingProps.className} onClick={fiberMappingProps.onClick} disabled={fiberMappingProps.disabled}>
                    {fiberMappingProps.text}
                </ToggleButton>
            </StyledToggleButtonGroup>
            <div className="vertical-divider" />
            <ToolItem {...closeProps} />
        </div>
    )
}