import { IColor, Blue, Orange, Green, Brown, Slate, White, Red, Black, Yellow, Violet, Rose, Aqua, Mustard, Lime, Beige, Magenta, Olive } from "../../../../../../../../ui/dialog/color/types";
import { getConnectorDefaultColor } from "../../../../../../../../pixi/factories/Texture";
import { IColorResponse } from "../../../../../../../redux/ssc/types";

export interface IConnectorCasingProps {
    width: number;
    height: number;
    fill: string;
    filter: string;
    opacity?: number;
}

export interface IConnectorTemplateProps {
    disabled?: boolean;
}

export type ConnectorTemplateProps = IConnectorTemplateProps & {
    type: string;
}

export interface IConnectorPinProps {
    pinIndex: number;
    connectorType: string;
    disabled?: boolean;
}

export interface IConnectorPinDrawingProps {
    fill: string;
    fillOpacity: number;
    stroke: string;
    strokeDashArray: string;
    text: string;
    textColor: string;
}

export const initialConnectorPinDrawingProps: IConnectorPinDrawingProps = {
    fill: "#000000",
    fillOpacity: 0,
    stroke: "#000000",
    text: "",
    textColor: "#000000",
    strokeDashArray: ""
}

export const FiberColors: IColor[] = [Blue, Orange, Green, Brown, Slate, White, Red, Black, Yellow, Violet, Rose, Aqua];
export const MMCFiberColors: IColor[] = [...FiberColors, Lime, Beige, Olive, Magenta]
export const MTPExclusiveColors: IColor[] = [Mustard, Lime, Beige];
export const MTPColors: IColor[] = [...FiberColors, ...MTPExclusiveColors];
export const TrunkColors: IColor[] = [Yellow, Aqua]
export const LCBootColors: IColor[] = [Blue, Aqua]
export const MTPBootColors: IColor[] = [Black]
export const AllColors = MTPColors;

export const getFillOpacity = (color: IColor) => {
    switch (color) {
        case White:
            return 0.65;
        default:
            return 0.2
    }
}

export const toColor = (res: IColorResponse, type?: string): IColor => {
    return MTPColors.find(c => c.id === res.id) ?? getConnectorDefaultColor(type)
}