import { TFunction } from "i18next";
import { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"
import { LocalizationKeys } from "../../../../../../../../../../../../locales/types";
import { TrunkData } from "../../../../../../../../../../../redux/build/types";
import { unitsOfMeasureContainerUnitSelector } from "../../../../../../../../header/components/units-of-measure-container/redux/selectors";
import { getUnitsName, Unit, Units } from "../../../../../../../../header/components/units-of-measure-container/UnitsOfMeasure";
import { BLengthContext } from "../../../types";
import { IBLengthInputProps } from "../blength-input/type";

export const useCustomBLength = () => {
    const { t } = useTranslation();
    const { trunk, isCustom } = useContext(BLengthContext)
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const lengthBString = getLengthBString(trunk, unit, t)

    const bLengths: IBLengthInputProps[] = useMemo(() => {
        if (isCustom && trunk) {
            let bLengths = []
            const groups = trunk.groups ?? []
            const trunkPosition = trunk.position ?? 0
            bLengths = groups.map((g) => {
                const groupLengthB = g.lengthB ?? { unit: Units.UoMInches, value: 0 }
                const position = g.position ?? 0
                return {
                    key: `${position}`,
                    position: position,
                    length: groupLengthB,
                    trunkPosition: trunkPosition,
                }
            })
            return bLengths;
        }

        return []
    }, [trunk, isCustom])

    return { bLengths, display: isCustom, lengthBString };
}

function getLengthBString(trunk: TrunkData | undefined, unit: Unit, translate: TFunction) {
    const lengthB = trunk ?
        translate(LocalizationKeys.LengthBWithUnit, { position: trunk.position!, unit: getUnitsName(unit) }) :
        "";
    return lengthB;
}