
import { Button, Fade, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../../../../locales/types';
import { ToolItem } from '../../../../ToolItem';
import { usePageResize } from './hooks';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import IconLens from './resources/icons/icon_lens.svg';
import IconLensDisabled from './resources/icons/icon_lens_disabled.svg';

export const PageResizeTool = () => {
    const { buttonText, onButtonClick, anchorElement, onMenuClose, onResize } = usePageResize();
    const { t } = useTranslation();
    
    return (
        <div className="controls-container page-resize-container background-blur toggle-pointer-events">
            <ToolItem source={getLensIcon(false)} alt="Page resize" tooltip="Page resize" labelTop={true} onClick={onResize} />
            <div className="tool-vertical-divider"/>
            <Button 
                className="page-resize-button" 
                disableRipple={true} 
                endIcon={<UnfoldMoreIcon/>}
                onClick={onButtonClick}
            >
                {buttonText}
            </Button>
            <Menu
                className="page-resize-menu"
                anchorEl={anchorElement}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                keepMounted
                open={Boolean(anchorElement)}
                onClose={(e) => onMenuClose(e, -1)}
                TransitionComponent={Fade}
            >
                <MenuItem id={"Fit to page"} className="page-resize-menu-item" disableRipple={true} selected={buttonText === "Fit to page"} onClick={(e) => onMenuClose(e, 0)}>{t(LocalizationKeys.FitToPage)}</MenuItem>
                <MenuItem id={"Fit to height"} className="page-resize-menu-item" disableRipple={true} selected={buttonText === "Fit to height"} onClick={(e) => onMenuClose(e, 1)}>{t(LocalizationKeys.FitToHeight)}</MenuItem>
                <MenuItem id={"Fit to source"} className="page-resize-menu-item" disableRipple={true} selected={buttonText === "Fit to source"} onClick={(e) => onMenuClose(e, 2)}>{t(LocalizationKeys.FitToSource)}</MenuItem>
            </Menu>
        </div>
    );
}

function getLensIcon(disabled: boolean) : string {
    return disabled ? IconLensDisabled : IconLens;
}

export default PageResizeTool;
