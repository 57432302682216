import React from 'react';
import { ToolItem } from '../../../../../../ToolItem';
import { OutlinedButton } from '@orbit/button';
import { useDebugMenu } from './DebugMenu.hooks';

import './DebugMenu.scss';

export const DebugMenu = () => {
    const { show, open, toggleOpen, showSoldTo, addErrorsAndWarnings, generateCatalogCode } = useDebugMenu();
    return !show ? null : (
        <div className="debug-menu">
            <ToolItem icon={open ? "expand_less" : "expand_more"} onClick={toggleOpen} />
            {!open ? null :
                <div className="debug-menu-items">
                    <OutlinedButton onClick={showSoldTo}>Set Sold To</OutlinedButton>
                    <OutlinedButton onClick={addErrorsAndWarnings}>Add Errors and Warnings</OutlinedButton>
                    <OutlinedButton onClick={generateCatalogCode}>Generate Catalog Code</OutlinedButton>
                </div>
            }
        </div>
    );
};
