import React from "react"
import { useFiberMappingDialog } from "./hooks"
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../locales/types";
import { CollapsibleDialog } from "../../../../../../ui/dialog/collapsible/CollapsibleDialog"
import { ContainedButton, TextButton, OutlinedButton } from "@orbit/button"
import { FiberMappingContext } from "./redux/types";
import { FiberMappingConnector } from "./connector/FiberMappingConnector";
import { GenericDialog } from "../../../../../../ui/dialog/generic-dialog/GenericDialog";
import { FiberMappingSaveDialog } from "./save-dialog/FiberMappingSaveDialog";
import { Select, MenuItem as Option } from "@mui/material";
import { SectionContext, Feeder, Drop } from "../../../../../../pixi/components/build/types";

import './FiberMappingDialog.scss';

export const FiberMappingDialog : React.FC = () => {
    const { dialogProps, polarityType, editable, clearAllProps, fiberMappingContext, data, dropdownProps, cancelProps, saveProps, saveAsProps, fiberMappingSaveDialogProps, saveNewDialogProps } = useFiberMappingDialog();
    const { t } = useTranslation();
    if (!dialogProps.display) {
        return null;
    }
    return (
        <CollapsibleDialog {...dialogProps}>
            <div className="horizontal-divider" />
            <div className="subheader-container">
                <div className="polarity-type">
                    {polarityType}
                </div>
                <OutlinedButton className="clear-button" palette="error" disabled={clearAllProps.disabled} onClick={clearAllProps.onClick}>{t(LocalizationKeys.ClearAll)}</OutlinedButton>
            </div>
            <FiberMappingContext.Provider value={fiberMappingContext}>
                <SectionContext.Provider value={Feeder}>
                    <div className="mapping-container">
                        <Select className="selection-dropdown source" value={dropdownProps.source.value} onChange={dropdownProps.source.onChange}>
                            { data.source.connectors.map((c, i) => <Option key={i + 1} value={i}>{t(LocalizationKeys.FeederConnector, { connectorIndex: c.index + 1 })}</Option>) }
                        </Select>
                        <FiberMappingConnector type={data.source.connectorType} disabled={!editable} />
                    </div>
                </SectionContext.Provider>
                <SectionContext.Provider value={Drop}>
                    <div className="mapping-container">
                        <div className="selection-container">
                            <Select className="selection-dropdown tap" value={dropdownProps.destination.taps.value} onChange={dropdownProps.destination.taps.onChange}>
                                { data.destination.taps.map((tap, i) => <Option key={i + 1} value={i}>{t(LocalizationKeys.TapNumber, { tapNumber: tap.position })}</Option>) }
                            </Select>
                            <Select className="selection-dropdown tap" value={dropdownProps.destination.connectors.value} onChange={dropdownProps.destination.connectors.onChange}>
                                { data.destination.connectors.map((c, i) => <Option key={i + 1} value={i}>{t(LocalizationKeys.ConnectorNumber, { connectorIndex: c.index + 1 })}</Option>) }
                            </Select>
                        </div>
                        <FiberMappingConnector type={data.destination.connectorType} disabled={!editable} />
                    </div>
                </SectionContext.Provider>
            </FiberMappingContext.Provider>
            <div className="fiber-mapping-footer">
                <div className="fiber-mapping-actions">
                    <TextButton className="cancel-button" palette="error" disabled={cancelProps.disabled} onClick={cancelProps.onClick}>{cancelProps.text}</TextButton>
                    <ContainedButton className="save-button" disabled={saveProps.disabled} onClick={saveProps.onClick}>{saveProps.text}</ContainedButton>
                    <ContainedButton className="save-as-button" disabled={saveAsProps.disabled} onClick={saveAsProps.onClick}>{saveAsProps.text}</ContainedButton>
                </div>
            </div>
            <FiberMappingSaveDialog {...fiberMappingSaveDialogProps}/>
            <GenericDialog {...clearAllProps.dialogProps} />
            <GenericDialog {...saveNewDialogProps}/>
        </CollapsibleDialog>
    )
}