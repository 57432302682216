import { ToggleButton } from "@mui/material";
import { DataTable, DataTableContent, DataTableBody } from "@rmwc/data-table";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../../locales/types";
import { GenericDialog } from "../../../../../../../ui/dialog/generic-dialog/GenericDialog";
import { CUSTOM_MAP_KEY, SESSION_MAP_KEY } from "../../../../../../redux/build/connector/polarity/types";
import { EditPolarityNameDialog } from "./edit-polarity/edit-polarity-name-dialog";
import { useAssignPolarity } from "./hooks";
import { PolarityDiagram } from "./polarity-graphic/PolarityDiagram";
import { PolarityAssignmentRow } from "./row/PolarityAssignmentRow";

import "./AssignPolarity.scss";

export const AssignPolarity = () => {
    const { t } = useTranslation();
    const { polarityAssignmentRowProps, editPolarityDialogProps, deletePolarityDialogProps, selectedMap, selectedConfig, polarityButtons } = useAssignPolarity();

    return (
        <div className="assign-polarity-container">
            <div className="polarity-button-container">
                {polarityButtons.map((p, i) => {
                    return (
                        <div key={i}>
                            <ToggleButton
                                className={p.className}
                                selected={p.selected}
                                value={p.description ?? ""}
                                size="small"
                                key={i}
                                onChange={p.onClick}>
                                <i className="material-icons">{p.applied ? "check_circle" : "radio_button_unchecked"}</i>
                                {p.description}
                            </ToggleButton>
                        </div>)
                })}
            </div>
            <div className="assign-polarity-content">
                {polarityAssignmentRowProps.length > 0 ?
                    <DataTable className="polarity-map-container">
                        <DataTableContent>
                            <DataTableBody>
                                {polarityAssignmentRowProps.map((m) => <PolarityAssignmentRow key={m.data!.key!.toString() === CUSTOM_MAP_KEY.toString() || m.data!.key!.toString() === SESSION_MAP_KEY.toString() ? m.data!.customKey : m.data!.key} {...m} />)}
                            </DataTableBody>
                        </DataTableContent>
                    </DataTable> : null}
                <div className="assign-polarity-graphic-container">
                    {polarityAssignmentRowProps.length > 0 ?
                        (selectedMap.key !== -1) ?
                            <PolarityDiagram polarityMap={selectedMap} polarityConfig={selectedConfig} /> :
                            <div className="polarity-instructions">
                                <div className="polarity-instructions-title">{t(LocalizationKeys.PolarityInstructionsTitle)}</div>
                                <br />
                                <div className="polarity-instructions-description">{t(LocalizationKeys.PolarityInstructionsDescription)}</div>
                                <br />
                                <div className="polarity-important">
                                    <i>{`${t(LocalizationKeys.Important)}: ${t(LocalizationKeys.PolarityImportantDescription)}`}</i>
                                </div>
                            </div> :
                        <div className="polarity-unavailable">
                            <i className="material-icons">error</i>
                            <div className="polarity-unavailable-text">{t(LocalizationKeys.PolarityUnavailable)}</div>
                        </div> }
                </div>
            </div>
            <GenericDialog {...deletePolarityDialogProps} />
            <EditPolarityNameDialog {...editPolarityDialogProps} />
        </div>
    )
}