import { Provider } from 'react-redux';
import { usePixiComponent } from './hooks';
import { StageContainer } from './types';
import { Stage } from '@inlet/react-pixi';
import { Background } from './components/background/Background';
import { Viewport } from './components/viewport/Viewport';
import { MarkerStyleContext, defaultStyle } from './components/decorators/markers/types';
import { DocumentGraphics } from './components/document-graphics/DocumentGraphics';
import { ResourceManager } from './manager/ResourceManager';
import { BuildContext } from '../workspace/redux/build/types';
import { Cable } from './components/build/components/cable/Cable';

import './PixiComponent.scss';

export const PixiComponent = () => {
    const { loaded, cableProps, build, unit, windowSize, store } = usePixiComponent();

    return (
        <div className={StageContainer} id="stage">
            <Stage options={{ backgroundColor: 0xffffff, antialias: true, sharedLoader: true }} {...windowSize}>
                <Provider store={store}>
                    <Background />
                    <ResourceManager />
                    { loaded && <Viewport>
                        <MarkerStyleContext.Provider value={defaultStyle}>
                            <BuildContext.Provider value={{ ...build.current!, unit }}>
                                <Cable {...cableProps} />
                            </BuildContext.Provider>
                        </MarkerStyleContext.Provider>
                    </Viewport>}
                    <DocumentGraphics />
                </Provider>
            </Stage>
        </div>
    )
}

export default PixiComponent;

