import React, { useCallback } from 'react';
import { WorkspaceStatus } from './redux/types';
import { useStatusIcon } from './hooks';
import { Loading } from './resources/Loading';
import { Saved } from './resources/Saved';
import { Saving } from './resources/Saving';
import { Locked } from './resources/Locked';

import './StatusIcon.scss';

export const StatusIcon = () => {
    const { currentStatus, className } = useStatusIcon();

    const getIcon = useCallback(() => {
        switch (currentStatus) {
            case WorkspaceStatus.Loading:
            case WorkspaceStatus.InitialLoad:
            case WorkspaceStatus.Busy:
                return <Loading />;
            case WorkspaceStatus.Ready:
            case WorkspaceStatus.Reporting:
                break;
            case WorkspaceStatus.Saved:
                return <Saved />;
            case WorkspaceStatus.Saving:
                return <Saving />;
            case WorkspaceStatus.Locked:
                return <Locked />;
        }
    }, [currentStatus]);
    
    return (
        <div className={className}>
            {currentStatus !== WorkspaceStatus.Ready && currentStatus !== WorkspaceStatus.Reporting ? getIcon() : null}
        </div>)
}