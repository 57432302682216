import React from 'react';
import { Radio, RadioGroup } from '@orbit/radio'
import { Checkbox } from '@orbit/checkbox';
import { ICheckboxInput } from '../../../../../../../../../ui/checkbox/types';
import { IRadioLabel, IRadioGroup } from '../../../../../../../../../ui/radio/types';
import { InputField } from '../../../../../../../../../ui/input/types';
import { FilledTextField } from '@orbit/text-field';

export const DropSubSection = (props: { checkBox: ICheckboxInput, collapsedRadio: IRadioLabel, expandedRadio: IRadioLabel, radioGroup: IRadioGroup, printGroup: IRadioGroup, printAllRadio: IRadioLabel,  printDropRadio: IRadioLabel, printDropField: InputField, disabled?: boolean} ) => {
    const { checkBox, collapsedRadio, expandedRadio, radioGroup, 
        printGroup, printAllRadio, printDropRadio, printDropField, disabled} = props;

    return <div className={"report-section"}>
    <div className={checkBox.className}>
        <Checkbox
            palette='primary'
            onChange={checkBox.onCheckBoxChange}
            checked={checkBox.checked}
            disabled={disabled}
            label={checkBox.label}
            />
    </div>
    <div className="horizontal-field-container">
        <div className="radio-group">
            <RadioGroup value={radioGroup.value} onChange={radioGroup.onRadioGroupChange}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <Radio label={collapsedRadio.label} value={collapsedRadio.label} checked={collapsedRadio.label === radioGroup.value} disabled={checkBox.disabledChildren}/>
                    <Radio label={expandedRadio.label} value={expandedRadio.label} checked={expandedRadio.label === radioGroup.value} disabled={checkBox.disabledChildren}/>
                </div>
            </RadioGroup>
        </div>
    </div>
    <div className="horizontal-field-container print-container">
        <div className="radio-group">
                <RadioGroup value={printGroup.value} onChange={printGroup.onRadioGroupChange}>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Radio label={printAllRadio.label} value={printAllRadio.label} checked={printAllRadio.label === printGroup.value} disabled={checkBox.disabledChildren}/>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <Radio label={""} style={{marginRight: "0px", marginLeft: "6px"}} value={printDropRadio.label} checked={printDropRadio.label === printGroup.value} disabled={checkBox.disabledChildren}/>
                            <FilledTextField palette='primary'
                                style={{maxWidth: "120px"}}
                                label={printDropField.label}
                                helperText={""}
                                error={!printDropField.isValid}
                                disabled={checkBox.disabledChildren || printDropField.disabled}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => printDropField.onChange && printDropField.onChange(e)}/>
                        </div>
                    </div>
                </RadioGroup>
        </div>
    </div>
</div>
}