import { useState, useCallback, useEffect } from "react";
import { ReportSettings, initialReportSettings } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { BuildDocument, DocumentDrop, DocumentFiberMap } from "../../../../../../redux/document/types";
import { setDocument } from "../../../../../../redux/document/reducers";
import { useCanvasExtract } from "../../../../../../../pixi/components/canvas-extractor/hooks";
import { createPDF } from "../../../../../../services/pdf/pdf-exporter";
import { ToBuildDTO, ToBuildData, ToSourceData, ToSourceDTO, ToDestinationData, ToDestinationDTO } from "../../../../../../services/build-service";
import { getUnitsName, convertToDisplay, Unit } from "../../../header/components/units-of-measure-container/UnitsOfMeasure";
import { getConnectorType } from "../../../wizard/redux/types";
import { getNbConnectors, IConnectorData } from "../../../../../../redux/build/connector/types";
import { IBuildData, TrunkData } from "../../../../../../redux/build/types";
import { IDestinationData } from "../../../../../../redux/build/destination/types";
import { useFooterFields } from "./components/footer-fields/hooks";
import { useDrawingSection, radioOptions } from "./components/drawing-section/hooks";
import { useDropSection, printDropRadioOptions } from "./components/drop-settings/hooks";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../../locales/types";
import { polarityDescriptionSelector } from "../../../../../../selectors/build.selectors";
import { useDiagramSectionFields } from "./components/diagrams-section/hooks";
import { showPrintDialogSelector } from "../../../../redux/selectors";
import { getLegacyFlameRating } from "../../../wizard/components/setup/components/flame-rating/types";
import { sscFiberCountSelector, sscFlameRatingSelector } from "../../../../../../redux/ssc/selectors";
import { documentFiberMapsSelector } from "../../../../../../redux/build/connector/polarity/selectors";
import { unitsOfMeasureContainerUnitSelector } from "../../../header/components/units-of-measure-container/redux/selectors";
import { setShowPrintDialog } from '../../../../redux/reducers';
import { isAnonymousUserSelector } from "../../../../../authentication/redux/selectors";
import { currentBuildSelector } from "../../../../../../selectors/root.selectors";
import { AppDispatch } from "../../../../../../redux/reducers";

export const useReportSettings = () => {
    const { t } = useTranslation();

    const currentBuild = useSelector(currentBuildSelector);
    const showPrintDialog = useSelector(showPrintDialogSelector);

    const { footerFields, fieldValues } = useFooterFields();
    const [dateField, revisionField, siteLocationField, drawnField, checkedField, approvedField] = footerFields;
    const { siteLocation, drawn, notes, date, revision } = fieldValues;

    const [overviewSettings, setOverviewSettings] = useState(initialReportSettings.overview)
    const [feederSettings, setFeederSettings] = useState(initialReportSettings.feeder)
    const [dropSettings, setDropSettings] = useState(initialReportSettings.drops)

    const { diagramSectionFields, fieldValues: diagramFieldValues, validDiagramsSection } = useDiagramSectionFields();
    const [connectorLabelField, polarityDiagramField, fiberMapField, buildPlanField] = diagramSectionFields;
    const { connectorLabel, polarityDiagram, buildPlan, fiberMap } = diagramFieldValues;

    const overviewSection = useDrawingSection('overview', t(LocalizationKeys.CableOverview), overviewSettings!, false)
    const feederSection = useDrawingSection('feeder', t(LocalizationKeys.TrunkSide, { trunk: t(LocalizationKeys.Feeder) }), feederSettings!, false)
    const dropSection = useDropSection(dropSettings!);

    const [disablePrint, setDisablePrint] = useState(false);

    const { closeWindow } = useCloseButton();

    const { print } = useBuildDocument();

    // section settings
    useEffect(() => {
        setOverviewSettings({
            include: overviewSection.checkBox.checked,
            collapsed: overviewSection.radioGroup.value === t(radioOptions[0])
        })
    }, [overviewSection.checkBox.checked, overviewSection.radioGroup.value, t])

    useEffect(() => {
        setFeederSettings({
            include: feederSection.checkBox.checked,
            collapsed: feederSection.radioGroup.value === t(radioOptions[0])
        })
    }, [feederSection.checkBox.checked, feederSection.radioGroup.value, t])

    useEffect(() => {
        if (showPrintDialog) {
            setDropSettings({
                include: dropSection.checkBox.checked,
                collapsed: dropSection.radioGroup.value === t(radioOptions[0]),
                includeAll: dropSection.printGroup.value === t(printDropRadioOptions[0])
            })
        }
    }, [dropSection.checkBox.checked, dropSection.radioGroup.value, dropSection.printGroup.value, dropSection.printDropField.value, currentBuild, showPrintDialog, t])

    useEffect(() => {
        const dropFieldValue = dropSection.printDropField.value as string
        const validDropSection = dropSection.printGroup.value === t(printDropRadioOptions[0])
            || (dropSection.printGroup.value === t(printDropRadioOptions[1]) && dropSection.printDropField.isValid && dropFieldValue && dropFieldValue.length > 0)

        setDisablePrint(!(((overviewSection.checkBox.checked || feederSection.checkBox.checked || dropSection.checkBox.checked || validDiagramsSection) && validDropSection)));
    }, [overviewSection.checkBox.checked, feederSection.checkBox.checked, dropSection.checkBox.checked, dropSection.printGroup.value, dropSection.printDropField.isValid, dropSection.printDropField.value, validDiagramsSection, t])

    const printSelection = useCallback(async () => {
        const docSettings: ReportSettings = {
            date,
            revision,
            siteLocation,
            drawn,
            notes,
            overview: overviewSettings,
            feeder: feederSettings,
            drops: { ...dropSettings!, dropPositions: dropSection.printDropField.selectedDrops },
            connectorLabel: connectorLabel,
            polarity: polarityDiagram,
            fiberMap: fiberMap,
            buildPlan: buildPlan,
        };
        await print(docSettings);
    }, [print, overviewSettings, feederSettings,
        dropSettings, dropSection.printDropField.selectedDrops,
        date, revision, siteLocation, drawn, notes, polarityDiagram, 
        fiberMap, buildPlan, connectorLabel])

    return {
        footerFields: [dateField, revisionField, siteLocationField, drawnField, checkedField, approvedField],
        drawingFields: [overviewSection, feederSection],
        dropSection, closeWindow, showPrintDialog, printSelection, disablePrint,
        diagramFields: [connectorLabelField, polarityDiagramField, fiberMapField, buildPlanField],
    }
}

const useBuildDocument = () => {
    const { t } = useTranslation();
    const isAnonymous = useSelector(isAnonymousUserSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const polarityDescription = useSelector(polarityDescriptionSelector)
    const documentFiberMaps = useSelector(documentFiberMapsSelector);
    const { flameRatingOptions } = useSelector(sscFlameRatingSelector)
    const { fiberCountOptions } = useSelector(sscFiberCountSelector);
    const dispatch = useDispatch();

    const { extractCanvas } = useCanvasExtract()

    const print = useCallback(async (settings: ReportSettings) => {

        const { overview, feeder, date, drawn, notes, revision, siteLocation, drops, polarity, connectorLabel, buildPlan, fiberMap } = settings;
        const units = getUnitsName(unit, false, true);
        const fileName = !isAnonymous ? currentBuild?.name?.length ? currentBuild?.name?.replace(/[^a-z0-9]/gi, "") : String(currentBuild!.id) : "";
        const catalogCode = currentBuild?.catalogCode;
        const draft = !currentBuild?.catalogCode?.length;
        const doc: BuildDocument = {
            date, drawn, revision, siteLocation, notes, units, fileName, draft, catalogCode
        }

        // apply report settings to document
        if (overview && overview.include) {
            const overviewBuild = ToBuildData(ToBuildDTO(currentBuild!))!; // create deep copy
            overviewBuild.availability = currentBuild!.availability;
            overviewBuild.source.isCollapsed = overviewBuild.source.customBLength ? false : overview.collapsed;
            const flameRating = getLegacyFlameRating(overviewBuild.flameRating!, flameRatingOptions)!;

            overviewBuild.destinations = overviewBuild.destinations!.map(d => {
                return { ...d, isCollapsed: d.customBLength ? false : overview.collapsed }
            })

            const fiberCountString = `${t(LocalizationKeys.FiberCount)}${t(LocalizationKeys.Colon)}${overviewBuild.source!.fiberCount}F`;
            const flameRatingString = `${t(LocalizationKeys.FlameRating)}${t(LocalizationKeys.Colon)}${flameRating.description}`;
            const apCountString = `${t(LocalizationKeys.NbAccessPoints)}${t(LocalizationKeys.Colon)}${overviewBuild.destinations!.length}`;
            const catalogCodeString = !draft ? `| ${t(LocalizationKeys.CatalogCode)}${t(LocalizationKeys.Colon)}${currentBuild!.catalogCode}` : "";

            const description = `${fiberCountString} | ${flameRatingString} | ${apCountString} ${catalogCodeString}`

            doc.overview = { ...overviewBuild, description };
        }

        if (feeder && feeder.include) {
            const buildFeeder = ToSourceData(ToSourceDTO(currentBuild!.source));
            const lengthB = buildFeeder.customBLength ? 
                buildFeeder.groups.map(g => g.lengthB!).reduce((a,b) => a.value < b.value ? a : b) :
                buildFeeder.lengthB;
                        
            const bEndStaggerValue = buildFeeder.lengthB!.value + (buildFeeder.groups.length - 1) * buildFeeder.groups[0].stagger!.value!
            const maxLengthB = buildFeeder.customBLength ? 
                buildFeeder.groups.map(g => g.lengthB!).reduce((a,b) => a.value > b.value ? a : b) : 
                { ...buildFeeder.lengthB!, value: bEndStaggerValue };

            const hasRange = buildFeeder.customBLength ?
                !buildFeeder.groups.every(g => g.lengthB!.value === buildFeeder.groups[0].lengthB!.value) :
                buildFeeder.groups.length > 1

            const bStaggerStartString = convertToDisplay(lengthB!, unit)
            const bEndStaggerString = convertToDisplay(maxLengthB, unit)
            
            const bStaggerRange = hasRange ? 
                `${bStaggerStartString + unit} to ${bEndStaggerString + unit}` :
                `${bStaggerStartString + unit}`

            const connectorsCountString = `${getNbConnectors(buildFeeder.groups)} ${t(LocalizationKeys.Connectors)}`;
            const connectorTypeString = `${t(LocalizationKeys.ConnectorType)}${t(LocalizationKeys.Colon)}${getConnectorType(buildFeeder.groups[0].type!).description}`;
            const fiberCountString = `${t(LocalizationKeys.FiberCount)}${t(LocalizationKeys.Colon)}${buildFeeder.fiberCount}F`;
            let polarityString = '';
            if (polarityDescription && polarityDescription.length > 0) {
                polarityString = ` | ${t(LocalizationKeys.Polarity)}${t(LocalizationKeys.Colon)}${polarityDescription}`;
            }

            const bString = `B0${t(LocalizationKeys.Colon)}${bStaggerRange}`;

            const description = `${connectorsCountString} | ${t(connectorTypeString)} | ${fiberCountString}${polarityString} | ${bString}`
            doc.feeder = { ...buildFeeder, description, isCollapsed: buildFeeder.customBLength ? false : feeder.collapsed }
        }

        if (drops && drops.include) {
            const destinations = currentBuild!.destinations;
            const feederTrunk: TrunkData = { ...ToSourceData(ToSourceDTO(currentBuild!.source)), position: 0 };
            const dropPositions = drops.includeAll ? destinations.map(d => d.position) : drops.dropPositions;
            if (dropPositions) {
                const documentDrops = dropPositions
                    .filter((value, index, self) => self.indexOf(value) === index && destinations.find(d => d.position === value)) // unique and real drops
                    .sort((p, c) => p > c ? 1 : -1)
                    .map(position => {
                        const prevTrunk: TrunkData = position === 1 ? { ...feederTrunk, position: 0 } : destinations.find((d) => d.position === position - 1)!
                        const destination = destinations.find(d => d.position === position)!;
                        const connectorCount = getNbConnectors(destination.groups)
                        const destinationData: IDestinationData = { ...ToDestinationData(ToDestinationDTO(destination)), isCollapsed: destination.customBLength ?  false : drops.collapsed }

                        const lengthB = destination.customBLength ? 
                            destination.groups.map(g => g.lengthB!).reduce((a,b) => a.value < b.value ? a : b) :
                            destination.lengthB;
                        
                        const bEndStaggerValue = destination.lengthB!.value + (destination.groups.length - 1) * destination.groups[0].stagger!.value!
                        const maxLengthB = destination.customBLength ? 
                            destination.groups.map(g => g.lengthB!).reduce((a,b) => a.value > b.value ? a : b) : 
                            { ...destination.lengthB!, value: bEndStaggerValue };

                        const hasRange = destination.customBLength ?
                            !destination.groups.every(g => g.lengthB!.value === destination.groups[0].lengthB!.value) :
                            destination.groups.length > 1

                        const bStaggerStartString = convertToDisplay(lengthB!, unit)
                        const bEndStaggerString = convertToDisplay(maxLengthB, unit)
                        
                        const bStaggerRange = hasRange ? 
                            `${bStaggerStartString + unit} to ${bEndStaggerString + unit}` :
                            `${bStaggerStartString + unit}`

                        const dropConnectorType = getConnectorType(destination.groups[0].type!);
                        const fiberCount = connectorCount * dropConnectorType.fiberCount
                        const a = convertToDisplay(prevTrunk.lengthA!, unit)

                        const connectorsCountString = `${connectorCount} ${t(LocalizationKeys.Connectors)}`;
                        const connectorTypeString = `${t(LocalizationKeys.ConnectorType)}${t(LocalizationKeys.Colon)}${getConnectorType(destination.groups[0].type!).description}`;
                        const fiberCountString = `${t(LocalizationKeys.FiberCount)}${t(LocalizationKeys.Colon)}${fiberCount}F`;

                        const polarity = documentFiberMaps ? 
                            documentFiberMaps.filter((m) => m.accessPointIndices.includes(position)).map((m) => m.name).filter((n, i, arr) => arr.indexOf(n) === i) :
                            [];
                        let polarityName: string | undefined = undefined;
                        if (polarity.length === 1) {
                            polarityName = polarity[0];
                        }
                        else if (polarity.length > 1) {
                            polarityName = t(LocalizationKeys.Multiple);
                        }
                        const polarityString = polarityName ? ` | ${t(LocalizationKeys.Polarity)}${t(LocalizationKeys.Colon)}${polarityName} | ` : ' |';

                        const aString = `A${prevTrunk.position}${t(LocalizationKeys.Colon)}${a + unit}`
                        const bString = `B${destination.position}${t(LocalizationKeys.Colon)}${bStaggerRange}`;

                        const description = `${connectorsCountString} | ${connectorTypeString} | ${fiberCountString}${polarityString} ${aString}, ${bString}`

                        const documentDrop: DocumentDrop = {
                            drop: destinationData,
                            prevTrunk: { ...prevTrunk, isCollapsed: true, customBLength: false },
                            description
                        }

                        return documentDrop;
                    });
                doc.drops = documentDrops;
            }
        }

        if (polarity?.include && documentFiberMaps) {
            doc.polarity = { documentFiberMaps };
        }

        if (fiberMap && fiberMap.include && !fiberMap.disabled ) {
            const documentFiberMap: DocumentFiberMap = {
                ...fiberMap,
                polarityMaps: []
            }

            doc.fiberMap = documentFiberMap;
        }

        if (connectorLabel && connectorLabel.include && !connectorLabel.disabled) {
            doc.connector = { include: true }
        }

        if (buildPlan && buildPlan.include && !buildPlan.disabled) {
            const customFiberCount = fiberCountOptions.find(f => f.count === currentBuild!.source.fiberCount!)
                ? `${currentBuild!.source.fiberCount!}`
                : t(LocalizationKeys.RoundBracket, { subject: `${currentBuild!.source.fiberCount!}`, extra: t(LocalizationKeys.Custom).toLocaleLowerCase() })

            const connectors = currentBuild!.destinations.map(d => d.groups).flat().map(g => g.connectors).flat();
            let distinctConnectors = getDistinctConnectors(connectors);
            const apConnectorTypes = distinctConnectors.join(", ")
            const assemblyLength = getAssemblyLengthString(currentBuild!, unit);

            const fiberCountString = t(LocalizationKeys.TotalFiberCount) + t(LocalizationKeys.Colon) + customFiberCount;
            const feederConnectorTypeString = t(LocalizationKeys.FeederConnectorType) + t(LocalizationKeys.Colon) + getConnectorType(currentBuild!.source.groups[0].type!).description!
            const apConnectorTypesString = t(LocalizationKeys.AccessPointConnectorTypes) + t(LocalizationKeys.Colon) + apConnectorTypes
            const apCountString = t(LocalizationKeys.NbAccessPoints) + t(LocalizationKeys.Colon) + currentBuild!.destinations.length
            const assemblyLengthString = t(LocalizationKeys.TotalAssemblyLength) + t(LocalizationKeys.Colon) + assemblyLength
            doc.buildPlan = {
                ...buildPlan,
                summary: {
                    fiberCount: fiberCountString,
                    feederConnectorType: feederConnectorTypeString,
                    apConnectorTypes: apConnectorTypesString,
                    apCount: apCountString,
                    assemblyLength: assemblyLengthString,
                }
            }
        }

        dispatch(setDocument(doc))
        await timeout(500);
        extractCanvas();
        await createPDF(doc, t)

    }, [documentFiberMaps, dispatch, currentBuild, extractCanvas, polarityDescription, t, flameRatingOptions, fiberCountOptions, unit, isAnonymous])

    return { print }
}

function getDistinctConnectors(connectors: IConnectorData[]) {
    const distinct: { [key: string]: { val: string } } = {};

    for (const { type } of connectors) {
        if (!distinct[type!]) {
            distinct[type!] = { val: type! };
        }
    }

    return Object.keys(distinct)
}

function getAssemblyLengthString(build: IBuildData, unit: Unit) {
    const { source, destinations } = build;
    const trunks: TrunkData[] = [{ ...source }, ...destinations]
    const aLengths = trunks.map(t => t.lengthA!.value).reduce((a, b) => a + b, 0) - trunks[trunks.length - 1].lengthA!.value;

    return convertToDisplay({ ...source.lengthA!, value: aLengths }, unit) + ' ' + getUnitsName(unit, false, true);
}

export function getMaxFurcationLegLength(trunk: TrunkData) {
    let assemblyLength = trunk.lengthB!.value + (trunk.groups!.length - 1) * trunk.groups![0].stagger!.value;
    return assemblyLength;
}

function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

const useCloseButton = () => {
    const dispatch = useDispatch<AppDispatch>()

    const closeWindow = useCallback(() => {
        dispatch(setShowPrintDialog(false));
    }, [dispatch])

    return { closeWindow };
}