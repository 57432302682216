import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../locales/types";
import { IGenericDialogProps } from "../../../../../ui/dialog/generic-dialog/types";
import { AppDispatch } from "../../../../redux/reducers";
import { useTimer } from "../../../../tracker/timer/hooks";
import { FIVE_MINS } from "../../../../tracker/timer/types";
import { renewToken } from "../../../authentication/redux/reducers";
import { setShowIdle, setShowTimeout } from "../../redux/reducers";
import { showIdleSelector } from "../../redux/selectors";
import { setStatusState } from "../header/components/status/redux/reducer";
import { WorkspaceStatus } from "../header/components/status/redux/types";

export const useIdleDialog = () => {
    const display = useSelector(showIdleSelector);
    const { t } = useTranslation();
    const storeDispatch = useDispatch<AppDispatch>();

    const onTimerEnd = useCallback(() => {
        batch(() => {
            storeDispatch(setShowIdle(false));
            storeDispatch(setShowTimeout(true));
        })
    }, [storeDispatch]);

    const { counter } = useTimer(display, FIVE_MINS, onTimerEnd);

    const onClick = useCallback(() => {
        batch(() => {
            storeDispatch(renewToken());
            storeDispatch(setShowIdle(false));
            storeDispatch(setStatusState(WorkspaceStatus.Ready));
        })
    }, [storeDispatch]);

    const dialogProps: IGenericDialogProps = {
        display,
        title: t(LocalizationKeys.IdleSessionTitle),
        message: t(LocalizationKeys.IdleSessionMessage, { countdown: `${counter.min}:${counter.sec}` }),
        closable: false,
        onConfirm: onClick,
        confirmText: t(LocalizationKeys.BackToWork)
    }

    return { dialogProps };
};