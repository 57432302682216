import { IBuildData, TrunkData } from "../build/types";
import { ISourceData } from "../build/source/types";
import { IDestinationData } from "../build/destination/types";
import { PolarityMap } from "../build/connector/polarity/types";
import { IDocumentFiberMap } from "../build/connector/polarity/fiber-map/types";

type ReportClassifier = Partial<{
    name: string,
    class: string,
    description: string,
}>

export type DocumentFields = Partial<{
    siteLocation: string,
    drawn: string,
    notes: string,
    date: string,
    revision: string,
    draft: boolean,
    catalogCode: string
}>

export type DocumentOverview = Partial<IBuildData> & ReportClassifier
export type DocumentFeeder = ISourceData & ReportClassifier
export type DocumentDrop = ReportClassifier & {
    drop: IDestinationData,
    prevTrunk: TrunkData,
};

export interface Polarities {
    accessPoints: number[]
}

export type DocumentPolarity = ReportClassifier & {
    documentFiberMaps: IDocumentFiberMap[];
}

export type DocumentFiberMap = Partial<{include: boolean}> & ReportClassifier & {
    polarityMaps: PolarityMap[];
}

export type DocumentConnector = Partial<{
    include: boolean
}>

export type DocumentBuildPlan = Partial<{
    include: boolean,
    summary: DocumentBuildPlanSummary
}>

type DocumentBuildPlanSummary = Partial<{
    fiberCount: string,
    feederConnectorType: string,
    apConnectorTypes: string,
    apCount: string,
    assemblyLength: string
}>

interface IDocumentDrawing {
    fileName: string,
    description: string,
    units: string
    overview: DocumentOverview,
    feeder: DocumentFeeder,
    drops: DocumentDrop[],
}

interface IDocumentDiagram {
    fiberMap: DocumentFiberMap,
    polarity: DocumentPolarity,
    connector: DocumentConnector,
    buildPlan: DocumentBuildPlan
}

export type BuildDocument = Partial<DocumentFields & IDocumentDrawing & IDocumentDiagram>;

export const initialBuildDocState: BuildDocument = {}