import { AlertPalettes } from "@orbit/snackbar";
import { t } from "i18next";
import { LocalizationKeys } from "../../../../../../../locales/types";
import { IConnectorData } from "../../../../../../redux/build/connector/types";
import { INotificationMessage } from "../../../notification/store/types";

export interface IPropagationOptions {
    propagateColors: boolean;
    propagateLabels: boolean;
}

export interface IPropagationState {
    showDialog: boolean;
    isActive: boolean;
}

export type PropagationState = IPropagationState & IPropagationOptions

export const initialPropagationState: PropagationState = {
    showDialog: false,
    isActive: false,
    propagateColors: false,
    propagateLabels: false
}

export const PropagationStatuses = {
    Failed: 0,
    Partial: 1,
    Success: 2
} as const;

export type PropagationStatus = typeof PropagationStatuses[keyof typeof PropagationStatuses];

export interface IPropagationResult {
    status: PropagationStatus,
    connectors: IConnectorData[]
}

export type PropagationNotificationPayload = IPropagationOptions & {
    status: PropagationStatus;
};

export const getPropagationNotification = (status: PropagationStatus): INotificationMessage => {
    switch (status) {
        case PropagationStatuses.Failed:
            return { message: t(LocalizationKeys.PropagationErrorMessage), palette: AlertPalettes.error };
        case PropagationStatuses.Partial:
            return { message: t(LocalizationKeys.PropagationPartialSuccessMessage), palette: AlertPalettes.warning };
        default:
            return { message: t(LocalizationKeys.PropagationSuccessfulMessage), palette: AlertPalettes.info };
    }
}