import React, { useCallback } from 'react'
import { FormControl, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { setUnit, setUnitsOfMeasureContainerDisplay } from './redux/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { Unit, UoMMeters, UoMCentimeters, UoMFeet, UoMInches } from './UnitsOfMeasure';
import { ToolItem } from '../../../../ToolItem';
import { unitsOfMeasureContainerSelector } from './redux/selectors';
import { buildIdSelector, currentBuildCatalogCodeSelector } from '../../../../../../selectors/build.selectors';
import { BuildService } from '../../../../../../services/build-service';

import icon_close from '../../../reports/components/connector/resources/icons/icon_close.svg'
import './UnitsOfMeasureContainer.scss'

export const UnitsOfMeasureContainer = () => {
    const { unit, display } = useSelector(unitsOfMeasureContainerSelector);
    const dispatch = useDispatch();
    const catalogCode = useSelector(currentBuildCatalogCodeSelector)
    const buildId = useSelector(buildIdSelector) 
    const disabledUnits = !!catalogCode?.length
    const close = useCallback(() => {
        dispatch(setUnitsOfMeasureContainerDisplay(false));
    }, [dispatch]);

    const handleChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        const unit = (event.currentTarget.value as Unit);
        dispatch(setUnit(unit));
        const buildService = new BuildService();
        await buildService.updateUnit(buildId ?? 0, unit)
    }, [dispatch, buildId]);

    if (display) {
        return (
            <div className="units-panel toggle-pointer-events">
                <FormControl component="fieldset">
                    <div className="units-header">
                        <div className="units-title">Units setup</div>
                        <ToolItem source={icon_close} alt="Close" onClick={close} />
                    </div>
                    <RadioGroup aria-label="units" value={unit} onChange={handleChange}>
                        <div className="units-metrics">
                            <div className="units-metrics-label">Metric</div>
                            <div>
                                <FormControlLabel value={UoMMeters} control={<Radio className="units-radio-meters" value={UoMMeters} disabled={disabledUnits}/>} label="Meters"/>
                                <div className="units-sublabel units-label-meters">Ex.: 4.52 m</div>
                            </div>
                            <div>
                                <FormControlLabel value={UoMCentimeters} control={<Radio className="units-radio-centimeters" value={UoMCentimeters} disabled={disabledUnits}/>} label="Centimeters"/>
                                <div className="units-sublabel units-label-centimeters">Ex.: 452 cm</div>
                            </div>
                        </div>
                        <div className="units-imperial">
                            <div className="units-imerial-label">Imperial</div>
                            <div>
                                <FormControlLabel value={UoMFeet} control={<Radio className="units-radio-foot" value={UoMFeet} disabled={disabledUnits}/>} label="Feet"/>
                                <div className="units-label-feet units-sublabel ">Ex.: 14.83 ft</div>
                            </div>
                            <div>
                                <FormControlLabel value={UoMInches} control={<Radio className="units-radio-inches" value={UoMInches} disabled={disabledUnits}/>} label="Inches"/>
                                <div className="units-label-inches units-sublabel ">Ex.: 178 "</div>
                            </div>
                        </div>
                    </RadioGroup>
                </FormControl>
            </div>
        );
    }
    return (null);
}