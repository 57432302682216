import { Black, Blue, Green, IColor, Mustard } from '../../ui/dialog/color/types';
import { MTP24BootColor, MTPBootColor, MTPSVG } from './MTP24';
import SOURCE_TRUNK_IMG from '../components/build/resources/image_cable_trunk.svg';
import SOURCE_TRUNK_EXTERNAL_IMG from '../components/build/resources/image_cable_trunk_external.svg';
import TAP_TRUNK_IMG from '../components/build/resources/image_TAP_trunk.svg'
import TAP_VERTICAL_TRUNK_IMG from "../components/build/resources/image_TAP_vertical_trunk.svg"
import WAM_IMG from "../components/build/resources/image_wam.svg";
import MTP_IMG from '../components/build/resources/image_8-12F_MTP.svg';
import MTP24F_IMG from '../components/build/resources/image_24F_MTP.svg';
import LC_IMG from '../components/build/resources/image_2F_LC.svg';
import { LCUnibootSVG } from './LcUniboot';
import { FiberColors, MMCFiberColors, MTPColors } from '../../workspace/components/overlay/components/polarity/fiber-mapping/connector/templates/types';
import { ISize } from '../types';
import { MMCSVG } from './MMC';

type Texture = {
    name: string;
    path: string;
    img: string;
} & ISize;

export const SOURCE_TRUNK = 'SourceTrunk';
export const SOURCE_TRUNK_IMGPATH = 'image_cable_trunk.svg';
export const SOURCE_TRUNK_EXTERNAL = 'SourceTrunkExternal';
export const SOURCE_TRUNK_EXTERNAL_IMGPATH = 'image_cable_trunk_external.svg';

export const TAP_TRUNK = "TAPTrunk";
export const TAP_TRUNK_IMGPATH = 'image_TAP_trunk.svg'
export const TAP_VERTICAL_TRUNK = "TAPVerticalTrunk"
export const TAP_VERTICAL_TRUNK_IMGPATH = "image_TAP_vertical_trunk.svg"
export const WAM = "WAM";
export const WAM_IMGPATH = "image_wam.svg";

export const MMC8F_NP = "8 F, MMC, non-pinned";
export const MMC8F_P = "8 F, MMC, pinned";
export const MMC16F_NP = "16 F, MMC, non-pinned";
export const MMC16F_P = "16 F, MMC, pinned";
export const MMC24F_NP = "24 F, MMC, non-pinned";
export const MMC24F_P = "24 F, MMC, pinned";

export const MTP = '12 F, MTP'; // default mtp
export const MTP8F_NP = '8 F, MTP, non-pinned';
export const MTP8F_P = '8 F, MTP, pinned';
export const MTP12F_NP = '12 F, MTP, non-pinned';
export const MTP12F_P = '12 F, MTP, pinned';
export const MTP12F_FP_NP = '12 F, MTP Ferrule Push, non-pinned';
export const MTP12F_FP_P = '12 F, MTP Ferrule Push, pinned';
export const MTP24F_NP = '24 F, MTP, non-pinned';
export const MTP24F_P = '24 F, MTP, pinned';
export const MTP24F_FP_NP = '24 F, MTP Ferrule Push, non-pinned';
export const MTP24F_FP_P = '24 F, MTP Ferrule Push, pinned';

export const MTP_IMGPATH = 'image_8-12F_MTP.svg';
export const MTP24F_IMGPATH = 'image_24F_MTP.svg';

export const LC = '2 F, LC Uniboot';
export const LCPC = '2 F, LC PC Uniboot';
export const LCUPC = '2 F, LC UPC Uniboot';
export const LC_IMGPATH = 'image_2F_LC.svg';

export const PinnedString = "pinned";
export const LCUnibootTypeString = "LCUniboot";
export const MTPTypeString = "MTP";
export const MTP24TypeString = "MTP24";
export const MMCTypeString = "MMC";

export const MetaLCConnectorTexture: Texture = { name: LC, path: LC_IMGPATH, img: LC_IMG, width: 14, height: 70 };
export const MetaMTPConnectorTexture: Texture = { name: "8-12F MTP", path: MTP_IMGPATH, img: MTP_IMG, width: 26, height: 100 };
export const MetaMTP24FConnectorTexture: Texture = { name: "24F MTP", path: MTP24F_IMGPATH, img: MTP24F_IMG, width: 26, height: 100 };
export const MetaMMCConnectorTexture: Texture = { name: "8-24F MMC", path: "", img: "", width: 14, height: 68 };

export const MetaSourceTrunkTexture: Texture = { name: SOURCE_TRUNK, path: SOURCE_TRUNK_IMGPATH, img: SOURCE_TRUNK_IMG, width: 288.619, height: 271.989 };
export const MetaSourceTrunkExternalTexture: Texture = { name: SOURCE_TRUNK_EXTERNAL, path: SOURCE_TRUNK_EXTERNAL_IMGPATH, img: SOURCE_TRUNK_EXTERNAL_IMG, width: 292.594, height: 279 };

export const MetaTAPTrunkTexture: Texture = { name: TAP_TRUNK, path: TAP_TRUNK_IMGPATH, img: TAP_TRUNK_IMG, width: 535.408, height: 57.869 };
export const MetaTAPVerticalTrunkTexture: Texture = { name: TAP_VERTICAL_TRUNK, path: TAP_VERTICAL_TRUNK_IMGPATH, img: TAP_VERTICAL_TRUNK_IMG, width: 34.0, height: 236.0 };
export const MetaWAMTexture: Texture = { name: WAM, path: WAM_IMGPATH, img: WAM_IMG, width: 55.009 , height: 36.225 };

export type MetaSprite =
    typeof MetaSourceTrunkTexture |
    typeof MetaSourceTrunkExternalTexture |
    typeof MetaTAPTrunkTexture |
    typeof MetaWAMTexture |
    typeof MetaTAPVerticalTrunkTexture;

export const MetaSprites = {
    MetaSourceTrunkTexture,
    MetaSourceTrunkExternalTexture,
    MetaTAPTrunkTexture,
    MetaWAMTexture,
    MetaTAPVerticalTrunkTexture,
} as const;

export const MetaSpriteList = [
    MetaSourceTrunkTexture,
    MetaSourceTrunkExternalTexture,
    MetaTAPTrunkTexture,
    MetaWAMTexture,
    MetaTAPVerticalTrunkTexture,
]

export function getConnectorTexture(connector: string): MetaSprite {
    switch (connector) {
        case MMC8F_NP:
        case MMC8F_P:
        case MMC16F_NP:
        case MMC16F_P:
        case MMC24F_NP:
        case MMC24F_P:
            return MetaMMCConnectorTexture;
        case MTP:
        case MTP8F_NP:
        case MTP8F_P:
        case MTP12F_NP:
        case MTP12F_P:
        case MTP12F_FP_NP:
        case MTP12F_FP_P:
        case MTP_IMGPATH:
            return MetaMTPConnectorTexture;
        case MTP24F_NP:
        case MTP24F_P:
        case MTP24F_FP_NP:
        case MTP24F_FP_P:
        case MTP24F_IMGPATH:
            return MetaMTP24FConnectorTexture;
        case LC:
        case LCPC:
        case LC_IMGPATH:
            return MetaLCConnectorTexture;
    }

    // Catch connector values of the type 'LCUniboot_Blue', 'MTP_pinned_Green', etc.
    if (isLCUniboot(connector)) {
        return MetaLCConnectorTexture;
    }
    else if (isMTP24(connector)) { // Check for MTP24 first because both "MTP" and "MTP24" contain the substring "MTP" 
        return MetaMTP24FConnectorTexture;
    }
    else if (isMTP(connector)) {
        return MetaMTPConnectorTexture;
    }

    return MetaMTPConnectorTexture;
}

export const getConnectorTypeString = (connectorType: string) => {
    switch (connectorType) {
        case MMC8F_NP:
        case MMC16F_NP:
        case MMC24F_NP:
            return MMCTypeString;
        case MMC8F_P:
        case MMC16F_P:
        case MMC24F_P:
            return `${MMCTypeString}_${PinnedString}`;
        case MTP:
        case MTP8F_P:
        case MTP12F_P:
        case MTP12F_FP_P:
        case MTP_IMGPATH:
            return `${MTPTypeString}_${PinnedString}`;   
        case MTP8F_NP:
        case MTP12F_NP:
        case MTP12F_FP_NP:
            return MTPTypeString;
        case MTP24F_P:
        case MTP24F_FP_P:
        case MTP24F_IMGPATH:
            return `${MTP24TypeString}_${PinnedString}`;
        case MTP24F_NP:
        case MTP24F_FP_NP:
            return MTP24TypeString;
        case LC:
        case LCPC:
        case LC_IMGPATH:
            return LCUnibootTypeString;
        default:
            return "Unknown";
    }
}

export const getConnectorDefaultColor = (connectorType?: string) => {
    switch (connectorType) {
        case MMC8F_NP:
        case MMC8F_P:
        case MMC16F_NP:
        case MMC16F_P:
        case MMC24F_NP:
        case MMC24F_P:
        case MTP:
        case MTP8F_NP:
        case MTP8F_P:
        case MTP12F_NP:
        case MTP12F_P:
        case MTP12F_FP_NP:
        case MTP12F_FP_P:
        case MTP_IMGPATH:
            return Green;    
        case MTP24F_NP:
        case MTP24F_P:
        case MTP24F_FP_NP:
        case MTP24F_FP_P:
        case MTP24F_IMGPATH:
            return Mustard;
        case LC:
        case LCPC:
        case LC_IMGPATH:
        default:
            return Blue;
    }
}

export const getConnectorTypeColors = (connectorType: string): IColor[] => {
    switch (connectorType) {
        case MMC16F_NP:
        case MMC16F_P:
            return MMCFiberColors;
        case MTP:
        case MMC8F_NP:
        case MMC8F_P:
        case MTP8F_NP:
        case MTP8F_P:
        case MTP12F_NP:
        case MTP12F_P:
        case MTP12F_FP_NP:
        case MTP12F_FP_P:
        case MTP24F_NP:
        case MTP24F_P:
        case MTP24F_FP_NP:
        case MTP24F_FP_P:
            return MTPColors;
        default:
            return FiberColors;
    }
}

export const getConnectorColor = (connectorType: string, color: string) => {
    const colors = getConnectorTypeColors(connectorType);
    return colors.find(c => color === c.name) || getConnectorDefaultColor(connectorType);
}

export function getMTPBootColor(connectorType: string) {
    return isMTP24(connectorType) ? MTP24BootColor : MTPBootColor;
}

export const isLCUniboot = (connectorType: string): boolean => {
    return connectorType.includes(LCUnibootTypeString);
}

export const isMTP = (connectorType: string) => {
    return connectorType.includes(MTPTypeString);
}

export const isMTP24 = (connectorType: string) => {
    return connectorType.includes(MTP24TypeString);
}

export const isMMC = (connectorType: string) => {
    return connectorType.includes(MMCTypeString);
}

export const isMMC16 = (connectorType: string) => {
    return connectorType === MMC16F_NP || connectorType === MMC16F_P;
}

export const isMMC8 = (connectorType: string) => {
    return connectorType === MMC8F_NP || connectorType === MMC8F_P;
}

export const isPinned = (connectorType: string) => {
    return connectorType.length > 0 ? connectorType.includes(PinnedString) : false;
}

export function getConnnectorSVG(connectorType: string, hex: string, scale?: number) {
    if (isMTP(connectorType)) {
        return MTPSVG(hex, getMTPBootColor(connectorType), isPinned(connectorType), scale);
    } else if (isMMC(connectorType)) {
        return MMCSVG(hex, Black.hex, isPinned(connectorType), scale);
    } else {
        return LCUnibootSVG(hex, scale, Blue.hex);
    }
}