import { DocumentDrop } from "../../../../../workspace/redux/document/types";
import { useContext } from "react";
import { Unit } from "../../../../../workspace/components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure";
import { MarkerStyleContext } from "../../../decorators/markers/types";
import { BuildContext, TrunkContext } from "../../../../../workspace/redux/build/types";
import { IPoint } from "../../../../types";
import { IBaseProps, TrunkProps } from "../../../build/types";
import { DropToTrunkProps } from "../../../../../workspace/selectors/build.selectors";
import { useSelector } from "react-redux";
import { fiberTypeTrunkColorSelector } from "../../../../../workspace/redux/ssc/selectors";
import { ILengthProps } from "../../../decorators/markers/components/length/types";
import { ILineMarkerProps } from "../../../decorators/markers/components/line/types";

export const usePreviousDrop = ({ prevTrunk }: DocumentDrop) => {
    const { thickness, color } = useContext(MarkerStyleContext);
    const { connectorType, position, wamLocation, markerProps } = useContext(TrunkContext);

    const display = !!prevTrunk;
    const baseProps: IBaseProps = { connectorType, wamLocation };

    const { pathStartA, pathA, pathEndA } = markerProps;
    const startA: ILineMarkerProps | undefined = pathStartA ? {
        path: pathStartA,
        orientation: "horizontal",
        thickness,
        color
    } : undefined;

    const a: ILineMarkerProps | undefined = pathA ? {
        path: pathA,
        orientation: "horizontal",
        direction: "bidirectional",
        thickness,
        color
    } : undefined;

    const endA: ILineMarkerProps | undefined = pathEndA ? {
        path: pathEndA,
        orientation: "vertical",
        thickness,
        color
    } : undefined;
    
    const aProps: ILengthProps = {
        position,
        start: startA,
        line: a,
        end: endA
    };

    return { display, aProps, prevTrunk, baseProps }
}

export const useCurrentDrop = ({ drop, prevTrunk }: DocumentDrop) => {
    const { unit } = useContext(BuildContext);
    const { id: trunkColor } = useSelector(fiberTypeTrunkColorSelector);

    const x = prevTrunk.position === 0 ? 267 : 510;
    const y = prevTrunk.position === 0 ? -14 : 0;

    const coordinates: IPoint = { x, y };
    const trunkProps: TrunkProps = DropToTrunkProps(prevTrunk, drop, unit as Unit, trunkColor, coordinates);

    return { x, y, trunkProps };
};