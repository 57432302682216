import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux"
import { LocalizationKeys } from "../../../../../../locales/types";
import { setViewportContext } from "../../../../../../pixi/components/viewport/redux/reducers";
import { ViewportStatus } from "../../../../../../pixi/components/viewport/redux/types";
import { IDialogHeaderProps } from "../../../../../../ui/dialog/header/types";
import { setShowThirdPartyCopyright } from "../../../redux/reducers";
import { showThirdPartyCopyrightSelector } from "../../../redux/selectors"
import { OpenSourceLicenses } from "../types";

export const useThirdPartyCopyrightDialog = () => {
    const { t } = useTranslation();
    const showThirdPartyCopyright = useSelector(showThirdPartyCopyrightSelector);
    const licenses = OpenSourceLicenses;
    const dispatch = useDispatch();

    const closeDialog = useCallback(() => {
        batch(() => {
            dispatch(setShowThirdPartyCopyright(false));
            dispatch(setViewportContext(ViewportStatus.Active));
        })
    }, [dispatch]);

    const dialogHeaderProps: IDialogHeaderProps = useMemo(() => {
        return {
            title: t(LocalizationKeys.ThirdPartyOSSCopyright),
            closable: true,
            onClose: closeDialog
        }
    }, [t, closeDialog])

    return { showThirdPartyCopyright, licenses, dialogHeaderProps, closeDialog }
}