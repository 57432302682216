import { useContext, useCallback, Dispatch, useRef, useMemo } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { ViewportStatus, ViewportStatusTypes } from "../../../../viewport/redux/types";
import { setSelectedPosition, setHoveredPosition, setToolbarDisplay } from "../../../../../../workspace/components/overlay/components/footer/components/toolbar/redux/reducers";
import { setCurrentStep, saveBuildData, setWizardDisplay, enableNextStep } from "../../../../../../workspace/components/overlay/components/wizard/redux/reducers";
import { BuildContext, IBuildData, TrunkContext } from "../../../../../../workspace/redux/build/types";
import { setViewportContext } from "../../../../viewport/redux/reducers";
import { BoundingBoxContext } from "../../BoundingBox";
import { IRectangleLayout, IRectangleDrawingProps } from "../rectangle/types";
import { overlaySelector } from "../../../../../../workspace/components/overlay/redux/selectors";
import { useSscConfigSession } from "../../../../../../workspace/redux/ssc/hooks";
import { sscConfigSessionBusySelector } from "../../../../../../workspace/redux/ssc/selectors";
import { DEFAULT_BLUE, GREY, toDecimalHex } from "./redux/types";
import { toolbarSelectionSelector } from "../../../../../../workspace/components/overlay/components/footer/components/toolbar/redux/selectors";
import { viewportContextSelector } from "../../../../viewport/redux/selectors";

export const useInteractions = () => {
    const { bounds, isMouseOver } = useContext(BoundingBoxContext);

    const dispatch = useDispatch();
    const { position } = useContext(TrunkContext);
    const { selected: stateSelected } = useSelector(toolbarSelectionSelector);
    const { showConnectorAssignment: isConnectorAssignment, showFiberMapping: isFiberMapping } = useSelector(overlaySelector);
    const currentBuild = useContext(BuildContext);
    const sessionBusy = useSelector(sscConfigSessionBusySelector)
    const { updateConfigSessionConfigurationType } = useSscConfigSession();
    const { isDragging, context } = useSelector(viewportContextSelector);

    const configType = currentBuild.configurationType;
    const getConfigSession = useCallback(async () => {
        await updateConfigSessionConfigurationType(configType)
    }, [updateConfigSessionConfigurationType, configType])

    const onSingleClick = useCallback(() => dispatchSelection(dispatch, stateSelected, position, isDragging, context), [dispatch, stateSelected, position, isDragging, context]);
    const onDoubleClick = useCallback(() => dispatchEditMode(dispatch, context, position, currentBuild, sessionBusy, getConfigSession), [dispatch, context, position, currentBuild, sessionBusy, getConfigSession]);
    const { triggerClick } = useClickEvents(onSingleClick, onDoubleClick);
    const onMouseClick = useCallback(() => triggerClick(), [triggerClick]);

    const selectionBoxProps: IRectangleDrawingProps = useMemo(() => {
        const display = context !== ViewportStatus.Inactive && !isConnectorAssignment && !isFiberMapping;
        const selected = stateSelected === position;
        const layout: IRectangleLayout = getSelectionLayout(selected, isMouseOver);

        return { display, interactive: true, bounds, layout, onMouseClick }

    }, [isConnectorAssignment, isFiberMapping, context, bounds, position, isMouseOver, stateSelected, onMouseClick]);

    return selectionBoxProps;
}

function getSelectionLayout(selected: boolean, isMouseOver: boolean): IRectangleLayout {
    const color = selected ? toDecimalHex(DEFAULT_BLUE.fill) : toDecimalHex(GREY.fill);
    const alpha = selected || isMouseOver ? 1 : 0;

    return { borderColor: color, color, alpha };
}

function dispatchSelection(dispatch: Dispatch<any>, selected: number, position: number, isDragging: boolean, context: ViewportStatusTypes) {
    if (!isDragging && context !== ViewportStatus.Editing) {
        if (selected !== position) {
            batch(() => {
                dispatch(setSelectedPosition(position));
                dispatch(setHoveredPosition(position));
                dispatch(setToolbarDisplay(true));
            });
        } else {
            batch(() => {
                dispatch(setSelectedPosition(-1));
                dispatch(setToolbarDisplay(false));
            });
        }
    }
}

function dispatchEditMode(dispatch: Dispatch<any>, context: ViewportStatusTypes, position: number, currentBuild: IBuildData, sessionBusy: boolean, getConfigSession: () => Promise<any>) {
    if (sessionBusy) return;
    getConfigSession().then(_ => {
        if (context !== ViewportStatus.Editing) {
            batch(() => {
                dispatch(setSelectedPosition(position));
                dispatch(setHoveredPosition(position));
            }) // A re-render is required when the selection is done

            batch(() => {
                if (position === 0) {
                    dispatch(setCurrentStep!(1));
                } else {
                    dispatch(setCurrentStep!(2));
                }

                dispatch(setViewportContext(ViewportStatus.Editing));
                dispatch(setWizardDisplay(true));
                dispatch(enableNextStep(false));
                dispatch(saveBuildData(currentBuild));
                dispatch(setToolbarDisplay(false));
            });
        }
    })
}

const useClickEvents = (triggerSingleClick: () => void, triggerDoubleClick: () => void) => {
    let delay = 250;
    let nbClicks = useRef(0);
    const triggerClick = useCallback(() => {
        nbClicks.current++;
        setTimeout(() => {
            if (nbClicks.current === 1) {
                triggerSingleClick();
            } else if (nbClicks.current === 2) {
                triggerDoubleClick();
            }
            nbClicks.current = 0;
        }, delay);
    }, [triggerSingleClick, triggerDoubleClick, delay]);

    return { triggerClick };
}