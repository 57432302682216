import { ISize } from "pixi.js";
import { SpriteProps } from "../../types";

export type ConnectorSpriteProps = {
    type: string;
    bootColor: string;
} & SpriteProps & ISize;

export const CONNECTOR_STEP = 30;
export const GROUP_STEP = 50

