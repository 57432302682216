import { showTimeoutSelector } from '../../redux/selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../../locales/types';
import { useCallback } from 'react';
import { useLogout } from '../../../authentication/components/oid/hooks';
import { IGenericDialogProps } from '../../../../../ui/dialog/generic-dialog/types';

export const useTimeoutDialog = () => {
    const { t } = useTranslation();
    const display = useSelector(showTimeoutSelector);
    const { logout } = useLogout();

    const onLoginAgainClick = useCallback(() => {
        logout();
    }, [logout]);

    const dialogProps: IGenericDialogProps = {
        display,
        title: t(LocalizationKeys.TimeoutTitle),
        message: t(LocalizationKeys.TimeoutMessage),
        closable: false,
        onConfirm: onLoginAgainClick,
        confirmText: t(LocalizationKeys.LoginAgain)
    }

    return { dialogProps }
};