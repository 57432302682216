import { useReducer, useCallback, useEffect } from "react"
import { PolarityReducer, setContextAssignedMapping } from "./redux/reducer"
import { initialPolarityState, IPolarityContext, IPolarityState } from "./redux/types"
import { useSelector, useDispatch } from "react-redux"
import { setShowPolarity, setShowConnectorAssignment, setDisablePolarity } from "../../redux/reducers"
import { buildIdSelector } from "../../../../selectors/build.selectors"
import { IFiberMapData } from "../../../../redux/build/connector/polarity/fiber-map/types"
import { forceReloadSelector, userFiberMapsSelector } from "../../../../redux/build/connector/polarity/selectors"
import { setDocumentFiberMaps, setUserFiberMaps } from "../../../../redux/build/connector/polarity/reducer"
import { overlaySelector } from "../../redux/selectors"
import { ProjectManagerService } from "../../../../services/projectmanager-service"
import { WorkspaceStatus } from "../header/components/status/redux/types"
import { currentStatusSelector } from "../header/components/status/redux/selectors"
import { BuildService } from "../../../../services/build-service"

export const usePolarity = () => {
    const [state, dispatch] = useReducer(PolarityReducer, initialPolarityState);
    const polarityContext: IPolarityContext = { state, dispatch };
    const currentBuildId = useSelector(buildIdSelector);
    const currentStatus = useSelector(currentStatusSelector);
    const userFiberMapsLoaded = useSelector(userFiberMapsSelector).length !== 0;
    const forceReload = useSelector(forceReloadSelector)
    const storeDispatch = useDispatch()
    
    const loadUserFiberMaps = useCallback(async () => {
        const { succesful, data } = await new BuildService().getUserPolarityFiberMapsByGroupId();
        if (succesful && data) {
            storeDispatch(setUserFiberMaps(data));
        }
    }, [storeDispatch]);

    const loadConnectorAssignments = useCallback(async (projectId?: number) => {
        dispatch(setContextAssignedMapping([]));
        storeDispatch(setDisablePolarity(true));
        const res = await new ProjectManagerService().getConnectorAssignments(projectId ?? 0);
        if (res.succesful && res.data) {
            const connectorAssignments = [...res.data].filter(d => d.sourceMapping.length);
            dispatch(setContextAssignedMapping(connectorAssignments));
        }
        storeDispatch(setDisablePolarity(false));
    }, [dispatch, storeDispatch]);

    useEffect(() => {
        if (currentStatus === WorkspaceStatus.Loading && !userFiberMapsLoaded) {
            loadUserFiberMaps();
        }
    }, [currentStatus, userFiberMapsLoaded, loadUserFiberMaps]);

    useEffect(() => {
        if (currentStatus === WorkspaceStatus.Loading) {
            loadConnectorAssignments(currentBuildId);
        }
        
    }, [currentStatus, currentBuildId, loadConnectorAssignments]);

    useEffect(() => {
        if (forceReload) {
            // Either load connector assignments or reset them
            forceReload.buildId ? loadConnectorAssignments(forceReload.buildId) : dispatch(setContextAssignedMapping([]));
        }
    }, [forceReload, dispatch, loadConnectorAssignments]);

    useEffect(() => {
        storeDispatch(setDocumentFiberMaps(state.assignmentMapping))
    }, [state.assignmentMapping, storeDispatch]);

    return { polarityContext };
}

export const usePolarityDialog = () => {
    const { showPolarity } = useSelector(overlaySelector)
    const dispatch = useDispatch();

    const closeDialog = useCallback(() => {
        dispatch(setShowPolarity(false))
        dispatch(setShowConnectorAssignment(true));
    }, [dispatch]);

    return { showPolarity, closeDialog }
}

export function getCurrentFiberMap(state: IPolarityState, fiberMaps: IFiberMapData[]): IFiberMapData | undefined {
    if (state.polarity && state.polarity.key !== undefined && fiberMaps[state.polarity.key]) {
        return fiberMaps[state.polarity.key];
    }
    else if (state.editAssignmentMap && state.editAssignmentMap.fiberMap) {
        return state.editAssignmentMap.fiberMap
    }
}
