import { IUnitOfMeasure, Unit } from "../../components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure";
import { ConfigurationType } from "../../components/overlay/components/wizard/redux/types";
import { FeederPreset, DropPreset, WizardPreset, UserWizardPreset, initialUserWizardPreset } from "../../redux/build/wizard/wizard-preset/redux/types";
import { LengthDTO, Identifiable } from "../types";

interface IFeederPresetDTO {
    fiberCount: number,
    connectorType: string,
    groups: number,
    connectorsPerGroup: number,
    aLength: LengthDTO,
    bLength: LengthDTO,
    staggerLength: LengthDTO,
    gripOnEnd: boolean,
    customTag: string,
    flameRating: string
}

interface IDropPresetDTO {
    connectorType: string,
    groups: number,
    connectorsPerGroup: number,
    aLength: LengthDTO,
    bLength: LengthDTO,
    staggerLength: LengthDTO,
    calculateAccessPoints: boolean,
    accessPointCount: number
}

interface IWizardPresetDTO {
    configurationType?: string,
    feederPreset?: FeederPresetDTO,
    dropPreset?: DropPresetDTO
}

interface IUserWizardPresetDTO {
    selectedPresetId?: number,
    wizardPresets?: WizardPresetDTO[]
}

export type FeederPresetDTO = Partial<IFeederPresetDTO & Identifiable>
export type DropPresetDTO = Partial<IDropPresetDTO & Identifiable>
export type WizardPresetDTO = Partial<IWizardPresetDTO & Identifiable>
export type UserWizardPresetDTO = Partial<IUserWizardPresetDTO & Identifiable>

function ToFeederPreset(dto: FeederPresetDTO): FeederPreset {
    const {
        id,
        fiberCount,
        connectorType,
        groups,
        connectorsPerGroup,
        aLength,
        bLength,
        staggerLength,
        gripOnEnd,
        customTag,
        flameRating
    } = dto;
    return {
        id: id,
        fiberCount: fiberCount,
        connectorType: connectorType,
        groups: groups,
        connectorsPerGroup: connectorsPerGroup,
        aLength: ToUnitofMeasure(aLength!),
        bLength: ToUnitofMeasure(bLength!),
        staggerLength: ToUnitofMeasure(staggerLength!),
        gripOnEnd: gripOnEnd,
        customTag: customTag,
        flameRating: flameRating
    }
}

function ToDropPreset(dto: DropPresetDTO): DropPreset {
    const {
        id,
        connectorType,
        groups,
        connectorsPerGroup,
        aLength,
        bLength,
        staggerLength,
        calculateAccessPoints,
        accessPointCount
    } = dto;
    return {
        id: id,
        connectorType: connectorType,
        groups: groups,
        connectorsPerGroup: connectorsPerGroup,
        aLength: ToUnitofMeasure(aLength!),
        bLength: ToUnitofMeasure(bLength!),
        staggerLength: ToUnitofMeasure(staggerLength!),
        calculateAccessPoints: calculateAccessPoints,
        accessPointCount: accessPointCount
    }
}

export function ToWizardPreset(dto: WizardPresetDTO): WizardPreset {
    const { id, configurationType, feederPreset, dropPreset } = dto;
    return {
        id: id,
        configurationType: configurationType ?? ConfigurationType.Patching,
        feederPreset: feederPreset ? ToFeederPreset(feederPreset) : {},
        dropPreset: dropPreset ? ToDropPreset(dropPreset) : {}
    }
}

export function ToUserWizardPreset(dto: UserWizardPresetDTO, configurationType?: string): UserWizardPreset {
    const { id, wizardPresets } = dto;
    const presets = wizardPresets ? wizardPresets.map(w => ToWizardPreset(w)) : [];
    const found = presets.find(p => p.configurationType === configurationType) ?? initialUserWizardPreset
    return {
        id: id,
        selectedPreset: found,
        wizardPresets: presets
    }
}

function ToFeederPresetDTO(data: FeederPreset): FeederPresetDTO {
    const {
        id,
        fiberCount,
        connectorType,
        groups,
        connectorsPerGroup,
        aLength,
        bLength,
        staggerLength,
        gripOnEnd,
        customTag,
        flameRating
    } = data;
    return {
        id: id,
        fiberCount: fiberCount,
        connectorType: connectorType,
        groups: groups,
        connectorsPerGroup: connectorsPerGroup,
        aLength: ToLengthDTO(aLength!),
        bLength: ToLengthDTO(bLength!),
        staggerLength: ToLengthDTO(staggerLength!),
        gripOnEnd: gripOnEnd,
        customTag: customTag,
        flameRating: flameRating
    }
}

function ToDropPresetDTO(dto: DropPreset): DropPresetDTO {
    const {
        id,
        connectorType,
        groups,
        connectorsPerGroup,
        aLength,
        bLength,
        staggerLength,
        calculateAccessPoints,
        accessPointCount
    } = dto;
    return {
        id: id,
        connectorType: connectorType,
        groups: groups,
        connectorsPerGroup: connectorsPerGroup,
        aLength: ToLengthDTO(aLength!),
        bLength: ToLengthDTO(bLength!),
        staggerLength: ToLengthDTO(staggerLength!),
        calculateAccessPoints: calculateAccessPoints,
        accessPointCount: accessPointCount
    }
}

export function ToWizardPresetDTO(wizardPreset: WizardPreset): WizardPresetDTO {
    const { id, feederPreset, dropPreset } = wizardPreset;
    return {
        id: id,
        configurationType: wizardPreset.configurationType ?? undefined,
        feederPreset: feederPreset ? ToFeederPresetDTO(feederPreset) : undefined,
        dropPreset: dropPreset ? ToDropPresetDTO(dropPreset) : undefined
    }
}

export function ToUserWizardPresetDTO(userWizardPreset: UserWizardPreset): UserWizardPresetDTO {
    const { id, selectedPreset, wizardPresets } = userWizardPreset;
    return {
        id: id,
        selectedPresetId: selectedPreset ? selectedPreset.id : undefined,
        wizardPresets: wizardPresets ? wizardPresets.map(w => ToWizardPresetDTO(w)) : []
    }
}

function ToUnitofMeasure(dto: LengthDTO | undefined): IUnitOfMeasure | undefined {
    return dto ? {
        id: dto.id,
        value: dto.value,
        unit: dto.unit as Unit
    } : undefined;
}

function ToLengthDTO(data: IUnitOfMeasure | undefined): LengthDTO | undefined {
    return data ? { ...data } : undefined;
}
