import { useSelector } from "react-redux";
import { useState, useEffect, useCallback, useMemo } from "react";
import { getUnitsName, convertToDisplay } from '../../../../../header/components/units-of-measure-container/UnitsOfMeasure';
import { StaggerLengthTypes } from "../../../../redux/types";
import { IStaggerAutoCompleteProps } from "./types";
import { useTranslation } from "react-i18next";
import { AutocompleteCloseReason } from "@mui/material";
import { LocalizationKeys } from "../../../../../../../../../locales/types";
import { unitsOfMeasureContainerUnitSelector } from '../../../../../header/components/units-of-measure-container/redux/selectors';

export const useStaggerAutocomplete = (props: IStaggerAutoCompleteProps) => {
    const { value: staggerValue, onValueChanged, onInputChanged, isInputValid, inputErrorText, disable, position, options: staggerOptions } = props;
    const { t } = useTranslation();
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);

    const [open, setOpen] = useState(false);
    const [label, setLabel] = useState(``);

    const enterKeyCode = 13;

    let options = useMemo(() => {
        return staggerOptions ?? StaggerLengthTypes.map(s => convertToDisplay(s.value, unit));
    }, [unit, staggerOptions]);

    useEffect(() => {
        setOpen(false);
    }, [staggerValue]);

    useEffect(() => {
        setLabel(t(LocalizationKeys.StaggerWithUnit, { position: position, unit: getUnitsName(unit) }));
    }, [unit, position, t]);

    const onOpen = useCallback((e: React.ChangeEvent<{}>) => {
        setOpen(true);
    }, []);

    const onClose = useCallback((e: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
        setOpen(false)
    }, []);

    const onInputKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === enterKeyCode) {
            setOpen(false);
        }
    }, []);

    return { open, disabled: disable, options, staggerValue, onOpen, onClose, onValueChanged, onInputChanged, label, isInputValid, inputErrorText, onInputKeyDown };
}