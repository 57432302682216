import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../locales/types';
import { BuildPlanTableProps } from './types';

export const BuildPlanTable: React.FC<BuildPlanTableProps> = (props) => {
    const { t } = useTranslation();
    const { buildPlanTables: [table1, table2] } = props;
    return (
        <div className="build-plan-tables">
            <table id="build-plan-table1">
                <thead>
                    <tr>
                        <th>{t(LocalizationKeys.Description)}</th>
                        <th>{t(LocalizationKeys.Quantity)}</th>
                        <th>{t(LocalizationKeys.UOM)}</th>
                    </tr>
                </thead>
                <tbody>
                    {table1.map((r, i) => <tr key={`table1row${i}`} className={r.className}>{Object.values(r.data).map((d, j) => <td key={`table1${d}${i}${j}`} className={r.className}>{`${d}`}</td>)}</tr>)}
                </tbody>
            </table>
            {table2.length &&
                <table id="build-plan-table2">
                    <thead>
                        <tr>
                            <th>{t(LocalizationKeys.Description)}</th>
                            <th>{t(LocalizationKeys.Quantity)}</th>
                            <th>{t(LocalizationKeys.UOM)}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {table2.map((r, i) => <tr key={`table2row${i}`}>{Object.values(r.data).map((d, j) => <td className={r.className} key={`table2${d}${i}${j}`}>{d}</td>)}</tr>)}
                    </tbody>
                </table>}
        </div>
    )
}