import { BezierMarker } from "../../../bezier/BezierMarker";
import { LineMarker } from "../../../line/LineMarker";
import { IALineProps } from "./types";

export const ALine: React.FC<IALineProps> = ({ position, line }: IALineProps) => {
    return (
        <>
            { position > 1 ? <LineMarker {...line} /> : <BezierMarker {...line} /> }
        </>
    )
};