import { SecuredService } from "../secured-service";
import { IApiResult } from "../api-result";
import { OrgLabelBundle } from "../../redux/build/connector/labelscheme/types";

export class LabelSchemeService extends SecuredService {
    private readonly baseUrl = '/api/labelscheme';

    public async getOrgBundles(): Promise<IApiResult<OrgLabelBundle[]>> {
        return await this.get(`${this.baseUrl}/org`);
    }
}


