import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../../../locales/types";
import { ICollapsibleDialogProps } from "../../../../../../../ui/dialog/collapsible/types";
import { IColor, IColorDialogProps } from "../../../../../../../ui/dialog/color/types";
import { NavigationBarReducer, setCurrentIndex } from "../../../../../../../ui/navigation/store/reducer";
import { INavigationBarProps } from "../../../../../../../ui/navigation/types";
import { IConnectorData } from "../../../../../../redux/build/connector/types";
import { updatePositionedConnectors } from "../../../../../../redux/build/reducers";
import { TrunkData } from "../../../../../../redux/build/types";
import { DialogReducer, showDialog } from "../../../../../../redux/dialog/reducer";
import { initialDialogState } from "../../../../../../redux/dialog/types";
import { AppDispatch } from "../../../../../../redux/reducers";
import { useSscBuildSession } from '../../../../../../redux/ssc/hooks';
import { sscConnectorTypeRecordSelector, sscDefaultTriggerColorSelector, sscDefaultTriggersColorSelector, sscSessionBusySelector } from "../../../../../../redux/ssc/selectors";
import { buildIdSelector, buildPositionsSelector, colorSelector, currentBuildCatalogCodeSelector, selectedTrunkSelector } from '../../../../../../selectors/build.selectors';
import { currentBuildSelector } from '../../../../../../selectors/root.selectors';
import { setShowConnectorReport, setShowLabelScheme, showTriggerManagement } from "../../../../redux/reducers";
import { showConnectorReportSelector } from '../../../../redux/selectors';
import { IToolItemProps } from "../../../../ToolItem";
import { setSelectedPosition, setToolbarDisplay } from "../../../footer/components/toolbar/redux/reducers";
import { toolbarSelectionSelector } from '../../../footer/components/toolbar/redux/selectors';
import { currentStatusSelector } from "../../../header/components/status/redux/selectors";
import { WorkspaceStatus } from "../../../header/components/status/redux/types";
import { unitsOfMeasureContainerUnitSelector } from '../../../header/components/units-of-measure-container/redux/selectors';
import { convertTo, getUnitsName, roundToDecimalBasedOnUnit, Unit } from "../../../header/components/units-of-measure-container/UnitsOfMeasure";
import { clearSelection, ConnectorReportContext, setLabelChanged, setSelectedRows, setTriggerColorsChanged } from '../../redux/reducers';
import { IConnectorRowData } from '../../redux/types';
import { IConnectorRowProps } from './row/types';
import { IConnectorReportTableHeaderProps } from "./types";
import { propagationOptionsSelector } from "../../../polarity/propagation/redux/selectors";

export const useConnectorReport = () => {
    const open = useSelector(showConnectorReportSelector);
    const trunk = useSelector(selectedTrunkSelector);
    const { selected: position } = useSelector(toolbarSelectionSelector);
    const currentStatus = useSelector(currentStatusSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const colors = useSelector(colorSelector)
    const defaultColor = useSelector(sscDefaultTriggerColorSelector(trunk.groups ? trunk.groups[0].type : ""))
    const currentBuild = useSelector(currentBuildSelector);
    const { state: reportState, dispatch: reportDispatch } = useContext(ConnectorReportContext);
    const { selectedRowIds } = reportState;
    const { navigationBarProps } = useNavigationBar(position);
    const { dispatch: navigationBarDispatch } = navigationBarProps.context;
    const { updateConnectorMaterials } = useSscBuildSession();
    const catalogCode = useSelector(currentBuildCatalogCodeSelector);
    const sscSessionBusy = useSelector(sscSessionBusySelector);
    const hasCatalogCode = catalogCode.length > 0;
    const locked = currentStatus === WorkspaceStatus.Locked;
    const currentBuildEditable = !hasCatalogCode && !locked && !sscSessionBusy;
    const propagationOptions = useSelector(propagationOptionsSelector);
    const storeDispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    
    const [focusedRow, setFocusedRow] = useState<IConnectorRowData | undefined>();

    const rows: IConnectorRowProps[] = useMemo(() => {
        if (!trunk || !Object.keys(trunk).length) {
            return [];
        }

        const rows = getRowsData(trunk, unit, colors, defaultColor);
        const focusedConnector = focusedRow ? getConnectorFromTrunk(trunk, focusedRow.groupIndex, focusedRow.connectorIndex) : undefined;

        const onRowFocus = (e: React.FocusEvent<HTMLTableRowElement>) => {
            const rowIndex = e.currentTarget.rowIndex;
            if (rows[rowIndex - 1].id === focusedRow?.id) {
                return;
            }
            setFocusedRow(rows[rowIndex - 1]);
        }

        const onRowBlur = () => {
            setFocusedRow(undefined);
        }

        const labelCallback = async (e: React.FormEvent<HTMLInputElement>) => {
            const { value } = e.currentTarget;
            if (!focusedRow) return;
            if (value === focusedRow.label) return;

            const updatedConnector: IConnectorData = { ...focusedConnector, label: value };
            if (currentBuild && currentBuild.id !== undefined && focusedRow) {
                const connectorsToUpdate: IConnectorData[] = [updatedConnector];
                await storeDispatch(updatePositionedConnectors(connectorsToUpdate, { buildId: currentBuild.id, options: propagationOptions }));
                reportDispatch(setLabelChanged(true));
            }
        }

        return rows.map((r) => {
            return {
                data: r,
                disabled: !currentBuildEditable,
                onFocus: onRowFocus,
                onBlur: onRowBlur,
                labelCallback,
            };
        })
    }, [trunk, unit, focusedRow, currentBuildEditable, colors, defaultColor, storeDispatch, currentBuild, propagationOptions, reportDispatch]);

    const { onPaletteClick, colorDialogProps, colorsUpdated } = useColorDialog(open, trunk!, rows, colors);

    const onClose = useCallback(() => {
        if (open) {
            batch(() => {
                storeDispatch(setShowConnectorReport(false));
                storeDispatch(setToolbarDisplay(true));
            });

            if (currentBuild && !sscSessionBusy) {
                if (colorsUpdated || reportState.labelChanged || reportState.triggerColorsChanged) {
                    updateConnectorMaterials(currentBuild);
                }
            }
        }
        reportDispatch(setLabelChanged(false));
        reportDispatch(setTriggerColorsChanged(false));
    }, [currentBuild, updateConnectorMaterials, open, sscSessionBusy, storeDispatch, colorsUpdated, reportState.labelChanged, reportState.triggerColorsChanged, reportDispatch]);

    const onManageClick = useCallback(() => {
        storeDispatch(setShowLabelScheme(true));
        storeDispatch(setShowConnectorReport(false));
    }, [storeDispatch]);

    const title = position === 0 ? t(LocalizationKeys.Feeder) : t(LocalizationKeys.AccessPointNumber, { position });
    const footnote = t(LocalizationKeys.StaggeringFootnote);
    const manageLabelScheme = t(LocalizationKeys.ManageLabelScheme).toLocaleUpperCase();
    const hasSelection = selectedRowIds.length > 0;
    const unitName = getUnitsName(unit, false, true);

    const tableHeaderProps: IConnectorReportTableHeaderProps = {
        connectorTitle: t(LocalizationKeys.Connector),
        groupTitle: t(LocalizationKeys.Group),
        connectorColorTitle: t(LocalizationKeys.ConnColor),
        connectorColorDisabled: !hasSelection,
        connectorColorClick: onPaletteClick,
        labelTitle: t(LocalizationKeys.Label),
        bTitle: `B${position} (${unitName})`,
    };

    const onMasterCheckboxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.checked) {
            reportDispatch(setSelectedRows(rows.map((r) => r.data.id)));
        }
        else {
            reportDispatch(clearSelection());
        }
    }, [reportDispatch, rows]);

    const isMasterChecked = selectedRowIds.length === rows.length;
    const masterCheckboxProps = {
        checked: isMasterChecked,
        className: isMasterChecked ? "checked" : "",
        indeterminate: !isMasterChecked && hasSelection,
        onChange: onMasterCheckboxChanged,
        disabled: !currentBuildEditable,
    };

    const collapsibleDialogProps: ICollapsibleDialogProps = {
        display: open,
        className: "connector-report-dialog",

        headerProps: {
            title,
            collapsible: true,
            closable: true,
            onClose,
        }
    };

    useEffect(() => {
        if (position < 0) {
            onClose();
        } else {
            navigationBarDispatch(setCurrentIndex(position));
        }
    }, [currentBuild, updateConnectorMaterials, navigationBarDispatch, onClose, position]);

    const onTriggerManagementClick = useCallback(() => {
        storeDispatch(showTriggerManagement(true));
        storeDispatch(setShowConnectorReport(false));
    }, [storeDispatch]);

    const triggerManagementButtonProps: IToolItemProps = {
        className: "trigger-management-button",
        text: t(LocalizationKeys.ManageConnectorColor),
        disabled: !currentBuildEditable,
        onClick: onTriggerManagementClick,
    };

    return {
        footnote,
        manageLabelScheme,
        tableHeaderProps,
        rows,
        onManageClick,
        masterCheckboxProps,
        colorDialogProps,
        navigationBarProps,
        collapsibleDialogProps,
        editDisabled: !currentBuildEditable,
        loading: sscSessionBusy,
        triggerManagementProps: {
            display: trunk.position === 0,
            buttonProps: triggerManagementButtonProps
        }
    };
}

function getRowsData(data: TrunkData, unit: Unit, colors: IColor[], defaultColor: string): IConnectorRowData[] {
    const { customBLength } = data;
    const groups = data.groups!;

    const rows: IConnectorRowData[] = [];

    let connectorId: number = 1;
    let groupStagger = 0;
    for (let i = 0; i < groups.length; i++) {
        let connectorGroup = groups[i];
        const lengthB = customBLength && connectorGroup.lengthB ? convertTo(connectorGroup.lengthB, unit).value! : convertTo(data.lengthB!, unit).value;
        for (let j = 0; j < connectorGroup.connectors.length!; j++) {
            let connector = connectorGroup.connectors[j];
            rows.push({
                id: connectorId,
                position: data.position!,
                groupIndex: connectorGroup.position!,
                connectorIndex: connector.position!,
                group: i + 1,
                color: colors.find(c => c.name === connector.color) ?? colors.find(c => c.name === defaultColor)!,
                label: connector.label,
                lengthB: roundToDecimalBasedOnUnit(lengthB, unit!),
                stagger: roundToDecimalBasedOnUnit(groupStagger, unit!),
                totalLength: roundToDecimalBasedOnUnit(lengthB + groupStagger, unit!),
            });
            connectorId++;
        }
        if (!customBLength) {
            groupStagger += convertTo(connectorGroup!.stagger!, unit).value;
        }
    }

    return rows;
}

const useColorDialog = (open: boolean, trunk: TrunkData, rows: IConnectorRowProps[], colors: IColor[]) => {
    const [state, dispatch] = useReducer(DialogReducer, initialDialogState);

    const { state: reportState, dispatch: reportDispatch } = useContext(ConnectorReportContext);
    const { selectedRowIds } = reportState;
    const storeDispatch = useDispatch<AppDispatch>();
    const connectorRecord = useSelector(sscConnectorTypeRecordSelector);
    const defaultTriggerColors = useSelector(sscDefaultTriggersColorSelector);
    const buildId = useSelector(buildIdSelector);
    const propagationOptions = useSelector(propagationOptionsSelector);

    const selectedRows = rows.filter(r => selectedRowIds.includes(r.data.id));
    const connectorColors = selectedRows.map(r => r.data.color).filter((value, index, self) => self.indexOf(value) === index);
    const currentColor = connectorColors.length === 1 ? connectorColors[0] : undefined;

    const [colorsUpdated, setColorsUpdated] = useState(false);

    useEffect(() => {
        if (!open) {
            if (colorsUpdated) {
                setColorsUpdated(false);
            }
            if (state.props.open) {
                dispatch(showDialog(false));
            }
            if (reportState.selectedRowIds.length > 0) {
                reportDispatch(clearSelection());
            }
        }
    }, [open, colorsUpdated, state.props.open, reportDispatch, reportState.selectedRowIds])

    const onPaletteClick = useCallback(() => {
        dispatch(showDialog(true));
    }, [dispatch]);

    const onClose = useCallback(() => {
        dispatch(showDialog(false));
        reportDispatch(clearSelection());
    }, [dispatch, reportDispatch]);

    const getConnectorsToUpdate = useCallback((newColor?: string) => {
        const connectorsToUpdate: IConnectorData[] = [];
        if (selectedRows.length) {
            for (const selectedRow of selectedRows) {
                const { groupIndex, connectorIndex } = selectedRow.data;
                let color = newColor;
                if (color === undefined) {
                    const connectorType = trunk.groups?.length && trunk.groups[0]?.type ? trunk.groups[0].type : ""
                    const connector = connectorRecord[connectorType];
                    color = connector ? defaultTriggerColors[connector.key].name : undefined
                }
                const updatedConnector: IConnectorData = {
                    ...getConnectorFromTrunk(trunk, groupIndex, connectorIndex),
                    color,
                };
                connectorsToUpdate.push(updatedConnector);
            }
        }
        return connectorsToUpdate;
    }, [selectedRows, trunk, connectorRecord, defaultTriggerColors]);

    const updateConnectorColors = useCallback(async(connectorsToUpdate: IConnectorData[]) => {
        if (buildId !== undefined) {
            await storeDispatch(updatePositionedConnectors(connectorsToUpdate, { buildId, options: propagationOptions }));
        }
        setColorsUpdated(true);
        onClose();
    }, [buildId, storeDispatch, propagationOptions, onClose])

    const onColorButtonClick = useCallback(async (color: IColor) => {
        const connectorsToUpdate: IConnectorData[] = getConnectorsToUpdate(color.name);
        if (connectorsToUpdate.length > 0) {
            updateConnectorColors(connectorsToUpdate);
        }
    }, [getConnectorsToUpdate, updateConnectorColors]);

    const onResetButtonClick = useCallback(() => {
        const connectorsToUpdate: IConnectorData[] = getConnectorsToUpdate();
        if (connectorsToUpdate.length > 0) {
            updateConnectorColors(connectorsToUpdate);
        }
    }, [getConnectorsToUpdate, updateConnectorColors]);

    const colorDialogProps: IColorDialogProps = useMemo(() => {
        return {
            context: { state, dispatch },
            preventOutsideDismiss: true,
            currentColor,
            className: "connector-report",
            displayFooter: true,
            colors: colors,
            onColorButtonClick,
            onResetButtonClick
        }
    }, [currentColor, colors, onColorButtonClick, onResetButtonClick, state]);

    return { colorsUpdated, onPaletteClick, colorDialogProps, onCloseColorDialog: onClose }
};

const useNavigationBar = (initialIndex: number) => {
    const storeDispatch = useDispatch();
    const [state, dispatch] = useReducer(NavigationBarReducer, { currentIndex: initialIndex, disabled: false });
    const { currentIndex, disabled } = state;
    const positions = useSelector(buildPositionsSelector);
    const { t } = useTranslation();
    const length = positions.length;

    const onNavigationButtonClick = useCallback((index: number) => {
        dispatch(setCurrentIndex(index));
        storeDispatch(setSelectedPosition(index));
    }, [storeDispatch, dispatch]);

    const onFirstClick = useCallback(() => {
        const firstIndex = 0;
        onNavigationButtonClick(firstIndex);
    }, [onNavigationButtonClick]);

    const onPreviousClick = useCallback(() => {
        const previousIndex = currentIndex ? currentIndex - 1 : 0;
        onNavigationButtonClick(previousIndex);
    }, [currentIndex, onNavigationButtonClick]);

    const onNextClick = useCallback(() => {
        const nextIndex = (currentIndex + 1) % length;
        onNavigationButtonClick(nextIndex);
    }, [currentIndex, onNavigationButtonClick, length]);

    const onLastClick = useCallback(() => {
        const lastIndex = length - 1;
        onNavigationButtonClick(lastIndex);
    }, [onNavigationButtonClick, length]);

    const buttons: IToolItemProps[] = positions.map(p => {
        if (p === 0) {
            return {
                tooltip: t(LocalizationKeys.Feeder),
                text: "F",
                onClick: () => onNavigationButtonClick(p)
            }
        }
        return {
            tooltip: t(LocalizationKeys.AccessPointNumber, { position: p }),
            text: p.toString(),
            onClick: () => onNavigationButtonClick(p)
        }
    });

    const navigationBarProps: INavigationBarProps = {
        context: { state, dispatch },
        displayThreshold: 8,
        onFirstClick,
        onPreviousClick,
        buttons,
        onNextClick,
        onLastClick
    };

    const previousDisabled = currentIndex === 0 || disabled;
    const nextDisabled = buttons ? currentIndex + 1 === buttons.length : disabled;

    return { onPreviousClick, onNextClick, previousDisabled, nextDisabled, navigationBarProps };
}

const getConnectorFromTrunk = (trunk: TrunkData, groupIndex: number, connectorIndex: number): IConnectorData | undefined => {
    const group = trunk.groups?.find(g => g.position === groupIndex);
    if (!group) {
        return undefined;
    }

    return { ...group.connectors.find(c => c.position === connectorIndex), tapPosition: trunk.position ?? 0, groupPosition: groupIndex };
};

export const getConnectorTypeFromTrunk = (trunk: TrunkData): string => {
    const { groups } = trunk;
    if (groups) {
        const { connectors } = groups[0];
        return connectors[0].type ?? "";
    }
    return "";
}