import { DocumentFields } from "../../../../../../redux/document/types";

export type ReportSettings = {
    overview?: IReportSection,
    feeder?: IReportSection,
    drops?: DropSection,
    connectorLabel?: IDiagramSection,
    polarity?: IDiagramSection,
    fiberMap?: IDiagramSection,
    buildPlan?: IDiagramSection,
} & DocumentFields;

export interface IReportSection {
    collapsed: boolean,
    include: boolean
}

export type DropSection = IReportSection & {
    dropPositions?: number[],
    includeAll: boolean,
}

export interface IDiagramSection {
    include: boolean,
    disabled: boolean;
}

export const initialReportSettings: ReportSettings = {
    siteLocation: "",
    drawn: "",
    notes: "",
    revision: "",
    overview: {
        collapsed: true,
        include: true
    },
    feeder: {
        collapsed: false,
        include: true,
    },
    drops: {
        dropPositions: [1],
        includeAll: true,
        collapsed: false,
        include: true
    },
    connectorLabel: {
        include: true,
        disabled: false,
    },
    polarity: {
        include: true,
        disabled: false,
    },
    fiberMap: {
        include: true,
        disabled: false,
    },
    buildPlan: {
        include: true,
        disabled: false,
    }
};