export const UoMMeters = 'm';
export const UoMCentimeters = 'cm';
export const UoMFeet = 'ft';
export const UoMInches = '"';

export const unitsIndex = [UoMMeters, UoMCentimeters, UoMFeet, UoMInches];
export const metricIndex = [UoMMeters, UoMCentimeters];
export const imperialIndex = [UoMFeet, UoMInches];

export const Units = {
    UoMMeters,
    UoMCentimeters,
    UoMFeet,
    UoMInches,
} as const;

export type Unit = typeof Units[keyof typeof Units]

export const isMetric = (x: string): x is Unit => metricIndex.includes(x);
export const isImperial = (x: string): x is Unit => imperialIndex.includes(x);

export interface IUnitOfMeasure {
    id?: number,
    value: number,
    unit: Unit
}

export const measureEqual = (m1: IUnitOfMeasure | undefined, m2: IUnitOfMeasure | undefined) => {
    return m1 && m2 
        ? m1.id === m2.id && m1.unit === m2.unit && m1.value === m2.value 
        : m1 === undefined && m2 === undefined;
}

export const getUnitsName = (unit: Unit, capitalize: boolean = false, abbreviate: boolean = false): string => {
    let name = "";
    switch (unit) {
        case UoMMeters:
            name = abbreviate ? "m" : "meters";
            break;
        case UoMCentimeters:
            name = abbreviate ? "cm" : "centimeters";
            break;
        case UoMFeet:
            name = abbreviate ? "ft" : "feet";
            break;
        case UoMInches:
            name = abbreviate ? "in" : "inches";
            break;
        default:
            name = "";
            break;
    }
    return capitalize === true ? name[0].toUpperCase() + name.slice(1) : name;
}

function genericConversion(conversion: IConversion) {
    const { ratio, reverse, sourceUnit: start, targetUnit: end } = conversion;
    const generic = (from: IUnitOfMeasure, to: Unit): IUnitOfMeasure => {
        const { unit, value } = from;
        if (unit === start && to === end) {
            return { value: ratio * value, unit: end };
        }
        else if (unit === end && to === start) {
            return { value: reverse * value, unit: start };
        }
        else {
            return { value: value, unit: to };
        }
    }
    return generic;
}

interface IConversion { ratio: number, reverse: number, sourceUnit: Unit, targetUnit: Unit }
const meterToCentimeterConversion: IConversion = {
    ratio: 100,
    reverse: 0.01,
    sourceUnit: UoMMeters,
    targetUnit: UoMCentimeters
}

const feetToInchesConversion: IConversion = {
    ratio: 12,
    reverse: 0.08333333,
    sourceUnit: UoMFeet,
    targetUnit: UoMInches
}

const inchesToCentimetersConversion: IConversion = {
    ratio: 2.54,
    reverse: 0.3937008,
    sourceUnit: UoMInches,
    targetUnit: UoMCentimeters
}

const metersToCentimeters = genericConversion(meterToCentimeterConversion);
const feetToInches = genericConversion(feetToInchesConversion);
const inchesToCentimeters = genericConversion(inchesToCentimetersConversion);

// converts imperial to metric and vice versa
const imperialToMetric = (from: IUnitOfMeasure, to: Unit): IUnitOfMeasure => {
    let { unit } = from;
    let result: IUnitOfMeasure;
    if (isMetric(unit)) {
        result = metersToCentimeters(from, UoMCentimeters);
        result = inchesToCentimeters(result, UoMInches);
        return feetToInches(result, to);
    } else {
        result = feetToInches(from, UoMInches);
        result = inchesToCentimeters(result, UoMCentimeters);
        return metersToCentimeters(result, to);
    }
}

// converts value to deseired unit
export const convertTo = (from: IUnitOfMeasure | undefined, to?: Unit): IUnitOfMeasure => {
    if (!from) {
        return { value: -1, unit: Units.UoMInches };
    }

    const toUnit = to ?? Units.UoMInches;
    let result: IUnitOfMeasure;
    if (isMetric(from.unit) && isMetric(toUnit)) {
        result = metersToCentimeters(from, toUnit);
    } else if (isImperial(from.unit) && isImperial(toUnit)) {
        result = feetToInches(from, toUnit);
    } else {
        result = imperialToMetric(from, toUnit);
    }
    return { unit: result.unit, value: result.value };
}

// converts value to desired unit and rounds to the 2nd decimal point if needed
export const convertToDisplay = (from: IUnitOfMeasure, to: Unit): string => {
    return roundToDecimalBasedOnUnit(convertTo(from, to).value, to);
}

export const decimalPlacesBasedOnUnit = (unit: Unit): number => {
    switch (unit) {
        case Units.UoMMeters:
            return 2;
        case Units.UoMFeet:
            return 1;
        case Units.UoMCentimeters:
        case Units.UoMInches:
        default:
            return 0;
    }
}

export const getDecimalPlaces = (value: string): number => {
    return value.split('.')[1]?.length ?? 0;
}

// converts value to desired decimal point based on unit
export const roundToDecimalBasedOnUnit = (value: number, unit: Unit): string => {
    if (value === 0) {
        return value.toString();
    }

    const decimalPlaces = decimalPlacesBasedOnUnit(unit);
    return roundToDecimalPoint(value, decimalPlaces, false);
}

// returns a string value to desired decimal point with/without trailing zeros
export const roundToDecimalPoint = (value: number, decimalPoint: number, trailingZeros: boolean): string => {
    if (trailingZeros) {
        let pow = Math.pow(10, decimalPoint);
        return (Math.ceil(value * pow) / pow).toFixed(decimalPoint);
    } else {
        return Number(value.toFixed(decimalPoint)).toString();
    }
}

export function duplicateLength(length: IUnitOfMeasure): IUnitOfMeasure {
    const { id, ...lengthData } = length;
    return { ...lengthData }
}