import { DropSection } from "../../types";
import { useDrawingSection } from "../drawing-section/hooks";
import { useRadioGroup, useRadioInput } from "../../../../../../../../../ui/radio/hooks";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useInputField } from "../../../../../../../../../ui/input/hooks";
import { InputFieldValue } from "../../../../../../../../../ui/input/types";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../../../../locales/types";
import { currentBuildSelector } from "../../../../../../../../selectors/root.selectors";

export const printDropRadioOptions = [LocalizationKeys.PrintAllAP, LocalizationKeys.SpecificAP]
export const printDropHelperText = [LocalizationKeys.PrintAPHint, LocalizationKeys.InvalidEntry, LocalizationKeys.InvalidAPs]

export const useDropSection = (options: DropSection) => {
    const { t } = useTranslation();
    const [radioOption, setRadioOption] = useState<string>(options.includeAll ? t(printDropRadioOptions[0]) : t(printDropRadioOptions[1]));

    useEffect(() => {
        setRadioOption(options.includeAll ? t(printDropRadioOptions[0]) : t(printDropRadioOptions[1]));
    }, [options.includeAll, t]);

    const dropSection = useDrawingSection('drop', `${t(LocalizationKeys.TrunkSide, {trunk: t(LocalizationKeys.AccessPoint)})}`, options)
    const printGroup = useRadioGroup('drop print', radioOption, !dropSection.checkBox.checked);
    const printAllRadio = useRadioInput('drop', t(printDropRadioOptions[0]), printGroup.value, dropSection.checkBox.disabledChildren)
    const printDropRadio = useRadioInput('drop', t(printDropRadioOptions[1]), printGroup.value, dropSection.checkBox.disabledChildren)
    const printDropField = usePrintDropField(printGroup.value as string, "1");


    return { ...dropSection, printGroup, printAllRadio, printDropRadio, printDropField }
}

export const usePrintDropField = (radioOption: string, value: InputFieldValue) => {
    const { t } = useTranslation();
    
    const { destinations } = useSelector(currentBuildSelector)!;

    const [selectedDrops, setSelectedDrops] = useState<number[]>([]);
    const [helperText, setHelperText] = useState<string>(t(printDropHelperText[0]));
    const [isValid, setIsValid] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [fieldValue, setFieldValue] = useState(value);

    const printDropInputField = useInputField(t(printDropRadioOptions[1]), fieldValue, 14);

    const validateDrops = useCallback((inputDrops: number[]) => {
        let valid = true;

        for (let i = 0; i < inputDrops.length; i++) {
            const drop = inputDrops[i];
            if (drop > destinations.length || drop === 0) {
                valid = false;
                break;
            }
        }

        setHelperText(valid ? t(printDropHelperText[0]) : t(printDropHelperText[2]))
        setIsValid(valid)

        return valid;
    }, [destinations, t])

    // validate input
    useEffect(() => {
        const stringValue = printDropInputField.value as string;
        let parsedValue: number[] = [];
        parsedValue = parseDropSelection(stringValue)
        if (parsedValue.length > 0) {
            const valid = validateDrops(parsedValue);
            setIsValid(valid)
        }
        else {
            setIsValid(false)
            setHelperText(t(printDropHelperText[1]))
        }

        setSelectedDrops(parsedValue)

    }, [printDropInputField.value, validateDrops, t])


    useEffect(() => {
        if (radioOption.valueOf() === t(printDropRadioOptions[0]).valueOf()) {
            setIsValid(true);
            setDisabled(true)
            setFieldValue("1");
        }
        else {
            setDisabled(false)
        }
    }, [radioOption, t])

    useEffect(() => {
        setFieldValue(printDropInputField.value || "")
    }, [printDropInputField.value])

    return { ...printDropInputField, helperText, isValid, disabled, selectedDrops, value: fieldValue }
}

function parseDropSelection(field: string): number[] {
    let seperatedValues = field.split(',');
    let dropPositions: number[] = [];

    for (let i = 0; i < seperatedValues.length; i++) {
        const value = seperatedValues[i];
        const rangeValue = value.split('-');
        let dropNumber = parseInt(seperatedValues[i], 10);
        if (rangeValue.length === 2) { // parse range
            const rangeStart = parseInt(rangeValue[0])
            const rangeEnd = parseInt(rangeValue[1])
            const length = rangeEnd - rangeStart;

            if (rangeStart && rangeEnd
                && compareNumberString(rangeStart, rangeValue[0]) && compareNumberString(rangeStart, rangeValue[1])
                && rangeStart > 0 && length > 0 && length < 100) {

                const rangeStartInt = Math.floor(rangeStart);
                const rangeEndInt = Math.floor(rangeEnd);
                const range = Array(rangeEndInt - rangeStartInt + 1).fill(0).map((_, i) => rangeStartInt + i)
                dropPositions.push(...range);
            }
            else { // invalid entry
                return [];
            }
        }
        else if (dropNumber && dropNumber.toString() === seperatedValues[i]) {
            dropPositions.push(dropNumber);
        }
        else if (value.length !== 0) { // invalid entry
            return [];
        }
    }

    return dropPositions;
}

function compareNumberString(value: number, stringValue: string) {
    return !(value.toString() > stringValue);
}