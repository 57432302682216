import React from 'react';
import { ClickAwayListener } from '@mui/material';
import { useMoreMenuContainer } from './hooks';
import { MenuItem } from './components/menu-item/MenuItem';

import './MoreMenuContainer.scss';

export const MoreMenuContainer = () => {
    const { display, items, close } = useMoreMenuContainer();

    return !display ? null : (
        <ClickAwayListener onClickAway={close}>
            <div className="more-menu-container toggle-pointer-events">
                <ul>
                    {items.map((i) => <MenuItem {...i} /> )}
                </ul>
            </div>
        </ClickAwayListener>
    );
};
