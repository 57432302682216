import { createSlice } from "@reduxjs/toolkit";
import { initialAppLabelScheme } from "./types";
import { setOrgBundlesAction } from "./actions";

const labelSchemeSlice = createSlice({
    name: 'labelScheme',
    initialState: initialAppLabelScheme,
    reducers: {
        setOrgBundles: setOrgBundlesAction
    }
})

export const LabelSchemeReducer = labelSchemeSlice.reducer;
export const { setOrgBundles } = labelSchemeSlice.actions;