import { AlertPalettes } from "@orbit/snackbar";
import { useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from '../../../locales/types';
import { createdLoadedBuild } from "../../components/overlay/components/header/components/status/hooks";
import { setStatusState } from "../../components/overlay/components/header/components/status/redux/reducer";
import { WorkspaceStatus } from "../../components/overlay/components/header/components/status/redux/types";
import { setNotification } from "../../components/overlay/components/notification/store/reducer";
import { getDefaultBuildDescription } from "../../components/overlay/components/projects/components/drawer/components/row/build-info/hooks";
import { setTotalBuildCount } from "../../components/overlay/components/projects/redux/reducers";
import { ConfigurationType } from "../../components/overlay/components/wizard/redux/types";
import { currentBuildConfigurationTypeSelector } from "../../selectors/build.selectors";
import { ToBuildData, ToBuildDTO } from "../../services/build-service";
import { edsSessionService, EDSSessionService } from '../../services/eds-session/eds-session-service';
import { buildDataToEDSSessionRequest } from "../../services/eds-session/types";
import { setBuildPolarity } from "../build/connector/polarity/reducer";
import { IConnectorData, IConnectorGroupData } from "../build/connector/types";
import { setCurrentBuild, setCurrentBuildSessionId, updateConnectorsAsync } from "../build/reducers";
import { IBuildData } from "../build/types";
import { totalBuildCountSelector } from "../project-manager/selectors";
import { AppDispatch } from "../reducers";
import { setBuildSession, setConfigSessionBusy, setConfigurationSession, setSessionBusy, setSessionError } from "./reducer";
import { sscAllConfigSessionSelector, sscConfigSessionIdSelector, sscConfigSessionSelector, sscDefaultBuildPolaritiesSelector } from "./selectors";
import { IConfigSession, IDrop, sscUpdatedEventName } from "./types";

const sscUpdatedEvent = new Event(sscUpdatedEventName);

export const useSscConfigSession = () => {
    const presetConfigType = useSelector(currentBuildConfigurationTypeSelector)
    const sscSession = useSelector(sscConfigSessionSelector(presetConfigType));
    const configSessionId = useSelector(sscConfigSessionIdSelector)
    const sscConfigSessions = useSelector(sscAllConfigSessionSelector)
    const dispatch = useDispatch();

    const createConfigSession = useCallback(async () => {
        try {
            const sscService = new EDSSessionService();
            dispatch(setConfigSessionBusy(true))
            const res = !sscSession.sessionId.length ? await sscService.createConfigSession() : { succesful: true, data: sscSession }
            if (res.succesful && res.data) {
                dispatch(setConfigurationSession(res.data))
                dispatch(setConfigSessionBusy(false))
                dispatch(setStatusState(WorkspaceStatus.Loading));
                return res.data;
            }
        }
        catch {
            dispatch(setStatusState(WorkspaceStatus.Loading));
            dispatch(setConfigSessionBusy(false))
        }
    }, [dispatch, sscSession])

    const updateConfigSessionConfigurationType = useCallback(async (config = ConfigurationType.Patching) => {
        let session: IConfigSession | undefined = sscConfigSessions[config]
        if (!session) {
            const service = new EDSSessionService();
            dispatch(setConfigSessionBusy(true))
            try {
                const res = await service.updateConfigurationType({ sessionId: configSessionId, configurationType: config });
                if (res.succesful && res.data) {
                    dispatch(setConfigurationSession(res.data));
                    session = res.data
                }
                dispatch(setConfigSessionBusy(false))
            }
            catch {
                dispatch(setConfigSessionBusy(false))
            }
        }

        return session;
    }, [sscConfigSessions, configSessionId, dispatch])

    return { createConfigSession, updateConfigSessionConfigurationType, sscSession }
}

export const useSscBuildSession = () => {
    const defaultSSCBuildPolarity = useSelector(sscDefaultBuildPolaritiesSelector)
    const totalBuildCount = useSelector(totalBuildCountSelector)
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const updateSscBuildInfo = useCallback(async (build: IBuildData) => {
        const sessionId = build.sessionId;
        if (!sessionId || !build.id) return;

        dispatch(setSessionBusy(true));
        const description = build.description?.length ? build.description : getDefaultBuildDescription(build);
        try {
            await edsSessionService.updateBuildInfo({ sessionId, description });
        }
        catch (_) {
            dispatch(setNotification({ message: t(LocalizationKeys.ErrorUpdatingBuildInfo), palette: AlertPalettes.error }));
        }
        dispatch(setSessionBusy(false));
    }, [dispatch, t]);

    const applyDefaultConnectorLabelSchemes = useCallback(async (build: IBuildData) => {
        dispatch(setSessionBusy(true));
        dispatch(setStatusState(WorkspaceStatus.Saving))
        const sscRes = await new EDSSessionService().applyConnectorLabelSchemes({
            build: { ...ToBuildDTO(build), description: build.description?.length ? build.description : getDefaultBuildDescription(build) },
            feederLabelScheme: "ACC001", // ACC001 is temporarily hardcoded here until the SSC model is updated. Once it is, replace with defaultLabelScheme exported from eds-session-service.ts
            distributionLabelScheme: "ACC001", // ACC001 is temporarily hardcoded here until the SSC model is updated. Once it is, replace with defaultLabelScheme exported from eds-session-service.ts
            polarityConfigurations: defaultSSCBuildPolarity.map(d => {
                return { ...d, id: 0 }
            })
        });

        if (sscRes.succesful && sscRes.data) {
            const { build, sessionId, warnings, configStatus, polarityConfigurations } = sscRes.data;
            dispatch(setBuildSession({ buildId: build?.id, sessionId, configStatus, warnings }));
            // dispatch(setConfigColors({ connectorColors, defaultConnectorColors }))
            const loadedBuild = createdLoadedBuild(ToBuildData(build));
            batch(() => {
                dispatch(setTotalBuildCount(totalBuildCount + 1))
                dispatch(setBuildPolarity(polarityConfigurations))
                dispatch(setCurrentBuild(loadedBuild));
            });
        }
        else {
            const warnings = { solutionText: sscRes.reason, componentName: t(LocalizationKeys.SscModel), problemText: t(LocalizationKeys.SscModelError) };
            dispatch(setSessionError({ buildId: build?.id, warnings }));
        }

        dispatch(setStatusState(WorkspaceStatus.Saved))
        dispatch(setSessionBusy(false));
    }, [dispatch, defaultSSCBuildPolarity, t, totalBuildCount]);

    const updateBuildSession = useCallback(async (build: IBuildData, applyDefaultLabels: boolean = false, saveDefaultColors: boolean = false, reload: boolean = true) => {
        const buildId = build.id ?? 0;
        if (applyDefaultLabels) {
            await applyDefaultConnectorLabelSchemes(build);
        }
        else if ((!build.catalogCode || !build.catalogCode.length)) {
            const sessionId = build.sessionId;
            dispatch(setSessionBusy(true))
            dispatch(setStatusState(WorkspaceStatus.Saving))
            const buildData = { ...build, description: build.description?.length ? build.description : getDefaultBuildDescription(build) }
            edsSessionService
                .updateEDSCharacteristic(buildDataToEDSSessionRequest(sessionId, buildData, reload))
                .then(res => {
                    dispatch(setSessionBusy(false))
                    dispatch(setStatusState(WorkspaceStatus.Saved))
                    if (res.data) {
                        const { session } = res.data;
                        const overlay = document.getElementsByClassName('overlay')[0];
                        if (overlay) {
                            overlay.dispatchEvent(sscUpdatedEvent);
                        }

                        batch(() => {
                            if (!build.sessionId) {
                                dispatch(setCurrentBuildSessionId(session.sessionId));
                            }
                            // dispatch(setConfigColors({ connectorColors, defaultConnectorColors }))
                            dispatch(setBuildSession({
                                buildId: buildId,
                                sessionId: session.sessionId,
                                configStatus: session.configStatus,
                                warnings: session.warnings
                            }));
                        })
                        if (saveDefaultColors) {
                            const { feederEnd, distribution } = session;
                            const updatedConnectors = getUpdatedConnectors(build, feederEnd.drop, distribution.accessPoints.flatMap(a => a.drop));
                            dispatch(updateConnectorsAsync(updatedConnectors));
                        }
                    }
                    else {
                        dispatch(setStatusState(WorkspaceStatus.Saved))
                        const warnings = { solutionText: res.reason, componentName: t(LocalizationKeys.SscModel), problemText: t(LocalizationKeys.SscModelError) };
                        dispatch(setSessionError({ buildId, warnings }));
                    }
                })
                .catch((_) => dispatch(setSessionBusy(false)));
        }
    }, [applyDefaultConnectorLabelSchemes, dispatch, t]);

    const updateConnectorMaterials = useCallback(async (build: IBuildData) => {
        const edsSessionService = new EDSSessionService();
        const buildId = build.id ?? 0;
        const sessionId = build.sessionId;
        const buildData = { ...build, description: build.description?.length ? build.description : getDefaultBuildDescription(build) }
        dispatch(setSessionBusy(true))
        dispatch(setStatusState(WorkspaceStatus.Saving))
        try {

            const res = await edsSessionService.updateConnectorsCharacteristic(buildDataToEDSSessionRequest(sessionId, buildData))
            if (res.succesful && res.data) {
                const { session } = res.data;
                batch(() => {
                    if (!build.sessionId) {
                        dispatch(setCurrentBuildSessionId(session.sessionId));
                    }
                    dispatch(setBuildSession({
                        buildId: buildId,
                        sessionId: session.sessionId,
                        configStatus: session.configStatus,
                        warnings: session.warnings
                    }));
                    dispatch(setSessionBusy(false))
                    dispatch(setStatusState(WorkspaceStatus.Saved))
                })
            }
            else {
                dispatch(setStatusState(WorkspaceStatus.Saved))
                const warnings = { solutionText: res.reason, componentName: t(LocalizationKeys.SscModel), problemText: t(LocalizationKeys.SscModelError) };
                dispatch(setSessionError({ buildId, warnings }));
            }
        }
        catch {
            dispatch(setStatusState(WorkspaceStatus.Saved))
            const warnings = { solutionText: t(LocalizationKeys.SScModelErrorSolution), componentName: t(LocalizationKeys.SscModel), problemText: t(LocalizationKeys.SscModelError) };
            dispatch(setSessionError({ buildId, warnings }));
            dispatch(setSessionBusy(false))
        }
    }, [t, dispatch])

    const updateDropMaterials = useCallback(async (build: IBuildData, dropPosition: number) => {
        const edsSessionService = new EDSSessionService();
        const buildId = build.id ?? 0;
        const sessionId = build.sessionId;
        const buildData = { ...build, description: build.description?.length ? build.description : getDefaultBuildDescription(build) }
        dispatch(setSessionBusy(true))
        dispatch(setStatusState(WorkspaceStatus.Saving))
        try {

            const res = await edsSessionService.updateDropCharacteristic(buildDataToEDSSessionRequest(sessionId, buildData), dropPosition)
            if (res.succesful && res.data) {
                const { session } = res.data;
                batch(() => {
                    if (!build.sessionId || !build.sessionId.length) {
                        dispatch(setCurrentBuildSessionId(session.sessionId));
                    }
                    dispatch(setBuildSession({
                        buildId: buildId,
                        sessionId: session.sessionId,
                        configStatus: session.configStatus,
                        warnings: session.warnings
                    }));
                    dispatch(setSessionBusy(false))
                    dispatch(setStatusState(WorkspaceStatus.Saved))
                })
            }
            else {
                dispatch(setStatusState(WorkspaceStatus.Saved))
                const warnings = { solutionText: res.reason, componentName: t(LocalizationKeys.SscModel), problemText: t(LocalizationKeys.SscModelError) };
                dispatch(setSessionError({ buildId, warnings }));
            }
        }
        catch {
            dispatch(setStatusState(WorkspaceStatus.Saved))
            const warnings = { solutionText: t(LocalizationKeys.SScModelErrorSolution), componentName: t(LocalizationKeys.SscModel), problemText: t(LocalizationKeys.SscModelError) };
            dispatch(setSessionError({ buildId, warnings }));
            dispatch(setSessionBusy(false))
        }
    }, [t, dispatch])

    return { updateBuildSession, updateSscBuildInfo, updateConnectorMaterials, updateDropMaterials };
};


function getUpdatedConnectors(build: IBuildData, feederEndDrop: IDrop, destinationDrops: IDrop[]): IConnectorData[] {
    const updatedSrcConnectors = updateDefaultColors(build.source.groups, feederEndDrop);
    const updatedDstConnectors = build.destinations.flatMap((d, i) => updateDefaultColors(d.groups, destinationDrops[i]));
    return [...updatedSrcConnectors, ...updatedDstConnectors];
}

function updateDefaultColors(groups: IConnectorGroupData[], drop: IDrop): IConnectorData[] {
    const updatedConnectors: IConnectorData[] = [];
    for (let i = 0; i < groups.length; i++) {
        const connectors = groups[i].connectors;
        for (let j = 0; j < connectors.length; j++) {
            const furcationGroup = drop.furcationGroups[i];
            if (furcationGroup.furcationGroupLegs) {
                const connector = furcationGroup.furcationGroupLegs[j].connectors[0];
                if (connector) {
                    const sscDefaultColor = connector.defaultColor.name;
                    if (connectors[j].defaultColor !== sscDefaultColor || !connectors[j].color) {
                        const updatedConnector: IConnectorData = {
                            ...connectors[j],
                            stagger: connectors[j].stagger,
                            color: connectors[j].color ?? sscDefaultColor,
                            defaultColor: sscDefaultColor
                        };
                        updatedConnectors.push(updatedConnector);
                    }
                }
            }
        }
    }

    return updatedConnectors;
}