import React from 'react';

import './DrawerPageTitle.scss'

export const DrawerPageTitle = (props: { title: string, catalogCode?: string }) => {
    const { title, catalogCode } = props;
    return (
        <div className="drawer-page-title">
            <div className="title-label">{title}</div>
            {catalogCode?.length ? <div className="part-number-label">{catalogCode}</div> : null}
        </div>
    );
}