import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux"
import { LocalizationKeys } from "../../../../../../../locales/types";
import { ICollapsibleDialogProps } from "../../../../../../../ui/dialog/collapsible/types"
import { schemesSelector } from "../../../../../../redux/build/connector/labelscheme/selectors";
import { AppDispatch } from "../../../../../../redux/reducers";
import { selectedTrunkSelector } from "../../../../../../selectors/build.selectors";
import { currentBuildSelector } from "../../../../../../selectors/root.selectors";
import { setShowConnectorReport, setShowLabelScheme } from "../../../../redux/reducers";
import { showLabelSchemeSelector } from "../../../../redux/selectors";
import { setStatusState } from "../../../header/components/status/redux/reducer";
import { currentStatusSelector } from "../../../header/components/status/redux/selectors";
import { WorkspaceStatus } from "../../../header/components/status/redux/types";
import { propagationOptionsSelector } from "../../../polarity/propagation/redux/selectors";
import { applyLabelScheme } from "../../../projects/redux/reducers";
import { ConnectorReportContext, setLabelChanged } from "../../redux/reducers";
import { LabelCustomizationReducer, setRackNumber, setReverseOrder } from "./components/customization/reducers/reducer";
import { ILabelCustomizationContext, initialLabelCustomizationState } from "./components/customization/reducers/types";
import { ITemplateProps } from "./components/template/types";
import { DefaultSequences, getCharFromNumber, LabelCustomizationOptions, SchemeTabs } from "./types";

export const useLabelScheme = () => {
    const display = useSelector(showLabelSchemeSelector);
    const schemes = useSelector(schemesSelector);
    const isFeeder = !!(schemes[0]?.side === "feeder");
    const isDrop = !!(schemes[0]?.side === "drop");
    const currentBuild = useSelector(currentBuildSelector);
    const trunk = useSelector(selectedTrunkSelector);
    const currentStatus = useSelector(currentStatusSelector);
    const disabled = currentStatus === WorkspaceStatus.Locked || currentStatus === WorkspaceStatus.Saving || currentStatus === WorkspaceStatus.Busy;
    const [state, dispatch] = useReducer(LabelCustomizationReducer, initialLabelCustomizationState);
    const { rackNumber, reverseOrder } = state;
    const customizationContext: ILabelCustomizationContext =  { state, dispatch };
    const { dispatch: connectorReportDispatch } = useContext(ConnectorReportContext);
    const propagationOptions = useSelector(propagationOptionsSelector);
    const { t } = useTranslation();
    const storeDispatch = useDispatch<AppDispatch>();

    const [selectedScheme, setSelectedScheme] = useState(-1);
    const [currentTab, setCurrentTab] = useState(SchemeTabs.Examples);
    const [applyAll, setApplyAll] = useState(true);

    useEffect(() => {
        if (display) {
            setSelectedScheme(schemes[0].id);
        }
    }, [display, schemes]);

    const onClose = useCallback(() => {
        dispatch(setReverseOrder(false));
        dispatch(setRackNumber(1));
        setSelectedScheme(-1);
        setCurrentTab(SchemeTabs.Examples);
        storeDispatch(setShowLabelScheme(false));
        storeDispatch(setShowConnectorReport(true));
    }, [dispatch, storeDispatch]);

    const dialogProps: ICollapsibleDialogProps = {
        className: "label-scheme-dialog",
        display,
        headerProps: {
            title: isFeeder ? `${t(LocalizationKeys.Feeder)} ${t(LocalizationKeys.LabelScheme)}` : `${t(LocalizationKeys.AccessPoint)} ${t(LocalizationKeys.LabelScheme)}`,
            closable: true,
            onClose
        }
    };

    const onSelectTemplate = useCallback((templateId: number) => {
        setSelectedScheme(templateId);
        setCurrentTab(SchemeTabs.Examples);
    }, [])

    const templates: ITemplateProps[] = schemes.map(s => {
        const desc = s.sequences.map(s => s.name).join(" + ");
        return { id: s.id, name: s.name, desc, selected: selectedScheme === s.id, onClick: onSelectTemplate };
    });
    const schemeProps = {
        title: t(LocalizationKeys.SchemeTemplates),
        templates
    };

    const onTabChange = useCallback((e, currentTab) => {
        setCurrentTab(currentTab);
    }, []);

    const exampleTab = {
        value: SchemeTabs.Examples,
        label: t(LocalizationKeys.Examples),
        disableRipple: true
    };

    const schemeIndex = schemes.findIndex(s => s.id === selectedScheme);
    const customizationTab = {
        value: SchemeTabs.Customization,
        label: t(LocalizationKeys.Customization),
        disabled: schemeIndex === 0 || schemeIndex === schemes.length - 1,
        disableRipple: true
    };

    // Generates basic hardcoded examples to give a general idea to the user
    const labels = useMemo(() => {
        const labels: string[] = [];
        const scheme = schemes.find(s => s.id === selectedScheme);
        if (scheme) {
            for (let i = 0; i < 6; i++) {
                let label = "";
                for (const sequence of scheme.sequences.slice().sort((a, b) => a.position - b.position)) {
                    if (sequence.symbol.toString().length > 1) {
                        switch (sequence.symbol) {
                            case DefaultSequences.AccessPoint:
                                let ap = trunk.position?.toString() ?? "0";
                                if (sequence.position === 0 && ap.length > 1) {
                                    ap = `0${ap}`;
                                }
                                label += ap;
                                break;
                            case DefaultSequences.Rack:
                                let rack = 0;
                                if (scheme.side === "feeder") {
                                    rack = reverseOrder ? rackNumber - i : rackNumber + i;
                                } else {
                                    rack = reverseOrder ? rackNumber - Math.floor(i * 0.5) : Math.floor(i * 0.5) + rackNumber
                                }
                                rack = Math.max(rack, 1);
                                label += rack >= 10 ? rack : `0${rack}`;
                                break;
                            case DefaultSequences.LegABCD:
                            case DefaultSequences.LegAC:
                                let acNumber = 0;
                                if (scheme.side === "feeder") {
                                    acNumber = Math.floor(i / 2) * sequence.increment;
                                } else {
                                    acNumber = i > 0 ? sequence.increment * (i % 2) : i;
                                }
                                label += `${sequence.prefix}${getCharFromNumber(acNumber)}`;
                                break;
                            case DefaultSequences.LegBD:
                                let bdNumber = 0;
                                if (scheme.side === "feeder") {
                                    bdNumber = Math.floor(i / 2) * sequence.increment;
                                } else {
                                    bdNumber = i > 0 ? sequence.increment * (i % 2) : i;
                                }
                                label += `${sequence.prefix}${getCharFromNumber(bdNumber + 1)}`;
                                break;
                            default: // Connector
                                let prefix = "";
                                let suffix = "";
                                if (sequence.position === 0) {
                                    prefix = "000";
                                } else {
                                    if (scheme.side === "feeder") {
                                        prefix = "00";
                                    } else {
                                        if (sequence.position === scheme.sequences.length - 1) {
                                            prefix = "0";
                                        }
                                    }
                                }
                                label += `${prefix}${i + 1}${suffix}`;
                                break;
                        }
                    } else {
                        label += sequence.prefix;
                    }
                }
                labels.push(label);
            }
        }
        return labels;
    }, [schemes, selectedScheme, trunk.position, reverseOrder, rackNumber]);

    const onApplyAllChange = useCallback((e, checked) => {
        setApplyAll(checked);
    }, []);

    const onCancel = useCallback(() => {
        onClose();
    }, [onClose]);

    const cancelButtonProps = {
        disabled,
        onClick: onCancel
    };

    const onApplyScheme = useCallback(async () => {
        if (currentBuild && currentBuild.id !== undefined && trunk.position !== undefined) {
            storeDispatch(setStatusState(WorkspaceStatus.Saving));
            const options: LabelCustomizationOptions = { applyAll, rackNumber, reverseOrder };
            await storeDispatch(applyLabelScheme(currentBuild, selectedScheme, trunk.position, options, propagationOptions));
            storeDispatch(setStatusState(WorkspaceStatus.Saved));
            connectorReportDispatch(setLabelChanged(true));
        }
        onClose();
    }, [currentBuild, selectedScheme, trunk.position, applyAll, rackNumber, reverseOrder, storeDispatch, propagationOptions, connectorReportDispatch, onClose]);

    const applyButtonProps = {
        disabled,
        onClick: onApplyScheme
    };

    return { 
        dialogProps,
        schemeProps,
        exampleProps: {
            tabNavigation: {
                value: currentTab,
                onChange: onTabChange
            },
            tabs: [exampleTab, customizationTab],
            showLabels: currentTab === SchemeTabs.Examples,
            labels
        },
        customizationContext,
        applyAllProps: {
            display: isDrop,
            label: t(LocalizationKeys.ApplyToAllAPs),
            checkboxProps: {
                disabled,
                value: applyAll,
                checked: applyAll,
                onChange: onApplyAllChange
            }
        },
        cancelProps: {
            buttonProps: cancelButtonProps,
            label: t(LocalizationKeys.Cancel)
        },
        applyProps: {
            buttonProps: applyButtonProps,
            label: t(LocalizationKeys.ApplyScheme)
        }
    };
}