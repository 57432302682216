import { InteractiveRectangle } from "../../../../../decorators/bounds/components/rectangle/InteractiveRectangle";
import { useConnectorHighlight } from "./hooks";
import { IConnectorHighlightProps } from "./types";

export const ConnectorHighlight: React.FC<IConnectorHighlightProps> = (props) => {
    const { display, rectangleProps } = useConnectorHighlight(props);
    if (!display) {
        return null;
    }

    return <InteractiveRectangle {...rectangleProps}/>
};