import { createSlice } from "@reduxjs/toolkit";
import { initialState } from './types';
import { setUnitsOfMeasureDisplayReducer, setUnitReducer } from "./actions";

const unitsOfMeasureContainerSlice = createSlice({
    name: "unitsOfMeasureContainer",
    initialState,
    reducers: {
        setUnitsOfMeasureContainerDisplay: setUnitsOfMeasureDisplayReducer,
        setUnit: setUnitReducer,
    }
});

export const UnitOfMeasureContainerReducer = unitsOfMeasureContainerSlice.reducer;
export const { setUnitsOfMeasureContainerDisplay, setUnit } = unitsOfMeasureContainerSlice.actions;
