import { PolarityLC } from "../polarity-diagrams/templates/PolarityLC";
import { PolarityMMC } from "../polarity-diagrams/templates/PolarityMMC";
import { PolarityMTP } from "../polarity-diagrams/templates/PolarityMTP";

export const PolarityConnectors: React.FC = () => {
    return (
        <div id="polarity-connectors-container">
            <PolarityLC />
            <PolarityMMC />
            <PolarityMTP isMTP8={true} />
            <PolarityMTP isMTP8={false} />
        </div>
    )
};