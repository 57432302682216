import { useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setShowMoreMenu } from "../../../../redux/reducers";
import { showMoreMenuSelector } from '../../../../redux/selectors';
import { LocalizationKeys } from '../../../../../../../locales/types';
import { useTranslation } from 'react-i18next';
import { useLogout } from "../../../../../authentication/components/oid/hooks";

export const useMoreMenuContainer = () => {
    const { t } = useTranslation();
    const display = useSelector(showMoreMenuSelector);
    const dispatch = useDispatch();
    const { logout } = useLogout();

    const close = useCallback(() => {
        dispatch(setShowMoreMenu(false));
    }, [dispatch]);

    const items = [
        { key: "logout-button", icon: 'close', label: t(LocalizationKeys.LogOut), onClick: logout }
    ];

    return {
        display, items, close
    }
};