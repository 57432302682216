import { Rectangle } from "../../../interaction/types";

export interface IRectangleDrawingProps {
    display?: boolean,
    interactive: boolean,
    bounds: Rectangle,
    layout: IRectangleLayout,
    onMouseClick?: () => void,
    onMouseOver?: () => void,
    onMouseOut?: () => void
}

export interface IRectangleLayout {
    borderColor: number,
    alpha: number,
    color?: number
}

export const DEFAULT_RECTANGLE_LAYOUT: IRectangleLayout = {
    borderColor: 0xFFFFFF,
    alpha: 0,
}

export interface IRectangleGraphics {
    bounds: Rectangle, 
    alpha: number
    interactive: boolean,
    interactiveChildren: boolean,
    name: string,
    buttonMode: boolean,
    preventRedraw: boolean 
    pointertap?: () => void,
    pointerout?: () => void,
    pointerover?: () => void
}

export const InteractiveRectangle = "interactive-rectangle";