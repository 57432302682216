import { PayloadAction } from '@reduxjs/toolkit';
import { IWarningsState } from "./types";

export const setWarningsCardDisplayAction = (state: IWarningsState, action: PayloadAction<boolean>) => {
    state.displayCard = action.payload;
};

export const acknowledgeWarningGroupAction = (state: IWarningsState, action: PayloadAction<number>) => {
    const { unacknowledgedWarningGroups } = state;
    const groupIndex = state.unacknowledgedWarningGroups.findIndex((g) => g.id === action.payload);
    if (groupIndex < 0) {
        return;
    }
    state.acknowledgedWarnings.push(...unacknowledgedWarningGroups[groupIndex].warnings);
    unacknowledgedWarningGroups.splice(groupIndex, 1);
};
 