import { PayloadAction } from "@reduxjs/toolkit";
import { ISelectedConnectors, IConnectorPosition, DistributedColors, ORANGE } from "../../../../../../../pixi/components/decorators/bounds/components/selection/redux/types";
import { IConnectorData } from "../../../../../../redux/build/connector/types";
import { IDestinationData } from "../../../../../../redux/build/destination/types";
import { ISourceData } from "../../../../../../redux/build/source/types";
import { getGroupsConnectors } from "../../../../../../selectors/build.selectors";
import { getConnectorType } from "../../../wizard/redux/types";
import { PolarityAssignment, PolarityAssignments, IAssignmentRowData } from "../types";
import { IConnectorMap, IConnectorAssignmentState, IConnectorAssignmentMap } from "./types";

export const handleSelectionAction = (state: IConnectorAssignmentState, action: PayloadAction<{ connectorMap: IConnectorMap }>) => {
    const { connectorMap } = action.payload;
    if (connectorMap.index < 1) return;
    const { rows, assignmentMapping } = state;
    const dropRows = rows[connectorMap.position]
    const selection = connectorMap.position ? state.destinationSelection : state.sourceSelection;
    const connectorRow = dropRows[connectorMap.index - 1]
    const previousSelected = connectorRow.selected

    const existingMap = assignmentMapping.find(a => {
        return connectorMap.position ?
            a.destinationMapping.some(d => d.position === connectorMap.position && d.index === connectorMap.index) :
            a.sourceMapping.some(s => s.index === connectorMap.index)
    });

    if (!connectorRow.assigned && !previousSelected) {
        if (state.assignedSelection.length) resetRowSelection(state)
        selectRow(connectorRow, state);
    }
    else if (!connectorRow.assigned && previousSelected) {
        unselectRow(connectorRow, selection, state);
    }
    else if (connectorRow.assigned && existingMap) {
        selectAssigned(state, existingMap, rows, !!previousSelected)
    }

    if (!(state.sourceSelection.length && state.destinationSelection.length)) {
        state.polarityType = undefined;
    }

    state.selectAllSelection = state.rows.flatMap(r => r).every(r => r.selected);
}

export const handleWorkspaceSelectionAction = (state: IConnectorAssignmentState, action: PayloadAction<ISelectedConnectors>) => {
    const { assignedConnectors: workspaceAssigned } = action.payload;
    const { rows, assignmentMapping } = state;
    const existingMap = workspaceAssigned.length && assignmentMapping
        .find(a => workspaceAssigned
            .some(w => w.position ?
                a.destinationMapping.some(d => d.index === w.index + 1 && d.position === w.position) :
                a.sourceMapping.some(d => d.index === w.index + 1)));

    const appliedSelected = workspaceAssigned.length && workspaceAssigned.every(w => rows[w.position] && rows[w.position][w.index] && rows[w.position][w.index].selected)
    if (existingMap && !appliedSelected && workspaceAssigned.length) {
        const workspaceConnector = workspaceAssigned[0]
        const connectorRow = rows[workspaceConnector.position][workspaceConnector.index]
        selectAssigned(state, existingMap, rows, connectorRow.selected ?? false)
    }
}

export const handleHoverConnectorSetAction = (state: IConnectorAssignmentState, action: PayloadAction<IConnectorPosition>) => {
    const { index, position } = action.payload;
    const { rows, assignmentMapping } = state;
    if (index > -1 && position > -1) {
        const connectorRow = rows[position][index]
        if (connectorRow.assigned) {
            const existingMap = assignmentMapping.find(a => {
                return position ?
                    a.destinationMapping.some(d => d.position === position && d.index === index + 1) :
                    a.sourceMapping.some(s => s.index === index + 1)
            });
            const connectorMap = existingMap ? [...existingMap.sourceMapping, ...existingMap.destinationMapping] : []
            const hoveredConnectorMap = connectorMap.map(c => { return { index: c.index - 1, position: c.position } })
            state.hoveredConnectorMap = hoveredConnectorMap

        }
        else {
            state.hoveredConnectorMap = []
        }
    }

}

export const deleteAssignmentAction = (state: IConnectorAssignmentState, action: PayloadAction<number>) => {
    const index = action.payload;
    const assignmentIndex = state.assignmentMapping.findIndex(am => am.sourceMapping.find(m => m.index === index));
    if (assignmentIndex > -1) {
        const map = state.assignmentMapping[assignmentIndex]
        state.assignmentMapping = state.assignmentMapping.splice(assignmentIndex, 1)
        const mapRows = [...map.sourceMapping, ...map.destinationMapping]

        for (const mapRow of mapRows) {
            const row = state.rows[mapRow.position][mapRow.index - 1]
            row.assigned = false;
            row.selected = false;
            row.selectionIndex = -1;
            row.unassignedFibers = row.fiberCount
            row.highlighted = false;
        }
    }
}

export const resetAllAction = (state: IConnectorAssignmentState) => {
    state.assignmentMapping = [];
    resetSelectionAction(state);
    resetRowColors(state);
}

export const resetSelectionAction = (state: IConnectorAssignmentState) => {
    state.assignedSelection = []
    state.selectAllSelection = false;
    state.polarityType = undefined;
    state.polarityAssignment = PolarityAssignments.Standard;
    resetRowSelection(state);
}

export const resetAssignedSelectionAction = (state: IConnectorAssignmentState) => {
    state.assignedSelection = [];
}

export const resetAssignmentMethodAction = (state: IConnectorAssignmentState) => {
    state.polarityAssignment = PolarityAssignments.Standard;
}

export const setAssignmentMappingAction = (state: IConnectorAssignmentState, action: PayloadAction<IConnectorAssignmentMap[]>) => {
    const assignmentMapping = action.payload;
    const assignmentIds = generateAssignmentIdRecord(assignmentMapping)
    state.assignmentMapping = assignmentMapping;
    state.assignmentIds = assignmentIds;
    const assignedRows = state.assignmentMapping.flat().flatMap(a => [...a.sourceMapping, ...a.destinationMapping])

    if (state.rows.length) {
        resetRowAssignment(state)
        for (const assignmentMap of assignmentMapping) {
            const assignedMaps = [...assignmentMap.sourceMapping, ...assignmentMap.destinationMapping].filter(a => state.rows[a.position] && state.rows[a.position][a.index - 1])
            for (const assignedMap of assignedMaps) {
                const connectorRow = state.rows[assignedMap.position][assignedMap.index - 1]
                connectorRow.assigned = true
                connectorRow.fiberCount = assignedMap.fiberCount
                connectorRow.unassignedFibers = assignedMap.unassignedFibers
                connectorRow.blockedFiberCount = assignedMap.blockedFiberCount ?? 0
                connectorRow.mapId = assignmentMap.id
            }
        }

    }
    const rows = [...state.rows.flat()]
    state.selectAllDisabled = !!(rows.length && assignedRows.length && rows.length === assignedRows.length)
}

export const handleAssignmentMethodAction = (state: IConnectorAssignmentState, action: PayloadAction<PolarityAssignment>) => {
    state.polarityAssignment = action.payload;
    state.destinationSelection = [];
    state.sourceSelection = [];

    if (state.polarityAssignment === PolarityAssignments.Standard) {
        applyStandardSelection(state);
    } else if (state.polarityAssignment === PolarityAssignments.Distributed) {
        applyDistributedSelection(state);
    }
}

export const setPolarityAssignmentAction = (state: IConnectorAssignmentState, action: PayloadAction<PolarityAssignment>) => {
    state.polarityAssignment = action.payload;
}

export const setPolarityTypeAction = (state: IConnectorAssignmentState, action: PayloadAction<string | undefined>) => {
    state.polarityType = action.payload;
}

export const handleSelectAllAction = (state: IConnectorAssignmentState, action: PayloadAction<boolean>) => {
    state.selectAllSelection = action.payload
    state.assignedSelection = [];
    state.sourceSelection = [];
    state.destinationSelection = []
    if (!action.payload) {
        state.polarityType = undefined;
        state.polarityAssignment = PolarityAssignments.Standard;
        resetRowSelection(state);
        resetRowColors(state);
    }
    else {
        applyStandardSelection(state);
    }
}

export const disabledConnectorRowsAction = (state: IConnectorAssignmentState, action: PayloadAction<boolean>) => {
    const disabled = action.payload;
    const rows = state.rows.flat();
    rows.forEach(r => r.disabled = disabled)
}

export const setConnectorRowsAction = (state: IConnectorAssignmentState, action: PayloadAction<{ source: ISourceData, taps: IDestinationData[] }>) => {
    const { source, taps } = action.payload;
    const { assignmentMapping, polarityAssignment: assignmentMethod, sourceSelection, destinationSelection } = state;
    const sourceAssignment = assignmentMapping.flatMap(a => a.sourceMapping)
    const destinationAssignment = assignmentMapping.flatMap(a => a.destinationMapping)
    const assignedRows = [...sourceAssignment, ...destinationAssignment]
    const assignmentRecord = generateConnectorMapRecord(assignedRows)
    if (assignedRows.some(a => state.assignmentIds[a.id ?? 0])) {
        const assignmentIds = generateAssignmentIdRecord(assignmentMapping)
        state.assignmentIds = assignmentIds;
    }
    const selectionRecord = generateConnectorMapRecord([...sourceSelection, ...destinationSelection])
    const sourceConnectors = source.groups.flatMap(g => g.connectors.map(c => { return { ...c, groupPosition: g.position ?? 0 } })) //getGroupsConnectors(source.groups);
    const sourceDataRows = getAssignmentDataRows(0, sourceConnectors, assignmentRecord, selectionRecord, state.assignmentIds, assignmentMethod);
    const tapDataRows = taps.map(t => {
        const groupConnectors = t.groups.flatMap(g => g.connectors.map(c => { return { ...c, groupPosition: g.position ?? 0 } }))//getGroupsConnectors(t.groups)
        const dataRows = getAssignmentDataRows(t.position, groupConnectors, assignmentRecord, selectionRecord, state.assignmentIds, assignmentMethod)
        return dataRows
    })

    state.rows = [sourceDataRows, ...tapDataRows]
    filterPolarityAssignments(state, source, taps);

}

type GroupConnector = IConnectorData & { groupPosition: number }
const getAssignmentDataRows = (position: number, connectors: GroupConnector[], assignmentRecord: Record<number, Record<number, IConnectorMap>>, selectionRecord: Record<number, Record<number, IConnectorMap>>, assignmentIds: Record<number, number>, assignmentMethod: PolarityAssignment | undefined): IAssignmentRowData[] => {
    const rows: IAssignmentRowData[] = [];
    const nbrGroups = connectors.map(c => c.groupPosition).reduce((prev, curr) => (prev > curr ) ? prev : curr) + 1;
    const connectorsPerGroup = connectors.length / nbrGroups;
    let coloringIndex = 0; 

    for (let i = 0; i < connectors.length; i++) {
        const connector = connectors[i];
        const rowId = i + 1;
        const groupPosition = connector.groupPosition;
        const connectorPosition = connector.position !== undefined ? connector.position + 1 : -1;
        const assignment = assignmentRecord[position]
        const assigned = assignment && assignment[rowId] !== undefined
        const selection = selectionRecord[position]
        const selected = selection && selection[rowId] !== undefined
        let selectionIndex = selected ? selection[rowId].orderIndex : -1
        selectionIndex = assigned ? assignment[rowId].orderIndex : selectionIndex
        const rowMapId = assigned ? assignment[rowId].id : 0
        const mapId = assignmentIds[rowMapId ?? 0] ?? 0 
        const fiberCount = (connector.type && getConnectorType(connector.type).fiberCount) || 0;
        const unassignedFibers = assigned ? assignment[rowId].unassignedFibers : fiberCount;
        const blockedFiberCount = (assigned && assignment[rowId].blockedFiberCount) || 0
        const highlighted = assigned && selected;

        const color = assignmentMethod === PolarityAssignments.Distributed ? DistributedColors[coloringIndex] : undefined;
        coloringIndex = position > 0 ? (coloringIndex + 1 < connectorsPerGroup ? coloringIndex + 1 : 0) : ((i + 1) % connectorsPerGroup === 0 ? coloringIndex + 1 : coloringIndex);

        rows.push({
            id: rowId,
            mapId,
            position,
            groupPosition,
            connectorPosition,
            fiberCount,
            unassignedFibers,
            blockedFiberCount,
            assigned,
            selected,
            highlighted,
            selectionIndex,
            connectorType: connector.type || '',
            color
        });
    }
    return rows;
}

function generateAssignmentIdRecord(assignmentMapping: IConnectorAssignmentMap[]) {
    return assignmentMapping.reduce((acc, a) => {
        const mapRecord = [...a.sourceMapping, ...a.destinationMapping].reduce((bcc, b) => { return { ...bcc, [b.id ?? 0]: a.id ?? 0 }; }, {} as Record<number, number>);
        return { ...acc, ...mapRecord };
    }, {} as Record<number, number>);
}

function selectRow(connectorRow: IAssignmentRowData, state: IConnectorAssignmentState) {
    if (connectorRow.selected) return;
    const selection = connectorRow.position ? state.destinationSelection : state.sourceSelection
    connectorRow.selected = true;
    connectorRow.selectionIndex = selection.length;
    const newSelection = [...selection, ({ ...connectorRow, orderIndex: connectorRow.selectionIndex, index: connectorRow.id })];
    connectorRow.position ?
        state.destinationSelection = newSelection :
        state.sourceSelection = newSelection;
}

function unselectRow(connectorRow: IAssignmentRowData, selection: IConnectorMap[], state: IConnectorAssignmentState) {
    if (!connectorRow.selected) return;
    const rows = state.rows;
    connectorRow.selected = false;
    const newSelections = selection.filter(s => !(s.position === connectorRow.position && s.index === connectorRow.id)).map((s, i) => { return { ...s, orderIndex: i }; });

    for (let i = 0; i < newSelections.length; i++) {
        const newSelection = newSelections[i];
        const row = rows[newSelection.position][newSelection.index - 1];
        row.selected = true;
        row.selectionIndex = i
    }

    connectorRow.selectionIndex = -1;
    connectorRow.position ?
        state.destinationSelection = newSelections :
        state.sourceSelection = newSelections;
}

function resetRowSelection(state: IConnectorAssignmentState) {
    state.sourceSelection = [];
    state.destinationSelection = [];
    state.assignedSelection = [];
    state.rows.flat().filter(r => r.selected).forEach(r => {
        if (!r.selected) return;
        r.selected = false;
        r.selectionIndex = -1;
        r.highlighted = false;
    });
}

function resetRowAssignment(state: IConnectorAssignmentState) {
    state.sourceSelection = [];
    state.destinationSelection = [];
    state.rows.flat().filter(r => r.assigned).forEach(r => {
        r.blockedFiberCount = 0;
        r.unassignedFibers = r.fiberCount
        r.assigned = false;
        r.selectionIndex = -1;
        r.highlighted = false;
    });
}

function resetRowColors(state: IConnectorAssignmentState) {
    state.rows.flat().forEach(r => {
        r.color = undefined;
    });
}

function selectAssigned(state: IConnectorAssignmentState, existingMap: IConnectorAssignmentMap, rows: IAssignmentRowData[][], previousSelected: boolean) {
    resetRowSelection(state)
    if (!previousSelected) {
        state.destinationSelection = existingMap.destinationMapping;
        state.sourceSelection = existingMap.sourceMapping;
        const connectorMaps = [...existingMap.sourceMapping, ...existingMap.destinationMapping];
        state.assignedSelection = [...connectorMaps];
        for (const connectorMap of connectorMaps) {
            const tapRows = rows[connectorMap.position];
            const connectorRow = tapRows[connectorMap.index - 1];
            connectorRow.selected = true;
            connectorRow.selectionIndex = connectorMap.orderIndex;
            connectorRow.highlighted = true;
        }
    }
}
function generateConnectorMapRecord<T extends IConnectorMap>(assignedRows: T[]) {
    return assignedRows.reduce((acc, c) => {
        const connectorEntry = { [c.index]: c };
        if (!acc[c.position]) {
            acc[c.position] = connectorEntry;
        }
        else
            acc[c.position][c.index] = c

        return acc;
    }, {} as Record<number, Record<number, T>>);
}

function applyStandardSelection(state: IConnectorAssignmentState) {
    const rows = [...state.rows].flat();
    let sourceIndex = 0;
    let tapIndex = 0;
    for (const row of rows) {
        if (!row.assigned) {
            row.selected = true;
            row.selectionIndex = row.position ? tapIndex : sourceIndex;
            row.color = ORANGE;
            const selectedMap: IConnectorMap = {
                ...row,
                index: row.id,
                orderIndex: row.selectionIndex
            }

            row.position ?
                state.destinationSelection.push(selectedMap) :
                state.sourceSelection.push(selectedMap)

            row.position ? tapIndex++ : sourceIndex++
        }
        else {
            row.highlighted = false;
            row.selected = false;
            row.selectionIndex = -1;
            row.color = undefined;
        }
    }
}

function applyDistributedSelection(state: IConnectorAssignmentState) {
    let coloringIndex = 0;

    const sourceRows = state.rows[0];
    const nbrSrcGroups = sourceRows.map(row => row.groupPosition).filter((pos, i, self) => self.indexOf(pos) === i).length;
    const nbrSrcConnectorsPerGroup = sourceRows.length / nbrSrcGroups;

    const tapRows = state.rows.slice(1, state.rows.length);
    const nbrTapGroups = tapRows[0].map(row => row.groupPosition).filter((pos, i, self) => self.indexOf(pos) === i).length;
    const nbrTapConnectorsPerGroup = tapRows[0].length / nbrTapGroups;

    for (let i = 0; i < sourceRows.length; i++) {
        const sourceRow = sourceRows[i];
        const color = DistributedColors[coloringIndex];
        if (!sourceRow.assigned) {
            sourceRow.selectionIndex = i;
            sourceRow.color = color;
            const sourceMap: IConnectorMap = {
                ...sourceRow,
                index: sourceRow.id,
                orderIndex: i
            };
            state.sourceSelection.push(sourceMap);
        }

        const tapIndex = Math.floor((i % nbrSrcConnectorsPerGroup) / nbrTapGroups);
        const tapGroupIndex = (i % nbrSrcConnectorsPerGroup) % nbrTapGroups;
        const tapConnectorIndex = tapGroupIndex * nbrTapConnectorsPerGroup + sourceRow.groupPosition;

        const tapRow = tapRows[tapIndex][tapConnectorIndex];
        if (!tapRow.assigned) {
            tapRow.selectionIndex = i;
            tapRow.color = color;
            const destinationMap: IConnectorMap = {
                ...tapRow,
                index: tapRow.id,
                orderIndex: i
            };
            state.destinationSelection.push(destinationMap);
        }
        coloringIndex = (i + 1) % nbrSrcConnectorsPerGroup === 0 ? coloringIndex + 1 : coloringIndex;
    }
}

function filterPolarityAssignments(state: IConnectorAssignmentState, source: ISourceData, destination: IDestinationData[]) {
    const nbrSrcConnectorsPerGroup = source.groups[0].connectors.length;;
    const nbrSourceConnectors = getGroupsConnectors(source.groups).length;
    const nbrDestinationConnectors = getGroupsConnectors(destination.flatMap(d => d.groups)).length;

    const fiberMatch = nbrSourceConnectors === nbrDestinationConnectors;
    const groupMatch = destination.every(d => d.groups.length <= nbrSrcConnectorsPerGroup);
    const connectorMatch = destination.every(d => d.groups[0].connectors.length === source.groups.length);
    const hasAssignments = state.assignmentMapping.length > 0;
    const filter = fiberMatch && groupMatch && connectorMatch && !hasAssignments;

    state.filteredPolarityAssignments = Object.values(PolarityAssignments).filter(assignment => {
        return !filter ? assignment !== PolarityAssignments.Distributed : true;
    });
};