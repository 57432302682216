import { Search } from '@mui/icons-material';
import { MenuItem, Select } from "@mui/material";
import { ContainedButton } from '@orbit/button';
import { FilledTextField } from '@orbit/text-field';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../../../../../../locales/types';
import { ToolItem } from "../../../../../../ToolItem";
import { Sorts } from '../../../../redux/types';
import icon_sort_asc from "../../resources/icons/icon_sort_asc.svg";
import icon_sort_desc from "../../resources/icons/icon_sort_desc.svg";
import { useDrawerSubHeader } from './hooks';

import './DrawerSubHeader.scss';

export const DrawerSubHeader = () => {
    const { t } = useTranslation();
    const { cableLabel, sortType, isSortAscending, searchTextFieldProps, onSelectChange, triggerSort, triggerCreate } = useDrawerSubHeader();
    return (
        <div className="drawer-sub-header">
            <div className="cable-label">
                {cableLabel}
            </div>
            <div className="sort-container">
                <div className="sort-label">
                    {t(LocalizationKeys.SortBy)}
                </div>
                <Select value={sortType} onChange={onSelectChange} className="sort-select">
                    <MenuItem value={Sorts.DateModified}>{t(LocalizationKeys.DateModified).toUpperCase()}</MenuItem>
                    <MenuItem value={Sorts.Name}>{t(LocalizationKeys.Name).toUpperCase()}</MenuItem>
                    <MenuItem value={Sorts.Description}>{t(LocalizationKeys.Description).toUpperCase()}</MenuItem>
                </Select>
                <div className="sort-button">
                    {isSortAscending ?
                        <ToolItem source={icon_sort_asc} alt="Sort asc" tooltip={t(LocalizationKeys.SortAscending)} labelTop={true} onClick={triggerSort} /> :
                        <ToolItem source={icon_sort_desc} alt="Sort desc" tooltip={t(LocalizationKeys.SortDescending)} labelTop={true} onClick={triggerSort} />}
                </div>
            </div>
            <FilledTextField {...searchTextFieldProps} InputProps={{ disableUnderline: true, startAdornment: <Search /> }} />
            <ContainedButton className="new-cable-button" size={"large"} onClick={triggerCreate} variant={"contained"}>
                {t(LocalizationKeys.NewCable)}
            </ContainedButton>
        </div>
    );
}