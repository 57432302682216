import { createSelector } from 'reselect';
import { workspaceSelector } from '../../../../../../../selectors/root.selectors';

export const unitsOfMeasureContainerSelector = createSelector(
    workspaceSelector,
    workspace => workspace.unitsOfMeasureContainer
);

export const unitsOfMeasureContainerDisplaySelector = createSelector(
    unitsOfMeasureContainerSelector,
    (container) => container.display
);

export const unitsOfMeasureContainerUnitSelector = createSelector(
    unitsOfMeasureContainerSelector,
    (container) => container.unit
);
