import { useSelector } from 'react-redux';
import { fiberTypeTrunkColorSelector } from '../../../../../workspace/redux/ssc/selectors';
import { LC } from '../../../../factories/Texture';
import { IBaseProps } from '../../types';

export const useFeederBase = (props: IBaseProps) => {
    const { id: color } = useSelector(fiberTypeTrunkColorSelector)

    const externalFurcation = props.connectorType === LC;
    return { externalFurcation, color };
};
