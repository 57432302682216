import { Container } from '@inlet/react-pixi';
import { useMarkers } from './hooks';
import { BLength } from './components/length/b/BLength';
import { ALength } from './components/length/a/ALength';
import { LineMarker } from './components/line/LineMarker';

export const Markers: React.FC = () => {
    const { aLengthProps, bLengthProps, staggerLines, staggerMarkers } = useMarkers();
 
    return (
        <Container>
            <ALength {...aLengthProps} />
            <BLength {...bLengthProps} />
            { staggerLines.map(c => <LineMarker {...c} />) }
            { staggerMarkers.map(c => <LineMarker {...c} />) }
        </Container>
    )
}