import { createSlice } from "@reduxjs/toolkit";
import { initialConnectorAssignmentState } from "./types";

import {
    deleteAssignmentAction,
    handleSelectAllAction,
    handleSelectionAction,
    resetAllAction,
    resetAssignedSelectionAction,
    resetSelectionAction,
    setAssignmentMappingAction,
    handleAssignmentMethodAction,
    setPolarityTypeAction,
    setConnectorRowsAction,
    disabledConnectorRowsAction,
    handleWorkspaceSelectionAction,
    handleHoverConnectorSetAction,
    resetAssignmentMethodAction,
    setPolarityAssignmentAction
} from "./actions";

const connectorAssignmentSlice = createSlice({
    initialState: initialConnectorAssignmentState,
    name: "connectorAssignment",
    reducers: {
        handleSelection: handleSelectionAction,
        handleWorkspaceSelection: handleWorkspaceSelectionAction,
        handleSelectAll: handleSelectAllAction,
        handleAssignmentMethod: handleAssignmentMethodAction,
        handleHoverConnectorSet: handleHoverConnectorSetAction,
        deleteAssignment: deleteAssignmentAction,
        resetAll: resetAllAction,
        resetSelection: resetSelectionAction,
        resetAssignedSelection: resetAssignedSelectionAction,
        resetAssignmentMethod: resetAssignmentMethodAction,
        setAssignmentMapping: setAssignmentMappingAction,
        setPolarityType: setPolarityTypeAction,
        setConnectorRows: setConnectorRowsAction,
        setPolarityAssignment: setPolarityAssignmentAction,
        disabledConnectorRows: disabledConnectorRowsAction
    }
});

export const ConnectorAssignmentReducer = connectorAssignmentSlice.reducer;
export const {
    handleSelection,
    handleWorkspaceSelection,
    handleSelectAll,
    handleAssignmentMethod,
    deleteAssignment,
    resetAll,
    resetSelection,
    resetAssignedSelection,
    resetAssignmentMethod,
    setAssignmentMapping,
    setPolarityType,
    setConnectorRows,
    setPolarityAssignment,
    disabledConnectorRows,
    handleHoverConnectorSet
} = connectorAssignmentSlice.actions;