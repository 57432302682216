export interface IOverlayState {
    printSettings: IPrintSettings;
    showConnectorReport: boolean;
    showLabelScheme: boolean;
    showTriggerManagement: boolean;
    showPolarity: boolean;
    disablePolarity: boolean;
    showThirdPartyCopyright: boolean;
    showAboutApplication: boolean;
    showCablePresetSnackbar: boolean;
    showConnectorAssignment: boolean;
    showFiberMapping: boolean;
    showViewProduct: boolean;
    showMoreMenu: boolean;
    showIdle: boolean;
    showTimeout: boolean;
    showAnonymousUserWarning: boolean;
}

export interface IPrintSettings {
    showPrintDialog: boolean; 
    enableFiberMapsOption: boolean;
    enablePolarityDiagramOption: boolean;
}

export const initialPrintSettings: IPrintSettings = {
    showPrintDialog: false,
    enableFiberMapsOption: false,
    enablePolarityDiagramOption: false
}

export const initialOverlayState: IOverlayState = {
    printSettings: initialPrintSettings,
    showThirdPartyCopyright: false,
    showAboutApplication: false,
    showConnectorReport: false,
    showLabelScheme: false,
    showTriggerManagement: false,
    showPolarity: false,
    disablePolarity: true,
    showCablePresetSnackbar: false,
    showConnectorAssignment: false,
    showFiberMapping: false,
    showViewProduct: false,
    showMoreMenu: false,
    showIdle: false,
    showTimeout: false,
    showAnonymousUserWarning: false
}
