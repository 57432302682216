import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit';
import { ZoomReducer } from '../components/overlay/components/footer/components/zoom/legacy/redux/reducers';
import { PageResizeReducer } from '../components/overlay/components/footer/components/resize/legacy/redux/reducers';
import { BuildReducer } from './build/reducers';
import { AuthenticationReducer } from '../components/authentication/redux/reducers';
import { WizardReducer } from '../components/overlay/components/wizard/redux/reducers';
import { EditToolbarReducer } from '../components/overlay/components/footer/components/toolbar/redux/reducers';
import { ViewportReducer } from '../../pixi/components/viewport/redux/reducers';
import { UnitOfMeasureContainerReducer } from '../components/overlay/components/header/components/units-of-measure-container/redux/reducers';
import { StatusReducer } from '../components/overlay/components/header/components/status/redux/reducer';
import { ProjectManagerReducer } from '../components/overlay/components/projects/redux/reducers';
import { OverlayReducer } from '../components/overlay/redux/reducers';
import { ReportDocumentReducer } from './document/reducers';
import { LabelSchemeReducer } from './build/connector/labelscheme/reducers';
import { PolarityConfigurationReducer } from './build/connector/polarity/reducer';
import { UserWizardPresetReducer } from './build/wizard/wizard-preset/redux/reducers';
import { ConnectorHighlightsReducer } from '../../pixi/components/decorators/bounds/components/selection/redux/reducer';
import { SSCSessionReducer } from './ssc/reducer';
import { WarningsReducer } from '../components/overlay/components/header/components/warnings/redux/reducer';
import { PropagationReducer } from '../components/overlay/components/polarity/propagation/redux/reducer';
import { NotificationManagerReducer } from '../components/overlay/components/notification/store/reducer';

export { connect } from 'react-redux';

export const WorkspaceReducer = combineReducers({
    authentication: AuthenticationReducer,
    zoom: ZoomReducer,
    resize: PageResizeReducer,
    builds: BuildReducer,
    wizard: WizardReducer,
    toolbar: EditToolbarReducer,
    viewport: ViewportReducer,
    unitsOfMeasureContainer: UnitOfMeasureContainerReducer,
    status: StatusReducer,
    projectManager: ProjectManagerReducer,
    overlay: OverlayReducer,
    reportDocument: ReportDocumentReducer,
    labelScheme: LabelSchemeReducer,
    polarity: PolarityConfigurationReducer,
    propagation: PropagationReducer,
    userWizard: UserWizardPresetReducer,
    connectorHighlights: ConnectorHighlightsReducer,
    ssc: SSCSessionReducer,
    warnings: WarningsReducer,
    snackbar: NotificationManagerReducer
});

export type WorkspaceState = ReturnType<typeof WorkspaceReducer>;

export const store = configureStore({
    reducer: WorkspaceReducer
})

export type AppDispatch = typeof store.dispatch;