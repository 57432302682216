import { useReducer } from "react";
import { ConnectorReportReducer } from "./redux/reducers";
import { initialState } from "./redux/types";

export const useReports = () => {
    const [state, dispatch] = useReducer(ConnectorReportReducer, initialState);
    const context = { state, dispatch };

    return { context };
};
