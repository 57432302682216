import { IViewportState, ViewportStatusTypes } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { ZoomArgs } from "../../../../workspace/components/overlay/components/footer/components/zoom/types";
import { IPoint } from "../../../types";
import { PageResizeArgs } from "../../../../workspace/components/overlay/components/footer/components/resize/types";

export const zoomToMouseReducer = (state: IViewportState, action: PayloadAction<ZoomArgs>) => {
    const oldScale = state.scale;
    const viewportPosition = state.position;
    let { newScale, cursor } = action.payload;
    
    // calculate the oldOffset between the viewport and the cursor
    const oldOffset: IPoint = {
        x: cursor.x - viewportPosition.x, 
        y: cursor.y - viewportPosition.y
    };

    // calculate the new offset by scaling the old one with the newScale
    const newOffset: IPoint = {
        x: oldOffset.x / oldScale.x * newScale.x, 
        y: oldOffset.y / oldScale.y * newScale.y
    };

    // calculate the viewportPos now that we have our newOffset and scale it
    const newPosition: IPoint = {
        x: cursor.x - newOffset.x,
        y: cursor.y - newOffset.y
    }

    state.position = newPosition;
    state.scale = newScale;
}

export const zoomInAction = (state: IViewportState) => {
    if (state.scaleIncrement > 0) state.scaleIncrement += 0.1
    else state.scaleIncrement = 0.1
}

export const zoomOutAction = (state: IViewportState) => {
    if (state.scaleIncrement < 0) state.scaleIncrement -= 0.05
    else state.scaleIncrement = -0.1
}

export const setIsDraggingReducer = (state: IViewportState, action: PayloadAction<boolean>) => {
    state.isDragging = action.payload;
}

export const setViewportContextReducer = (state: IViewportState, action: PayloadAction<ViewportStatusTypes>) => {
    state.context = action.payload;
}

export const setScaleReducer = (state: IViewportState, action: PayloadAction<IPoint>) => {
    state.scale = action.payload;
}

export const setPositionReducer = (state: IViewportState, action: PayloadAction<IPoint>) => {
    state.position = action.payload;
}

export const setResizeReducer = (state: IViewportState, action: PayloadAction<PageResizeArgs>) => {
    state.resize = action.payload;
}