import { AlertPalettes, AlertPalette } from "@orbit/snackbar";

export interface INotificationMessage {
    message: string;
    palette: AlertPalette
}

export interface INotificationManagerState {
    display: boolean;
    notification: INotificationMessage;
}

export const initialNotificationManagerState: INotificationManagerState = {
    display: false,
    notification: {
        message: "",
        palette: AlertPalettes.error
    }
};