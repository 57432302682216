import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../locales/types';
import { useDialogFooter } from './hooks';
import { IDialogFooterProps } from './types';
import { Button } from '@mui/material'

import './DialogFooter.scss'
export const DialogFooter: React.FC<IDialogFooterProps> = (props) => {
    const { t } = useTranslation();
    const { actionButtonText, onAction, onCancel } = useDialogFooter(props);

    return (
        <div className="dialog-footer">
            <div className="dialog-footer-button-container">
                <Button className="cancel-button" onClick={onCancel}>{t(LocalizationKeys.Cancel)}</Button>
                <Button className="action-button" onClick={onAction}>{actionButtonText}</Button>
            </div>
        </div>
    );
}