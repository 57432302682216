import React from 'react';
import * as Pixi from 'pixi.js'
import { Text } from "@inlet/react-pixi";
import { LabelProps } from './types';

export const Label: React.FC<LabelProps> = ({ x, y, text, name, fontSize, color }: LabelProps) => {
    let style = new Pixi.TextStyle({ align: 'center', fontFamily: 'TheSansC4s', fill: [color], fontSize: fontSize ? fontSize + "px" : '32px' });
    let textObj = new Pixi.Text(text, style);
    let anchor = new Pixi.Point(0.5, textObj.anchor.y);
    let scale = new Pixi.Point(textObj.scale.x * 0.5, textObj.scale.y * 0.5);
    return <Text name={name ? name : "text-label" } x={x} y={y} anchor={anchor} text={text} scale={scale} style={style} resolution={2} />
}
