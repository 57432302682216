import { createSelector } from "reselect";
import { workspaceSelector } from "../../../../../../../workspace/selectors/root.selectors";

export const connectorHighlightsSelector = createSelector(
    workspaceSelector,
    workspace => workspace.connectorHighlights
)

export const hoveredConnectorSelector = createSelector(
    connectorHighlightsSelector,
    connectors => connectors.hoveredConnector
)

export const lastSelectedConnectorsSelector = createSelector(
    connectorHighlightsSelector,
    connectors => connectors.lastClickedConnector
)

export const highlightsSelector = createSelector(
    connectorHighlightsSelector,
    connectors => connectors.highlights
)

export const getConnectorHighlightSelectorFactory = (position: number, index: number) => {
    return createSelector(
        highlightsSelector,
        highlights => highlights.find(h => h.position === position && h.index === index)
    )
}