import React from 'react';
import { useFooter } from './hooks';
import { ZoomTool } from './components/zoom/ZoomTool';
import { PageResizeTool } from './components/resize/PageResizeTool';
import { EditToolbar } from './components/toolbar/EditToolbar';
import CurrentUnitsTool from './components/toolbar/components/current-units/CurrentUnitsTool';
import { ActionToolbar } from './components/toolbar/components/action/ActionToolbar';
import { RepresentativeExample } from './components/representative-example/RepresentativeExample';

import './Footer.scss';

export const Footer: React.FC = () => {
    const { footerClassName } = useFooter();
    return (
        <div className={footerClassName}>
            <ZoomTool />
            <PageResizeTool />
            <EditToolbar />
            <CurrentUnitsTool />
            <ActionToolbar />
            <RepresentativeExample />
        </div>
    );
}