import { withMemoization } from "../../../../../pixi/memoization/Memoization";
import { IPolarityDiagramsProps, arePolarityDiagramsEqual } from "./types";

const PolarityDiagramsGraphics: React.FC<IPolarityDiagramsProps> = ({ diagrams }) => {
    return (
        <div id="polarity-container">
            { diagrams.filter((d) => d.url).map(({ sessionId, url }) =>
                <img id={`polarity-image-${sessionId}`} key={sessionId} src={url} alt="polarity diagram" />
            )}
        </div>
    )
};

export const PolarityDiagrams = withMemoization(PolarityDiagramsGraphics, arePolarityDiagramsEqual);