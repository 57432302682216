import { useWindowSize } from "../../hooks";
import { useSelector } from "react-redux";
import { IBuildData } from "../../../workspace/redux/build/types";
import { currentBuildSelector } from "../../../workspace/selectors/root.selectors";
import { unitsOfMeasureContainerUnitSelector } from '../../../workspace/components/overlay/components/header/components/units-of-measure-container/redux/selectors';
import { reportDocumentSelector } from "../../../workspace/redux/document/selectors";
import { TrunkProps } from "../build/types";
import { getTrunks, ToTrunkProps } from "../../../workspace/selectors/build.selectors";
import { fiberTypeTrunkColorSelector } from "../../../workspace/redux/ssc/selectors";
import { IPoint, cablePosition } from "../../types";
import { ISourceData } from "../../../workspace/redux/build/source/types";
import { CableProps } from "../build/components/cable/types";
import { ISize } from "pixi.js";

export const useDocumentGraphics = () => {
    const currentBuild = useSelector(currentBuildSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const windowSize = useWindowSize();

    const { overviewGraphics, build } = useOverviewGraphics(currentBuild!, windowSize);
    const { feederGraphics } = useFeederGraphics(currentBuild!, windowSize);

    return { windowSize, overviewGraphics, build, feederGraphics, unit };
}

export const useOverviewGraphics = (currentBuild: IBuildData, windowSize: ISize) => {
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const fiberTypeTrunkColor = useSelector(fiberTypeTrunkColorSelector);
    const { overview } = useSelector(reportDocumentSelector);
    const build: IBuildData = overview ? { destinations: overview.destinations ?? [], source: overview.source!, availability: overview.availability } : { ...currentBuild };

    const cableOffset: IPoint = { x: windowSize.width * 2, y: windowSize.height };
    const trunks = getTrunks(build, unit, fiberTypeTrunkColor.id, cablePosition, cableOffset);

    const overviewGraphics: CableProps = {
        display: !!overview,
        trunks
    };

    return { overviewGraphics, build };
}

export const useFeederGraphics = (currentBuild: IBuildData, windowSize: ISize) => {
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const { id: trunkColor } = useSelector(fiberTypeTrunkColorSelector);
    const { feeder } = useSelector(reportDocumentSelector);

    const source: ISourceData = feeder ? { ...feeder } : { ...currentBuild.source };
    const point: IPoint = { x: windowSize.width * 2, y: windowSize.height * 2 };
    const trunkProps: TrunkProps = ToTrunkProps(source, unit, trunkColor, point);

    const feederGraphics = {
        display: true, 
        source, 
        trunkProps
    };

    return { feederGraphics }
}