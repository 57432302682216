import React from 'react';
import { Drawer as OrbitDrawer } from '@orbit/drawer'
import { Divider } from '@mui/material';
import { DrawerProps } from '../types';
import { DrawerHeader } from '../drawer-header/DrawerHeader';
import { useDrawer } from './hooks';
import { DrawerHeaderProps } from '../drawer-header/types';

import './ModalDrawer.scss';

export const ModalDrawer: React.FC<DrawerProps> = (props) => {
    const { display } = useDrawer(props);
    const { className, children, onClose } = props;
    return (
        <OrbitDrawer open={display} anchor={"left"} onClose={onClose}>
            <div className={className ? `${props.className} modal-drawer` : `modal-drawer`}>
                <DrawerHeader {...props as DrawerHeaderProps} />
                <Divider />
                <div className="children-container">
                    {children}
                </div>
            </div>
        </OrbitDrawer>
    );
}