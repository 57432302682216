import React from "react";
import { TableRow, TableCell, InputAdornment } from "@mui/material";
import { Checkbox } from "@orbit/checkbox";
import { useConnectorRow } from "./hooks";
import { ColorChip } from "../../../../../../../../ui/dialog/color/chip/ColorChip";
import { IConnectorRowProps } from './types';
import { TextField } from "@mui/material"
import { styled } from "@mui/system";
import { Edit } from '@mui/icons-material'

const StyledTextField = styled(TextField)(({ theme }) => ({
    Input: {
        color: theme.palette.primary.main,
        padding: "17.5px 0 17.5px 3px",
    }
})) as typeof TextField;

export const ConnectorRow = (props: IConnectorRowProps) => {
    const {
        data,
        onFocus,
        onBlur,
        checkboxProps,
        editableLabelProps,
    } = useConnectorRow(props);

    return (
        <TableRow key={data.id} onFocus={onFocus} onBlur={onBlur} >
            <TableCell align="center" className="row-checkbox">
                <Checkbox palette="primary" {...checkboxProps} />
            </TableCell>
            <TableCell align="right" component="th" scope="row">{data.id}</TableCell>
            <TableCell align="right">{data.group}</TableCell>
            <TableCell align="left">
                <ColorChip color={data.color} scale={0.2} />
            </TableCell>
            <TableCell align="left" className="label-cell">
                <StyledTextField
                    variant="standard"
                    className="label-field"
                    value={editableLabelProps.value}
                    disabled={editableLabelProps.disabled}
                    onChange={editableLabelProps.onChange}
                    onBlur={editableLabelProps.onBlur}
                    onFocus={editableLabelProps.onFocus}
                    InputProps={{ 
                        endAdornment: (
                        <InputAdornment position="end">
                            <Edit/>
                        </InputAdornment>),
                        disableUnderline: true
                    }}
                    />
            </TableCell>
            <TableCell align="right">{data.totalLength}</TableCell>
        </TableRow >
    );
};
