import React from 'react';
import { IFiberMappingConnectorProps } from './types';
import { useFiberMappingConnector } from './hooks';
import { isMMC, isMTP } from '../../../../../../../pixi/factories/Texture';
import { LCTemplate } from './templates/LCTemplate';
import { MMCTemplate } from './templates/MMCTemplate';
import { MTPTemplate } from './templates/MTPTemplate';
import { ToolItem } from '../../../../ToolItem';

import './FiberMappingConnector.scss';

export const FiberMappingConnector: React.FC<IFiberMappingConnectorProps> = (props) => {
    const { type, disabled, previousProps, nextProps } = useFiberMappingConnector(props);
    let template = <LCTemplate disabled={disabled} />;
    if (isMMC(type)) {
        template = <MMCTemplate type={type} disabled={disabled} />
    }
    if (isMTP(type)) {
        template = <MTPTemplate type={type} disabled={disabled} />
    } 
    return (
        <div className="connector-container">
            <ToolItem icon="chevron_left" disabled={previousProps.disabled} tooltip={previousProps.tooltip} labelBottom={true} onClick={previousProps.onClick} />
            {template}
            <ToolItem icon="chevron_right" disabled={nextProps.disabled} tooltip={nextProps.tooltip} labelBottom={true} onClick={nextProps.onClick} />
        </div>
    );
}