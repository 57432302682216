import React from "react";
import { useWizardFooter } from "./hooks";
import { OutlinedButton, ContainedButton, TextButton } from '@orbit/button';
import { GenericDialog } from "../../../../../../../ui/dialog/generic-dialog/GenericDialog";

import './WizardFooter.scss';

export const WizardFooter = () => {
    const { 
        footerClassName, 
        previousClassName, 
        previousHidden, 
        previousOutlined, 
        previousDisabled, 
        onPreviousClick, 
        previousText, 
        nextClassName, 
        onNextClick, 
        nextText, 
        isNextEnabled,
        resetDialogProps
    } = useWizardFooter();

    return (
        <div className={footerClassName}>
            {!previousHidden ? 
                previousOutlined ? <OutlinedButton 
                    className={previousClassName}
                    disabled={previousDisabled} 
                    onClick={onPreviousClick}>
                        <i className="material-icons">chevron_left</i>
                        {previousText}
                </OutlinedButton> :
                <TextButton
                    palette="error"
                    className={previousClassName}
                    disabled={previousDisabled}
                    onClick={onPreviousClick}>
                        {previousText}
                </TextButton>
            : null}
            <ContainedButton className={nextClassName} disabled={!isNextEnabled} onClick={onNextClick}>
                {nextText}
            </ContainedButton>
            <GenericDialog { ...resetDialogProps } />
        </div> 
    );
}