import React from 'react';
import { useCablePresetSnackbar } from './hooks';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../../../../locales/types';
import { GenericSnackbar } from '../../../../../../ui/snackbar/generic/GenericSnackbar';
import { SnackbarTypes } from '../../../../../../ui/snackbar/generic/redux/types';

export const CablePresetSnackbar = () => {
    const { open, handleClose, handleSavePreset } = useCablePresetSnackbar();
    const { t } = useTranslation();

    return (
        <div className="cable-preset-snackbar">
            <GenericSnackbar
                type={SnackbarTypes.Information}
                open={open}
                message={t(LocalizationKeys.CablePresetConfirmation)}
                onConfirm={handleSavePreset}
                onCancel={handleClose}
                hideCloseButton={true}
            />
        </div>
    )
}