import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sscConfigurationTypeSelector } from '../../../../../../../../redux/ssc/selectors';
import { LocalizationKeys } from "../../../../../../../../../locales/types";
import { useCallback, useEffect, useState } from "react";
import { setConfigurationSession } from "../../../../../../../../redux/ssc/reducer";
import { setConfigurationType } from "../../../../../../../../redux/build/reducers";
import { ViewportStatus } from "../../../../../../../../../pixi/components/viewport/redux/types";
import { selectedPresetConfigurationTypeSelector, selectedPresetFiberCountSelector } from "../../../../../../../../redux/build/wizard/wizard-preset/selector";
import { useSscConfigSession } from "../../../../../../../../redux/ssc/hooks";
import { currentBuildSelector } from "../../../../../../../../selectors/root.selectors";
import { viewportContextSelector } from "../../../../../../../../../pixi/components/viewport/redux/selectors";

export const useConfigurationType = () => {
    const { t } = useTranslation();
    const { configurationTypeOptions } = useSelector(sscConfigurationTypeSelector);
    const label = t(LocalizationKeys.ConfigurationType);
    const context = useSelector(viewportContextSelector);
    const presetConfigType = useSelector(selectedPresetConfigurationTypeSelector)
    const presetFiberCount = useSelector(selectedPresetFiberCountSelector)
    const currentBuild = useSelector(currentBuildSelector)
    const { updateConfigSessionConfigurationType } = useSscConfigSession();
    const dispatch = useDispatch();
    const disabled = context.context === ViewportStatus.Editing;
    const initialConfigurationType = currentBuild?.configurationType ?? presetConfigType
    const [configurationType, setUiConfigurationType] = useState(configurationTypeOptions.find(x => x.key === initialConfigurationType) ?? configurationTypeOptions[0]);
    
    useEffect(() => {
        if (!configurationType && initialConfigurationType) {
            const updateConfigType = configurationTypeOptions.find(x => x.key === initialConfigurationType) ?? configurationTypeOptions[0]
            if (updateConfigType) {
                setUiConfigurationType(updateConfigType)
                dispatch(setConfigurationType({ configurationType: updateConfigType.key }));
            }
        }
    }, [configurationType, initialConfigurationType, configurationTypeOptions, dispatch])

    const onConfigurationTypeChange = useCallback(async (e: React.ChangeEvent<any>) => {
        const value = e.target.value;
        const selectedConfigurationType = configurationTypeOptions.find(c => c.value === value)
        const configSession = await updateConfigSessionConfigurationType(value)

        if (configurationType && configurationType.key !== value && configSession.sessionId.length && selectedConfigurationType) {
            dispatch(setConfigurationSession(configSession))
            const configArgs = {
                configurationType: selectedConfigurationType.value,
                fiberCount: Math.max(presetFiberCount, configSession.fiberCounts[0]?.id.min ?? 0)
            }
            dispatch(setConfigurationType(configArgs));
            setUiConfigurationType(selectedConfigurationType)
        }

    }, [dispatch, configurationType, configurationTypeOptions, updateConfigSessionConfigurationType, presetFiberCount]);

    return { label, disabled, configurationType, onConfigurationTypeChange, configurationTypeOptions };
};