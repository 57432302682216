import { Dispatch } from "react";
import { AnyAction } from "redux";
import { Blue, IColor } from "../../../../../../ui/dialog/color/types";
import { IConnectorAssignmentMap } from "../../polarity/connector-assignment/redux/types";

export interface IConnectorReportState {
    selectedRowIds: number[];
    labelChanged: boolean;
    triggerColorsChanged: boolean;
    propagationMapping: IConnectorAssignmentMap[];
}

export interface IConnectorRowData {
    id: number;
    position: number;
    groupIndex: number;
    connectorIndex: number;
    group: number;
    color: IColor;
    label?: string;
    lengthB: string;
    stagger: string;
    totalLength: string;
}

export const initialState: IConnectorReportState = {
    selectedRowIds: [],
    labelChanged: false,
    triggerColorsChanged: false,
    propagationMapping: []
}

export const initialConnectorRow: IConnectorRowData = {
    id: -1,
    position: -1,
    groupIndex: -1,
    connectorIndex: -1,
    group: 0,
    color: Blue,
    label: "",
    lengthB: "",
    stagger: "",
    totalLength: "",
}

export interface IConnectorReportContext {
    state: IConnectorReportState;
    dispatch: Dispatch<AnyAction>;
}

export const initialConnectorReportContext: IConnectorReportContext = {
    state: initialState,
    dispatch: () => {},
}
