import { IFiberCountAutocompleteProps } from "./types";
import { useSelector } from "react-redux";
import React, { useState, useCallback, useEffect } from "react";
import { ViewportStatus } from "../../../../../../../../../pixi/components/viewport/redux/types";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../../../../locales/types";
import { AutocompleteCloseReason } from "@mui/material";
import { sscFiberCountSelector } from "../../../../../../../../redux/ssc/selectors";
import { viewportContextSelector } from "../../../../../../../../../pixi/components/viewport/redux/selectors";
import { workspaceSelector } from "../../../../../../../../selectors/root.selectors";

export const useFiberCountAutocomplete = (props: IFiberCountAutocompleteProps) => {
    const { value: fiberCountValue, onValueChanged, isInputValid, inputErrorText, onInputChanged: onFiberCountChange, disabled: buildDisabled} = props;
    const { t } = useTranslation();
    const context = useSelector(viewportContextSelector);
    const { fiberCountOptions } = useSelector(sscFiberCountSelector);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [label, setLabel] = useState("");
    
    const options = fiberCountOptions.map(c => c.key);
    
    useEffect(() => {
        if (context.context === ViewportStatus.Editing) {
            setDisabled(true);
        } else {
            setDisabled(buildDisabled ?? false);
        }
    }, [context.context, buildDisabled]);

    useEffect(() => {
        const fiberCount = fiberCountOptions.find(x => x.key === fiberCountValue || x.count === Number.parseInt(fiberCountValue));
        if (fiberCount) {
            setLabel(t(LocalizationKeys.FiberCount));
            setOpen(false);
        } else {
            setLabel(t(LocalizationKeys.CustomFiberCount));
            setOpen(false);
        }
    }, [fiberCountValue, fiberCountOptions, t]);

    const onOpen = useCallback((e: React.ChangeEvent<{}>) => {
        setOpen(true);
    }, []);

    const onClose = useCallback((e: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
        setOpen(false)
    }, []);

    const onInputKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === 13) {
            setOpen(false);
        }
    }, []);

    const onInputChanged = useCallback((e: React.ChangeEvent<{}>, value: string) => {
        onFiberCountChange(e, value);
    }, [onFiberCountChange])

    return { open, disabled, options, fiberCountValue, onOpen, onClose, onValueChanged, onInputChanged, label, isInputValid, inputErrorText, onInputKeyDown };
}

export const useFiberCount = () => {
    const { builds } = useSelector(workspaceSelector);
    const { fiberCount: initialFiberCount } = builds.currentBuild!.source;
    const { fiberCountOptions } = useSelector(sscFiberCountSelector);

    const [fiberCount, setFiberCount] = useState(fiberCountOptions.find(x => x.count === initialFiberCount!)!.description!);

    const onFiberCountChange = useCallback((e: React.FormEvent<HTMLSelectElement>) => {
        const value = e.currentTarget.value
        if (fiberCount !== value) {
            const index = e.currentTarget.selectedIndex;
            const fiberCount = fiberCountOptions[index].description;
            setFiberCount(fiberCount)
        }
    }, [fiberCount, fiberCountOptions]);

    return { fiberCount, onFiberCountChange };
}