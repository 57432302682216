import * as PIXI from "pixi.js";
import { useState, useContext, useRef, useEffect } from "react";
import { MarkerStyleContext } from "../markers/types";

export const useText = (text?: string) => {
    const [textSize, setTextSize] = useState({ width: 0, height: 0 });
    const { fontSize, color } = useContext(MarkerStyleContext);

    const textStyleRef = useRef<PIXI.TextStyle>(new PIXI.TextStyle({
        align: 'center',
        fontSize,
        fontFamily: 'TheSansC4s',
        fill: color,
    }));

    useEffect(() => {
        if (text && text.length > 0) {
            const textSize = PIXI.TextMetrics.measureText(text, textStyleRef.current);
            textSize.width = textSize.width / 2;
            textSize.height = textSize.height / 2;
            setTextSize(textSize);
        }
    }, [text]);

    return { text, textStyle: textStyleRef.current, textSize }
}