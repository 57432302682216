import React from 'react';
import { FilledSelect as Select, SelectItem as Option } from '@orbit/select';

import { useFlameRating } from './hooks';

export const FlameRating: React.FC<{ disabled: boolean }> = (props: { disabled: boolean }) => {
    const { disabled: locked } = props;
    const { label, onFlameRatingChange, flameRatingOptions, disabled, flameRatingValue } = useFlameRating();
    return (
        <Select className="field" label={label} value={flameRatingValue} disabled={disabled || locked} onChange={onFlameRatingChange} palette="primary" helperText="" units="">
            {flameRatingOptions.map(fr => <Option key={fr.value} value={fr.value}>{fr.description}</Option>)}
        </Select>
    )
}