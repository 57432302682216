import { FormControlLabel, Switch } from '@mui/material';
import { FilledSelect as Select, SelectItem as Option } from '@orbit/select';
import { FilledTextField } from "@orbit/text-field";
import i18next from 'i18next';
import React from 'react';
import { LocalizationKeys } from '../../../../../../../../locales/types';
import { connect, WorkspaceState } from '../../../../../../../redux/reducers';
import { currentBuildFiberTypeSelector, sscFeederOptionsSelector, sscFiberCountSelector, sscGripSelector } from '../../../../../../../redux/ssc/selectors';
import { convertTo, convertToDisplay, decimalPlacesBasedOnUnit, getDecimalPlaces, getUnitsName, roundToDecimalPoint } from '../../../../header/components/units-of-measure-container/UnitsOfMeasure';
import { enableNextStep, setCurrentStep } from '../../../redux/reducers';
import { ConnectorTypes, IConnectorType, IFiberCount, StaggerLengthTypes, validateStaggerValue, validateUnSignedDecimal, validateWholeNumber } from '../../../redux/types';
import { BLengthSection } from '../components/blength-section/BLengthSection';
import { CustomBLength } from '../components/blength-section/components/blength-custom-section/custom/CustomBLength';
import { UniformBLengthLength } from '../components/blength-section/components/blength-custom-section/uniform/UniformBLength';
import { ConfigurationType } from '../components/configuration-type/ConfigurationType';
import { FiberCountAutocomplete } from '../components/fiber-count/FiberCountAutocomplete';
import { FlameRating } from '../components/flame-rating/FlameRating';
import { StaggerAutocomplete } from '../components/stagger/StaggerAutocomplete';
import { ISourceSetupProps, ISourceSetupState } from './redux/types';

function mapStateToProps(state: WorkspaceState) {
    let { source, configurationType } = state.builds.currentBuild!;
    const { unit } = state.unitsOfMeasureContainer;
    let { context } = state.viewport;
    let { applyEditChanges, stepCount, isNextEnabled } = state.wizard;
    const fiberType = currentBuildFiberTypeSelector(state)
    const maxDecimalPlaces = decimalPlacesBasedOnUnit(unit);
    const sscFiberCount = sscFiberCountSelector(state);
    const sscGrip = sscGripSelector(state);
    const sscFeederOptions = sscFeederOptionsSelector(state)

    return {
        source, configurationType, unit, maxDecimalPlaces,
        context, applyEditChanges, stepCount, isNextEnabled,
        sscFiberCount, sscGrip, fiberType,
        sscFeederOptions
    }
}

const mapDispatchToProps = {
    enableNextStep,
    setCurrentStep
}

type props = Partial<ReturnType<typeof mapStateToProps>> & Partial<typeof mapDispatchToProps> & ISourceSetupProps;

class SourceSetupComponent extends React.Component<props, ISourceSetupState> {

    public componentDidUpdate(prevProps: props, prevState: ISourceSetupState) {
        if (prevProps.configurationType !== this.props.configurationType) {
            this.updateConfigurationType(prevProps);
        }

        if (this.props.unit !== prevProps.unit) {
            this.ConvertValuesByUnits();
        }
        const bLengthRange = this.getBLegLengthRange()
        const customB = !!(!this.props.source!.customBLength || !this.props.source!.groups
            .some(g => !g.lengthB || convertTo(g.lengthB, this.props.unit).value > bLengthRange.maxLength || convertTo(g.lengthB, this.props.unit).value < bLengthRange.minLength));

        const isValid = this.state.isFiberCountValid && this.state.isALengthValid && this.state.isBLengthValid && this.state.isSLengthValid && this.state.isGroupCountValid && customB
        const dynamicUpdate = prevState !== this.state && isValid;
        if (isValid) {
            this.props.enableNextStep!(true);
            if (dynamicUpdate) this.props.applyChanges!(this.state);
        }
        else if (this.props.isNextEnabled) {
            this.props.enableNextStep!(false);
        }

        if (this.props.applyEditChanges) {
            this.props.setCurrentStep!(this.props.stepCount! + 1);
        }
    }

    constructor(props: props) {
        super(props);
        const defaultConnectorType = ConnectorTypes[0];
        const fiberCounts = this.props.sscFiberCount!.fiberCountOptions
        const source = this.props.source!
        const connectorTypes = this.props.sscFeederOptions!.connectorTypes ?? []
        const connectorType = connectorTypes.find(x => x.type === source.groups[0].type) ?? defaultConnectorType;
        const buildFiberCount = { key: source.fiberCount!.toString(), description: i18next.t(LocalizationKeys.CustomFiberCountValue, { fiberCount: source.fiberCount }), count: source.fiberCount! };
        const fiberCount: IFiberCount = fiberCounts.find(x => x.count === source.fiberCount) ? fiberCounts.find(x => x.count === source.fiberCount)! : buildFiberCount
        const fiberCountField = fiberCount.count.toString();
        const groupCount = source.groups.length;
        const connectorCount = groupCount ? source.groups[0].connectors.length : 0;
        const stagger = StaggerLengthTypes.find(x => x.value === source.groups[0].stagger)!;

        const groupCountField = groupCount.toString();
        const connectorCountField = connectorCount.toString();
        const aLengthField = this.props.source?.lengthA && this.props.unit ?
            convertToDisplay(this.props.source.lengthA, this.props.unit) : '';
        const bLengthField = this.props.source?.lengthB && this.props.unit ?
            convertToDisplay(this.props.source.lengthB, this.props.unit) : '';
        const sLengthField = this.props.source?.groups[0].stagger && this.props.unit ?
            convertToDisplay(this.props.source.groups[0].stagger, this.props.unit) : '';

        const initialState: ISourceSetupState = {
            connectorType, configurationType: props.configurationType,
            connectorTypes: connectorTypes.filter(c => !(fiberCount.count % c.fiberCount)),
            fiberCount, fiberCountField, groupCount, fiberType: props.fiberType,
            connectorCount, aLength: source.lengthA, bLength: source.lengthB,
            sLength: stagger === undefined ? source.groups[0].stagger! : stagger.value,
            gripOnEnd: source.gripOnEnd, unitOfMeasure: props.unit, groupCountField,
            connectorCountField, aLengthField, bLengthField, sLengthField
        };

        let newState = this.validateALength(initialState, aLengthField)
        newState = this.validateFiberCount(newState, fiberCountField)
        this.state = { ...newState }
        const isValid = this.state.isFiberCountValid && this.state.isGroupCountValid && this.state.isALengthValid && this.state.isBLengthValid && this.state.isSLengthValid
        this.props.enableNextStep!(isValid || false);
    }

    private ConvertValuesByUnits = () => {
        const aLengthField = this.state.aLengthField?.length && this.props.source?.lengthA && this.props.unit ?
            convertToDisplay(this.props.source?.lengthA, this.props.unit) : "";
        const bLengthField = this.state.bLengthField?.length && this.props.source?.lengthB && this.props.unit ?
            convertToDisplay(this.props.source.lengthB, this.props.unit) : "";
        const sLengthField = this.state.sLengthField?.length && this.props.source?.groups[0].stagger && this.props.unit ?
            convertToDisplay(this.props.source?.groups[0].stagger, this.props.unit) : "";
        let newState = this.validateALength(this.state, aLengthField)
        newState = this.validateBLength(newState, bLengthField, sLengthField)
        this.setState(newState)
    }

    private validateFiberCount = (oldState: ISourceSetupState, fiberCountField?: string): ISourceSetupState => {
        if (!fiberCountField || !validateWholeNumber(fiberCountField)) return oldState;
        const fiberCounts = this.props.sscFiberCount!.fiberCountOptions
        const fiberCountValue = Number(fiberCountField)
        let selectedFiberCount = fiberCounts.find(x => x.count === fiberCountValue)!;
        const minFiberCount = this.props.sscFiberCount!.minFiberCount;
        const maxFiberCount = this.props.sscFiberCount!.maxFiberCount;

        let isFiberCountValid = true;
        let fiberCountErrorText = '';
        if (!selectedFiberCount) {
            const fiberCountRanges = this.props.sscFiberCount!.fiberCountRanges;
            const connectorTypes = this.props.sscFeederOptions?.connectorTypes ?? []
            const filteredConnectorTypes: IConnectorType[] = connectorTypes.filter(x => !(fiberCountValue % x.fiberCount));
            selectedFiberCount = { count: fiberCountValue, key: fiberCountValue.toString(), description: i18next.t(LocalizationKeys.CustomFiberCount) }
            if (fiberCountField.length === 0) {
                fiberCountErrorText = i18next.t(LocalizationKeys.MissingValue);
                isFiberCountValid = false;
            }
            else if (fiberCountValue < minFiberCount) {
                fiberCountErrorText = i18next.t(LocalizationKeys.ValueLesserThan, { value: minFiberCount });
                isFiberCountValid = false;
            } else if (fiberCountValue > maxFiberCount) {
                fiberCountErrorText = i18next.t(LocalizationKeys.ValueGreaterThan, { value: maxFiberCount });
                isFiberCountValid = false;
            }
            else if (fiberCountValue % 2 !== 0) {
                fiberCountErrorText = i18next.t(LocalizationKeys.RestrictedFiberCount);
                isFiberCountValid = false;
            }
            else if (!filteredConnectorTypes.length) {
                isFiberCountValid = false;
                fiberCountErrorText = i18next.t(LocalizationKeys.NoConnectorsAvailable);
            }
            else if (!fiberCountRanges.length) {
                fiberCountErrorText = i18next.t(LocalizationKeys.RestrictedFiberCountOptions);
                isFiberCountValid = false;
            }
        }
        return this.validateConnectorTypes({ ...oldState, fiberCount: selectedFiberCount, fiberCountField, fiberCountErrorText, isFiberCountValid }, oldState.connectorType)
    }

    private validateConnectorTypes = (oldState: ISourceSetupState, connectorType: IConnectorType): ISourceSetupState => {
        const fiberCount = oldState.fiberCount.count
        if (!fiberCount || fiberCount < 0 || !oldState.isFiberCountValid) return oldState;
        const connectorTypes = this.props.sscFeederOptions!.connectorTypes.filter(c => !(fiberCount % c.fiberCount))
        const existingConnectorType = connectorTypes.find(c => c.key === connectorType.key)
        const validConnectorType = existingConnectorType || (connectorTypes.length && connectorTypes[0]) || ConnectorTypes[0]
        const oldGroupCount = oldState.groupCount ?? 1
        const groupCount = !(fiberCount % (oldGroupCount * validConnectorType.fiberCount)) ? oldState.groupCountField : "1"
        return this.validateGroupCount({ ...oldState, connectorTypes, connectorType: validConnectorType }, groupCount)
    }
    private onFiberCountValueChanged = (e: React.ChangeEvent<{}>, value: string): void => {
        const fiberCountKey = this.state.fiberCount.key;
        if (fiberCountKey === value) {
            return;
        }
        const newState = this.validateFiberCount(this.state, value)
        this.setState(newState);
    }

    private onFiberCountInputChanged = (e: React.ChangeEvent<{}>, value: string): void => {
        if (!validateWholeNumber(value) || this.state.fiberCount.key === value) {
            return;
        }
        const newState = this.validateFiberCount(this.state, value)
        this.setState(newState)
    }

    private onStaggerValueChanged = (e: React.ChangeEvent<{}>, sLength: string): void => {
        const newState = this.validateSLength(this.state, sLength)
        this.setState(newState)
    };

    private onStaggerInputChanged = (e: React.ChangeEvent<{}>, sLength: string): void => {
        const newState = this.validateSLength(this.state, sLength)
        this.setState(newState)
    }

    private onConnectorTypeChange = (e: React.ChangeEvent<any>): void => {
        const target = e.target as HTMLSelectElement | HTMLOptionElement;
        const currentValue = this.state.connectorType.key;
        if (currentValue === target.value) {
            return;
        }
        const connectorTypes = this.props.sscFeederOptions?.connectorTypes ?? []
        const selectedConnectorType = connectorTypes.find(x => x.key === target.value);
        if (selectedConnectorType) {
            const newState = this.validateConnectorTypes(this.state, selectedConnectorType)
            this.setState(newState)
        }
    }

    private onFiberTypesChange = (e: React.ChangeEvent<any>) => {
        const target = e.target as HTMLSelectElement | HTMLOptionElement;
        this.setState({ fiberType: target.value })
    }

    private onGroupCountChange = (e: React.FormEvent<any>): void => {
        const entry = e.currentTarget.value;
        const newState = this.validateGroupCount(this.state, entry)
        this.setState(newState)
    }

    private validateALength = (oldState: ISourceSetupState, aLengthField?: string): ISourceSetupState => {
        const validFieldValue = aLengthField !== undefined && validateUnSignedDecimal(aLengthField) && this.props.maxDecimalPlaces !== undefined && getDecimalPlaces(aLengthField) <= this.props.maxDecimalPlaces
        if (!validFieldValue) return oldState;
        const aLengthRange = this.props.sscFeederOptions!.aLengthRange;
        let isALengthValid = true;
        let value = Number(aLengthField);
        let aLengthErrorText = ''
        if (aLengthField === '') {
            aLengthErrorText = i18next.t(LocalizationKeys.MissingValue);
            isALengthValid = false;
        } else if (value < aLengthRange.min) {
            aLengthErrorText = i18next.t(LocalizationKeys.ValueLesserThan, { value: roundToDecimalPoint(aLengthRange.min, 4, false) }) + " " + this.props.unit!;
            isALengthValid = false;
        } else if (value > aLengthRange.max) {
            aLengthErrorText = i18next.t(LocalizationKeys.ValueGreaterThan, { value: roundToDecimalPoint(aLengthRange.max, 4, false) }) + " " + this.props.unit!;
            isALengthValid = false;
        }
        const unit = this.props.unit;
        const aLength = unit ? { value, unit } : undefined;
        return { ...oldState, isALengthValid, aLengthField, aLengthErrorText, aLength }
    }

    private validateBLength = (oldState: ISourceSetupState, bLengthField: string, sLengthField?: string): ISourceSetupState => {
        const validFieldValue = this.validateField(bLengthField);
        if (validFieldValue === undefined || !oldState.isGroupCountValid) return oldState;
        let isBLengthValid = true;
        let value = Number(bLengthField);
        let bLengthErrorText = ''
        const { minLength, maxLength } = this.getBLegLengthRange();

        if (bLengthField === '') {
            bLengthErrorText = i18next.t(LocalizationKeys.MissingValue);
            isBLengthValid = false;
        } else if (value < minLength) {
            bLengthErrorText = i18next.t(LocalizationKeys.ValueLesserThan, { value: roundToDecimalPoint(minLength, 4, false) }) + " " + this.props.unit!;
            isBLengthValid = false;
        } else if (value > maxLength) {
            bLengthErrorText = i18next.t(LocalizationKeys.ValueGreaterThan, { value: roundToDecimalPoint(maxLength, 4, false) }) + " " + this.props.unit!;
            isBLengthValid = false;
        }

        const unit = this.props.unit;
        const bLength = unit ? { value, unit } : undefined;
        return this.validateSLength({ ...oldState, bLength, isBLengthValid, bLengthErrorText, bLengthField }, sLengthField ?? oldState.sLengthField)
    }

    private validateSLength = (oldState: ISourceSetupState, sLengthField?: string): ISourceSetupState => {
        const validFieldValue = this.validateField(sLengthField);
        if (!validFieldValue || !oldState.isBLengthValid) return oldState;
        const sLengthRange = this.props.sscFeederOptions!.staggerRange;
        const bLengthRange = this.props.sscFeederOptions!.legRange;
        const bLengthValue = oldState.bLength?.value ?? 0;
        const groupCount = oldState.groupCount ?? 0;
        const unit = this.props.unit!;
        const isSLengthEnabled = groupCount > 1;
        let sLengthFieldValue = isSLengthEnabled ? sLengthField : "0"

        const value = sLengthFieldValue ? Number.parseFloat(sLengthFieldValue) : 0;
        const staggerArgs = { bLengthRange, bLengthValue, groupCount, sLengthRange, sLengthValue: value, unit }
        const { valid: isSLengthValid, sLengthErrorText } = validateStaggerValue(staggerArgs);
        const sLength = { value, unit }

        return { ...oldState, sLength, isSLengthValid, sLengthErrorText, sLengthField: sLengthFieldValue, isSLengthEnabled }
    }

    private validateField = (field: string | undefined) => {
        const validFeildValue = field && validateUnSignedDecimal(field) && this.props.maxDecimalPlaces !== undefined && getDecimalPlaces(field) <= this.props.maxDecimalPlaces;
        return validFeildValue;
    }

    private getStaggerLengthRange = (value: number) => {
        const sLengthRange = this.props.sscFeederOptions!.staggerRange;
        const bLengthRange = this.props.sscFeederOptions!.legRange;
        const bLengthValue = this.state.bLength?.value ?? 0;
        const groupCount = this.state.groupCount ?? 0;
        const staggerArgs = { bLengthRange, bLengthValue, groupCount, sLengthRange, sLengthValue: value, unit: this.props.unit! }
        const staggerLengthRange = validateStaggerValue(staggerArgs);
        return { ...staggerLengthRange };
    }

    private getBLegLengthRange = () => {
        const bLengthRange = this.props.sscFeederOptions!.legRange;
        const minLength = bLengthRange.min;
        const maxLength = bLengthRange.max;
        return { bLengthRange, minLength, maxLength };
    }

    private validateGroupCount = (oldState: ISourceSetupState, groupCountField?: string): ISourceSetupState => {
        if (groupCountField === undefined || !validateWholeNumber(groupCountField)) return oldState
        let isGroupCountValid = true;
        let groupCount = groupCountField ? Number(groupCountField) : 0;
        let groupCountErrorText = '';

        const groupCountRange = this.props.sscFeederOptions!.groupCounts!;
        const maxGroupCount = Math.floor(oldState.fiberCount.count / oldState.connectorType.fiberCount!);
        const maxValidGroupCount = Math.min(groupCountRange.max, maxGroupCount);
        const fiberCount = oldState.fiberCount.count;
        const connectorFiberCount = oldState.connectorType.fiberCount
        if (!groupCount) {
            groupCountErrorText = i18next.t(LocalizationKeys.MissingValue);
            isGroupCountValid = false;
        } else if (groupCount < groupCountRange.min) {
            groupCountErrorText = i18next.t(LocalizationKeys.ValueLesserThan, { value: groupCountRange.min });
            isGroupCountValid = false;
        } else if (groupCount > maxValidGroupCount) {
            groupCountErrorText = i18next.t(LocalizationKeys.ValueGreaterThan, { value: maxValidGroupCount });
            isGroupCountValid = false;
        }
        else if (fiberCount % (groupCount * connectorFiberCount)) {
            groupCountErrorText = i18next.t(LocalizationKeys.InvalidFiberEntry, { fiberCount });
            isGroupCountValid = false;
        }

        const newConnectorCount = groupCount ? Math.floor((oldState.fiberCount.count / (oldState.connectorType.fiberCount! * groupCount))) : oldState.connectorCount;

        return this.validateBLength({ ...oldState, groupCount, isGroupCountValid, groupCountErrorText, groupCountField, connectorCount: newConnectorCount, connectorCountField: newConnectorCount?.toString() }, oldState.bLengthField ?? '')
    }

    private onALengthChange = (e: React.ChangeEvent<any>): void => {
        const newState = this.validateALength(this.state, e.currentTarget.value)
        this.setState(newState)
    }

    private onBLengthChange = (e: React.ChangeEvent<any>): void => {
        const newState = this.validateBLength(this.state, e.currentTarget.value)
        this.setState(newState)
    }

    private onGripChange = (): void => {
        this.setState({ gripOnEnd: !this.state.gripOnEnd });
    }

    private updateConfigurationType = (prevProps: props) => {
        const oldConfigurationType = prevProps.configurationType;
        const newConfigurationType = this.props.configurationType;

        const fiberCountHasChanged = prevProps.source!.fiberCount !== this.props.source!.fiberCount
        const hasChanged = (newConfigurationType !== oldConfigurationType) || fiberCountHasChanged;
        if (hasChanged) {
            const configurationType = newConfigurationType;
            const newState = this.validateFiberCount({ ...this.state }, this.props.source!.fiberCount?.toString())
            this.setState({ ...newState, configurationType })
        }
    }

    render() {
        const unit = getUnitsName(this.props.unit!);

        const { options: staggerOptions } = this.getStaggerLengthRange(this.state.sLength.value);
        const disabled: boolean = !!this.props.disabled;
        const fiberTypes = this.props.sscFeederOptions?.fiberTypes ?? [];
        return (
            <div className="setup">
                <div className="field-container">
                    <ConfigurationType disabled={disabled} />
                </div>
                <div className="field-container">
                    <FiberCountAutocomplete
                        disabled={disabled}
                        value={this.state.fiberCount.key!}
                        onValueChanged={this.onFiberCountValueChanged}
                        onInputChanged={this.onFiberCountInputChanged}
                        isInputValid={this.state.isFiberCountValid || false}
                        inputErrorText={this.state.fiberCountErrorText || ""}
                    />
                </div>
                <div className="field-container">
                    <FlameRating disabled={disabled} />
                </div>
                <div className="field-container">
                    <Select label={i18next.t(LocalizationKeys.FiberType)} className="field" onChange={(e) => this.onFiberTypesChange(e)} value={this.state.fiberType} disabled={fiberTypes.length <= 1 || disabled} palette="primary" units='' helperText=''>
                        {fiberTypes.map(f => <Option key={f.id} value={f.id}>{f.description}</Option>)}
                    </Select>
                </div>
                <div className="field-container">
                    <Select label={i18next.t(LocalizationKeys.ConnectorType)} className="field" onChange={(e) => this.onConnectorTypeChange(e)} value={this.state.connectorType.key} disabled={disabled || !this.state.connectorTypes.length} palette="primary" units='' helperText=''>
                        {this.state.connectorTypes.map(c => <Option key={c.key} value={c.key}>{c.description}</Option>)}
                    </Select>
                </div>
                <div className="field-container horizontal">
                    <div className="group-field field">
                        <FilledTextField palette='primary'
                            disabled={disabled}
                            label={i18next.t(LocalizationKeys.NbGroups)}
                            helperText={this.state.isGroupCountValid ? "" : this.state.groupCountErrorText}
                            error={!this.state.isGroupCountValid} value={this.state.groupCountField}
                            onChange={(e) => this.onGroupCountChange(e)} />
                    </div>
                    <div className="connector-separator">
                        X
                    </div>
                    <div className="cpg-field field">
                        <FilledTextField palette='primary'
                            disabled={true}
                            helperText={""}
                            label={i18next.t(LocalizationKeys.NbCPG)}
                            value={this.state.connectorCount!} />
                    </div>
                </div>
                <div className="field-container">
                    <FilledTextField palette='primary'
                        disabled={true}
                        helperText={""}
                        label={i18next.t(LocalizationKeys.NbSrcConnectors)}
                        value={this.state.groupCount! * this.state.connectorCount!} />
                </div>
                <div className="field-container">
                    <FilledTextField palette='primary'
                        disabled={disabled}
                        label={i18next.t(LocalizationKeys.LengthAWithUnit, { position: 0, unit: unit })}
                        helperText={this.state.isALengthValid ? "" : this.state.aLengthErrorText}
                        error={!this.state.isALengthValid} value={this.state.aLengthField}
                        onChange={(e) => this.onALengthChange(e)} />
                </div>
                <div className="field-container">
                    <BLengthSection trunk={{ position: 0, ...this.props.source }} disabled={disabled}>
                        <UniformBLengthLength>
                            <div className="field-container horizontal">
                                <div className="lengthB-field field">
                                    <FilledTextField palette='primary'
                                        disabled={disabled}
                                        label={i18next.t(LocalizationKeys.LengthBWithUnit, { position: 0, unit: unit })}
                                        helperText={this.state.isBLengthValid ? "" : this.state.bLengthErrorText}
                                        error={!this.state.isBLengthValid} value={this.state.bLengthField}
                                        onChange={(e) => this.onBLengthChange(e)} />
                                </div>
                                <StaggerAutocomplete
                                    value={this.state.sLengthField ?? ""}
                                    position={0}
                                    disable={!this.state.isSLengthEnabled || disabled}
                                    onValueChanged={this.onStaggerValueChanged}
                                    onInputChanged={this.onStaggerInputChanged}
                                    isInputValid={this.state.isSLengthValid ?? false}
                                    inputErrorText={this.state.sLengthErrorText ?? ""}
                                    options={staggerOptions} />
                            </div>
                        </UniformBLengthLength>
                        <CustomBLength disabled={disabled} />
                    </BLengthSection>
                </div>
                <div className="switch-container">
                    <FormControlLabel className="switch-form-control" control={<Switch checked={this.state.gripOnEnd} onChange={(_) => this.onGripChange()} value="checked" disableRipple={true} disableTouchRipple={true} disabled={disabled} />} label={i18next.t(LocalizationKeys.GripOnEnd)} disabled={disabled} />
                </div>
            </div>
        );
    }
}

export const SourceSetup = connect(mapStateToProps, mapDispatchToProps)(SourceSetupComponent)