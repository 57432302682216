 import { FiberColors, getFillOpacity, IConnectorPinDrawingProps, IConnectorPinProps, ConnectorTemplateProps, IConnectorTemplateProps, MMCFiberColors } from './types';
import { useCallback, useContext, useMemo } from "react";
import { FiberMappingContext, IFiberMap } from "../../redux/types";
import { breakFiberMap, handleFiberMap, handleUnusedPin, setSelectedPinIndex } from "../../redux/reducer";
import { PolarityContext } from "../../../redux/types";
import { getConnectorType } from "../../../../wizard/redux/types";
import { getTextColor } from "../../../../../../../../ui/dialog/color/types";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from '../../../../../../../../locales/types';
import { SectionContext, Feeder, Drop } from '../../../../../../../../pixi/components/build/types';
import { isMMC16 } from '../../../../../../../../pixi/factories/Texture';

const colorDisabled = "#E0E0E0";
const colorUnused = "#E11725";
const grey = "#606065";

const LCFiberCount = 2;
const LCStartX = 17;
const LCStartY = 40;
const MMCStartX = 32;
const MMCStartY = 68;
const MMC24StartY = 38;
const MTPStartX = 18;
const MTP24StartY = 40;
const MTP12StartY = 70;
const pinStep = 56;

export const useConnectorPin = (props: IConnectorPinProps) => {
    const { connectorType, pinIndex: index, disabled } = props;
    const section = useContext(SectionContext);
    const { state, dispatch } = useContext(FiberMappingContext);
    const { state: polarityState } = useContext(PolarityContext);

    const pinProps = useMemo(() => {
        let className = "";
        let tooltip = "";
        let fiberMap: IFiberMap | undefined;
        let selected = false;
        let assigned = false;
        let isAssignedToTAP = false;
        let unused = false;
        let sourceType = "";

        if (section === Feeder) {
            fiberMap = state.mapping.find(m => m.sourcePin.index === index && m.sourcePin.connectorIndex === state.navigation.source.currentIndex);
            selected = state.navigation.selectedPinIndex === index;
            if (fiberMap) {
                assigned = fiberMap.sourcePin.connectorIndex === state.navigation.source.currentIndex;
                const tap = polarityState.connectorAssignment.destinations[fiberMap.destinationPin.tapIndex];
                if (assigned && tap && tap.connectors[fiberMap.destinationPin.connectorIndex]) {
                    const connectorIndex = tap.connectors[fiberMap.destinationPin.connectorIndex].index + 1;
                    tooltip = "T" + tap.tapIndex + " C" + connectorIndex;
                }
            }
            unused = state.unused.sourcePins.find(p => p.index === index && p.connectorIndex === state.navigation.source.currentIndex) !== undefined;
        } else {
            fiberMap = state.mapping.find(m => m.destinationPin.index === index && m.destinationPin.tapIndex === state.navigation.destination.taps.currentIndex && m.destinationPin.connectorIndex === state.navigation.destination.connectors.currentIndex);
            if (fiberMap) {
                assigned = fiberMap.destinationPin.tapIndex === state.navigation.destination.taps.currentIndex && fiberMap.destinationPin.connectorIndex === state.navigation.destination.connectors.currentIndex;
                if (assigned) {
                    const srcAssignment = polarityState.connectorAssignment.source.connectors[fiberMap.sourcePin.connectorIndex]
                    const connectorIndex = srcAssignment.index + 1;
                    tooltip = "F" + connectorIndex;
                    sourceType = srcAssignment.connectorType
                }
            }
            unused = state.unused.destinationPins.some(p => p.index === index && p.tapIndex === state.navigation.destination.taps.currentIndex && p.connectorIndex === state.navigation.destination.connectors.currentIndex);
        }

        if (fiberMap) {
            isAssignedToTAP = fiberMap.destinationPin.tapIndex === state.navigation.destination.taps.currentIndex && fiberMap.destinationPin.connectorIndex === state.navigation.destination.connectors.currentIndex;
        }

        className = disabled ? "connector-pin disabled" : selected ? "connector-pin selected" : "connector-pin";

        return {
            className,
            tooltip,
            fiberMap,
            selected,
            assigned,
            isAssignedToTAP,
            disabled,
            unused,
            sourceType
        }
    }, [section, state, index, polarityState.connectorAssignment, disabled]);

    const { color, isBottomPin } = getPinInfo(index, connectorType);
    const drawingProps: IConnectorPinDrawingProps = useMemo(() => {
        let fill = color.hex;
        let fillOpacity = 0;
        let stroke = color.hex;
        let text = index.toString();
        let textColor = grey;
        let strokeDashArray = section === Feeder && isBottomPin ? "4" : "";

        if (pinProps.assigned) {
            fillOpacity = getFillOpacity(color)
            if (pinProps.isAssignedToTAP) {
                fillOpacity = 1;
                textColor = getTextColor(color).hex
            }
            if (section === Drop && pinProps.fiberMap) {
                const sourcePinIndex = pinProps.fiberMap.sourcePin.index;
                const { color: sourceColor, isBottomPin: sourceIsBottomPin } = getPinInfo(sourcePinIndex, pinProps.sourceType);
                const isAssignedToFeeder = pinProps.fiberMap.sourcePin.connectorIndex === state.navigation.source.currentIndex;
                fill = sourceColor.hex;
                fillOpacity = isAssignedToFeeder ? 1 : 0.2;
                stroke = sourceColor.hex;
                text = sourcePinIndex.toString();
                textColor = isAssignedToFeeder ? getTextColor(sourceColor).hex : grey;
                strokeDashArray = sourceIsBottomPin ? "4" : ""
            }
        } else {
            if (section === Drop) {
                fillOpacity = 0;
                stroke = grey;
                textColor = "none";
            }
        }

        if (pinProps.disabled) {
            fill = colorDisabled;
            fillOpacity = 1;
            stroke = colorDisabled;
        }

        if (pinProps.unused) {
            fillOpacity = 0;
            stroke = colorUnused;
            strokeDashArray = ""
        }

        return {
            fill,
            fillOpacity,
            stroke,
            strokeDashArray,
            text,
            textColor
        };
    }, [color, index, isBottomPin, section, pinProps, state.navigation.source.currentIndex]);

    const onClick = useCallback((e: any) => {
        e.stopPropagation();
        if (section === Feeder && !pinProps.selected) {
            dispatch(setSelectedPinIndex(index));
        } else {
            if (section === Drop && state.navigation.selectedPinIndex !== -1) {
                if (!pinProps.unused && !pinProps.assigned) {
                    dispatch(handleFiberMap(index));
                }
            } else {
                if (pinProps.unused) {
                    dispatch(handleUnusedPin({ section, pinIndex: index }));
                } else {
                    if (pinProps.assigned) {
                        if (section === Feeder && pinProps.selected) {
                            dispatch(breakFiberMap());
                        }
                    } else {
                        dispatch(handleUnusedPin({ section, pinIndex: index }));
                    }
                }
            }
        }
    }, [section, pinProps.selected, dispatch, index, state.navigation.selectedPinIndex, pinProps.unused, pinProps.assigned]);

    return {
        tooltip: pinProps.tooltip,
        className: pinProps.className,
        onClick,
        drawingProps,
        unused: pinProps.unused,
        disabled: pinProps.disabled,
    };
}

const getPinInfo = (pinIndex: number, connectorType: string) => {
    let isBottomPin = pinIndex > 12;
    let color = FiberColors[(pinIndex - 1) % 12];
    if (isMMC16(connectorType)) {
        color = MMCFiberColors[pinIndex - 1];
        isBottomPin = false;
    }
    return { color, isBottomPin };
}

export const useTemplate = () => {
    const { state, dispatch } = useContext(FiberMappingContext);

    const onClick = useCallback(() => {
        if (state.navigation.selectedPinIndex !== -1) {
            dispatch(setSelectedPinIndex(-1));
        }
    }, [state.navigation.selectedPinIndex, dispatch]);

    return { onClick };
}

export const useLCTemplate = (props: IConnectorTemplateProps) => {
    const { onClick } = useTemplate();
    const disabled = props.disabled;
    const { t } = useTranslation();

    const label = t(disabled ? LocalizationKeys.LCUnibootViewOnly : LocalizationKeys.LCUniboot);

    const pinIndexes = Array.from(Array(LCFiberCount).keys());
    const pins = pinIndexes.map(i => {
        const x = LCStartX + i * pinStep;
        const y = LCStartY;
        return {
            position: { x, y },
            index: i + 1,
            disabled: false,
        }
    });

    return { onClick, pins, label, disabled };
}

export const useMMCTemplate = (props: ConnectorTemplateProps) => {
    const { type } = props;
    const { onClick } = useTemplate();
    const disabled = props.disabled;
    const { t } = useTranslation();
    
    const { fiberCount } = getConnectorType(type);
    const isMMC16F = isMMC16(type);
    const nbPins = isMMC16F ? fiberCount : 24;

    const label = t(disabled ? LocalizationKeys.MmcFiberCountViewOnly : LocalizationKeys.MmcFiberCount, { fiberCount });

    const pinIndexes = Array.from(Array(nbPins).keys());
    const step = isMMC16F ? pinStep: 76.5;
    const pins = pinIndexes.map(i => {
        const pinColumnIndex = isMMC16F ? i : i % 12;
        const x = MMCStartX + pinColumnIndex * step;
        let y = i >= 12 ? MMC24StartY + pinStep : MMC24StartY;
        if (isMMC16F) {
            y = MMCStartY;
        }
        const disabled = (fiberCount === 8 && ((i > 3 && i < 8) || i > 11));
        return {
            position: { x, y },
            index: i + 1,
            disabled,
        }
    });

    return { onClick, isMMC16F, pins, label, disabled, type };
}

export const useMTPTemplate = (props: ConnectorTemplateProps) => {
    const { type } = props;
    const { onClick } = useTemplate();
    const disabled = props.disabled;
    const { t } = useTranslation();
    
    const fiberCount = getConnectorType(type).fiberCount;
    const isMTP24 = fiberCount === 24;
    const nbPins = isMTP24 ? 24 : 12;

    const label = t(disabled ? LocalizationKeys.MtpFiberCountViewOnly : LocalizationKeys.MtpFiberCount, { fiberCount: fiberCount });

    const pinIndexes = Array.from(Array(nbPins).keys());
    const pins = pinIndexes.map(i => {
        const pinColumnIndex = i % 12;
        const x = MTPStartX + pinColumnIndex * pinStep;
        let y = MTP12StartY;
        if (isMTP24) {
            y = i >= 12 ? MTP24StartY + pinStep : MTP24StartY;
        }
        const disabled = (fiberCount === 8 && i > 3 && i < 8);
        return {
            position: { x, y },
            index: i + 1,
            disabled,
        }
    });

    return { onClick, isMTP24, pins, label, disabled, type };
}