import React from 'react';
import { Graphics, Container, Text } from '@inlet/react-pixi';
import { ILineMarkerProps } from '../line/types';
import { useBezierMarker } from './hooks';

export const BezierMarker: React.FC<ILineMarkerProps> = (props: ILineMarkerProps) => {
    const { text, textStyle, textPosition, drawBezierMarker } = useBezierMarker(props);

    return (
        <Container>
            <Graphics draw={drawBezierMarker} />
            <Text text={text} {...textPosition} style={textStyle} resolution={2} scale={0.5} />
        </Container>
    );
}

