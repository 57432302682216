import React from 'react';
import { useCustomization } from './hooks';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { FilledTextField } from '@orbit/text-field';

import './Customization.scss';

export const Customization: React.FC = () => {
    const { title, reverseProps, rackNumberProps, resetProps } = useCustomization();
    return (
        <div className="customization">
            <div className="title">
                {title}
            </div>
            <div className='controls'>
                <FormControlLabel label={reverseProps.label} control={<Checkbox {...reverseProps.checkboxProps} />} />
                <FilledTextField
                    palette="primary"
                    label={rackNumberProps.fieldProps.label}
                    value={rackNumberProps.inputProps.value}
                    onChange={rackNumberProps.inputProps.onChange}
                    />
            </div>
            <div className="button-container">
                <Button variant='text' {...resetProps.buttonProps}>
                    {resetProps.label}
                </Button>
            </div>
        </div>
    )
}