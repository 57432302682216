import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../../locales/types";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import { ViewportStatus } from "../../../../../../../pixi/components/viewport/redux/types";
import { setCurrentStep, setWizardDisplay, enableNextStep, setApplyEditChanges, saveBuildData } from "../../redux/reducers";
import { setCurrentBuild } from "../../../../../../redux/build/reducers";
import { setToolbarDisplay } from "../../../footer/components/toolbar/redux/reducers";
import { setViewportContext } from "../../../../../../../pixi/components/viewport/redux/reducers";
import { useCablePresetSnackbar } from "../../../cable-detail-presets/snackbar/hooks";
import { BuildService } from '../../../../../../services/build-service';
import { buildIdSelector, buildSessionIdSelector, currentBuildCatalogCodeSelector } from '../../../../../../selectors/build.selectors';
import { IGenericDialogProps } from '../../../../../../../ui/dialog/generic-dialog/types';
import { connectorAssignmentResetRequiredSelector, wizardSelector } from '../../redux/selectors';
import { useWebSave } from "../../../header/components/status/hooks";
import { currentStatusSelector } from "../../../header/components/status/redux/selectors";
import { WorkspaceStatus } from "../../../header/components/status/redux/types";
import { sscConfigSessionBusySelector, sscSessionBusySelector } from "../../../../../../redux/ssc/selectors";
import { forceReloadConnectorMaps } from "../../../../../../redux/build/connector/polarity/reducer";
import { viewportContextSelector } from "../../../../../../../pixi/components/viewport/redux/selectors";
import { EDSSessionService } from "../../../../../../services/eds-session/eds-session-service";
import { workspaceSelector } from "../../../../../../selectors/root.selectors";
import { connectorAssignmentSelector } from "../../../../../../redux/build/connector/polarity/selectors";
import { setStatusState } from "../../../header/components/status/redux/reducer";
import { setConfigStatus, setSessionWarnings } from "../../../../../../redux/ssc/reducer";

export const useWizardFooter = () => {
    const { t } = useTranslation();
    const { viewport, wizard } = useSelector(workspaceSelector);
    const sessionId = useSelector(buildSessionIdSelector)
    const { context } = viewport;
    const { currentStep, isNextEnabled } = wizard;
    const { movePrevious, cancelEdit, moveNext, connectorAssignmentResetRequired, applyChanges, disabled } = useButtonEvents();
    const connectorAssignmentMaps = useSelector(connectorAssignmentSelector)
    const buildId = useSelector(buildIdSelector);
    const dispatch = useDispatch()

    const [footerClassName, setFooterClassName] = useState("");

    const [previousClassName, setPreviousClassName] = useState("");
    const [previousHidden, setPreviousHidden] = useState(false);
    const [previousOutlined, setPreviousOutlined] = useState(true);
    const [previousDisabled, setPreviousDisabled] = useState(false);
    const [previousText, setPreviousText] = useState("");

    const [nextClassName, setNextClassName] = useState("");
    const [nextText, setNextText] = useState("");

    useEffect(() => {
        if (context === ViewportStatus.Editing) {
            setPreviousDisabled(false);
            setPreviousHidden(false);
            setNextText(t(LocalizationKeys.ApplyChanges));
            setFooterClassName("wizard-footer edit-mode");
            setPreviousClassName("cancel-button");
            setPreviousOutlined(false);
            setPreviousText(t(LocalizationKeys.Cancel));
            setNextClassName("apply-button");
        } else {
            setFooterClassName("wizard-footer");
            setPreviousClassName("back-button");
            setPreviousOutlined(true);
            setPreviousText(t(LocalizationKeys.Back));
            setNextClassName("next-button");
            if (currentStep === 1) {
                setPreviousHidden(true);
                setNextText(t(LocalizationKeys.Continue));
            } else {
                setPreviousHidden(false);
                setNextText(t(LocalizationKeys.Confirm));
            }
        }
    }, [context, t, currentStep]);

    const onPreviousClick = useCallback(() => {
        if (context === ViewportStatus.Editing) {
            cancelEdit();
        } else {
            movePrevious();
        }
    }, [context, cancelEdit, movePrevious]);

    const [showResetDialog, setShowResetDialog] = useState(false);

    const onNextClick = useCallback(async () => {
        if (context === ViewportStatus.Editing) {
            if (connectorAssignmentMaps.length && connectorAssignmentResetRequired) {
                setShowResetDialog(true);
            } else {
                applyChanges();
            }
        } else {
            moveNext();
        }
    }, [context, applyChanges, moveNext, connectorAssignmentResetRequired, connectorAssignmentMaps]);

    const onResetClose = useCallback(() => {
        setShowResetDialog(false);
    }, []);

    const onResetConfirm = useCallback(async () => {
        setShowResetDialog(false);
        dispatch(forceReloadConnectorMaps())
        buildId && await applyChanges().then(async (r) => {
            await new BuildService().deletePolarityConnectorMaps(buildId)
        });

        if (sessionId) {
            dispatch(setStatusState(WorkspaceStatus.Saving))
            const res = await new EDSSessionService().deletePolarityAllMap(sessionId);
            if (res.succesful && res.data) {
                const warnings = res.data.warnings
                const configStatus = res.data.configStatus
                dispatch(setSessionWarnings({ buildId, warnings }));
                dispatch(setConfigStatus({ buildId, configStatus }));
            }

            dispatch(setStatusState(WorkspaceStatus.Saved))
        }
    }, [applyChanges, buildId, sessionId, dispatch]);

    const resetDialogProps: IGenericDialogProps = {
        title: t(LocalizationKeys.ResetConnectorAssignment),
        display: showResetDialog,
        message: t(LocalizationKeys.ResetConnectorAssignmentOnEdit),
        onClose: onResetClose,
        closable: true,
        confirmText: t(LocalizationKeys.Continue),
        onConfirm: onResetConfirm,
        critical: true
    }

    return {
        footerClassName,
        previousClassName,
        previousHidden,
        previousOutlined,
        previousDisabled,
        onPreviousClick,
        previousText,
        nextClassName,
        onNextClick,
        nextText,
        isNextEnabled: isNextEnabled && !disabled,
        resetDialogProps,
    };
}

export const useButtonEvents = () => {
    const { wizard } = useSelector(workspaceSelector);
    const { currentStep, stepCount, savedBuildData } = wizard;
    const connectorAssignmentResetRequired = useSelector(connectorAssignmentResetRequiredSelector);
    const currentStatus = useSelector(currentStatusSelector);
    const catalogCode = useSelector(currentBuildCatalogCodeSelector);
    const configSessionBusy = useSelector(sscConfigSessionBusySelector);
    const sessionBusy = useSelector(sscSessionBusySelector)
    const { context: viewportContext } = useSelector(viewportContextSelector)
    const hasCatalogCode = catalogCode.length > 0;
    const isSessionBusy = viewportContext === ViewportStatus.Editing ? sessionBusy : configSessionBusy
    const locked = currentStatus === WorkspaceStatus.Locked;
    const disabled = hasCatalogCode || locked || isSessionBusy;
    const { setIsEditing } = useEditCallback();
    const { handleOpen: displayPresetSnackbar } = useCablePresetSnackbar();
    const { saveBuild } = useWebSave();
    const dispatch = useDispatch();

    const movePrevious = useCallback(() => {
        dispatch(setCurrentStep(currentStep - 1));
    }, [dispatch, currentStep]);

    const cancelEdit = useCallback(() => {
        setIsEditing(false);
        dispatch(setCurrentBuild(savedBuildData!));
        dispatch(setToolbarDisplay(true));
    }, [setIsEditing, dispatch, savedBuildData]);

    const moveNext = useCallback(async () => {
        dispatch(setCurrentStep(currentStep + 1));
        if (currentStep >= stepCount) {
            displayPresetSnackbar();
            saveBuild().then(_ => {
                dispatch(forceReloadConnectorMaps())
            });
            dispatch(setViewportContext(ViewportStatus.Active));
        }
    }, [dispatch, currentStep, stepCount, displayPresetSnackbar, saveBuild]);

    const applyChanges = useCallback(async () => {
        dispatch(setApplyEditChanges(true));
        await saveBuild();
    }, [dispatch, saveBuild]);

    return { movePrevious, cancelEdit, moveNext, connectorAssignmentResetRequired, applyChanges, disabled }
}

export const useEditCallback = () => {
    const dispatch = useDispatch();
    const { stepCount } = useSelector(wizardSelector);

    const setIsEditing = useCallback((isEditing: boolean) => {
        if (isEditing) {
            dispatch(setViewportContext(ViewportStatus.Editing));
            dispatch(setWizardDisplay(true));
            dispatch(enableNextStep(false));
        } else {
            dispatch(setViewportContext(ViewportStatus.Active));
            dispatch(setApplyEditChanges!(false));
            dispatch(setCurrentStep!(stepCount + 1));
            dispatch(setWizardDisplay!(false));
            dispatch(saveBuildData!(undefined));
        }
    }, [dispatch, stepCount]);

    return { setIsEditing };
}