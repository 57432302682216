import { PropsWithChildren } from "react";
import { ConnLC, ConnMTP8_NP, ConnMTP8_P, ConnMTP12_NP, ConnMTP12_P, ConnMTP12_FP_NP, ConnMTP12_FP_P, ConnMTP24_NP, ConnMTP24_P, ConnMTP24_FP_NP, ConnMTP24_FP_P, ConnMMC16_NP, ConnMMC16_P, ConnMMC24_NP, ConnMMC24_P, ConnMMC8_NP, ConnMMC8_P } from "../../../../../../../workspace/components/overlay/components/wizard/redux/types";
import { getConnectorTexture } from "../../../../../../factories/Texture";
import { pointEqual, IPoint } from "../../../../../../types";

export interface IFurcationProps {
    legProps: IConnectorLegProps[];
}

export interface IConnectorLegProps {
    location: IPoint;
    legEndpoint: IPoint;
    staggerPosition: number;
    text: string;
    connectorPosition: number;
    connectorColor?: string;
}

export const COLLAPSED_NBCONNECTOR = 4;
export const COLLAPSED_FIRST_GROUPINDEX = 1;
export const COLLAPSED_LAST_GROUPINDEX = 3;

export const FurcationContainer = "furcation-container";
export const ConnectorTypeLabel = "connector-type-label";
export const ExpandMarkers = "expand-markers";

export const STAGGER_OFFSET_Y = 50;
export const BORDER_COLOR = 0x484646;
export const FEEDER_BORDER_WIDTH = 8;
export const DROP_BORDER_WIDTH = 4;
export const CABLE_COLOR = 0xF4E74E;
export const FEEDER_CABLE_WIDTH = 6;
export const DROP_CABLE_WIDTH = 2;
export const DASH_WIDTH = 1;
export const DASH_COLOR = 0xFDFDFD

export const LegContainer = "leg-container";
export const Leg = "leg";
export const ConnectorIndexLabel = "connector-index-label";

export const getConnectorCableDrawWidth = (type: string): number => {
    switch (type) {
        case ConnLC.type:
        case ConnMMC8_NP.type:
        case ConnMMC8_P.type:
        case ConnMMC16_NP.type:
        case ConnMMC16_P.type:
        case ConnMMC24_NP.type:
        case ConnMMC24_P.type:
            return 4;
        case ConnMTP8_NP.type:
        case ConnMTP8_P.type:
        case ConnMTP12_NP.type:
        case ConnMTP12_P.type:
        case ConnMTP12_FP_NP.type:
        case ConnMTP12_FP_P.type:
        case ConnMTP24_NP.type:
        case ConnMTP24_P.type:
        case ConnMTP24_FP_NP.type:
        case ConnMTP24_FP_P.type:
            return 8;
        default:
            return 4;
    }
};

const legEqual = (prev: IConnectorLegProps, next: IConnectorLegProps) => {
    const location = pointEqual(prev.location, next.location);
    const legEndPoint = pointEqual(prev.legEndpoint, next.legEndpoint);
    const staggerPosition = prev.staggerPosition === next.staggerPosition;
    const text = prev.text === next.text;
    const connectorPosition = prev.connectorPosition === next.connectorPosition;
    const connectorColor =  prev.connectorColor === next.connectorColor;

    return location && legEndPoint && staggerPosition && text && connectorPosition && connectorColor;
}

export const areFurcationEqual = ({ legProps: prev }: PropsWithChildren<IFurcationProps>, { legProps: next }: PropsWithChildren<IFurcationProps>) => {
    if (prev.length !== next.length) return false;
    return prev.every((p, i) => legEqual(p, next[i]));
}

export function getSpriteLocation(type: string, location: IPoint, anchorHeight: number) {
    const { width, height } = getConnectorTexture(type);
    return { x: location.x - width * 0.5, y: anchorHeight - height };
}

export function getConnectorText(index: number, spriteCount: number, connectorCount: number) {
    if (index + 1 === spriteCount - 1) {
        return '...';
    } else if (index + 1 < spriteCount) {
        return (index + 1).toString();
    } else {
        return connectorCount!.toString();
    }
}

