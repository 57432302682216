import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from '../../../../../../../../../../locales/types';
import { AppDispatch } from "../../../../../../../../../redux/reducers";
import { useSscBuildSession } from "../../../../../../../../../redux/ssc/hooks";
import { buildSelectorFactory, buildsPageIndexSelector } from "../../../../../../../../../selectors/build.selectors";
import { duplicateProject } from "../../../../../redux/reducers";
import { setReloading } from "../../../redux/reducers";
import { ProjectDrawerContext } from "../../../redux/types";
import { IInputDialogProps } from "../generic-components/types";
import { IDuplicateDialogProps } from "./types";

export const useDuplicateDialog = (props: IDuplicateDialogProps) => {
    const { context, projectId } = props;
    const open = context.state.props.open;
    const build = useSelector(buildSelectorFactory(projectId));
    const pageIndex = useSelector(buildsPageIndexSelector);
    const { state: { search }, dispatch } = useContext(ProjectDrawerContext);
    const { updateBuildSession } = useSscBuildSession();
    const buildName = build && build.name !== "" && build.name ? build.name + "-Copy" : `Cable #${build.buildId!}-Copy` ?? "";
    const { t } = useTranslation();
    const storeDispatch = useDispatch<AppDispatch>();

    const onSave = useCallback(async (name: string) => {
        dispatch(setReloading(true));
        await storeDispatch(duplicateProject(projectId, name, updateBuildSession, pageIndex, search));
        dispatch(setReloading(false));
    }, [dispatch, storeDispatch, projectId, updateBuildSession, pageIndex, search]);

    const inputDialogProps: IInputDialogProps = {
        value: buildName,
        maxCharacterCount: 20,
        cancelProps: {
            label: t(LocalizationKeys.Cancel),
        },
        saveProps: {
            label: t(LocalizationKeys.Save),
            onClick: onSave,
        },
        title: t(LocalizationKeys.DuplicateCable),
        inputFieldLabel: t(LocalizationKeys.CableNewName)
    }

    return { open, dialogContext: context, inputDialogProps };
}