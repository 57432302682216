import { IDialogHeaderProps } from "./types";
import { useCallback, useContext } from "react";
import { collapseDialog, DialogContext } from "../../../workspace/redux/dialog/reducer";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../locales/types";

export const useDialogHeader = (props: IDialogHeaderProps) => {
    const { icon, title, collapsible, closable, onClose } = props;
    const { state, dispatch } = useContext(DialogContext);
    const { collapsed } = state.props;
    const { t } = useTranslation();
    const collapseIcon = collapsed ? "expand_more" : "expand_less";
    const collapseTooltip = collapsed ? t(LocalizationKeys.Expand) : t(LocalizationKeys.Collapse);
    const onCollapse = useCallback(() => {
        if (dispatch) {
            dispatch(collapseDialog(!collapsed));
        }
    }, [dispatch, collapsed]);
    const closeTooltip = t(LocalizationKeys.Close);
    
    return { icon, title, collapsible, collapseIcon, collapseTooltip, onCollapse, closable, closeTooltip, onClose };
}