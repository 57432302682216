import { createSlice } from "@reduxjs/toolkit";
import { setSourceIndexAction, setSourceNavigationAction, setTAPConnectorsIndexAction, setTAPConnectorsNavigationAction, setTAPIndexAction, setTAPNavigationAction, setSelectedPinIndexAction, setFiberMappingAction, handleFiberMapAction, breakFiberMapAction, handleUnusedPinAction, clearAllAction, resetNavigationAction } from "./actions";
import { initialFiberMappingState } from "./types";

const fiberMappingSlice = createSlice({
    initialState: initialFiberMappingState,
    name: "fiberMapping",
    reducers: {
        setSourceNavigation: setSourceNavigationAction,
        setSourceIndex: setSourceIndexAction,
        setTAPNavigation: setTAPNavigationAction,
        setTAPIndex: setTAPIndexAction,
        setTAPConnectorsNavigation: setTAPConnectorsNavigationAction,
        setTAPConnectorsIndex: setTAPConnectorsIndexAction,
        setSelectedPinIndex: setSelectedPinIndexAction,
        setFiberMapping: setFiberMappingAction,
        handleFiberMap: handleFiberMapAction,
        breakFiberMap: breakFiberMapAction,
        handleUnusedPin: handleUnusedPinAction,
        clearAll: clearAllAction,
        resetNavigation: resetNavigationAction
    }
});

export const FiberMappingReducer = fiberMappingSlice.reducer;
export const { 
    setSourceNavigation,
    setSourceIndex,
    setTAPNavigation,
    setTAPIndex,
    setTAPConnectorsNavigation,
    setTAPConnectorsIndex,
    setSelectedPinIndex,
    setFiberMapping,
    handleFiberMap,
    breakFiberMap,
    handleUnusedPin,
    clearAll,
    resetNavigation
} = fiberMappingSlice.actions;