import React from 'react';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux';
import { hybrisCustomerSelector } from '../authentication/redux/selectors';

import './ToolItem.scss';

export interface IToolItemProps {
    className?: string,
    disabled?: boolean,
    source?: any,
    alt?: string,
    labelLeft?: boolean,
    labelRight?: boolean,
    labelBottom?: boolean,
    labelTop?: boolean,
    tooltip?: string,
    icon?: string,
    badge?: number,
    badgeClassName?: string,
    text?: string,
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const StyledToolTip = styled(Tooltip)(({theme}) => ({ 
    arrow: {
        color: "#101010",
      },
      tooltip: {
        backgroundColor: "#101010",
        color: "#E1F5FC",
        font: "12px TheSansC4s"
      }
}))

const HybrisToolTip = styled(Tooltip)(({theme}) => ({ 
    arrow: {
        color: "#101010",
      },
      tooltip: {
        backgroundColor: "#101010",
        color: "#E1F5FC",
        font: "12px TheSansC4s"
      },
      tooltipPlacementTop: {
          top: "0px !important"
      },
      tooltipPlacementBottom: {
          top: "0px !important"
      }
}))

export function CustomTooltip(props: any) {
    return !!props.hybriscustomer ? <HybrisToolTip {...props} /> : <StyledToolTip {...props}/>;
}

export const ToolItem: React.FC<IToolItemProps> = ({ className, labelLeft, labelRight, labelBottom, labelTop, badge, badgeClassName, text, disabled, tooltip, icon, onClick, source, alt }) => {
    const hybrisCustomer = useSelector(hybrisCustomerSelector);
    const tooltipText = tooltip && !disabled ? tooltip : "";
    let placement: any;
    if (labelLeft) placement = "left";
    if (labelRight) placement = "right";
    if (labelBottom) placement = "bottom";
    if (labelTop) placement = "top";

    return (
        <div className="tool-item-container">
            <div className="tool-item">
                <CustomTooltip arrow title={tooltipText} placement={placement} hybriscustomer={!!hybrisCustomer ? 1 : 0}>
                    <span>
                        <button className={className} onClick={onClick} disabled={disabled}>
                            {
                                text ?
                                    <div className="button-text">
                                        {text}
                                    </div> :
                                    icon ? 
                                    <i className="material-icons">
                                        {icon}
                                    </i> : 
                                    <img src={source} alt={alt} />
                            }
                            {badge && badge > 0 ? <div key={`badge${badge}`} className={`badge ${badgeClassName}`}><div className="badge-number">{badge}</div></div> : null}
                        </button>
                    </span>
                </CustomTooltip>
            </div>
        </div>
    );
}
