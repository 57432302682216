import { IWizardState, initialState } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { IBuildData } from "../../../../../redux/build/types";

export const setWizardDisplayAction = (state: IWizardState, action: PayloadAction<boolean>) => {
    state.display = action.payload;
}

export const setCurrentStepAction = (state: IWizardState, action: PayloadAction<number>) => {
    state.currentStep = action.payload;
}

export const enableNextStepAction = (state: IWizardState, action: PayloadAction<boolean>) => {
    if (state.isNextEnabled !== action.payload) {
        state.isNextEnabled = action.payload
    }
}

export const setApplyEditChangesAction = (state: IWizardState, action: PayloadAction<boolean>) => {
    state.applyEditChanges = action.payload;
}

export const restartWizardAction = (state: IWizardState) => {
    state.display = true;
    state.currentStep = initialState.currentStep;
    state.isNextEnabled = initialState.isNextEnabled;
}

export const saveBuildDataAction = (state: IWizardState, action: PayloadAction<IBuildData | undefined>) => {
    state.savedBuildData = action.payload;
}

export const setDropCheckboxDisabledAction = (state: IWizardState, action: PayloadAction<boolean>) => {
    state.dropCheckboxDisabled = action.payload;
}