import React from "react";
import { GenericSnackbar } from "../../../../../../../../../ui/snackbar/generic/GenericSnackbar";
import { useWarningSnackbar } from './hooks';

export const WarningSnackbar = () => {
    const { type, open, message, onClose } = useWarningSnackbar();

    return (
        <GenericSnackbar
            type={type}
            open={open}
            message={message}
            hideCancelButton={true}
            hideActionButton={true}
            timeout={-1}
            onClose={onClose}
        />
    );
};
