import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './types';
import { setResizeReducer } from "./actions";

const slice = createSlice({
    name: 'resize',
    initialState,
    reducers: {
        setResize: setResizeReducer
    }
})

export const PageResizeReducer = slice.reducer;
export const { setResize } = slice.actions;