import React from 'react';
import { useViewProduct } from './hooks';
import { ToolItem } from '../../../../ToolItem';

export const ViewProduct = () => {
    const { label, tooltip, disableButton, showViewProductDialog } = useViewProduct();
    return (
        <ToolItem
            text={label}
            alt={tooltip}
            tooltip={tooltip}
            disabled={disableButton}
            onClick={showViewProductDialog}
        />
    );
}