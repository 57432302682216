import React from 'react';
import { useBLengthSection } from './hooks';
import {RadioGroup, Radio} from '@orbit/radio'
import { BLengthContext, IBLengthProps } from './types';

import './BLengthSection.scss';

export const BLengthSection: React.FC<IBLengthProps> = ({ trunk, disabled, children }) => {
    const { bLengthRadioGroup, radioOptions, isCustom } = useBLengthSection(trunk, !!disabled);
    return (
        <div className="blength-section-container">
            <BLengthContext.Provider value={{ trunk, isCustom }}>
                <div className="radio-group">
                    <RadioGroup value={bLengthRadioGroup.value} onChange={bLengthRadioGroup.onRadioGroupChange}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            {radioOptions.map(r => <Radio key={r.label} label={r.label} value={r.label} checked={r.label === bLengthRadioGroup.value} disabled={disabled}/>)}
                        </div>
                    </RadioGroup>
                </div>
                {children}
            </BLengthContext.Provider>
        </div>
    )
}