import { ISourceData, duplicateSource } from './source/types'
import { IDestinationData, duplicateDestination } from './destination/types';
import { Fiber84, ConfigurationType, Stagger0 } from '../../components/overlay/components/wizard/redux/types';
import { IUnitOfMeasure, Unit, UoMInches, } from '../../components/overlay/components/header/components/units-of-measure-container/UnitsOfMeasure';
import { IConnectorGroupData, initialSourceGroups, initialDestinationGroups, IConnectorData, IConnectorDataWithGroupIndex, IConnectorGroupDataWithIndex } from './connector/types';
import { createContext } from 'react';
import { getName } from '../../components/overlay/components/projects/components/drawer/components/row/build-info/hooks';
import { FlameRatings } from '../../components/overlay/components/wizard/components/setup/components/flame-rating/types';
import { LocalizationKeys } from '../../../locales/types';
import { IPoint } from '../../../pixi/types';
import { Feeder, SpriteProps } from '../../../pixi/components/build/types';
import { IMarkerProps } from '../../../pixi/components/decorators/markers/types';
import { IPropagationOptions } from '../../components/overlay/components/polarity/propagation/redux/types';

export interface IBuildsState {
    currentBuild?: IBuildData,
    builds: IBuildInfo[],
    loaded: boolean
}

export interface IBuildData {
    id?: number,
    userId?: number,
    groupId?: number,
    sessionId?: string,
    ownerEmail?: string,
    catalogCode?: string,
    name?: string,
    description?: string,
    polarityDescription?: string,
    polarityAssignment?: string, 
    lastModified?: string,
    isAsymmetric?: boolean,
    flameRating?: string,
    configurationType?: string,
    lockedById?: number,
    lockedByFullName?: string,
    source: ISourceData,
    destinations: IDestinationData[],
    availability?: IEnableData,
    unit?: string,
    fiberType?: string
}

export interface IBuildInfo {
    buildId?: number,
    userId?: number,
    groupId?: number, 
    name?: string,
    description?: string;
    polarityDescription?: string;
    partNumber?: string;
    ownerEmail?: string;
    lockedById?: number;
    catalogCode?: string;
    lastModified?: string;
    fiberCount?: number,
    fiberType?: string,
    flameRating?: string,
    customTag?: string, 
    gripOnEnd?: boolean, 
    nbConnectorsSrc?: number,
    connectorTypeSrc?: string, 
    nbTAPs?: number,
    nbConnectorsDst?: number,
    connectorTypeDst?: string,
}

export type BuildPropagation = {
    buildId: number;
    options: IPropagationOptions
}

export type TrunkData = Partial<{
    id: number,
    position: number, 
    lengthA: IUnitOfMeasure,
    lengthB: IUnitOfMeasure,   
    customBLength: boolean,
    groups: IConnectorGroupData[],
}> & TrunkDrawState;

export type TrunkDrawState = Partial<{
    isCollapsed: boolean,
    enabled: boolean,
    wamLocation: IPoint
}>;

export interface ITrunkContext {
    section: string;
    position: number;
    unit: Unit;
    lengthA: IUnitOfMeasure;
    lengthB: IUnitOfMeasure;   
    customBLength: boolean;
    groups: IConnectorGroupDataWithIndex[];
    isCollapsed: boolean;
    enabled: boolean;
    connectorType: string;
    stagger: IUnitOfMeasure;
    nbConnectors: number;
    nbGroups: number;
    markerProps: IMarkerProps;
    anchorHeight?: number;
    furcationPoint?: IPoint;
    connectorAnchorPoint?: IPoint;
    wamLocation?: IPoint;
    verticalTrunkSpriteProps?: SpriteProps;
};

export const initialTrunkContext: ITrunkContext = {
    section: Feeder,
    position: 0,
    unit: UoMInches,
    lengthA: Stagger0.value,
    lengthB: Stagger0.value,
    customBLength: false,
    groups: [],
    isCollapsed: false,
    enabled: false,
    stagger: Stagger0.value,
    connectorType: "",
    nbConnectors: 0,
    nbGroups: 0,
    markerProps: {}
};

export interface IPositionContext {
    anchorHeight: number;
    furcationPoint: IPoint;
    connectorAnchorPoint: IPoint;
    wamLocation: IPoint;
};

export const TrunkContext = createContext<ITrunkContext>(initialTrunkContext);

export interface ICableInfoUpdateState {
    buildId: number,
    name: string,
    desc: string,
}

export interface IConnectorLabelUpdateState {
    position: number,
    groupId: number,
    connectorId: number,
    label: string
}

export interface IEnableData {
    sourceEnabled: boolean,
    enabledDestinations: number[]
}

export function duplicateBuild(build: IBuildData, name?: string): IBuildData {
    const { id, ...buildData } = build;
    const availability: IEnableData = {
        sourceEnabled: true,
        enabledDestinations: buildData.destinations.map(d => d.position)
    }
    return {
        ...buildData,
        availability,
        name: name ?? getName(build) + "-Copy",
        source: duplicateSource(buildData.source),
        destinations: buildData.destinations.map(d => duplicateDestination(d)),
    }
}

export function getBuildOwnerByUserId(userId: number, builds: IBuildInfo[]) {
    const ownerEmail = builds.find(b => b.userId === userId)!.ownerEmail;
    return ownerEmail ? extractOwnerFromEmail(ownerEmail) : LocalizationKeys.Unknown;
}

export const extractOwnerFromEmail = (ownerEmail: string) => {
    return ownerEmail.split("@")[0];
}

export function getBuildUserIdByProjectId(projectId: number, builds: IBuildInfo[]) {
    const build = builds.find(b => b.buildId === projectId);
    return build ? build.userId : -1;
}

export function isBuildLocked(userId: number, projectId: number, builds: IBuildData[]) {
    const build = builds.find(b => b.id && b.id === projectId);
    if (build) {
        if (build.lockedById === -1) {
            return false;
        } else if (build.lockedById !== userId) {
            return true;
        }
    }
    return false;
}

export const getSortedGroups = (data: TrunkData): IConnectorGroupData[] => {
    if (!data.groups) return [];

    const lengthB = data.lengthB! ?? Stagger0.value;
    const stagger = data.groups[0].stagger ?? Stagger0.value;
    return data.groups.map(g => g.lengthB && g.lengthB.value > -1 ? g : { ...g, lengthB: { value: lengthB.value + g.position! * stagger.value, unit: lengthB.unit } })
                      .sort((a, b) => a.lengthB!.value > b.lengthB!.value ? 1 : -1)
                      .filter((c, i, self) => self.findIndex(s => s.lengthB === c.lengthB) === i); // unique values
}

export const getGroupsWithIndex = (data: TrunkData): IConnectorGroupDataWithIndex[] => {
    if (!data.groups) return [];

    const lengthB = data.lengthB ?? Stagger0.value;
    const stagger = data.groups[0].stagger ?? Stagger0.value;

    let groups = data.groups.map(g => g.lengthB && g.lengthB.value > -1 ? g : { ...g, lengthB: { value: lengthB.value + g.position! * stagger.value, unit: lengthB.unit } })
    if (data.customBLength) {
        const lengths = Array.from(new Set([...groups.map(g => g.lengthB!.value).sort((a, b) => a > b ? 1 : -1)]));
        return groups.map((g, i) => ({ ...g, lengthB: { value: lengths[i], unit: g.lengthB!.unit }, connectors: g.connectors.map(c => ({ ...c, groupIndex: lengths.indexOf(g.lengthB!.value) })) }))
    }

    return groups.map((g, i) => ({ ...g, connectors: g.connectors.map(c => ({ ...c, groupIndex: i })) }));

}

export const getConnectorsWithGroupIndex = (groupIndex: number, connectors: IConnectorData[]) : IConnectorDataWithGroupIndex[] => {
    return connectors.map(c => ({ ...c, groupIndex }));
}

export const initialBuildData: IBuildData = {
// This is temporary and solely for the purpose of testing
        id: 0,
        name: '',
        description: '',
        lastModified: new Date().toLocaleDateString(),
        isAsymmetric: true,
        configurationType: ConfigurationType.Patching,
        flameRating: FlameRatings.Riser,
        source: {
            fiberType: "",
            fiberCount: Fiber84.count,
            lengthA: { unit: UoMInches, value: 46 },
            lengthB: { unit: UoMInches, value: 24 },
            groups: initialSourceGroups,
            gripOnEnd: true,
            isCollapsed: false,
            customBLength: false,
        },
        destinations: [
            { position: 1, lengthA: { unit: UoMInches, value: 80 }, lengthB: { unit: UoMInches, value: 172 }, groups: initialDestinationGroups(1), isCollapsed: false, customBLength: false },
            { position: 2, lengthA: { unit: UoMInches, value: 80 }, lengthB: { unit: UoMInches, value: 172 }, groups: initialDestinationGroups(2), isCollapsed: false, customBLength: false },
            { position: 3, lengthA: { unit: UoMInches, value: 80 }, lengthB: { unit: UoMInches, value: 172 }, groups: initialDestinationGroups(3), isCollapsed: false, customBLength: false },
            { position: 4, lengthA: { unit: UoMInches, value: 80 }, lengthB: { unit: UoMInches, value: 172 }, groups: initialDestinationGroups(4), isCollapsed: false, customBLength: false },
            { position: 5, lengthA: { unit: UoMInches, value: 80 }, lengthB: { unit: UoMInches, value: 172 }, groups: initialDestinationGroups(5), isCollapsed: false, customBLength: false },
            { position: 6, lengthA: { unit: UoMInches, value: 80 }, lengthB: { unit: UoMInches, value: 172 }, groups: initialDestinationGroups(6), isCollapsed: false, customBLength: false },
            { position: 7, lengthA: { unit: UoMInches, value: 12 }, lengthB: { unit: UoMInches, value: 172 }, groups: initialDestinationGroups(7), isCollapsed: false, customBLength: false }
        ],
        availability: {
            sourceEnabled: false,
            enabledDestinations: []
        }
}

export const initialState: IBuildsState = {
    currentBuild: initialBuildData,
    builds: [],
    loaded: false,
}

export const BuildContext = createContext({ ...initialState.currentBuild!, unit: UoMInches });