import * as PIXI from "pixi.js";
import { IPoint, IPointEmpty } from "../../../../../types";
import { useState, useEffect, useContext, useCallback } from "react";
import { useText } from "../../../text/hooks";
import { ILineMarkerProps, Orientations, Sides } from "./types";
import { MarkerStyleContext } from "../../types";
import { drawArrow } from "../../hooks";

export const useLineMarker = (props: ILineMarkerProps) => {
    const { thickness, color } = useContext(MarkerStyleContext);
    const { path, orientation, direction } = props;
    const { text: textPath } = path;
    const { lineSide } = useLineSide(orientation!);
    const { text, textStyle, textSize } = useText(textPath?.text);
    const [startPath, setStartPath] = useState<IPoint[]>([IPointEmpty, IPointEmpty]);
    const [endPath, setEndPath] = useState<IPoint[]>([IPointEmpty, IPointEmpty]);
    const [textPosition, setTextPosition] = useState(IPointEmpty);

    useEffect(() => {
        const { start: initialStart, end: initialEnd } = path;
        const startPath: IPoint[] = [initialStart, initialEnd];

        if (textPath && textSize.width && textSize.height) {
            const length = orientation === "horizontal" ?  initialEnd.x - initialStart.x : initialEnd.y - initialStart.y;
            const textPosition: IPoint = { x: initialStart.x - textSize.width - 20, y: initialEnd.y - textSize.height * 0.5 - 2 };
            if (textPath.position === "middle") {
                textPosition.x = orientation === "horizontal" 
                    ? initialStart.x + length * 0.5 - textSize.width * 0.5
                    : initialStart.x - textSize.width - 30;

                textPosition.y = orientation === "horizontal"
                    ? textPosition.y
                    : initialEnd.y + (initialStart.y - initialEnd.y) * 0.5 - textSize.height * 0.5;
                    
                if (orientation === "horizontal") {
                    startPath[0] = { x: initialStart.x, y: initialStart.y };
                    startPath[1] = { x: textPosition.x - 10, y: initialStart.y };
                    setEndPath([{ x: textPosition.x + textSize.width + 10, y: initialStart.y }, initialEnd]);
                }
            }

            setTextPosition(textPosition);
        }

        setStartPath(startPath);
    }, [path, direction, textPath, orientation, textSize]);

    const drawLine = useCallback((g: PIXI.Graphics, path: IPoint[]) => {
        const [ first, second ] = path;

        g.clear();
        g.lineStyle(thickness, color);
        g.moveTo(first.x, first.y);
        g.lineTo(second.x, second.y);
    }, [thickness, color]);

    const drawStartLine = useCallback((g: PIXI.Graphics) => {
        drawLine(g, startPath);

        const displayArrow = direction === "start" || direction === "bidirectional";
        if (displayArrow) {
            const point: IPoint = (textPath && textPath.position === "middle") || orientation === "horizontal" ? startPath[0] : startPath[1];
            drawArrow(g, point, lineSide.start, thickness, color);
        }

    }, [startPath, textPath, lineSide.start, orientation, direction, color, thickness, drawLine]);

    const startLine = {
        display: startPath.length === 2,
        draw: drawStartLine
    };

    const drawEndLine = useCallback((g: PIXI.Graphics) => {
        drawLine(g, endPath);

        const displayArrow = direction === "end" || direction === "bidirectional";
        if (displayArrow) {
            let point: IPoint =  startPath[0];
            if (orientation === "horizontal") {
                point = textPath && textPath.position === "middle" ? endPath[1] : startPath[1];
            }
            drawArrow(g, point, lineSide.end, thickness, color);
        }

    }, [startPath, endPath, lineSide.end, thickness, color, direction, orientation, textPath, drawLine]);

    const endLine = {
        display: endPath.length === 2,
        draw: drawEndLine
    };

    return { text, textStyle, textPosition, startLine, endLine };
}

export const useLineSide = (orientation: string) => {
    const [lineSide, setLineSide] = useState(getLineSide(orientation));

    useEffect(() => {
        if (orientation === Orientations.Horizontal) {
            setLineSide({ start: Sides.Left, end: Sides.Right });
        }
        else {
            setLineSide({ start: Sides.Top, end: Sides.Bottom });
        }
    }, [orientation])

    return { lineSide };
}

const getLineSide = (orientation: string) => {
    if (orientation === Orientations.Horizontal) {
        return { start: Sides.Left, end: Sides.Right };
    }
    else {
        return { start: Sides.Top, end: Sides.Bottom };
    }
}